export const tableConvertedData = (res) => {
  let data = [];
  res?.map((itm) => {
    data.push({
      rollNo: itm?.s_roll || "--",
      name: itm?.s_name || "--",
      section: itm?.s_section || "--",
      gender: itm?.s_gender || "--",
      relagion: itm?.s_religion || "--",
      tempAddress: itm?.s_t_address || "--",
      perAddress: itm?.s_p_address || "--",
      healthStatus: itm?.s_health_status || "--",
    });
  });

  return data;
};
