import { createSlice } from "@reduxjs/toolkit";

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: {
    userInfo: {},
    role: "",
    token: "",
    expire: "",
    isLogin: false,
  },
  reducers: {
    setUserInfo: (state, action) => {
      if (action?.payload?.success) {
        const userData = action.payload;

        state.userInfo = userData?.data;
        state.role = userData?.data?.user_admin_type;
        state.token = userData?.data?.token;
        state.expire = userData?.data?.expire;
        state.isLogin = action?.payload?.success;
      }
    },

    logout: (state, action) => {
      if (action?.payload) {
        sessionStorage.removeItem("url");
        sessionStorage.removeItem("subUrl");
        localStorage.removeItem("persist:root");
        state.userInfo = {};
        state.role = "";
        state.token = "";
        state.expire = "";
        state.isLogin = false;
      }
    },
  },
});

// Exports all actions
export const { setUserInfo, logout, handleDeleteObj } = userInfoSlice.actions;

export default userInfoSlice.reducer;
