import { useSelector } from "../redux/store";

export const useAuth = () => {
  let auth = false;
  const { token, expire } = useSelector((state) => state.userInfo);
  const expireTokenTime = Math.floor(Date.now() / 1000);
  if ( (token && expireTokenTime < expire)) {
    auth = true;
  }
  // //console.log("currentTime:",expireTokenTime);
  // //console.log("expireTime:",expire);
  // //console.log(expireTokenTime<expire);
  // //console.log("loginStatus:",loginStatus);
  // //console.log("auth:",auth);
  return auth;
};


export const useToken = () => {
  const { token } = useSelector((state) => state.userInfo);
 
  return token;
};