import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { formateDate } from "../../../../../utils/Formatter/dateFormater";

const Remarks = ({ onChangeRemarks , formRemarks,errorsRemarks,isEdit}) => {
  return (
    <div>
      {" "}
      <div>
        <p className="text-2xl text-center mb-5 mt-10 font-bold ">Comment</p>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="diagnosis"
                label={formRemarks?.diagnosis ? "Diagnosis" : "Diagnosis" + "*"}
                error={errorsRemarks.diagnosis ? true : false}
                helperText={errorsRemarks.diagnosis}
                value={formRemarks?.diagnosis || ""}
                multiline
                rows={3}
                fullWidth
                onChange={(e) => {
                  onChangeRemarks({
                    name: "diagnosis",
                    value: e.target.value,
                  });
                }}
              />
            </Grid>{" "}
            <Grid item xs={12} sm={6}>
              <TextField
                id="treatment"
                label={formRemarks?.treatment ? "Treatment" : "Treatment" + "*"}
                error={errorsRemarks.treatment ? true : false}
                helperText={errorsRemarks.treatment}
                value={formRemarks?.treatment || ""}
                multiline
                rows={3}
                fullWidth
                onChange={(e) => {
                  onChangeRemarks({
                    name: "treatment",
                    value: e.target.value,
                  });
                }}
              />
            </Grid>{" "}
            <Grid item xs={12} sm={6}>
              <TextField
                id="referral"
                label="Referral(if needed)"
                error={errorsRemarks.referral ? true : false}
                helperText={errorsRemarks.referral}
                value={formRemarks?.referral || ""}
                multiline
                rows={3}
                fullWidth
                onChange={(e) => {
                  onChangeRemarks({
                    name: "referral",
                    value: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      <div>
        <p className="text-2xl text-center mb-5 mt-10 font-bold ">
          Check up time
        </p>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                 
                  label={
                    formRemarks.startDate ? "Checkup Date" : "Checkup Date *"
                  }
                  value={formRemarks?.startDate || null}
                  onChange={(newValue) => {
                    onChangeRemarks({
                      name: "startDate",
                      value: formateDate(newValue),
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errorsRemarks?.startDate ? true : false}
                      helperText={errorsRemarks?.startDate}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>{" "}
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={
                    formRemarks.dataEntryDate
                      ? "Data Entry Date"
                      : "Data Entry Date *"
                  }
                  value={formRemarks?.dataEntryDate || null}
                  onChange={(newValue) => {
                    onChangeRemarks({
                      name: "dataEntryDate",
                      value: formateDate(newValue),
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errorsRemarks?.dataEntryDate ? true : false}
                      helperText={errorsRemarks?.dataEntryDate}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>{" "}
          </Grid>
        </Box>
      </div>
      <div>
        <p className="text-2xl text-center mb-5 mt-10 font-bold ">
          Health Status
        </p>
        <div sx={{ display: "flex", justifyContent: "center" }}>
          <Autocomplete
            disablePortal
            id="healthStatus"
            options={["Healthy", "Needs Attention", "Sick - treated/ referred"]}
            fullWidth
            size="small"
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            value={formRemarks.healthStatus || ""}
            onChange={(event, newValue) => {
              onChangeRemarks({ name: "healthStatus", value: newValue });
            }}
            renderInput={(params) => (
              <TextField
                error={errorsRemarks.healthStatus ? true : false}
                helperText={errorsRemarks.healthStatus}
                {...params}
                label="Health Status"
              />
            )}
          />
        </div>
      </div>

{
  isEdit && (
    <div>
    <p className="text-2xl text-center mb-5 mt-10 font-bold ">
      Account Status
    </p>
    <div sx={{ display: "flex", justifyContent: "center" }}>
      <Autocomplete
        disablePortal
        id="accountStatus"
        options={["active", "expired", "inactive"]}
        fullWidth
        size="small"
        isOptionEqualToValue={(option, value) =>
          option.value === value.value
        }
        value={formRemarks.accountStatus || ""}
        onChange={(event, newValue) => {
          onChangeRemarks({ name: "accountStatus", value: newValue });
        }}
        renderInput={(params) => (
          <TextField
            error={errorsRemarks.accountStatus ? true : false}
            helperText={errorsRemarks.accountStatus}
            {...params}
            label="Account Status"
          />
        )}
      />
    </div>
  </div>
  )
}

    </div>
  );
};

export default Remarks;
