import React from 'react';
// import View from '../../../components/HealthCare/Institutions/View/View';
 import View from '../../../components/HealthCare/institutions/ViewMain/SAInstitutionsView';


const InstitutionsViewPage = () => {
  return <div>
  <View/>
  </div>;
};

export default InstitutionsViewPage;
