import React from "react";

const AppBadge = ({ type }) => {
  if (type?.toLocaleLowerCase() === "unpaid") {
    return <div className="badge bg-red-200 text-red-500 border-none px-4 py-3  capitalize">{type}</div>;
  }
  if (type?.toLocaleLowerCase() === "pending") {
    return <div className="badge bg-yellow-200 text-yellow-500 border-none px-4 py-3  capitalize">{type}</div>;
  }

  if (type?.toLocaleLowerCase() === "inactive") {
    return <div className="badge badge-error p-3 ">{type}</div>;
  }
  return <div className="badge bg-green-200 text-green-500 border-none px-4 py-3 capitalize">{type}</div>;
};

export default AppBadge;
