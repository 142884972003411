import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../constants/ApiConstant/routeConstant";
import { setAllClasses } from "../../../redux/slices/schoolAuthoritySlice";
import { useDispatch, useSelector } from "../../../redux/store";
import AppProfile from "../../ui/AppProfile";
import About from "./About";
import Settings from "./Settings";

const SAProfile = () => {
  const dispatch = useDispatch();
  const { role, token, userInfo } = useSelector((state) => state.userInfo);
  // const location = useLocation();
  // const { settingsRoute } = location.state;
  const [selectedNav, setSelectedNav] = useState();
  const [authorityInfo, setAuthorityInfo] = useState([]);

  const getSchoolAuthorityInfo = async () => {
    const id = userInfo.user_id;
    const body = {
      profileType: role,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(`https://api.adminomphd.online/api/user/get/${id}`, body, header)
      .then((res) => {
        if (res?.data?.message) {
          setAuthorityInfo(res?.data?.data);
          dispatch(setAllClasses(res?.data?.data.classes));
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    getSchoolAuthorityInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return (
    <div>
      <AppProfile
        bgImgClass="bg-[url('./assets/school-authority-bg.jpg')]"
        profileImg={
          authorityInfo.user_img
            ? `${BASE_URL}/${authorityInfo.user_img}`
            : null
        }
        callback={(item) => setSelectedNav(item)}
        shortInfo={
          <div>
            <p className="font-extrabold ">{authorityInfo?.ins_principle}</p>
            <p className="text-xs text-gray-500">
              Principal of
              <span className="ml-1">{authorityInfo?.ins_name}</span>
            </p>
          </div>
        }
        othersInfo={
          <div>
            <p className="font-extrabold text-center">
              {authorityInfo?.ins_total_students}+
            </p>
            <p className="text-xs text-gray-500 text-center">Total Students</p>
          </div>
        }
      >
        {selectedNav?.key === "About" && (
          <About authorityInfo={authorityInfo} />
        )}
        {selectedNav?.key === "Settings" && <Settings />}
      </AppProfile>
    </div>
  );
};

export default SAProfile;
