import { Box, Grid, TextField } from "@mui/material";

export default function Password({
  onChangePasswordInfo,
  errorsFormPassword,
   formPassword
}) {
  return (
    <div className="bg-[#ffffff]  py-2 px-7 pb-5">
      <h3 className="ml-2 py-5 text-2xl font-bold tracking-wider text-center ">
        Set School Authority Password{" "}
      </h3>
      <div>
        {" "}
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                error={errorsFormPassword.password}
                helperText={errorsFormPassword.password}
                id="password"
                label={formPassword.password ? "Password" : "Password *"}
                variant="outlined"
                fullWidth
                type="password"
                size="small"
                value={formPassword.password || ""}
                onChange={(e) => {
                  onChangePasswordInfo({
                    name: "password",
                    value: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="confirmPassword"
               
                label={
                  formPassword.confirmPassword
                    ? "Confirm Password"
                    : "Confirm Password *"
                }
                error={errorsFormPassword.confirmPassword}
                helperText={errorsFormPassword.confirmPassword}
                variant="outlined"
                fullWidth
                size="small"
                type="password"
                value={formPassword.confirmPassword || ""}
                onChange={(e) => {
                  onChangePasswordInfo({
                    name: "confirmPassword",
                    value: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
