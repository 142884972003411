import React from "react";

const AppCardContainer = ({
  classes,
  children,
  spacing = "",
  width = "min-w-96 ",
}) => {
  return (
    <div
      className={`bg-white shadow-md rounded-xl md:px-8 px-3 md:py-10 py-5 ${width} ${spacing} ${classes}`}
    >
      {children}
    </div>
  );
};

export default AppCardContainer;
