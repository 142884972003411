import * as React from "react";

import { Autocomplete, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const BasicInfo = ({
  onChangeBasicInfo,
  errorsBasicInfo,
  formBasicInfo,
  isEdit,
}) => {
  var dateItem = [];
  for (var i = 1800; i <= 2100; i++) {
    dateItem.push({
      label: `${i}`,
      value: `${i}`,
    });
  }
  return (
    <div>
      {" "}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="institueType"
              options={["School", "College", "Madrasa", "University"]}
              fullWidth
              size="small"
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              value={formBasicInfo.institueType || null}
              onChange={(event, newValue) => {
                onChangeBasicInfo({ name: "institueType", value: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  error={errorsBasicInfo.institueType ? true : false}
                  helperText={errorsBasicInfo.institueType}
                  {...params}
                  label={
                    formBasicInfo.institueType
                      ? "Institue Type"
                      : "Institue Type" + " *"
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={errorsBasicInfo.institueName ? true : false}
              helperText={errorsBasicInfo.institueName}
              id="institueName"
              label={
                formBasicInfo.institueName
                  ? "Institue Name"
                  : "Institue Name" + " *"
              }
              variant="outlined"
              fullWidth
              size="small"
              value={formBasicInfo.institueName || ""}
              onChange={(e) => {
                onChangeBasicInfo({
                  name: "institueName",
                  value: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="establishedYearInstitue"
              options={dateItem.map((item) => item.value)}
              fullWidth
              size="small"
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(event, newValue) => {
                onChangeBasicInfo({
                  name: "establishedYearInstitue",
                  value: newValue,
                });
              }}
              value={formBasicInfo.establishedYearInstitue || ""}
              renderInput={(params) => (
                <TextField
                  error={errorsBasicInfo.establishedYearInstitue ? true : false}
                  helperText={errorsBasicInfo.establishedYearInstitue}
                  {...params}
                  label={
                    formBasicInfo.establishedYearInstitue
                      ? "Established Year"
                      : "Established Year" + " *"
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <TextField
              error={errorsBasicInfo.registrationNumber ? true : false}
              helperText={errorsBasicInfo.registrationNumber}
              id="registrationNumber"
              label={
                formBasicInfo.registrationNumber
                  ? "Registration Number"
                  : "Registration Number" + " *"
              }
              variant="outlined"
              fullWidth
              size="small"
              value={formBasicInfo.registrationNumber || ""}
              onChange={(e) => {
                onChangeBasicInfo({
                  name: "registrationNumber",
                  value: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <TextField
              error={errorsBasicInfo.nameOfPrincipal ? true : false}
              helperText={errorsBasicInfo.nameOfPrincipal}
              id="nameOfPrincipal"
              label={
                formBasicInfo.nameOfPrincipal
                  ? "Name Of Principal"
                  : "Name Of Principal" + " *"
              }
              variant="outlined"
              fullWidth
              size="small"
              value={formBasicInfo.nameOfPrincipal || ""}
              onChange={(e) => {
                onChangeBasicInfo({
                  name: "nameOfPrincipal",
                  value: e.target.value,
                });
              }}
            />
          </Grid>
          {!isEdit && (
            <Grid item xs={12} sm={6}>
              {" "}
              <TextField
                error={errorsBasicInfo.phone ? true : false}
                helperText={errorsBasicInfo.phone}
                id="phone"
                label={
                  formBasicInfo.phone ? "Phone Number" : "Phone Number" + " *"
                }
                variant="outlined"
                fullWidth
                size="small"
                value={formBasicInfo.phone || ""}
                onChange={(e) => {
                  onChangeBasicInfo({
                    name: "phone",
                    value: e.target.value,
                  });
                }}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            {" "}
            <TextField
              error={errorsBasicInfo.totalStudents ? true : false}
              helperText={errorsBasicInfo.totalStudents}
              id="totalStudents"
              label={
                formBasicInfo.totalStudents
                  ? "Total Students"
                  : "Total Students" + " *"
              }
              variant="outlined"
              fullWidth
              size="small"
              type="number"
              value={formBasicInfo.totalStudents || ""}
              onChange={(e) => {
                onChangeBasicInfo({
                  name: "totalStudents",
                  value: e.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default BasicInfo;
