export const speciality = [
  "Medicine",
  "Cardiology",
  "Neuromedicine Neurology",
  "Neuclear medicine",
  "Skin & VD",
  "Endocrinology",
  "Diabetology",
  "Nephrology",
  "Urology",
  "Family Medicine",
  "Respiratory medicine",
  "Hepatology",
  "Hematology",
  "Gastroenterology",
  "Paediatrics",
  "Paediatric Cardiology",
  "Paediatric Hepatology",
  "Paediatric Gastroenterology",
  "Paediatric Hematology",
  "Paediatric Neurology",
  "Paediatric Surgery",
  "ENT & Head Neck surgery",
  "Opthalmology",
  "Eye",
  "Neurosurgery",
  "Hepatobiliary Surgery",
  "Emergency Medicine",
  "Reproductive Medicine",
  "Gynae & Obs",
  "General Practitioner",
  "Paediatric Endocrinology",
];
