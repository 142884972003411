import React, { useRef } from "react";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

export const ApexBarChart = ({ setGetChartData, healthStatus, chartTitle }) => {
  const { healthy, attention, sick } = healthStatus;
  const [chartData, setChartData] = useState();
  const chartRef = useRef(null);

  const [ setSelectedBarIndex] = useState(null);

  const toggleBarSelection = (event, chartContext, config) => {
  
    const chart = chartRef.current.chart;
    const currentIndex = chart.w.globals.selectedBar;
    const newIndex = currentIndex === config.dataPointIndex ? null : config.dataPointIndex;
    setChartData(newIndex)
    if (newIndex === null) {
      chart.updateOptions({
        plotOptions: {
          bar: {
            selectedBar: {
              index: null,
            },
          },
        },
      });
    } else {
      chart.updateOptions({
        plotOptions: {
          bar: {
            selectedBar: {
              index: newIndex,
            },
          },
        },
      });
    }
    //console.log(chart.w.config.plotOptions.bar.selectedBar); // add this line to check if the selectedBar option is being updated correctly
    setSelectedBarIndex(newIndex);
  };
  


  const chartState = {
    series: [
      {
        name: "Health Status",
        data: [healthy, attention, sick],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        height: 370,
        type: "bar",
      },

      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      colors: ["#00b894", "#ff9f43", "#d63031"],
      dataLabels: {
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },

      xaxis: {
        categories: ["Healthy", "Needs Attention", "Sick - treated/ referred"],
        position: "top",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      title: {
        text: chartTitle,
        style: {
          color: "#444",
        },
      },
    },
  };




  useEffect(() => {
    const filteredData = chartState.options.xaxis.categories.filter(
      (_, i) => i === chartData
    )[0];
    setGetChartData(filteredData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);


  return (
    <>
    <ReactApexChart
      options={{
        ...chartState.options,
        chart: {
          ...chartState.options.chart,
          events: {
            dataPointSelection: toggleBarSelection,
          },
        },
      }}
      series={chartState.series}
      type="bar"
      height={350}
      // width={550}
      ref={chartRef}
      style={{
        width: '550px'
      }}
    />
      {/* <div>Selected bar index: {selectedBarIndex}</div> */}
    </>
  );
};

export const ApexPieChart = ({ healthStatus }) => {
  const { healthy, attention, sick } = healthStatus;
  const chartState = {
    series: [healthy, attention, sick],
    options: {
      chart: {
        height: 370,
        type: "donut",
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
        },
      },
      colors: ["#00b894", "#ff9f43", "#d63031"],
      labels: ["Healthy", "Needs Attention", "Sick - treated/ referred"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 330,
            },
            legend: {
              position: "top",
            },
          },
        },
        
      ],
    },
  };

  return (
    <ReactApexChart
      options={chartState.options}
      series={chartState.series}
      type="donut"
      width={500}
    />
  );
};
