const lockScroll = () => {
    document.body.style.overflow = 'hidden'
}
const unlockScroll = () => {
    document.body.style.overflow = ''
}
const useScrollLock = () => {
    return {
        lockScroll,
        unlockScroll
    }

}

export default useScrollLock;