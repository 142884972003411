import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Loader from '../../../../assets/loader.gif';
import {
  BASE_URL,
  endPoint,
} from "../../../../constants/ApiConstant/routeConstant";
import { useToken } from "../../../../services/useAuth";
import { notify } from "../../../ui/helpers/ToastMsg";
import Loader from "../../../ui/Loader";

const Notices = () => {
  const [formNotice, setFormNotice] = useState({});
  const [errorsNotice, setErrorsNotice] = useState({});
  const [events, setEvents] = useState([]);
  const [, setEventError] = useState();
  const [loading, setLoading] = useState();

  const [notices, setNotices] = useState({});
  const token = useToken();

  const navigate =useNavigate()
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  ////console.log(events)
  const getData = async () => {
    await axios
      .get(`${BASE_URL}${endPoint.event}`, header)
      .then((res) => {
        if (res?.data?.success) {
          setEvents(res.data?.data);
        }
      })
      .catch((err) => {
        setEventError(err.response.data.message);
      });
  };

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //console.log(formNotice);

  const onSubmit = async () => {
    const eventId = events.find(
      (el) => el.e_title === formNotice.eventName
    ).e_id;
    const bodyData = {
      olymId: eventId,
      title: formNotice.noticetitle,
      msg: formNotice.message,
      time: new Date(),
    };
    //console.log(bodyData);
    setLoading(true);
    await axios
      .post(`${BASE_URL}${endPoint.notice}`, bodyData, header)
      .then((res) => {
        setLoading(false);
        setFormNotice({});
        if (res?.data?.success) {
          //console.log(res.data?.data);
          notify("Notice Added Successfully!", "success");
          navigate("/olympiad/notices/view");
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
        notify(err.response.data?.message, "warning");
      });
  };

  const onChangeNotice = ({ name, value }) => {
    setFormNotice({ ...formNotice, [name]: value });
    if (name === "noticetitle") {
      if (value === "") {
        setErrorsNotice((prev) => {
          return {
            ...prev,
            [name]: "Notice title cann't be blank.",
          };
        });
      } else {
        setErrorsNotice((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "eventName") {
      if (value === "") {
        setErrorsNotice((prev) => {
          return {
            ...prev,
            [name]: "Event Name cann't be blank.",
          };
        });
      } else {
        setErrorsNotice((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "message") {
      if (value === "") {
        setErrorsNotice((prev) => {
          return {
            ...prev,
            [name]: "Message cann't be blank.",
          };
        });
      } else {
        setErrorsNotice((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
  };
  const onSubmitNotice = () => {
    if (!formNotice.noticetitle) {
      setErrorsNotice((prev) => {
        return {
          ...prev,
          noticetitle: "Please add a Notice Title",
        };
      });
    }
    if (!formNotice.eventName) {
      setErrorsNotice((prev) => {
        return {
          ...prev,
          eventName: "Please Select an Event Name",
        };
      });
    }
    if (!formNotice.message) {
      setErrorsNotice((prev) => {
        return {
          ...prev,
          message: "Please add an Event Message",
        };
      });
    }
    if (
      formNotice.noticetitle &&
      formNotice.eventName &&
      formNotice.message &&
      Object.values(errorsNotice).every((item) => !item)
    ) {
      setNotices({ ...formNotice });
      onSubmit();
    }
  };

  //console.log(notices);

  return (
    <div className="my-2 mt-10 shadow-md rounded-xl">
      <div className=" bg-white px-5 md:px-[3rem] py-[2rem] rounded-xl">
        <h1 className=" text-2xl pt-5 text-center font-bold uppercase">
          Add Notices
        </h1>{" "}
        <Box sx={{ flexGrow: 1, mt: 7 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="eventName"
                options={events ? events.map((item) => item.e_title) : []}
                fullWidth
                size="small"
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                value={formNotice.eventName || ""}
                onChange={(event, newValue) => {
                  onChangeNotice({
                    name: "eventName",
                    value: newValue,
                    //value: `${events.find((el)=> el.e_title===newValue).e_id}`,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    error={errorsNotice.eventName ? true : false}
                    helperText={errorsNotice?.eventName}
                    {...params}
                    label={
                      events.length === 0 ? (
                        <span className="flex items-center justify-center w-full">
                          <Loader variant="#3498db" height="25px" />
                        </span>
                      ) : formNotice?.eventName ? (
                        "Select Event"
                      ) : (
                        "Select Event" + " *"
                      )
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {" "}
              <TextField
                id="noticetitle"
                variant="outlined"
                fullWidth
                size="small"
                value={formNotice?.noticetitle || ""}
                label={
                  formNotice?.noticetitle ? "Notice title" : "Notice title *"
                }
                onChange={(e) => {
                  onChangeNotice({
                    name: "noticetitle",
                    value: e.target.value,
                  });
                }}
                error={errorsNotice?.noticetitle ? true : false}
                helperText={errorsNotice?.noticetitle}
              />
            </Grid>

            <Grid item xs={12}>
              {" "}
              <TextField
                id="message"
                variant="outlined"
                fullWidth
                size="small"
                multiline
                rows={5}
                value={formNotice?.message || ""}
                label={formNotice?.message ? "Message" : "Message *"}
                onChange={(e) => {
                  onChangeNotice({
                    name: "message",
                    value: e.target.value,
                  });
                }}
                error={errorsNotice?.message ? true : false}
                helperText={errorsNotice?.message}
              />
            </Grid>
          </Grid>
        </Box>
        <div className=" flex justify-center">
          <button
            disabled={loading}
            className="btn bg-blue-600 hover:bg-blue-600  border-none  font-bold mt-5 px-10 rounded-2xl  "
            onClick={() => onSubmitNotice()}
          >
            Submit
          </button>
        </div>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "1rem",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </div>
    </div>
  );
};

export default Notices;
