import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { userTypes } from "../../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint
} from "../../../../constants/ApiConstant/routeConstant";
import { useSelector } from "../../../../redux/store";
import AppBredcrumbs from "../../../ui/AppBredcrumbs";
import AppCardContainer from "../../../ui/AppCardContainer";
import AppSubNav from "../../../ui/AppSubNav";
import { tableConvertedData } from "./helper/convertedStudentsData";

import FamilyDetails from "./Details/FamilyDetails";
import HealthRecordsContainer from "./Details/HealthRecordsContainer";
import InstitutionalInfo from "./Details/InstitutionalInfo";
import ParticularDetails from "./Details/ParticularDetails";
import UploadedDocuments from "./Details/UploadedDocuments";

const TeachersDetails = () => {
  const { id } = useParams();
  const { token } = useSelector((state) => state.userInfo);
  const [singleStudents, setSingleStudents] = useState({});
  const [selectedNav, setSelectedNav] = useState();

  const getSingleStudentsData = async () => {
    const body = {
      profileType: userTypes.teacher,
    };

    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(`${BASE_URL}${endPoint.userGet}/${id}`, body, header)
      .then((res) => {
        if (res?.data?.success) {
          setSingleStudents(tableConvertedData([res?.data?.data])[0]);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  //console.log(singleStudents);
  useEffect(() => {
    getSingleStudentsData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {" "}
      <AppCardContainer spacing="xl:mt-10 3xl:-mr-16 md:mx-5 mt-20">
        <div className="flex items-center justify-between">
          <AppBredcrumbs
            link="/teachers"
            allTitle="All Teachers"
            detailsTitle="Teachers Detaile"
          />
        </div>

        <div className="mt-10">
          <AppSubNav
            navbarData={[
              "Institution Info",
              "Particular Details",
              "Family Details",
              "HealthRecords",
              "Uploaded Documents",
            ]}
            callback={(item) => setSelectedNav(item)}
            type="tab"
            width="w-40"
          />
        </div>

        {selectedNav?.key === "InstitutionInfo" ? (
          <InstitutionalInfo singleStudents={singleStudents} />
        ) : selectedNav?.key === "ParticularDetails" ? (
          <ParticularDetails singleStudents={singleStudents} />
        ) : selectedNav?.key === "FamilyDetails" ? (
          <FamilyDetails singleStudents={singleStudents} />
        ) : selectedNav?.key === "HealthRecords" ? (
          <HealthRecordsContainer singleStudents={singleStudents} />
        ) : selectedNav?.key === "UploadedDocuments" ? (
          <UploadedDocuments singleStudents={singleStudents} />
        ) : (
          <InstitutionalInfo singleStudents={singleStudents} />
        )}
      </AppCardContainer>
    </div>
  );
};

export default TeachersDetails;
