import React, { useEffect, useState } from "react";
import { FiVideo } from "react-icons/fi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { MdOutlineQuiz } from "react-icons/md";
import { userTypes } from "../../constants/ApiConstant/databaseConstant";
import { useGetDashboardData } from "../../services/api";
import AppDashboardCard from "../ui/AppDashboardCard";

const HijamaDashboard = () => {
  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const body = {
    type: userTypes.superAdmin,
  };
  const { getSADashboardData } = useGetDashboardData(body);

  useEffect(() => {
    setIsLoading(true);
    getSADashboardData()
      .then((res) => {
        if (res) {
          setDashboardData(res);
          setIsLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className="mt-20 xl:mt-10 mx-5 xl:mx-0">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        <AppDashboardCard
          title="Total Videos"
          value={dashboardData.totalVideo}
          viewLink="/hijama/Videos/view"
          bgColor="bg-red-50"
          textColor="text-red-600"
          icon={<FiVideo className="text-5xl text-red-600" />}
        />

        <AppDashboardCard
          title="Total Quizes"
          value={dashboardData.totalQuizes}
          viewLink="/hijama/quiz/view"
          bgColor="bg-amber-50"
          textColor="text-amber-600"
          icon={
            <MdOutlineQuiz className="text-5xl text-amber-600" />
          }
        />

        <AppDashboardCard
          title="Total Hijama Users"
          value={dashboardData.totalHijamaUsers}
          viewLink="/hijama/users"
          bgColor="bg-fuchsia-50"
          textColor="text-fuchsia-600"
          icon={<HiOutlineUserGroup className="text-5xl text-fuchsia-600" />}
        />

      </div>
    </div>
  );
};

export default HijamaDashboard;
