import React from "react";
import { Paper, Typography, Box } from "@mui/material";

const PaymentInfo = ({ authorityInfo }) => {
  const {
    ins_fp_s_no,
    ins_fp_s_rate,
    ins_fp_s_amount,
    ins_fp_t_no,
    ins_fp_t_rate,
    ins_fp_t_amount,
  } = authorityInfo;
  return (
    <div className="">
      <Paper sx={{ borderRadius: "12px", mt: 4, p: 4 }}>
        <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
          Full Payment Details
        </Typography>

        <Box sx={{ mt: 3 }}>
          <Typography sx={{ fontWeight: "600", fontSize: 18, mb: 1 }}>
            Students Information
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", mt: 2 }}>
            <Box sx={{ width: "50%" }}>
              <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
                Students Paid
              </Typography>
              <Typography sx={{ fontWeight: "400", fontSize: 16 }}>
                {ins_fp_s_no}
              </Typography>
            </Box>
            <Box sx={{ width: "50%" }}>
              <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
                Students Rate
              </Typography>
              <Typography sx={{ fontWeight: "400", fontSize: 16 }}>
                {ins_fp_s_rate}
              </Typography>
            </Box>
            <Box sx={{ width: "50%", my: 2 }}>
              <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
                Total Amount
              </Typography>
              <Typography sx={{ fontWeight: "400", fontSize: 16 }}>
                {ins_fp_s_amount}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography sx={{ fontWeight: "600", fontSize: 18, mb: 1 }}>
            Teacherss Information
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", mt: 2 }}>
            <Box sx={{ width: "50%" }}>
              <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
                Teacherss Paid
              </Typography>
              <Typography sx={{ fontWeight: "400", fontSize: 16 }}>
                {ins_fp_t_no}
              </Typography>
            </Box>
            <Box sx={{ width: "50%" }}>
              <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
                Teacherss Rate
              </Typography>
              <Typography sx={{ fontWeight: "400", fontSize: 16 }}>
                {ins_fp_t_rate}
              </Typography>
            </Box>
            <Box sx={{ width: "50%", my: 2 }}>
              <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
                Total Amount
              </Typography>
              <Typography sx={{ fontWeight: "400", fontSize: 16 }}>
                {ins_fp_t_amount}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  );
};

export default PaymentInfo;
