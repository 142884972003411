import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsCardChecklist } from "react-icons/bs";
import { userTypes } from "../../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint
} from "../../../../constants/ApiConstant/routeConstant";
import { singleStudentsPdfFnc } from "../../../../redux/slices/tableDataForPdfSlice";
import { useDispatch, useSelector } from "../../../../redux/store";
import AppTable from "../../../ui/AppTable";
import { DynamicTableDataForSAStudentsData } from "../../../ui/helpers/table/TableBodyData";
import { tableHeadStudentsHeaderData } from "../../../ui/helpers/table/TableHeadeData";
import { useConvertedTableData } from "../../../ui/helpers/table/useConvertedTableData";
import { tableConvertedData } from "./helper/convertedStudentsData";
import FilterData from "./helper/FilterData";



const SAStudentsView = () => {
  const { deleteSuccess } = useSelector((state) => state.modal);
  const { token, userInfo } = useSelector((state) => state.userInfo);
  const SAStudentsData = {
    insType: "",
    insId: userInfo.schoolAuthority.ins_id,
    classId: 0,
    className: '',
    gender: "",
  };
  const dispatch = useDispatch();
  const [students, setStudents] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [clearFilter, setClearFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sAStudentsFilter, setSAStudentsFilter] = useState(SAStudentsData);
  const limit = 6;

  //console.log(deleteSuccess);
  const { tableData, pageCount, handlePageClick } =
    useConvertedTableData(students, limit);

  const getAllStudentsData = async () => {
    const body = {
      type: userTypes.schoolHealth,
      data: {
        insType: "",
        insId: userInfo.schoolAuthority.ins_id,
        classId: 0,
        gender: "",
      },
    };

    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    await axios
      .post(`${BASE_URL}${endPoint.filter}`, body, header)
      .then((res) => {
        if (res?.data?.success) {
          setStudents(tableConvertedData(res?.data?.data));
          setLoading(false);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const getAllSAStudentsSearchedData = async () => {
    if (!(searchValue === "")) {
      const body = {
        type: userTypes.schoolHealth,
        data: searchValue,
      };

      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);
      await axios
        .post(`${BASE_URL}${endPoint.search}`, body, header)
        .then((res) => {
          if (res?.data?.success) {
            setStudents(tableConvertedData(res?.data?.data));
            setLoading(false);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    } else {
      getAllStudentsData();
    }
  };

  const handleFilter = async () => {
    if (
      sAStudentsFilter.insType === "" &&
      sAStudentsFilter.insId === 0 &&
      sAStudentsFilter.classId === 0 &&
      sAStudentsFilter.gender === ""
    ) {
      getAllStudentsData();
    } else {
      const body = {
        type: userTypes.schoolHealth,
        data: sAStudentsFilter,
      };

      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);
      await axios
        .post(`${BASE_URL}${endPoint.filter}`, body, header)
        .then((res) => {
          if (res?.data?.success) {
            setStudents(tableConvertedData(res?.data?.data));
            setLoading(false);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };

    
  useEffect(() => {
    if (students) {
      dispatch(singleStudentsPdfFnc(students));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [students, searchValue]);
  
  useEffect(() => {
    getAllStudentsData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess,clearFilter]);

  useEffect(() => {
    getAllSAStudentsSearchedData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div className="3xl:-mr-10 mx-0 md:mx-5 mt-16 xl:-mr-16 xl:mx-0 xl:mt-0">
      <AppTable
        tableTitle="Students Information"
        titleIcon={<BsCardChecklist />}
        search
        searchPlaceHolder="Search by institute name and id"
        searchVal={(val) => setSearchValue(val)}
        filter
        filterBox={
          <FilterData
            sAStudentsFilter={sAStudentsFilter}
            setSAStudentsFilter={setSAStudentsFilter}
            handleFilter={handleFilter}
            setClearFilter={setClearFilter}
            clearFilter={clearFilter}
          />
        }
        titleClass="font-bold"
        tableHeadData={tableHeadStudentsHeaderData}
        tableBodyData={tableData}
        dynamicTable={(colItem, headItem, index, shadow) =>
          DynamicTableDataForSAStudentsData(colItem, headItem, index, shadow)
        }
        loader={loading}
        pagination
        pageCount={pageCount}
        handlePageClick={handlePageClick}
      />
    </div>
  );
};

export default SAStudentsView;
