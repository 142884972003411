import React from "react";
import {  Typography, Box } from "@mui/material";
import {
  AiOutlineSafetyCertificate,
} from "react-icons/ai";
import { BiIdCard } from "react-icons/bi";
import {
  BsCalendar2Date,
  BsCalendarCheck,
} from "react-icons/bs";
import {  GrStatusInfo } from "react-icons/gr";
import { IoIdCardOutline } from "react-icons/io5";

const BasicInfo = ({ singlePatients }) => {
  const {
    pId,
    pName,
    pDob,
    pGender,
    pAddress,
    pPhone,
    pEmail,
    pNid,
  } = singlePatients;

  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiIdCard style={{ marginRight: 4 }} />
            Patients Id
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {pId}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoIdCardOutline style={{ marginRight: 4 }} />
            Patients Name
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {pName}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineSafetyCertificate style={{ marginRight: 4 }} />
            Date Of Birth
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {pDob}
          </Typography>
        </Box>

        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <GrStatusInfo style={{ marginRight: 4 }} />
            Gender
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {pGender}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", my: 5, flexWrap:'wrap' }}>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Address
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {pAddress}
          </Typography>
        </Box>

        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendar2Date style={{ marginRight: 4 }} />
            Phone
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {pPhone}
          </Typography>
        </Box>

        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendar2Date style={{ marginRight: 4 }} />
            Email
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {pEmail}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendar2Date style={{ marginRight: 4 }} />
            NID
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {pNid}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", my: 5 }}>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Birth Certificate
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BasicInfo;
