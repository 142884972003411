import React from "react";
import { Grid, Box } from "@mui/material";
import GenaralInfo from "./about-details/GenaralInfo";
import Address from "./about-details/Address";

const About = ({ doctorInfo }) => {
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item md={7} xs={12}>
          <GenaralInfo doctorInfo={doctorInfo} />
        </Grid>

        <Grid item md={5} xs={12}>
          <Address doctorInfo={doctorInfo} />
        </Grid>

      </Grid>
    </Box>
  );
};

export default About;
