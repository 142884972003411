import React from "react";
import { Paper, Typography, Box } from "@mui/material";
import {
  AiOutlineIdcard,
  AiOutlineLink,
  AiOutlineSafetyCertificate,
  AiOutlineSolution,
} from "react-icons/ai";
import {
  MdOutlineBusinessCenter,
  MdOutlineMarkEmailUnread,
} from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { BiIdCard } from "react-icons/bi";
import {
  BsCalendar2Date,
  BsCalendarCheck,
  BsGenderTrans,
} from "react-icons/bs";
import { GrMapLocation, GrStatusInfo } from "react-icons/gr";
import { IoIdCardOutline } from "react-icons/io5";

const RequiredServices = ({ singleInstitution }) => {
  const {
    insServiceType,
    insContactStart,
    insContactEnd,
    insFpSNo,
    insFpSRate,
    insFpSAmount,
    insFpTNo,
    insFpTRate,
    insFpTAmount,
    insDsSNo,
    insDsTNo,
    insDsRate,
    insDsAmount,
    insFsSNo,
    insFsTNo,
    insSmTotalPatients,
    insSmTotalAmount,
    insSmPerHead,
    insServiceStatus,
  } = singleInstitution;

  // let text = "Hello world!";
  // const date = text.slice(0, 7)
  // //console.log(insContactStart.toString())

  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: { md: "30%", xs: "100%", sm:'50%' } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiIdCard style={{ marginRight: 4 }} />
            Select Services
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insServiceType}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoIdCardOutline style={{ marginRight: 4 }} />
            Contract Timeline
          </Typography>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: 16,
              mt: 0.5,
            }}
          >
            <span> {insContactStart} </span>{" "}
            <span className="mx-3 font-normal"> to </span>
            <span> {insContactEnd} </span>
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexWrap: "wrap", my: 5 }}>
        <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineSafetyCertificate style={{ marginRight: 4 }} />
            No of Students Full payment
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insFpSNo}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <GrStatusInfo style={{ marginRight: 4 }} />
            Rate per head student Full payment
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insFpSRate}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Full Payment Amount
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insFpSAmount}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexWrap: "wrap", my: 5 }}>
        <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            No of Teachers Full payment
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insFpTNo}
          </Typography>
        </Box>
        <Box sx={{ width: "35%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Rate per head student Full payment
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insFpTRate}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Full Payment Amount
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insFpTAmount}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexWrap: "wrap", my: 5 }}>
        <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            No of Student get discount
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insDsSNo}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            No of Teacher get discount
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insDsTNo}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Discount Rate
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insDsRate}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" }, my:5 }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
           Discount Amount
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insDsAmount}
          </Typography>
        </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", my: 5 }}>
       
        <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            No of Student get Free Service
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insFsSNo}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            No of Teacher get Free Service
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insFsTNo}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", my: 5 }}>
       
        <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Summery of total patients
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insSmTotalPatients}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Summery of total amounts
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insSmTotalAmount}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Summery of per head 
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insSmPerHead}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" }, my:5 }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
          Service Status
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insServiceStatus}
          </Typography>
        </Box>
    </Box>
  );
};

export default RequiredServices;
