import React from "react";
import RegisteredStudentsView from '../../components/Olympiad/RegisteredStudentsMain/ViewMain/SARegisteredStudentsView'

const RegisteredStudents = () => {
  return (
   <div><RegisteredStudentsView/></div>
  );
};

export default RegisteredStudents;
