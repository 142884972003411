import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const BasicMedicalHistory = ({
  formBasicMedicalHistory,
  errorsBasicMedicalHistory,
  onChangeBasicMedicalHistory,
  setFormBasicMedicalHistory
}) => {
  const [bmi, setBmi] = useState(0);
  const bmiFunction = () => {
    if (
      formBasicMedicalHistory.heightFts &&
      formBasicMedicalHistory.heightInches &&
      formBasicMedicalHistory.weight
    ) {
      let x =(12 * formBasicMedicalHistory.heightFts +
          formBasicMedicalHistory.heightInches * 1) *
        0.0254;
       x = x * x * 1;
      setBmi((( formBasicMedicalHistory.weight * 1) / x).toFixed(2));
      const addedObj ={
        "bmi":(( formBasicMedicalHistory.weight * 1) / x).toFixed(2),
        "height":`${formBasicMedicalHistory.heightFts} feets ${formBasicMedicalHistory.heightInches} inch`
      }
      setFormBasicMedicalHistory({...formBasicMedicalHistory,...addedObj})
    }
    else {
       setBmi(0);
    }
  };
  useEffect(() => {
    bmiFunction();
  }, [
    formBasicMedicalHistory.heightFts,
    formBasicMedicalHistory.heightInches,
    formBasicMedicalHistory.weight,
  ]);
  return (
    <div>
      <p className=" text-2xl text-center mb-5 font-bold">
        Basic Medical History
      </p>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {" "}
            <TextField
              id="previousIllness"
              variant="outlined"
              fullWidth
              size="small"
              label={
                formBasicMedicalHistory?.previousIllness
                  ? "Any known previous illness"
                  : "Any known previous illness *"
              }
              error={errorsBasicMedicalHistory?.previousIllness? true:false}
              helperText={errorsBasicMedicalHistory?.previousIllness}
              value = {formBasicMedicalHistory.previousIllness || ""}
              onChange={(e) => {
                onChangeBasicMedicalHistory({
                  name: "previousIllness",
                  value: e.target.value,
                });

              }}
            />
          </Grid>{" "}
          <Grid item xs={6}>
            {" "}
            <TextField
              id="medicationHistory"
              variant="outlined"
              fullWidth
              size="small"
              label={
                formBasicMedicalHistory?.medicationHistory
                  ? "Medication History"
                  : "Medication History *"
              }
              error={errorsBasicMedicalHistory?.medicationHistory? true:false}
              helperText={errorsBasicMedicalHistory?.medicationHistory}
              value = {formBasicMedicalHistory.medicationHistory || ""}
              onChange={(e) => {
                onChangeBasicMedicalHistory({
                  name: "medicationHistory",
                  value: e.target.value,
                });
              }}
            />
          </Grid>{" "}

        </Grid>
      </Box>

    <p className="text-2xl font-bold text-center mb-5 mt-10 ">
      Vital Signs
    </p>

    <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={2}>

  {  
    //   <Grid item xs={6}>
    //   <TextField
    //     id="vitalSigns"
    //     variant="outlined"
    //     fullWidth
    //     size="small"
    //     label={
    //       formBasicMedicalHistory?.vitalSigns
    //         ? "Vital Signs"
    //         : "Vital Signs *"
    //     }
    //     error={errorsBasicMedicalHistory?.vitalSigns? true:false}
    //     helperText={errorsBasicMedicalHistory?.vitalSigns}
    //     value = {formBasicMedicalHistory.vitalSigns || ""}
    //     onChange={(e) => {
    //       onChangeBasicMedicalHistory({
    //         name: "vitalSigns",
    //         value: e.target.value,
    //       });
    //     }}
    //   />
    // </Grid>
  }
    
    <Grid item xs={6}>
      {" "}
      <TextField
        id="bloodPressure"
        variant="outlined"
        fullWidth
        size="small"
        label={
          formBasicMedicalHistory?.bloodPressure
            ? "Blood Pressure(mmHg)"
            : "Blood Pressure(mmHg) *"
        }
        error={errorsBasicMedicalHistory?.bloodPressure? true:false}
        helperText={errorsBasicMedicalHistory?.bloodPressure}
        value = {formBasicMedicalHistory.bloodPressure || ""}
        onChange={(e) => {
          onChangeBasicMedicalHistory({
            name: "bloodPressure",
            value: e.target.value,
          });
        }}
      />
    </Grid>
    <Grid item xs={6}>
      {" "}
      <TextField
        id="pulse"
        variant="outlined"
        fullWidth
        size="small"
        label={formBasicMedicalHistory?.pulse ? "Pulse(bpm)" : "Pulse(bpm) *"}
        error={errorsBasicMedicalHistory?.pulse}
        helperText={errorsBasicMedicalHistory?.pulse}
        value = {formBasicMedicalHistory.pulse || ""}
        onChange={(e) => {
          onChangeBasicMedicalHistory({
            name: "pulse",
            value: e.target.value,
          });
        }}
      />
    </Grid>
    <Grid item xs={6}>
      {" "}
      <TextField
        id="temperature"
        variant="outlined"
        fullWidth
        size="small"
        label={
          formBasicMedicalHistory?.temperature
            ? "Temperature(°F)"
            : "Temperature(°F) *"
        }
        error={errorsBasicMedicalHistory?.temperature? true:false}
        helperText={errorsBasicMedicalHistory?.temperature}
        value = {formBasicMedicalHistory.temperature || ""}
        onChange={(e) => {
          onChangeBasicMedicalHistory({
            name: "temperature",
            value: e.target.value,
          });
        }}
      />
    </Grid>
      </Grid>
    </Box>

      <p className="text-2xl font-bold text-center mb-5 mt-10 ">
        Anthropometry
      </p>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {" "}
            <TextField
              id="heightFts"
              variant="outlined"
              fullWidth
              size="small"
              type="number"
              label={
                formBasicMedicalHistory?.heightFts
                  ? "Height(in Fts)"
                  : "Height(in Fts) *"
              }
              error={errorsBasicMedicalHistory.heightFts? true:false}
              helperText={errorsBasicMedicalHistory?.heightFts}
              value = {formBasicMedicalHistory.heightFts || ""}
              onChange={(e) => {
                onChangeBasicMedicalHistory({
                  name: "heightFts",
                  value: e.target.value,
                });
              }}
            />
          </Grid>{" "}
          <Grid item xs={6}>
            {" "}
            <TextField
              id="heightInches"
              variant="outlined"
              fullWidth
              size="small"
              type="number"
              label={
                formBasicMedicalHistory?.heightInches
                  ? "Height(in inches)"
                  : "Height(in inches) *"
              }
              error={errorsBasicMedicalHistory?.heightInches? true:false}
              helperText={errorsBasicMedicalHistory?.heightInches}
              value = {formBasicMedicalHistory.heightInches || ""}
              onChange={(e) => {
                onChangeBasicMedicalHistory({
                  name: "heightInches",
                  value: e.target.value,
                });
              }}
            />
          </Grid>{" "}
          <Grid item xs={6}>
            {" "}
            <TextField
              id="weight"
              variant="outlined"
              fullWidth
              size="small"
              type="number"
              label={
                formBasicMedicalHistory?.weight
                  ? "Weight(in kg)"
                  : "Weight(in kg) *"
              }
              error={errorsBasicMedicalHistory?.weight? true:false}
              helperText={errorsBasicMedicalHistory?.weight}
              value = {formBasicMedicalHistory.weight || ""}
              onChange={(e) => {
                onChangeBasicMedicalHistory({
                  name: "weight",
                  value: e.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <div className="p-2 flex text-xl justify-end 	text-center">
        <span className="text-lime-600	">Body Mass Index (BMI) = </span>
        <span className="text-lime-600 flex	"> {bmi}</span>
      </div>

      <p className="text-2xl text-center mb-5 mt-10 font-bold ">
        Eye And Vision
      </p>
      <p className="text-2xl  mb-5 mt-10 ">Color Vision</p>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              disablePortal
              id="leftEye"
              options={["Intact", "Defective"]}
              fullWidth
              isOptionEqualToValue={(option, value) => option.value === value.value}
              size="small"
              onChange={(event, newValue) => {
                onChangeBasicMedicalHistory({
                  name: "leftEye",
                  value: newValue,
                });
              }}
              value = {formBasicMedicalHistory.leftEye || ""}
                 
              renderInput={(params) => (
                <TextField
                  error={errorsBasicMedicalHistory.leftEye}
                  helperText={errorsBasicMedicalHistory.leftEye}
                  value = {formBasicMedicalHistory.leftEye || ""}
                  {...params}
                  label={
                    formBasicMedicalHistory.leftEye
                      ? "Left Eye"
                      : "Left Eye" + " *"
                  }
                />
              )}
            />
            
          </Grid>{" "}
          <Grid item xs={6}>
            <Autocomplete
              disablePortal
              id="rightEye"
              options={["Intact", "Defective"]}
              fullWidth
              size="small"
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(event, newValue) => {
                onChangeBasicMedicalHistory({
                  name: "rightEye",
                  value: newValue,
                });
              }}
              value = {formBasicMedicalHistory.rightEye || ""}
              renderInput={(params) => (
                <TextField
                  error={errorsBasicMedicalHistory.rightEye? true:false}
                  helperText={errorsBasicMedicalHistory.rightEye}
                  value = {formBasicMedicalHistory.rightEye || ""}
                  {...params}
                  label={
                    formBasicMedicalHistory.rightEye
                      ? "Right Eye"
                      : "Right Eye" + " *"
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <p className="text-2xl mb-5 mt-6 ">Visual Acquity</p>
      <p className="text-xl mb-3 mt-6 text-stone-500">Left eye</p>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {" "}
            <TextField
              id="farvisionLeftEye"
              variant="outlined"
              fullWidth
              size="small"
              label={
                formBasicMedicalHistory?.farvisionLeftEye
                  ? "Far vision"
                  : "Far vision *"
              }
              error={errorsBasicMedicalHistory?.farvisionLeftEye? true:false}
              helperText={errorsBasicMedicalHistory?.farvisionLeftEye}
              value = {formBasicMedicalHistory.farvisionLeftEye || ""}
              onChange={(e) => {
                onChangeBasicMedicalHistory({
                  name: "farvisionLeftEye",
                  value: e.target.value,
                });
              }}
            />
          </Grid>{" "}
          <Grid item xs={6}>
            {" "}
            <TextField
              id="nearvisionLeftEye"
              variant="outlined"
              fullWidth
              size="small"
              label={
                formBasicMedicalHistory?.nearvisionLeftEye
                  ? "Near vision"
                  : "Near vision *"
              }
              error={errorsBasicMedicalHistory?.nearvisionLeftEye? true:false}
              helperText={errorsBasicMedicalHistory?.nearvisionLeftEye}
              value = {formBasicMedicalHistory.nearvisionLeftEye || ""}
              onChange={(e) => {
                onChangeBasicMedicalHistory({
                  name: "nearvisionLeftEye",
                  value: e.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <p className="text-xl mb-3 mt-6 text-stone-500">Right eye</p>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {" "}
            <TextField
              id="farvisionRightEye"
              variant="outlined"
              fullWidth
              size="small"
              label={
                formBasicMedicalHistory?.farvisionRightEye
                  ? "Far vision"
                  : "Far vision *"
              }
              error={errorsBasicMedicalHistory?.farvisionRightEye? true:false}
              helperText={errorsBasicMedicalHistory?.farvisionRightEye}
              value = {formBasicMedicalHistory.farvisionRightEye || ""}
              onChange={(e) => {
                onChangeBasicMedicalHistory({
                  name: "farvisionRightEye",
                  value: e.target.value,
                });
              }}
            />
          </Grid>{" "}
          <Grid item xs={6}>
            {" "}
            <TextField
              id="nearvisionRightEye"
              variant="outlined"
              fullWidth
              size="small"
              label={
                formBasicMedicalHistory?.nearvisionRightEye
                  ? "Near vision"
                  : "Near vision *"
              }
              error={errorsBasicMedicalHistory?.nearvisionRightEye? true:false}
              helperText={errorsBasicMedicalHistory?.nearvisionRightEye}
              value = {formBasicMedicalHistory.nearvisionRightEye || ""}
              onChange={(e) => {
                onChangeBasicMedicalHistory({
                  name: "nearvisionRightEye",
                  value: e.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <p className="text-xl mb-3 mt-6 text-stone-500">Both eye</p>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {" "}
            <TextField
              id="farvisionBothEye"
              variant="outlined"
              fullWidth
              size="small"
              label={
                formBasicMedicalHistory?.farvisionBothEye
                  ? "Far vision"
                  : "Far vision *"
              }
              error={errorsBasicMedicalHistory?.farvisionBothEye? true:false}
              helperText={errorsBasicMedicalHistory?.farvisionBothEye}
              value = {formBasicMedicalHistory.farvisionBothEye || ""}
              onChange={(e) => {
                onChangeBasicMedicalHistory({
                  name: "farvisionBothEye",
                  value: e.target.value,
                });
              }}
            />
          </Grid>{" "}
          <Grid item xs={6}>
            {" "}
            <TextField
              id="nearvisionBothEye"
              variant="outlined"
              fullWidth
              size="small"
              label={
                formBasicMedicalHistory?.nearvisionBothEye
                  ? "Near vision"
                  : "Near vision *"
              }
              error={errorsBasicMedicalHistory?.nearvisionBothEye? true:false}
              helperText={errorsBasicMedicalHistory?.nearvisionBothEye}
              value = {formBasicMedicalHistory.nearvisionBothEye || ""}
              onChange={(e) => {
                onChangeBasicMedicalHistory({
                  name: "nearvisionBothEye",
                  value: e.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <p className="text-xl mb-3 mt-6 text-stone-500">Comment</p>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="comment"
              label="Comment"
              multiline
              rows={3}
              fullWidth
              error={errorsBasicMedicalHistory?.vaComment? true:false}
              helperText={errorsBasicMedicalHistory?.vaComment}
              value = {formBasicMedicalHistory.vaComment || ""}
              onChange={(e) => {
                onChangeBasicMedicalHistory({
                  name: "vaComment",
                  value: e.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default BasicMedicalHistory;
