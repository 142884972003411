import React from "react";
import View from "../../../components/Hijama/Videos/View/View";
const ViewVideos = () => {
  return (
    <div>
      <View />
    </div>
  );
};

export default ViewVideos;
