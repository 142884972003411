import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import axios from "axios";
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import Loader from "../../assets/loader.gif";
import { pdfTypes, userTypes } from "../../constants/ApiConstant/databaseConstant";
import { BASE_URL, endPoint } from "../../constants/ApiConstant/routeConstant";
import { useToken } from "../../services/useAuth";
import { AppointmentTableToPrint } from "./Appointment/AppointmentTable/AppointmentTable";
import { DoctorDetailsPrint } from "./Doctor/DoctorDetails/DoctorDetailsInfo";
import { DoctorTableToPrint } from "./Doctor/DoctorTable/doctorTable";
import { InstituteDetailsPrint } from "./HealthCare/Institution/InstitutionDetailsPDF/InstituteDetailsPrint";
import { InstituteTableToPrint } from "./HealthCare/Institution/InstitutionTablePDF/InstitutionTable";
import { StudentDetailsPrint } from "./HealthCare/Students/StudentDetails/StudentDetailsPrint";
import { StudentTableToPrint } from "./HealthCare/Students/StudentTable/StudentTable";
import { TeacherDetailsPrint } from "./HealthCare/Teachers/StudentDetails/TeacherDetailsPrint";
import { TeacherTableToPrint } from "./HealthCare/Teachers/StudentTable/TeacherTableToPrint";
import { HijamaUserDetailsPrint } from "./Hijama/HijamaUserDetails/HijamaUserDetailsInfo";
import { HijamaUserTableToPrint } from "./Hijama/HijamaUserTable/hijjamaUserTable";
import { OlympiadUserDetailsPrint } from "./Olympiad/OlympiadUserDetails/OlympiadUserDetailsInfo";
import { OlympiadUserTableToPrint } from "./Olympiad/OlympiadUserTable/olympiadUserTable";
import { PatientDetailsPrint } from "./Patient/PatientDetails/PatientDetailsInfo";
import { PatientTableToPrint } from "./Patient/PatientTable/patientTable";

const PDFScreen = () => { 
  const componentRef = useRef();
  const params = useParams();
  const userid = params.id;
  const proType = params.type;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const token = useToken()

  const { institutePdf,studentsPdf,doctorsPdf,patientsPdf,appointmentsPdf,olympiedUsersPdf,hijamaUsersPdf,singlePatientsPdf,singleAppointmentsPdf,singlePrescriptionsPdf,singleStudentsPdf } = useSelector((state) => state.tablePdf);
  //console.log(institutePdf);



  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const getBody =()=>{
    if(proType===pdfTypes.schoolAuthority){
      return {
        profileType:userTypes.schoolAuthority
      }
    }else if(proType===pdfTypes.schoolAuthorityList){
      return {
        profileType:userTypes.schoolAuthority
      }
    }else if(proType===pdfTypes.schoolHealthList){
      return {
        profileType:userTypes.schoolHealth
      }
    }else if(proType===pdfTypes.teacherList){
      return {
        profileType:userTypes.teacher
      }
    }else if(proType===pdfTypes.hijamaList){
      return {
        profileType:userTypes.hijama
      }
    }else if(proType===pdfTypes.olympiadList){
      return {
        profileType:userTypes.olympiad
      }
    }else if(proType===pdfTypes.doctorList){
      return {
        profileType:userTypes.doctor
      }
    }else if(proType===pdfTypes.telemedicineList){
      return {
        profileType:userTypes.telemedicine
      }
    }else if(proType===pdfTypes.appointmentList){
      return {
        
      }
    }else if(proType===pdfTypes.schoolHealth){
      return {
        profileType:userTypes.schoolHealth
      }
    }else if(proType===pdfTypes.teacher){
      return {
        profileType:userTypes.teacher
      }
    }else if(proType===pdfTypes.hijama){
      return {
        profileType:userTypes.hijama
      }
    }else if(proType===pdfTypes.olympiad){
      return {
        profileType:userTypes.olympiad
      }
    }else if(proType===pdfTypes.doctor){
      return {
        profileType:userTypes.doctor
      }
    }else if(proType===pdfTypes.telemedicine){
      return {
        profileType:userTypes.telemedicine
      }
    }else if(proType===pdfTypes.appointment){
      return {
        profileType:userTypes.appointment
      }
    }
  }


  const getData = async () => {
    ////console.log(`${BASE_URL}${endPoint.userGet}/${userid}`)

    setLoading(true)
    
    if(proType===pdfTypes.schoolAuthority || proType===pdfTypes.schoolHealth || proType===pdfTypes.teacher || proType===pdfTypes.hijama || proType===pdfTypes.olympiad || proType===pdfTypes.doctor || proType===pdfTypes.telemedicine  ){
      await axios
      .post(`${BASE_URL}${endPoint.userGet}/${userid}`,getBody(), header)
      .then((res) => {
        setLoading(false)

          if (res?.data?.success) {
            setData(res.data?.data)
          }
          
      })
      .catch((err) => {
        setLoading(false)

        setError(err.response.data.message)     
      });
    }else if(proType===pdfTypes.schoolAuthorityList || proType===pdfTypes.schoolHealthList || proType===pdfTypes.teacherList || proType===pdfTypes.hijamaList || proType===pdfTypes.olympiadList || proType===pdfTypes.doctorList || proType===pdfTypes.telemedicineList){
      await axios
      .post(`${BASE_URL}${endPoint.userGet}`,getBody(), header)
      .then((res) => {
        setLoading(false)

          if (res?.data?.success) {
            setData(res.data?.data)
          }
          
      })
      .catch((err) => {
        setLoading(false)

        setError(err.response.data.message)     
      });
    }else if(proType===pdfTypes.appointmentList ){
      await axios
      .post(`${BASE_URL}${endPoint.appointmentGet}`,getBody(), header)
      .then((res) => {
        setLoading(false)

          if (res?.data?.success) {
            setData(res.data?.data)
          }
          
      })
      .catch((err) => {
        setLoading(false)

        setError(err.response.data.message)     
      });
    }

  };
  //console.log(data)

  useEffect(() => {
    getData();
  }, []);

  const getComponetToPrint= ()=>{
    if(proType===pdfTypes.schoolAuthority  ){
      return <InstituteDetailsPrint ref={componentRef} profile={data} proType={proType} />
    }else if(proType===pdfTypes.schoolAuthorityList){
      return <InstituteTableToPrint ref={componentRef} data={institutePdf} proType={proType} />
    }else if(proType===pdfTypes.schoolHealth){
      return <StudentDetailsPrint ref={componentRef} profile={data} proType={proType} />
    }else if(proType===pdfTypes.schoolHealthList){
      return <StudentTableToPrint ref={componentRef} data={studentsPdf} proType={proType} />
    }else if(proType===pdfTypes.teacher){
      return <TeacherDetailsPrint ref={componentRef} profile={data} proType={proType} />
    }else if(proType===pdfTypes.teacherList){
      return <TeacherTableToPrint ref={componentRef} data={studentsPdf} proType={proType} />
    }else if(proType===pdfTypes.hijamaList){
      return <HijamaUserTableToPrint ref={componentRef} data={hijamaUsersPdf} proType={proType} />
    }else if(proType===pdfTypes.hijama){
      return <HijamaUserDetailsPrint ref={componentRef} profile={data} proType={proType} />
    }else if(proType===pdfTypes.olympiadList){
      return <OlympiadUserTableToPrint ref={componentRef} data={olympiedUsersPdf} proType={proType} />
    }else if(proType===pdfTypes.olympiad){
      return <OlympiadUserDetailsPrint ref={componentRef} profile={data} proType={proType} />
    }else if(proType===pdfTypes.doctorList){
      return <DoctorTableToPrint ref={componentRef} data={doctorsPdf} proType={proType} />
    }else if(proType===pdfTypes.doctor){
      return <DoctorDetailsPrint ref={componentRef} profile={data} proType={proType} />
    }else if(proType===pdfTypes.telemedicineList){
      return <PatientTableToPrint ref={componentRef} data={patientsPdf} proType={proType} />
    }else if(proType===pdfTypes.telemedicine){
      return <PatientDetailsPrint ref={componentRef} profile={data} proType={proType} />
    }else if(proType===pdfTypes.appointmentList){
      return <AppointmentTableToPrint ref={componentRef} data={appointmentsPdf} proType={proType} />
    }
  }


  return (
    <>
    {data ? (
      <div style={{
        width: "100%",
        margin: "0 auto ",
        "@media (max-width: 768px)": {
          width: "100% ",
          margin: "0 auto ",
        },
      }
      }>
      <ReactToPrint
      documentTitle={Date.now()}
        trigger={() => <button className="commonbtn my-3 whitespace-nowrap text-left xl:text-center xl:py-2 overflow-hidden d-block">
        <PictureAsPdfIcon className=" xl:mr-3" />  Download 
      </button>}
        content={() => componentRef.current}
      />
      {getComponetToPrint()}
    </div>
    ):
    (
      <div style={{display:"flex",justifyContent:'center',alignItems:'center'}}>
      <img src={Loader} />
      </div>
    )
  }
    </>

  );
}

export default PDFScreen