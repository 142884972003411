import React, { useEffect, useState } from "react";
import { BsPeople } from "react-icons/bs";
import { FaSchool } from "react-icons/fa";
import { userTypes } from "../../constants/ApiConstant/databaseConstant";
import { useGetDashboardData } from "../../services/api";
import AppDashboardCard from "../ui/AppDashboardCard";

const HealthCareDashboard = () => {
  const [dashboardData, setDashboardData] = useState({});
  const [, setIsLoading] = useState(false);

  const body = {
    type: userTypes.superAdmin,
  };
  const { getSADashboardData } = useGetDashboardData(body);

  useEffect(() => {
    setIsLoading(true);
    getSADashboardData()
      .then((res) => {
        if (res) {
          setDashboardData(res);
          setIsLoading(false);
        }
      })
      .catch((err) => console.log(err));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-20 xl:mt-10 mx-5 xl:mx-0">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 ">
        <AppDashboardCard
          title="Total Institutions"
          value={dashboardData.totalInstitutes}
          viewLink="/healthcare/institutions/view"
          bgColor="bg-violet-50"
          textColor="text-violet-600"
          icon={<FaSchool className="text-5xl text-violet-600" />}
        />

        <AppDashboardCard
          title="Total Students"
          value={dashboardData.totalStudents}
          viewLink="/healthcare/students/view"
          bgColor="bg-amber-50"
          textColor="text-amber-600"
          icon={<BsPeople className="text-5xl text-amber-600" />}
        />
      </div>
    </div>
  );
};

export default HealthCareDashboard;
