export const BASE_URL = "https://api.adminomphd.online";
//export const BASE_URL = "http://localhost:5000";
export const endPoint={
       common:"/api/user/common",
       mobile:"/api/user/common",
       user:"/api/user",
       dashboard:"/api/admin/dashboard",
       userGet:"/api/user/get",
       userEdit:"/api/user/edit",
       uploadDoc:"/api/user/uploadDocs",
       uploadDocGet:"/api/user/uploadDocs",
       uploadDocTeacher:"/api/user/uploadDocs/teacher",
       uploadDocTeacherGet:"/api/user/uploadDocs/teacher",
       login:"/api/user/login",
       forgetPassword:"/api/user/forgetpass",
       deleteUser:"/api/user/delete",
       appointment:"/api/tele/appointment",
       appointmentGet:"/api/tele/appointment/get",
       appointmentEdit:"/api/tele/appointment",
       appointmentDelete:"/api/tele/appointment",
       prescription:"/api/tele/prescription",
       prescriptionGet:"/api/tele/prescription/get",
       prescriptionEdit:"/api/tele/prescription",
       prescriptionDelete:"/api/tele/prescription",
       patientsGet:"/api/tele/patient",
       event:"/api/olym/event",
       eventGet:"/api/olym/event",
       eventEdit:"/api/olym/event",
       eventDelete:"/api/olym/event",
       eventReg:"/api/olym/event/reg",
       eventRegGet:"/api/olym/event/reg/get",
       notice:"/api/olym/notice",
       noticeGet:"/api/olym/notice/get",
       noticeEdit:"/api/olym/notice",
       noticeDelete:"/api/olym/notice",
       video:"/api/hijama/video",
       videoGet:"/api/hijama/video",
       videoEdit:"/api/hijama/video",
       videoDelete:"/api/hijama/video",
       videoWatch:"/api/hijama/videoWatch",
       videoWatchGet:"/api/hijama/videoWatch",
       hijamaUserUpdate:"/api/hijama/user",
       quiz:"/api/hijama/quiz",
       quizGet:"/api/hijama/quiz",
       quizEdit:"/api/hijama/quiz",
       quizDelete:"/api/hijama/quiz",
       response:"/api/hijama/response",
       responseGet:"/api/hijama/response",
       uploadImg:"/api/upload/image",
       uploadPdf:"/api/upload/pdf",
       search:"/api/search",
       filter:"/api/search/filter",
       notificationGet:"/api/noti/get",
       userPassUpdate:"/api/user/passdadmin",
       updateVariant:"/api/noti",
       checkNoti:"/api/noti/checknoti",
}