import React from 'react';
import { styles } from '../../../Common/Constant';
import MyTable from '../../../Common/Table';

// function createData(No, Class, Gender, TotalStudents) {
//   return {No, Class, Gender, TotalStudents };
// }
// const StyledTableCell = styled(TableCell)({
//   padding: '3px !important',
//   fontFamily:'Noto Serif Bengali !important',
//   border:'1px solid black !important'
// })

const ClassInfo =  ({profile}) => {

  // const tableData = profile.classes?.map((item,index)=>{
  //    return createData(index+1,item.ins_class_name,item.ins_class_gender,item.total_student)
  // })

  const headerData = ['NO','Class','Gender','Total Student']

  const bodyData =profile.classes.map(cls => {
    return [cls.ins_class_name,cls.ins_class_gender,cls.total_student]
  } )
  //console.log(bodyData)

  
  
 
  return (
      <div style={styles.innerContainer}>
        <div style={styles.sectionContainer}>
          <h4 style={styles.subHeader}> Class Information </h4>
          <div style={{display:'flex', flexDirection:'row',justifyContent:'center',alignItems:'center',}}>

          <div style={{flex:'0.8'}}>
          
     {
         //  <ul style={{flexDirection:'column',marginLeft: '1.5rem'}}>
     
     
     
         //  {profile.classes && profile.classes.map((item)=> (
         //   <li style={styles.list}>
         //   <div style={{display:'flex',flexDirection:'row'}} >
         //   <h4 style={styles.tag}> Class </h4>
         //   <h4 style={styles.value}> {item.ins_class_name}  </h4>
     
         //   <h4 style={styles.tag}> Gender </h4>
         //   <h4 style={styles.value}> {item.ins_class_gender}  </h4>
     
         //   <h4 style={styles.tag}> Total Students </h4>
         //   <h4 style={styles.value}> {item.total_student}  </h4>
     
           
         //   </div>
         //   </li>
         //  ))}
     
         //  </ul>
     
     }
     
     {
       profile.classes.length>0 ? (
    //      <TableContainer component={Paper} sx={{marginTop:'1rem',marginBottom:'1rem',borderRadius:'0 !important'}} >
    //  <Table  aria-label="simple table" >
    //    <TableHead  >
    //      <TableRow > 
    //        <StyledTableCell align="center" style={{fontWeight:700}} >NO</StyledTableCell>
    //        <StyledTableCell align="center" style={{fontWeight:700}} >Class</StyledTableCell>
    //        <StyledTableCell align="center" style={{fontWeight:700}} >Gender</StyledTableCell>
    //        <StyledTableCell align="center" style={{fontWeight:700}} >Total Students</StyledTableCell>
    //      </TableRow>
    //    </TableHead>
    //    <TableBody>
    //      {tableData.map((row) => (
    //        <TableRow
    //          key={row.No}
    //         >
    //          <StyledTableCell align="center"  >
    //            #{row.No}
    //          </StyledTableCell>
    //          <StyledTableCell align="center"  >{row.Class}</StyledTableCell>
    //          <StyledTableCell align="center">{row.Gender}</StyledTableCell>
    //          <StyledTableCell align="center">{row.TotalStudents}</StyledTableCell>
    //        </TableRow>
    //      ))}
    //    </TableBody>
    //  </Table>
    //  </TableContainer>
      <MyTable 
      headersData={headerData}
      bodyData = {bodyData}
      />
       ) : <h3 style={{textAlign:'center'}}> No Class found!</h3>
     }
      
          </div>
     
        </div>
      </div>
 
    
    
     </div>


 
   
  );
};

export default ClassInfo