import React from 'react';
import View from '../../../components/Telemedicine/Doctors/View/View';

const ViewDoctorsPage = () => {
  return <div>
    <View/>
  </div>;
};

export default ViewDoctorsPage;
