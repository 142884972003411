export const tableConvertedData = (res) => {
  let data = [];
  res?.map((itm) => {
    data.push({
      userId: itm?.user_id || "--",
      userPhoneId: itm?.user_phone_id || "--",
      userName: itm?.user_name || "--",
      userImg: itm?.user_img || "--",
      userSchlHealth: itm?.user_schl_health || "--",
      userTelemedicine: itm?.user_telemedicine || "--",
      userOlymp: itm?.user_olymp || "--",
      userHijama: itm?.user_hijama || "--",
      userAdminType: itm?.user_admin_type || "--",
      insId: itm?.ins_id || "--",
      uId: itm?.u_id || "--",
      insType: itm?.ins_type || "--",
      insName: itm?.ins_name || "--",
      insEstYear: itm?.ins_est_year || "--",
      insRegNo: itm?.ins_reg_no || "--",
      insPrinciple: itm?.ins_principle || "--",
      insDivision: itm?.ins_division || "--",
      insDistrict: itm?.ins_district || "--",
      insUpozila: itm?.ins_upozila || "--",
      insRoadNo: itm?.ins_road_no || "--",
      insHouseNo: itm?.ins_house_no || "--",
      insServiceType: itm?.ins_service_type || "--",
      insContactStart: itm?.ins_contact_start || "--",
      insContactEnd: itm?.ins_contact_end || "--",
      insTotalStudents: itm?.ins_total_students || "--",
      insFpSNo: "ট-" + (itm?.ins_fp_s_no || "--"),
      insFpSRate: "ট-" + (itm?.ins_fp_s_rate || "--"),
      insFpSAmount: "ট-" + (itm?.ins_fp_s_amount || "--"),
      insFpTNo: "ট-" + (itm?.ins_fp_t_no || "--"),
      insFpTRate: "ট-" + (itm?.ins_fp_t_rate || "--"),
      insFpTAmount: "ট-" + (itm?.ins_fp_t_amount || "--"),
      insDsSNo: itm?.ins_ds_s_no || "--",
      insDsTNo: itm?.ins_ds_t_no || "--",
      insDsRate: (itm?.ins_ds_rate || "--") + " %",
      insDsAmount: "ট-" + (itm?.ins_ds_amount || "--"),
      insFsSNo: itm?.ins_fs_s_no || "--",
      insFsTNo: itm?.ins_fs_t_no || "--",
      insSmTotalPatients: itm?.ins_sm_total_patients || "--",
      insSmTotalAmount: "ট-" + (itm?.ins_sm_total_amount || "--"),
      insSmPerHead: itm?.ins_sm_per_head || "--",
      insServiceStatus: itm?.ins_service_status || "--",
      classes: itm?.classes || "--",
    });
  });

  return data;
};
