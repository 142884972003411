import React from "react";
import { Paper, Typography, Box } from "@mui/material";
import FreeServices from "./FreeServices";

const DiscountInfo = ({ authorityInfo }) => {
  const { ins_ds_s_no, ins_ds_t_no, ins_ds_rate, ins_ds_amount } =
    authorityInfo;
  return (
    <Paper sx={{ borderRadius: "12px", p: 4 }}>
      <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
        Discount Services
      </Typography>

      <Box sx={{ mt: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Box sx={{ width: "50%" }}>
            <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
              Students No.
            </Typography>
            <Typography sx={{ fontWeight: "400", fontSize: 16, mb: 2 }}>
              {ins_ds_s_no}
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
              Teachers No.
            </Typography>
            <Typography sx={{ fontWeight: "400", fontSize: 16, mb: 2 }}>
              {ins_ds_t_no}
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
              Discount Rate
            </Typography>
            <Typography sx={{ fontWeight: "400", fontSize: 16, mb: 2 }}>
              {ins_ds_rate}
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
              Total Discount Amount
            </Typography>
            <Typography sx={{ fontWeight: "400", fontSize: 16, mb: 2 }}>
              {ins_ds_amount}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* <FreeServices authorityInfo={authorityInfo} /> */}
    </Paper>
  );
};

export default DiscountInfo;
