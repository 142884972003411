import HealthRecord from "./HealthRecord";

export default function HealthRecordsContainer({ singleStudents }) {

  return (
    <>
      {singleStudents?.healthRecords?.map((singleHealthRecord) => (
        <HealthRecord
          singleHealthRecord={singleHealthRecord}
          singleStudents={singleStudents}
        />
      ))}
    </>
  );
}