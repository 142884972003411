import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsCardChecklist } from "react-icons/bs";
import {
  pdfTypes,
  userTypes,
} from "../../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint,
} from "../../../../constants/ApiConstant/routeConstant";
import { institutePdfFnc } from "../../../../redux/slices/tableDataForPdfSlice";
import { useDispatch, useSelector } from "../../../../redux/store";
import AppTable from "../../../ui/AppTable";
import { DynamicTableDataForHealthcareInst } from "../../../ui/helpers/table/TableBodyData";
import { tableHeadInstitutionsHeaderData } from "../../../ui/helpers/table/TableHeadeData";
import { InstSearchOptions } from "../../../ui/helpers/table/TableSearchOptions";
import { useConvertedTableData } from "../../../ui/helpers/table/useConvertedTableData";
import { tableConvertedData } from "./helper/convertedInstitutionData";
import FilterData from "./helper/FilterData";

export const InstData = {
  insType: "",
  division: "",
  district: "",
};

const SAInstitutionsView = () => {
  const { token } = useSelector((state) => state.userInfo);
  const { institutePdf } = useSelector((state) => state.tablePdf);
  const dispatch = useDispatch();
  const { deleteSuccess } = useSelector((state) => state.modal);
  const [loading, setLoading] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [, setSearchKey] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [clearFilter, setClearFilter] = useState(false);
  const [instFilter, setInstFilter] = useState(InstData);
  const limit = 6;

  const { tableData, pageCount, handlePageClick } = useConvertedTableData(
    institutions,
    limit
  );

  // //console.log(tableData)
  //console.log(institutePdf);
  const getAllInstitutionsData = async () => {
    const body = {
      profileType: userTypes.schoolAuthority,
    };

    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    await axios
      .post(`${BASE_URL}${endPoint.userGet}`, body, header)
      .then((res) => {
        if (res?.data?.success) {
          setInstitutions(tableConvertedData(res?.data?.data));
          setLoading(false);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const getAllInstitutionsSearchedData = async () => {
    if (!(searchValue === "")) {
      const body = {
        type: userTypes.schoolAuthority,
        data: searchValue,
      };

      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);
      await axios
        .post(`${BASE_URL}${endPoint.search}`, body, header)
        .then((res) => {
          if (res?.data?.success) {
            setInstitutions(tableConvertedData(res?.data?.data));
            setLoading(false);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    } else {
      getAllInstitutionsData();
    }
  };

  const handleFilter = async () => {
    if (
      instFilter.insType === "" &&
      instFilter.division === "" &&
      instFilter.district === ""
    ) {
      getAllInstitutionsData();
    } else {
      const body = {
        type: userTypes.schoolAuthority,
        data: instFilter,
      };

      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);
      await axios
        .post(`${BASE_URL}${endPoint.filter}`, body, header)
        .then((res) => {
          if (res?.data?.success) {
            setInstitutions(tableConvertedData(res?.data?.data));
            setLoading(false);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };

  const handleDeleteUser = async ( userId, id) => {
    const body = {
      profileType:  userTypes.schoolAuthority,
      userId: userId,
      id: id,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios
      .post(`${BASE_URL}${endPoint.deleteUser}`, body, header)
      .then((res) => {
        //console.log(res.data.message);
        return res.data.message;
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    if (institutions) {
      dispatch(institutePdfFnc(institutions));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [institutions, searchValue]);

  useEffect(() => {
    getAllInstitutionsData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess, clearFilter]);

  useEffect(() => {
    getAllInstitutionsSearchedData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess, searchValue]);

  return (
    <div className="3xl:-mr-10 mx-5 mt-20 xl:mx-0 xl:mt-0">
      <AppTable
        // table title information
        tableTitle="Institution Information" // mandatory
        titleIcon={<BsCardChecklist />} // mandatory
        titleClass="font-bold" // mandatory
        // search and filter
        search
        searchOption={InstSearchOptions}
        searchPlaceHolder="Search by institute name and id"
        searchVal={(val) => setSearchValue(val)}
        searchKey={(key) => setSearchKey(key)}
        filter
        pdf={`/pdfview/${pdfTypes.schoolAuthorityList}`}
        filterBox={
          <FilterData
            instFilter={instFilter}
            setInstFilter={setInstFilter}
            handleFilter={handleFilter}
            setClearFilter={setClearFilter}
            clearFilter={clearFilter}
          />
        }
        handleDelete={handleDeleteUser}
        // table Information
        tableHeadData={tableHeadInstitutionsHeaderData} // mandatory
        tableBodyData={tableData} // mandatory
        dynamicTable={
          (colItem, headItem, index, shadow, handleDelete) =>
            DynamicTableDataForHealthcareInst(
              colItem,
              headItem,
              index,
              shadow,
              handleDelete
            ) // mandatory
        }
        loader={loading} // mandatory
        // pagination
        pagination
        pageCount={pageCount}
        handlePageClick={handlePageClick}
      />
    </div>
  );
};

export default SAInstitutionsView;
