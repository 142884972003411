import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAdminRole } from "../../services/useAdminRole";
import { routes } from "../../helpers/AllPaths";

const Sidebar = ({ setSubMenu }) => {

  const { roleData } = useAdminRole();

  const location = useLocation();
  const navigate = useNavigate();

  const [borderClass, setBorderClass] = useState("/dashboard");

  useEffect(() => {
    setBorderClass(
      sessionStorage.getItem("url") === null
        ? "/dashboard"
        : sessionStorage.getItem("url")
    );
  }, [borderClass]);

  return (
    <>
    <div
      className={` ${
       routes.includes(location.pathname) ? "w-full" : "w-[8rem]"
      }  h-full border-r ml-2 mt-1  `}
    >
      {roleData?.map((Menu, index) => {
        return (
          <div
            key={index}
            className=" w-full h-[13%] border-b cursor-pointer flex items-center "
            onClick={() => {
              // setUrl(Menu.title)
              navigate(Menu?.url);
              setBorderClass(Menu?.url);
              setSubMenu(Menu.submenu);
              sessionStorage.setItem("url", Menu?.url);
            }}
          >
            <div
              className={`w-full h-[90%] ${
                borderClass === Menu?.url || borderClass === null
                  ? "border-l-orange-500 border-l-[6px] bg-orange-50"
                  : "border-l-[6px] border-l-white"
              } flex flex-col justify-center items-center `}
            >
              <div
                className={`text-3xl hover:text-orange-400  ${
                  borderClass === Menu?.url
                    ? "text-orange-400 "
                    : "text-gray-500"
                }`}
              >
                {Menu?.icon}
              </div>
              <p
                className={`text-sm font-sans mt-1 font-semibold hover:text-orange-400 ${
                  borderClass === Menu.url ? "text-orange-400 " : ""
                }`}
              >
                {Menu?.title}
              </p>
            </div>
          </div>
        );
      })}
    </div>
    </>
  );
};
export default Sidebar;
