import React from "react";
const CardView = ({ event }) => {
  return (
    <div
      // role="button"
      className=" flex  shadow-md rounded-md mx-[1rem] mb-[1.5rem] bg-slate-200  p-4 pl-8"
      // onClick={() => getSingleEventData(event?.e_id)}
    >
      <div className=" h-full flex items-center w-[80rem]">
        <div className="grid grid-cols-4 w-full">
          <div className="w-full flex flex-col justify-between">
            <div>
              <p className="text-sm font-sans text-gray-400 mt-1">Event ID</p>
              <p className="text-sm font-sans font-bold">{event.e_id}</p>
            </div>
            <div>
              <p className="text-sm font-sans text-gray-400 ">Event Name </p>
              <p className="text-sm font-sans font-bold">{event.e_title} </p>
            </div>
          </div>
          <div className="w-full flex flex-col justify-between col-span-2">
            <div className="flex mb-2">
              <div className="mr-[3rem]">
                <p className="text-sm font-sans text-gray-400 ">Venue</p>
                <p className="text-sm font-sans text-gray-800  font-bold">
                  {event.e_venue}
                </p>
              </div>
              <div>
                <p className="text-sm font-sans text-gray-400 ">Region</p>
                <p className="text-sm font-sans text-gray-800  font-bold">
                  {event.e_region_details}
                </p>
              </div>
            </div>
            <div>
              <p className="text-sm font-sans text-gray-400 ">Location</p>
              <p className="text-sm font-sans font-bold">
                {event.e_district}, {event.e_division}
              </p>
            </div>
          </div>

          <div className="bg-gray-100 flex items-center justify-between px-[3rem] h-full">
            <div className="flex flex-col">
              <p className="text-2xl font-bold font-sans text-orange-600">
                {event.e_date.slice(0, 10)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardView;
