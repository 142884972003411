export const tableConvertedData = (res) => {
  let data = [];
  res?.map((itm) => {
    data.push({
      id: itm?.user_id,
      pId: itm?.p_id || "--",
      pName: itm?.p_name || "--",
      pGender: itm?.p_gender || "--",
      pNumber: itm?.p_phone || "--",
      pDoB: itm?.p_dob || "--",
      pProfession: itm?.p_profession || "--",
      pAddress: itm?.p_address || "--",
      pEmail: itm?.p_email || "--",
      pNid: itm?.p_nid || "--",
      pBirthCft: itm?.p_birth_cft || "--",
    });
  });

  return data;
};
