import React, { useEffect, useState } from "react";
import { BsCardChecklist } from "react-icons/bs";
import { FaUserMd } from "react-icons/fa";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { userTypes } from "../../constants/ApiConstant/databaseConstant";
import { useGetDashboardData } from "../../services/api";
import AppDashboardCard from "../ui/AppDashboardCard";

const TelemedicineDashboard = () => {
  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const body = {
    type: userTypes.superAdmin,
  };
  const { getSADashboardData } = useGetDashboardData(body);

  useEffect(() => {
    setIsLoading(true);
    getSADashboardData()
      .then((res) => {
        if (res) {
          setDashboardData(res);
          setIsLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className="mt-20 xl:mt-10 mx-5 xl:mx-0">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        <AppDashboardCard
          title="Total Doctors"
          value={dashboardData.totalDoctors}
          viewLink="/telemedicine/doctors/view"
          bgColor="bg-teal-50"
          textColor="text-teal-600"
          icon={<FaUserMd className="text-5xl text-teal-600" />}
        />

        <AppDashboardCard
          title="Total Patients"
          value={dashboardData.totalPatients}
          viewLink="/telemedicine/patients"
          bgColor="bg-orange-50"
          textColor="text-orange-600"
          icon={<MdOutlinePersonalInjury className="text-5xl text-orange-600" />}
        />

        <AppDashboardCard
          title="Total Appointments"
          value={dashboardData.totalAppointments}
          viewLink="/telemedicine/appointments"
          bgColor="bg-fuchsia-50"
          textColor="text-fuchsia-600"
          icon={<BsCardChecklist className="text-5xl text-fuchsia-600" />}
        />

        <AppDashboardCard
          title="Total Prescriptions"
          value={dashboardData.totalPrescriptions}
          viewLink="/telemedicine/appointments"
          bgColor="bg-sky-50"
          textColor="text-sky-600"
          icon={<HiOutlineClipboardDocumentList className="text-5xl text-sky-600" />}
        />
      </div>
    </div>
  );
};

export default TelemedicineDashboard;
