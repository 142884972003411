import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { BsCardChecklist } from "react-icons/bs";
// import { searchTypes } from "../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint,
} from "../../../constants/ApiConstant/routeConstant";
import { useDispatch, useSelector } from "../../../redux/store";
import AppTable from "../../ui/AppTable";
import { DynamicTableDataForAppointments } from "../../ui/helpers/table/TableBodyData";
import { tableHeadAppointmentsData } from "../../ui/helpers/table/TableHeadeData";
import { useConvertedTableData } from "../../ui/helpers/table/useConvertedTableData";
import { tableConvertedData } from "./helper/convertedAppointmentData";
import { AppointmentSearchOptions } from "../../ui/helpers/table/TableSearchOptions";
import { singleAppointmentsPdfFnc } from "../../../redux/slices/tableDataForPdfSlice";
// import { number } from "yup";

const DAppointments = () => {
  const { token, userInfo } = useSelector((state) => state.userInfo);
  // const { singleAppointmentsPdf } = useSelector((state) => state.tablePdf);
  const dispatch = useDispatch();
  const [appointmentData, setAppointmentData] = useState([]);
  const [appointmentSearchData, setAppointmentSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchKey, setSearchKey] = useState();
  const limit = 6;

  
  const { tableData, pageCount, handlePageClick } =
    useConvertedTableData(appointmentData, limit);

  const getAllAppointmentssData = async () => {
    const body = {
      dId: userInfo?.doctor?.d_id,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    await axios
      .post(`${BASE_URL}${endPoint.appointmentGet}`, body, header)
      .then((res) => {
        if (res?.data?.message) {
          setAppointmentData(tableConvertedData(res?.data?.data));
          setAppointmentSearchData(tableConvertedData(res?.data?.data));
          setLoading(false);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  
  const getAllDAppointmentSearchedData = async () => {
    if (searchValue === "" || searchValue === 0) {
      getAllAppointmentssData();
    } else {
      const data =
        searchValue &&
        appointmentSearchData?.filter((col) => {
          if (isNaN(col[searchKey])) {
            return col[searchKey]
              ?.toString()
              .toLowerCase()
              .includes(searchValue?.toString().toLowerCase());
          } else {
            return col[searchKey] === searchValue;
          }
        });
      setAppointmentData(data);
    }
  };

  useEffect(() => {
    if (appointmentData) {
      dispatch(singleAppointmentsPdfFnc(appointmentData));
    }
  }, [appointmentData, searchValue]);

  useEffect(() => {
    getAllAppointmentssData();
  }, [searchKey]);

  useEffect(() => {
    getAllDAppointmentSearchedData();
  }, [searchValue]);

  return (
    <div className="3xl:-mr-10 mx-0 md:mx-5 mt-16 xl:-mr-16 xl:mx-0 xl:mt-0">
      <AppTable
        tableTitle="Appointments Information"
        titleIcon={<BsCardChecklist />}
        titleClass="font-bold"
        search
        searchType
        searchOption={AppointmentSearchOptions}
        searchVal={(val) => setSearchValue(val)}
        searchKey={(key) => setSearchKey(key)}
        tableHeadData={tableHeadAppointmentsData}
        tableBodyData={tableData}
        dynamicTable={(colItem, headItem, index, shadow) =>
          DynamicTableDataForAppointments(colItem, headItem, index, shadow)
        }
        loader={loading}
        pagination
        pageCount={pageCount}
        handlePageClick={handlePageClick}
      />
    </div>
  );
};

export default DAppointments;
