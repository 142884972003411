import { Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";
import { divisions } from "../../../../../constants/GeoLocation/geoLocation";
import { getLocation } from "../../../../../utils/AddressFunctions/getLocation";

const ServiceLocation = ({
  onChangeServiceLocation,
  errorsServiceLocation,
  formServiceLocation,
}) => {
  const [districtData, setDistrictData] = useState();

  const handleSelectedDivision = (param) => {
    onChangeServiceLocation({ name: "division", value: param });
    const divId = divisions.filter((item) => item.name === param);
    const location = getLocation(divId[0].id);
    setDistrictData(location);
    //console.log(divId[0].id);
  };

  return (
    <div
      className={` "block"
      rounded-xl bg-[#ffffff] p-5 font-poppins`}
    >
      <form>
        <div className=" grid md:grid-cols-2 grid-cols-1  gap-3 px-2  ">
          <Autocomplete
            disablePortal
            id="division"
            options={divisions.map((div) => div.name)}
            fullWidth
            size="small"
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            value={formServiceLocation?.division || null}
            onChange={(event, newValue) => {
              handleSelectedDivision(newValue);
            }}
            renderInput={(params) => (
              <TextField
                error={errorsServiceLocation?.division ? true : false}
                helperText={errorsServiceLocation?.division}
                {...params}
                label={
                  formServiceLocation?.division ? "Division" : "Division" + " *"
                }
              />
            )}
          />
          <Autocomplete
            disablePortal
            id="district"
            options={districtData ? districtData.map((dist) => dist.name) : []}
            fullWidth
            size="small"
            value={formServiceLocation?.district || null}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(event, newValue) => {
              onChangeServiceLocation({ name: "district", value: newValue });
            }}
            renderInput={(params) => (
              <TextField
                error={errorsServiceLocation?.district ? true : false}
                helperText={errorsServiceLocation?.district}
                {...params}
                label={
                  formServiceLocation?.district ? "District" : "District" + " *"
                }
              />
            )}
          />

        </div>
      </form>
    </div>
  );
};
export default ServiceLocation;
