import React, { useEffect, useState } from "react";
import { FiSettings } from "react-icons/fi";
import { FaRegUserCircle } from "react-icons/fa";
import { BsBoxArrowRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../redux/slices/userInfoSlice";
import { useDispatch, useSelector } from "../../redux/store";
import AppDropdown from "../ui/AppDropdown";
import Loader from "../ui/Loader";
import img from "../../assets/profile-img.png";
import { BASE_URL } from "../../constants/ApiConstant/routeConstant";

const ProfileDropdown = () => {
  const [isShow, setIsShow] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo, role } = useSelector((state) => state.userInfo);

  const handleLogout = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
      setIsShow(false);
      dispatch(logout(true));
      navigate(`/admin`);
    }, 100);
  };

  const getProfileRoute = () => {
    if (role === "superAdmin") {
      return "/settings";
    }
    if (role === "schoolAuthority") {
      return "/school-authority-profile";
    }
    if (role === "doctor") {
      return "/doctor-profile";
    }
  };

  const getSettingsRoute = () => {
    if (role === "superAdmin") {
      return "About";
    }
    if (role === "schoolAuthority") {
      return "Settings";
    }
  };

  useEffect(() => {
    if (role === "superAdmin") {
      setIsProfile(false);
    } else {
      setIsProfile(true);
    }
  }, [role]);

  return (
    <div>
      <AppDropdown
        classes="min-w-[150px] origin-top-right top-14 right-4 "
        callback={(item) => setIsShow(item)}
        showDropdown={isShow}
        btnComponent={
          <div
            className=" text-2xl text-orange-400 flex items-center bg-orange-50 hover:bg-orange-100 rounded-3xl pl-6 "
            role="button"
            tabIndex={-1}
            onClick={() => {
              setIsShow(!isShow);
            }}
          >
            <div className="pl-4 pr-2">
              <p className=" text-sm text-right font-semibold font-serif">
                {userInfo?.user_name}
              </p>
              <p className=" capitalize text-xs text-right text-orange-400 font-semibold">
                ({userInfo?.user_admin_type})
              </p>
            </div>
            <div className="w-10 h-10 rounded-full m-1">
              <img
                src={
                  userInfo.user_img ? `${BASE_URL}/${userInfo.user_img}` : img
                }
                className="w-full h-full rounded-full"
                alt=""
              />
            </div>
          </div>
        }
        isShowFooter={true}
        footer={
          <div
            className="p-4 text-xs md:text-sm xl:text-base flex items-center border-t border-orange-100 border-solid hover:bg-orange-50"
            role="button"
            tabIndex={0}
            onClick={() => {
              handleLogout();
            }}
          >
            {loader ? (
              <>
                <div className="flex justify-center w-full">
                  <Loader />
                </div>
              </>
            ) : (
              <>
                <div>
                  <BsBoxArrowRight className="" />
                </div>
                <div className="ml-3">Log Out</div>
              </>
            )}
          </div>
        }
      >
        {isProfile === true ? (
          <Link
            to={getProfileRoute()}
            state={{ settingsRoute: getSettingsRoute() }}
          >
            <div
              className="flex items-center text-xs md:text-sm xl:text-base  py-3 pl-4 border-t border-orange-50 border-solid hover:bg-orange-50"
              role="button"
              tabIndex={0}
              onClick={() => {
                sessionStorage.setItem("url", getProfileRoute());
                setIsShow(!isShow);
              }}
            >
              <div>
                <FaRegUserCircle className=" text-xl text-gray-500" />
              </div>

              <div className="ml-3">
                <p>My Profile</p>
              </div>
            </div>
          </Link>
        ) : (
          <Link
            to={getProfileRoute()}
            state={{ settingsRoute: getSettingsRoute() }}
          >
            <div
              className="flex items-center text-xs md:text-sm xl:text-base py-3 pl-4 border-t border-orange-50 border-solid hover:bg-orange-50"
              role="button"
              tabIndex={0}
              onClick={() => setIsShow(!isShow)}
            >
              <div>
                <FiSettings className=" text-xl text-gray-500" />
              </div>

              <div className="ml-3">
                <p>Settings</p>
              </div>
            </div>
          </Link>
        )}
      </AppDropdown>
    </div>
  );
};

export default ProfileDropdown;
