import { Document, pdf, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL, endPoint } from '../../../../constants/ApiConstant/routeConstant';
import { useToken } from '../../../../services/useAuth';
import { notify } from '../../../ui/helpers/ToastMsg';
import PdfPage from './PdfPage';



const MyDocument = ({addPress}) => (


  <Document
  author={addPress?.appointment?.dName}
  subject="OMPHD"
  title="Prescription"
  >

 
  <PdfPage size="A4" addPress = {addPress} />
  

</Document>

);

export default function PDFContainer({addPress,setIsShow,setAddPress,IniData,setPdfScreen}) {
  //console.log("first render")
  //console.log(addPress)

  const [loading, setLoading] = useState();
  const token = useToken();
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  //   const addPrescription = async (link) => {
  //   const body = {...addPress,fileTitle:link};
    
  //   const header = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };
  //   await axios
  //     .post(`${BASE_URL}${endPoint.prescription}`, body, header)
  //     .then((res) => {
  //       setIsShow(false);
  //       notify(res?.data?.message, "success")
  //       //setAddPress(IniData)
  //     })
  //     .catch((err) => {
  //       //console.log(err.message);
  //     });
  // };

  const uploadPdf = async () => {

    //console.log("testing")

      const element = <MyDocument addPress={addPress} />;//my pdf component
      const myPdf = pdf([]);
      myPdf.updateContainer(element);
      const blob = await myPdf.toBlob();
      var file = new File([blob], "testpdf.pdf", {lastModified: (new Date()).getTime(),type:"application/pdf"});
      //console.log(file);

      //upload to server start

      const formData = new FormData();
      formData.append("pdf", file);
      try {
        setLoading(true);
        const url = `${BASE_URL}/api/upload/pdf`;
        const res = await axios.post(url, formData, headers);
        if (res.data.success) {
          //console.log(res.data?.data?.path)
          const link = res.data?.data?.path
         //await addPrescription(link)

            const body = {...addPress,fileTitle:link};
            
            const header = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            await axios
              .post(`${BASE_URL}${endPoint.prescription}`, body, header)
              .then((res) => {
                //setIsShow(false);
                notify(res?.data?.message, "success")
                setAddPress(IniData)
              })
              .catch((err) => {
                //console.log(err.message);
              });

        }
        setLoading(false);
      } catch (e) {
        //console.log(e);
        notify(e.response.data.message)
      }

      //upload to server end

  };

  useEffect(()=>{
    ////console.log("Effect")
    if(addPress?.appointment){
      uploadPdf()
    }
  },[])

  const downloadButton = <button
  style={{
    backgroundColor:'black',
    color:'white',
    marginBottom:10,
    padding:10,
    borderRadius:5
  }}
  >
   Download Now!
  </button>

  return (
    <div style={{ position: 'relative', height: '100%',width:"100%" }} >
    <PDFDownloadLink document={<MyDocument addPress={addPress} />} fileName={`${addPress?.appointment?.dName}-${Date.now()}`}>
    {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : downloadButton
    }
  </PDFDownloadLink>
  <div>
        <div>
          <PDFViewer  style={{ position: 'relative', height: '100vh',width:"100%" }} >
            <MyDocument addPress={addPress}/>
          </PDFViewer>
        </div>
      </div>
    </div>
  )
}