import React, { useEffect, useRef } from "react";

const AppDropdown = ({
  classes ,
  btnComponent,
  children,
  isShowFooter = false,
  footer,
  id,
  showDropdown,
  callback,
  dropDownPosition = "absolute",
}) => {
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (callback) {
          callback(false);
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      className={` z-40 relative `}
      ref={ref}
      id={`${id}`}
    >
      {btnComponent}
      <div
        id={`dropdown-${id}`}
        className={`dropdown ${dropDownPosition} overflow-y-auto ${classes}
         bg-white border border-[#ffeaa7]/[0.5] border-solid rounded-lg drop-shadow-xl
         transition ease duration-200
          ${
            showDropdown
              ? "scale-1 transition  duration-200 in-expo"
              : "scale-0"
          }`}
      >
        <div className="">{children}</div>
        {isShowFooter ? footer : ""}
      </div>
    </div>
  );
};

export default AppDropdown;
