import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AppSearchAndFilter from "./AppSearchAndFilter";
import AppTablePagination from "./AppTablePagination";
import Loader from "./Loader";

const AppTable = ({
  spacing = "mt-10",
  search = false,
  filter = false,
  searchType,
  searchOption,
  searchKey,
  searchPlaceHolder,
  searchVal,
  filterBox,
  titleIcon,
  titleClass,
  tableTitle,
  handleDelete,
  tableHeadData,
  tableBodyData,
  dynamicTable,
  width,
  loader,
  pagination,
  pageCount,
  handlePageClick,
  pdf,
}) => {
  const ref = useRef();
  const [columnShadow, setColumnShadow] = useState(false);

  useEffect(() => {
    return () => {
      if (ref.current) {
        ref.current.scrollLeft = 0;
      }
      columnShadow && setColumnShadow(false);
    };
  }, [ref, dynamicTable]);

  return (
    <div className={` z-0 bg-white w-full shadow-md rounded-xl ${spacing}`}>
      {/* Title & Search */}
      <div
        className={`flex lg:flex-row flex-col items-center ${
          (filter || search) && "justify-between"
        } lg:px-8 px-2 py-5`}
      >
        <div>
          <p
            className={`${titleClass}  flex items-center font-extrabold text-xl`}
          >
            <span className="mr-3">{titleIcon}</span>
            <span>{tableTitle}</span>
            {pdf && (
              <Link to={pdf} rel="noopener noreferrer">
                <SimCardDownloadIcon
                  style={{ color: "red", cursor: "pointer" }}
                  fontSize="large"
                />
              </Link>
            )}
          </p>
        </div>
        <div className="flex items-center mt-5 lg:mt-0">
          {search && (
            <AppSearchAndFilter
              searchType={searchType}
              searchOption={searchOption}
              searchKey={searchKey}
              searchVal={searchVal}
              searchPlaceHolder={searchPlaceHolder}
            />
          )}
          {filter && filterBox}
        </div>
      </div>

      {/* Table */}
      <div
        ref={ref}
        onScroll={(e) => setColumnShadow(!!e.currentTarget.scrollLeft)}
        className={`relative z-0 bg-white overflow-auto w-full `}
      >
        <table className={`min-h-[10rem] ${width ? width : "w-full "}`}>
          <thead className="">
            <tr className=" text-left text-xs">
              {tableHeadData?.map((item, index) => (
                <th
                  key={index}
                  className={`py-4 px-8 bg-blue-100 font-semibold uppercase ${
                    index === 0
                      ? `sticky left-0 z-30 before:absolute ${
                          columnShadow ? "drop-shadow-2xl" : ""
                        } `
                      : ""
                  }`}
                >
                  <p className={`${item.width ? item.width : ""}`}>
                    {item.title}
                  </p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!loader ? (
              <>
                {tableBodyData?.length === 0 && (
                  <div className="absolute w-full top-[50%] text-center block text-gray-500 text-xs">
                    Data is not available
                  </div>
                )}

                {tableBodyData?.length > 0 &&
                  tableBodyData?.map((item, index) => (
                    <tr key={index}>
                      {tableHeadData?.map((el) =>
                        dynamicTable(item, el, index, columnShadow, handleDelete)
                      )}
                    </tr>
                  ))}
              </>
            ) : (
              <div className="absolute w-full top-[50%]">
                <Loader variant="#3498db" />
              </div>
            )}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      {pagination && (
        <AppTablePagination
          pageCount={pageCount}
          handlePageClick={handlePageClick}
        />
      )}
    </div>
  );
};

export default AppTable;
