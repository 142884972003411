import { toast } from 'react-toastify';

export const notify = (msg,type) => {
  if(type==="success"){
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER
  });
  }else if(type==="danger"){
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER
    });
  }else if(type==="warning"){
    toast.warn(msg, {
      position: toast.POSITION.TOP_CENTER
    });
  }else{
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER
    });
  }
  
}