import React, { useState } from "react";
import {  Box } from "@mui/material";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { MdDownload } from "react-icons/md";
import axios from "axios";
import { BASE_URL, endPoint } from "../../../../../constants/ApiConstant/routeConstant";
import { useEffect } from "react";
import { useSelector } from "../../../../../redux/store";

const AdmidCard = ({ singleRegisteredStudent }) => {
  const { token } = useSelector((state) => state.userInfo);

  const [registrationEvent, setRegistrationEvent] = useState();
 
 const {
   oId
  } = singleRegisteredStudent;
  
  
  const getRegistrationEventData = async () => {
    const body = {
      oId: oId,
    };

    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(`${BASE_URL}${endPoint.eventRegGet}`, body, header)
      .then((res) => {
        if (res?.data?.success) {
          setRegistrationEvent(res?.data?.data);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    getRegistrationEventData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {registrationEvent?.map((item) => {
          return (
            <Box sx={{ width: "100%" }}>
              <div className="flex lg:w-full mb-2 border border-slate-300 rounded-md p-3 ">
                <p className=" w-[30%] mt-2">Admid Card</p>
                <span className=" w-[60%] flex">
                  <p className="pr-3 ">
                    <BsFileEarmarkPdf className="text-red-700 cursor-pointer text-5xl  	" />
                  </p>
                  <p className="font-bold mt-2">{item.e_title}</p>
                </span>

                <a href={`${BASE_URL}/${item.olym_admi_card}`} target="_blank" rel="noreferrer">
                  <MdDownload className=" mt-3 text-2xl text-green-600 cursor-pointer" />
                </a>
              </div>
            </Box>
          );
        })}

        
      </Box>
    </Box>
  );
};

export default AdmidCard;
