import React from "react";
const CardView = ({ event, getSingleEventData }) => {
  return (
    <div
      role="button"
      className=" flex bg-base-100 shadow-md rounded-md mx-[1rem] mb-[1.5rem] hover:bg-slate-200 cursor-pointer md:p-4 p-3 md:pl-8"
      onClick={() => getSingleEventData(event?.e_id)}
    >
      <div className=" h-full flex items-center w-[80rem]">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-2 w-full">
          <div className="w-full flex flex-row md:flex-col md:justify-between items-center md:items-start mb-4 md:mb-0">
            <div>
              <p className="text-sm font-sans text-gray-400 md:mt-1 mt-0 mr-5 md:mr-0">Event ID</p>
              <p className="text-sm font-sans font-bold">{event.e_id}</p>
            </div>
            <div>
              <p className="text-sm font-sans text-gray-400 ">Event Name </p>
              <p className="text-sm font-sans font-bold">{event.e_title} </p>
            </div>
          </div>
          <div className="w-full flex flex-col justify-between col-span-2">
            <div className="flex md:mb-2 mb-4">
              <div className="mr-[3rem]">
                <p className="text-sm font-sans text-gray-400 ">Venue</p>
                <p className="text-sm font-sans text-gray-800  font-bold">
                  {event.e_venue}
                </p>
              </div>
              <div>
                <p className="text-sm font-sans text-gray-400 ">Region</p>
                <p className="text-sm font-sans text-gray-800  font-bold">
                  {event.e_region_details}
                </p>
              </div>
            </div>
            <div>
              <p className="text-sm font-sans text-gray-400 ">Location</p>
              <p className="text-sm font-sans font-bold">
                {event.e_district}, {event.e_division}
              </p>
            </div>
          </div>

          <div className="bg-gray-100 flex items-center justify-between lg:px-[3rem] px-5 mt-4 md:mt-0 h-16 md:h-full">
            <div className="flex flex-col">
              <p className="text-xl font-bold font-sans text-orange-600">
                {event.e_date.slice(0, 10)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardView;
