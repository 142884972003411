import React, { useEffect, useState } from "react";

const AppSubNav = ({ navbarData, callback, font, type = "tab", width }) => {
  const [navbar, setNavbar] = useState([]);

  const handleSelectedSubNav = (item) => {
    const data = item.find((el) => el.checked);
    if (data) {
      callback({ ...data });
    }
  };

  const handleClick = (id) => {
    const updateData = [...navbar];
    updateData.map((item) => {
      if (item.id === id) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });

    setNavbar(updateData);
    handleSelectedSubNav(updateData);
  };
 

  useEffect(() => {
    const arr = [];
    navbarData.length > 0 &&
      navbarData.map((item, index) => {
        const obj = {
          id: index + 1,
          title: item,
          checked: index === 0 ? true : false,
          key: item.replace(/ /g, ""),
        };
        arr.push(obj);
      });
    setNavbar([...arr]);
    handleSelectedSubNav([...arr]);
  }, []);

  return (
    <>
      {type === "tab" && (
        <div className="flex overflow-auto border-b-2 border-gray-200 custom-scroll">
          {navbar.map((item) => (
            <div
              key={item.id}
              className={`py-2 mx-1 text-center ${
                item?.checked ? "border-b-2 border-primary  " : ""
              } ${font}`}
              role="button"
              tabIndex={0}
              onClick={() => handleClick(item.id)}
            >
              <p className={`${width}`}>{item.title}</p>
            </div>
          ))}
        </div>
      )}
      {type === "button" && (
        <div className=" overflow-auto flex rounded-md custom-scroll">
          {navbar.map((item) => (
            <button
              key={item.id}
              className={`btn mx-1 text-xs text-center rounded-lg h-11 ${
                item?.checked
                  ? "bg-[#54a0ff] text-white font=semibold border-none hover:bg-[#54a0ff]"
                  : "bg-transparent hover:bg-transparent  text-black border-gray-500 "
              }`}
              onClick={() => handleClick(item.id)}
            >
              <p className={`${width}`}>{item.title}</p>
            </button>
          ))}
        </div>
      )}
    </>
  );
};

export default AppSubNav;
