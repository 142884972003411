
// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)

import React from "react";
import { styles } from "../../Common/Constant";
import Footer from '../../Common/Footer';
import Header from '../../Common/Header';
import MyTable from '../../Common/Table';

export const HijamaUserTableToPrint = React.forwardRef((props, ref) => {


 const headerData = ['NO','Name','ID','Phone','Email','Payment Status','Course Status']

//   const bodyData =props.data?.map((item)=>{
//     return [item.h_name,item.h_id,item.user_phone_id,item.h_email,item.payment_status,item.course_status]
//  })

const bodyData =props.data?.map((item)=>{
  return [item.hName,item.hId,item.userPhoneId,item.hEmail,item.paymentStatus,item.courseStatus]
})
  return (
    <div ref={ref} style={styles.page}>
      <Header profile={props.data} proType={props.proType} title={"Hijama User List"} />
      <div style={styles.container}>
        <div style={styles.leftColumn}>
          <div style={styles.container}>

          {
            props.data.length>0 ? (
          <MyTable 
            headersData={headerData}
            bodyData = {bodyData}
          />
            ) : <h3 style={{textAlign:'center'}}> No Class found!</h3>
          }

          </div>
          

        </div>

        
        
      </div>
      <Footer/>
    </div>
  );
});