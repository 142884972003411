
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  
  endPoint,
} from "../../../../constants/ApiConstant/routeConstant";
import { useDelete } from "../../../../services/api";

import AppBredcrumbs from "../../../ui/AppBredcrumbs";
import AppCardContainer from "../../../ui/AppCardContainer";
import AppSubNav from "../../../ui/AppSubNav";
import AppTableActionButton from "../../../ui/AppTableActionButton";
import NoticeInfo from "./NoticeInfoDetails";

const NoticeInfoDetails = () => {
  const { id } = useParams();
  const { handleDelete } = useDelete(endPoint.noticeDelete, id);
  
  const [selectedNav, setSelectedNav] = useState();

 

  return (
    <AppCardContainer spacing="xl:mt-10 3xl:-mr-16 mx-5 mt-20">
      <div className="flex items-center justify-between">
        <AppBredcrumbs
          link="/olympiad/notices/view"
          allTitle="All Notices"
          detailsTitle="Notices Details"
        />
        <AppTableActionButton
          isView={false}
          editLink={`/olympiad/notices/edit/${id}`}
          deleteFunction={() => handleDelete()}
          redirectTo="/olympiad/notices/view"
        />
      </div>

      <div className="mt-10">
        <AppSubNav
          navbarData={["Message"]}
          callback={(item) => setSelectedNav(item)}
          type="tab"
          width="w-24"
        />
      </div>

      {selectedNav?.key === "Message" && <NoticeInfo id={id} />}
    </AppCardContainer>
  );
};

export default NoticeInfoDetails;
