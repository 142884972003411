import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import AppButton from "../../../../ui/AppButton";
import AppModal from "../../../../ui/AppModal";
import { HijamaData } from "../SAHijamaUsersView";

const buttonApply = {
  variant: "primary",
  fullWidth: true,
  size: "xs",
  classes: " text-sm h-11 md:w-28 font-semibold",
};
const buttonClear = {
  variant: "info",
  fullWidth: true,
  size: "xs",
  classes: " text-sm h-11 md:w-28 font-semibold mr-2",
};


const FilterData = ({
  hijamaUserFilter,
  setHijamaUserFilter,
  handleFilter,
  setClearFilter,
  clearFilter,
}) => {
  const [isShow, setIsShow] = useState(false);
  return (
    <div>
      <AppModal
        isShowModal={isShow}
        callback={(item) => setIsShow(item)}
        option
        modalTitle="Hijama Filter"
        modalTitleClass="text-xl"
        modalWidth="md:w-[420px]"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="gender"
                  options={["Registered", "In Progress","Videos Completed", "Completed"]}
                  fullWidth
                  size="small"
                  value={hijamaUserFilter.courseStatus || ""}
                  onChange={(event, newValue) => {
                    setHijamaUserFilter({
                      ...hijamaUserFilter,
                      courseStatus: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Course Status" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="gender"
                  options={["Paid", "Unpaid"]}
                  fullWidth
                  size="small"
                  value={hijamaUserFilter.paymentStatus || ""}
                  onChange={(event, newValue) => {
                    setHijamaUserFilter({
                      ...hijamaUserFilter,
                      paymentStatus: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Payment Status" />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <div className="mt-32 flex justify-end">
            <AppButton
              {...buttonClear}
              onClick={() => {
                setClearFilter(!clearFilter);
                setHijamaUserFilter(HijamaData);
                setIsShow(false);
              }}
            >
              Clear_Filter
            </AppButton>
            <AppButton
              {...buttonApply}
              onClick={() => {
                handleFilter();
                setIsShow(false);
              }}
            >
              Apply
            </AppButton>
          </div>
        </Box>
      </AppModal>
    </div>
  );
};

export default FilterData;
