import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import React from "react";
import { timeConvertor } from "../../../../utils/timeConvertor/timeConvertor";


const CardView = ({ notice, getSingleNoticeData }) => {

    let n_time = "--";
    if (notice?.n_time) {
      n_time = `${notice?.n_time.slice(0, 10)}  ${timeConvertor(
        notice?.n_time.slice(11, 19),
      )}`;
    }
  return (
    <div
      role="button"
      className=" flex bg-base-100 shadow-md md:h-32 h-auto rounded-md mx-[1rem] hover:bg-slate-200 mb-[1.5rem] p-4"
      onClick={() => getSingleNoticeData(notice?.n_id)}
    >
      <div className=" h-full flex items-center w-[80rem]">
        <div className="grid md:grid-cols-3 grid-cols-1 gap-x-10 w-full">
          <div className="w-full">
            <p className="text-xs font-sans text-gray-400">Notice title</p>
            <p className="text-base font-sans font-bold">{notice.n_title}</p>

            <p className="text-xs font-sans text-gray-400 mt-2 ">Event name</p>
            <p className="text-sm font-sans font-bold"> {notice.e_title} </p>
          </div>
          <div className="w-full col-span-2 mt-2 md:mt-0">
            <p className="text-xs font-sans text-gray-400 ">Notice uploaded</p>
            <p className="text-sm font-sans text-gray-800 font-bold">
              {n_time?.slice(0, 10)} { " "}{n_time?.slice(11, 21)}
            </p>
            <div className="flex">
              <div className="mr-[3rem]">
                <p className="text-sm font-sans text-gray-400 mt-2">Message</p>
                <p className="text-sm font-sans text-gray-800  font-bold text-justify">
                  {notice.n_msg.slice(0, 80)}
                  <label htmlFor="my-modal-3" className="modal-button">
                    <span className="cursor-pointer">
                      <MoreHorizIcon className="text-green-500 	" />
                    </span>
                  </label>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardView;
