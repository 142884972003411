import { Grid } from '@mui/material';
import React from 'react';
import { BASE_URL } from '../../../../constants/ApiConstant/routeConstant';
import { styles } from '../../Common/Constant';
import Item from '../../Common/Item';


const BasicInfo = ({profile}) => {
  return (
    <div style={styles.innerContainer}>
    
    <div style={styles.sectionContainer}>
      <h3 style={styles.subHeader}>  Doctor Information </h3>
<Grid container>
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Name"} value={profile.d_name} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Phone 1"} value={profile.user_phone_id} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Doctor ID"} value={profile.d_id} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Degree"} value={profile.d_degree} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Education"} value={profile.d_education} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Experience"} value={profile.d_experience} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Division"} value={profile.d_division}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"District"} value={profile.d_district}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Email"} value={profile.p_email} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Working Place Details"} value={profile.d_woking_place_detials} /> 
  </Grid>

 
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Charge"} value={profile.d_charge} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Total Year of Experience"} value={profile.d_total_exp_years} /> 
  </Grid>


  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Specialty"} value={profile.d_specialty} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Signature Image"} value={
    <div style={{display:"flex", alignContent:'center',justifyContent:'center'}} >
    <img src={`${BASE_URL}/${profile.d_signature}`} style={{width:150}} ></img>
    </div>
   
  } /> 
  </Grid>

  
  
</Grid>

       
       

</div>

   </div>
  )
}

export default BasicInfo
