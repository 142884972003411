
import React from "react";
import { API, styles } from "../../Common/Constant";
import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import BasicInfo from "./BasicInfo";

export const PatientDetailsPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} style={styles.page}>
      <Header profile={props.profile} proType={props.proType} title={"Patient Profile"} />
      <div style={styles.container}>
        <div style={styles.leftColumn}>
          <div style={styles.container}>
          {props.profile.user_img &&
            <img
            style={styles.image}
            src={`${API}/${props.profile.user_img}`}
            alt="image"
          />
        }
          
          </div>

          <BasicInfo profile={props.profile} />

          
        </div>

      </div>
      <Footer/>
    </div>
  );
});