import { Image, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { BASE_URL } from '../../../../constants/ApiConstant/routeConstant';
import AppNextIT from './Images/AppNextIT.png';
import Signature from './Images/Signature.png';
import { styles } from './pdfCss';

const Footer = ({addPress}) => {
  const signatureImageLink = addPress?.appointment?.dSignature ? `${BASE_URL}/${addPress?.appointment?.dSignature}` : Signature

  return (
    <View  style={styles.section3} >
    {
    //   <View  >
    //   <Image src={signatureImageLink}
    //    style={styles.section3_left_img}
    //    source={{
    //     header: {
    //        'Access-Control-Allow-Origin': '*'
    //     }
    //   }}
    //    />
    //   <Text style={styles.basicText} >Doctor's Signature</Text>
    // </View>
    }
    
    <View style={{display:'flex' , flexDirection:'column',
    position: "absolute",
    bottom: "0",
    right: "0"}} >
   
    <Text style={{
      fontSize:10,
      fontWeight:300,
      fontFamily:'bangla-black',
      textAlign:'center',
      }} >Powered By</Text>
      <Image src={AppNextIT} style={styles.section3_right_img} />
     
    </View>
  </View>
  

  )
}

export default Footer