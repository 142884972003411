
// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)

import React from "react";
import { styles } from "../../Common/Constant";
import Footer from '../../Common/Footer';
import Header from '../../Common/Header';
import MyTable from '../../Common/Table';

export const AppointmentTableToPrint = React.forwardRef((props, ref) => {


 const headerData = ['NO','Appointment ID','Doctor Name','Patient Name','Doctor ID','Patient ID','Request Time','Appointment Time','Payment Status']

//   const bodyData =props.data?.map((item)=>{
//     return [item.ap_id,item.d_name,item.p_name,item.d_id,item.p_id,item.ap_req_time,item.ap_time?.slice(0,19),item.payment_status]
//  })

const bodyData =props.data?.map((item)=>{
  return [item.apId,item.dName,item.pName,item.dId,item.pId,item.apReqTime,item.apTime?.slice(0,19),item.status]
})

  return (
    <div ref={ref} style={styles.page}>
      <Header profile={props.data} proType={props.proType} title={"Appointment List"} />
      <div style={styles.container}>
        <div style={styles.leftColumn}>
          <div style={styles.container}>

          {
            props.data.length>0 ? (
          <MyTable 
            headersData={headerData}
            bodyData = {bodyData}
          />
            ) : <h3 style={{textAlign:'center'}}> No Class found!</h3>
          }

          </div>
          

        </div>

        
        
      </div>
      <Footer/>
    </div>
  );
});