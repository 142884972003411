import React from "react";
import { endPoint } from "../../../../constants/ApiConstant/routeConstant";
import { useDelete } from "../../../../services/api";
import AppTableActionButton from "../../../ui/AppTableActionButton";
const Button = ({ quiz }) => {
  const { handleDelete } = useDelete(endPoint.quizDelete, quiz.q_id);
  return (
    <AppTableActionButton
      isView={false}
      editLink={`/hijama/quiz/edit/${quiz.q_id}`}
      deleteFunction={() => handleDelete()}
    />
  );
};

export default Button;
