import { Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const FamilyDetails = ({
  formFamilyDetails,
  errorsFamilyDetails,
  onChangeFamilyDetails,
}) => {
  return (
    <div>
      <h3 className=" text-2xl text-center mb-5 font-bold">Parent's Details</h3>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {" "}
            <TextField
              id="fatherName"
              variant="outlined"
              fullWidth
              size="small"
              label={
                formFamilyDetails?.fatherName ? "Father Name" : "Father Name *"
              }
              error={errorsFamilyDetails?.fatherName? true: false}
              helperText={errorsFamilyDetails?.fatherName}
              value = {formFamilyDetails.fatherName || ""}
              onChange={(e) => {
                onChangeFamilyDetails({
                  name: "fatherName",
                  value: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
          <TextField
            id="fatherChronic"
            multiline
            rows={3}
            fullWidth
        
            label= "Chronic Disease of Father"
            
            error={errorsFamilyDetails?.fatherChronic? true: false}
            helperText={errorsFamilyDetails?.fatherChronic}
            value = {formFamilyDetails.fatherChronic || ""}
            onChange={(e) => {
              onChangeFamilyDetails({
                name: "fatherChronic",
                value: e.target.value,
              });
            }}
          />
        </Grid>
          <Grid item xs={6}>
            {" "}
            <TextField
              id="motherName"
              variant="outlined"
              fullWidth
              size="small"
              label={
                formFamilyDetails?.motherName ? "Mother Name" : "Mother Name *"
              }
              error={errorsFamilyDetails?.motherName? true: false}
              helperText={errorsFamilyDetails?.motherName}
              value = {formFamilyDetails.motherName || ""}
              onChange={(e) => {
                onChangeFamilyDetails({
                  name: "motherName",
                  value: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
          <TextField
            id="motherChronic"
            multiline
            rows={3}
            fullWidth
        
            label= "Chronic Disease of Mother"
            
            error={errorsFamilyDetails?.motherChronic? true: false}
            helperText={errorsFamilyDetails?.motherChronic}
            value = {formFamilyDetails.motherChronic || ""}
            onChange={(e) => {
              onChangeFamilyDetails({
                name: "motherChronic",
                value: e.target.value,
              });
            }}
          />
        </Grid>
        </Grid>
      </Box>

      <h3 className="text-2xl text-center mb-5 mt-10 font-bold">
        Details Of Sibling
      </h3>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {" "}
            <TextField
              id="noOfSiblings"
              variant="outlined"
              fullWidth
              size="small"
              type="number"
              label={
                formFamilyDetails?.noOfSiblings
                  ? "No Of Siblings"
                  : "No Of Siblings *"
              }
              error={errorsFamilyDetails?.noOfSiblings? true: false}
              helperText={errorsFamilyDetails?.noOfSiblings}
              value = {formFamilyDetails.noOfSiblings || ""}
              onChange={(e) => {
                onChangeFamilyDetails({
                  name: "noOfSiblings",
                  value: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
          <TextField
            id="siblingChronic"
            multiline
            rows={3}
            fullWidth
        
            label= "Chronic Disease of Siblings"
            
            error={errorsFamilyDetails?.siblingChronic? true: false}
            helperText={errorsFamilyDetails?.siblingChronic}
            value = {formFamilyDetails.siblingChronic || ""}
            onChange={(e) => {
              onChangeFamilyDetails({
                name: "siblingChronic",
                value: e.target.value,
              });
            }}
          />
        </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default FamilyDetails;
