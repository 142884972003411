import { timeConvertor } from "../../../../utils/timeConvertor/timeConvertor";

export const convertedPrascriptionData = (res) => {
  let data = [];
  res?.map((itm) => {
     
    let ap_time = "--";
    if (itm?.ap_time) {
      ap_time = `${itm?.ap_time.slice(0, 10)}  ${timeConvertor(
        itm?.ap_time.slice(11, 19),
      )}`;
    }

    let ap_req_time = "--";
    if (itm?.ap_req_time) {
      ap_req_time = `${itm?.ap_req_time.slice(0, 10)}  ${timeConvertor(
        itm?.ap_req_time.slice(11, 19),
      )}`;
    }
    data.push({
      apId: itm?.ap_id || "--",
      pId: itm?.p_id || "--",
      dId: itm?.d_id || "--",
      apLink: ap_time,
      apReqTime: ap_req_time,
      apTime: itm?.ap_time || "--",
      apReason: itm?.ap_reason || "--",
      bloodPressure: itm?.blood_pressure || "--",
      pulse: itm?.pulse || "--",
      height: itm?.height || "--",
      weight: itm?.weight || "--",
      bloodGlucose: itm?.blood_glucose || "--",
      additional_note: itm?.additional_note || "--",
      paymentStatus: itm?.payment_status || "--",
      presId: itm?.pres_id || "--",
      presChiefComplaint: itm?.pres_chief_complaint || "--",
      presHistoryIllness: itm?.pres_history_illness || "--",
      presExaFinding: itm?.pres_exa_finding || "--",
      presDDiag: itm?.pres_d_diag || "--",
      presTreatment: itm?.pres_treatment || "--",
      presAdvice: itm?.pres_advice || "--",
      presInvestigation: itm?.pres_investigation || "--",
      presReferrel: itm?.pres_referrel || "--",
      presNotes: itm?.pres_notes || "--",
      presSignatureImg: itm?.pres_signature_img || "--",
      presFile: itm?.pres_file_title || "--",
      uId: itm?.u_id || "--",
      dName: itm?.d_name || "--",
      dDegree: itm?.d_degree || "--",
      dEducation: itm?.d_education || "--",
      dExperience: itm?.d_experience || "--",
      dDivision: itm?.d_division || "--",
      dDistrict: itm?.d_district || "--",
      dWokingPlaceDetials: itm?.d_woking_place_detials || "--",
      dCharge: itm?.d_charge || "--",
      dTotalExpYears: itm?.d_total_exp_years || "--",
      dSignature: itm?.d_signature || "--",
      dSpecialty: itm?.d_specialty || "--",
    });
  });
  ////console.log(data)

  return data;
};
