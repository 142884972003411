import React from "react";
import { endPoint } from "../../../../constants/ApiConstant/routeConstant";
import { useDelete } from "../../../../services/api";
import AppTableActionButton from "../../../ui/AppTableActionButton";

const CardView = ({ video }) => {
  const { handleDelete } = useDelete(endPoint.videoDelete, video?.vid_id);
  return (
    <div className=" bg-base-100 shadow-md w-[15.5rem] rounded-md p-4">
      <div className=" h-full flex items-center w-full">
        <div className="flex flex-col w-full">
          <div className="w-full">
            <iframe
              className="rounded cursor-pointer"
              width="100%"
              height="150"
              src={video.vid_link}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="w-full my-3">
            <p className="text-sm font-sans font-bold text-center">
              {video.vid_title}
            </p>

            <div className="flex justify-center  my-2">
              <p className="text-sm font-sans text-gray-400 mr-2">Duration: </p>
              <p className="text-sm font-sans text-gray-800  font-bold">
                {video.vid_length}
              </p>
            </div>
          </div>
          <div className="w-full pt-4 flex justify-center border-t">
            <AppTableActionButton
              isView={false}
              editLink={`/hijama/Videos/edit/${video.vid_id}`}
              deleteFunction={() => handleDelete()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardView;
