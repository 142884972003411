import { Autocomplete, Checkbox, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";

const GeneralExamination = ({
  onChangeGeneralExamination,
  formGeneralExamination,
  errorsGeneralExamination,
  setFormGeneralExamination ,
  setErrorsGeneralExamination
}) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const InitialData = {
    appearance: "",
    bodyBuilt: "",
    anemia: "",
    jaundice: "",
    cyanosis: "",
    nailCondition: "",
    ear: "",
    nose: "",
    throat: "",
    limbs: "",
    generalSkinCondition: "",
    lymphNodes: "",
  };

  const defaultData = {
    appearance: "Normal",
    bodyBuilt: "Normal",
    anemia: "Absent",
    jaundice: "Absent",
    cyanosis: "Absent",
    nailCondition: "Normal",
    ear: "Normal",
    nose: "Normal",
    throat: "Normal",
    limbs: "Normal",
    generalSkinCondition: "Normal",
    lymphNodes: "NAD",
  };

  const [checked, setChecked] = useState(false);
  const [genaralExamination, setGenaralExamination] = useState(InitialData);

  const handleChange = (event) => {
    // //console.log(event.target.checked);
    if (event.target.checked) {
      setGenaralExamination(defaultData);
     
      const arr =Object.keys(defaultData)
      let addedObj= {}
      arr.map((item)=>{
         addedObj[item]= defaultData[item]
      })
    
         setFormGeneralExamination({ ...formGeneralExamination, ...addedObj });
         let obj={}
         Object.keys(errorsGeneralExamination).forEach((key,value) => obj[key] = "");
         setErrorsGeneralExamination(obj)
         

    } else {
      setGenaralExamination(InitialData);
      const arr =Object.keys(InitialData)
      let addedObj= {}
      arr.map((item)=>{
         addedObj[item]= InitialData[item]
      })
    
         setFormGeneralExamination({ ...formGeneralExamination, ...addedObj });
         let obj={}
         Object.keys(errorsGeneralExamination).forEach((key,value) => obj[key] = "Please Select");
         setErrorsGeneralExamination(obj)
         
  
    }
    setChecked(event.target.checked);
  };

  return (
    <div>
      <div className=" flex justify-end mb-2">
        <p className="font-bold mt-2" >NORMAL FINDINGS</p>
        <Checkbox {...label} checked={checked} onChange={handleChange} />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="appearance"
              value={formGeneralExamination?.appearance || ""}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(event, newValue) => {
                setGenaralExamination({
                  ...genaralExamination,
                  appearance: newValue,
                });
                onChangeGeneralExamination({
                  name:"appearance",
                  value:newValue
                })
              }}
              options={["Normal", "Ill-looking", "Depressed"]}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params}
                error={errorsGeneralExamination?.appearance? true:false}
                helperText={errorsGeneralExamination?.appearance}
                label={formGeneralExamination.appearance? "Appearance": "Appearance"+"*"} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="bodyBuilt"
              value={formGeneralExamination?.bodyBuilt || ""}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(event, newValue) => {
                setGenaralExamination({
                  ...genaralExamination,
                  bodyBuilt: newValue,
                });

                onChangeGeneralExamination({
                  name: "bodyBuilt",
                  value: newValue,
                });
              }}
              options={["Normal", "Underweight", "Overweight"]}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} 
                error={errorsGeneralExamination?.bodyBuilt? true:false}
                helperText={errorsGeneralExamination?.bodyBuilt}
                label={formGeneralExamination.bodyBuilt? "Body Built": "Body Built"+"*"}

                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="anemia"
              value={formGeneralExamination?.anemia || ""}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(event, newValue) => {
                setGenaralExamination({
                  ...genaralExamination,
                  anemia: newValue,
                });
                
                onChangeGeneralExamination({
                  name:"anemia",
                  value:newValue
                })
              }}
              options={["Present", "Absent"]}
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params}
              error={errorsGeneralExamination?.anemia? true:false}
              helperText={errorsGeneralExamination?.anemia}
              label={formGeneralExamination.anemia? "Anemia": "Anemia"+"*"}

              />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="jaundice"
              value={formGeneralExamination?.jaundice || ""}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(event, newValue) => {
                setGenaralExamination({
                  ...genaralExamination,
                  jaundice: newValue,
                });
                
                onChangeGeneralExamination({
                  name:"jaundice",
                  value:newValue
                })
              }}
              options={["Present", "Absent"]}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params}
                error={errorsGeneralExamination?.jaundice? true:false}
                helperText={errorsGeneralExamination?.jaundice}
                 
                 label={formGeneralExamination.jaundice? "Jaundice": "Jaundice"+"*"}
   

                 />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="cyanosis"
              value={formGeneralExamination?.cyanosis || ""}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(event, newValue) => {
                setGenaralExamination({
                  ...genaralExamination,
                  cyanosis: newValue,
                });
                
                onChangeGeneralExamination({
                  name:"cyanosis",
                  value:newValue
                })
              }}
              options={["Present", "Absent"]}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params}
                error={errorsGeneralExamination?.cyanosis? true:false}
                helperText={errorsGeneralExamination?.cyanosis}
                 label={formGeneralExamination.cyanosis? "Cyanosis": "Cyanosis"+"*"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="nailCondition"
              value={formGeneralExamination?.nailCondition || ""}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(event, newValue) => {
                setGenaralExamination({
                  ...genaralExamination,
                  nailCondition: newValue,
                });
                
                onChangeGeneralExamination({
                  name:"nailCondition",
                  value:newValue
                })
              }}
              options={[
                "Normal",
                "Clubbing",
                "Koilonychia",
                "Leuchonychila",
                "Other",
              ]}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params}
                error={errorsGeneralExamination?.nailCondition? true:false}
                helperText={errorsGeneralExamination?.nailCondition}
                label={formGeneralExamination.nailCondition? "Nail Condition": "Nail Condition"+"*"}
              />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {" "}
            <TextField
              id="ear"
              label={formGeneralExamination.ear? "Ear": "Ear"+"*"}
              error={errorsGeneralExamination?.ear? true:false}
              helperText={errorsGeneralExamination?.ear}
              value={formGeneralExamination?.ear || ""}
              onChange={(e) => {
                setGenaralExamination({
                  ...genaralExamination,
                  ear: e.target.value,
                })
                onChangeGeneralExamination({
                  name:"ear",
                  value:e.target.value
                })
              }
              }
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <TextField
              id="nose"
              label={formGeneralExamination.nose? "Nose": "Nose"+"*"}
              error={errorsGeneralExamination?.nose? true:false}
              helperText={errorsGeneralExamination?.nose}
              value={formGeneralExamination?.nose || ""}
              onChange={(e) => {
                setGenaralExamination({
                  ...genaralExamination,
                  nose: e.target.value,
                })
                onChangeGeneralExamination({
                  name:"nose",
                  value:e.target.value
                })
              }
              }
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <TextField
              id="throat"
              label={formGeneralExamination.throat? "Throat": "Throat"+"*"}
              error={errorsGeneralExamination?.throat? true:false}
              helperText={errorsGeneralExamination?.throat}
              value={formGeneralExamination?.throat || ""}
              onChange={(e) => {
                setGenaralExamination({
                  ...genaralExamination,
                  throat: e.target.value,
                })
                onChangeGeneralExamination({
                  name:"throat",
                  value:e.target.value
                })
              }
              }
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <TextField
              id="limbs"
              label={formGeneralExamination.limbs? "Limbs": "Limbs"+"*"}
              error={errorsGeneralExamination?.limbs? true:false}
              helperText={errorsGeneralExamination?.limbs}
              value={formGeneralExamination?.limbs || ""}
              onChange={(e) => {
                setGenaralExamination({
                  ...genaralExamination,
                  limbs: e.target.value,
                })
                onChangeGeneralExamination({
                  name:"limbs",
                  value:e.target.value
                })
              }
              }
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <TextField
              id="generalSkinCondition"
              label={formGeneralExamination.generalSkinCondition? "General Skin Condition": "General Skin Condition"+"*"}

              error={errorsGeneralExamination?.generalSkinCondition? true:false}
              helperText={errorsGeneralExamination?.generalSkinCondition}
              value={formGeneralExamination?.generalSkinCondition || ""}
              onChange={(e) => {
                setGenaralExamination({
                  ...genaralExamination,
                  generalSkinCondition: e.target.value,
                })
                onChangeGeneralExamination({
                  name:"generalSkinCondition",
                  value:e.target.value
                })
              }
              }
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <TextField
              id="lymphNodes"
              label={formGeneralExamination.lymphNodes? "Lymph Nodes": "Lymph Nodes"+"*"}

              error={errorsGeneralExamination?.lymphNodes? true:false}
              helperText={errorsGeneralExamination?.lymphNodes}
              
              value={formGeneralExamination?.lymphNodes || ""}
              onChange={(e) => {
                setGenaralExamination({
                  ...genaralExamination,
                  lymphNodes: e.target.value,
                })
                onChangeGeneralExamination({
                  name:"lymphNodes",
                  value:e.target.value
                })
              }
              }
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <TextField
              id="others"
              label="Others"
              error={errorsGeneralExamination?.others? true:false}
              helperText={errorsGeneralExamination?.others}
              
              value={formGeneralExamination?.others || ""}
              onChange={(e) => {
                setGenaralExamination({
                  ...genaralExamination,
                  others: e.target.value,
                })
                onChangeGeneralExamination({
                  name:"others",
                  value:e.target.value
                })
              }
              }
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default GeneralExamination;
