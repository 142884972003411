import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import img from "../../assets/login-image.webp";
import { BASE_URL, endPoint } from "../../constants/ApiConstant/routeConstant";
import { setUserInfo } from "../../redux/slices/userInfoSlice";
import { useDispatch, useSelector } from "../../redux/store";
import { useAuth } from "../../services/useAuth";
import Loader from "../ui/Loader";

const LoginForm = ({ setLogin }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const InitialLogindata = {
    errors: {
      phone: false,
      password: false,
    },

    values: {
      phone: "",
      password: "",
    },
  };

  const [loginForm, setLoginForm] = useState(InitialLogindata.values);
  const [error, setError] = useState(InitialLogindata.errors);
  const [apiError, setApiError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumber, setIsPhoneNumber] = useState({
    errorMessage: "",
    successMessage: "",
    infoMessage: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(true);
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [isForget, setIsForget] = useState(false);
  const { isLogin, token } = useSelector((state) => state.userInfo);

  const auth = useAuth(isLogin);


  const handleRememberMe = (e) => {
    setIsRememberMe(e.target.checked);
  };

  const handleError = () => {
    const phoneValidator = "/^(?:(?:+|00)88|01)?d{11}$/";
    if (!loginForm?.phone && !loginForm?.password) {
      return setError({ ...error, phone: true, password: true });
    } else if (!loginForm?.phone) {
      return setError({ ...error, phone: true });
    } else if (phoneValidator.match(parseFloat(loginForm?.phone))) {
      return setError({ ...error, password: true });
    } else if (!loginForm?.password) {
      return setError({ ...error, password: true });
    } else return null;
  };

  const handleForgetPassword = async () => {
    try {
      if (phoneNumber === "") {
        setIsPhoneNumber({
          ...isPhoneNumber,
          errorMessage: "Please fill in your phone number",
        });
        setTimeout(() => {
          setIsPhoneNumber({ ...isPhoneNumber, errorMessage: "" });
        }, 2000);
      } else {
        const body = {
          phone: phoneNumber,
        };
        const header = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        setIsLoading(true);
        await axios
          .post(`${BASE_URL}${endPoint.forgetPassword}`, body, header)
          .then((res) => {
            if (res.data.success) {
              setIsLoading(false);
              setIsPhoneNumber({
                ...isPhoneNumber,
                successMessage: "Request Submitted Successfully",
              });
              setPhoneNumber("");
              setTimeout(() => {
                setIsPhoneNumber({ ...isPhoneNumber, successMessage: "" });
                setIsPhoneNumber({ ...isPhoneNumber, infoMessage: true });
              }, 3000);
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    } catch (error) {}
  };

  const handleLogin = async () => {
    const error = handleError();
    if (error === null) {
      const body = {
        phone: loginForm?.phone,
        password: loginForm?.password,
      };
      setIsLoading(true);
      await axios
        .post(`${BASE_URL}/api/user/login`, body)
        .then((res) => {
          dispatch(setUserInfo(res?.data));
          if (res?.data?.message && res?.data?.data?.user_admin_type) {
            navigate("/dashboard");
            setIsLoading(false);
          } else {
            setTimeout(() => {
              setApiError("");
            }, 3000);
            setApiError("User is not available");
          }
        })
        .catch(() => {
          setTimeout(() => {
            setApiError("");
          }, 3000);
          setApiError("Invalid login credentials");
          setIsLoading(false);
        });

      if (isRememberMe) {
        const origin = window.location.origin;
        Cookies.set("loginInfo", JSON.stringify(loginForm), {
          expires: 3,
          path: `${origin}/admin`,
        });
      }
    }
  };

  useEffect(() => {
    if (Cookies.get("loginInfo")) {
      const loginData = JSON.parse(Cookies.get("loginInfo"));
      setLoginForm({ ...loginData });
    }
  }, []);

  useEffect(() => {
    if (auth) {
      navigate("/dashboard");
      sessionStorage.setItem("url", "/dashboard");
    }
  }, [auth, navigate]);

  return (
    <div>
      <div className="hero min-h-screen bg-[#f7f1e35e]">
        <div className="hero-content w-full flex flex-col items-center">
          <div className="flex lg:flex-row flex-col ">
            <div className="text-center lg:text-left">
              <img src={img} alt="" />
            </div>
            {isForget ? (
              <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100 mx-auto lg:mx-0">
                <div className="card-body flex flex-col justify-between">
                  <div className="flex flex-col ">
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Phone</span>
                      </label>
                      <input
                        type="text"
                        placeholder="phone"
                        className={` ${
                          error.phone
                            ? "border-2 border-orange-400 "
                            : "input-bordered"
                        } input `}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>

                    {isPhoneNumber.successMessage !== "" && (
                      <div className="mt-3 p-3 bg-green-50 rounded">
                        <p className="text-green-600 text-sm ">
                          {isPhoneNumber.successMessage}
                        </p>
                      </div>
                    )}
                    {isPhoneNumber.infoMessage && (
                      <div className="mt-3 p-3 bg-green-50 rounded">
                        <p className="text-green-600 text-sm ">
                          After succefully send the number please contact with{" "}
                          <span className="font-bold">Super Admin</span> to get
                          the password.
                        </p>
                      </div>
                    )}
                    {isPhoneNumber.errorMessage !== "" && (
                      <div className="mt-3 p-3 bg-red-50 rounded">
                        <p className="text-red-600 text-sm ">
                          {isPhoneNumber?.errorMessage}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="form-control mt-3">
                    <div className="flex justify-center mb-3">
                      <label
                        className="label"
                        onClick={() => {
                          setIsPhoneNumber({
                            ...isPhoneNumber,
                            infoMessage: false,
                          });
                          setIsForget(false);
                        }}
                      >
                        <p className="label-text-alt link link-hover">
                          Back to{" "}
                          <span className=" font-bold text-blue-500">
                            Login
                          </span>{" "}
                          ?
                        </p>
                      </label>
                    </div>
                    <button
                      className="btn bg-orange-400 hover:bg-orange-400 border-none"
                      onClick={handleForgetPassword}
                    >
                      {isLoading ? (
                        <Loader variant="#FFFFFF" />
                      ) : (
                        <span>Reset Password</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100 mx-auto lg:mx-0">
                <div className="card-body">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Phone</span>
                    </label>
                    <input
                      type="text"
                      placeholder="phone"
                      className={` ${
                        error.phone
                          ? "border-2 border-orange-400 "
                          : "input-bordered"
                      } input `}
                      value={loginForm?.phone}
                      onChange={(e) => {
                        setLoginForm({ ...loginForm, phone: e.target.value });
                        e.target.value && setError({ ...error, phone: false });
                      }}
                    />
                  </div>
                  <div className="form-control ">
                    <label className="label">
                      <span className="label-text">Password</span>
                    </label>
                    <div className="flex flex-row items-center z-10 relative  ">
                      <input
                        type={togglePassword ? "password" : "text"}
                        placeholder="password"
                        className={` ${
                          error.password
                            ? "border-2 border-orange-400 "
                            : "input-bordered"
                        } input w-full`}
                        value={loginForm?.password}
                        onChange={(e) => {
                          setLoginForm({
                            ...loginForm,
                            password: e.target.value,
                          });
                          e.target.value &&
                            setError({ ...error, password: false });
                        }}
                      />
                      {!togglePassword ? (
                        <BsFillEyeFill
                          className="ml-[-30px] cursor-pointer text-gray-500 "
                          onClick={() => setTogglePassword(!togglePassword)}
                        />
                      ) : (
                        <BsFillEyeSlashFill
                          className="ml-[-30px] cursor-pointer text-gray-500"
                          onClick={() => setTogglePassword(!togglePassword)}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="">
                      <label className="checkbox-inline text-white flex flex-row items-center">
                        <input
                          type="checkbox"
                          id="inlineCheckbox1"
                          value="option1"
                          onChange={handleRememberMe}
                          className="checkbox checkbox-sm border border-gray-500 w-3 h-3  rounded-sm z-10 relative"
                        />
                        <span className="label-text text-xs ml-2 cursor-pointer">
                          Remember Me
                        </span>
                      </label>
                    </div>
                    <label className="label" onClick={() => setIsForget(true)}>
                      <p className="label-text-alt link link-hover">
                        Forgot password?
                      </p>
                    </label>
                  </div>

                  {!(apiError === "") && (
                    <div className=" py-3 bg-red-200 rounded">
                      <p className="text-red-600 text-center font-semibold">
                        {apiError}
                      </p>
                    </div>
                  )}

                  <div className="form-control mt-6">
                    <button
                      className="btn bg-orange-400 hover:bg-orange-400 border-none"
                      onClick={handleLogin}
                    >
                      {isLoading ? (
                        <Loader variant="#FFFFFF" />
                      ) : (
                        <span>Login</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
