import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { pdfTypes, userTypes } from "../../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint
} from "../../../../constants/ApiConstant/routeConstant";
import { useSelector } from "../../../../redux/store";
import AppBredcrumbs from "../../../ui/AppBredcrumbs";
import AppCardContainer from "../../../ui/AppCardContainer";
import AppSubNav from "../../../ui/AppSubNav";
import BasicInfo from "./Details/Basic Info";
import { tableConvertedData } from "./helper/convertedPatientsData";

const PatientsDetails = () => {
  const { id } = useParams();
  const { token } = useSelector((state) => state.userInfo);
  const [singlePatients, setSinglePatients] = useState({});
  const [selectedNav, setSelectedNav] = useState();

 

  const getSinglePatientsData = async () => {
   const body = {
     profileType: userTypes.telemedicine,
   };

   const header = {
     headers: {
       Authorization: `Bearer ${token}`,
     },
   };
   await axios
     .post(`${BASE_URL}${endPoint.userGet}/${id}`, body, header)
     .then((res) => {
       if (res?.data?.success) {
         setSinglePatients(tableConvertedData([res?.data?.data])[0]);
       }
     })
     .catch((err) => {
       //console.log(err);
     });
  };

  //console.log();

  useEffect(() => {
    getSinglePatientsData();
  }, []);

  return (
    <div>
   
      <AppCardContainer spacing="xl:mt-10 3xl:-mr-16 mx-5 mt-20">
        <div className="flex items-center justify-between">
          <AppBredcrumbs
            link="/telemedicine/patients"
            allTitle="All Patients"
            detailsTitle="Patients Detaile"
          />
          <Link
            to={`/pdfview/${pdfTypes.telemedicine}/${id}`}
            target="_blank"
            rel="noopener noreferrer"
          >

          <SimCardDownloadIcon
              style={{ color: "red", cursor: "pointer" }}
              fontSize="large"
            />
          
          </Link>
        </div>

        <div className="mt-10">
          <AppSubNav
            navbarData={["Basic Info"]}
            callback={(item) => setSelectedNav(item)}
            type="tab"
            width="w-40"
          />
        </div>

        <BasicInfo singlePatients={singlePatients} />
      </AppCardContainer>
    </div>
  );
};

export default PatientsDetails;
