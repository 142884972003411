import { Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { userTypes } from "../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint
} from "../../../constants/ApiConstant/routeConstant";
import { useToken } from "../../../services/useAuth";
import { checkFile } from "../../../utils/Validation/formValidation";
import { notify } from "../../ui/helpers/ToastMsg";

const AddAdmin = () => {
  const navigation = useNavigate();
  const [formAdmin, setFormAdmin] = useState({});
  const [errorsAdmin, setErrorsAdmin] = useState({});
  const [, setAdmin] = useState({});
  const [proPicUploadLoading, setProPicUploadLoading] = useState();


  const token = useToken();
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };


  const onChangeAdmin = ({ name, value }) => {
    setFormAdmin({ ...formAdmin, [name]: value });
    if (name === "phone") {
      if (value === "") {
        setErrorsAdmin((prev) => {
          return {
            ...prev,
            [name]: "Phone cann't be blank.",
          };
        });
      } else {
        setErrorsAdmin((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "name") {
      if (value === "") {
        setErrorsAdmin((prev) => {
          return {
            ...prev,
            [name]: "Name cann't be blank.",
          };
        });
      } else {
        setErrorsAdmin((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "password") {
      if (value === "") {
        setErrorsAdmin((prev) => {
          return {
            ...prev,
            [name]: "Password cann't be blank.",
          };
        });
      } else {
        setErrorsAdmin((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "adminType") {
      if (value === "") {
        setErrorsAdmin((prev) => {
          return {
            ...prev,
            [name]: "Admin Type cann't be blank.",
          };
        });
      } else {
        setErrorsAdmin((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
  };


    
  const pickProPicAndUpload = async (e) => {
    if (checkFile(e.target.files, "image")) {
      //console.log(e.target.files);

      //upload to server start

      const file = e.target.files[0];

      const formData = new FormData();
      formData.append("image", file);
      try {
        setProPicUploadLoading(true);
        const url = `${BASE_URL}/api/upload/image`;
        const res = await axios.post(url, formData, headers);
        if (res.data.success) {
          onChangeAdmin({
            name: "proImg",
            value: res.data.data.path,
          });
          setErrorsAdmin((prev) => {
            return {
              ...prev,
              proImg: null,
            };
          });
        }
        setProPicUploadLoading(false);
      } catch (e) {
        //console.log(e);
      }

      //upload to server end
    } else {
      //setError
      setErrorsAdmin((prev) => {
        return {
          ...prev,
          proImg:
            "Image should be jpg, jpeg, png, svg,gif & size should be less that 2MB",
        };
      });
    }
  };


  const onSubmitAdmin = () => {
    if (!formAdmin.phone) {
      setErrorsAdmin((prev) => {
        return {
          ...prev,
          phone: "Please add a Phone",
        };
      });
    }
    if (!formAdmin.name) {
      setErrorsAdmin((prev) => {
        return {
          ...prev,
          name: "Please add Name",
        };
      });
    }
    if (!formAdmin.password) {
      setErrorsAdmin((prev) => {
        return {
          ...prev,
          password: "Please add Password",
        };
      });
    }

    if (
      formAdmin.phone &&
      formAdmin.name &&
      formAdmin.password &&
      Object.values(errorsAdmin).every((item) => !item)
    ) {
      setAdmin({ ...formAdmin });
      onSubmit();
    }
  };

  const onSubmit = () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const bodyData = {
      profileType: userTypes.superAdmin,
      data: {
        phone: formAdmin.phone,
        name: formAdmin.name,
        pass: formAdmin.password,
        image: formAdmin.proImg,
        adminType: userTypes.superAdmin,
      },
      profileData: {
        adminName: formAdmin.name,
      },
    };
    //console.log(bodyData);
    axios
      .post(`${BASE_URL}${endPoint.user}`, bodyData, header)
      .then((res) => {
        if (res?.data?.success) {
          //console.log(res.data?.data);
          notify("Admin Added Successfully!", "success");
          setFormAdmin({});
          navigation("/superAdmin/view");
        }
      })
      .catch((err) => {
        //console.log(err);
        //notify(err.response.data?.message, "warning");
      });
  };

  return (
    <div className="my-2 mt-10 shadow-md rounded-xl">
      <div className=" bg-white px-5 md:px-[3rem] py-[2rem] rounded-xl">
        <h1 className=" text-2xl pt-5 text-center font-bold uppercase">
          Add Admin
        </h1>{" "}
        <Box sx={{ flexGrow: 1, mt: 7 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="phone"
                variant="outlined"
                fullWidth
                size="small"
                value={formAdmin?.phone || ""}
                label={formAdmin?.phone ? "Phone" : "Phone *"}
                onChange={(e) => {
                  onChangeAdmin({
                    name: "phone",
                    value: e.target.value,
                  });
                }}
                error={errorsAdmin?.phone ? true : false}
                helperText={errorsAdmin?.phone}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="name"
                variant="outlined"
                fullWidth
                size="small"
                value={formAdmin?.name || ""}
                label={formAdmin?.name ? "Name" : "Name *"}
                onChange={(e) => {
                  onChangeAdmin({
                    name: "name",
                    value: e.target.value,
                  });
                }}
                error={errorsAdmin?.name ? true : false}
                helperText={errorsAdmin?.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="password"
                variant="outlined"
                type="password"
                fullWidth
                size="small"
                value={formAdmin?.password || ""}
                label={formAdmin?.password ? "Password" : "Password *"}
                onChange={(e) => {
                  onChangeAdmin({
                    name: "password",
                    value: e.target.value,
                  });
                }}
                error={errorsAdmin?.password ? true : false}
                helperText={errorsAdmin?.password}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              {" "}
              <div className="flex overflow-x-hidden">
                <Grid item xs={6}>
                  <p className="">Profile Pricture </p>
                </Grid>
                <Grid item xs={6}>
                  <input
                    type="file"
                    name="image"
                    onChange={pickProPicAndUpload}
                  />
                </Grid>
              </div>
              {formAdmin?.proImg && (
                <p className=" text-green-700 ">
                  Uploaded 
                </p>
              )}
              {errorsAdmin?.proImg && (
                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall text-red-700 MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">
                  {errorsAdmin?.proImg}
                </p>
              )}
              {proPicUploadLoading && (
                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall text-yellow-700 MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">
                  Uploading....
                </p>
              )}
            </Grid>
          </Grid>
        </Box>
        <div className=" flex justify-center">
          <button
            className="btn bg-blue-600 hover:bg-blue-600  border-none  font-bold mt-5 px-10 rounded-2xl  "
            onClick={() => onSubmitAdmin()}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
