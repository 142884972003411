import { Box, Typography } from "@mui/material";
import React from "react";
import { BiIdCard } from "react-icons/bi";
import { BsCalendarDate, BsGenderAmbiguous } from "react-icons/bs";
import { FaRegAddressCard } from "react-icons/fa";
import { GiPeaceDove } from "react-icons/gi";
 

const ParticularDetails = ({ singleStudents }) => {
  const {
    
    tName,
    tDob,
    tGender,
    tReligion,
    tPAddress,
    tTAddress,
   
  } = singleStudents;
  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiIdCard style={{ marginRight: 4 }} />
            Name
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {tName}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: 14,
            mt: 3,
            display: "flex",
            alignItems: "center",
          }}
        >
          <BsCalendarDate style={{ marginRight: 4 }} />
          Date of birth
        </Typography>
        <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
          {tDob ? tDob?.slice(0, 10) : ""}
        </Typography>
      </Box>
      <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: 14,
            mt: 3,
            display: "flex",
            alignItems: "center",
          }}
        >
          <BsGenderAmbiguous style={{ marginRight: 4 }} />
          Gender
        </Typography>
        <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
          {tGender}
        </Typography>
      </Box>

      <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: 14,
            mt: 3,
            display: "flex",
            alignItems: "center",
          }}
        >
          <GiPeaceDove style={{ marginRight: 4 }} />
          Religion
        </Typography>
        <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
          {tReligion}
        </Typography>
      </Box>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>

        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaRegAddressCard style={{ marginRight: 4 }} />
            Permanent address
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {tPAddress}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: 14,
            mt: 3,
            display: "flex",
            alignItems: "center",
          }}
        >
          <FaRegAddressCard style={{ marginRight: 4 }} />
          Present address
        </Typography>
        <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
          {tTAddress}
        </Typography>
      </Box>
      </Box>

    </Box>
  );
};

export default ParticularDetails;
