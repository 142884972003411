

import React from "react";
import { styles } from "../../../Common/Constant";
import Footer from '../../../Common/Footer';
import Header from '../../../Common/Header';
import MyTable from '../../../Common/Table';
// function createData(no, id, type, name, totalStudents,division,district,totalClass,serviceType,start,end,totalPatients,totalAmount,perHead,serviceStatus) {
//   return {no, id, type, name, totalStudents,division,district,totalClass,serviceType,start,end,totalPatients,totalAmount,perHead,serviceStatus };
// }
// const StyledTableCell = styled(TableCell)({
//   padding: '3px !important',
//   fontFamily:'Noto Serif Bengali !important'
// })
export const InstituteTableToPrint = React.forwardRef((props, ref) => {
//   const tableData = props.data?.map((item,index)=>{
//     return createData(index+1,item.ins_id,item.ins_type,item.ins_name,item.ins_total_students,item.ins_division,item.ins_district,item.classes.length,item.ins_service_type,item.ins_contact_start.slice(0,10),item.ins_contact_end.slice(0,10),item.ins_sm_total_patients,item.ins_sm_total_amount,item.ins_name,item.ins_sm_per_head,item.ins_service_status)
//  })

 const headerData = ['NO','ID','Type','Name','Total Students','Division','District','Total Classes','Type','Start','End','Total Patients','Total Amount','Per Head','Service Status']

//   const bodyData =props.data?.map((item)=>{
//     return [item.ins_id,item.ins_type,item.ins_name,item.ins_total_students,item.ins_division,item.ins_district,item.classes.length,item.ins_service_type,item.ins_contact_start?.slice(0,10),item.ins_contact_end?.slice(0,10),item.ins_sm_total_patients,item.ins_sm_total_amount,item.ins_sm_per_head,item.ins_service_status]
//  })

const bodyData =props.data?.map((item)=>{
  return [item.insId,item.insType,item.insName,item.insTotalStudents,item.insDivision,item.insDistrict,item.classes.length,item.insServiceType,item.insContactStart?.slice(0,10),item.insContactEnd?.slice(0,10),item.insSmTotalPatients,item.insSmTotalAmount,item.insSmPerHead,item.insServiceStatus]
})



  return (
    <div ref={ref} style={styles.page}>
      <Header profile={props.data} proType={props.proType} title={"Institution List"} />
      <div style={styles.container}>
        <div style={styles.leftColumn}>
          <div style={styles.container}>

          {
            props.data.length>0 ? (
          //     <TableContainer component={Paper} sx={{marginTop:'1rem',marginBottom:'1rem'}} >
          // <Table  aria-label="simple table"  >
          //   <TableHead>
          //     <TableRow>
          //       <StyledTableCell align="center">NO</StyledTableCell>
          //       <StyledTableCell align="center">ID</StyledTableCell>
          //       <StyledTableCell align="center">Type</StyledTableCell>
          //       <StyledTableCell align="center">Name</StyledTableCell>
          //       <StyledTableCell align="center">Total Students</StyledTableCell>
          //       <StyledTableCell align="center">Division</StyledTableCell>
          //       <StyledTableCell align="center">District</StyledTableCell>
          //       <StyledTableCell align="center">Total Classes</StyledTableCell>
          //       <StyledTableCell align="center">Type</StyledTableCell>
          //       <StyledTableCell align="center">Start</StyledTableCell>
          //       <StyledTableCell align="center">End</StyledTableCell>
          //       <StyledTableCell align="center">Total Patients</StyledTableCell>
          //       <StyledTableCell align="center">Total Amount</StyledTableCell>
          //       <StyledTableCell align="center">Per Head</StyledTableCell>
          //       <StyledTableCell align="center">Service Status</StyledTableCell>
                
          //     </TableRow>
          //   </TableHead>
          //   <TableBody>
          //     {tableData.map((row) => (
          //       <TableRow
          //         key={row.no}
          //        >
          //         <StyledTableCell align="center"  >
          //           #{row.no}
          //         </StyledTableCell>
          //         <StyledTableCell align="center"  >{row.id}</StyledTableCell>
          //         <StyledTableCell align="center">{row.type}</StyledTableCell>
          //         <StyledTableCell align="center">{row.name}</StyledTableCell>
          //         <StyledTableCell align="center">{row.totalStudents}</StyledTableCell>
          //         <StyledTableCell align="center">{row.division}</StyledTableCell>
          //         <StyledTableCell align="center">{row.district}</StyledTableCell>
          //         <StyledTableCell align="center">{row.totalClass}</StyledTableCell>
          //         <StyledTableCell align="center">{row.serviceType}</StyledTableCell>
          //         <StyledTableCell align="center">{row.start}</StyledTableCell>
          //         <StyledTableCell align="center">{row.end}</StyledTableCell>
          //         <StyledTableCell align="center">{row.totalPatients}</StyledTableCell>
          //         <StyledTableCell align="center">{row.totalAmount}</StyledTableCell>
          //         <StyledTableCell align="center">{row.perHead}</StyledTableCell>
          //         <StyledTableCell align="center">{row.serviceStatus}</StyledTableCell>
                  
          //       </TableRow>
          //     ))}
          //   </TableBody>
          // </Table>
          // </TableContainer>
          <MyTable 
            headersData={headerData}
            bodyData = {bodyData}
          />
            ) : <h3 style={{textAlign:'center'}}> No Class found!</h3>
          }

          </div>
          

        </div>

        
        
      </div>
      <Footer/>
    </div>
  );
});