export const tableHeadClassData = [
  {
    id: 1,
    title: "Class Name",
    key: "className",
    width: "w-32",
  },
  {
    id: 2,
    title: "Class Gender",
    key: "gender",
    width: "w-40",
  },
  {
    id: 3,
    title: "Total Students",
    key: "totalStudents",
    width: "w-40",
  },
];

export const tableHeadStudentsData = [
  {
    id: 1,
    title: "Roll No.",
    key: "rollNo",
  },
  {
    id: 2,
    title: "Student Name",
    key: "name",
  },
  {
    id: 3,
    title: "Section",
    key: "section",
  },
  {
    id: 4,
    title: "Gender",
    key: "gender",
  },
  {
    id: 5,
    title: "Relagion",
    key: "relagion",
  },
  {
    id: 6,
    title: "Address",
    key: "address",
  },
  {
    id: 7,
    title: "Health Status",
    key: "healthStatus",
  },
];

export const tableHeadPatientsData = [
  {
    id: 1,
    title: "Patient ID",
    key: "pId",
  },
  {
    id: 2,
    title: "Patient Name",
    key: "pName",
  },
  {
    id: 3,
    title: "Gender",
    key: "pGender",
  },
  {
    id: 4,
    title: "DOB",
    key: "pDoB",
  },
  {
    id: 5,
    title: "Phone Number",
    key: "pNumber",
  },
  {
    id: 6,
    title: "Email",
    key: "pEmail",
  },
  {
    id: 7,
    title: "Action",
    key: "action",
  },
];

export const tableHeadAppointmentsData = [
  {
    id: 1,
    title: "Appointment ID",
    key: "apId",
  },
  {
    id: 2,
    title: "Patient ID",
    key: "pId",
  },
  {
    id: 3,
    title: "Patient Name",
    key: "pName",
  },
  {
    id: 4,
    title: "Patient DOB",
    key: "pDob",
  },
  {
    id: 5,
    title: "Appointment Request Time",
    key: "apReqTime",
  },
  {
    id: 6,
    title: "Appointment Time",
    key: "apTime",
  },

  {
    id: 7,
    title: "Payment Status",
    key: "status",
  },
  {
    id: 8,
    title: "Action",
    key: "action",
  },
];

export const tableHeadPrescriptionsData = [
  {
    id: 1,
    title: "Prescription ID",
    key: "presId",
  },
  {
    id: 2,
    title: "Patient ID",
    key: "pId",
  },
  {
    id: 3,
    title: "History",
    key: "presHistoryIllness",
  },

  {
    id: 4,
    title: "Action",
    key: "action",
  },
];

//SuperAdmin institutions data
export const tableHeadInstitutionsHeaderData = [
  {
    id: 1,
    title: "Institution ID",
    key: "insId",
  },
  {
    id: 2,
    title: "Name",
    key: "insName",
  },
  {
    id: 3,
    title: "Type",
    key: "insType",
  },
  {
    id: 4,
    title: "Location",
    key: "insDivision",
  },
  {
    id: 5,
    title: "Total Students",
    key: "insTotalStudents",
  },
  {
    id: 6,
    title: "Action",
    key: "action",
  },
];

//SuperAdmin student data
export const tableHeadStudentsHeaderData = [
  {
    id: 1,
    title: "Student ID",
    key: "sId",
    width: "w-20",
  },
  {
    id: 2,
    title: "Name",
    key: "sName",
    width: "w-40",
  },
  {
    id: 3,
    title: "Institution",
    key: "insName",
  },
  {
    id: 4,
    title: "Class",
    key: "insClassName",
  },
  {
    id: 5,
    title: "Section",
    key: "sSection",
  },

  {
    id: 6,
    title: "Health Status",
    key: "sHealthStatus",
    width: "w-40",
  },

  {
    id: 7,
    title: "Action",
    key: "action",
  },
];

//SuperAdmin teacher data
export const tableHeadTeachersHeaderData = [
  {
    id: 1,
    title: "Teacher ID",
    key: "tId",
    width: "w-20",
  },
  {
    id: 2,
    title: "Name",
    key: "tName",
    width: "w-40",
  },
  {
    id: 3,
    title: "Institution",
    key: "insName",
  },

  {
    id: 4,
    title: "Health Status",
    key: "tHealthStatus",
    width: "w-40",
  },
  {
    id: 5,
    title: "Action",
    key: "action",
  },
];

//superadmin patient data
export const tableHeadSAPatientsData = [
  {
    id: 1,
    title: "Patients ID",
    key: "pId",
  },
  {
    id: 2,
    title: "Name",
    key: "pName",
  },

  {
    id: 3,
    title: "Gender",
    key: "pGender",
  },
  {
    id: 4,
    title: "DOB",
    key: "pDob",
  },
  {
    id: 5,
    title: "Address",
    key: "pAddress",
  },
  {
    id: 6,
    title: "Phone",
    key: "pPhone",
  },

  {
    id: 7,
    title: "Action",
    key: "action",
  },
];

//superadmin Appointments data
export const tableHeadSAAppointmentsData = [
  {
    id: 1,
    title: "Appointment ID",
    key: "apId",
    width:'w-28'
  },
  {
    id: 2,
    title: "Patient ID",
    key: "pId",
    width:'w-24'
  },
  {
    id: 3,
    title: "Patient Name",
    key: "pName",
    width:'w-36'
  },
  {
    id: 4,
    title: "Patient DOB",
    key: "pDob",
  },
  {
    id: 4,
    title: "Appointment Request Time",
    key: "apReqTime",
    width:'w-44'
  },
  {
    id: 5,
    title: "Appointment Time",
    key: "apTime",
    width:'w-44'
  },

  {
    id: 6,
    title: "Payment Status",
    key: "status",
    width:'w-28'
  },
  {
    id: 7,
    title: "Action",
    key: "action",
  },
];

//superadmin Appointments data
export const tableHeadRegisteredStudentsData = [
  {
    id: 1,
    title: "Olympiad Id",
    key: "oId",
    width: "w-20",
  },
  {
    id: 2,
    title: "Name",
    key: "oUserName",
    width: "w-28",
  },

  {
    id: 3,
    title: "Institution",
    key: "oInstitute",
  },
  {
    id: 4,
    title: "Address",
    key: "oDetails_address",
  },

  {
    id: 5,
    title: "Action",
    key: "action",
  },
];

//superadmin Hijama user

export const tableHeadHijamaUsersDataData = [
  {
    id: 1,
    title: "Hijama ID",
    key: "hId",
  },
  {
    id: 2,
    title: "Name",
    key: "hName",
  },

  {
    id: 4,
    title: "Phone",
    key: "userPhoneId",
  },

  {
    id: 5,
    title: "Action",
    key: "action",
  },
];
