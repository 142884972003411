import { Grid } from '@mui/material';
import React from 'react';
import { styles } from '../../../Common/Constant';
import Item from '../../../Common/Item';

const ParticularDetailsInfo = ({profile}) => {
 
  return (
    <div style={styles.innerContainer}>
    
    <div style={styles.sectionContainer}>
      <h3 style={styles.subHeader}>  Basic Information </h3>
<Grid container>
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Name"} value={profile.s_name} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Institution Name"} value={profile.ins_name} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Class"} value={profile.ins_class_name} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Section"} value={profile.s_section} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Roll"} value={profile.s_roll}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Session"} value={profile.s_session}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Date of Birth"} value={profile.s_dob} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Blood Group"} value={profile.s_blood} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Gender"} value={profile.s_gender} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Religion"} value={profile.s_religion} /> 
  </Grid>
  
</Grid>

       
       

</div>


<div style={styles.sectionContainer} break>
<h4 style={styles.subHeader}> Address Information </h4> 

<Grid container>
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Permanent Address"} value={profile.s_p_address} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Present Address"} value={profile.s_t_address} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Upozila / Thana"} value={profile.ins_upozila} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"House Type"} value={profile.s_house_type} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"House Construction Type "} value={profile.s_house_con_type}  /> 
  </Grid>


  
</Grid>


</div>

<div style={styles.sectionContainer}>
<h4 style={styles.subHeader}> Family Information </h4> 
<Grid container>
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Father Name"} value={profile.s_ftr_name} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Mother Name"} value={profile.s_mtr_name} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Father Profession"} value={profile.s_ftr_profession} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Mother Profession"} value={profile.s_mtr_profession} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"No of Siblings"} value={profile.s_no_of_siblings} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Details of Siblings"} value={profile.s_diblings_details} /> 
  </Grid>

  
</Grid>



      
    </div>
   </div>
  )
}

export default ParticularDetailsInfo