import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { AiOutlineSolution } from "react-icons/ai";
import { BsSpeedometer2 } from "react-icons/bs";
import doc1 from "../../../../assets/doc1.jpg";
import { BASE_URL } from "../../../../constants/ApiConstant/routeConstant";


const PersonalInfo = ({ data }) => {
  //console.log(data);
  return (
    <>
      <div className="">
        <Box sx={{ px: 2, py: 1 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BsSpeedometer2 style={{ marginRight: 4 }} />
                Doctor ID
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {data.d_id}
              </Typography>
            </Box>
            <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AiOutlineSolution style={{ marginRight: 4 }} />
                Name
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {data?.d_name ? data.d_name : "--"}
              </Typography>
            </Box>

            <Box sx={{ width: "25%" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BsSpeedometer2 style={{ marginRight: 4 }} />
                Degree
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {data.d_degree}
              </Typography>
            </Box>
            <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <BsSpeedometer2 style={{ marginRight: 4 }} /> */}
                {/* Profile */}
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                <figure className="w-[15rem] p-3">
                  {data.user_img === "" ? (
                    <img src={doc1} alt="Movie" className="h-full rounded-md" />
                  ) : (
                    <img
                      src={`${BASE_URL}/${data.user_img}`}
                      alt="Movie"
                      className="h-full rounded-md"
                    />
                  )}
                </figure>
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineSolution style={{ marginRight: 4 }} />
            Phone
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {data.user_phone_id}
          </Typography>
        </Box>
            <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AiOutlineSolution style={{ marginRight: 4 }} />
                Specialty
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {data.d_specialty}
              </Typography>
            </Box>
            <Box sx={{ width: {  xs: "100%", sm: "50%" } }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AiOutlineSolution style={{ marginRight: 4 }} />
                Education
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {data.d_education}
              </Typography>
            </Box>

           
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap" }}>

          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineSolution style={{ marginRight: 4 }} />
            Charge
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {data.d_charge}
          </Typography>
        </Box>
            <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AiOutlineSolution style={{ marginRight: 4 }} />
                Experience Place
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {data.d_experience}
              </Typography>
            </Box>
            <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AiOutlineSolution style={{ marginRight: 4 }} />
                Experience
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {data.d_total_exp_years} Years
              </Typography>
            </Box>
            <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AiOutlineSolution style={{ marginRight: 4 }} />
                Division
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {data.d_division}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AiOutlineSolution style={{ marginRight: 4 }} />
                District
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {data.d_district}
              </Typography>
            </Box>
            <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AiOutlineSolution style={{ marginRight: 4 }} />
                Work Place
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {data.d_woking_place_detials}
              </Typography>
            </Box>
            <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BsSpeedometer2 style={{ marginRight: 4 }} />
                Signature
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                <figure className="w-[15rem] p-3">
                  {data.d_signature === "" ? (
                    // <img src={doc1} alt="Movie" className="h-full rounded-md" />
                    "--"
                  ) : (
                    <img
                      src={`${BASE_URL}/${data.d_signature}`}
                      alt="Movie"
                      className="h-full rounded-md"
                    />
                  )}
                </figure>
              </Typography>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default PersonalInfo;
