import React, { useState, useEffect } from "react";
import { IoDocumentTextOutline, IoSettingsOutline } from "react-icons/io5";
import img from "../../assets/profile-img.png";

const AppProfile = ({
  children,
  shortInfo,
  othersInfo,
  profileImg,
  bgPosition = "bg-center",
  bgImgClass,
  callback,
}) => {
  const [navbar, setNavbar] = useState([]);

  const handleSelectedSubNav = (item) => {
    const data = item.find((el) => el.checked);
    if (data) {
      callback({ ...data });
    }
  };

  const handleClick = (id) => {
    const updateData = [...navbar];
    updateData.map((item) => {
      if (item.id === id) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });

    setNavbar(updateData);
    handleSelectedSubNav(updateData);
  };

  useEffect(() => {
    const arr = [];
    ["About", "Settings"].map((item, index) => {
      const obj = {
        id: index + 1,
        title: item,
        checked: index === 0 ? true : false,
        key: item.replace(/ /g, ""),
        icon: index === 0 ? <IoDocumentTextOutline /> : <IoSettingsOutline />,
      };
      arr.push(obj);
    });
    setNavbar([...arr]);
    handleSelectedSubNav([...arr]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-full font-poppins overflow-hidden">
      <div className={`${bgImgClass} ${bgPosition} bg-cover h-40 md:h-48 lg:h-80 w-full`}></div>

      <div className="bg-[#ffffff] lg:h-20 md:h-40 h-56 xl:ml-[8.5rem] relative flex flex-col lg:flex-row items-center">
        <div className="avatar absolute left-28 md:left-20 xl:left-44 3xl:left-80 lg:bottom-5 -top-16 lg:top-auto">
          <div className="w-32 rounded-full ring ring-[#ffffff] ring-offset-base-100 ring-offset-2">
            <img src={profileImg ? profileImg : img} alt=''/>
          </div>
        </div>

        <div className=" absolute lg:left-[22%] 3xl:left-[26%] flex md:flex-row flex-col items-center md:justify-between lg:w-[67%] 3xl:w-[58%] top-20 lg:top-auto">
          <div className="flex items-center mr-10 lg:mr-auto">
            <div className=" border-r-2 border-gray-300 px-7">{shortInfo}</div>
            <div className="ml-7">{othersInfo}</div>
          </div>
          <button className="flex rounded-md mt-7 md:mt-0">
            {navbar?.map((item, index) => (
              <div
                key={index}
                className={`btn border-none mr-4 px-8 rounded-lg font-bold text-sm flex items-center  ${
                  item?.checked
                    ? "bg-orange-100 text-orange-500 hover:bg-orange-100 hover:text-orange-500"
                    : " bg-gray-100 hover:bg-gray-100 text-gray-600"
                }`}
                onClick={() => {
                  handleClick(item.id);
                }}
              >
                <span> {item?.title}</span>
                <span className="ml-2">{item.icon}</span>
              </div>
            ))}
          </button>
        </div>
      </div>
      <div className="xl:pl-56 xl:pr-24 md:px-10 px-5 xl:px-0 py-5 ">{children}</div>
    </div>
  );
};

export default AppProfile;
