import React from "react";
import { Paper, Typography, Box } from "@mui/material";

const RequiredService = ({ authorityInfo }) => {
  const { ins_service_type, ins_contact_start, ins_contact_end,ins_service_status } =
    authorityInfo;

  return (
    <Paper sx={{ borderRadius: "12px", p: 4 }}>
      <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
        Required Services
      </Typography>

      <Box sx={{ mt: 3 }}>
        <Box sx={{ width: "50%" }}>
          <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
            Select Service
          </Typography>
          <Typography sx={{ fontWeight: "400", fontSize: 16, mt: 0.5 }}>
            {ins_service_type}
          </Typography>
        </Box>
        <Box sx={{ width: "50%" }}>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 3 }}>
            Contract Time
          </Typography>
          <Typography sx={{ fontWeight: "400", fontSize: 16, mt: 0.5 }}>
            <span>Start: {ins_contact_start}</span> <br />
            <span>End: {ins_contact_end}</span>
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default RequiredService;
