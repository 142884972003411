import { Box } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import defaultImage from "../../../assets/defaultImageDoctor.png";
import { userTypes } from "../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint,
} from "../../../constants/ApiConstant/routeConstant";
import AppTableActionButton from "../../ui/AppTableActionButton";
import { tableConvertedData } from "./helper/convertedAdminData";

const View = () => {
  const { deleteSuccess } = useSelector((state) => state.modal);
  const { token } = useSelector((state) => state.userInfo);
  const [admins, setAdmins] = useState([]);

  const getAllAdminData = async () => {
    const body = {
      profileType: userTypes.superAdmin,
    };

    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(`${BASE_URL}${endPoint.userGet}`, body, header)
      .then((res) => {
        if (res?.data?.success) {
          setAdmins(tableConvertedData(res?.data?.data));
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const handleDeleteUser = async (userId, id) => {
    const body = {
      profileType: userTypes.superAdmin,
      userId: userId,
      id: id,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios
      .post(`${BASE_URL}${endPoint.deleteUser}`, body, header)
      .then((res) => {
        //console.log(res.data.message);
        return res.data.message;
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    getAllAdminData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  //console.log(admins);

  return (
    <div>
      <div className="shadow-md w-full bg-white mt-10 h-[77vh] relative overflow-scroll custom-scroll rounded-xl">
        <div className="flex items-center justify-between fixed bg-white xl:w-[70%] w-full  py-7 shadow-md rounded-xl">
          <h1 className=" text-2xl w-full text-center font-bold uppercase">Admins</h1>
        </div>
        <div className=" mt-28 mb-10 mx-4 ">
        <Box sx={{ flexGrow: 1, mt: 2 }}>
          <div className="grid 2xl:grid-cols-4 md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-10">
            {admins.map((item) => {
              //console.log(item)
              return (
                <div >
                  <div className=" bg-base-100 shadow-md w-[15.5rem] rounded-md p-4">
                    <div className=" h-full flex items-center w-full">
                      <div className="flex flex-col w-full">
                        <div className="w-full ">
                          <img
                            src={
                              item.userImg === "--"
                                ? defaultImage
                                : `${BASE_URL}/${item.userImg}`
                            }
                            alt="Admin"
                            className="h-40 w-full"
                          />
                        </div>
                        <div className="w-full my-3">
                          <p className="text-sm font-sans font-bold text-center">
                            {item.userName}
                          </p>
                          <div className="flex justify-center  my-2">
                            <p className="text-sm font-sans text-gray-400 mr-2">
                              {" "}
                              Admin Type :{" "}
                            </p>
                            <p className="text-sm font-sans text-gray-800  font-bold">
                              {item.userAdminType}
                            </p>
                          </div>
                          <p className="text-sm font-sans font-bold text-center">
                            {item.userPhoneId}
                          </p>
                        </div>
                        <div className="w-full pt-4 flex justify-center border-t">
                          <AppTableActionButton
                            isView={false}
                            isEdit={false}
                            deleteFunction={() => handleDeleteUser(item?.userId, item?.adminId)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Box>
        </div>
      </div>
    </div>
  );
};

export default View;
