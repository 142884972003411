import axios from "axios";
import { BASE_URL, endPoint } from "../constants/ApiConstant/routeConstant";
import { useToken } from "./useAuth";

export const handleDeleteUser = async (profileType, userId, id) => {
  const body = {
    profileType: profileType,
    userId: userId,
    id: id,
  };
  return await axios
    .post(`${BASE_URL}${endPoint.deleteUser}`, body)
    .then((res) => {
      //console.log(res.data.message)
      return res.data.message;
    })
    .catch((err) => {
      //console.log(err);
    });
};

export const useDelete = (endPoint, id) => {
  const token = useToken();
  const handleDelete = async () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios
      .delete(`${BASE_URL}${endPoint}/${id}`, header)
      .then((res) => {
        return res.data.message;
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  return { handleDelete };
};

export const useDeleteObj = () => {
  const token = useToken();
  const handleDelete = async (endPoint, id) => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios
      .delete(`${BASE_URL}${endPoint}/${id}`, header)
      .then((res) => {
        return res.data.message;
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  return { handleDelete };
};

export const useGetDashboardData = (body) => {
  const token = useToken();
  const getSADashboardData = async () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios
      .post(`${BASE_URL}${endPoint.dashboard}`, body, header)
      .then((res) => {
        if (res?.data?.success) {
          return res?.data?.data;
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  return { getSADashboardData };
};
