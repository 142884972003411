import { Box } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BASE_URL, endPoint } from "../../../../../constants/ApiConstant/routeConstant";


const VideoInfo = ({ singleHijamaUsers }) => {
 const {
   hId,
 } = singleHijamaUsers;



 const [watchedVideos,setWatchedVideos] = useState([]);
 const {token} = useSelector((state)=> state.userInfo)

 const getVideoWatchedInfo = async()=>{
    const header = {
      headers:{
        Authorization:`Bearer ${token}`
      }
    }

    await axios.get(`${BASE_URL}${endPoint.videoWatchGet}/${hId}`,header)
    .then((res)=>{
      if(res?.data?.success){
        //notify("Data getting successfully","success");
        setWatchedVideos(res.data?.data)
        
      }
    })
    .catch((err)=>{
      //console.log(err) 
      //notify(err.message,"danger");
    })
 }
 ///POST,PUT : header,body
 ///GET,DELETE : header
 /// axios.post(endpoint,body,header)
 /// axios.get(endpoint,header)

 useEffect(()=>{
   getVideoWatchedInfo()
 },[])
//console.log(watchedVideos);
  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {watchedVideos.length === 0 ? "No video watched" : (
          watchedVideos.map((item) => {
          return (
            <Box sx={{ width: "100%" }}>
              <div className="flex lg:w-full mb-5">
                {
                  //   <img
                  //   src={WatchVideo}
                  //   alt=""
                  //   className=" w-[140px] h-[80px] mr-10 rounded-md"
                  // />
                }
                <iframe
                  src={item.vid_link}
                  className=" w-[140px] h-[80px] mr-10 rounded-md"
                ></iframe>
                <div>
                  <p className=" font-bold mb-2">{item.vid_title}</p>
                  <p className=" font-bold "> {item.vid_length} </p>
                </div>
              </div>
            </Box>
          );
        })
        )}
        
      </Box>
    </Box>
  );
};

export default VideoInfo;
