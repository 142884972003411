import { Page, View } from '@react-pdf/renderer';
import React from 'react';
import Body from './Body';
import Footer from './Footer';
import Header from './Header';
import { styles } from './pdfCss';

const PdfPage =props => (
  <Page >
  
  <View style={styles.MainContainer}>

  {/* Section1  */}
  <Header addPress={props.addPress} />

  {/* Section2 */}
  <Body addPress={props.addPress} />

  {/* Section3 */}
  <Footer addPress={props.addPress} />
  </View>
</Page>
);

export default PdfPage