export const tableConvertedData = (res) => {
  let data = [];
  res?.map((itm) => {
    data.push({
      userId: itm?.user_id || "--",
      userPhoneId: itm?.user_phone_id || "--",
      userName: itm?.user_name || "--",
      userImg: itm?.user_img || "--",
      userSchlHealth: itm?.user_schl_health || "--",
      userTeacher: itm?.user_teacher || "--",
      userTelemedicine: itm?.user_telemedicine || "--",
      userOlymp: itm?.user_olymp || "--",
      userHijama: itm?.user_hijama || "--",
      userAdminType: itm?.user_admin_type || "--",
      uId: itm?.u_id || "--",
      tId: itm?.t_id || "--",
      insId: itm?.ins_id || "--",
      tName: itm?.t_name || "--",
      tJoin: itm?.t_join || "--",
      tDob: itm?.t_dob || "--",
      tBlood: itm?.t_blood || "--",
      tGender: itm?.t_gender || "--",
      tReligion: itm?.t_religion || "--",
      tPAddress: itm?.t_p_address || "--",
      tTAddress: itm?.t_t_address || "--",
      tFtrName: itm?.t_ftr_name || "--",
      tMtrName: itm?.t_mtr_name || "--",
      tFtrChronic: itm?.t_ftr_chronic || "--",
      tMtrChronic: itm?.t_mtr_chronic || "--",
      tNoOfSiblings: itm?.t_no_of_siblings || "--",
      tSiblingChronic: itm?.t_sibling_chronic || "--",
      tPrevIllness: itm?.t_prev_illness || "--",
      tDrugHistory: itm?.t_drug_history || "--",
      tVitalSign: itm?.t_vital_sign || "--",
      tBloodPressure: itm?.t_blood_pressure || "--",
      tPulse: itm?.t_pulse || "--",
      tTemp: itm?.t_temp || "--",
      tHeight: itm?.t_height || "--",
      tWeight: itm?.t_weight || "--",
      tBmi: itm?.t_bmi || "--",
      tCvLE: itm?.t_cv_l_e || "--",
      tCvRE: itm?.t_cv_r_e || "--",
      tVaLF: itm?.t_va_l_f || "--",
      tVaLN: itm?.t_va_l_n || "--",
      tVaRF: itm?.t_va_r_f || "--",
      tVaRN: itm?.t_va_r_n || "--",
      tVaBF: itm?.t_va_b_f || "--",
      tVaBN: itm?.t_va_b_n || "--",
      tVaCmnt: itm?.t_va_cmnt || "--",
      tAppearance: itm?.t_appearance || "--",
      tBodybuilt: itm?.t_body_built || "--",
      tAnemia: itm?.t_anemia || "--",
      tJaundice: itm?.t_jaundice || "--",
      tCyanosis: itm?.t_cyanosis || "--",
      tNail: itm?.t_nail || "--",
      tEar: itm?.t_ear || "--",
      tThroat: itm?.t_throat || "--",
      tNose: itm?.t_nose || "--",
      tLimbs: itm?.t_limbs || "--",
      tSkin: itm?.t_skin || "--",
      tLymph: itm?.t_lymph || "--",
      tOthers: itm?.t_others || "--",
      tResSys: itm?.t_res_sys || "--",
      tCarSys: itm?.t_car_sys || "--",
      tOralSys: itm?.t_oral_sys || "--",
      tNervousSys: itm?.t_nervous_sys || "--",
      tMuscuSys: itm?.t_muscu_sys || "--",
      tDiagnosis: itm?.t_diagnosis || "--",
      tTreatment: itm?.t_treatment || "--",
      tReferral: itm?.t_referral || "--",
      tFollowUp: itm?.t_follow_up || "--",
      tHealthStatus: itm?.t_health_status || "--",
      tCheckupDate: itm?.t_checkup_date || "--",
      tInputDate: itm?.t_input_date || "--",
      tAccountStatus: itm?.t_account_status || "--",
      healthRecords: itm?.healthRecords || "--",
      insType: itm?.ins_type || "--",
      insName: itm?.ins_name || "--",
      insEstYear: itm?.ins_est_year || "--",
      insRegNo: itm?.ins_reg_no || "--",
      insPrinciple: itm?.ins_principle || "--",
      insDivision: itm?.ins_division || "--",
      insDistrict: itm?.ins_district || "--",
      insUpozila: itm?.ins_upozila || "--",
      insRoadNo: itm?.ins_road_no || "--",
      insHouseNo: itm?.ins_house_no || "--",
      insServiceType: itm?.ins_service_type || "--",
      insContactStart: itm?.ins_contact_start || "--",
      insContactEnd: itm?.ins_contact_end || "--",
      insTotalStudents: itm?.ins_total_students || "--",
      insFpSNo: itm?.ins_fp_s_no || "--",
      insFpSRate: itm?.ins_fp_s_rate || "--",
      insFpSAmount: itm?.ins_fp_s_amount || "--",
      insFpTNo: itm?.ins_fp_t_no || "--",
      insFpTRate: itm?.ins_fp_t_rate || "--",
      insFpTAmount: itm?.ins_fp_t_amount || "--",
      insDsSNo: itm?.ins_ds_s_no || "--",
      insDsTNo: itm?.ins_ds_t_no || "--",
      insDsRate: itm?.ins_ds_rate || "--",
      insDsAmount: itm?.ins_ds_amount || "--",
      insFsSNo: itm?.ins_fs_s_no || "--",
      insFsTNo: itm?.ins_fs_t_no || "--",
      insSmTotalPatients: itm?.ins_sm_total_patients || "--",
      insSmTotalAmount: itm?.ins_sm_total_amount || "--",
      insSmPerHead: itm?.ins_sm_per_head || "--",
      insServiceStatus: itm?.ins_service_status || "--",
      insClassId: itm?.ins_class_id || "--",
      insClassName: itm?.ins_class_name || "",
      insClassGender: itm?.ins_class_gender || "--",
      totalStudent: itm?.total_student || "--",
    });
  });

  return data;
};
