import React from "react";
import { Typography, Box } from "@mui/material";
import { MdOutlineClass } from "react-icons/md";
import { BsGenderTrans } from "react-icons/bs";
import { HiOutlineUserGroup } from "react-icons/hi2";

const StudentInfo = ({ singleInstitution }) => {
  const { classes } = singleInstitution;
  return (
    <Box sx={{ px: 2, py: 4 }}>
      {classes.map((elm, index) => {
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap", mb: 2 }}>
            <Typography sx={{ width: "6%", fontWeight: "bold" }}>
              {index + 1})
            </Typography>
            <Box sx={{ width: {sm:"18%"}, display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  mr: 3,
                }}
              >
                <MdOutlineClass style={{ marginRight: 4 }} />
                Class:
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 14 }}>
                {elm?.ins_class_name}
              </Typography>
            </Box>
            <Box sx={{ width: "30%", display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  mr: 3,
                }}
              >
                <BsGenderTrans style={{ marginRight: 4 }} />
                Gender:
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 14 }}>
                {elm?.ins_class_gender}
              </Typography>
            </Box>
            <Box sx={{ width: { sm: "25%", xs: "100%" }, display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  mr: 3,
                }}
              >
                <HiOutlineUserGroup style={{ marginRight: 4 }} />
                Total Students:
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 14 }}>
                {elm?.total_student}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default StudentInfo;
