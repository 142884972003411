import React from "react";
import { Paper, Typography, Box } from "@mui/material";

const FreeServices = ({ authorityInfo }) => {
  const { ins_fs_s_no, ins_fs_t_no } = authorityInfo;
  return (
    <Paper sx={{ mt: 4, p:4, borderRadius: "12px" }}>
      <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
        Free Services
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          mt: 1,
        }}
      >
        <Box
          sx={{
            width: "50%",
          }}
        >
          <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
            Students No.
          </Typography>
          <Typography sx={{ fontWeight: "400", fontSize: 16, }}>
            {ins_fs_s_no}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "50%",
          }}
        >
          <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
            Teachers No.
          </Typography>
          <Typography sx={{ fontWeight: "400", fontSize: 16,  }}>
            {ins_fs_t_no}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default FreeServices;
