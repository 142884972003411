import React, { useState } from "react";

export const useConvertedTableData = (data, limit) => {
  const [itemOffset, setItemOffset] = useState(0);
  // let loader = false;

  const endOffset = itemOffset + limit;
  const tableData = data?.slice(itemOffset, endOffset);

  const pageCount = Math.ceil(data?.length / limit);

  // //console.log(tableData)
  const handlePageClick = (event) => {
    const newOffset = (event?.selected * limit) % data?.length;
    setItemOffset(newOffset);
  };

  // if (!tableData) {
  //   loader = true;
  // }
  // if (tableData) {
  //   loader = false;
  // }
  // if (tableData?.length === 0) {
  //   loader = false;
  // }
  // if (tableData?.length > 0) {
  //   loader = false;
  // }
  const loader = tableData?.length === 0  ? true :  false;

  return { tableData, pageCount, loader, handlePageClick };
};
