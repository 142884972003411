export const tableConvertedData = (res) => {
  let data = [];
  res?.map((itm) => {
    data.push({
      userId: itm?.user_id || "--",
      userPhoneId: itm?.user_phone_id || "--",
      userName: itm?.user_name || "--",
      userImg: itm?.user_img || "--",
      userSchlHealth: itm?.user_schl_health || "--",
      userTelemedicine: itm?.user_telemedicine || "--",
      userOlymp: itm?.user_olymp || "--",
      userHijama: itm?.user_hijama || "--",
      userAdminType: itm?.user_admin_type || "--",
      oId: itm?.o_id || "--",
      oUserName: itm?.o_user_name || "--",
      oInstitute: itm?.o_institute || "--",
      oEmail: itm?.o_email || "--",
      oDivision: itm?.o_division || "--",
      oDistrict: itm?.o_district || "--",
      oFtr_name: itm?.o_ftr_name || "--",
      oMtr_name: itm?.o_mtr_name || "--",
      oDob: itm?.o_dob || "--",
      oAge: itm?.o_age || "--",
      oDetails_address: itm?.o_details_address || "--",
      oClass: itm?.o_class || "--",
      oSection: itm?.o_section || "--",
      oRoll: itm?.o_roll || "--",
      oImage: itm?.o_image || "--",
    });
  });

  return data;
};
