import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import doc1 from "../../../../assets/doc1.jpg";
import { BASE_URL } from "../../../../constants/ApiConstant/routeConstant";

const CardView = ({ doctor }) => {
  return (
    <Link
      to={`/telemedicine/doctors/view/details/${doctor?.user_id}`}
      state={{ data: doctor }}
    >
      <div className=" flex md:flex-row flex-col bg-base-100 shadow-md h-auto md:h-32 rounded-md mx-[1rem] mb-[1.5rem] hover:bg-slate-200 cursor-pointer ">
        <figure className="md:w-[15rem] w-full p-3">
          {doctor.user_img === "" ? (
            <img src={doc1} alt="Movie" className="md:h-full h-48 rounded-md" />
          ) : (
            <img
              src={`${BASE_URL}/${doctor.user_img}`}
              alt="Movie"
              className="md:h-full h-48 w-full rounded-md"
            />
          )}
        </figure>
        <div className="h-full flex items-center w-[80rem]">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-1 w-full">
            <div className="w-full px-3 md:px-0">
              <p className="md:text-base text-sm font-sans font-bold">
                {doctor.d_name}
              </p>
              <p className="text-xs font-sans">{doctor.d_degree}</p>
              <p className="text-sm font-sans text-gray-400 md:mt-3 mt-2">
                Specialities
              </p>
              <p className="text-xs font-sans text-gray-800">
                {doctor.d_specialty}
              </p>
            </div>
            <div className="w-full px-3 md:px-0 my-2 md:my-0 flex md:flex-col flex-row md:gap-0 gap-10">
              <div>
                <p className="text-sm font-sans text-gray-400 ">Working in</p>
                <p className="text-xs font-sans font-bold">
                  {doctor.d_woking_place_detials}
                </p>
              </div>
              <div className="flex">
                <div className="md:mr-[3rem] mr-0">
                  <p className="text-sm font-sans text-gray-400 md:mt-3 mt-0">
                    Total Experience
                  </p>
                  <p className="text-xs font-sans text-gray-800  font-bold">
                    {doctor.d_total_exp_years}+ years
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-gray-100 flex items-center justify-between lg:px-[3rem] md:px-4 px-3 md:h-full h-20">
              <div className="flex flex-col">
                <p className="text-2xl  font-bold font-sans text-orange-600">
                  ট {doctor.d_charge}{" "}
                  <span className="text-xs text-black">(incl. VAT)</span>
                </p>
                <p className="font-sans text-sm md:text-base">
                  Per consultation
                </p>
              </div>
              <HiOutlineArrowNarrowRight />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardView;
