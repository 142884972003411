import React from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';



const getClassNames = ({
  variant = '',
  size = 'lg',
  fullWidth,
  classes,
  borderColor,
}) => {
  let classNames =

    'btn normal-case  h-12	font-semibold	disabled:bg-gray-300 disabled:color-gray-300 disabled:cursor-not-allowed	rounded-xl ';
  switch (variant) {
    case 'primary':
      classNames += ' btn-info text-white border-0 ';
      break;
    case 'info':
      classNames += ' border-2 border-info text-info bg-transparent hover:bg-transparent hover:border-info';
      break;
    case 'secondary':
      classNames += ' btn-secondary text-white ';
      break;
    // transparent background color for back button
    case 'ghost':
      classNames += ' text-black border-2';
      break;
    case 'btn-inline':
      classNames += ' text-black ';
      break;
    default:
  }
  switch (size) {
    case 'lg':
      classNames += ' px-[36px] py-[14px] ';
      break;
    case 'md':
      classNames += ' px-[24px] py-[10px] ';
      break;
    case 'sm':
      classNames += ' px-[18px] py-[8px] ';
      break;
    case 'xs':
      classNames += ' px-[12px] py-[4px] ';
      break;
    default:
  }

  if (borderColor) {
    classNames += borderColor;
  }
  if (fullWidth) {
    classNames += ' w-full';
  }
  return `${classNames} ${classes || ''}`;
};

const AppButton = ({
  variant,
  classes,
  fullWidth,
  isLoading,
  disabled,
  children,
  size,
  link,
  borderColor,

  ...restProps
}) => {
  const navigate = useNavigate();
  const handleLinkClick = () => {
    if (link) {
        navigate(link);
    }
  };

  return (
    <button
      disabled={disabled || isLoading}
      className={getClassNames({
        variant,
        size,
        fullWidth,
        classes,
        borderColor,
      })}
      onClick={handleLinkClick}
      {...restProps}
    >
      {isLoading ? <Loader height='34px' variant="#ffffff" /> : children}
    </button>
  );
};

export default AppButton;
