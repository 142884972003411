import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "../../../../assets/loader.gif";
import {
  BASE_URL,
  endPoint,
} from "../../../../constants/ApiConstant/routeConstant";
import { useSelector } from "../../../../redux/store";
import { useToken } from "../../../../services/useAuth";
import CardView from "./CardView";

const View = () => {
  const { deleteSuccess } = useSelector((state) => state.modal);
  const [videos, setVideos] = useState([]);
  const [, setError] = useState();
  const [loading, setLoading] = useState();

  // //console.log(videos)
  const token = useToken();
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getData = async () => {
    setLoading(true);

    await axios
      .get(`${BASE_URL}${endPoint.video}`, header)
      .then((res) => {
        setLoading(false);

        if (res?.data?.success) {
          setVideos(res.data?.data);
        }
      })
      .catch((err) => {
        setLoading(false);

        setError(err.response.data.message);
      });
  };
  //console.log(videos);

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  return (
    <div>
      <div className="shadow-md w-full bg-white mt-10 h-[77vh] relative overflow-scroll custom-scroll rounded-xl">
        <div className="flex items-center justify-between fixed bg-white xl:w-[70%] w-full py-7 shadow-md rounded-xl">
          <p className="pl-5 text-xl font-bold ">Videos</p>
        </div>
        <div className=" mt-28 mb-10 mx-4 ">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Loader} style={{ width: "20%" }} alt='' />
            </div>
          ) : (
            <div className="grid 2xl:grid-cols-4 3xl:grid-cols-5 md:grid-cols-3 gap-5">
              {videos?.map((video, index) => {
                return <CardView key={index} video={video} />;
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default View;
