import React from "react";
import { Grid,  Box } from "@mui/material";
import GenaralInfo from "./about-details/GenaralInfo";
import RequiredService from "./about-details/RequiredService";
import PaymentInfo from "./about-details/PaymentInfo";
import DiscountInfo from "./about-details/DiscountInfo";
import Summary from "./about-details/Summary";
import FreeServices from "./about-details/FreeServices";
import ClassesTable from "./about-details/ClassesTable";

const About = ({ authorityInfo }) => {
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={8}>
          <GenaralInfo authorityInfo={authorityInfo} />
          <ClassesTable authorityInfo={authorityInfo} />
        </Grid>

        <Grid item xs={12} lg={4}>
          <RequiredService authorityInfo={authorityInfo} />
          <PaymentInfo authorityInfo={authorityInfo} />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={3}>
              <Summary authorityInfo={authorityInfo} />
            </Grid>

            <Grid item xs={12} lg={9}>
              <DiscountInfo authorityInfo={authorityInfo} />
              <FreeServices authorityInfo={authorityInfo} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default About;
