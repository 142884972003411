import React, { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { ImUserPlus } from "react-icons/im";
import { MdOutlineEmojiEvents } from "react-icons/md";
import { userTypes } from "../../constants/ApiConstant/databaseConstant";
import { useGetDashboardData } from "../../services/api";
import AppDashboardCard from "../ui/AppDashboardCard";

const OlympiadDashboard = () => {
  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const body = {
    type: userTypes.superAdmin,
  };
  const { getSADashboardData } = useGetDashboardData(body);

  useEffect(() => {
    setIsLoading(true);
    getSADashboardData()
      .then((res) => {
        if (res) {
          setDashboardData(res);
          setIsLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className="mt-20 xl:mt-10 mx-5 xl:mx-0">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        <AppDashboardCard
          title="Total Events"
          value={dashboardData.totalEvents}
          viewLink="/olympiad/events/view"
          bgColor="bg-indigo-50"
          textColor="text-indigo-600"
          icon={<MdOutlineEmojiEvents className="text-5xl text-indigo-600" />}
        />

        <AppDashboardCard
          title="Total Notices"
          value={dashboardData.totalNotices}
          viewLink="/olympiad/notices/view"
          bgColor="bg-green-50"
          textColor="text-green-600"
          icon={<AiOutlineInfoCircle className="text-5xl text-green-600" />}
        />

        <AppDashboardCard
          title="Total Olympiad Users"
          value={dashboardData.totalOlympiadUsers}
          viewLink="/olympiad/registered-students"
          bgColor="bg-yellow-50"
          textColor="text-yellow-600"
          icon={<ImUserPlus className="text-5xl text-yellow-600" />}
        />
      </div>
    </div>
  );
};

export default OlympiadDashboard;
