import { Grid } from "@mui/material";
import React from "react";
import { styles } from "../../../Common/Constant";
import Item from "../../../Common/Item";

const BasicInstitutionInfo = ({ profile }) => {
  return (
    <div style={styles.innerContainer}>
      <div style={styles.sectionContainer}>
        <h3 style={styles.subHeader}> Basic Information </h3>
        <Grid container>
          <Grid item xs={4} style={styles.itemContainer}>
            <Item tag={"Name"} value={profile.ins_name} />
          </Grid>

          <Grid item xs={4} style={styles.itemContainer}>
            <Item tag={"Institution Type"} value={profile.ins_type} />
          </Grid>

          <Grid item xs={4} style={styles.itemContainer}>
            <Item tag={"Establishment Year"} value={profile.ins_est_year} />
          </Grid>

          <Grid item xs={4} style={styles.itemContainer}>
            <Item tag={"Registration No"} value={profile.ins_reg_no} />
          </Grid>

          <Grid item xs={4} style={styles.itemContainer}>
            <Item tag={"Principal Name"} value={profile.ins_principle} />
          </Grid>

          <Grid item xs={4} style={styles.itemContainer}>
            <Item tag={"Login Phone No"} value={profile.user_phone_id} />
          </Grid>

          <Grid item xs={4} style={styles.itemContainer}>
            <Item tag={"Total Students"} value={profile.ins_total_students} />
          </Grid>
        </Grid>
      </div>

      <div style={styles.sectionContainer} break>
        <h4 style={styles.subHeader}> Address Information </h4>

        <Grid container>
          <Grid item xs={4} style={styles.itemContainer}>
            <Item tag={"Division"} value={profile.ins_division} />
          </Grid>

          <Grid item xs={4} style={styles.itemContainer}>
            <Item tag={"District"} value={profile.ins_district} />
          </Grid>

          <Grid item xs={4} style={styles.itemContainer}>
            <Item tag={"Upozila / Thana"} value={profile.ins_upozila} />
          </Grid>

          <Grid item xs={4} style={styles.itemContainer}>
            <Item tag={"Road No"} value={profile.ins_road_no} />
          </Grid>

          <Grid item xs={4} style={styles.itemContainer}>
            <Item tag={"House No "} value={profile.ins_house_no} />
          </Grid>
        </Grid>
      </div>

      <div style={styles.sectionContainer}>
        <h4 style={styles.subHeader}> Service Information </h4>
        <Grid container>
          <Grid item xs={4} style={styles.itemContainer}>
            <Item tag={"Service Type"} value={profile.ins_service_type} />
          </Grid>

          <Grid item xs={4} style={styles.itemContainer}>
            <Item tag={"Contract Start"} value={profile.ins_contact_start} />
          </Grid>

          <Grid item xs={4} style={styles.itemContainer}>
            <Item tag={"Contract End"} value={profile.ins_contact_end} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default BasicInstitutionInfo;
