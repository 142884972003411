import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    show: false,
    showZindex: false,
    deleteSuccess: false,
  },
  reducers: {
    handleShow(state, action) {
      if (!state.show) {
        state.show = true;
      }
    },
    handleBackgroudZIndexShow(state, action) {
      state.showZindex = action.payload;
    },
    handleDeleteSuccess(state, action) {
      state.deleteSuccess = action.payload;
    },

    handleClose(state, action) {
      if (state.show) {
        state.show = false;
      }
    },
  },
});

// Exports all actions
export const {
  handleClose,
  handleShow,
  handleBackgroudZIndexShow,
  handleDeleteSuccess,
} = modalSlice.actions;

export default modalSlice.reducer;
