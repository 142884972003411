import React, { useEffect, useState } from "react";
import {
  SuperAdminDashboard,
  SchoolAuthorityDashboard,
  DoctorDashboard,
} from "../components/sidebar/SidebarItems";
import { useSelector } from "../redux/store";

export const useAdminRole = () => {
  const { role } = useSelector((state) => state.userInfo);
  const [roleData, setRoleData] = useState([]);

  useEffect(() => {
    const getAdminRoles = () => {
      if (role) {
        if (role === "superAdmin") {
          return setRoleData(SuperAdminDashboard);
        }
        if (role === "schoolAuthority") {
          return setRoleData(SchoolAuthorityDashboard);
        }
        if (role === "doctor") {
          return setRoleData(DoctorDashboard);
        }
      } else return null;
    };
    getAdminRoles();
  }, [role]);

  return { roleData };
};
