import { Box, Grid, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { BASE_URL } from "../../../../../constants/ApiConstant/routeConstant";
import { useToken } from "../../../../../services/useAuth";
import { checkFile } from "../../../../../utils/Validation/formValidation";

const BasicInfo = ({
  formBasicInfo,
  errorsBasicInfo,
  onChangeBasicInfo,
  setErrorsBasicInfo,
  isEdit
}) => {
  const [proPicUploadLoading, setProPicUploadLoading] = useState();
  const token = useToken();
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const pickProPicAndUpload = async (e) => {
    if (checkFile(e.target.files, "image")) {
      //console.log(e.target.files);

      //upload to server start

      const file = e.target.files[0];

      const formData = new FormData();
      formData.append("image", file);
      try {
        setProPicUploadLoading(true);
        const url = `${BASE_URL}/api/upload/image`;
        const res = await axios.post(url, formData, headers);
        if (res.data.success) {
          onChangeBasicInfo({
            name: "proImg",
            value: res.data.data.path,
          });
          setErrorsBasicInfo((prev) => {
            return {
              ...prev,
              proImg: null,
            };
          });
        }
        setProPicUploadLoading(false);
      } catch (e) {
        //console.log(e);
      }

      //upload to server end
    } else {
      //setError
      setErrorsBasicInfo((prev) => {
        return {
          ...prev,
          proImg:
            "Image should be jpg, jpeg, png, svg,gif & size should be less that 2MB",
        };
      });
    }
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {" "}
            <TextField
              id="name"
              variant="outlined"
              fullWidth
              size="small"
              label={formBasicInfo?.name ? "Name" : "Name *"}
              value={formBasicInfo.name || ""}
              onChange={(e) => {
                onChangeBasicInfo({
                  name: "name",
                  value: e.target.value,
                });
              }}
              error={errorsBasicInfo?.name ? true : false}
              helperText={errorsBasicInfo?.name}
            />
          </Grid>
        
          <Grid item xs={12} sm={6}>
            <TextField
              id="education"
              multiline
              rows={3}
              fullWidth
              label={formBasicInfo?.degree ? "Degree" : "Degree *"}
              onChange={(e) => {
                onChangeBasicInfo({
                  name: "degree",
                  value: e.target.value,
                });
              }}
              value={formBasicInfo?.degree}
              error={errorsBasicInfo?.degree}
              helperText={errorsBasicInfo?.degree}
              
            />
          </Grid>{" "}
          <Grid item xs={12} sm={6}>
            {" "}
            <TextField
              id="totalYearsOfExperience"
              label={
                formBasicInfo?.totalYearsOfExperience
                  ? "Total Years Of Experience"
                  : "Total Years Of Experience *"
              }
              variant="outlined"
              fullWidth
              size="small"
              type="number"
              onChange={(e) => {
                onChangeBasicInfo({
                  name: "totalYearsOfExperience",
                  value: e.target.value,
                });
              }}
              value={formBasicInfo.totalYearsOfExperience || ""}
              error={errorsBasicInfo?.totalYearsOfExperience ? true : false}
              helperText={errorsBasicInfo?.totalYearsOfExperience}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <TextField
              id="phone"
              label={formBasicInfo?.phone ? "Phone Number" : "Phone Number *"}
              variant="outlined"
              fullWidth
              size="small"
              onChange={(e) => {
                onChangeBasicInfo({
                  name: "phone",
                  value: e.target.value,
                });
              }}
              value={formBasicInfo.phone || ""}
              error={errorsBasicInfo?.phone ? true : false}
              helperText={errorsBasicInfo?.phone}
            />
          </Grid>
          {!isEdit && (
            <Grid item xs={12} sm={6}>
              {" "}
              <div className="flex overflow-x-hidden">
                <Grid item xs={6}>
                  <p className="">Profile Pricture </p>
                </Grid>
                <Grid item xs={6}>
                  <input
                    type="file"
                    name="image"
                    onChange={pickProPicAndUpload}
                  />
                </Grid>
              </div>
              {formBasicInfo?.proImg && (
                <p className=" text-green-700 ">Uploaded</p>
              )}
              {errorsBasicInfo?.proImg && (
                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall text-red-700 MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">
                  {errorsBasicInfo?.proImg}
                </p>
              )}
              {proPicUploadLoading && (
                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall text-yellow-700 MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">
                  Uploading....
                </p>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default BasicInfo;
