import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  BASE_URL,
  endPoint,
} from "../../../../../constants/ApiConstant/routeConstant";
import { divisions } from "../../../../../constants/GeoLocation/geoLocation";
import { useToken } from "../../../../../services/useAuth";
import { getLocation } from "../../../../../utils/AddressFunctions/getLocation";
import AppButton from "../../../../ui/AppButton";
import AppModal from "../../../../ui/AppModal";
import { OlympiadData } from "../SARegisteredStudentsView";

const buttonApply = {
  variant: "primary",
  fullWidth: true,
  size: "xs",
  classes: " text-sm h-11 w-28 font-semibold",
};
const buttonClear = {
  variant: "info",
  fullWidth: true,
  size: "xs",
  classes: " text-sm h-11 w-28 font-semibold mr-2",
};
const FilterData = ({
  registeredStudentFilter,
  setRegisteredStudentFilter,
  handleFilter,
  setClearFilter,
  clearFilter,
}) => {
  const [isShow, setIsShow] = useState(false);
  const [districtData, setDistrictData] = useState();
  const [eventOptionts, setEventOptionts] = useState("");
  const [, setLoading] = useState();

  const token = useToken();

  const handleSelectedDivision = (param) => {
    setRegisteredStudentFilter({ ...registeredStudentFilter, division: param });
    const divId = divisions.filter((item) => item.name === param);
    const location = getLocation(divId[0].id);
    setDistrictData(location);
  };

  // Category List
  const getCategories = () => {
    let options = [];
    eventOptionts &&
      eventOptionts.filter((item) => {
        options.push({ value: item.e_id, label: item.e_title });
      });

    return options;
  };

  const getData = async () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    await axios
      .get(`${BASE_URL}${endPoint.eventGet}`, header)
      .then((res) => {
        setLoading(false);

        if (res?.data?.success) {
          setEventOptionts(res.data?.data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AppModal
        isShowModal={isShow}
        callback={(item) => setIsShow(item)}
        option
        modalTitle="Filter"
        modalTitleClass="text-xl"
        modalWidth="md:w-[420px] w-full"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="institueType"
                  options={getCategories().map((elm) => elm.label)}
                  fullWidth
                  size="small"
                  value={registeredStudentFilter.eventName || ""}
                  onChange={(event, newValue) => {
                    setRegisteredStudentFilter({
                      ...registeredStudentFilter,
                      eventName: newValue,
                      eventId: getCategories().filter(
                        (elm) => elm.label === newValue
                      )[0].value,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Event Name" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="division"
                  fullWidth
                  size="small"
                  options={divisions.map((div) => div.name)}
                  value={registeredStudentFilter.division || ""}
                  onChange={(event, newValue) => {
                    handleSelectedDivision(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Division" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="district"
                  options={
                    districtData ? districtData.map((dist) => dist.name) : []
                  }
                  fullWidth
                  size="small"
                  value={registeredStudentFilter.district || null}
                  onChange={(event, newValue) => {
                    setRegisteredStudentFilter({
                      ...registeredStudentFilter,
                      district: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="District" />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <div className="mt-52 flex justify-end">
            <AppButton
              {...buttonClear}
              onClick={() => {
                setClearFilter(!clearFilter);
                setRegisteredStudentFilter(OlympiadData);
                setIsShow(false);
              }}
            >
              Clear_Filter
            </AppButton>
            <AppButton
              {...buttonApply}
              onClick={() => {
                handleFilter();
                setIsShow(false);
              }}
            >
              Apply
            </AppButton>
          </div>
        </Box>
      </AppModal>
    </div>
  );
};

export default FilterData;
