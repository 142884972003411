import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../assets/loader.gif";
import {
  BASE_URL,
  endPoint,
} from "../../../../constants/ApiConstant/routeConstant";
import { useToken } from "../../../../services/useAuth";
import { notify } from "../../../ui/helpers/ToastMsg";

const EditNotice= () => {
  const [formNotice, setFormNotice] = useState({});
  const [errorsNotice, setErrorsNotice] = useState({});
  const [events, setEvents] = useState([]);
  const [eventError, setEventError] = useState();
  const [loading, setLoading] = useState();

  const [notices, setNotices] = useState({});
  const token = useToken();
  const { id } = useParams()
  const navigate=useNavigate()
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  ////console.log(events)
  const getData = async () => {
    await axios
      .get(`${BASE_URL}${endPoint.event}`, header)
      .then((res) => {
        if (res?.data?.success) {
          setEvents(res.data?.data);
        }
      })
      .catch((err) => {
        setEventError(err.response.data.message);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  //console.log(formNotice);

  const onSubmit = async () => {
    const eventId = events.find(
      (el) => el.e_title === formNotice.eventName,
    ).e_id;
    const bodyData = {
      olymId: eventId,
      title: formNotice.noticetitle,
      msg: formNotice.message,
      time: new Date(),
    };
    //console.log(bodyData);
    setLoading(true);
    await axios
      .put(`${BASE_URL}${endPoint.notice}/${id}`, bodyData, header)
      .then((res) => {
        setLoading(false);
        setFormNotice({});
        if (res?.data?.success) {
          //console.log(res.data?.data);
          notify("Notice Update Successfully!", "success");
          navigate("/olympiad/notices/view");
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
        notify(err.response.data?.message, "warning");
      });
  };

  const onChangeNotice = ({ name, value }) => {
    setFormNotice({ ...formNotice, [name]: value });
    if (name === "noticetitle") {
      if (value === "") {
        setErrorsNotice((prev) => {
          return {
            ...prev,
            [name]: "Notice title cann't be blank.",
          };
        });
      } else {
        setErrorsNotice((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "eventName") {
      if (value === "") {
        setErrorsNotice((prev) => {
          return {
            ...prev,
            [name]: "Event Name cann't be blank.",
          };
        });
      } else {
        setErrorsNotice((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "message") {
      if (value === "") {
        setErrorsNotice((prev) => {
          return {
            ...prev,
            [name]: "Message cann't be blank.",
          };
        });
      } else {
        setErrorsNotice((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
  };
  const onSubmitNotice = () => {
    if (!formNotice.noticetitle) {
      setErrorsNotice((prev) => {
        return {
          ...prev,
          noticetitle: "Please add a Notice Title",
        };
      });
    }
    if (!formNotice.eventName) {
      setErrorsNotice((prev) => {
        return {
          ...prev,
          eventName: "Please Select an Event Name",
        };
      });
    }
    if (!formNotice.message) {
      setErrorsNotice((prev) => {
        return {
          ...prev,
          message: "Please add an Event Message",
        };
      });
    }
    if (
      formNotice.noticetitle &&
      formNotice.eventName &&
      formNotice.message &&
      Object.values(errorsNotice).every((item) => !item)
    ) {
      setNotices({ ...formNotice });
      onSubmit();
    }
  };

  //console.log(notices);



  const getSingleEvent = () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${BASE_URL}${endPoint.noticeGet}/${id}`, header)
      .then((res) => {
        if (res?.data?.success) {
          //console.log(res.data?.data);
          const eventDetails = res.data?.data;
          setFormNotice({
            eventName: eventDetails?.e_title,
            noticetitle: eventDetails?.n_title,
            message: eventDetails?.n_msg,
          });
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    getSingleEvent();
  }, []);

  return (
    <div className="my-2 mt-10 shadow-md rounded-xl">
      <div className=" bg-white px-[3rem] py-[2rem] rounded-xl">
        {events.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!eventError && <img src={Loader} style={{ width: "20%" }} />}
          </div>
        ) : (
          <div>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    id="eventName"
                    options={events ? events.map((item) => item.e_title) : []}
                    fullWidth
                    size="small"
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    value={formNotice.eventName || ""}
                    onChange={(event, newValue) => {
                      onChangeNotice({
                        name: "eventName",
                        value: newValue,
                        //value: `${events.find((el)=> el.e_title===newValue).e_id}`,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={errorsNotice.eventName ? true : false}
                        helperText={errorsNotice?.eventName}
                        {...params}
                        label={
                          formNotice?.eventName
                            ? "Select Event"
                            : "Select Event" + " *"
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  {" "}
                  <TextField
                    id="noticetitle"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={formNotice?.noticetitle || ""}
                    label={
                      formNotice?.noticetitle
                        ? "Notice title"
                        : "Notice title *"
                    }
                    onChange={(e) => {
                      onChangeNotice({
                        name: "noticetitle",
                        value: e.target.value,
                      });
                    }}
                    error={errorsNotice?.noticetitle ? true : false}
                    helperText={errorsNotice?.noticetitle}
                  />
                </Grid>

                <Grid item xs={12}>
                  {" "}
                  <TextField
                    id="message"
                    variant="outlined"
                    fullWidth
                    size="small"
                    multiline
                    rows={5}
                    value={formNotice?.message || ""}
                    label={formNotice?.message ? "Message" : "Message *"}
                    onChange={(e) => {
                      onChangeNotice({
                        name: "message",
                        value: e.target.value,
                      });
                    }}
                    error={errorsNotice?.message ? true : false}
                    helperText={errorsNotice?.message}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
        )}

        <div className=" flex justify-center">
          <button
            disabled={loading}
            className="btn bg-blue-600 hover:bg-blue-600  border-none  font-bold mt-5 px-10 rounded-2xl  "
            onClick={() => onSubmitNotice()}
          >
            Upadte
          </button>
        </div>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "1rem",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </div>
    </div>
  );
};

export default EditNotice;
