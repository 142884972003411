import { Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL, endPoint } from '../../../../constants/ApiConstant/routeConstant';
import { useSelector } from '../../../../redux/store';
import { styles } from '../../Common/Constant';
import Item from '../../Common/Item';


const BasicInfo = ({profile}) => {

  const { token } = useSelector((state) => state.userInfo);

  const [registrationEvent, setRegistrationEvent] = useState([]);
  const getRegistrationEventData = async () => {
    const body = {
      oId: profile.o_id,
    };

    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(`${BASE_URL}${endPoint.eventRegGet}`, body, header)
      .then((res) => {
        if (res?.data?.success) {
          setRegistrationEvent(res?.data?.data);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    getRegistrationEventData();
  }, []);

  

  return (
    <div style={styles.innerContainer}>
    
    <div style={styles.sectionContainer}>
      <h3 style={styles.subHeader}>  Basic Information </h3>
<Grid container>
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Name"} value={profile.o_user_name} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Phone"} value={profile.user_phone_id} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Olympiad ID"} value={profile.o_id} /> 
  </Grid>

  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Father Name"} value={profile.o_ftr_name}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Mother Name"} value={profile.o_mtr_name}  /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Institute"} value={profile.o_institute} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Class"} value={profile.o_class} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Section"} value={profile.o_section} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Roll"} value={profile.o_roll} /> 
  </Grid>

  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Date Of Birth"} value={profile.o_dob}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Age"} value={profile.o_age}  /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Email"} value={profile.o_email} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Division"} value={profile.o_division} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"District"} value={profile.o_district} /> 
  </Grid>


  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Details Address"} value={profile.p_address}  /> 
  </Grid>

  
  
</Grid>
    

</div>

{
  registrationEvent.length>0 && (
    <div style={styles.sectionContainer}>
<h3 style={styles.subHeader}>  Registered Olympiad </h3>
<Grid container>

{
  registrationEvent.map((item)=> (
<Grid item xs={4} style={styles.itemContainer} >
<Item tag={"Event Name"} value={item.e_title} /> 
</Grid>
  ))
}



</Grid>


</div>
  )
}


   </div>
  )
}

export default BasicInfo
