import { Box, Grid, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  BASE_URL,
  endPoint
} from "../../../../constants/ApiConstant/routeConstant";
import { useSelector } from "../../../../redux/store";
import AppButton from "../../../ui/AppButton";
import AppCardContainer from "../../../ui/AppCardContainer";
import { notify } from "../../../ui/helpers/ToastMsg";

const AddPrescription = () => {
  const [ setIsShow] = useState(false);
  const { token } = useSelector((state) => state.userInfo);
  const { id } = useParams();
  const navigate = useNavigate();
  const [addPress,setAddPress] = useState()
  const [prescription,setPrescription] = useState()


  //console.log(id);

  const singlePrescriptionData = async () => {
    // const body = addPress;
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .get(`${BASE_URL}${endPoint.prescriptionGet}/${id}`, header)
      .then((res) => {
        if (res?.data?.success) {
          setIsShow(false);
          const presentData = res?.data?.data;
          setPrescription(presentData)
          setAddPress({
            chiefComplain: presentData?.pres_chief_complaint,
            illnessHistory: presentData?.pres_history_illness,
            extraFindings: presentData?.pres_exa_finding,
            diagnosis: presentData?.pres_d_diag,
            advice: presentData?.pres_advice,
            investigation: presentData?.pres_investigation,
            referrel: presentData?.pres_referrel,
            notes: presentData?.pres_notes,
            signatureImg: presentData?.pres_signature_img,
          });
        }
      })
      .catch((err) => {
        //console.log(err.message);
      });
  };
  useEffect(() => {
    singlePrescriptionData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addPrescription = async () => {
    const body = addPress;
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .put(`${BASE_URL}${endPoint.prescriptionEdit}/${id}`, body, header)
      .then((res) => {
        setIsShow(false);
        notify("Prescription Updated Successfully","success");
        navigate("/prescriptions");
      })
      .catch((err) => {
        //console.log(err.message);
      });
  };

  useEffect(() => {
    if (prescription) {
      setAddPress({
        ...addPress,
        apId: prescription?.ap_id,
        dId: prescription.d_id,
        pId: prescription?.p_id,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescription]);

  return (
    <div>
      <AppCardContainer spacing="xl:mt-10 3xl:-mr-10 md:mx-5 mt-20">
        <ToastContainer />

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="chiefComplain"
                label={
                  addPress?.chiefComplain
                    ? "Chief Complain"
                    : "Chief Complain *"
                }
                variant="outlined"
                fullWidth
                type="chiefComplain"
                size="small"
                multiline
                rows={3}
                value={addPress?.chiefComplain || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    chiefComplain: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="illnessHistory"
                label={
                  addPress?.illnessHistory
                    ? "Illness History"
                    : "Illness History *"
                }
                variant="outlined"
                fullWidth
                type="illnessHistory"
                size="small"
                multiline
                rows={3}
                value={addPress?.illnessHistory || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    illnessHistory: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="extraFindings"
                label={
                  addPress?.extraFindings
                    ? "Extra Findings"
                    : "Extra Findings *"
                }
                variant="outlined"
                fullWidth
                type="extraFindings"
                size="small"
                multiline
                rows={3}
                value={addPress?.extraFindings || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    extraFindings: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="diagnosis"
                label={addPress?.diagnosis ? "Diagnosis" : "Diagnosis *"}
                variant="outlined"
                fullWidth
                type="diagnosis"
                size="small"
                multiline
                rows={3}
                value={addPress?.diagnosis || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    diagnosis: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="advice"
                label={addPress?.advice ? "Advice" : "Advice *"}
                variant="outlined"
                fullWidth
                type="advice"
                size="small"
                multiline
                rows={3}
                value={addPress?.advice || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    advice: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="investigation"
                label={
                  addPress?.investigation ? "Investigation" : "Investigation *"
                }
                variant="outlined"
                fullWidth
                type="investigation"
                size="small"
                multiline
                rows={3}
                value={addPress?.investigation || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    investigation: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="referrel"
                label={addPress?.referrel ? "Referrel" : "Referrel *"}
                variant="outlined"
                fullWidth
                type="referrel"
                size="small"
                multiline
                rows={3}
                value={addPress?.referrel || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    referrel: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="notes"
                label={addPress?.notes ? "Notes" : "Notes *"}
                variant="outlined"
                fullWidth
                type="notes"
                size="small"
                multiline
                rows={3}
                value={addPress?.notes || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    notes: e.target.value,
                  });
                }}
              />
            </Grid>
          
          </Grid>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <AppButton
            variant="primary"
            size="md"
            classes="mt-5"
            onClick={addPrescription}
          >
            Update
          </AppButton>
        </Box>
      </AppCardContainer>
    </div>
  );
};

export default AddPrescription;
