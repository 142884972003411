import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React from "react";
const AppSteper = ({
  ind,
  setInd,
  firstStep,
  OtherSteps,
  children,
  getNextSt,
  loading,
  isEdit
}) => {
  return (
    <div className="my-2  mt-10 shadow-md rounded-xl w-full">
      <div className=" bg-white md:px-[3rem] px-5 py-[2rem] rounded-xl">
        <div className="flex justify-center">
          <ul className="steps">
            <li className="step step-primary font-bold text-xs md:text-sm lg:text-base">{firstStep}</li>
            {OtherSteps.map((item, index) => (
              <li
                key={index}
                className={`step font-bold text-xs md:text-sm lg:text-base ${
                  ind >= index + 1 ? "step-primary" : ""
                }`}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>

        <main className="py-10">{children}</main>

        <div className=" flex justify-center">
          <button
            disabled={ind === 0}
            onClick={() => setInd((prev) => prev - 1)}
            className="btn bg-orange-600  border-none  font-bold mt-5 px-10 rounded-2xl mr-4 hover:bg-orange-600  "
          >
            Back
          </button>
          {ind === OtherSteps.length ? (
            <button
              onClick={getNextSt}
              disabled={loading}
              className="btn bg-blue-600 hover:bg-blue-600  border-none  font-bold mt-5 px-10 rounded-2xl  "
            >
              
                 {isEdit? "Update":"Submit"}
            
            </button>
          ) : (
            <button
              disabled={ind === OtherSteps.length}
              onClick={getNextSt}
              className="btn bg-blue-600 hover:bg-blue-600  border-none  font-bold mt-5 px-10 rounded-2xl  "
            >
              Next
            </button>
          )}
          
        </div>
      </div>
      {loading && (
        <Box sx={{ display: 'flex',justifyContent:'center',paddingBottom:'1rem' }}>
           <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default AppSteper;
