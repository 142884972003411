export const tableConvertedData = (res) => {
  let data = [];
  res?.map((itm) => {
      data.push({
        className: itm?.ins_class_name || "--",
        gender: itm?.ins_class_gender || "--",
        totalStudents: itm?.total_student || "--",
      });
    });

  return data;
};
