export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const checkFile = (files, type) => {
  const myFile = files[0];
  const acceptedImageFiles = ["jpg", "jpeg", "png", "svg", "gif"];
  const acceptedPdfFiles = ["pdf"];
  if (Math.floor(myFile.size / 1024) > 2 * 1024) {
    //console.log(false);
    return false;
  }
  if (type === "pdf") {
    if (!acceptedPdfFiles.includes(myFile.name.split(".")[1])) {
      return false;
    }
  } else {
    if (!acceptedImageFiles.includes(myFile.name.split(".")[1])) {
      return false;
    }
  }

  return true;
};


export const valid_mobile = ( value )=> {
  /*When value not number then try to convert bangla to english number*/
  if (isNaN(value)) {
      value = translteBanglaToEngNum(value);
  }
  let valid_number = value.match("(?:\\+88|88)?(01[3-9]\\d{8})"); /*Regular expression to validate number*/
  /*When valid return without +88/88 number if exist*/
  if(valid_number){
      //return valid_number[1]; /*valid number method return 3 with actual input*/
      return true
  } else {
      return false; /*return false when not valid*/
  }
}


const translteBanglaToEngNum=( num_str )=>{
  var bengali = {"০":0, "১":1, "২":2, "৩":3, "৪":4, "৫":5, "৬":6, "৭":7, "৮":8, "৯":9};
  var changed_nun='';
  num_str.toString().split('').forEach(l => {
      if(isNaN(l)){changed_nun += bengali[l];}else{changed_nun +=l;}
  });
  return changed_nun;
}
