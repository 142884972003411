import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  useDispatch as useDispatchBase,
  useSelector as useSelectorBase,
} from "react-redux";
import thunkMiddleware from "redux-thunk";
import storage from "redux-persist/lib/storage";
import userInfoSlice from "./slices/userInfoSlice";
import { persistReducer, persistStore } from "redux-persist";
import modalSlice from "./slices/modalSlice";
import schoolAuthoritySlice from "./slices/schoolAuthoritySlice";
import tableDataForPdfSlice from "./slices/tableDataForPdfSlice";
import notificationSlice from "./slices/notificationSlice";
import drawerSlice from "./slices/drawerSlice";

// reducers
const rootReducer = combineReducers({
  userInfo: userInfoSlice,
  modal: modalSlice,
  schoolAuthority: schoolAuthoritySlice,
  tablePdf: tableDataForPdfSlice,
  notificationInfo: notificationSlice,
  drawer: drawerSlice,
});

// persisting only userInfo and schoolAuthority reducer
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userInfo", "schoolAuthority", "notificationInfo"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunkMiddleware],
});

export const persistor = persistStore(store);

export const useDispatch = () => useDispatchBase();
export const useSelector = (selector) => useSelectorBase(selector);
