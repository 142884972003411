import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../constants/ApiConstant/routeConstant";
import { useSelector } from "../../../redux/store";
import AppProfile from "../../ui/AppProfile";
import About from "./About";
import Settings from "./Settings";

const DProfile = () => {
  const [selectedNav, setSelectedNav] = useState();
  const [doctorInfo, setDoctorInfo] = useState([]);
  const { role, token, userInfo } = useSelector((state) => state.userInfo);

  

  const getdoctorInfo = async () => {
    const id = userInfo.user_id;
    const body = {
      profileType: role,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(`${BASE_URL}/api/user/get/${id}`, body, header)
      .then((res) => {
          if (res?.data?.message) {
            setDoctorInfo(res?.data?.data);
            //console.log(res?.data?.data)
          }
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  useEffect(() => {
    getdoctorInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return (
    <div>
      <AppProfile
        bgImgClass="bg-[url('./assets/doctor-bg.jpeg')]"
        callback={(item) => setSelectedNav(item)}
        profileImg={
          doctorInfo.user_img
            ? `${BASE_URL}/${doctorInfo.user_img}`
            : null
        }
        shortInfo={
          <div>
            <p className="font-extrabold ">{doctorInfo?.d_name}</p>
            <p className="text-sm text-gray-500">
              <span className="mr-1">{doctorInfo?.d_degree}, </span>
              <span className=" capitalize">{doctorInfo?.user_admin_type}</span>
            </p>
          </div>
        }
        othersInfo={
          <div>
            <p className="font-extrabold text-center">{doctorInfo?.d_total_exp_years}+</p>
            <p className="text-sm text-gray-500 text-center">Total Experience</p>
          </div>
        }
      >
        {selectedNav?.key === "About" && <About doctorInfo={doctorInfo} />}
        {selectedNav?.key === "Settings" && <Settings />}
      </AppProfile>
    </div>
  );
};

export default DProfile;
