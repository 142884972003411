import {
  Autocomplete,
  Box,
  Grid,
  Slider,
  TextField,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { divisions } from "../../../../../constants/GeoLocation/geoLocation";
import { getLocation } from "../../../../../utils/AddressFunctions/getLocation";
import AppButton from "../../../../ui/AppButton";
import AppModal from "../../../../ui/AppModal";
import { speciality } from "../../helper/options";
import { DocData } from "../View";

const buttonApply = {
  variant: "primary",
  fullWidth: true,
  size: "xs",
  classes: " text-sm h-11 w-28 font-semibold",
};
const buttonClear = {
  variant: "info",
  fullWidth: true,
  size: "xs",
  classes: " text-sm h-11 w-28 font-semibold mr-2",
};

const PrettoSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const FilterData = ({
  docFilter,
  setDocFilter,
  handleFilter,
  setClearFilter,
  clearFilter,
}) => {
  const [isShow, setIsShow] = useState(false);

  const [districtData, setDistrictData] = useState();

  const handleSelectedDivision = (param) => {
    setDocFilter({ ...docFilter, division: param });
    const divId = divisions.filter((item) => item.name === param);
    const location = getLocation(divId[0].id);
    setDistrictData(location);
  };

  return (
    <div>
      <AppModal
        isShowModal={isShow}
        callback={(item) => setIsShow(item)}
        option
        modalTitle="Doctor Filter"
        modalTitleClass="text-xl"
        modalWidth="md:w-[500px] h-full w-full"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="specialty"
                  options={speciality}
                  fullWidth
                  size="small"
                  value={docFilter.specialty || ""}
                  onChange={(event, newValue) => {
                    setDocFilter({ ...docFilter, specialty: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Specialty" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="division"
                  fullWidth
                  size="small"
                  options={divisions.map((div) => div.name)}
                  value={docFilter.division || ""}
                  onChange={(event, newValue) => {
                    handleSelectedDivision(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Division" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="district"
                  options={
                    districtData ? districtData.map((dist) => dist.name) : []
                  }
                  fullWidth
                  size="small"
                  value={docFilter.district || null}
                  onChange={(event, newValue) => {
                    setDocFilter({ ...docFilter, district: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="District" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Box
                  sx={{
                    width: "100%",
                    px: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{fontWeight:'bold'}}> Doctor fees</Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      my: 1,
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    ট0 - ট2000
                  </Typography>
                  <PrettoSlider
                    aria-label="Custom marks"
                    value={docFilter.fees}
                    onChange={(event, newValue) => {
                      setDocFilter({ ...docFilter, fees: newValue });
                    }}
                    min={0}
                    max={2000}
                    step={10}
                    valueLabelDisplay="auto"
                    disableSwap
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Box
                  sx={{
                    width: "100%",
                    mt: 3,
                    px: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mb: 1, fontWeight:'bold' }}> Year of experience </Typography>
                  <PrettoSlider
                    aria-label="Custom marks"
                    value={docFilter.experience}
                    onChange={(event, newValue) => {
                      setDocFilter({ ...docFilter, experience: newValue });
                    }}
                    min={0}
                    max={20}
                    step={0.5}
                    valueLabelDisplay="auto"
                    disableSwap
                    marks={[
                      {
                        value: 0,
                        label: "0 yr",
                      },
                      {
                        value: 20,
                        label: "20 yr",
                      },
                    ]}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <div className="mt-16 flex justify-end">
            <AppButton
              {...buttonClear}
              onClick={() => {
                setClearFilter(!clearFilter);
                setDocFilter(DocData);
                setIsShow(false);
              }}
            >
              Clear_Filter
            </AppButton>
            <AppButton
              {...buttonApply}
              onClick={() => {
                handleFilter();
                setIsShow(false);
              }}
            >
              Apply
            </AppButton>
          </div>
        </Box>
      </AppModal>
    </div>
  );
};

export default FilterData;
