import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineLink, AiOutlineSafetyCertificate } from "react-icons/ai";
import { BiIdCard } from "react-icons/bi";
import { BsCalendar2Date, BsCalendarCheck } from "react-icons/bs";
import { GrStatusInfo } from "react-icons/gr";
import { IoIdCardOutline } from "react-icons/io5";
import { BASE_URL, endPoint } from "../../../../constants/ApiConstant/routeConstant";
import { useSelector } from "../../../../redux/store";
import { useToken } from "../../../../services/useAuth";
import { timeConvertor } from "../../../../utils/timeConvertor/timeConvertor";

const PersonalInfo = ({ id }) => {
    const [singleEvents, setSingleEvents] = useState([]);
    const { deleteSuccess } = useSelector((state) => state.modal);
    const [, setEventsError] = useState();
    const [, setLoading] = useState();

    const token = useToken();
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
  };
  

    const getData = async () => {
      setLoading(true);

      await axios
        .get(`${BASE_URL}${endPoint.eventGet}/${id}`, header)
        .then((res) => {
          setLoading(false);

          if (res?.data?.success) {
            setSingleEvents(res.data?.data);
          }
        })
        .catch((err) => {
          setLoading(false);

          setEventsError(err.response.data.message);
        });
    };
    useEffect(() => {
      getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteSuccess]);
  
  
      let e_date = "--";
      if (singleEvents.e_date) {
        e_date = `${singleEvents?.e_date.slice(0, 10)}  ${timeConvertor(
          singleEvents?.e_date.slice(11, 19),
        )}`;
      }

   
  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiIdCard style={{ marginRight: 4 }} />
            Event Name
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {singleEvents.e_title}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoIdCardOutline style={{ marginRight: 4 }} />
            Event ID
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {singleEvents.e_id}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineSafetyCertificate style={{ marginRight: 4 }} />
            Division
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {singleEvents.e_division}
          </Typography>
        </Box>

        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <GrStatusInfo style={{ marginRight: 4 }} />
            District
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {singleEvents.e_district}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", my: 5, flexWrap:'wrap' }}>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Venue
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {singleEvents.e_venue}
          </Typography>
        </Box>

        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" }, my:{xs:3 , sm:0} }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendar2Date style={{ marginRight: 4 }} />
            Region
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {singleEvents.e_region_details}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" }, mt:{sm:3, md:0, xs:0} }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,

              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineLink style={{ marginRight: 4 }} />
            Date
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {e_date?.slice(0, 10)}{" "} {e_date?.slice(11, 22)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalInfo;
