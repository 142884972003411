import { Box, Typography } from "@mui/material";
import React from "react";
import {
  AiOutlineLink,
  AiOutlineSafetyCertificate
} from "react-icons/ai";
import { BiIdCard } from "react-icons/bi";
import {
  BsCalendar2Date,
  BsCalendarCheck
} from "react-icons/bs";
import { GrStatusInfo } from "react-icons/gr";
import { IoIdCardOutline } from "react-icons/io5";

const BasicMedicalHistory = ({ singleHealthRecord }) => {
  const {
    s_prev_illness,
    s_drug_history,
    s_vital_sign,
    s_blood_pressure,
    s_pulse,
    s_temp,
    s_height,
    s_weight,
    s_cv_l_e,
    s_cv_r_e,
    s_va_l_f,
    s_va_l_n,
    s_va_r_f,
    s_va_r_n,
    s_va_b_f,
    s_va_b_n,
    s_va_cmnt,
    s_diagnosis,
  } = singleHealthRecord;
  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiIdCard style={{ marginRight: 4 }} />
            Any known previous illness
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_prev_illness}
          </Typography>
        </Box>

        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiIdCard style={{ marginRight: 4 }} />
             Drug History
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_drug_history}
          </Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoIdCardOutline style={{ marginRight: 4 }} />
            Medication History
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_drug_history}
          </Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineSafetyCertificate style={{ marginRight: 4 }} />
            Vital Signs
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_vital_sign}
          </Typography>
        </Box>

        
      </Box>

      <Box sx={{ display: "flex", my: 5 }}>
      <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <GrStatusInfo style={{ marginRight: 4 }} />
            Blood Pressure
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_blood_pressure}
          </Typography>
        </Box>
        
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Pulse
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_pulse}
          </Typography>
        </Box>

        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendar2Date style={{ marginRight: 4 }} />
            Temperature
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_temp}
          </Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,

              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineLink style={{ marginRight: 4 }} />
            Height(in Fts)
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_height}
          </Typography>
        </Box>
        
      </Box>

      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt:3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineLink style={{ marginRight: 4 }} />
            Height(in inches)
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {}
          </Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiIdCard style={{ marginRight: 4 }} />
            Weight(in kg)
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_weight}
          </Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoIdCardOutline style={{ marginRight: 4 }} />
            Color Vision Left Eye
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_cv_l_e}
          </Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineSafetyCertificate style={{ marginRight: 4 }} />
            Color Vision Right Eye
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_cv_r_e}
          </Typography>
        </Box>

        
      </Box>

      <Box sx={{ display: "flex", my: 5 }}>
      <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <GrStatusInfo style={{ marginRight: 4 }} />
            Visual Acquity Left Eye(Far Vision)
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_va_l_f}
          </Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Visual Acquity Right Eye(Far Vision)
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_va_r_f}
          </Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Visual Acquity Left Eye(Near Vision)
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_va_l_n}
          </Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Visual Acquity Right Eye(Near Vision)
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_va_r_n}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", my: 5 }}>
        <Box sx={{ width: "33%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendar2Date style={{ marginRight: 4 }} />
            Visual Acquity Both Eye (Far Vision)
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_va_b_f}
          </Typography>
        </Box>
        <Box sx={{ width: "33%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendar2Date style={{ marginRight: 4 }} />
            Visual Acquity Both Eye (Near Vision)
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_va_b_n}
          </Typography>
        </Box>
        <Box sx={{ width: "33%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,

              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineLink style={{ marginRight: 4 }} />
            Comment
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_va_cmnt}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BasicMedicalHistory;
