import * as React from "react";

import { Autocomplete, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ToastContainer } from "react-toastify";
// import Loader from '../../../../../assets/loader.gif';
import { notify } from "../../../../ui/helpers/ToastMsg";
import Loader from "../../../../ui/Loader";
const InstitutionalInfo = ({
  formInstitutionalInfo,
  errorsInstitutionalInfo,
  onChangeInstitutionalInfo,
  institutions,
  error,
  isEdit
}) => {
  var dateItem = [];
  for (var i = 1800; i <= 2100; i++) {
    dateItem.push({
      label: `${i}`,
      value: `${i}`,
    });
  }
  React.useEffect(() => {
    if (error) {
      notify(error, "danger");
    }
  }, [error]);

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="educationalInstitution"
              options={
                institutions ? institutions.map((item) => item.ins_name) : []
              }
              fullWidth
              size="small"
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              value={formInstitutionalInfo.educationalInstitution || ""}
              onChange={(event, newValue) => {
                onChangeInstitutionalInfo({
                  name: "educationalInstitution",
                  value: newValue,
                  //value: `${institutions.find((el)=> el.ins_name===newValue).ins_id}`,
                });
              }}
              renderInput={(params) => (
                <TextField
                  error={
                    errorsInstitutionalInfo.educationalInstitution
                      ? true
                      : false
                  }
                  helperText={errorsInstitutionalInfo?.educationalInstitution}
                  {...params}
                  label={
                    !(institutions.length > 0) ? (
                      <span className="flex items-center justify-center w-full">
                        <Loader variant="#3498db" height="25px" />
                      </span>
                    ) : formInstitutionalInfo?.educationalInstitution ? (
                      "Select Institution"
                    ) : (
                      "Select Institution" + " *"
                    )
                  }
                />
              )}
            />
          </Grid>
          {
            !isEdit && (
              <Grid item xs={12}>
          <TextField
          id="teacherId"
          fullWidth
          type="number"
          variant="outlined"
          value={formInstitutionalInfo.teacherId || ""}
          onChange={( e) => {
            onChangeInstitutionalInfo({
              name: "teacherId",
              value: e.target.value,
            });
          }}
          error={
            errorsInstitutionalInfo.teacherId
              ? true
              : false
          }
          helperText={errorsInstitutionalInfo?.teacherId}
          label={"Unique Teacher ID"}
        />
          </Grid>
            )
          }

          
        </Grid>
      </Box>
      <ToastContainer />
    </div>
  );
};

export default InstitutionalInfo;
