import React from "react";
import OlympiadDashboard from "../../components/Olympiad/OlympiadDashboard";

const Olympiad = () => {
  return (
    <div>
      <OlympiadDashboard />
    </div>
  );
};

export default Olympiad;
