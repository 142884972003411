import { Box } from "@mui/material";
import React from "react";
import {
  BsFileEarmarkPdf
} from "react-icons/bs";
import { IoMdImages } from "react-icons/io";
import {
  MdDownload
} from "react-icons/md";

import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  endPoint
} from "../../../../../constants/ApiConstant/routeConstant";

const UploadedDocuments = ({ singleStudents }) => {
  const {
    sId,
    
  } = singleStudents;

  const [uploadedDocument, setUploadedDocument] = useState([]);
  const { token } = useSelector((state) => state.userInfo);
  
  const getUploadedDocument = async () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .get(`${BASE_URL}${endPoint.uploadDocGet}/${sId}`, header)
      .then((res) => {
        if (res?.data?.success) {
          setUploadedDocument(res.data?.data);
        }
      })
      .catch((err) => {
        //console.log(err);
        //notify(err.message,"danger");
      });
  };
  useEffect(() => {
    getUploadedDocument();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box sx={{ px: 2, py: 2 }}>
      {uploadedDocument?.length === 0 ? (
        <p>No Data Found</p>
      ) : (
        uploadedDocument?.map((item) => {
          if (item.doc_format === "pdf" || item.doc_format === ".pdf") {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Box sx={{ width: "100%" }}>
                  <div className="flex flex-col md:flex-row lg:w-full mb-5 border-2	p-4">
                    <p className="w-[30%]">Pdf</p>
                    <p className="pr-3 my-3 md:my-0 w-[8%]">
                      <BsFileEarmarkPdf className="text-red-700  text-5xl  	" />
                    </p>
                    <div className="  w-[54%]">
                      <p className=" font-bold">{item.doc_titile}</p>
                      {/* <p>02:48:20</p> */}
                    </div>

                    <a
                      href={`${BASE_URL}/${item.download_url}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MdDownload className=" mt-3 text-2xl text-green-600 cursor-pointer" />
                    </a>
                  </div>
                </Box>
              </Box>
            );
          } else  {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Box sx={{ width: "100%" }}>
                  <div className="flex flex-col md:flex-row lg:w-full mb-5 border-2	p-4">
                    <p className="w-[30%]">Image</p>
                    <p className="pr-3 my-3 md:my-0 w-[8%]">
                      <IoMdImages className="text-green-500	text-5xl  	" />
                    </p>
                    <div className="  w-[54%]">
                      <p className=" font-bold ">{item.doc_titile}</p>
                      {/* <p>02:48:20</p> */}
                    </div>

                    <a
                      href={`${BASE_URL}/${item.download_url}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MdDownload className=" mt-3 text-2xl text-green-600 cursor-pointer" />
                    </a>
                  </div>
                </Box>
              </Box>
            );
          }
        })
      )}
    </Box>
  );
};

export default UploadedDocuments;