export const routes = [
  "/",
  "/dashboard",
  "/settings",
  "/school-authority-profile",
  "/doctor-profile",
  "/patients",
  "/appointments",
  "/prescriptions",
  "/students",
  "/teachers",
  "/statistics",
  "/notifications",
  "/pdfview/schoolAuthorityList",
];

export const profileRoutes = ["/school-authority-profile", "/doctor-profile"];
