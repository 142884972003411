import HealthRecord from "./HealthRecord";

export default function HealthRecordsContainer({ singleStudents }) {
  return (
    <>
      {singleStudents?.healthRecords?.length === 0
        ? "No Data Found"
        : singleStudents?.healthRecords?.map((singleHealthRecord) => (
            <HealthRecord
              singleHealthRecord={singleHealthRecord}
              singleStudents={singleStudents}
            />
          ))}
    </>
  );
}
