export const tableConvertedData = (res) => {
  let data = [];
  res?.map((itm) => {
    data.push({
      userId: itm?.user_id || "--",
      userPhoneId: itm?.user_phone_id || "--",
      userName: itm?.user_name || "--",
      userImg: itm?.user_img || "--",
      userSchlHealth: itm?.user_schl_health || "--",
      userTelemedicine: itm?.user_telemedicine || "--",
      userOlymp: itm?.user_olymp || "--",
      userHijama: itm?.user_hijama || "--",
      userAdminType: itm?.user_admin_type || "--",
      hId: itm?.h_id || "--",
      hName: itm?.h_name || "--",
      hEmail: itm?.h_email || "--",
      paymentStatus: itm?.payment_status || "--",
      courseStatus: itm?.course_status || "--",
    });
  });

  return data;
};
