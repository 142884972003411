
// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)

import React from "react";
import { styles } from "../../Common/Constant";
import Footer from '../../Common/Footer';
import Header from '../../Common/Header';
import MyTable from '../../Common/Table';

export const OlympiadUserTableToPrint = React.forwardRef((props, ref) => {


 const headerData = ['NO','Name','ID','Phone','Email','Address','Institute','Class','Section','Roll']

//   const bodyData =props.data?.map((item)=>{
//     return [item.o_user_name,item.o_id,item.user_phone_id,item.o_email,`${item.o_district},${item.o_division}`,item.o_institute,item.o_class,item.o_section,item.o_roll]
//  })

const bodyData =props.data?.map((item)=>{
  return [item.oUserName,item.oId,item.userPhoneId,item.oEmail,`${item.oDistrict},${item.oDivision}`,item.oInstitute,item.oClass,item.oSection,item.oRoll]
})

  return (
    <div ref={ref} style={styles.page}>
      <Header profile={props.data} proType={props.proType} title={"Olympiad User List"} />
      <div style={styles.container}>
        <div style={styles.leftColumn}>
          <div style={styles.container}>

          {
            props.data.length>0 ? (
          <MyTable 
            headersData={headerData}
            bodyData = {bodyData}
          />
            ) : <h3 style={{textAlign:'center'}}> No Class found!</h3>
          }

          </div>
          

        </div>

        
        
      </div>
      <Footer/>
    </div>
  );
});