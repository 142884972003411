import { createSlice } from "@reduxjs/toolkit";

export const drawerSlice = createSlice({
  name: "drawer",
  initialState: {
    drawerState: false,
  },
  reducers: {
    handleShowDrawer(state, action) {
      state.drawerState = action.payload;
    },
  },
});

export const { handleShowDrawer } = drawerSlice.actions;

export default drawerSlice.reducer;
