import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsCardChecklist } from "react-icons/bs";
import { pdfTypes, userTypes } from "../../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint
} from "../../../../constants/ApiConstant/routeConstant";
import { patientsPdfFnc } from "../../../../redux/slices/tableDataForPdfSlice";
import { useDispatch, useSelector } from "../../../../redux/store";
import AppTable from "../../../ui/AppTable";
import { DynamicTableDataForSAPatients } from "../../../ui/helpers/table/TableBodyData";
import { tableHeadSAPatientsData } from "../../../ui/helpers/table/TableHeadeData";
import { useConvertedTableData } from "../../../ui/helpers/table/useConvertedTableData";
import { tableConvertedData } from "./helper/convertedPatientsData";
import FilterData from "./helper/FilterData";

export const PatientData = {
  gender: "",
};

const SAPatientsView = () => {
  const { token } = useSelector((state) => state.userInfo);
  // const { patientsPdf } = useSelector((state) => state.tablePdf);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [patients, setPatients] = useState([]);
  const { deleteSuccess } = useSelector((state) => state.modal);
  const [searchValue, setSearchValue] = useState("");
  const [clearFilter, setClearFilter] = useState(false);
  const [patientFilter, setPatientFilter] = useState(PatientData);

  const limit = 6;

  const { tableData, pageCount,  handlePageClick } =
    useConvertedTableData(patients, limit);

  const getAllPatientsData = async () => {
    const body = {
      profileType: userTypes.telemedicine,
    };

    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    await axios
      .post(`${BASE_URL}${endPoint.userGet}`, body, header)
      .then((res) => {
        if (res?.data?.success) {
          setPatients(tableConvertedData(res?.data?.data));
          setLoading(false);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const getAllPatientSearchedData = async () => {
    if (!(searchValue === "")) {
      const body = {
        type: userTypes.telemedicine,
        data: searchValue,
      };

      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);
      await axios
        .post(`${BASE_URL}${endPoint.search}`, body, header)
        .then((res) => {
          if (res?.data?.success) {
            setPatients(tableConvertedData(res?.data?.data));
            setLoading(false);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    } else {
      getAllPatientsData();
    }
  };

  const handleFilter = async () => {
    if (patientFilter.gender === "") {
      getAllPatientsData();
    } else {
      const body = {
        type: userTypes.telemedicine,
        data: patientFilter,
      };

      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);
      await axios
        .post(`${BASE_URL}${endPoint.filter}`, body, header)
        .then((res) => {
          if (res?.data?.success) {
            setPatients(tableConvertedData(res?.data?.data));
            setLoading(false);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };

  const handleDeleteUser = async ( userId, id) => {
    const body = {
      profileType:  userTypes.telemedicine,
      userId: userId,
      id: id,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios
      .post(`${BASE_URL}${endPoint.deleteUser}`, body, header)
      .then((res) => {
        //console.log(res.data.message);
        return res.data.message;
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    if (patients) {
      dispatch(patientsPdfFnc(patients));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patients, searchValue]);

  useEffect(() => {
    getAllPatientsData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess, clearFilter]);

  useEffect(() => {
    getAllPatientSearchedData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div className="3xl:-mr-10 mx-5 mt-20 xl:mx-0 xl:mt-0">
      <AppTable
        tableTitle="Patients Information"
        titleClass="font-bold"
        titleIcon={<BsCardChecklist />}
        search
        searchPlaceHolder="Search by patient name, id and dob"
        searchVal={(val) => setSearchValue(val)}
        filter
        filterBox={
          <FilterData
            patientFilter={patientFilter}
            setPatientFilter={setPatientFilter}
            handleFilter={handleFilter}
            setClearFilter={setClearFilter}
            clearFilter={clearFilter}
          />
        }
        handleDelete={handleDeleteUser}
        tableHeadData={tableHeadSAPatientsData}
        tableBodyData={tableData}
        dynamicTable={(colItem, headItem, index, shadow, handleDelete) =>
          DynamicTableDataForSAPatients(colItem, headItem, index, shadow, handleDelete)
        }
        pdf={`/pdfview/${pdfTypes.telemedicineList}`}
        loader={loading}
        pagination
        pageCount={pageCount}
        handlePageClick={handlePageClick}
      />
    </div>
  );
};

export default SAPatientsView;
