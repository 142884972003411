
import React, { useEffect, useState } from "react";
import { FaGrinBeamSweat } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";
import { MdSick } from "react-icons/md";
import { RiMentalHealthFill } from "react-icons/ri";
import { userTypes } from "../../constants/ApiConstant/databaseConstant";
import { useSelector } from "../../redux/store";
import { useGetDashboardData } from "../../services/api";
import AppDashboardCard from "../ui/AppDashboardCard";

const SADashboard = () => {
  const { userInfo } = useSelector((state) => state.userInfo);
  const [, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState({});

  const body = {
    type: userTypes.schoolAuthority,
    id: userInfo.schoolAuthority.ins_id,
  };

  const { getSADashboardData } = useGetDashboardData(body);

  useEffect(() => {
    setIsLoading(true);
    getSADashboardData()
      .then((res) => {
        setDashboardData(res);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-20 xl:mt-10 xl:-mr-16">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        <AppDashboardCard
          title="Total Students"
          value={dashboardData?.totalStudents}
          viewLink="/students"
          bgColor="bg-orange-50"
          textColor="text-orange-600"
          icon={<HiUserGroup className="text-5xl text-orange-600" />}
        />

        <AppDashboardCard
          title="Total Healthy Students"
          value={dashboardData?.totalHealthy}
          viewLink="/statistics"
          bgColor="bg-green-50"
          textColor="text-green-600"
          icon={<RiMentalHealthFill className="text-5xl text-green-600" />}
        />
        <AppDashboardCard
          title="Total Need Attention Students"
          value={dashboardData?.totalNeedAttention}
          viewLink="/statistics"
          bgColor="bg-blue-50"
          textColor="text-blue-600"
          icon={<FaGrinBeamSweat className="text-5xl text-blue-600" />}
        />
        <AppDashboardCard
          title="Total Sick Students"
          value={dashboardData?.totalSick}
          viewLink="/statistics"
          bgColor="bg-red-50"
          textColor="text-red-600"
          icon={<MdSick className="text-5xl text-red-600" />}
        />
      </div>
    </div>
  );
};

export default SADashboard;
