import { Grid } from '@mui/material'
import React from 'react'
import { styles } from '../../../Common/Constant'
import Item from '../../../Common/Item'

const HealthRecordInfo = ({healthRecord}) => {
  return (
    <div style={{marginTop:10,borderTop:'1px solid black'}}>
    
    <div style={{display:'flex',justifyContent:'space-between',marginTop:10}} >
    
    <p style={{
      fontSize: 18,
      fontWeight: 'bold'
    }} >
    Checkup Date: {healthRecord.s_checkup_date}
    </p>

    <p style={{
      fontSize: 18,
      fontWeight: 'bold'
    }} >
    Data Entry Date: {healthRecord.s_input_date}
    </p>

    </div>


    <div style={styles.innerContainer}>
    
    <div style={styles.sectionContainer}>
      <h3 style={styles.subHeader}>  Basic Medical History </h3>
<Grid container>
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Previous Illness"} value={healthRecord.s_prev_illness} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Drug History"} value={healthRecord.s_drug_history} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Medication History"} value={healthRecord.s_drug_history} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Vital Signs"} value={healthRecord.s_vital_sign} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Blood Pressure"} value={healthRecord.s_blood_pressure}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Pulse"} value={healthRecord.s_pulse}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Temperature"} value={healthRecord.s_temp} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Height"} value={healthRecord.s_height} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Weight"} value={healthRecord.s_weight} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"BMI"} value={healthRecord.s_bmi} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Color Vision Left Eye"} value={healthRecord.s_cv_l_e} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Color Vision Right Eye"} value={healthRecord.s_cv_r_e} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Visual Acquity Left Eye(Far Vision)"} value={healthRecord.s_va_l_f} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Visual Acquity Right Eye(Far Vision)"} value={healthRecord.s_va_r_f} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Visual Acquity Left Eye(Near Vision)"} value={healthRecord.s_va_l_n} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Visual Acquity Right Eye(Near Vision)"} value={healthRecord.s_va_r_n} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Visual Acquity Both Eye (Far Vision)"} value={healthRecord.s_va_b_f} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Visual Acquity Both Eye (Near Vision"} value={healthRecord.s_va_b_n} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Comment"} value={healthRecord.s_va_cmnt} /> 
  </Grid>
  
  
</Grid>

       
       

</div>


<div style={styles.sectionContainer} >
<h4 style={styles.subHeader}> General Examination </h4> 

<Grid container>
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Appearance"} value={healthRecord.s_appearance} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Body Built"} value={healthRecord.s_body_built} /> 
  </Grid>
  

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Anemia"} value={healthRecord.s_anemia} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Jaundice"} value={healthRecord.s_jaundice}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Cyanosis"} value={healthRecord.s_cyanosis}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Nail condition"} value={healthRecord.s_nail}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Ear"} value={healthRecord.s_ear}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Nose"} value={healthRecord.s_nose}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Throat"} value={healthRecord.s_throat}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Limbs"} value={healthRecord.s_limbs}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"General Skin Condition"} value={healthRecord.s_skin}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Lymph Nodes"} value={healthRecord.s_lymph}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Others"} value={healthRecord.s_others}  /> 
  </Grid>

  

  
</Grid>


</div>

<div style={styles.sectionContainer}>
<h4 style={styles.subHeader}> Systemic Examination </h4> 
<Grid container>
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Respiratory System"} value={healthRecord.s_res_sys} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Cardiovascular System"} value={healthRecord.s_car_sys} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Oral and Abdominal System"} value={healthRecord.s_oral_sys} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Nervous System"} value={healthRecord.s_nervous_sys} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Musculoskeletal System"} value={healthRecord.s_muscu_sys} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Urogenital System"} value={healthRecord.s_uro_sys} /> 
  </Grid>
  
</Grid>



      
    </div>


<div style={styles.sectionContainer}>
<h4 style={styles.subHeader}> Remarks </h4> 
<Grid container>
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Diagnosis"} value={healthRecord.s_diagnosis} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Treatment"} value={healthRecord.s_treatment} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Referral(if needed)"} value={healthRecord.s_referral} /> 
  </Grid>

  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Health Status"} value={healthRecord.s_health_status} /> 
  </Grid>
  
  
</Grid>



      
    </div>

   </div>
    
    </div>
  )
}

export default HealthRecordInfo