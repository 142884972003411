import { Box, Typography } from "@mui/material";
import React from "react";
import {
  AiOutlineSafetyCertificate
} from "react-icons/ai";
import { BiIdCard } from "react-icons/bi";
import {
  BsCalendarCheck
} from "react-icons/bs";
import { GrStatusInfo } from "react-icons/gr";
import { IoIdCardOutline } from "react-icons/io5";

const SystemicExamination = ({ singleHealthRecord }) => {
  const {
    s_res_sys,
    s_car_sys,
    s_oral_sys,
    s_nervous_sys,
    s_muscu_sys,
    s_uro_sys,
  } = singleHealthRecord;
  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiIdCard style={{ marginRight: 4 }} />
            Respiratory System
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_res_sys}
          </Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoIdCardOutline style={{ marginRight: 4 }} />
            Cardiovascular System
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_car_sys}
          </Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineSafetyCertificate style={{ marginRight: 4 }} />
            Oral and Abdominal System
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_oral_sys}
          </Typography>
        </Box>

        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <GrStatusInfo style={{ marginRight: 4 }} />
            Nervous System
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_nervous_sys}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", my: 5 }}>
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Musculoskeletal System
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_muscu_sys}
          </Typography>
        </Box>

        <Box sx={{ width: "25%" }}>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: 14,
            display: "flex",
            alignItems: "center",
          }}
        >
          <BsCalendarCheck style={{ marginRight: 4 }} />
          Urogenital System
        </Typography>
        <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
          {s_uro_sys}
        </Typography>
      </Box>
      </Box>
      
  
    </Box>
  );
};

export default SystemicExamination;
