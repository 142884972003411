import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import axios from "axios";
import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  pdfTypes,
  userTypes,
} from "../../../../constants/ApiConstant/databaseConstant";
import { BASE_URL, endPoint } from "../../../../constants/ApiConstant/routeConstant";
import { useSelector } from "../../../../redux/store";
import AppBredcrumbs from "../../../ui/AppBredcrumbs";
import AppCardContainer from "../../../ui/AppCardContainer";
import AppSubNav from "../../../ui/AppSubNav";
import AppTableActionButton from "../../../ui/AppTableActionButton";
import PersonalInfo from "./PersonalInfoDetails";

const DoctorsInfo = () => {
  const { token } = useSelector((state) => state.userInfo);
  const location = useLocation();
  const { data } = location.state;
  const [, setSelectedNav] = useState();
  const { id } = useParams();

  
  const handleDeleteUser = async ( userId, id) => {
    const body = {
      profileType:  userTypes.doctor,
      userId: userId,
      id: id,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios
      .post(`${BASE_URL}${endPoint.deleteUser}`, body, header)
      .then((res) => {
        //console.log(res.data.message);
        return res.data.message;
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  return (
    <div>
      <AppCardContainer spacing="xl:mt-10 3xl:-mr-16 mx-5 mt-20">
        <div className="flex md:flex-row flex-col items-center justify-between">
          <AppBredcrumbs
            link="/telemedicine/doctors/view"
            allTitle="All Doctors"
            detailsTitle="Doctor Details"
          />
        <div className="flex items-center mt-3 md:mt-0">
        <Link
            to={`/pdfview/${pdfTypes.doctor}/${id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SimCardDownloadIcon
              style={{ color: "red", cursor: "pointer" }}
              fontSize="large"
            />
          </Link>
          <AppTableActionButton
            isView={false}
            isEdit
            editLink={`/telemedicine/doctors/edit/${id}`}
            deleteFunction={() =>
              handleDeleteUser( data?.user_id, data?.d_id)
            }
            redirectTo="/telemedicine/doctors/view"
          />
        </div>
        </div>

        <div className="mt-10">
          <AppSubNav
            navbarData={["Personal Information"]}
            callback={(item) => setSelectedNav(item)}
            type="tab"
            width="w-40"
          />
        </div>

        <PersonalInfo data={data} />
      </AppCardContainer>
    </div>
  );
};

export default DoctorsInfo;
