import { Box, Typography } from "@mui/material";
import React from "react";
import {
  AiOutlineLink,
  AiOutlineSafetyCertificate
} from "react-icons/ai";
import { BiIdCard } from "react-icons/bi";
import {
  BsCalendar2Date,
  BsCalendarCheck
} from "react-icons/bs";
import { GrStatusInfo } from "react-icons/gr";
import { IoIdCardOutline } from "react-icons/io5";

const InstitutionalInfo = ({ singleStudents }) => {
  const {
    userPhoneId,
   
    tAccountStatus,
    insType,
    insName,
    insEstYear,
    insRegNo,
    insPrinciple,
    insTotalStudents,
  } = singleStudents;
  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiIdCard style={{ marginRight: 4 }} />
            Intitute Type
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insType}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoIdCardOutline style={{ marginRight: 4 }} />
            Institute Name
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insName}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineSafetyCertificate style={{ marginRight: 4 }} />
            Established Year
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insEstYear}
          </Typography>
        </Box>

        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <GrStatusInfo style={{ marginRight: 4 }} />
            Registration Number
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insRegNo}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", my: 5 , flexWrap:'wrap'}}>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Name Of Principal
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insPrinciple}
          </Typography>
        </Box>

        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendar2Date style={{ marginRight: 4 }} />
            Total Students
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insTotalStudents}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,

              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineLink style={{ marginRight: 4 }} />
            Phone Number
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {userPhoneId}
          </Typography>
        </Box>

        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,

              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineLink style={{ marginRight: 4 }} />
            Account Status
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {tAccountStatus}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default InstitutionalInfo;
