import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { userTypes } from "../../../../constants/ApiConstant/databaseConstant";
import { BASE_URL, endPoint } from "../../../../constants/ApiConstant/routeConstant";
import { useToken } from "../../../../services/useAuth";
import AppSteper from "../../../ui/AppSteper";
import { notify } from "../../../ui/helpers/ToastMsg";
import AddInstituteStudents from "./Forms/AddInstituteStudents";
import BasicInfo from "./Forms/BasicInfo";
import InstituteAddress from "./Forms/InstituteAddress";
import ServiceInfo from "./Forms/ServiceInfo";

const Add = () => {
  const {id}= useParams();
  const [insId,setInsId] = useState()
  const navigate = useNavigate();

  const [ind, setInd] = useState(0);

  const [formBasicInfo, setFormBasicInfo] = useState({});
  const [errorsBasicInfo, setErrorsBasicInfo] = useState({});

  const [formAddressInfo, setformAddressInfo] = useState({});
  const [errorsAddressInfo, setErrorsAddressInfo] = useState({});

  const [formServiceInfo, setformServiceInfo] = useState({});
  const [errorsServiceInfo, setErrorsServiceInfo] = useState({});

  const [formClassInfo, setformClassInfo] = useState([]);
  const [errorsClassInfo, setErrorsClassInfo] = useState([]);
  const [institutionInfo, setInstitutionInfo] = useState({});

  const [loading,setLoading]=useState()

  ///Basic info validation onchange and onSubmit
  const token = useToken();
  const getSingleInstitutionData = async () => {
    const body = {
      profileType: userTypes.schoolAuthority,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(`${BASE_URL}${endPoint.userGet}/${id}`, body, header)
      .then((res) => {
        if (res?.data?.message) {
          //console.log([res.data.data]);
          const institutionData= res?.data?.data;
          setInsId(institutionData?.ins_id)
          setFormBasicInfo({
            institueType:institutionData?.ins_type,
            institueName:institutionData?.ins_name,
            establishedYearInstitue:institutionData?.ins_est_year,
            registrationNumber:institutionData?.ins_reg_no,
            nameOfPrincipal:institutionData?.ins_principle,
            phone:institutionData?.user_phone_id,
            totalStudents:institutionData?.ins_total_students
          })
          setformAddressInfo({
            division:institutionData?.ins_division,
            district:institutionData?.ins_district,
            upozila:institutionData?.insUpozila,
            roadno:institutionData?.ins_road_no,
            houseno:institutionData?.ins_house_no,
          })
          // //console.log(institutionData.classes.map((cls)=> {
          //   return {
          //     className:cls.ins_class_name,
          //     gender:cls.ins_class_gender,
          //     totalStudent:cls.total_student,
          //   }
          // }))

          setformClassInfo(institutionData.classes.map((cls)=> {
            return {
              className:cls.ins_class_name,
              gender:cls.ins_class_gender,
              totalStudent:cls.total_student,
            }
          }))
          setErrorsClassInfo(institutionData.classes.map((cls)=> {
            return {
              className:null,
              gender:null,
              totalStudent:null,
            }
          }))
          ////console.log(errorsClassInfo)
          ////console.log(formClassInfo)
          setformServiceInfo({
            serviceSelect:institutionData?.ins_service_type,
            otherService:institutionData?.ins_service_type,
            startDate:institutionData?.ins_contact_start,
            endDate:institutionData?.ins_contact_end,
            noOfStudents:institutionData?.ins_fp_s_no,
            ratePerHeadStudent:institutionData?.ins_fp_s_rate,
            noOfTeachers:institutionData?.ins_fp_t_no,
            ratePerHeadTeacher:institutionData?.ins_fp_t_rate,
            noOfStudentsReceivingDiscountedServices:institutionData?.ins_ds_s_no,
            noOfTeachersReceivingDiscountedServices:institutionData?.ins_ds_t_no,
            rateOfTheDiscountServices:institutionData?.ins_ds_rate,
            noOfStudentsReceivingFreeServices:institutionData?.ins_fs_s_no,
            noOfTeachersReceivingFreeServices:institutionData?.ins_fs_t_no,
            serviceStatus:institutionData?.ins_service_status,
            
          })
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(()=>{
    getSingleInstitutionData()
  },[])

  const onSubmit = () => {
    setLoading(true)
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const bodyData = {
      profileType:userTypes.schoolAuthority,
      profileData:{

        insType: institutionInfo.institueType,
        insName: institutionInfo.institueName,
        insEstYear: institutionInfo.establishedYearInstitue,
        insRegNo: institutionInfo.registrationNumber,
        insPrinciple: institutionInfo.nameOfPrincipal,
        insDivision: institutionInfo.division,
        insDistrict: institutionInfo.district,
        insUpozila: institutionInfo.upozila || '',
        insRoadNo: institutionInfo.roadno || '',
        insHouseNo: institutionInfo.houseno || '',
        insServiceType: institutionInfo.serviceSelect,
        insContactStart: institutionInfo.startDate,
        insContactEnd: institutionInfo.endDate,
        insTotalStudents: institutionInfo.totalStudents,
        insFPsNo: institutionInfo.noOfStudents,
        insFPsRate: institutionInfo.ratePerHeadStudent,
        insFPsAmount: institutionInfo.studentTotalAmount,
        insFPtNo: institutionInfo.noOfTeachers,
        insFPtRate: institutionInfo.ratePerHeadTeacher,
        insFPtAmount: institutionInfo.teacherTotalAmount,
        insDSsNo: institutionInfo.noOfStudentsReceivingDiscountedServices,
        insDStNo: institutionInfo.noOfTeachersReceivingDiscountedServices,
        insDSRate: institutionInfo.rateOfTheDiscountServices,
        insDSAmount: institutionInfo.discountTotalAmount,
        insFSsNo: institutionInfo.noOfStudentsReceivingFreeServices,
        insFStNo: institutionInfo.noOfTeachersReceivingFreeServices,
        insSMTotalPatients: institutionInfo.totalPatient,
        insSMTotalAmount: institutionInfo.totalAmount,
        insSMPerHead: institutionInfo.perHead,
        insServiceStatus: institutionInfo.serviceStatus,
        classes: institutionInfo.classes ,
       
      
    }
    };
    //console.log(bodyData)
    axios
      .put(`${BASE_URL}${endPoint.userEdit}/${insId}`, bodyData, header)
      .then((res) => {
        setLoading(false)
        if (res?.data?.success) {
          //console.log(res.data?.data);
          notify("Institute Updated Successfully!", "success");
          setInstitutionInfo({})
          navigate("/healthcare/institutions/view");
        }
      })
      .catch((err) => {
        setLoading(false)
        //console.log(err);
        notify(err.response.data?.message, "warning");
      });
  };

 

  const onChangeBasicInfo = ({ name, value }) => {
    setFormBasicInfo({ ...formBasicInfo, [name]: value });
    ////console.log(form)
    if (name === "institueType") {
      if (value === null) {
        setErrorsBasicInfo((prev) => {
          return { ...prev, [name]: "Institution type is required!" };
        });
      } else {
        setErrorsBasicInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "institueName") {
      if (value === "") {
        setErrorsBasicInfo((prev) => {
          return { ...prev, [name]: "Institute name can't be blank!" };
        });
        ////console.log(errorsBasicInfo.institueName);
      } else {
        setErrorsBasicInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "establishedYearInstitue") {
      if (value === null) {
        setErrorsBasicInfo((prev) => {
          return { ...prev, [name]: "Establishment year is required!" };
        });
      } else {
        setErrorsBasicInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "registrationNumber") {
      if (value === "") {
        setErrorsBasicInfo((prev) => {
          return { ...prev, [name]: "Registration no can't be blank!" };
        });
      } else {
        setErrorsBasicInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "nameOfPrincipal") {
      if (value === "") {
        setErrorsBasicInfo((prev) => {
          return { ...prev, [name]: "Name of Principle can't be blank!" };
        });
      } else {
        setErrorsBasicInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "phone") {
      if (value === "") {
        setErrorsBasicInfo((prev) => {
          return { ...prev, [name]: "Phone number can't be blank!" };
        });
      } else {
        setErrorsBasicInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "totalStudents") {
      if (value === "") {
        setErrorsBasicInfo((prev) => {
          return { ...prev, [name]: "Total Students can't be blank!" };
        });
      } else {
        setErrorsBasicInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
  };
  
  const onBasicInfoSubmit = () => {
    //validations checking here

    if (!formBasicInfo.institueType) {
      setErrorsBasicInfo((prev) => {
        return { ...prev, institueType: "Please Select an Institution type" };
      });
    }

    if (!formBasicInfo.institueName) {
      setErrorsBasicInfo((prev) => {
        return { ...prev, institueName: "Please add an Institution name" };
      });
    }

    if (!formBasicInfo.establishedYearInstitue) {
      setErrorsBasicInfo((prev) => {
        return {
          ...prev,
          establishedYearInstitue: "Please select an Established Year",
        };
      });
    }
    if (!formBasicInfo.registrationNumber) {
      setErrorsBasicInfo((prev) => {
        return {
          ...prev,
          registrationNumber: "Please add an Registration number",
        };
      });
    }
    if (!formBasicInfo.nameOfPrincipal) {
      setErrorsBasicInfo((prev) => {
        return { ...prev, nameOfPrincipal: "Please add a Principle name" };
      });
    }
    if (!formBasicInfo.phone) {
      setErrorsBasicInfo((prev) => {
        return { ...prev, phone: "Please add a Phone Number" };
      });
    }
    if (!formBasicInfo.totalStudents) {
      setErrorsBasicInfo((prev) => {
        return { ...prev, totalStudents: "Please add a Total Students" };
      });
    }

    if (
      formBasicInfo.institueType &&
      formBasicInfo.institueName &&
      formBasicInfo.establishedYearInstitue &&
      formBasicInfo.registrationNumber &&
      formBasicInfo.nameOfPrincipal &&
      formBasicInfo.phone &&
      formBasicInfo.totalStudents &&
      Object.values(errorsBasicInfo).every((item) => !item)
    ) {
      ////console.log(formBasicInfo);
      setInd((prev) => prev + 1);
    }
  };

  ///Address info validation onchange and onSubmit
  const onChangeAddressInfo = ({ name, value }) => {
    setformAddressInfo({ ...formAddressInfo, [name]: value });

    if (name === "division") {
      if (value === null) {
        setErrorsAddressInfo((prev) => {
          return { ...prev, [name]: "Division is required!" };
        });
      } else {
        setErrorsAddressInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "district") {
      if (value === null) {
        setErrorsAddressInfo((prev) => {
          return { ...prev, [name]: "District is required!" };
        });
      } else {
        setErrorsAddressInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
  };
  const onAddressInfoSubmit = () => {
    //validations checking here
    if (!formAddressInfo.division) {
      setErrorsAddressInfo((prev) => {
        return { ...prev, division: "Please Select a Division" };
      });
    }

    if (!formAddressInfo.district) {
      setErrorsAddressInfo((prev) => {
        return { ...prev, district: "Please Select a District" };
      });
    }

    if (
      formAddressInfo.division &&
      formAddressInfo.district &&
      Object.values(errorsAddressInfo).every((item) => !item)
    ) {
      ////console.log(formAddressInfo);
      setInd((prev) => prev + 1);
    }
  };

  ///Classes info validation onchange and onSubmit
  const onChangeClasses = ({ name, value, index }) => {
    setformClassInfo(
      formClassInfo.map((item, id) => {
        if (id === index) {
          item = { ...item, [name]: value };
        }
        return item;
      }),
    );

    if (name === "className") {
      if (value.length === 0) {
        setErrorsClassInfo(
          errorsClassInfo.map((item, id) => {
            if (id === index) {
              item = { ...item, [name]: "Class can't be blank" };
            }
            return item;
          }),
        );
      } else {
        setErrorsClassInfo(
          errorsClassInfo.map((item, id) => {
            if (id === index) {
              item = { ...item, [name]: null };
            }
            return item;
          }),
        );
      }
    }

    if (name === "gender") {
      if (value === null) {
        setErrorsClassInfo(
          errorsClassInfo.map((item, id) => {
            if (id === index) {
              item = { ...item, [name]: "Gender can't be blank" };
            }
            return item;
          }),
        );
      } else {
        setErrorsClassInfo(
          errorsClassInfo.map((item, id) => {
            if (id === index) {
              item = { ...item, [name]: null };
            }
            return item;
          }),
        );
      }
    }

    if (name === "totalStudent") {
      if (value.length === 0 || value <= 0) {
        setErrorsClassInfo(
          errorsClassInfo.map((item, id) => {
            if (id === index) {
              item = { ...item, [name]: "Total Students is invalid or blank" };
            }
            return item;
          }),
        );
      } else {
        setErrorsClassInfo(
          errorsClassInfo.map((item, id) => {
            if (id === index) {
              item = { ...item, [name]: null };
            }
            return item;
          }),
        );
      }
    }
  };

  const checkClassInfo = () => {
    for (let i = 0; i < formClassInfo.length; i += 1) {
      if (
        !formClassInfo[i].className.length > 0 ||
        !formClassInfo[i].gender.length > 0 ||
        !formClassInfo[i].totalStudent > 0
      ) {
        return false;
      }
    }

    return true;
  };
  const checkErrorInfo = () => {
    for (let i = 0; i < errorsClassInfo.length; i += 1) {
      if (
        errorsClassInfo[i].className ||
        errorsClassInfo[i].gender ||
        errorsClassInfo[i].totalStudent
      ) {
        return false;
      }
    }
    return true;
  };
  const onClassInfoSubmit = () => {
    //validations checking here
    formClassInfo.map((item, index) => {
      if (item.className?.length === 0) {
        errorsClassInfo[index].className = "Class can't be blank";
        setErrorsClassInfo([...errorsClassInfo]);
      }

      if (item.gender?.length === 0) {
        errorsClassInfo[index].gender = "Select a gender";
        setErrorsClassInfo([...errorsClassInfo]);
      }

      if (item.totalStudent <= 0) {
        errorsClassInfo[index].totalStudent = "Total students is invalid";
        setErrorsClassInfo([...errorsClassInfo]);
      }
      ////console.log(errorsClassInfo)
    });

    if (checkClassInfo() && checkErrorInfo()) {
      ////console.log(formClassInfo);
      setInd((prev) => prev + 1);
    }
  };

  ///Service info validation onchange and onSubmit
  const onChangeServiceInfo = ({ name, value }) => {
    setformServiceInfo({ ...formServiceInfo, [name]: value });

    if (name === "serviceSelect") {
      if (value.length === 0) {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: "Select a Service is required!" };
        });
      } else {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "otherService" && formServiceInfo.serviceSelect === "Other") {
      if (value.length === 0) {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: "Other service is required!" };
        });
      } else {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "startDate") {
      if (value.length === 0) {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: "Start date can't be blank!" };
        });
      } else {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "endDate") {
      if (value.length === 0) {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: "End date can't be blank!" };
        });
      } else {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "noOfStudents") {
      if (value < 0 || value.length === 0) {
        setErrorsServiceInfo((prev) => {
          return {
            ...prev,
            [name]: "Total Students can't be negative or blank!",
          };
        });
      } else {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "ratePerHeadStudent") {
      if (value < 0 || value.length === 0) {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: "Rate can't be negative or blank!" };
        });
      } else {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "noOfTeachers") {
      if (value < 0 || value.length === 0) {
        setErrorsServiceInfo((prev) => {
          return {
            ...prev,
            [name]: "Total Teachers can't be negative or blank!",
          };
        });
      } else {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "ratePerHeadTeacher") {
      if (value < 0 || value.length === 0) {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: "Rate can't be negative or blank!" };
        });
      } else {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "noOfStudentsReceivingDiscountedServices") {
      if (value < 0 || value.length === 0) {
        setErrorsServiceInfo((prev) => {
          return {
            ...prev,
            [name]: "Students number can't be negative or blank!",
          };
        });
      } else {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "noOfTeachersReceivingDiscountedServices") {
      if (value < 0 || value.length === 0) {
        setErrorsServiceInfo((prev) => {
          return {
            ...prev,
            [name]: "Teachers number can't be negative or blank!",
          };
        });
      } else {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "rateOfTheDiscountServices") {
      if (value < 0 || value.length === 0 || value > 100) {
        setErrorsServiceInfo((prev) => {
          return {
            ...prev,
            [name]:
              "Discount rate can't be negative or blank or greater than 100%!",
          };
        });
      } else {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "noOfStudentsReceivingFreeServices") {
      if (value < 0 || value.length === 0) {
        setErrorsServiceInfo((prev) => {
          return {
            ...prev,
            [name]: "Students number can't be negative or blank!",
          };
        });
      } else {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "noOfTeachersReceivingFreeServices") {
      if (value < 0 || value.length === 0) {
        setErrorsServiceInfo((prev) => {
          return {
            ...prev,
            [name]: "Teachers number can't be negative or blank!",
          };
        });
      } else {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "serviceStatus") {
      if (value.length === 0) {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: "Service Status can't be blank!" };
        });
      } else {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "totalPatient") {
      if (value.length === 0) {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: "Total Patient can't be blank!" };
        });
      } else {
        setErrorsServiceInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
  };
  const onServiceInfoSubmit = () => {
    //validations checking here
    if (!formServiceInfo.serviceSelect) {
      setErrorsServiceInfo((prev) => {
        return { ...prev, serviceSelect: "This field is required!" };
      });
    }

    if (
      !formServiceInfo.otherService &&
      formServiceInfo.serviceSelect === "Other"
    ) {
      setErrorsServiceInfo((prev) => {
        return { ...prev, otherService: "This field is required!" };
      });
    }
    if (!formServiceInfo.startDate) {
      setErrorsServiceInfo((prev) => {
        return { ...prev, startDate: "This field is required!" };
      });
    }

    if (!formServiceInfo.endDate) {
      setErrorsServiceInfo((prev) => {
        return { ...prev, endDate: "This field is required!" };
      });
    }

    if (!formServiceInfo.noOfStudents) {
      setErrorsServiceInfo((prev) => {
        return { ...prev, noOfStudents: "This field is required!" };
      });
    }

    if (!formServiceInfo.ratePerHeadStudent) {
      setErrorsServiceInfo((prev) => {
        return { ...prev, ratePerHeadStudent: "This field is required!" };
      });
    }

    if (!formServiceInfo.noOfTeachers) {
      setErrorsServiceInfo((prev) => {
        return { ...prev, noOfTeachers: "This field is required!" };
      });
    }

    if (!formServiceInfo.ratePerHeadTeacher) {
      setErrorsServiceInfo((prev) => {
        return { ...prev, ratePerHeadTeacher: "This field is required!" };
      });
    }

    if (!formServiceInfo.noOfStudentsReceivingDiscountedServices) {
      setErrorsServiceInfo((prev) => {
        return {
          ...prev,
          noOfStudentsReceivingDiscountedServices: "This field is required!",
        };
      });
    }

    if (!formServiceInfo.noOfTeachersReceivingDiscountedServices) {
      setErrorsServiceInfo((prev) => {
        return {
          ...prev,
          noOfTeachersReceivingDiscountedServices: "This field is required!",
        };
      });
    }

    if (!formServiceInfo.rateOfTheDiscountServices) {
      setErrorsServiceInfo((prev) => {
        return {
          ...prev,
          rateOfTheDiscountServices: "This field is required!",
        };
      });
    }

    if (!formServiceInfo.noOfStudentsReceivingFreeServices) {
      setErrorsServiceInfo((prev) => {
        return {
          ...prev,
          noOfStudentsReceivingFreeServices: "This field is required!",
        };
      });
    }
    if (!formServiceInfo.noOfTeachersReceivingFreeServices) {
      setErrorsServiceInfo((prev) => {
        return {
          ...prev,
          noOfTeachersReceivingFreeServices: "This field is required!",
        };
      });
    }
    if (!formServiceInfo.serviceStatus) {
      setErrorsServiceInfo((prev) => {
        return { ...prev, serviceStatus: "This field is required!" };
      });
    }
    if (!formServiceInfo.totalPatient) {
      setErrorsServiceInfo((prev) => {
        return { ...prev, totalPatient: "This field is required!" };
      });
    }

    if (
      formServiceInfo.serviceSelect &&
      (() =>
        formServiceInfo.serviceSelect === "Other"
          ? formServiceInfo?.otherService
          : true) &&
      formServiceInfo.startDate &&
      formServiceInfo.endDate &&
      formServiceInfo.noOfStudents &&
      formServiceInfo.ratePerHeadStudent &&
      formServiceInfo.noOfTeachers &&
      formServiceInfo.ratePerHeadTeacher &&
      formServiceInfo.noOfStudentsReceivingDiscountedServices &&
      formServiceInfo.noOfTeachersReceivingDiscountedServices &&
      formServiceInfo.rateOfTheDiscountServices &&
      formServiceInfo.noOfStudentsReceivingFreeServices &&
      formServiceInfo.noOfTeachersReceivingFreeServices &&
      formServiceInfo.serviceStatus &&
      formServiceInfo.totalPatient &&
      Object.values(errorsServiceInfo).every((item) => !item)
    ) {
      ////console.log(formServiceInfo);
      //setInd((prev) => prev + 1);
      onSubmit()
    }
  };

  // //Password info validation onChange and onSubmit
  // const onChangePasswordInfo = ({ name, value }) => {
  //   setFormPassword({ ...formPassword, [name]: value });

  //   if (name === "password") {
  //     if (value.length === 0) {
  //       setErrorsFormPassword((prev) => {
  //         return { ...prev, [name]: "Password can't be blank!" };
  //       });
  //     } else if (value.length < 8) {
  //       setErrorsFormPassword((prev) => {
  //         return {
  //           ...prev,
  //           [name]: "Password should be at least 8 characters!",
  //         };
  //       });
  //     } else {
  //       setErrorsFormPassword((prev) => {
  //         return { ...prev, [name]: null };
  //       });
  //     }
  //   }

  //   if (name === "confirmPassword") {
  //     if (value.length === 0) {
  //       setErrorsFormPassword((prev) => {
  //         return { ...prev, [name]: "Password can't be blank!" };
  //       });
  //     } else if (value.length < 8) {
  //       setErrorsFormPassword((prev) => {
  //         return {
  //           ...prev,
  //           [name]: "Password should be at least 8 characters!",
  //         };
  //       });
  //     } else {
  //       setErrorsFormPassword((prev) => {
  //         return { ...prev, [name]: null };
  //       });
  //     }
  //   }
  // };
  // const onPasswordInfoSubmit = () => {
  //   //validations checking here
  //   ////console.log("asdf")
  //   if (!formPassword.password) {
  //     setErrorsFormPassword((prev) => {
  //       return { ...prev, password: "Please Insert Password" };
  //     });
  //   }

  //   if (!formPassword.confirmPassword) {
  //     setErrorsFormPassword((prev) => {
  //       return { ...prev, confirmPassword: "Please Insert Confirm Password" };
  //     });
  //   }

  //   if (formPassword.confirmPassword !== formPassword.password) {
  //     setErrorsFormPassword((prev) => {
  //       return {
  //         ...prev,
  //         password: "Password doesn't match with confirm Password",
  //         confirmPassword: "Password doesn't match with confirm Password",
  //       };
  //     });
  //   }

  //   if (
  //     formPassword.password &&
  //     formPassword.confirmPassword &&
  //     formPassword.password === formPassword.confirmPassword &&
  //     Object.values(errorsFormPassword).every((item) => !item)
  //   ) {
  //     onSubmit()
  //   }
  // };


  //console.log(institutionInfo);

  useEffect(() => {
    setInstitutionInfo({
      ...institutionInfo,
      ...formBasicInfo,
      ...formAddressInfo,
      ...formServiceInfo,
      classes: formClassInfo.length > 0 ? formClassInfo : [],
    });
  }, [
   
    formBasicInfo,
    formAddressInfo,
    formServiceInfo,
    formClassInfo
  ]);
  // //console.log(institutionInfo)
  const getNextSt = () => {
    if (ind === 0) {
      return onBasicInfoSubmit();
    } else if (ind === 1) {
      return onAddressInfoSubmit();
    } else if (ind === 2) {
      return onClassInfoSubmit();
    } else if (ind === 3) {
      return onServiceInfoSubmit();
    }
  };
 
  return (
    <AppSteper
      firstStep="Basic Info"
      OtherSteps={[
        "Institution Address",
        "Student Info",
        "ServiceInfo"
      ]}
      ind={ind}
      setInd={setInd}
      getNextSt={getNextSt}
      isEdit={true}
      loading={loading}
    >
      <div>
        {ind === 0 && (
          <BasicInfo
            onChangeBasicInfo={onChangeBasicInfo}
            errorsBasicInfo={errorsBasicInfo}
            formBasicInfo={formBasicInfo}
            isEdit={true}
          />
        )}
        {ind === 1 && (
          <InstituteAddress
            onChangeAddressInfo={onChangeAddressInfo}
            errorsAddressInfo={errorsAddressInfo}
            formAddressInfo={formAddressInfo}
          />
        )}
        {ind === 2 && (
          <AddInstituteStudents
            onChangeClasses={onChangeClasses}
            errorsClassInfo={errorsClassInfo}
            formClassInfo={formClassInfo}
            setformClassInfo={setformClassInfo}
            setErrorsClassInfo={setErrorsClassInfo}
          />
        )}
        {ind === 3 && (
          <ServiceInfo
            onChangeServiceInfo={onChangeServiceInfo}
            errorsServiceInfo={errorsServiceInfo}
            formServiceInfo={formServiceInfo}
            setformServiceInfo={setformServiceInfo}
          />
        )}
      </div>
    </AppSteper>
  );
};

export default Add;
