import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { FaPrescription } from "react-icons/fa";
import { useDispatch, useSelector } from "../../../redux/store";
import AppTable from "../../ui/AppTable";
import { DynamicTableDataForPrescription } from "../../ui/helpers/table/TableBodyData";
import { tableHeadPrescriptionsData } from "../../ui/helpers/table/TableHeadeData";
import { useConvertedTableData } from "../../ui/helpers/table/useConvertedTableData";
import { tableConvertedData } from "./helper/ConvertedPressData";
import {
  BASE_URL,
  endPoint,
} from "../../../constants/ApiConstant/routeConstant";
import { singlePrescriptionsPdfFnc } from "../../../redux/slices/tableDataForPdfSlice";

const DPrescription = () => {
  const { token, userInfo } = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const limit = 6;

  const { tableData, pageCount, handlePageClick } = useConvertedTableData(
    prescriptionData,
    limit,
  );

  const getAllPrescriptionData = async () => {
    const body = {
      dId: userInfo?.doctor?.d_id,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    await axios
      .post(`${BASE_URL}${endPoint.prescriptionGet}`, body, header)
      .then((res) => {
        if (res?.data?.message) {
          setPrescriptionData(tableConvertedData(res?.data?.data));
          setLoading(false);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  
  useEffect(() => {
    if (prescriptionData) {
      dispatch(singlePrescriptionsPdfFnc(prescriptionData));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescriptionData]);


  useEffect(() => {
    getAllPrescriptionData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="3xl:-mr-10 mx-0 md:mx-5 mt-16 xl:-mr-16 xl:mx-0 xl:mt-0">
      <AppTable
        tableTitle="Prescription Information"
        titleClass="font-bold"
        titleIcon={<FaPrescription />}
        tableHeadData={tableHeadPrescriptionsData}
        tableBodyData={tableData}
        dynamicTable={(colItem, headItem, index, shadow) =>
          DynamicTableDataForPrescription(colItem, headItem, index, shadow)
        }
        loader={loading}
        pagination
        pageCount={pageCount}
        handlePageClick={handlePageClick}
      />
    </div>
  );
};

export default DPrescription;
