export const tableConvertedData = (res) => {
  let data = [];
  res?.map((itm) => {
    data.push({
      userId: itm?.user_id || "--",
      userPhoneId: itm?.user_phone_id || "--",
      userName: itm?.user_name || "--",
      userImg: itm?.user_img || "--",
      userSchlHealth: itm?.user_schl_health || "--",
      userTelemedicine: itm?.user_telemedicine || "--",
      userOlymp: itm?.user_olymp || "--",
      userHijama: itm?.user_hijama || "--",
      userAdminType: itm?.user_admin_type || "--",
      uId: itm?.u_id || "--",
      sId: itm?.s_id || "--",
      insId: itm?.ins_id || "--",
      classId: itm?.class_id || "--",
      sName: itm?.s_name || "--",
      sSection: itm?.s_section || "--",
      sRoll: itm?.s_roll || "--",
      sSession: itm?.s_session || "--",
      sDob: itm?.s_dob || "--",
      sBlood: itm?.s_blood || "--",
      sGender: itm?.s_gender || "--",
      sReligion: itm?.s_religion || "--",
      sPAddress: itm?.s_p_address || "--",
      sTAddress: itm?.s_t_address || "--",
      sHouseType: itm?.s_house_type || "--",
      sHouseConType: itm?.s_house_con_type || "--",
      sFtrName: itm?.s_ftr_name || "--",
      sMtrName: itm?.s_mtr_name || "--",
      sFtrProfession: itm?.s_ftr_profession || "--",
      sMtrProfession: itm?.s_mtr_profession || "--",
      sNoOfSiblings: itm?.s_no_of_siblings || "--",
      sDiblingsDetails: itm?.s_diblings_details || "--",
      sPrevIllness: itm?.s_prev_illness || "--",
      sDrugHistory: itm?.s_drug_history || "--",
      sVitalSign: itm?.s_vital_sign || "--",
      sBloodPressure: itm?.s_blood_pressure || "--",
      sPulse: itm?.s_pulse || "--",
      sTemp: itm?.s_temp || "--",
      sHeight: itm?.s_height || "--",
      sWeight: itm?.s_weight || "--",
      sBmi: itm?.s_bmi || "--",
      sCvLE: itm?.s_cv_l_e || "--",
      sCvRE: itm?.s_cv_r_e || "--",
      sVaLF: itm?.s_va_l_f || "--",
      sVaLN: itm?.s_va_l_n || "--",
      sVaRF: itm?.s_va_r_f || "--",
      sVaRN: itm?.s_va_r_n || "--",
      sVaBF: itm?.s_va_b_f || "--",
      sVaBN: itm?.s_va_b_n || "--",
      sVaCmnt: itm?.s_va_cmnt || "--",
      sAppearance: itm?.s_appearance || "--",
      sBodybuilt: itm?.s_body_built || "--",
      sAnemia: itm?.s_anemia || "--",
      sJaundice: itm?.s_jaundice || "--",
      sCyanosis: itm?.s_cyanosis || "--",
      sNail: itm?.s_nail || "--",
      sEar: itm?.s_ear || "--",
      sThroat: itm?.s_throat || "--",
      sNose: itm?.s_nose || "--",
      sLimbs: itm?.s_limbs || "--",
      sSkin: itm?.s_skin || "--",
      sLymph: itm?.s_lymph || "--",
      sOthers: itm?.s_others || "--",
      sResSys: itm?.s_res_sys || "--",
      sCarSys: itm?.s_car_sys || "--",
      sOralSys: itm?.s_oral_sys || "--",
      sNervousSys: itm?.s_nervous_sys || "--",
      sMuscuSys: itm?.s_muscu_sys || "--",
      sUroSys: itm?.s_uro_sys || "--",
      sDiagnosis: itm?.s_diagnosis || "--",
      sTreatment: itm?.s_treatment || "--",
      sReferral: itm?.s_referral || "--",
      sFollowUp: itm?.s_follow_up || "--",
      sHealthStatus: itm?.s_health_status || "--",
      sCheckupDate: itm?.s_checkup_date || "--",
      sInputDate: itm?.s_input_date || "--",
      sAccountStatus: itm?.s_account_status || "--",
      healthRecords: itm?.healthRecords || "--",
      insType: itm?.ins_type || "--",
      insName: itm?.ins_name || "--",
      insEstYear: itm?.ins_est_year || "--",
      insRegNo: itm?.ins_reg_no || "--",
      insPrinciple: itm?.ins_principle || "--",
      insDivision: itm?.ins_division || "--",
      insDistrict: itm?.ins_district || "--",
      insUpozila: itm?.ins_upozila || "--",
      insRoadNo: itm?.ins_road_no || "--",
      insHouseNo: itm?.ins_house_no || "--",
      insServiceType: itm?.ins_service_type || "--",
      insContactStart: itm?.ins_contact_start || "--",
      insContactEnd: itm?.ins_contact_end || "--",
      insTotalStudents: itm?.ins_total_students || "--",
      insFpSNo: itm?.ins_fp_s_no || "--",
      insFpSRate: itm?.ins_fp_s_rate || "--",
      insFpSAmount: itm?.ins_fp_s_amount || "--",
      insFpTNo: itm?.ins_fp_t_no || "--",
      insFpTRate: itm?.ins_fp_t_rate || "--",
      insFpTAmount: itm?.ins_fp_t_amount || "--",
      insDsSNo: itm?.ins_ds_s_no || "--",
      insDsTNo: itm?.ins_ds_t_no || "--",
      insDsRate: itm?.ins_ds_rate || "--",
      insDsAmount: itm?.ins_ds_amount || "--",
      insFsSNo: itm?.ins_fs_s_no || "--",
      insFsTNo: itm?.ins_fs_t_no || "--",
      insSmTotalPatients: itm?.ins_sm_total_patients || "--",
      insSmTotalAmount: itm?.ins_sm_total_amount || "--",
      insSmPerHead: itm?.ins_sm_per_head || "--",
      insServiceStatus: itm?.ins_service_status || "--",
      insClassId: itm?.ins_class_id || "--",
      insClassName: itm?.ins_class_name || "--",
      insClassGender: itm?.ins_class_gender || "--",
      totalStudent: itm?.total_student || "--",
    });
  });

  return data;
};
