import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../assets/loader.gif";
import {
  BASE_URL,
  endPoint,
} from "../../../../constants/ApiConstant/routeConstant";
import { useSelector } from "../../../../redux/store";
import { useToken } from "../../../../services/useAuth";
import CardView from "./CardView";

const View = () => {
  const [events, setEvents] = useState([]);
  const { deleteSuccess } = useSelector((state) => state.modal);
  const [, setEventsError] = useState();
  const [loading, setLoading] = useState();

  const navigate = useNavigate()
  const token = useToken();
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getData = async () => {
    setLoading(true);

    await axios
      .get(`${BASE_URL}${endPoint.eventGet}`, header)
      .then((res) => {
        setLoading(false);

        if (res?.data?.success) {
          setEvents(res.data?.data);
        }
      })
      .catch((err) => {
        setLoading(false);

        setEventsError(err.response.data.message);
      });
  };
  
  const getSingleEventData = (id)=> {
    navigate(`/olympiad/events/details/${id}`)
  }

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  return (
    <div className="shadow-md w-full bg-white mt-10 h-[77vh] relative overflow-scroll custom-scroll rounded-xl">
      <div className="flex items-center justify-between fixed bg-white xl:w-[70%] w-full  py-7 shadow-md rounded-xl">
        <p className="pl-5 text-xl font-bold ">Events</p>
      </div>
      <div className="mt-28 mb-10 ">
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={Loader} style={{ width: "20%" }} alt='' />
          </div>
        ) : (
          <>
            {events.map((event) => {
              return <CardView event={event} getSingleEventData={getSingleEventData}/>;
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default View;
