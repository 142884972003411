export const tableConvertedData = (res) => {
  let data = [];
  res?.map((itm) => {
    data.push({
      userId: itm?.user_id || "--",
      userPhoneId: itm?.user_phone_id || "--",
      userName: itm?.user_name || "--",
      userImg: itm?.user_img || "--",
      userSchlHealth: itm?.user_schl_health || "--",
      userTelemedicine: itm?.user_telemedicine || "--",
      userOlymp: itm?.user_olymp || "--",
      userHijama: itm?.user_hijama || "--",
      userAdminType: itm?.user_admin_type || "--",
      adminId: itm?.admin_id || "--",
      adminName: itm?.admin_name || "--",
    });
  });

  return data;
};
