import React, { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FiVideo } from "react-icons/fi";
import { MdOutlineEmojiEvents, MdOutlineQuiz } from "react-icons/md";
import { userTypes } from "../../constants/ApiConstant/databaseConstant";
import { useGetDashboardData } from "../../services/api";
import AppCardContainer from "../ui/AppCardContainer";
import AppDashboardCard from "../ui/AppDashboardCard";
import Loader from "../ui/Loader";
import {
  ApexBarChart,
  ApexPolarAreaChart,
  ApexRadialBarChart,
} from "./helpers/GetStatticChart";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const body = {
    type: userTypes.superAdmin,
  };
  const { getSADashboardData } = useGetDashboardData(body);

  useEffect(() => {
    setIsLoading(true);
    getSADashboardData()
      .then((res) => {
        if (res) {
          setDashboardData(res);
          setIsLoading(false);
        }
      })
      .catch((err) => console.log(err));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className=" xl:-mr-16 3xl:-mr-32">
      <AppCardContainer spacing="mt-10" width="w-full">
        {isLoading ? (
          <Loader variant="#3498db" />
        ) : (
          <ApexBarChart
            chartTitle="All OMPHD Users"
            dashboardData={dashboardData}
          />
        )}
      </AppCardContainer>

      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-5 mt-5">
        <AppDashboardCard
          title="Total Events"
          value={dashboardData.totalEvents}
          viewLink="/olympiad/events/view"
          bgColor="bg-indigo-50"
          textColor="text-indigo-600"
          icon={
            <MdOutlineEmojiEvents className="text-3xl 3xl:text-4xl text-indigo-600" />
          }
          isStyle
        />

        <AppDashboardCard
          title="Total Notices"
          value={dashboardData.totalNotices}
          viewLink="/olympiad/notices/view"
          bgColor="bg-green-50"
          textColor="text-green-600"
          icon={
            <AiOutlineInfoCircle className="text-3xl 3xl:text-4xl text-green-600" />
          }
          isStyle
        />

        <AppDashboardCard
          title="Total Quizes"
          value={dashboardData.totalQuizes}
          viewLink="/hijama/quiz/view"
          bgColor="bg-amber-50"
          textColor="text-amber-600"
          icon={
            <MdOutlineQuiz className="text-3xl 3xl:text-4xl text-amber-600" />
          }
          isStyle
        />

        <AppDashboardCard
          title="Total Videos"
          value={dashboardData.totalVideo}
          viewLink="/hijama/Videos/view"
          bgColor="bg-red-50"
          textColor="text-red-600"
          icon={<FiVideo className="text-3xl 3xl:text-4xl text-red-600" />}
          isStyle
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-5">
        <AppCardContainer>
          {isLoading ? (
            <Loader variant="#3498db" />
          ) : (
            <ApexPolarAreaChart
              chartTitle="Doctors Information"
              dashboardData={dashboardData}
            />
          )}
        </AppCardContainer>
        <AppCardContainer width="w-full">
          {isLoading ? (
            <Loader variant="#3498db" />
          ) : (
            <ApexRadialBarChart
              chartTitle="Institute Information"
              dashboardData={dashboardData}
            />
          )}
        </AppCardContainer>
      </div>
    </div>
  );
};

export default Dashboard;
