
import React from 'react';
import Logo from "../../../assets/logo.png";
import { styles } from './Constant';

const Header = ({profile,proType,title}) => {
 
  return (
    <div style={styles.headerContainer} >
    <div style={styles.header}>
    <div style={styles.detailColumnLeft}>
        <img src={Logo} style={styles.headImage} />
        <h1 style={styles.logoHeader} > OMPHD </h1>
         <p style={styles.name}> {profile.u_name} </p>
        </div>

        <div style={styles.detailColumnRight}>
        <p style={styles.rightHeaderText}>Omphdofficial@gmail.com</p>
        <p style={styles.rightHeaderText} > 101 Holy View, Kanishail Road,</p>
        <p style={styles.rightHeaderText} > Shamimabad, Sylhet </p>
        <p style={styles.rightHeaderText} > COO- 01614312153, CFO- 0161431215 </p>
        
        </div>
    </div>
        
        
        <p style={styles.subtitle}>  {title} </p>
     </div>
  )
}

export default Header