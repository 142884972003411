import { Box, Typography } from "@mui/material";
import React from "react";
import {
  AiOutlineSafetyCertificate
} from "react-icons/ai";
import { BiIdCard } from "react-icons/bi";
import { GrStatusInfo } from "react-icons/gr";
import { IoIdCardOutline } from "react-icons/io5";
import {BsCalendarDate, BsGenderAmbiguous} from "react-icons/bs"
 import { GiPeaceDove } from "react-icons/gi";
 import { FaRegAddressCard } from "react-icons/fa";
 import { BsFillHouseFill } from "react-icons/bs";

const ParticularDetails = ({ singleStudents }) => {
    const {
      sName,
      sSection,
      sRoll,
      sDob,
      sGender,
      sReligion,
      sPAddress,
      sTAddress,
      sHouseType,
      sHouseConType,
      
      insClassName,
    } = singleStudents;
  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiIdCard style={{ marginRight: 4 }} />
            Name
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {sName}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoIdCardOutline style={{ marginRight: 4 }} />
            Class
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insClassName}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineSafetyCertificate style={{ marginRight: 4 }} />
            Section
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {sSection}
          </Typography>
        </Box>

        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <GrStatusInfo style={{ marginRight: 4 }} />
            Roll
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {sRoll}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarDate style={{ marginRight: 4 }} />
            Date of birth
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {sDob ? sDob.slice(0, 10) : ""}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsGenderAmbiguous style={{ marginRight: 4 }} />
            Gender
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {sGender}
          </Typography>
        </Box>

        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <GiPeaceDove style={{ marginRight: 4 }} />
            Religion
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {sReligion}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaRegAddressCard style={{ marginRight: 4 }} />
            Permanent address
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {sPAddress}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaRegAddressCard style={{ marginRight: 4 }} />
            Present address
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {sTAddress}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsFillHouseFill style={{ marginRight: 4 }} />
            House type
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {sHouseType}
          </Typography>
        </Box>

        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsFillHouseFill style={{ marginRight: 4 }} />
            House Construction Type
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {sHouseConType}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ParticularDetails;
