import { createSlice } from "@reduxjs/toolkit";

export const schoolAuthoritySlice = createSlice({
  name: "schoolAuthority",
  initialState: {
    classes: [],
  },
  reducers: {
    setAllClasses: (state, action) => {
      state.classes = action?.payload;
    },
  },
});

// Exports all actions
export const { setAllClasses } = schoolAuthoritySlice.actions;

export default schoolAuthoritySlice.reducer;
