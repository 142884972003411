
// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)

import React from "react";
import { styles } from "../../Common/Constant";
import Footer from '../../Common/Footer';
import Header from '../../Common/Header';
import MyTable from '../../Common/Table';

export const PatientTableToPrint = React.forwardRef((props, ref) => {


 const headerData = ['NO','Name','ID','Phone-1','Phone-2','Profession','Gender','NID','Email','Address']

//   const bodyData =props.data?.map((item)=>{
//     return [item.p_name,item.p_id,item.user_phone_id,item.p_phone,item.p_profession,item.p_gender,item.p_nid,item.p_email,item.p_address]
//  })

 const bodyData =props.data?.map((item)=>{
  return [item.pName,item.pId,item.userPhone_id,item.pPhone,item.pProfession,item.pGender,item.pNid,item.pEmail,item.pAddress]
})


  return (
    <div ref={ref} style={styles.page}>
      <Header profile={props.data} proType={props.proType} title={"Patients List"} />
      <div style={styles.container}>
        <div style={styles.leftColumn}>
          <div style={styles.container}>

          {
            props.data.length>0 ? (
          <MyTable 
            headersData={headerData}
            bodyData = {bodyData}
          />
            ) : <h3 style={{textAlign:'center'}}> No Class found!</h3>
          }

          </div>
          

        </div>

        
        
      </div>
      <Footer/>
    </div>
  );
});