import { Box, Typography } from "@mui/material";
import React from "react";
import { BiIdCard } from "react-icons/bi";
import { IoIdCardOutline } from "react-icons/io5";
import { BASE_URL } from "../../../../../constants/ApiConstant/routeConstant";

const style = {
  width: "90%",
  height: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};
const titleStyle = {
  fontSize: "20px",
  textAlign: "center",
  border: "2px solid gray",
  padding: "0.5rem",
  margin: "0.5rem 0",
  fontWeight: 600,
};

const PrescriptionDetails = ({ handleClose, singlePrescriptionData }) => {
  const {
    apId,
    pId,
    dId,
    apLink,
    apReqTime,
    apTime,
    apReason,
    bloodPressure,
    pulse,
    height,
    weight,
    bloodGlucose,
    additional_note,
    status,

    presId,
    presChiefComplaint,
    presHistoryIllness,
    presExaFinding,
    presDDiag,
    presAdvice,
    presInvestigation,
    presReferrel,
    presNotes,
    presSignatureImg,
    presFile,

    uId,
    dName,
    dDegree,
    dEducation,
    dExperience,
    dDivision,
    dDistrict,
    dWokingPlaceDetials,
    dCharge,
    dTotalExpYears,
    dSignature,
    dSpecialty,
  } = singlePrescriptionData;

  return (
    <Box className="prescriptionDetailsModal" sx={style}>
      <h3 style={titleStyle}>Prescription Details</h3>

      <div className="">
        <Box sx={{ px: 2, py: 1 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BiIdCard style={{ marginRight: 4 }} />
                Prescription ID
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {presId}
              </Typography>
            </Box>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IoIdCardOutline style={{ marginRight: 4 }} />
                 Chief Complaint
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {presChiefComplaint}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ px: 2, py: 1 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IoIdCardOutline style={{ marginRight: 4 }} />
                 History Illness
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {presHistoryIllness}
              </Typography>
            </Box>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IoIdCardOutline style={{ marginRight: 4 }} />
                Extra Finding
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {presExaFinding}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ px: 2, py: 1 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BiIdCard style={{ marginRight: 4 }} />
                Diagnosis
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {presDDiag}
              </Typography>
            </Box>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IoIdCardOutline style={{ marginRight: 4 }} />
                 Advice
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {presAdvice}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ px: 2, py: 1 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IoIdCardOutline style={{ marginRight: 4 }} />
                 Investigation
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {presInvestigation}
              </Typography>
            </Box>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IoIdCardOutline style={{ marginRight: 4 }} />
                 Referrel
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {presReferrel}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ px: 2, py: 1 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BiIdCard style={{ marginRight: 4 }} />
                 Notes
              </Typography>
              <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
                {presNotes}
              </Typography>
            </Box>
            <Box sx={{ width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IoIdCardOutline style={{ marginRight: 4 }} />
                 Signature Image
              </Typography>
              <img src={`${BASE_URL}/${presSignatureImg}`} style={{maxWidth:150}} />

            </Box>
          </Box>
        </Box>

        <Box sx={{ px: 2, py: 1 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box sx={{ width: "25%" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: 14,
                  mt: 3,
                  display: "flex",
                  alignItems: "center",
                  mb:3
                }}
              >
                <IoIdCardOutline style={{ marginRight: 4 }} />
                 File
              </Typography>
              {
                presFile &&
              <a href={`${BASE_URL}/${presFile}`} style={{ fontWeight: "500", fontSize: 16, mt: 0.5,backgroundColor:'black',color:'white',padding:8,borderRadius:3 }}>
                Download Prescription
              </a>
              }
            </Box>
          </Box>
        </Box>
      </div>

      <div className=" flex justify-center p-[2rem]">
        <button
          onClick={handleClose}
          className="btn  bg-slate-500	 border-none hover:bg-slate-500 mr-5"
        >
          Back
        </button>

        <button
          onClick={handleClose}
          className="btn modal-button  bg-red-400 border-none hover:bg-red-400"
        >
          Close
        </button>
      </div>
    </Box>
  );
};

export default PrescriptionDetails;
