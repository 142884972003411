import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  userTypes
} from "../../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint
} from "../../../../constants/ApiConstant/routeConstant";
import { useSelector } from "../../../../redux/store";
import AppBredcrumbs from "../../../ui/AppBredcrumbs";
import AppCardContainer from "../../../ui/AppCardContainer";
import AppSubNav from "../../../ui/AppSubNav";
import BasicInfo from "./Details/Basic Info";
import InstituteAddress from "./Details/InstituteAddress";
import RequiredServices from "./Details/RequiredServices";
import StudentInfo from "./Details/StudentInfo";
import { tableConvertedData } from "./helper/convertedInstitutionData";

const InstitutionDetails = () => {
  const { id } = useParams();
  const { token } = useSelector((state) => state.userInfo);
  const [singleInstitution, setSingleInstitution] = useState({});
  const [selectedNav, setSelectedNav] = useState();

  //console.log(singleInstitution);
  const getSingleInstitutionData = async () => {
    const body = {
      profileType: userTypes.schoolAuthority,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(`${BASE_URL}${endPoint.userGet}/${id}`, body, header)
      .then((res) => {
        if (res?.data?.message) {
          //console.log([res.data.data]);
          setSingleInstitution(tableConvertedData([res?.data?.data])[0]);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    getSingleInstitutionData();
  }, []);

  return (
    <div>
      <AppCardContainer spacing="xl:mt-10 3xl:-mr-16 mx-5 mt-20">
        <div className="flex items-center justify-between">
          <AppBredcrumbs
            link="/healthcare/institutions/view"
            allTitle="All Institution"
            detailsTitle="Institution Details"
          />
          <Link
            to={`/pdfview/${userTypes.schoolAuthority}/${id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SimCardDownloadIcon
              style={{ color: "red", cursor: "pointer" }}
              fontSize="large"
            />
          </Link>
        </div>

        <div className="mt-10">
          <AppSubNav
            navbarData={[
              "Basic Info",
              "Institute Address",
              "Student Info",
              "Required Services",
            ]}
            callback={(item) => setSelectedNav(item)}
            type="tab"
            width="w-40"
          />
        </div>

        {selectedNav?.key === "BasicInfo" ? (
          <BasicInfo singleInstitution={singleInstitution} />
        ) : selectedNav?.key === "InstituteAddress" ? (
          <InstituteAddress singleInstitution={singleInstitution} />
        ) : selectedNav?.key === "StudentInfo" ? (
          <StudentInfo singleInstitution={singleInstitution} />
        ) : (
          <RequiredServices singleInstitution={singleInstitution} />
        )}
      </AppCardContainer>
    </div>
  );
};

export default InstitutionDetails;
