import React, { useEffect, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { debounce } from "lodash";
import AppFilterSelect from "./AppFilterSelect";
import AppInput from "./AppInput";

const AppSearchAndFilter = ({
  searchOption,
  searchKey,
  searchVal,
  searchType = false,
  searchPlaceHolder,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [errorType, setErrorType] = useState(false);
  const [selectedPlaceholder, setSelectedPlaceholder] = useState("Search.....");

  const delayedSearchChangeForFilter = useRef(
    debounce((value) => {
      if (searchType) {
        if (isNaN(value)) {
          searchVal(String(value));
        } else {
          searchVal(Number(value));
        }
      } else {
        searchVal(value);
      }
    }, 500)
  ).current;

  useEffect(() => {
    return () => {
      delayedSearchChangeForFilter.cancel();
    };
  }, []);

  const handleSearchValueChange = (value) => {
    delayedSearchChangeForFilter(value);
    setSearchValue(value);
  };

  const onSelectOptionChange = (value) => {
    setSearchValue("");
    searchKey(value.key);
    setSelectedPlaceholder(value.placeHolder);
    setErrorType(false);
  };

  return (
    <div className="flex flex-col">
      <div className={`relative w-full md:w-auto`}>
        <div className="absolute pl-4 top-[37.5%]">
          <BiSearch />
        </div>
        <AppInput
          type="text"
          classes={`input  ${
            errorType ? "border-2 border-red-400" : "input-bordered"
          } bg-secondary-content w-full md:w-[26rem] ${
            searchType ? "lg:w-[28.85rem] w-full pr-48" : "lg:w-96 w-full pr-3"
          }  h-12 text-xs md:text-base pl-10 `}
          placeHolder={searchType ? selectedPlaceholder : searchPlaceHolder}
          onChange={(e) =>
            searchType && selectedPlaceholder === "Search....."
              ? setErrorType(true)
              : handleSearchValueChange(e)
          }
          value={searchValue}
        />
        {searchType && (
          <div className="absolute text-primary-content right-0 top-[1%] border-l border-black/[0.3] my-2 h-8 flex justify-center items-center cursor-pointer">
            <AppFilterSelect
              selectionData={searchOption}
              callback={(data) => onSelectOptionChange(data)}
              showOnClickApi={true}
            />
          </div>
        )}
      </div>
      {errorType ? (
        <span className="text-red-400 mt-1 text-xs font-bold">
          please select type
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default AppSearchAndFilter;
