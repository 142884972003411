import React, { useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { HiOutlineViewGrid } from "react-icons/hi";
import { MdOutlineDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import ques from "../../assets/ques.gif";
import success from "../../assets/success.gif";
import { handleDeleteSuccess } from "../../redux/slices/modalSlice";
import { useDispatch, useSelector } from "../../redux/store";
import AppButton from "./AppButton";
import AppModal from "./AppModal";

const AppTableActionButton = ({
  isView = true,
  isDelete = true,
  isEdit = true,
  viewData,
  editedData,
  viewLink,
  editLink,
  deleteFunction,
  redirectTo,
}) => {
  const { deleteSuccess } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const handleDeleteDetails =  () => {
    deleteFunction();
    const mesg = deleteFunction();
    mesg
      .then((res) => {
        //console.log(res);
        setSuccessMsg(res);
       
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (successMsg !== "") {
      setTimeout(() => {
        setIsShow(false);
        setSuccessMsg("");
        if (redirectTo) {
          navigate(redirectTo);
        }
        dispatch(handleDeleteSuccess(!deleteSuccess));
      }, 2800);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg]);

  return (
    <div className="flex items-center ">
      <>
        {isView && (
          <Link
            to={viewLink}
            state={{ data: viewData }}
            className="btn btn-ghost btn-xs text-lg"
          >
            <HiOutlineViewGrid className="text-blue-400" />
          </Link>
        )}
        {isEdit && (
          <Link
            to={editLink}
            state={{ data: editedData }}
            className="btn btn-ghost btn-xs text-lg"
          >
            <FiEdit2 className="text-orange-400" />
          </Link>
        )}
        <AppModal
          isShowModal={isShow}
          callback={(item) => setIsShow(item)}
          modalhead={false}
          modalWidth="md:w-[450px] w-full"
          modalBtn={
            isDelete && (
              <button className="btn btn-ghost btn-xs text-lg">
                <MdOutlineDelete className="text-red-600" />
              </button>
            )
          }
        >
          <div className="flex flex-col items-center">
            <img
              src={!(successMsg === "") ? success : ques}
              width="150px"
              alt=""
            />
            <p className="font-semibold">
              {!(successMsg === "")
                ? successMsg
                : "Want to delete this details ?"}
            </p>
            <div className="mt-10 flex ">
              <AppButton
                onClick={() => handleDeleteDetails()}
                variant="primary"
                size="xs"
                classes=" !w-24 mr-3"
              >
                Yes
              </AppButton>
              <AppButton
                onClick={() => setIsShow(false)}
                variant="info"
                size="xs"
                classes=" !w-24 "
              >
                Cancel
              </AppButton>
            </div>
          </div>
        </AppModal>
      </>
    </div>
  );
};

export default AppTableActionButton;
