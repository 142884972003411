import { Box, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { AiOutlineMessage, AiOutlineSafetyCertificate } from "react-icons/ai";
import { BiIdCard } from "react-icons/bi";
import { IoIdCardOutline } from "react-icons/io5";
import { BASE_URL, endPoint } from "../../../../constants/ApiConstant/routeConstant";
import { useToken } from "../../../../services/useAuth";
import { timeConvertor } from "../../../../utils/timeConvertor/timeConvertor";

const NoticeInfo = ({ id }) => {
 const [notice, setNotice] = useState();

 const [, setLoading] = useState();

 const token = useToken();
 const header = {
   headers: {
     Authorization: `Bearer ${token}`,
   },
 };

 const getData = async () => {
   setLoading(true);

   await axios
     .get(`${BASE_URL}${endPoint.noticeGet}/${id}`, header)
     .then((res) => {
       setLoading(false);

       if (res?.data?.success) {
         setNotice(res.data?.data);
       }
     })
     .catch((err) => {
       setLoading(false);
       //console.log(err);
     });
 };

 useEffect(() => {
   getData();
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 //console.log(notice);
  
      let n_time = "--";
      if (notice?.n_time) {
        n_time = `${notice?.n_time.slice(0, 10)}  ${timeConvertor(
          notice?.n_time.slice(11, 19),
        )}`;
      }

  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: { md: "33.33%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiIdCard style={{ marginRight: 4 }} />
            Notice Title
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {notice?.n_title ? notice.n_title : "--"}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "33.33%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoIdCardOutline style={{ marginRight: 4 }} />
            Event Name
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {notice?.e_title ? notice.e_title : "--"}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "33.33%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineSafetyCertificate style={{ marginRight: 4 }} />
            Event Uploaded
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {n_time?.slice(0, 10)} {n_time?.slice(11, 21)}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineMessage style={{ marginRight: 4 }} />
            Message
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {notice?.n_msg ? notice.n_msg : "--"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NoticeInfo;
