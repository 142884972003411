import { Autocomplete, Box, Grid, Stack, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { formateDate } from "../../../../../utils/Formatter/dateFormater";

const ParticularDetails = ({
  formParticularDetails,
  errorsParticularDetails,
  onChangeParticularDetails,
  classes,
  isEdit
}) => {
  const [value, setValue] = React.useState(null);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {" "}
          <TextField
            id="name"
            label={formParticularDetails?.name ? "Name" : "Name *"}
            variant="outlined"
            fullWidth
            size="small"
            error={errorsParticularDetails.name ? true : false}
            helperText={errorsParticularDetails?.name}
            value={formParticularDetails.name || ""}
            onChange={(e) => {
              onChangeParticularDetails({
                name: "name",
                value: e.target.value,
              });
            }}
          />
        </Grid>
        
        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            id="bloodGroup"
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            value={formParticularDetails.bloodGroup || ""}
            onChange={(even, newValue) => {
              onChangeParticularDetails({
                name: "bloodGroup",
                value: newValue,
              });
            }}
            options={["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-" ,"Unknown"]}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField
                error={errorsParticularDetails.bloodGroup ? true : false}
                helperText={errorsParticularDetails?.bloodGroup}
                {...params}
                label={
                  formParticularDetails?.bloodGroup
                    ? "Blood Group"
                    : "Blood Group *"
                }
              />
            )}
          />
          </Grid>
       {!isEdit && (
        
        <Grid item xs={6}>
        <TextField
        id="section"
        label={formParticularDetails?.phone ? "Phone" : "Phone *"}
        variant="outlined"
        fullWidth
        size="small"
        error={errorsParticularDetails.phone ? true : false}
        helperText={errorsParticularDetails?.phone}
        value={formParticularDetails?.phone || ""}
        onChange={(e) => {
          onChangeParticularDetails({
            name: "phone",
            value: e.target.value,
          });
        }}
      />
        </Grid>
       )}
       
  
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <DatePicker
                disableFuture
                id="dateOfBirth"
                label={
                  formParticularDetails?.dateOfBirth
                    ? "Date of birth"
                    : "Date of birth *"
                }
                openTo="year"
                views={["year", "month", "day"]}
                value={formParticularDetails.dateOfBirth || null}
                inputFormat="YYYY-MM-DD"
                onChange={(newValue) => {
                  setValue(newValue);
                  onChangeParticularDetails({
                    name: "dateOfBirth",
                    value: formateDate(newValue),
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    error={errorsParticularDetails.dateOfBirth ? true : false}
                    helperText={errorsParticularDetails.dateOfBirth}
                  />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>


        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            id="gender"
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            options={["Male", "Female", "Other"]}
            fullWidth
            size="small"
            value={formParticularDetails.gender || ""}
            onChange={(event, newValue) => {
              onChangeParticularDetails({
                name: "gender",
                value: newValue,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errorsParticularDetails.gender ? true : false}
                helperText={errorsParticularDetails?.gender}
                label={formParticularDetails?.gender ? "Gender" : "Gender *"}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            id="religion"
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            options={["Islam", "Hinduism", "Buddhism", "Cristianity", "Other"]}
            fullWidth
            size="small"
            value={formParticularDetails.religion || ""}
            onChange={(event, newValue) => {
              onChangeParticularDetails({
                name: "religion",
                value: newValue,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errorsParticularDetails.religion ? true : false}
                helperText={errorsParticularDetails?.religion}
                label={
                  formParticularDetails?.religion ? "Religion" : "Religion *"
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="permanentAddress"
            label={
              formParticularDetails?.permanentAddress
                ? "Permanent address"
                : "Permanent address *"
            }
            multiline
            rows={3}
            fullWidth
            error={errorsParticularDetails.permanentAddress ? true : false}
            helperText={errorsParticularDetails?.permanentAddress}
            value={formParticularDetails.permanentAddress || ""}
            onChange={(e) => {
              onChangeParticularDetails({
                name: "permanentAddress",
                value: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="presentAddress"
            label={
              formParticularDetails?.presentAddress
                ? "Present address"
                : "Present address *"
            }
            error={errorsParticularDetails.presentAddress ? true : false}
            helperText={errorsParticularDetails?.presentAddress}
            value={formParticularDetails.presentAddress || ""}
            onChange={(e) => {
              onChangeParticularDetails({
                name: "presentAddress",
                value: e.target.value,
              });
            }}
            multiline
            rows={3}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DatePicker
              disableFuture
              id="joiningDate"
              label={
                formParticularDetails?.joiningDate
                  ? "Joining Date"
                  : "Joining Date *"
              }
              openTo="year"
              views={["year", "month", "day"]}
              value={formParticularDetails.joiningDate || null}
              inputFormat="YYYY-MM-DD"
              onChange={(newValue) => {
                setValue(newValue);
                onChangeParticularDetails({
                  name: "joiningDate",
                  value: formateDate(newValue),
                });
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  error={errorsParticularDetails.joiningDate ? true : false}
                  helperText={errorsParticularDetails.joiningDate}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
      </Grid>
    
      </Grid>
    </Box>
  );
};

export default ParticularDetails;
