import { Grid, Modal, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import * as React from "react";
import PrescriptionDetails from "./PrescriptionDetails";
import prescriptionImg from "../../../../../assets/prescription.png"
const Prescription = ({ singlePrescriptionData }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    apId,
    pId,
  } = singlePrescriptionData;

  return (
    <>
      <Card style={{ padding: "1rem", margin: "1rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <div>
              <Typography
                ml={2}
                style={{ fontWeight: 700, fontSize: 25, color: "#ff5722" }}
              >
              </Typography>

              <CardContent className="flex-column justify-center">
                <div className="flex md:flex-row flex-col justify-start" mb={2}>
                  <Typography
                    mr={2}
                    sx={{ fontWeight: "medium", fontSize: 18 }}
                  >
                    Prescription ID : 
                  </Typography>
                  <Typography>
                    {pId}
                  </Typography>
                </div>
                <div className="flex md:flex-row flex-col justify-start" mb={2}>
                  <Typography
                    mr={2}
                    sx={{ fontWeight: "medium", fontSize: 18 }}
                  >
                    Appointment Id : 
                  </Typography>
                  <Typography>
                    {apId}
                  </Typography>
                </div>
              </CardContent>
              <CardActions disableSpacing>
                <button
                  className="btn  bg-purple-500	 border-none hover:bg-purple-600 mr-5"
                  onClick={handleOpen}
                >
                  View Details
                </button>
              </CardActions>
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <img
              style={{ maxWidth: "120px"}}
              src={prescriptionImg}
              alt=''
            />
          </Grid>
        </Grid>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          overflow: "scroll",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <>
          <PrescriptionDetails
            handleClose={handleClose}
            singlePrescriptionData={singlePrescriptionData}
          />
        </>
      </Modal>
    </>
  );
};

export default Prescription;
