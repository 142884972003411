import React from 'react';
import Logo from "./AppNextIT.png";
import { styles } from './Constant';

const Footer = () => {
  return (
    <div style={styles.footer}>
    <div style={styles.footerWrapper}>
    <b> Powered By  </b>
     <img style={styles.footerLogo} src={Logo} alt='logo' />

    </div>
  </div>
  )
}

export default Footer