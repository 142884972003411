import React from "react";
import {  Typography, Box } from "@mui/material";
import { BiIdCard } from "react-icons/bi";
import {  BsSpeedometer2 } from "react-icons/bs";
import { IoIdCardOutline, IoPulse } from "react-icons/io5";
import { GiStethoscope } from "react-icons/gi";
import { HiOutlineScale } from "react-icons/hi2";
import { CgNotes } from "react-icons/cg";

const GeneralInfo = ({ singleAppointment }) => {
  const {
    dId,
    dName,
    apId,
    pId,
    bloodPressure,
    pulse,
    height,
    weight,
    bloodGlucose,
    additionalNote,
  } = singleAppointment;

  return (
    <div className="">
      <Box sx={{ px: 2, py: 1 }}>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <BiIdCard style={{ marginRight: 4 }} />
              Appointment ID
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {apId}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <IoIdCardOutline style={{ marginRight: 4 }} />
              Patient ID
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {pId}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <IoIdCardOutline style={{ marginRight: 4 }} />
              Doctor ID
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {dId}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <IoIdCardOutline style={{ marginRight: 4 }} />
              Doctor Name
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {dName}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", my: 5 , flexWrap:'wrap'}}>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <GiStethoscope style={{ marginRight: 4 }} />
              Blood Preasure
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {bloodPressure}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <IoPulse style={{ marginRight: 4 }} />
              Pulse
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {pulse}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <BsSpeedometer2 style={{ marginRight: 4 }} />
              Blood Glucose
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {bloodGlucose}
            </Typography>
          </Box>

          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <HiOutlineScale style={{ marginRight: 4 }} />
              Measurment
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              Height: {height}, <br /> Weight: {weight}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", my: 5 }}>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <CgNotes style={{ marginRight: 4 }} />
              Aditional Notes
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {additionalNote}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default GeneralInfo;
