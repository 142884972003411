import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { profileRoutes, routes } from "../../helpers/AllPaths";
import { handleShowDrawer } from "../../redux/slices/drawerSlice";
import { useDispatch, useSelector } from "../../redux/store";
import { useAdminRole } from "../../services/useAdminRole";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";

const Layout = ({ children }) => {
  const { drawerState } = useSelector((state) => state.drawer);
  const dispatch = useDispatch();
  const { roleData } = useAdminRole();
  const [, setOpenSubMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  //console.log(drawerState)

  const [subMenu, setSubMenu] = useState([]);

  useEffect(() => {
    setSubMenu(
      roleData
        .filter((item) =>
          sessionStorage.getItem("url") === null
            ? item?.url === "/dashboard"
            : item?.url === sessionStorage.getItem("url")
        )
        .map((val) => val?.submenu)[0]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subMenu]);

  const [borderClass, setBorderClass] = useState(
    sessionStorage.getItem("subUrl") === null
      ? "/dashboard"
      : sessionStorage.getItem("subUrl")
  );

  const route = ["/admin", "/"];
  if (route.includes(location.pathname)) {
    return <>{children}</>;
  }

  const conditions =
    routes.includes(location.pathname) ||
    location.pathname.split("/", -1)[2] === "details" ||
    location.pathname.split("/", -1)[1] === "pdfview";

  //console.log(location.pathname.split("/", -1)[1]);
  return (
    <div className="bg-[#f7f1e3b3] min-h-screen">
      <div className="shadow-lg relative z-10">
        <Navbar />
      </div>
      <div className=" grid grid-cols-1 xl:grid-cols-12 relative">
        <div
          className={`${
            conditions ? "w-[8.5rem]" : "w-[20rem]"
          }  bg-white h-[100%] col-span-2 drop-shadow-2xl  rounded-tr-[1.5rem] rounded-br-[1.5rem] xl:flex hidden   fixed top-[5rem] 3xl:top-[6rem] pb-8 `}
        >
          <Sidebar setSubMenu={setSubMenu} />
          <div className={`${conditions ? "w-auto" : "w-[40%]"}  h-full`}>
            <div className={` ${conditions ? "px-0 " : "px-8 "}  py-8`}>
              {!conditions &&
                subMenu?.map((elm, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      elm?.submenu ? setOpenSubMenu(true) : navigate(elm?.url)
                    }
                    className=" text-gray-500 mb-8 font-sans text-md cursor-pointer "
                  >
                    <div
                      className={`${
                        borderClass === elm?.url || borderClass === null
                          ? "text-orange-500"
                          : ""
                      } flex items-center`}
                    >
                      <p className="mr-2 text-2xl">{elm?.icon}</p>
                      <p className="">{elm?.title}</p>
                    </div>
                    <>
                      {elm?.submenu &&
                        elm?.submenu?.map((itm, index) => (
                          <div
                            key={index}
                            className={` ${
                              borderClass === itm?.url || borderClass === null
                                ? "text-orange-500"
                                : ""
                            } ml-8 my-5 flex items-center text-base`}
                            onClick={() => {
                              navigate(itm?.url);
                              setBorderClass(itm?.url);
                              sessionStorage.setItem("subUrl", itm?.url);
                            }}
                          >
                            <p className="mr-2 text-lg">{itm?.icon}</p>
                            <p className=" ">{itm?.title}</p>
                          </div>
                        ))}
                    </>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <Drawer
          anchor="left"
          open={drawerState}
          onClose={() => dispatch(handleShowDrawer(false))}
        >
          <div
            className={`${
              conditions ? "w-[8.5rem]" : "w-[20rem]"
            }  bg-white h-[100%] col-span-2 drop-shadow-2xl  flex    fixed top-0  pb-8 `}
          >
            <Sidebar setSubMenu={setSubMenu} />
            <div className={`${conditions ? "w-auto" : "w-[40%]"}  h-full`}>
              <div className={` ${conditions ? "px-0 " : "px-8 "}  py-8`}>
                {!conditions &&
                  subMenu?.map((elm, index) => (
                    <div
                      key={index}
                      onClick={() =>
                        elm?.submenu ? setOpenSubMenu(true) : navigate(elm?.url)
                      }
                      className=" text-gray-500 mb-8 font-sans text-md cursor-pointer "
                    >
                      <div
                        className={`${
                          borderClass === elm?.url || borderClass === null
                            ? "text-orange-500"
                            : ""
                        } flex items-center`}
                      >
                        <p className="mr-2 text-2xl">{elm?.icon}</p>
                        <p className="">{elm?.title}</p>
                      </div>
                      <>
                        {elm?.submenu &&
                          elm?.submenu?.map((itm, index) => (
                            <div
                              key={index}
                              className={` ${
                                borderClass === itm?.url || borderClass === null
                                  ? "text-orange-500"
                                  : ""
                              } ml-8 my-5 flex items-center text-base`}
                              onClick={() => {
                                navigate(itm?.url);
                                setBorderClass(itm?.url);
                                sessionStorage.setItem("subUrl", itm?.url);
                                dispatch(handleShowDrawer(false))
                              }}
                            >
                              <p className="mr-2 text-lg">{itm?.icon}</p>
                              <p className=" ">{itm?.title}</p>
                            </div>
                          ))}
                      </>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Drawer>
        <div
          className={` ${
            conditions
              ? profileRoutes.includes(location.pathname)
                ? "xl:col-span-full pt-20 md:pt-11 3xl:pt-10"
                : " xl:ml-[10rem] xl:p-10 p-5 xl:col-span-10"
              : "2xl:ml-[22rem] xl:ml-[20rem] 3xl:ml-96 ml-0 xl:col-span-9 xl:p-10"
          }   h-auto w-full mt-20 md:mt-12 3xl:mt-20`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
