import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { userTypes } from "../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint,
} from "../../../constants/ApiConstant/routeConstant";
import { useSelector } from "../../../redux/store";
import AppCardContainer from "../../ui/AppCardContainer";
import AppSubNav from "../../ui/AppSubNav";
import AppTable from "../../ui/AppTable";
import { DynamicTableDataForStudents } from "../../ui/helpers/table/TableBodyData";
import { tableHeadStudentsData } from "../../ui/helpers/table/TableHeadeData";
import { useConvertedTableData } from "../../ui/helpers/table/useConvertedTableData";
import { ApexBarChart, ApexPieChart } from "./GetStaticChart";
import { tableConvertedData } from "./helpers/convertedStatisticsData";

const SAStatistics = () => {
  const { classes } = useSelector((state) => state.schoolAuthority);
  const { token, userInfo } = useSelector((state) => state.userInfo);
  const [getChartData, setGetChartData] = useState("");
  const [selectedNav, setSelectedNav] = useState();
  const [studentData, setStudentData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [singleClassData, setSingleClassData] = useState([]);
  const [isAllClass] = useState(false);

  const limit = 8;

  const { tableData, pageCount, handlePageClick } = useConvertedTableData(
    studentData,
    limit
  );

  const getStatusLength = (status, opt) => {
    if (opt === "all") {
      return allData?.filter((item) => item.s_health_status === status).length;
    } else {
      return singleClassData?.filter((item) => item.s_health_status === status)
        .length;
    }
  };

  const getStatusInfo = (status, opt) => {
    if (opt === "all") {
      return allData?.filter((item) => item.s_health_status === status);
    } else {
      return singleClassData?.filter((item) => item.s_health_status === status);
    }
  };

  const getDataForStatistics = async (classId) => {
    const body = {
      type: userTypes.schoolHealth,
      data: {
        insType: "",
        insId: userInfo?.schoolAuthority?.ins_id,
        classId: classId,
        gender: "",
      },
    };

    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios
      .post(`${BASE_URL}${endPoint.filter}`, body, header)
      .then((res) => {
        if (res?.data?.success) {
          return res?.data?.data;
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const getNavbarClassData = () => {
    const navbarClassData = ["All Classes"];
    classes?.map((elm) => navbarClassData.push(`Class ${elm.ins_class_name}`));

    return navbarClassData;
  };

  const getChartValue = () => {
    if (selectedNav?.key === "AllClasses") {
      if (getChartData === "Healthy") {
        const data = getStatusInfo("Healthy", "all");
        setStudentData(tableConvertedData(data));
      }
      if (getChartData === "Needs Attention") {
        const data = getStatusInfo("Needs Attention", "all");
        setStudentData(tableConvertedData(data));
      }
      if (getChartData === "Sick - treated/ referred") {
        const data = getStatusInfo("Sick - treated/ referred", "all");
        setStudentData(tableConvertedData(data));
      }
    } else {
      if (getChartData === "Healthy") {
        const data = getStatusInfo("Healthy", "-");
        setStudentData(tableConvertedData(data));
      }
      if (getChartData === "Needs Attention") {
        const data = getStatusInfo("Needs Attention", "-");
        setStudentData(tableConvertedData(data));
      }
      if (getChartData === "Sick - treated/ referred") {
        const data = getStatusInfo("Sick - treated/ referred", "-");
        setStudentData(tableConvertedData(data));
      }
    }
  };

  useEffect(() => {
    if (selectedNav?.key === "AllClasses") {
      // setIsAllClassa(!isAllClass);
      getDataForStatistics(0).then((res) => {
        setAllData(res);
        setStudentData(tableConvertedData(res));
      });
    } else {
      const getClass = classes.find(
        (c) => c.ins_class_name === selectedNav?.key?.slice(5)
      );
      getDataForStatistics(getClass?.ins_class_id).then((res) => {
        setSingleClassData(res);
        setStudentData(tableConvertedData(res));
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, selectedNav]);

  useEffect(() => {
    getChartValue();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChartData, isAllClass]);

  return (
    <div className="3xl:-mr-10 mx-0 md:mx-5 mt-16 xl:-mr-16 xl:mx-0 xl:mt-0">
      <AppCardContainer spacing="mt-10" width="w-full" classes="">
        <div className="mb-10">
          <AppSubNav
            navbarData={getNavbarClassData()}
            callback={(item) => setSelectedNav(item)}
            type="button"
            width="w-32"
          />
        </div>
        <div className="w-full grid 2xl:grid-cols-6 grid-cols-1">
          <div className="2xl:col-span-3 w-full flex items-center">
            <ApexBarChart
              setGetChartData={setGetChartData}
              healthStatus={{
                healthy:
                  selectedNav?.key === "AllClasses"
                    ? getStatusLength("Healthy", "all")
                    : getStatusLength("Healthy", "-"),
                attention:
                  selectedNav?.key === "AllClasses"
                    ? getStatusLength("Needs Attention", "all")
                    : getStatusLength("Needs Attention", "-"),
                sick:
                  selectedNav?.key === "AllClasses"
                    ? getStatusLength("Sick - treated/ referred", "all")
                    : getStatusLength("Sick - treated/ referred", "-"),
              }}
              chartTitle={`Health Status of ${
                selectedNav?.key === "AllClasses"
                  ? "All Classes"
                  : `Class ${selectedNav?.key?.slice(5)}`
              } `}
            />
          </div>
          <div className="2xl:col-span-3 w-full flex items-center mt-10 2xl:mt-0 2xl:justify-end">
            <ApexPieChart
              healthStatus={{
                healthy: getStatusLength("Healthy", "all"),
                attention: getStatusLength("Needs Attention", "all"),
                sick: getStatusLength("Sick - treated/ referred", "all"),
              }}
            />
          </div>
        </div>
      </AppCardContainer>
      <div>
        <AppTable
          tableTitle={`Students of ${
            selectedNav?.key === "AllClasses"
              ? "All Classes"
              : `Class ${selectedNav?.key?.slice(5)}`
          } `}
          titleClass="font-bold -ml-3"
          tableHeadData={tableHeadStudentsData}
          tableBodyData={tableData}
          dynamicTable={(colItem, headItem, index, shadow) =>
            DynamicTableDataForStudents(colItem, headItem, index, shadow)
          }
          pagination={true}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
        />
      </div>
    </div>
  );
};

export default SAStatistics;
