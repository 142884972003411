import React from "react";
import {  Typography, Box } from "@mui/material";
import {  GrStatusInfo } from "react-icons/gr";

const InstitutionalInfo = ({ singleStudents }) => {
  const {
 
    insName,
  } = singleStudents;
  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <GrStatusInfo style={{ marginRight: 4 }} />
            Educational Institution
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insName}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default InstitutionalInfo;
