
// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)

import React from "react";
import { styles } from "../../Common/Constant";
import Footer from '../../Common/Footer';
import Header from '../../Common/Header';
import MyTable from '../../Common/Table';

export const DoctorTableToPrint = React.forwardRef((props, ref) => {


 const headerData = ['NO','Name','ID','Phone','Degree','Address','Working Place','Charge','Experience','Specialty']

  const bodyData =props.data?.map((item)=>{
    return [item.d_name,item.d_id,item.user_phone_id,item.d_degree,`${item.d_district},${item.d_division}`,item.d_woking_place_detials,`${item.d_charge} ৳`,item.d_total_exp_years,item.d_specialty]
 })

  return (
    <div ref={ref} style={styles.page}>
      <Header profile={props.data} proType={props.proType} title={"Doctors List"} />
      <div style={styles.container}>
        <div style={styles.leftColumn}>
          <div style={styles.container}>

          {
            props.data.length>0 ? (
          <MyTable 
            headersData={headerData}
            bodyData = {bodyData}
          />
            ) : <h3 style={{textAlign:'center'}}> No Class found!</h3>
          }

          </div>
          

        </div>

        
        
      </div>
      <Footer/>
    </div>
  );
});