export const tableConvertedData = (res) => {
  let data = [];
  res?.map((itm) => {
    data.push({
      userId: itm?.user_id|| "--",
      userPhone_id: itm?.user_phone_id|| "--",
      userName: itm?.user_name|| "--",
      userImg: itm?.user_img|| "--",
      userSchlHealth: itm?.user_schl_health|| "--",
      userTelemedicine: itm?.user_telemedicine|| "--",
      userOlymp: itm?.user_olymp|| "--",
      userHijama: itm?.user_hijama|| "--",
      userAdminType: itm?.user_admin_type|| "--",
      pId: itm?.p_id|| "--",
      pName: itm?.p_name|| "--",
      pDob: itm?.p_dob|| "--",
      pGender: itm?.p_gender|| "--",
      pProfession: itm?.p_profession|| "--",
      pAddress: itm?.p_address|| "--",
      pPhone: itm?.p_phone|| "--",
      pEmail: itm?.p_email|| "--",
      pNid: itm?.p_nid|| "--",
      pBirthCft: itm?.p_birth_cft|| "--",
    });
  });

  return data;
};
