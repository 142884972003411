import React, { useRef } from "react";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

export const ApexBarChart = ({ dashboardData, chartTitle }) => {
  const {
    totalUsers,
    totalAdmins,
    totalDoctors,
    totalPatients,
    totalInstitutes,
    totalStudents,
    totalHijamaUsers,
    totalOlympiadUsers,
  } = dashboardData;
  const [chartData, setChartData] = useState();
  const chartRef = useRef(null);

  const [selectedBarIndex, setSelectedBarIndex] = useState(null);

  const toggleBarSelection = (event, chartContext, config) => {
    const chart = chartRef.current.chart;
    const currentIndex = chart.w.globals.selectedBar;
    const newIndex =
      currentIndex === config.dataPointIndex ? null : config.dataPointIndex;
    setChartData(newIndex);
    if (newIndex === null) {
      chart.updateOptions({
        plotOptions: {
          bar: {
            selectedBar: {
              index: null,
            },
          },
        },
      });
    } else {
      chart.updateOptions({
        plotOptions: {
          bar: {
            selectedBar: {
              index: newIndex,
            },
          },
        },
      });
    }
    //console.log(chart.w.config.plotOptions.bar.selectedBar);
    setSelectedBarIndex(newIndex);
  };

  const chartState = {
    series: [
      {
        name: "",
        data: [
          totalUsers,
          totalAdmins,
          totalDoctors,
          totalPatients,
          totalInstitutes,
          totalStudents,
          totalHijamaUsers,
          totalOlympiadUsers,
        ],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        height: 370,
        type: "bar",
      },

      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      colors: [
        "#ff9f43",
        "#4834d4",
        "#00b894",
        "#d63031",
        "#B33771",
        "#0fbcf9",
        "#576574",
      ],
      dataLabels: {
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },

      xaxis: {
        categories: [
          "Total Users",
          "Admins",
          "Doctors",
          "Patients",
          "School Authority",
          "Students",
          "Hijama Users",
          "Olympiad Users",
        ],
        position: "top",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        // labels: {
        //   show: false,
        //   formatter: function (val) {
        //     return val + "%";
        //   },
        // },
      },
      title: {
        text: chartTitle,
        style: {
          color: "#444",
        },
      },
    },
  };

  //   useEffect(() => {
  //     const filteredData = chartState.options.xaxis.categories.filter(
  //       (_, i) => i === chartData
  //     )[0];
  //     setGetChartData(filteredData);
  //   }, [chartData]);

  return (
    <>
      <ReactApexChart
        options={{
          ...chartState.options,
          chart: {
            ...chartState.options.chart,
            events: {
              dataPointSelection: toggleBarSelection,
            },
          },
        }}
        series={chartState.series}
        type="bar"
        height={350}
        width="100%"
        ref={chartRef}
      />
    </>
  );
};
export const ApexRadialBarChart = ({ dashboardData, chartTitle }) => {
  const { totalInstitutes, totalStudents } = dashboardData;

  const [chartData, setChartData] = useState();
  const chartRef = useRef(null);

  const [selectedBarIndex, setSelectedBarIndex] = useState(null);

  const toggleBarSelection = (event, chartContext, config) => {
    const chart = chartRef.current.chart;
    const currentIndex = chart.w.globals.selectedBar;
    const newIndex =
      currentIndex === config.dataPointIndex ? null : config.dataPointIndex;
    setChartData(newIndex);
    if (newIndex === null) {
      chart.updateOptions({
        plotOptions: {
          bar: {
            selectedBar: {
              index: null,
            },
          },
        },
      });
    } else {
      chart.updateOptions({
        plotOptions: {
          bar: {
            selectedBar: {
              index: newIndex,
            },
          },
        },
      });
    }
    //console.log(chart.w.config.plotOptions.bar.selectedBar);
    setSelectedBarIndex(newIndex);
  };

  const chartState = {
    series: [totalInstitutes, totalStudents],
    options: {
      chart: {
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: "30%",
            background: "transparent",
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      colors: ["#1ab7ea", "#0084ff"],
      labels: ["Institutions", "Students"],
      legend: {
        show: true,
        floating: true,
        fontSize: "12px",
        position: "left",
        offsetX: 100,
        offsetY: 60,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        formatter: function (seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
        },
        itemMargin: {
          vertical: 3,
        },
      },
      title: {
        text: chartTitle,
        style: {
          color: "#444",
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  };

  return (
    <>
      <ReactApexChart
        options={{
          ...chartState.options,
          chart: {
            ...chartState.options.chart,
            events: {
              dataPointSelection: toggleBarSelection,
            },
          },
        }}
        series={chartState.series}
        type="radialBar"
        ref={chartRef}
      />
    </>
  );
};
export const ApexPolarAreaChart = ({ dashboardData, chartTitle }) => {
  const { totalDoctors, totalPatients, totalAppointments, totalPrescriptions } =
    dashboardData;

  const [chartData, setChartData] = useState();
  const chartRef = useRef(null);

  const [selectedBarIndex, setSelectedBarIndex] = useState(null);

  const toggleBarSelection = (event, chartContext, config) => {
    const chart = chartRef.current.chart;
    const currentIndex = chart.w.globals.selectedBar;
    const newIndex =
      currentIndex === config.dataPointIndex ? null : config.dataPointIndex;
    setChartData(newIndex);
    if (newIndex === null) {
      chart.updateOptions({
        plotOptions: {
          bar: {
            selectedBar: {
              index: null,
            },
          },
        },
      });
    } else {
      chart.updateOptions({
        plotOptions: {
          bar: {
            selectedBar: {
              index: newIndex,
            },
          },
        },
      });
    }
    //console.log(chart.w.config.plotOptions.bar.selectedBar);
    setSelectedBarIndex(newIndex);
  };

  const chartState = {
    series: [
      totalDoctors,
      totalPatients,
      totalAppointments,
      totalPrescriptions,
    ],
    options: {
      chart: {
        type: "polarArea",
      },
      stroke: {
        colors: ["#fff"],
      },
      fill: {
        opacity: 0.8,
      },
      colors: ["#00b894", "#d63031", "#ff9f43", "#4834d4"],
      labels: ["Doctors", "Patients", "Appointments", "Prescriptions"],
      title: {
        text: chartTitle,
        style: {
          color: "#444",
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <>
      <ReactApexChart
        options={{
          ...chartState.options,
          chart: {
            ...chartState.options.chart,
            events: {
              dataPointSelection: toggleBarSelection,
            },
          },
        }}
        series={chartState.series}
        type="polarArea"
        ref={chartRef}
      />
    </>
  );
};
