import React from "react";
import ReactPaginate from "react-paginate";

const AppTablePagination = ({
  pageCount,
  handlePageClick,
  paddingY = "py-6 ",
}) => {
  return (
    <div className={` ${paddingY} w-full flex justify-center rounded-xl bg-white`}>
      <ReactPaginate
        previousLabel={"←"}
        nextLabel={"→"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={
          "pagination flex items-center text-black text-xs md:text-base font-semibold py-2 px-8 space-x-2 md:space-x-5"
        }
        previousLinkClassName={
          "border border-black rounded-lg px-4  py-1 text-xs md:text-sm text-black font-bold"
        }
        nextLinkClassName={
          "border border-black rounded-lg px-4  py-1 text-xs md:text-sm  text-black font-bold "
        }
        activeClassName={
          "bg-blue-600 text-white flex justify-center rounded px-2"
        }
        disabledClassName={"opacity-50 rounded px-2 cursor-not-allowed"}
      />
    </div>
  );
};

export default AppTablePagination;
