
import React, { useEffect, useState } from "react";
import { BsCardChecklist } from "react-icons/bs";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { userTypes } from "../../constants/ApiConstant/databaseConstant";
import { useSelector } from "../../redux/store";
import { useGetDashboardData } from "../../services/api";
import AppDashboardCard from "../ui/AppDashboardCard";

const DoctorDashboard = () => {
  const { userInfo } = useSelector((state) => state.userInfo);
  const [, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState({});

  //console.log(dashboardData);
  const body = {
    type: userTypes.doctor,
    id: userInfo?.doctor?.d_id,
  };

  const { getSADashboardData } = useGetDashboardData(body);

  useEffect(() => {
    setIsLoading(true);
    getSADashboardData()
      .then((res) => {
        setDashboardData(res);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="mt-20 xl:mt-10 xl:-mr-16">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        <AppDashboardCard
          title="Total Patients"
          value={dashboardData?.totalPatients}
          viewLink="/patients"
          bgColor="bg-orange-50"
          textColor="text-orange-600"
          icon={
            <MdOutlinePersonalInjury className="text-5xl text-orange-600" />
          }
        />

        <AppDashboardCard
          title="Total Appointments"
          value={dashboardData?.totalAppointments}
          viewLink="/appointments"
          bgColor="bg-fuchsia-50"
          textColor="text-fuchsia-600"
          icon={<BsCardChecklist className="text-5xl text-fuchsia-600" />}
        />
        <AppDashboardCard
          title="Total Prescriptions"
          value={dashboardData?.totalPrescriptions}
          viewLink="/prescriptions"
          bgColor="bg-sky-50"
          textColor="text-sky-600"
          icon={
            <HiOutlineClipboardDocumentList className="text-5xl text-sky-600" />
          }
        />
      </div>
    </div>
  );
};

export default DoctorDashboard;
