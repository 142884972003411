
const API = 'https://api.adminomphd.online/';
const styles = {

  page: {
    padding: 20,
    background:'white',
    color:'black',
    position:'relative',
    paddingBottom:'2rem',
  },
  headerContainer:{
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
  },
  header:{
    display:'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    fontFamily:'Noto Serif Bengali'
  },
  detailColumnLeft: {
    flexDirection: 'column',
    flex: 0.25,
    textTransform: 'uppercase',
    fontFamily:'Noto Serif Bengali'
  },
  detailColumnRight: {
    flexDirection: 'column',
    flex: 0.75,
    textTransform: 'uppercase',
    fontFamily:'Noto Serif Bengali',
    display:'flex',
    alignItems:'flex-end',
    justifyContent:'end'
  },
  
  headImage: {
    marginBottom: "-10px",
    width:100,
    marginLeft:-10
  },
  logoHeader:{
    fontSize: 23,
    fontWeight:600,
    fontFamily:'Noto Serif Bengali',
    marginBottom:-10
  },
  rightHeaderText:{
    fontSize:13,
    fontWeight:600,
    textTransform:'none'

  },
  name: {
    fontSize: 24,
    fontFamily:'Noto Serif Bengali',
    marginBottom:'0px'
  },
  subtitle: {
    fontSize: 18,
    justifySelf: 'flex-end',
    fontFamily:'Noto Serif Bengali',
    margin:0
  },
  container: {
    display:'flex',
    flex: 1,
    flexDirection: "row",
    justifyContent:'center',
    marginBottom:10,
  },
  image: {
    marginBottom: 10,
    marginBottom: "0px",
    width:'25%',
    maxHeight:150,
    border:'2px solid black',
    padding:2
  },
  leftColumn: {
    flexDirection: "column",
    flex:1,
    paddingTop: 30,
    paddingRight: 15,
  },
  textMiddle:{
    textAlign:'center',
    fontFamily:'Noto Serif Bengali',
    fontSize:22,
    fontWeight:700,
    borderBottom:'1px solid black',
    marginBottom:10,
    marginTop:25,
    padding:10,
    color:'white',
    background:'black'
  },
  footer: {
    fontSize: 10,
    color: "red",
    fontWeight: "bold",
    fontFamily: "Lato Bold",
    textAlign: "center",
    position:'absolute',
    bottom:2,
    right:25,
    display: "flex",
    justifyContent: "flex-end",
    "@media orientation: landscape": {
      marginTop: 10
    }
  },
  footerWrapper:{
    display:'flex',
   flexDirection:'column',
   justifySelf:'flex-end', 
   marginRight: 10

  },
  footerLogo:{
    width:40,
    margin:'0 auto'
  },
  rightcontainer: {
    flexDirection:'column',
    paddingTop: 30,
    flex: 0.75,
    paddingLeft: 15,
    "@media max-width: 400": {
      paddingTop: 10,
      paddingLeft: 0
    }
  },
  ///inner design

  innerContainer: {
    marginBottom: 5,
  },
  sectionContainer:{
    border:'1px dotted black',padding:15,marginTop:20,
  },
  subHeader:{
    fontFamily:'Noto Serif Bengali',
    fontSize:20,
    fontWeight:700,
    border:'1px solid black',
    textAlign:'center',
    marginBottom:10,
    background:'black',
    color:'white',
  },
  itemContainer:{
   padding:0,
  },
  
  item:{
    display:'flex',
    flexDirection:'column',
    margin:5,
    paddingBottom:3,
    border:'0.5px solid rgb(54 54 62)',
   },
   tag: {
     fontFamily: 'Noto Serif Bengali',
     fontSize: 17,
     fontWeight:700,
     margin:0,
     padding:0,
     textAlign:'center',
     textDecoration:'underline'
   },
   value:{
     fontFamily:'Noto Serif Bengali',
     fontSize:17,
     fontWeight:500,
     textAlign:'left',
     marginLeft:5,
     padding:0,
     whiteSpace:'pre-wrap',
     display:'table'
   },
    pagebreak:{ 
      pageBreakBefore: 'always'
    } /* page-break-after works, as well */

    ,
    //right side styling
    entryContainer: {
      marginBottom: 10,
      border:'1px dotted black',
      padding:4,
      display:'block',
    },
    list:{
      fontSize: 11,
    fontFamily: 'Noto Serif Bengali',
    listStyle: 'disc',
    },
    leftColumn2: {
      flexDirection: "column",
      flex:0.25,
      paddingTop: 5,
      paddingRight: 15,
      "@media max-width: 400": {
        width: "100%",
        paddingRight: 0
      },
      "@media orientation: landscape": {
        width: 200
      }
    },

};
export { API, styles };

