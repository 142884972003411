import { Autocomplete, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BASE_URL,
  endPoint,
} from "../../../../constants/ApiConstant/routeConstant";
import { useToken } from "../../../../services/useAuth";
import { notify } from "../../../ui/helpers/ToastMsg";

const EditQuiz = () => {
  const [formQuiz, setFormQuiz] = useState({});
  const [errorsQuiz, setErrorsQuiz] = useState({});
  const [quiz, setQuiz] = useState({});

  const token = useToken();
  const { id } = useParams();
  const navigate = useNavigate()

  const onChangeQuiz = ({ name, value }) => {
    setFormQuiz({ ...formQuiz, [name]: value });
    if (name === "question") {
      if (value === "") {
        setErrorsQuiz((prev) => {
          return {
            ...prev,
            [name]: "Question cann't be blank.",
          };
        });
      } else {
        setErrorsQuiz((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "option1") {
      if (value === "") {
        setErrorsQuiz((prev) => {
          return {
            ...prev,
            [name]: "Option1 cann't be blank.",
          };
        });
      } else {
        setErrorsQuiz((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "option2") {
      if (value === "") {
        setErrorsQuiz((prev) => {
          return {
            ...prev,
            [name]: "Option2 cann't be blank.",
          };
        });
      } else {
        setErrorsQuiz((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "option3") {
      if (value === "") {
        setErrorsQuiz((prev) => {
          return {
            ...prev,
            [name]: "Option3 cann't be blank.",
          };
        });
      } else {
        setErrorsQuiz((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "option4") {
      if (value === "") {
        setErrorsQuiz((prev) => {
          return {
            ...prev,
            [name]: "Option4 cann't be blank.",
          };
        });
      } else {
        setErrorsQuiz((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "currectAnswer") {
      if (value === "") {
        setErrorsQuiz((prev) => {
          return {
            ...prev,
            [name]: "Currect Answer cann't be blank.",
          };
        });
      } else {
        setErrorsQuiz((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "point") {
      if (value === "" || value < 0) {
        setErrorsQuiz((prev) => {
          return {
            ...prev,
            [name]: "Point cann't be blank or Negative.",
          };
        });
      } else {
        setErrorsQuiz((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
  };
  const onSubmitQuiz = () => {
    if (!formQuiz.question) {
      setErrorsQuiz((prev) => {
        return {
          ...prev,
          question: "Please add a Question",
        };
      });
    }
    if (!formQuiz.option1) {
      setErrorsQuiz((prev) => {
        return {
          ...prev,
          option1: "Please add option1",
        };
      });
    }
    if (!formQuiz.option2) {
      setErrorsQuiz((prev) => {
        return {
          ...prev,
          option2: "Please add option2",
        };
      });
    }
    if (!formQuiz.option3) {
      setErrorsQuiz((prev) => {
        return {
          ...prev,
          option3: "Please add option3",
        };
      });
    }
    if (!formQuiz.option4) {
      setErrorsQuiz((prev) => {
        return {
          ...prev,
          option4: "Please add option4",
        };
      });
    }
    if (!formQuiz.currectAnswer) {
      setErrorsQuiz((prev) => {
        return {
          ...prev,
          currectAnswer: "Please add  The currect answer",
        };
      });
    }
    if (!formQuiz.point) {
      setErrorsQuiz((prev) => {
        return {
          ...prev,
          point: "Please add point for this Question",
        };
      });
    }

    if (
      formQuiz.question &&
      formQuiz.option1 &&
      formQuiz.option2 &&
      formQuiz.option3 &&
      formQuiz.option4 &&
      formQuiz.currectAnswer &&
      formQuiz.point &&
      Object.values(errorsQuiz).every((item) => !item)
    ) {
      setQuiz({ ...formQuiz });
      onSubmit();
    }
  };



  const QuizData = () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
   

    axios
      .get(`${BASE_URL}${endPoint.quizEdit}/${id}`, header)
      .then((res) => {
        if (res?.data?.success) {
          //console.log(res.data?.data);
          const quizData = res.data?.data;
          setFormQuiz({
            question: quizData?.q_title,
            option1: quizData?.q_op_1,
            option2: quizData?.q_op_2,
            option3: quizData?.q_op_3,
            option4: quizData?.q_op_4,
            currectAnswer: quizData?.q_ans,
            point: quizData?.q_mark,
        
          })
        }
      })
      .catch((err) => {
        //console.log(err);
        notify(err.response.data?.message, "warning");
      });
  };

  useEffect(() => {
    QuizData();
  },[]);

  const onSubmit = () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const bodyData = {
      title: formQuiz?.question,
      mark: formQuiz?.point,
      ans: formQuiz?.currectAnswer,
      op1: formQuiz?.option1,
      op2: formQuiz?.option2,
      op3: formQuiz?.option3,
      op4: formQuiz?.option4,
    };

    axios
      .put(`${BASE_URL}${endPoint.quiz}/${id}`, bodyData, header)
      .then((res) => {
        if (res?.data?.success) {
          //console.log(res.data?.data);
          notify("Quiz Update Successfully!", "success");
          navigate("/hijama/quiz/view");
        }
      })
      .catch((err) => {
        //console.log(err);
        notify(err.response.data?.message, "warning");
      });
  };

  return (
    <div className="my-2 mt-10 shadow-md rounded-xl">
      <div className=" bg-white px-[3rem] py-[2rem] rounded-xl">
        <h1 className=" text-2xl pt-5 text-center font-bold uppercase">
          Edit Quiz
        </h1>{" "}
        <Box sx={{ flexGrow: 1, mt: 7 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="question"
                variant="outlined"
                fullWidth
                size="small"
                value={formQuiz?.question || ""}
                label={formQuiz?.question ? "Question" : "Question *"}
                onChange={(e) => {
                  onChangeQuiz({
                    name: "question",
                    value: e.target.value,
                  });
                }}
                error={errorsQuiz?.question ? true : false}
                helperText={errorsQuiz?.question}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="option1"
                variant="outlined"
                fullWidth
                size="small"
                value={formQuiz?.option1 || ""}
                label={formQuiz?.option1 ? "Option1" : "Option1 *"}
                onChange={(e) => {
                  onChangeQuiz({
                    name: "option1",
                    value: e.target.value,
                  });
                }}
                error={errorsQuiz?.option1 ? true : false}
                helperText={errorsQuiz?.option1}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="option2"
                variant="outlined"
                fullWidth
                size="small"
                value={formQuiz?.option2 || ""}
                label={formQuiz?.option2 ? "Option2" : "Option2 *"}
                onChange={(e) => {
                  onChangeQuiz({
                    name: "option2",
                    value: e.target.value,
                  });
                }}
                error={errorsQuiz?.option2 ? true : false}
                helperText={errorsQuiz?.option2}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="option3"
                variant="outlined"
                fullWidth
                size="small"
                value={formQuiz?.option3 || ""}
                label={formQuiz?.option3 ? "Option3" : "Option3 *"}
                onChange={(e) => {
                  onChangeQuiz({
                    name: "option3",
                    value: e.target.value,
                  });
                }}
                error={errorsQuiz?.option3 ? true : false}
                helperText={errorsQuiz?.option3}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="option4"
                variant="outlined"
                fullWidth
                size="small"
                value={formQuiz?.option4 || ""}
                label={formQuiz?.option4 ? "Option4" : "Option4 *"}
                onChange={(e) => {
                  onChangeQuiz({
                    name: "option4",
                    value: e.target.value,
                  });
                }}
                error={errorsQuiz?.option4 ? true : false}
                helperText={errorsQuiz?.option4}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="currectAnswer"
                variant="outlined"
                fullWidth
                size="small"
                value={formQuiz?.currectAnswer || ""}
                label={
                  formQuiz?.currectAnswer
                    ? "Currect Answer"
                    : "Currect Answer *"
                }
                onChange={(e) => {
                  onChangeQuiz({
                    name: "currectAnswer",
                    value: e.target.value,
                  });
                }}
                error={errorsQuiz?.currectAnswer ? true : false}
                helperText={errorsQuiz?.currectAnswer}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="point"
                variant="outlined"
                type="number"
                fullWidth
                size="small"
                label={formQuiz?.point ? "Point" : "Point *"}
                value={formQuiz?.point || ""}
                onChange={(e) => {
                  onChangeQuiz({
                    name: "point",
                    value: e.target.value,
                  });
                }}
                error={errorsQuiz?.point ? true : false}
                helperText={errorsQuiz?.point}
              />
            </Grid>
          </Grid>
        </Box>
        <div className=" flex justify-center">
          <button
            className="btn bg-blue-600 hover:bg-blue-600  border-none  font-bold mt-5 px-10 rounded-2xl  "
            onClick={() => onSubmitQuiz()}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditQuiz;
