import { Box } from '@mui/material'
import React from 'react'
import BasicMedicalHistory from './BasicMedicalHistory'
import GeneralExamination from './GeneralExamination'
import Remarks from './Remarks'
import SystemicExamination from './SystemicExamination'
const style = {
  width: '90%',
  height:'90vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,  
  overflowY: "auto"

};
const titleStyle={
    fontSize: '20px',
    textAlign: 'center',
    border: "2px solid gray",
    padding: '0.5rem',
    margin: '0.5rem 0',
    fontWeight: 600
}

const HealthRecordsDetails = ({ handleClose, singleHealthRecord }) => {
  return (
    <Box className="healthRecordsDetailsModal" sx={style}>
      <h3 style={titleStyle}>Basic Medical History</h3>
      <BasicMedicalHistory singleHealthRecord={singleHealthRecord} />
      <h3 style={titleStyle}>General Examination</h3>
      <GeneralExamination singleHealthRecord={singleHealthRecord} />
      <h3 style={titleStyle}>Systemic Examination</h3>
      <SystemicExamination singleHealthRecord={singleHealthRecord} />
      <h3 style={titleStyle}>Remarks</h3>
      <Remarks singleHealthRecord={singleHealthRecord} />

      <div className=" flex justify-center p-[2rem]">
        <button
          onClick={handleClose}
          className="btn  bg-slate-500	 border-none hover:bg-slate-500 mr-5"
        >
          Back
        </button>

        <button
          onClick={handleClose}
          className="btn modal-button  bg-red-400 border-none hover:bg-red-400"
        >
          Close
        </button>
      </div>
    </Box>
  );
};

export default HealthRecordsDetails
