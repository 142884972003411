import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsCardChecklist } from "react-icons/bs";
import {
  pdfTypes,
  searchTypes,
  userTypes
} from "../../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint
} from "../../../../constants/ApiConstant/routeConstant";
import { olympiedUsersPdfFnc } from "../../../../redux/slices/tableDataForPdfSlice";
import { useDispatch, useSelector } from "../../../../redux/store";
import AppTable from "../../../ui/AppTable";
import { DynamicTableDataForSARegisteredStudents } from "../../../ui/helpers/table/TableBodyData";
import { tableHeadRegisteredStudentsData } from "../../../ui/helpers/table/TableHeadeData";
import { useConvertedTableData } from "../../../ui/helpers/table/useConvertedTableData";
import { tableConvertedData } from "./helper/convertedAppointmentData";
import FilterData from "./helper/FilterData";

export const OlympiadData = {
  division: "",
  district: "",
  eventName: "",
  eventId: 0,
};

const SARegisteredStudentsView = () => {
  const { deleteSuccess } = useSelector((state) => state.modal);
  // const { olympiedUsersPdf } = useSelector((state) => state.tablePdf);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [registeredStudents, setRegisteredStudents] = useState([]);
  const { token } = useSelector((state) => state.userInfo);
  const [searchValue, setSearchValue] = useState("");
  const [clearFilter, setClearFilter] = useState(false);
  const [registeredStudentFilter, setRegisteredStudentFilter] =
    useState(OlympiadData);

  const limit = 6;

  const { tableData, pageCount, handlePageClick } =
    useConvertedTableData(registeredStudents, limit);

  const getAllRegisteredStudentsData = async () => {
    const body = {
      profileType: userTypes.olympiad,
    };

    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    await axios
      .post(`${BASE_URL}${endPoint.userGet}`, body, header)
      .then((res) => {
        if (res?.data?.success) {
          setRegisteredStudents(tableConvertedData(res?.data?.data));
          setLoading(false);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const getAllRegisteredStudentsSearchedData = async () => {
    if (!(searchValue === "")) {
      const body = {
        type: searchTypes.olympiad,
        data: searchValue,
      };

      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);
      await axios
        .post(`${BASE_URL}${endPoint.search}`, body, header)
        .then((res) => {
          if (res?.data?.success) {
            setRegisteredStudents(tableConvertedData(res?.data?.data));
            setLoading(false);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    } else {
      getAllRegisteredStudentsData();
    }
  };

  const handleFilter = async () => {
    if (
      registeredStudentFilter.division === "" &&
      registeredStudentFilter.district === "" &&
      registeredStudentFilter.eventId === 0
    ) {
      getAllRegisteredStudentsData();
    } else {
      const newData = { ...registeredStudentFilter };
      delete newData.eventName;
      const body = {
        type: searchTypes.olympiad,
        data: newData,
      };

      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);
      await axios
        .post(`${BASE_URL}${endPoint.filter}`, body, header)
        .then((res) => {
          if (res?.data?.success) {
            setRegisteredStudents(tableConvertedData(res?.data?.data));
            setLoading(false);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };

  const handleDeleteUser = async ( userId, id) => {
    const body = {
      profileType:  userTypes.olympiad,
      userId: userId,
      id: id,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios
      .post(`${BASE_URL}${endPoint.deleteUser}`, body, header)
      .then((res) => {
        //console.log(res.data.message);
        return res.data.message;
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  
  useEffect(() => {
    if (registeredStudents) {
      dispatch(olympiedUsersPdfFnc(registeredStudents));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registeredStudents, searchValue]);

  useEffect(() => {
    getAllRegisteredStudentsData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess, clearFilter]);

  useEffect(() => {
    getAllRegisteredStudentsSearchedData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div className="3xl:-mr-10 mx-5 mt-20 xl:mx-0 xl:mt-0">
      <AppTable
        tableTitle="Registered Students Information"
        titleClass="font-bold"
        titleIcon={<BsCardChecklist />}
        search
        searchPlaceHolder="Search by olympiad user name, id and dob"
        searchVal={(val) => setSearchValue(val)}
        filter
        filterBox={
          <FilterData
            registeredStudentFilter={registeredStudentFilter}
            setRegisteredStudentFilter={setRegisteredStudentFilter}
            handleFilter={handleFilter}
            setClearFilter={setClearFilter}
            clearFilter={clearFilter}
          />
        }
        handleDelete={handleDeleteUser}
        tableHeadData={tableHeadRegisteredStudentsData}
        tableBodyData={tableData}
        dynamicTable={(colItem, headItem, index, shadow, handleDelete) =>
          DynamicTableDataForSARegisteredStudents(
            colItem,
            headItem,
            index,
            shadow, 
            handleDelete
          )
        }
        pdf={`/pdfview/${pdfTypes.olympiadList}`}
        loader={loading}
        pagination
        pageCount={pageCount}
        handlePageClick={handlePageClick}
      />
    </div>
  );
};

export default SARegisteredStudentsView;
