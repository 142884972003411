import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { MdOutlinePersonalInjury } from "react-icons/md";
import {
  BASE_URL,
  endPoint,
} from "../../../constants/ApiConstant/routeConstant";
import { singlePatientsPdfFnc } from "../../../redux/slices/tableDataForPdfSlice";
import { useDispatch, useSelector } from "../../../redux/store";
import AppTable from "../../ui/AppTable";
import { DynamicTableDataForPatients } from "../../ui/helpers/table/TableBodyData";
import { tableHeadPatientsData } from "../../ui/helpers/table/TableHeadeData";
import { PatientSearchOptions } from "../../ui/helpers/table/TableSearchOptions";
import { useConvertedTableData } from "../../ui/helpers/table/useConvertedTableData";
import { tableConvertedData } from "./helpers/convertedPatientsData";
import FilterData from "./helpers/FilterData";

export const PatientData = {
  gender: "",
};

const DPatients = () => {
  const { token, userInfo } = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const [patientData, setPatientData] = useState([]);
  const [patientSearchData, setPatientSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchKey, setSearchKey] = useState();
  const [clearFilter, setClearFilter] = useState(false);
  const [patientFilter, setPatientFilter] = useState(PatientData);
  const limit = 10;

  const { tableData, pageCount, handlePageClick } = useConvertedTableData(
    patientData,
    limit
  );

  const getAllPatientsData = async () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    await axios
      .get(
        `${BASE_URL}${endPoint.patientsGet}/${userInfo?.doctor?.d_id}`,
        header
      )
      .then((res) => {
        if (res?.data?.message) {
          setPatientData(tableConvertedData(res?.data?.data));
          setPatientSearchData(tableConvertedData(res?.data?.data));
          setLoading(false);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const getAllPatientSearchedData = async () => {
    if (searchValue === "" || searchValue === 0) {
      getAllPatientsData();
    } else {
      const data =
        searchValue &&
        patientSearchData?.filter((col) => {
          if (isNaN(col[searchKey])) {
            return col[searchKey]
              ?.toString()
              .toLowerCase()
              .includes(searchValue?.toString().toLowerCase());
          } else {
            return col[searchKey] === searchValue;
          }
        });
      setPatientData(data);
    }
  };

  const handleFilter = async () => {
    if (patientFilter.gender === "") {
      getAllPatientsData();
    } else {
      const data =
        searchValue &&
        patientSearchData?.filter((col) => col?.pGender === patientFilter);
      setPatientData(data);
    }
    
  };

  useEffect(() => {
    if (patientData) {
      dispatch(singlePatientsPdfFnc(patientData));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData]);

  useEffect(() => {
    getAllPatientsData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, clearFilter]);

  useEffect(() => {
    getAllPatientSearchedData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div className="3xl:-mr-10 mx-0 md:mx-5 mt-16 xl:-mr-16 xl:mx-0 xl:mt-0">
      <AppTable
        titleClass="font-bold"
        tableTitle="Patients Information"
        titleIcon={<MdOutlinePersonalInjury />}
        search
        searchType
        searchOption={PatientSearchOptions}
        searchVal={(val) => setSearchValue(val)}
        searchKey={(key) => setSearchKey(key)}
        filter
        filterBox={
          <FilterData
            patientFilter={patientFilter}
            setPatientFilter={setPatientFilter}
            handleFilter={handleFilter}
            setClearFilter={setClearFilter}
            clearFilter={clearFilter}
          />
        }
        tableHeadData={tableHeadPatientsData}
        tableBodyData={tableData}
        dynamicTable={(colItem, headItem, index, shadow) =>
          DynamicTableDataForPatients(colItem, headItem, index, shadow)
        }
        pagination
        loader={loading}
        pageCount={pageCount}
        handlePageClick={handlePageClick}
      />
    </div>
  );
};

export default DPatients;
