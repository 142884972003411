import { Box, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiAddToQueue } from "react-icons/bi";
import { ToastContainer } from "react-toastify";
import { useSelector } from "../../../redux/store";
import AppButton from "../../ui/AppButton";
import AppModal from "../../ui/AppModal";
import PDFContainer from "./prescriptionPdf/PdfContainer";

const AddPrescription = ({ singleAppointment }) => {
  const [isShow, setIsShow] = useState(false);
  const [pdfScreen, setPdfScreen] = useState(false);
  const {  userInfo } = useSelector((state) => state.userInfo);
  const IniData = {
    apId: singleAppointment?.apId,
    dId: userInfo.doctor.d_id,
    pId: singleAppointment?.pId,
    chiefComplain: "",
    illnessHistory: "",
    extraFindings: "",
    diagnosis: "",
    treatment:"",
    advice: "",
    investigation: "",
    referrel: "",
    notes: "",
    signatureImg: userInfo.doctor.d_signature,
    fileTitle: "",
  };
  ////console.log(singleAppointment.pId)

  const [addPress, setAddPress] = useState(IniData);

  // const onChangeQuiz = ({ name, value }) => {
  //   setFormQuiz({ ...formQuiz, [name]: value });
  //   if (name === "question") {
  //     if (value === "") {
  //       setErrorsPres((prev) => {
  //         return {
  //           ...prev,
  //           [name]: "Question cann't be blank.",
  //         };
  //       });
  //     } else {
  //       setErrorsPres((prev) => {
  //         return {
  //           ...prev,
  //           [name]: null,
  //         };
  //       });
  //     }
  //   }
  //   if (name === "option1") {
  //     if (value === "") {
  //       setErrorsPres((prev) => {
  //         return {
  //           ...prev,
  //           [name]: "Option1 cann't be blank.",
  //         };
  //       });
  //     } else {
  //       setErrorsPres((prev) => {
  //         return {
  //           ...prev,
  //           [name]: null,
  //         };
  //       });
  //     }
  //   }
  //   if (name === "option2") {
  //     if (value === "") {
  //       setErrorsPres((prev) => {
  //         return {
  //           ...prev,
  //           [name]: "Option2 cann't be blank.",
  //         };
  //       });
  //     } else {
  //       setErrorsPres((prev) => {
  //         return {
  //           ...prev,
  //           [name]: null,
  //         };
  //       });
  //     }
  //   }
  //   if (name === "option3") {
  //     if (value === "") {
  //       setErrorsPres((prev) => {
  //         return {
  //           ...prev,
  //           [name]: "Option3 cann't be blank.",
  //         };
  //       });
  //     } else {
  //       setErrorsPres((prev) => {
  //         return {
  //           ...prev,
  //           [name]: null,
  //         };
  //       });
  //     }
  //   }
  //   if (name === "option4") {
  //     if (value === "") {
  //       setErrorsPres((prev) => {
  //         return {
  //           ...prev,
  //           [name]: "Option4 cann't be blank.",
  //         };
  //       });
  //     } else {
  //       setErrorsPres((prev) => {
  //         return {
  //           ...prev,
  //           [name]: null,
  //         };
  //       });
  //     }
  //   }
  //   if (name === "currectAnswer") {
  //     if (value === "") {
  //       setErrorsPres((prev) => {
  //         return {
  //           ...prev,
  //           [name]: "Currect Answer cann't be blank.",
  //         };
  //       });
  //     } else {
  //       setErrorsPres((prev) => {
  //         return {
  //           ...prev,
  //           [name]: null,
  //         };
  //       });
  //     }
  //   }
  //   if (name === "point") {
  //     if (value === "" || value < 0) {
  //       setErrorsPres((prev) => {
  //         return {
  //           ...prev,
  //           [name]: "Point cann't be blank or Negative.",
  //         };
  //       });
  //     } else {
  //       setErrorsPres((prev) => {
  //         return {
  //           ...prev,
  //           [name]: null,
  //         };
  //       });
  //     }
  //   }
  // };

  // //console.log(userInfo.doctor.d_id);
  // //console.log(addPress);

  // const addPrescription = async () => {
  //   const body = addPress;
  //   const header = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };
  //   await axios
  //     .post(`${BASE_URL}${endPoint.prescription}`, body, header)
  //     .then((res) => {
  //       setIsShow(false);
  //       notify(res?.data?.message, "success")
  //       setAddPress(IniData)
  //     })
  //     .catch((err) => {
  //       //console.log(err.message);
  //     });
  // };

  useEffect(() => {
    if (singleAppointment) {
      setAddPress({
        ...addPress,
        apId: singleAppointment?.apId,
        dId: userInfo?.doctor?.d_id,
        pId: singleAppointment?.pId,
        appointment: singleAppointment,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleAppointment]);

  return (
    <div>
      <ToastContainer />
      <AppModal
        isShowModal={isShow}
        callback={(item) => setIsShow(item)}
        modalTitle="Add Prescription"
        modalTitleClass="text-xl"
        modalWidth="min-w-[60%] w-full"
        modalBtn={
          <button className="btn flex items-center border-none hover:bg-white hover:text-orange-600 bg-white rounded-3xl text-sm text-orange-600">
            <BiAddToQueue />
            <span className="ml-2">Add Prescription</span>
          </button>
        }
      >
        {isShow && (
          <>
           <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="chiefComplain"
                label={
                  addPress?.chiefComplain
                    ? "Chief Complain"
                    : "Chief Complain *"
                }
                variant="outlined"
                fullWidth
                type="chiefComplain"
                size="small"
                multiline
                rows={3}
                value={addPress?.chiefComplain || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    chiefComplain: e.target.value,
                  });
                }}
              />
            </Grid>
         
            <Grid item xs={12} sm={6}>
              <TextField
                id="illnessHistory"
                label={
                  addPress?.illnessHistory
                    ? "Illness History"
                    : "Illness History *"
                }
                variant="outlined"
                fullWidth
                type="illnessHistory"
                size="small"
                multiline
                rows={3}
                value={addPress?.illnessHistory || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    illnessHistory: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="extraFindings"
                label={
                  addPress?.extraFindings
                    ? "Extra Findings"
                    : "Extra Findings *"
                }
                variant="outlined"
                fullWidth
                type="extraFindings"
                size="small"
                multiline
                rows={3}
                value={addPress?.extraFindings || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    extraFindings: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="diagnosis"
                label={addPress?.diagnosis ? "Diagnosis" : "Diagnosis *"}
                variant="outlined"
                fullWidth
                type="diagnosis"
                size="small"
                multiline
                rows={3}
                value={addPress?.diagnosis || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    diagnosis: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="treatment"
                label={addPress?.treatment ? "Treatment" : "Treatment *"}
                variant="outlined"
                fullWidth
                size="small"
                multiline
                rows={3}
                value={addPress?.treatment || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    treatment: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="advice"
                label={addPress?.advice ? "Advice" : "Advice *"}
                variant="outlined"
                fullWidth
                type="advice"
                size="small"
                multiline
                rows={3}
                value={addPress?.advice || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    advice: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="investigation"
                label={
                  addPress?.investigation ? "Investigation" : "Investigation *"
                }
                variant="outlined"
                fullWidth
                type="investigation"
                size="small"
                multiline
                rows={3}
                value={addPress?.investigation || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    investigation: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="referrel"
                label={addPress?.referrel ? "Referrel" : "Referrel *"}
                variant="outlined"
                fullWidth
                type="referrel"
                size="small"
                multiline
                rows={3}
                value={addPress?.referrel || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    referrel: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="notes"
                label={addPress?.notes ? "Notes" : "Notes *"}
                variant="outlined"
                fullWidth
                type="notes"
                size="small"
                multiline
                rows={3}
                value={addPress?.notes || ""}
                onChange={(e) => {
                  setAddPress({
                    ...addPress,
                    notes: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>

        <AppModal
        isShowModal={pdfScreen}
        callback={(item) => setPdfScreen(item)}
        modalTitle="Prescription Pdf"
        modalTitleClass="text-xl"
        modalWidth="min-w-[60%] w-full"
        modalBtn={
          <AppButton
            variant="primary"
            size="md"
            classes="mt-5 mb-5"
            //onClick={()=> setPdfScreen(true)}
          >
            Submit
          </AppButton>
        }
      >
     {
      pdfScreen  &&
      <PDFContainer addPress={addPress} setIsShow={setIsShow} setAddPress={setAddPress} IniData={IniData} setPdfScreen={setPdfScreen} />
     } 
      
      </AppModal>

         
        </Box>
          </>
        )}
      </AppModal>
    </div>
  );
};

export default AddPrescription;
