import { Grid } from '@mui/material';
import React from 'react';
import { styles } from '../../../Common/Constant';
import Item from '../../../Common/Item';
import '../../../Common/pdfPageStyle.css';

const OthersInfo = ({proType,profile}) => {
  return (
    
      <div style={styles.innerContainer}>
        <div style={styles.sectionContainer}>
           <h3 style={styles.subHeader}>  Service Information </h3>
           <Grid container>
               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={"No of Students Full payment"} value={profile.ins_fp_s_no} /> 
               </Grid>
               
               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={"Rate per head student Full payment"} value={`${profile.ins_fp_s_rate} ৳ ` } /> 
               </Grid>
               
               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={"Full Payment Amount (Students)"} value={`${profile.ins_fp_s_amount} ৳ `} /> 
               </Grid>

               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={"No of Teachers Full payment"} value={profile.ins_fp_t_no} /> 
               </Grid>
               
               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={"Rate per head student Full payment"} value={`${profile.ins_fp_t_rate} ৳ `} /> 
               </Grid>
               
               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={"Full Payment Amount (Teachers) "} value={`${profile.ins_fp_t_amount} ৳ `} /> 
               </Grid>

               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={"No of Student getting discount"} value={profile.ins_ds_s_no} /> 
               </Grid>
               
               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={"No of Teacher get discount"} value={profile.ins_ds_t_no} /> 
               </Grid>
               
               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={"Discount Rate "} value={`${profile.ins_ds_rate} %`} /> 
               </Grid>

               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={"Service Type"} value={profile.ins_service_type} /> 
               </Grid>
               
               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={"Total Discount Amount"} value={`${profile.ins_ds_amount} ৳ `} /> 
               </Grid>
               
               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={"No of Student getting Free Service"} value={profile.ins_fs_s_no} /> 
               </Grid>

               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={"No of Teacher getting Free Service"} value={profile.ins_fs_t_no} /> 
               </Grid>

               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={"Total Patients "} value={profile.ins_sm_total_patients} /> 
               </Grid>

               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={"Total Amounts"} value={profile.ins_sm_total_amount} /> 
               </Grid>


               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={" Per Head "} value={`${profile.ins_sm_per_head} ৳`} /> 
               </Grid>

               <Grid item xs={4} style={styles.itemContainer} >
               <Item tag={"Service Status"} value={profile.ins_service_status} /> 
               </Grid>

  
         </Grid>

        </div>

     

    </div>
  );
};

export default OthersInfo