import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppTable from "../../../ui/AppTable";
import { DynamicTableDataForClasses } from "../../../ui/helpers/table/TableBodyData";
import { tableHeadClassData } from "../../../ui/helpers/table/TableHeadeData";
import { useConvertedTableData } from "../../../ui/helpers/table/useConvertedTableData";
import { tableConvertedData } from "../helpers/convertedData";

const ClassesTable = ({ authorityInfo }) => {
  const [classData, setClassData] = useState([]);
  const limit = 4;

  const { tableData, pageCount, handlePageClick } = useConvertedTableData(
    classData,
    limit
  );

  useEffect(() => {
    setClassData(tableConvertedData(authorityInfo?.classes));
  }, [authorityInfo]);

  return (

      <AppTable
        tableTitle="Classes"
        tableHeadData={tableHeadClassData}
        tableBodyData={tableData}
        dynamicTable={(colItem, headItem, index, shadow) =>
          DynamicTableDataForClasses(colItem, headItem, index, shadow)
        }
        pagination={true}
        pageCount={pageCount}
        handlePageClick={handlePageClick}
      />
 
  );
};

export default ClassesTable;
