const userTypes = {
  schoolHealth: "schoolHealth",
  telemedicine: "telemedicine",
  olympiad: "olympiad",
  hijama: "hijama",
  superAdmin: "superAdmin",
  schoolAuthority: "schoolAuthority",
  doctor: "doctor",
  appointment: "appointment",
  teacher:"teacher",
};

const searchTypes = {
  schoolHealth: "schoolHealth",
  telemedicine: "telemedicine",
  olympiad: "olympiad",
  hijama: "hijama",
  superAdmin: "superAdmin",
  schoolAuthority: "schoolAuthority",
  doctor: "doctor",
  appointment: "appointment",
  teacher:"teacher",
};

const pdfTypes = {
  schoolHealth: "schoolHealth",
  schoolHealthList: "schoolHealthList",
  telemedicine: "telemedicine",
  telemedicineList: "telemedicineList",
  olympiad: "olympiad",
  olympiadList: "olympiadList",
  hijama: "hijama",
  hijamaList: "hijamaList",
  schoolAuthority: "schoolAuthority",
  schoolAuthorityList: "schoolAuthorityList",
  doctor: "doctor",
  doctorList: "doctorList",
  appointment: "appointment",
  appointmentList: "appointmentList",
  teacher:"teacher",
  teacherList:"teacherList",
};

const defaultDoctorImage = ".well-known/default_doctor_img.jpg"

module.exports = { userTypes, searchTypes,pdfTypes,defaultDoctorImage };