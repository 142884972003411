import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineLink, AiOutlineSafetyCertificate } from "react-icons/ai";
import { BiIdCard } from "react-icons/bi";
import { BsCalendar2Date, BsCalendarCheck } from "react-icons/bs";
import { GrStatusInfo } from "react-icons/gr";
import { IoIdCardOutline } from "react-icons/io5";
import {
  BASE_URL,
  endPoint,
} from "../../../../../constants/ApiConstant/routeConstant";
import { useToken } from "../../../../../services/useAuth";
import { notify } from "../../../../ui/helpers/ToastMsg";

const BasicInfo = ({ singleInstitution }) => {
  const {
    userPhoneId,
    insType,
    insName,
    insEstYear,
    insRegNo,
    insPrinciple,
    insTotalStudents,
  } = singleInstitution;

  const [serviceStatus, setServiceStatus] = useState("");
  const [, setLoading] = useState("");

  useEffect(() => {
    setServiceStatus(singleInstitution.insServiceStatus);
  }, [singleInstitution]);
  const token = useToken();

  const updateServiceStatus = () => {
    setLoading(true);
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const bodyData = {
      updateTo: serviceStatus,
    };
    //console.log(bodyData);
    axios
      .put(
        `${BASE_URL}${endPoint.serviceUpdate}/${singleInstitution?.insId}`,
        bodyData,
        header
      )
      .then((res) => {
        setLoading(false);

        if (res?.data?.success) {
          ////console.log(res.data?.data);
          notify("Service Status Updated Successfully!", "success");
        }
      })
      .catch((err) => {
        setLoading(false);

        // //console.log(err);
        notify(err.response.data?.message, "warning");
      });
  };

  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiIdCard style={{ marginRight: 4 }} />
            Intitute Type
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insType}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoIdCardOutline style={{ marginRight: 4 }} />
            Institute Name
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insName}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineSafetyCertificate style={{ marginRight: 4 }} />
            Established Year
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insEstYear}
          </Typography>
        </Box>

        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <GrStatusInfo style={{ marginRight: 4 }} />
            Registration Number
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insRegNo}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex",flexWrap: "wrap", my: 5 }}>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
              mt: 3,
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Name Of Principal
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insPrinciple}
          </Typography>
        </Box>

        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
              mt: 3,
            }}
          >
            <BsCalendar2Date style={{ marginRight: 4 }} />
            Total Students
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {insTotalStudents}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineLink style={{ marginRight: 4 }} />
            Phone Number
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {userPhoneId}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", my: 5 }}>
        <Box sx={{ width: {md:"50%", xs:'100%'} }}>
          <Autocomplete
            disablePortal
            id="institueType"
            options={["Not Started", "Ongoing", "Finished"]}
            fullWidth
            size="small"
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            value={serviceStatus || null}
            onChange={(event, newValue) => {
              setServiceStatus(newValue);
            }}
            renderInput={(params) => (
              <TextField
                error={false}
                {...params}
                label={"Update Service Status"}
              />
            )}
          />
          <Box mt={2}>
            <Button
              variant="contained"
              color="warning"
              onClick={updateServiceStatus}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BasicInfo;
