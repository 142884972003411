import { Box, Grid, Stack, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL, endPoint } from "../../../../constants/ApiConstant/routeConstant";
import { useToken } from "../../../../services/useAuth";
import { notify } from "../../../ui/helpers/ToastMsg";

const Add = () => {
  const [formVideo, setFromVideo] = useState({});
  const [errorsVideo, setErrorsVideo] = useState({});
  const navigate = useNavigate();

  const [videos, setVideos] = useState({});

  const token = useToken();

  const onChangeVideo = ({ name, value }) => {
    setFromVideo({ ...formVideo, [name]: value });
    if (name === "videoTitle") {
      if (value === "") {
        setErrorsVideo((prev) => {
          return {
            ...prev,
            [name]: "video Title cann't be blank.",
          };
        });
      } else {
        setErrorsVideo((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "videoLink") {
      if (value === "") {
        setErrorsVideo((prev) => {
          return {
            ...prev,
            [name]: "Video Link cann't be blank.",
          };
        });
      } else {
        setErrorsVideo((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "videoDuration") {

      if (value === null) {
        setErrorsVideo((prev) => {
          return {
            ...prev,
            [name]: "video Duration cann't be blank.",
          };
        });
      } else {
        setErrorsVideo((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
  };

  const onSubmitVideo = () => {
    if (!formVideo.videoTitle) {
      setErrorsVideo((prev) => {
        return {
          ...prev,
          videoTitle: "Please add a Video Title",
        };
      });
    }
    if (!formVideo.videoLink) {
      setErrorsVideo((prev) => {
        return {
          ...prev,
          videoLink: "Please add a Video Link",
        };
      });
    }
    if (!formVideo.videoDuration) {
      setErrorsVideo((prev) => {
        return {
          ...prev,
          videoDuration: "Please add a Video Duration",
        };
      });
    }
    if (
      formVideo.videoTitle &&
      formVideo.videoLink &&
      formVideo.videoDuration &&
      Object.values(errorsVideo).every((item) => !item)
    ) {
      setVideos({ ...formVideo })
      onSubmit()
    }
  };

  //console.log(videos);

  const onSubmit = () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const bodyData = {
   
        title:formVideo?.videoTitle,
        length:formVideo?.videoDuration,
        link:formVideo?.videoLink
   
    };

    axios
      .post(`${BASE_URL}${endPoint.video}`, bodyData, header)
      .then((res) => {
        if (res?.data?.success) {
          //console.log(res.data?.data);
          notify("Video Added Successfully!", "success");
          navigate("/hijama/Videos/view");
        }
      })
      .catch((err) => {
        //console.log(err);
        notify(err.response.data?.message, "warning");
      });
  };

  return (
    <div className=" shadow-md rounded-xl w-full bg-white mt-10">
      <div className=" pb-5 md:px-14 px-5">
        <h1 className=" text-2xl pt-10 text-center font-bold uppercase">
          Add Video
        </h1>

        <Box sx={{ flexGrow: 1, mt: 7 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="videoTitle"
                variant="outlined"
                fullWidth
                size="small"
                value={formVideo?.videoTitle || ""}
                label={formVideo?.videoTitle ? "Video Title" : "Video Title *"}
                onChange={(e) => {
                  onChangeVideo({
                    name: "videoTitle",
                    value: e.target.value,
                  });
                }}
                error={errorsVideo?.videoTitle ? true : false}
                helperText={errorsVideo?.videoTitle}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {" "}
              <TextField
                id="videoLink"
                variant="outlined"
                fullWidth
                size="small"
                value={formVideo?.videoLink || ""}
                label={formVideo?.videoLink ? "Video Link" : "Video Link *"}
                onChange={(e) => {
                  onChangeVideo({
                    name: "videoLink",
                    value: e.target.value,
                  });
                }}
                error={errorsVideo?.videoLink ? true:false}
                helperText={errorsVideo?.videoLink}
              />
            </Grid>{" "}
            <Grid item xs={12}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <TimePicker
              id="videoDuration"
                label={
                      formVideo?.videoDuration
                        ? "Video Duration"
                        : "Video Duration*"
                    }
                ampmInClock
                views={['minutes', 'seconds']}
                inputFormat="mm:ss"
                mask="__:__"
                value={formVideo?.videoDuration || ""}
                onChange={(newValue) => {
                  onChangeVideo({
                    name: "videoDuration",
                    value: newValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={errorsVideo?.videoDuration ?true:false}
                    helperText={errorsVideo?.videoDuration}
                  />
                )} 
            
              />
            </Stack>
          </LocalizationProvider>
          
              {
              //   <LocalizationProvider dateAdapter={AdapterDayjs}>
              //   <Stack spacing={3}>
              //     <TimePicker
              //       id="videoDuration"
              //       label={
              //         formVideo?.videoDuration
              //           ? "Video Duration"
              //           : "Video Duration*"
              //       }
              //       type="time"
              //       value={formVideo?.videoDuration || ""}
              //       onChange={(newValue) => {
              //         onChangeVideo({
              //           name: "videoDuration",
              //           value: newValue,
              //         });
              //       }}
              //       renderInput={(params) => (
              //         <TextField
              //           {...params}
              //           size="small"
              //           error={errorsVideo?.videoDuration ?true:false}
              //           helperText={errorsVideo?.videoDuration}
              //         />
              //       )}
              //     />
              //   </Stack>
              // </LocalizationProvider>
              }
            </Grid>
          </Grid>
        </Box>

        <div className="flex justify-center mt-5">
          <button className="btn bg-blue-600 hover:bg-blue-600  border-none  font-bold mt-5 px-10 rounded-2xl  "
          onClick={()=>onSubmitVideo()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Add;
