import { AiOutlineInfoCircle, AiOutlineMedicineBox } from "react-icons/ai";
import { BsCardChecklist, BsPeople, BsViewStacked } from "react-icons/bs";
import { FaAward, FaHandHoldingMedical, FaPrescription, FaSchool, FaUserMd, FaUsers, FaUserTie } from "react-icons/fa";
import { FiVideo } from "react-icons/fi";
import { GiHealing } from "react-icons/gi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { ImStatsBars } from "react-icons/im";
import { IoNotifications } from "react-icons/io5";
import {
  MdAdminPanelSettings, MdOutlineAddComment, MdOutlineEmojiEvents, MdOutlinePersonalInjury, MdOutlineQuiz
} from "react-icons/md";
import { RiUserReceived2Line } from "react-icons/ri";
import { TbLayoutDashboard } from "react-icons/tb";

export const SuperAdminDashboard = [
  {
    title: "Dashboard",
    icon: <TbLayoutDashboard />,
    selectedBtn: "dash",
    url: "/dashboard",
    submenu: [],
  },

  {
    title: "Health Care",
    icon: <GiHealing />,
    selectedBtn: "health",
    url: "/healthCare",
    submenu: [
      {
        title: "Institutions",
        submenu: [
          {
            title: "Add",
            url: "/healthcare/institutions/add",
            icon: <MdOutlineAddComment />,
          },
          {
            title: "View",
            url: "/healthcare/institutions/view",
            icon: <BsViewStacked />,
          },
        ],
        icon: <FaSchool />,
      },
      {
        title: "Students",
        submenu: [
          {
            title: "Add",
            url: "/healthcare/students/add",
            icon: <MdOutlineAddComment />,
          },
          {
            title: "View",
            url: "/healthcare/students/view",
            icon: <BsViewStacked />,
          },
        ],
        icon: <BsPeople />,
      },
      {
        title: "Teachers",
        submenu: [
          {
            title: "Add",
            url: "/healthcare/teachers/add",
            icon: <MdOutlineAddComment />,
          },
          {
            title: "View",
            url: "/healthcare/teachers/view",
            icon: <BsViewStacked />,
          },
        ],
        icon: <BsPeople />,
      },
    ],
  },
  {
    title: "Telemedicine",

    icon: <AiOutlineMedicineBox />,
    selectedBtn: "medicine",
    url: "/telemedicine",
    submenu: [
      {
        title: "Doctors",
        submenu: [
          {
            title: "Add",
            url: "/telemedicine/doctors/add",
            icon: <MdOutlineAddComment />,
          },

          {
            title: "View",
            url: "/telemedicine/doctors/view",
            icon: <BsViewStacked />,
          },
        ],
        icon: <FaUserMd />,
      },
      {
        title: "Patients",
        url: "/telemedicine/patients",
        icon: <MdOutlinePersonalInjury />,
      },
      {
        title: "Appointments",
        url: "/telemedicine/appointments",
        icon: <BsCardChecklist />,
      },
    ],
  },
  {
    title: "Olympiad",
    icon: <FaAward />,
    selectedBtn: "olym",
    url: "/olympiad",
    submenu: [
      {
        title: "Events",
        submenu: [
          {
            title: "Add",
            url: "/olympiad/events/add",
            icon: <MdOutlineAddComment />,
          },

          {
            title: "View",
            url: "/olympiad/events/view",
            icon: <BsViewStacked />,
          },
        ],
        icon: <MdOutlineEmojiEvents />,
      },

      {
        title: "Notices",
        submenu: [
          {
            title: "Add",
            url: "/olympiad/notices/add",
            icon: <MdOutlineAddComment />,
          },
          {
            title: "View",
            url: "/olympiad/notices/view",
            icon: <BsViewStacked />,
          },
        ],
        icon: <AiOutlineInfoCircle />,
      },
      {
        title: "Registered Students",
        url: "/olympiad/registered-students",
        icon: <RiUserReceived2Line />,
      },
    ],
  },
  {
    title: "Hijama",
    icon: <FaHandHoldingMedical />,
    selectedBtn: "hijama",
    url: "/hijama",
    submenu: [
      {
        title: "Videos",
        submenu: [
          {
            title: "Add",
            url: "/hijama/Videos/add",
            icon: <MdOutlineAddComment />,
          },
          {
            title: "View",
            url: "/hijama/Videos/view",
            icon: <BsViewStacked />,
          },
        ],
        icon: <FiVideo />,
      },
      {
        title: "Quiz",
        submenu: [
          {
            title: "Add",
            url: "/hijama/quiz/add",
            icon: <MdOutlineAddComment />,
          },
          {
            title: "View",
            url: "/hijama/quiz/view",
            icon: <BsViewStacked />,
          },
        ],
        icon: <MdOutlineQuiz />,
      },
      {
        title: "Users",
        url: "/hijama/users",
        icon: <HiOutlineUserGroup />,
      },
    ],
  },
  

  {
    title: "Admin",
    icon: <MdAdminPanelSettings />,
    selectedBtn: "sadmin",
    url: "/superAdmin/view",
    submenu: [
      {
        title: "Add",
        url: "/superAdmin/add",
        icon: <MdOutlineAddComment />,
      },
      {
        title: "View",
        url: "/superAdmin/view",
        icon: <BsViewStacked />,
      },
    ],
  },
  {
    title: "Notifications",
    icon: <IoNotifications />,
    selectedBtn: "noti",
    url: "/notifications",
    submenu: [],
  },
];

export const SchoolAuthorityDashboard = [
  {
    title: "Dashboard",
    icon: <TbLayoutDashboard />,
    selectedBtn: "dash",
    url: "/dashboard",
    submenu: [],
  },
  {
    title: "Profile",
    icon: <FaUserTie />,
    selectedBtn: "profile",
    url: "/school-authority-profile",
    submenu: [],
  },
  {
    title: "Students",
    icon: <FaUsers />,
    selectedBtn: "students",
    url: "/students",
    submenu: [],
  },
  {
    title: "Teachers",
    icon: <FaUsers />,
    selectedBtn: "teachers",
    url: "/teachers",
    submenu: [],
  },

  {
    title: "Statistics",
    icon: <ImStatsBars />,
    selectedBtn: "statistics",
    url: "/statistics",
    submenu: [],
  },
  {
    title: "Notifications",
    icon: <IoNotifications />,
    selectedBtn: "noti",
    url: "/notifications",
    submenu: [],
  },
];

export const DoctorDashboard = [
  {
    title: "Dashboard",
    icon: <TbLayoutDashboard />,
    selectedBtn: "dash",
    url: "/dashboard",
    submenu: [],
  },
  {
    title: "Profile",
    icon: <FaUserTie />,
    selectedBtn: "profile",
    url: "/doctor-profile",
    submenu: [],
  },
  {
    title: "Patients",
    icon:  <MdOutlinePersonalInjury />,
    selectedBtn: "patients",
    url: "/patients",
    submenu: [],
  },
  {
    title: "Appointments",
    icon: <BsCardChecklist />,
    selectedBtn: "appointments",
    url: "/appointments",
    submenu: [],
  },
  {
    title: "Prescriptions",
    icon: <FaPrescription />,
    selectedBtn: "prescriptions",
    url: "/prescriptions",
    submenu: [],
  },
  {
    title: "Notifications",
    icon: <IoNotifications />,
    selectedBtn: "noti",
    url: "/notifications",
    submenu: [],
  },
];

