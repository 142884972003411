import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { BASE_URL } from "../../../../../constants/ApiConstant/routeConstant";
import { useToken } from "../../../../../services/useAuth";
import { checkFile } from "../../../../../utils/Validation/formValidation";
import { speciality } from "../../helper/options";

const Others = ({
  setErrorsOthers,
  errorsOthers,
  onChangeOthers,
  formOthers,
}) => {
  const [loading, setLoading] = useState();
  const token = useToken();
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const pickSingnaturePicAndUpload = async (e) => {
    if (checkFile(e.target.files, "image")) {
      //console.log(e.target.files);

      //upload to server start

      const file = e.target.files[0];

      const formData = new FormData();
      formData.append("image", file);
      try {
        setLoading(true);
        const url = `${BASE_URL}/api/upload/image`;
        const res = await axios.post(url, formData, headers);
        if (res.data.success) {
          onChangeOthers({
            name: "signatureImg",
            value: res.data.data.path,
          });

          setErrorsOthers((prev) => {
            return {
              ...prev,
              signatureImg: null,
            };
          });
        }
        setLoading(false);
      } catch (e) {
        //console.log(e);
      }

      //upload to server end
    } else {
      //setError
      setErrorsOthers((prev) => {
        return {
          ...prev,
          signatureImg:
            "Image should be jpg, jpeg, png, svg,gif & size should be less that 2MB",
        };
      });
    }
  };

  return (
    <div>
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                id="speciality"
                options={speciality}
                fullWidth
                size="small"
                isOptionEqualToValue={(options, value) =>
                  options.value === value.value
                }
                value={formOthers?.speciality || ""}
                onChange={(event, newValue) => {
                  onChangeOthers({
                    name: "speciality",
                    value: newValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errorsOthers?.speciality ? true : false}
                    helperText={errorsOthers?.speciality}
                    label={formOthers?.speciality ? "Speciality" : "Speciality"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {" "}
              <TextField
                id="charge"
                variant="outlined"
                fullWidth
                size="small"
                error={errorsOthers?.charge ? true : false}
                value={formOthers?.charge || ""}
                helperText={errorsOthers?.charge}
                label={formOthers?.charge ? "Charge" : "Charge *"}
                onChange={(e) => {
                  onChangeOthers({
                    name: "charge",
                    value: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="flex overflow-x-hidden">
                <Grid item xs={6}>
                  <p className="">Signature Image </p>
                </Grid>
                <Grid item xs={6}>
                  <input
                    type="file"
                    name="image"
                    onChange={pickSingnaturePicAndUpload}
                  />
                </Grid>
              </div>
              {formOthers?.signatureImg && (
                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall text-green-700 MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">
                  Uploaded
                </p>
              )}
              {errorsOthers?.signatureImg && (
                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall text-red-700 MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">
                  {errorsOthers?.signatureImg}
                </p>
              )}
              {loading && (
                <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall text-yellow-700 MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">
                  Uploading....
                </p>
              )}
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default Others;
