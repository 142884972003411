import { Grid } from '@mui/material';
import React from 'react';
import { BASE_URL } from '../../../../constants/ApiConstant/routeConstant';
import { styles } from '../../Common/Constant';
import Item from '../../Common/Item';


const BasicInfo = ({profile}) => {
  return (
    <div style={styles.innerContainer}>
    
    <div style={styles.sectionContainer}>
      <h3 style={styles.subHeader}>  Patient Information </h3>
<Grid container>
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Name"} value={profile.p_name} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Phone 1"} value={profile.user_phone_id} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Patient ID"} value={profile.p_id} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Date Of Birth"} value={profile.p_dob} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Gender"} value={profile.p_gender} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Profession"} value={profile.p_profession} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Address"} value={profile.p_address}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Phone 2"} value={profile.p_phone}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Email"} value={profile.p_email} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"NID"} value={profile.p_nid} /> 
  </Grid>

 {profile.p_birth_cft && (
  <Grid item xs={4} style={styles.itemContainer} >
  <img src={`${BASE_URL}/${profile.p_birth_cft}`} style={{width:'80%',height:'80%'}} /> 
  </Grid>
 )}
  
  
</Grid>

       
       

</div>

   </div>
  )
}

export default BasicInfo
