import React from 'react'

import Prescription from './Details/AddPrescription'
const EditPrescription = () => {
  return (
    <div>
      <Prescription />
    </div>
  );
}

export default EditPrescription