
import { Font } from '@react-pdf/renderer';
import BanglaBlack from './fonts/NotoSerifBengali-Black.ttf';
import BanglaBlackExtraBold from './fonts/NotoSerifBengali-Bold.ttf';
import BanglaBlackExtraLight from './fonts/NotoSerifBengali-ExtraLight.ttf';
import BanglaBlackLight from './fonts/NotoSerifBengali-Light.ttf';
import BanglaBlackMedium from './fonts/NotoSerifBengali-Medium.ttf';
import BanglaBlackRegular from './fonts/NotoSerifBengali-Regular.ttf';
import BanglaBlackThin from './fonts/NotoSerifBengali-Thin.ttf';


Font.register({
  family:"Noto Serif Bengali",
  src:`https://fonts.googleapis.com/css2?family=Noto+Serif+Bengali&display=swap`
})
Font.register({
  family: 'bangla-black', 
  src: BanglaBlack,
});

Font.register({
  family: 'bangla-extra-bold', 
  src: BanglaBlackExtraBold,
});

Font.register({
  family: 'bangla-extra-light', 
  src: BanglaBlackExtraLight,
});

Font.register({
  family: 'bangla-light', 
  src: BanglaBlackLight,
});

Font.register({
  family: 'bangla-medium', 
  src: BanglaBlackMedium,
});

Font.register({
  family: 'bangla-regular', 
  src: BanglaBlackRegular,
});

Font.register({
  family: 'bangla-thin', 
  src: BanglaBlackThin,
});
export const styles = {

  page:{
   marginTop:5,
   fontFamily:'bangla-black'
  },

  MainContainer: {
    margin: 10,
    display:'flex',
    flexDirection:'column'
  },
  basicText:{
    fontSize:10,
    fontWeight:300,
    fontFamily:'bangla-medium'
  }
  ,
  basicTextRight:{
    fontSize:10,
    fontWeight:300,
    fontFamily:'bangla-medium',
    textAlign:'right'
  }
  ,
  docImage:{
   maxWidth:70,
   maxHeight:70,
   borderRadius:"50%",
   marginBottom:5
  },
  logoImage:{
    maxWidth:70,
    maxHeight:70,
    marginLeft:-8
   },
   textCenterHeading:{
    textAlign:'center',
    fontSize:12,
    fontWeight:700,
    fontFamily:'bangla-black'
   },
  section1: {
    display: "flex",
    flexDirection:'row',
    justifyContent: "space-between",
    backgroundColor: "#606060",
    borderRadius: 10,
    padding: 10,
    color:"white"
  },
  section1_right_img: {
    width: "100px",
    height: "100px"
  },
  sectionHeader:{
    fontSize:12,
    color:'white',
    backgroundColor:"#606060",
    padding:5,
    marginBottom:3,
    textAlign:'center',
    fontFamily:'bangla-black',
    borderRadius:5,
  },
  textContainer:{
    display:'flex',
    flexDirection:'column',
    marginRight:3,
    marginBottom:8
  },
  key:{
    fontSize:11,
    fontWeight:'bold',
    fontFamily:'bangla-black',
    marginRight:2
  },
  value:{
    fontSize:10,
    fontFamily:'bangla-regular',
    border:'1px dotted black',
    padding:5
  },
  section2: {
    display: "flex",
    flexDirection:'row',
    marginTop:10,
    marginBottom:10
  },
  section2_left: {
    marginRight: 10,
    flex:0.3,
    display:'flex',
    flexDirection:'column'
  },
  section2_left_part1:{
    marginBottom:5,
  },
  section2_left_h2: {
    textAlign: "center"
  },
  section2_right:{
    flex:0.7,
    display:'flex',
    flexDirection:'column'
  },
  input_field: {
    fontWeight: 400
  },
  section3: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "20px",
    position: "relative",
    flexDirection:'row'
  },
  section3_left_img: {
    width: 100,
  },
  section3_right_img: {
    width: 80,
  },
  
}