import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from './pdfCss';
const Body = ({addPress}) => {
  return (
    <View style={styles.section2} >
    <View  style={styles.section2_left} >
      <View  style={styles.section2_left_part1} >
        <Text style={styles.sectionHeader} >Patient Info</Text>
        <View style={styles.textContainer} >
        <Text style={styles.key} >ID:</Text>
        <Text style={styles.value} >{addPress?.pId}</Text>
        </View>

        <View style={styles.textContainer} >
        <Text style={styles.key} >Name:</Text>
        <Text style={styles.value} >{addPress?.appointment?.pName}</Text>
        </View>

        <View style={styles.textContainer} >
        <Text style={styles.key} >Address:</Text>
        <Text style={styles.value} >{addPress?.appointment?.pAddress}</Text>
        </View>
        
        <View style={styles.textContainer} >
        <Text style={styles.key} >Gender:</Text>
        <Text style={styles.value} >{addPress?.appointment?.pGender}</Text>
        </View>
      
      </View>

    <View  >
      <Text style={styles.sectionHeader} >Appointment Info</Text>
      <View style={styles.textContainer} >
      <Text style={styles.key} >Reason:</Text>
      <Text style={styles.value} >{addPress?.appointment?.apReason}</Text>
      </View>
    
      <View style={styles.textContainer} >
      <Text style={styles.key} >Blood Pressure:</Text>
      <Text style={styles.value} >{addPress?.appointment?.bloodPressure}</Text>
      </View>
    
      <View style={styles.textContainer} >
      <Text style={styles.key} >Height:</Text>
      <Text style={styles.value} >{addPress?.appointment?.height}</Text>
      </View>
    
      <View style={styles.textContainer} >
      <Text style={styles.key} >Weight:</Text>
      <Text style={styles.value} >{addPress?.appointment?.weight}</Text>
      </View>
    
      <View style={styles.textContainer} >
      <Text style={styles.key} >Additional Note:</Text>
      <Text style={styles.value} >{addPress?.appointment?.additionalNote}</Text>
      </View>
    
      <View style={styles.textContainer} >
      <Text style={styles.key} >Request Time::</Text>
      <Text style={styles.value} >{addPress?.appointment?.apReqTime}</Text>
      </View>
    
      <View style={styles.textContainer} >
      <Text style={styles.key} >Appointment Time:</Text>
      <Text style={styles.value} >{addPress?.appointment?.apTime}</Text>
      </View>
    
    
    </View>

    </View>
    <View  style={styles.section2_right}  >
    <Text style={styles.sectionHeader} >Prescription Info</Text>
        <View style={styles.textContainer} >
        <Text style={styles.key} >Chief Complain:</Text>
        <Text style={styles.value} >{addPress?.chiefComplain}</Text>
        </View>
        
        
        <View style={styles.textContainer} >
        <Text style={styles.key} >History Illness:</Text>
        <Text style={styles.value} >{addPress?.illnessHistory}</Text>
        </View>
        
        
        <View  style={styles.textContainer}>
        <Text style={styles.key} >Extra Findings:</Text>
        <Text style={styles.value} >{addPress?.extraFindings}</Text>
        </View>

              
        <View  style={styles.textContainer}>
        <Text style={styles.key} >Diagnosis:</Text>
        <Text style={styles.value} >{addPress?.diagnosis}</Text>
        </View>

              
        <View  style={styles.textContainer}>
        <Text style={styles.key} >Treatment:</Text>
        <Text style={styles.value} >{addPress?.treatment}</Text>
        </View>

        
        <View  style={styles.textContainer}>
        <Text style={styles.key} >Advice:</Text>
        <Text style={styles.value} >{addPress?.advice}</Text>
        </View>

        
        <View  style={styles.textContainer}>
        <Text style={styles.key} >Investigation:</Text>
        <Text style={styles.value} >{addPress?.investigation}</Text>
        </View>

        
        <View  style={styles.textContainer}>
        <Text style={styles.key} >Referral:</Text>
        <Text style={styles.value} >{addPress?.referrel}</Text>
        </View>

        
        <View  style={styles.textContainer}>
        <Text style={styles.key} >Notes:</Text>
        <Text style={styles.value} >{addPress?.notes}</Text>
        </View>
    </View>
  </View>
  )
}

export default Body