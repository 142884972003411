import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import SchoolIcon from "@mui/icons-material/School";
import { FormControl, Radio, RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useEffect, useState } from "react";
import { TbCurrencyTaka } from "react-icons/tb";
import { formateDate } from "../../../../../utils/Formatter/dateFormater";

export default function ServiceInfo({
  onChangeServiceInfo,
  errorsServiceInfo,
  formServiceInfo,
  setformServiceInfo
}) {
  const [studentTotalAmount, setStudentTotalAmount] = useState(0);
  const [teacherTotalAmount, setTeacherTotalAmount] = useState(0);
  const [discountTotalAmount, setDiscountTotalAmount] = useState(0);
  const [totalPatient, setTotalPatient] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [perHead, setPerHead] = useState(0);

  const updateTotalAmount = () => {
    let addedData = {}
    if (formServiceInfo.noOfStudents && formServiceInfo.ratePerHeadStudent) {
      setStudentTotalAmount(
        (
          formServiceInfo.noOfStudents * formServiceInfo.ratePerHeadStudent
        ).toFixed(2),
      );
      addedData = {...addedData,studentTotalAmount:(
        formServiceInfo.noOfStudents * formServiceInfo.ratePerHeadStudent
      ).toFixed(2)}
      // onChangeServiceInfo({
      //   name: "studentTotalAmount",
      //   value: (
      //     formServiceInfo.noOfStudents * formServiceInfo.ratePerHeadStudent
      //   ).toFixed(2),
      // });
      ////console.log(studentTotalAmount)
    }

    if (formServiceInfo.noOfTeachers && formServiceInfo.ratePerHeadTeacher) {
      setTeacherTotalAmount(
        (
          formServiceInfo.noOfTeachers * formServiceInfo.ratePerHeadTeacher
        ).toFixed(2),
      );
      
      addedData = {...addedData,teacherTotalAmount:(
        formServiceInfo.noOfTeachers * formServiceInfo.ratePerHeadTeacher
      ).toFixed(2)}

      // onChangeServiceInfo({
      //   name: "teacherTotalAmount",
      //   value: (
      //     formServiceInfo.noOfTeachers * formServiceInfo.ratePerHeadTeacher
      //   ).toFixed(2),
      // });
    }

    if (
      formServiceInfo.noOfStudentsReceivingDiscountedServices &&
      formServiceInfo.noOfTeachersReceivingDiscountedServices &&
      formServiceInfo.ratePerHeadTeacher &&
      formServiceInfo.ratePerHeadStudent &&
      formServiceInfo.rateOfTheDiscountServices
    ) {
      setDiscountTotalAmount(
        `${
          (
            formServiceInfo.noOfStudentsReceivingDiscountedServices *
            (formServiceInfo.ratePerHeadStudent *
              ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
          ).toFixed(2) *
            1 +
          (
            formServiceInfo.noOfTeachersReceivingDiscountedServices *
            (formServiceInfo.ratePerHeadTeacher *
              ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
          ).toFixed(2) *
            1
        }`,
      );
      addedData = {...addedData,discountTotalAmount:`${(
        formServiceInfo.noOfStudentsReceivingDiscountedServices *
        (formServiceInfo.ratePerHeadStudent *
          ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
      ).toFixed(2) *
        1 +
      (
        formServiceInfo.noOfTeachersReceivingDiscountedServices *
        (formServiceInfo.ratePerHeadTeacher *
          ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
      ).toFixed(2) *
        1}`
            }

      // onChangeServiceInfo({
      //   name: "discountTotalAmount",
      //   value: `${
      //     (
      //       formServiceInfo.noOfStudentsReceivingDiscountedServices *
      //       (formServiceInfo.ratePerHeadStudent *
      //         ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
      //     ).toFixed(2) *
      //       1 +
      //     (
      //       formServiceInfo.noOfTeachersReceivingDiscountedServices *
      //       (formServiceInfo.ratePerHeadTeacher *
      //         ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
      //     ).toFixed(2) *
      //       1
      //   }`,
      // });
    }

    if (
      formServiceInfo.noOfStudentsReceivingDiscountedServices &&
      formServiceInfo.noOfTeachersReceivingDiscountedServices &&
      formServiceInfo.noOfStudents &&
      formServiceInfo.noOfTeachers &&
      formServiceInfo.noOfStudentsReceivingFreeServices &&
      formServiceInfo.noOfTeachersReceivingFreeServices
    ) {
      setTotalPatient(
        `${
          formServiceInfo.noOfStudentsReceivingDiscountedServices * 1 +
          formServiceInfo.noOfTeachersReceivingDiscountedServices * 1 +
          formServiceInfo.noOfStudents * 1 +
          formServiceInfo.noOfTeachers * 1 +
          formServiceInfo.noOfStudentsReceivingFreeServices * 1 +
          formServiceInfo.noOfTeachersReceivingFreeServices * 1
        }`,
      );

      addedData = {...addedData,totalPatient:`${formServiceInfo.noOfStudentsReceivingDiscountedServices * 1 +
        formServiceInfo.noOfTeachersReceivingDiscountedServices * 1 +
        formServiceInfo.noOfStudents * 1 +
        formServiceInfo.noOfTeachers * 1 +
        formServiceInfo.noOfStudentsReceivingFreeServices * 1 +
        formServiceInfo.noOfTeachersReceivingFreeServices * 1}`
      }

      // onChangeServiceInfo({
      //   name: "totalPatient",
      //   value: `${ formServiceInfo.noOfStudentsReceivingDiscountedServices * 1 +
      //     formServiceInfo.noOfTeachersReceivingDiscountedServices * 1 +
      //     formServiceInfo.noOfStudents * 1 +
      //     formServiceInfo.noOfTeachers * 1 +
      //     formServiceInfo.noOfStudentsReceivingFreeServices * 1 +
      //     formServiceInfo.noOfTeachersReceivingFreeServices * 1}`,
      // });
    }

    if (
      formServiceInfo.noOfStudentsReceivingDiscountedServices &&
      formServiceInfo.noOfTeachersReceivingDiscountedServices &&
      formServiceInfo.ratePerHeadTeacher &&
      formServiceInfo.ratePerHeadStudent &&
      formServiceInfo.rateOfTheDiscountServices &&
      formServiceInfo.noOfTeachers &&
      formServiceInfo.noOfStudents
    ) {
      setTotalAmount(
        `${
          (
            formServiceInfo.noOfStudentsReceivingDiscountedServices *
            (formServiceInfo.ratePerHeadStudent *
              ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
          ).toFixed(2) *
            1 +
          (
            formServiceInfo.noOfTeachersReceivingDiscountedServices *
            (formServiceInfo.ratePerHeadTeacher *
              ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
          ).toFixed(2) *
            1 +
          (
            formServiceInfo.noOfTeachers * formServiceInfo.ratePerHeadTeacher
          ).toFixed(2) *
            1 +
          (
            formServiceInfo.noOfStudents * formServiceInfo.ratePerHeadStudent
          ).toFixed(2) *
            1
        }`,
      );
      addedData = {...addedData,totalAmount:`${
        (
          formServiceInfo.noOfStudentsReceivingDiscountedServices *
          (formServiceInfo.ratePerHeadStudent *
            ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
        ).toFixed(2) *
          1 +
        (
          formServiceInfo.noOfTeachersReceivingDiscountedServices *
          (formServiceInfo.ratePerHeadTeacher *
            ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
        ).toFixed(2) *
          1 +
        (
          formServiceInfo.noOfTeachers * formServiceInfo.ratePerHeadTeacher
        ).toFixed(2) *
          1 +
        (
          formServiceInfo.noOfStudents * formServiceInfo.ratePerHeadStudent
        ).toFixed(2) *
          1
      }`
      }

      // onChangeServiceInfo({
      //   name: "totalAmount",
      //   value: `${
      //     (
      //       formServiceInfo.noOfStudentsReceivingDiscountedServices *
      //       (formServiceInfo.ratePerHeadStudent *
      //         ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
      //     ).toFixed(2) *
      //       1 +
      //     (
      //       formServiceInfo.noOfTeachersReceivingDiscountedServices *
      //       (formServiceInfo.ratePerHeadTeacher *
      //         ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
      //     ).toFixed(2) *
      //       1 +
      //     (
      //       formServiceInfo.noOfTeachers * formServiceInfo.ratePerHeadTeacher
      //     ).toFixed(2) *
      //       1 +
      //     (
      //       formServiceInfo.noOfStudents * formServiceInfo.ratePerHeadStudent
      //     ).toFixed(2) *
      //       1
      //   }`,
      // });
    }

    if (
      formServiceInfo.noOfStudentsReceivingDiscountedServices &&
      formServiceInfo.noOfTeachersReceivingDiscountedServices &&
      formServiceInfo.ratePerHeadTeacher &&
      formServiceInfo.ratePerHeadStudent &&
      formServiceInfo.rateOfTheDiscountServices &&
      formServiceInfo.noOfTeachers &&
      formServiceInfo.noOfStudents &&
      formServiceInfo.noOfStudentsReceivingFreeServices &&
      formServiceInfo.noOfTeachersReceivingFreeServices
    ) {
      setPerHead(
        `${(
          ((
            formServiceInfo.noOfStudentsReceivingDiscountedServices *
            (formServiceInfo.ratePerHeadStudent *
              ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
          ).toFixed(2) *
            1 +
            (
              formServiceInfo.noOfTeachersReceivingDiscountedServices *
              (formServiceInfo.ratePerHeadTeacher *
                ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
            ).toFixed(2) *
              1 +
            (
              formServiceInfo.noOfTeachers * formServiceInfo.ratePerHeadTeacher
            ).toFixed(2) *
              1 +
            (
              formServiceInfo.noOfStudents * formServiceInfo.ratePerHeadStudent
            ).toFixed(2) *
              1) /
          (formServiceInfo.noOfStudentsReceivingDiscountedServices * 1 +
            formServiceInfo.noOfTeachersReceivingDiscountedServices * 1 +
            formServiceInfo.noOfStudents * 1 +
            formServiceInfo.noOfTeachers * 1 +
            formServiceInfo.noOfStudentsReceivingFreeServices * 1 +
            formServiceInfo.noOfTeachersReceivingFreeServices * 1)
        ).toFixed(2)}`,
      );
      addedData = {...addedData,perHead:(
        ((
          formServiceInfo.noOfStudentsReceivingDiscountedServices *
          (formServiceInfo.ratePerHeadStudent *
            ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
        ).toFixed(2) *
          1 +
          (
            formServiceInfo.noOfTeachersReceivingDiscountedServices *
            (formServiceInfo.ratePerHeadTeacher *
              ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
          ).toFixed(2) *
            1 +
          (
            formServiceInfo.noOfTeachers * formServiceInfo.ratePerHeadTeacher
          ).toFixed(2) *
            1 +
          (
            formServiceInfo.noOfStudents * formServiceInfo.ratePerHeadStudent
          ).toFixed(2) *
            1) /
        (formServiceInfo.noOfStudentsReceivingDiscountedServices * 1 +
          formServiceInfo.noOfTeachersReceivingDiscountedServices * 1 +
          formServiceInfo.noOfStudents * 1 +
          formServiceInfo.noOfTeachers * 1 +
          formServiceInfo.noOfStudentsReceivingFreeServices * 1 +
          formServiceInfo.noOfTeachersReceivingFreeServices * 1)
      ).toFixed(2)
      }

      // onChangeServiceInfo({
      //   name: "perHead",
      //   value: `${(
      //     ((
      //       formServiceInfo.noOfStudentsReceivingDiscountedServices *
      //       (formServiceInfo.ratePerHeadStudent *
      //         ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
      //     ).toFixed(2) *
      //       1 +
      //       (
      //         formServiceInfo.noOfTeachersReceivingDiscountedServices *
      //         (formServiceInfo.ratePerHeadTeacher *
      //           ((100 - formServiceInfo.rateOfTheDiscountServices) / 100))
      //       ).toFixed(2) *
      //         1 +
      //       (
      //         formServiceInfo.noOfTeachers * formServiceInfo.ratePerHeadTeacher
      //       ).toFixed(2) *
      //         1 +
      //       (
      //         formServiceInfo.noOfStudents * formServiceInfo.ratePerHeadStudent
      //       ).toFixed(2) *
      //         1) /
      //     (formServiceInfo.noOfStudentsReceivingDiscountedServices * 1 +
      //       formServiceInfo.noOfTeachersReceivingDiscountedServices * 1 +
      //       formServiceInfo.noOfStudents * 1 +
      //       formServiceInfo.noOfTeachers * 1 +
      //       formServiceInfo.noOfStudentsReceivingFreeServices * 1 +
      //       formServiceInfo.noOfTeachersReceivingFreeServices * 1)
      //   ).toFixed(2)}`,
      // });

      
    }
    setformServiceInfo({...formServiceInfo,...addedData})
  };

  useEffect(() => {
    updateTotalAmount();
  }, [
    formServiceInfo.noOfStudents,
    formServiceInfo.ratePerHeadStudent,
    formServiceInfo.noOfTeachers,
    formServiceInfo.ratePerHeadTeacher,
    formServiceInfo.noOfStudentsReceivingDiscountedServices,
    formServiceInfo.noOfTeachersReceivingDiscountedServices,
    formServiceInfo.rateOfTheDiscountServices,
    formServiceInfo.noOfStudentsReceivingFreeServices,
    formServiceInfo.noOfTeachersReceivingFreeServices,
  ]);

  return (
    <>
      <section className="flex rounded-xl flex-col items-center justify-center bg-[#ffffff]  pb-5">
        <div>
          {" "}
          <h3 className="ml-2 py-4 text-2xl font-bold tracking-wider text-center ">
            Select Services
          </h3>
          <div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={formServiceInfo.serviceSelect || null}
                onChange={(e) => {
                  onChangeServiceInfo({
                    name: "serviceSelect",
                    value: e.target.value,
                  });
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <FormControlLabel
                  value="School Health Program"
                  control={<Radio />}
                  label="School Health Program"
                />
                <FormControlLabel
                  value="Only Counselling"
                  control={<Radio />}
                  label="Only Counselling"
                />
                <FormControlLabel
                  value="Only Check Up"
                  control={<Radio />}
                  label="Only Check Up"
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
            {errorsServiceInfo?.serviceSelect && (
              <p class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">
                {errorsServiceInfo?.serviceSelect}
              </p>
            )}
          </div>
          <div className="mt-5">
            {formServiceInfo?.serviceSelect === "Other" && (
              <TextField
                id="otherService"
                error={errorsServiceInfo?.otherService ? true : false}
                helperText={errorsServiceInfo?.otherService}
                label={
                  formServiceInfo.otherService
                    ? "Others service"
                    : "Other service *"
                }
                variant="outlined"
                fullWidth
                size="small"
                value={formServiceInfo.otherService || ""}
                onChange={(e) => {
                  onChangeServiceInfo({
                    name: "otherService",
                    value: e.target.value,
                  });
                }}
              />
            )}
          </div>
        </div>
      </section>{" "}
      <section className="flex flex-col items-center justify-center bg-[#ffffff] mt-4  sm:px-5 pb-5">
        <h3 className="ml-2 py-5 text-2xl font-bold tracking-wider text-center ">
          Contract Status
        </h3>

        <div className="flex sm:flex-row flex-col  gap-3">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={formServiceInfo.startDate ? "Start Date" : "Start Date *"}
              value={formServiceInfo?.startDate || null}
              onChange={(newValue) => {
                onChangeServiceInfo({
                  name: "startDate",
                  value: formateDate(newValue),
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errorsServiceInfo?.startDate ? true : false}
                  helperText={errorsServiceInfo?.startDate}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={formServiceInfo.endDate ? "End Date" : "End Date *"}
              value={formServiceInfo?.endDate || null}
              onChange={(newValue) => {
                onChangeServiceInfo({
                  name: "endDate",
                  value: formateDate(newValue),
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errorsServiceInfo?.endDate ? true : false}
                  helperText={errorsServiceInfo?.endDate}
                />
              )}
            />
          </LocalizationProvider>
        </div>
      </section>
      <div className="bg-[#ffffff]  py-2 mt-4 px-7 pb-5">
        <h3 className="ml-2 py-5 text-2xl font-bold tracking-wider text-center ">
          Full payment
        </h3>
        <h6 className="ml-[20px] mb-[20px]  text-xl font-bold ">
          <ReduceCapacityIcon className="text-sky-500 mr-5		" />
          Students
        </h6>
        <div className="  grid md:grid-cols-2 grid-col-1 items-center justify-center grid-cols-1  gap-3 sm:px-2 pb-1 relative ">
          <TextField
            error={errorsServiceInfo.noOfStudents ? true : false}
            helperText={errorsServiceInfo.noOfStudents}
            id="noOfStudents"
            label={
              formServiceInfo.noOfStudents
                ? "No of Students"
                : "No of Students *"
            }
            variant="outlined"
            fullWidth
            value={formServiceInfo.noOfStudents || ""}
            size="small"
            type="number"
            onChange={(e) => {
              onChangeServiceInfo({
                name: "noOfStudents",
                value: e.target.value,
              });
            }}
          />{" "}
          <TextField
            error={errorsServiceInfo.ratePerHeadStudent ? true : false}
            helperText={errorsServiceInfo.ratePerHeadStudent}
            id="ratePerHeadStudent"
            label={
              formServiceInfo.ratePerHeadStudent
                ? "Rate per head student"
                : "Rate per head student *"
            }
            variant="outlined"
            fullWidth
            value={formServiceInfo.ratePerHeadStudent || ""}
            size="small"
            type="number"
            onChange={(e) => {
              onChangeServiceInfo({
                name: "ratePerHeadStudent",
                value: e.target.value,
              });
            }}
          />
        </div>
        <div className="p-2 flex justify-end 	text-center	">
          <span className="text-lime-600	">Total amount - </span>
          <span className="text-lime-600 flex	">
            <TbCurrencyTaka
              icon="fa-solid fa-bangladeshi-taka-sign "
              className="text-lg	"
            />
            {studentTotalAmount}
          </span>
        </div>
        <h6 className="ml-[20px] mb-[20px]  text-xl font-bold ">
          <SchoolIcon className="text-sky-500 	mr-5	" />
          Teachers
        </h6>
        <div className=" grid md:grid-cols-2 grid-col-1 items-center justify-center grid-cols-1  gap-3 sm:px-2 pb-1 relative ">
          <TextField
            error={errorsServiceInfo.noOfTeachers ? true : false}
            helperText={errorsServiceInfo.noOfTeachers}
            id="noOfTeachers"
            label={
              formServiceInfo.noOfTeachers
                ? "No of Teachers"
                : "No of Teachers *"
            }
            variant="outlined"
            value={formServiceInfo.noOfTeachers || ""}
            fullWidth
            size="small"
            type="number"
            onChange={(e) => {
              onChangeServiceInfo({
                name: "noOfTeachers",
                value: e.target.value,
              });
            }}
          />{" "}
          <TextField
            error={errorsServiceInfo.ratePerHeadTeacher ? true : false}
            helperText={errorsServiceInfo.ratePerHeadTeacher}
            id="ratePerHeadTeacher"
            label={
              formServiceInfo.ratePerHeadTeacher
                ? "Rate per head teacher"
                : "Rate per head teacher *"
            }
            variant="outlined"
            value={formServiceInfo.ratePerHeadTeacher || ""}
            fullWidth
            size="small"
            type="number"
            onChange={(e) => {
              onChangeServiceInfo({
                name: "ratePerHeadTeacher",
                value: e.target.value,
              });
            }}
          />
        </div>

        <div className="p-2 flex justify-end 	text-center	">
          <span className="text-lime-600	">Total amount - </span>
          <span className="text-lime-600 flex	">
            <TbCurrencyTaka
              icon="fa-solid fa-bangladeshi-taka-sign "
              className="text-lg	"
            />
            {teacherTotalAmount}
          </span>
        </div>
      </div>
      <div className="bg-[#ffffff] py-2 mt-4 px-7 pb-5">
        <h3 className="ml-2 py-5 text-2xl font-bold tracking-wider text-center ">
          Discount services
        </h3>
        <div className=" grid md:grid-cols-2 grid-col-1 items-center justify-center grid-cols-1  gap-3 sm:px-2 pb-1 relative ">
          <TextField
            error={
              errorsServiceInfo.noOfStudentsReceivingDiscountedServices
                ? true
                : false
            }
            helperText={
              errorsServiceInfo.noOfStudentsReceivingDiscountedServices
            }
            id="noOfStudentsReceivingDiscountedServices"
            variant="outlined"
            label={
              formServiceInfo.noOfStudentsReceivingDiscountedServices
                ? "No of students receiving discounted services"
                : "No of students receiving discounted services *"
            }
            value={
              formServiceInfo.noOfStudentsReceivingDiscountedServices || ""
            }
            fullWidth
            size="small"
            type="number"
            onChange={(e) => {
              onChangeServiceInfo({
                name: "noOfStudentsReceivingDiscountedServices",
                value: e.target.value,
              });
            }}
          />{" "}
          <TextField
            error={
              errorsServiceInfo.noOfTeachersReceivingDiscountedServices
                ? true
                : false
            }
            helperText={
              errorsServiceInfo.noOfTeachersReceivingDiscountedServices
            }
            id="noOfTeachersReceivingDiscountedServices"
            label={
              formServiceInfo.noOfTeachersReceivingDiscountedServices
                ? "No of teachers receiving discounted services"
                : "No of teachers receiving discounted services *"
            }
            variant="outlined"
            value={
              formServiceInfo.noOfTeachersReceivingDiscountedServices || ""
            }
            fullWidth
            size="small"
            type="number"
            onChange={(e) => {
              onChangeServiceInfo({
                name: "noOfTeachersReceivingDiscountedServices",
                value: e.target.value,
              });
            }}
          />
          <TextField
            error={errorsServiceInfo.rateOfTheDiscountServices ? true : false}
            helperText={errorsServiceInfo.rateOfTheDiscountServices}
            id="rateOfTheDiscountServices"
            label={
              formServiceInfo.rateOfTheDiscountServices
                ? "Rate of the discount services(%)"
                : "Rate of the discount services(%) *"
            }
            variant="outlined"
            value={formServiceInfo.rateOfTheDiscountServices || ""}
            fullWidth
            size="small"
            type="number"
            onChange={(e) => {
              onChangeServiceInfo({
                name: "rateOfTheDiscountServices",
                value: e.target.value,
              });
            }}
          />
        </div>

        <div className="p-2 flex justify-end 	text-center	">
          <span className="text-lime-600	">Total amount - </span>
          <span className="text-lime-600 flex	">
            <TbCurrencyTaka
              icon="fa-solid fa-bangladeshi-taka-sign "
              className="text-lg	"
            />
            {(discountTotalAmount * 1)?.toFixed(2)}
          </span>
        </div>
      </div>
      <div className="bg-[#ffffff]  py-2 mt-4 px-7 pb-5">
        <h3 className="ml-2 py-5 text-2xl font-bold tracking-wider text-center ">
          Free services
        </h3>
        <div className="  grid md:grid-cols-2 grid-col-1 items-center justify-center grid-cols-1  gap-3 sm:px-2 pb-5 relative ">
          <TextField
            error={
              errorsServiceInfo.noOfStudentsReceivingFreeServices ? true : false
            }
            helperText={errorsServiceInfo.noOfStudentsReceivingFreeServices}
            id="noOfStudentsReceivingFreeServices"
            label={
              formServiceInfo.noOfStudentsReceivingFreeServices
                ? "No of students receiving free services"
                : "No of students receiving free services *"
            }
            variant="outlined"
            value={formServiceInfo.noOfStudentsReceivingFreeServices || ""}
            fullWidth
            size="small"
            type="number"
            onChange={(e) => {
              onChangeServiceInfo({
                name: "noOfStudentsReceivingFreeServices",
                value: e.target.value,
              });
            }}
          />{" "}
          <TextField
            error={
              errorsServiceInfo.noOfTeachersReceivingFreeServices ? true : false
            }
            helperText={errorsServiceInfo.noOfTeachersReceivingFreeServices}
            id="noOfTeachersReceivingFreeServices"
            
            label={
              formServiceInfo.noOfTeachersReceivingFreeServices
                ? "No of teachers receiving free services"
                : "No of teachers receiving free services *"
            }
            variant="outlined"
            value={formServiceInfo.noOfTeachersReceivingFreeServices || ""}
            fullWidth
            size="small"
            type="number"
            onChange={(e) => {
              onChangeServiceInfo({
                name: "noOfTeachersReceivingFreeServices",
                value: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="bg-[#ffffff]  py-2 mt-4 px-7 pb-5">
        <h3 className="ml-2 py-5 text-2xl font-bold tracking-wider text-center ">
          Summary
        </h3>
        <div className="p-2 flex justify-between 	text-center	">
          <span className="text-lime-600	">Total patients </span>

          <span className="text-lime-600 flex	">
            <TbCurrencyTaka
              icon="fa-solid fa-bangladeshi-taka-sign "
              className="text-lg	"
            />
            {totalPatient}
          </span>
        </div>
        <div className="p-2 flex justify-between 	text-center	">
          <span className="text-lime-600	">Sum of Total Amount </span>
          <span className="text-lime-600 flex	">
            <TbCurrencyTaka
              icon="fa-solid fa-bangladeshi-taka-sign "
              className="text-lg	"
            />

            {totalAmount}
          </span>
        </div>
        <div className="p-2 flex justify-between 	text-center	">
          <span className="text-lime-600	">Per head costing </span>
          <span className="text-lime-600 flex	">
            <TbCurrencyTaka
              icon="fa-solid fa-bangladeshi-taka-sign "
              className="text-lg	"
            />
            {perHead}
          </span>
        </div>
      </div>
      <div className="bg-[#ffffff]  py-2 mt-4 px-7 pb-5">
        <h3 className="ml-2 py-5 text-2xl font-bold tracking-wider text-center ">
          Service Status
        </h3>
        {errorsServiceInfo?.serviceStatus && (
          <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">
            {errorsServiceInfo?.serviceStatus}
          </p>
        )}
        <div className="flex justify-center">
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={formServiceInfo.serviceStatus || null}
              onChange={(e) => {
                onChangeServiceInfo({
                  name: "serviceStatus",
                  value: e.target.value,
                });
              }}
            >
              <FormControlLabel
                value="Not Started"
                control={<Radio />}
                label="Not Started"
              />
              <FormControlLabel
                value="Ongoing"
                control={<Radio />}
                label="Ongoing"
              />
              <FormControlLabel
                value="Finished"
                control={<Radio />}
                label="Finished"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </>
  );
}
