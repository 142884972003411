import { Box, Typography } from "@mui/material";
import React from "react";
import {
  AiOutlineLink
} from "react-icons/ai";
import {
  BsCalendar2Date,
  BsCalendarCheck
} from "react-icons/bs";

const Remarks = ({ singleHealthRecord }) => {
   const {
    s_diagnosis,
    s_treatment,
    s_referral,
    s_health_status
   } = singleHealthRecord;
  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", my: 5 }}>
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendarCheck style={{ marginRight: 4 }} />
            Diagnosis
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_diagnosis}
          </Typography>
        </Box>

        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsCalendar2Date style={{ marginRight: 4 }} />
            Treatment
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_treatment}
          </Typography>
        </Box>
        <Box sx={{ width: "25%" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,

              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineLink style={{ marginRight: 4 }} />
            Referral(if needed)
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {s_referral}
          </Typography>
        </Box>


        <Box sx={{ width: "25%" }}>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: 14,

            display: "flex",
            alignItems: "center",
          }}
        >
          <AiOutlineLink style={{ marginRight: 4 }} />
          Health Status
        </Typography>
        <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
          {s_health_status}
        </Typography>
      </Box>

      </Box>
    </Box>
  );
};

export default Remarks;
