import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { defaultDoctorImage, userTypes } from "../../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint
} from "../../../../constants/ApiConstant/routeConstant";
import { useToken } from "../../../../services/useAuth";
import AppSteper from "../../../ui/AppSteper";
import { notify } from "../../../ui/helpers/ToastMsg";
import BasicInfo from "./Forms/BasicInfo";
import EducationAndExperience from "./Forms/EducationAndExperience";
import Others from "./Forms/Others";
import Password from "./Forms/Password";
import ServiceLocation from "./Forms/ServiceLocation";

const Add = () => {
  const [ind, setInd] = useState(0);
  const navigate = useNavigate();

  const [formBasicInfo, setFormBasicInfo] = useState({
    proImg: defaultDoctorImage,
  });
  const [errorsBasicInfo, setErrorsBasicInfo] = useState({});

  const [formEducationAndExperience, setFormEducationAndExperience] = useState(
    {},
  );
  const [errorsEducationAndExperience, setErrorsEducationAndExperience] =
    useState({});

  const [formServiceLocation, setFormServiceLocation] = useState({});
  const [errorsServiceLocation, setErrorsServiceLocation] = useState({});

  const [formOthers, setFormOthers] = useState({});
  const [errorsOthers, setErrorsOthers] = useState({});

  const [formPassword, setFormPassword] = useState({});
  const [errorsPassword, setErrorsPassword] = useState({});

  const [telemedicineDoctor, setTelemedicineDoctor] = useState({});

  //console.log(telemedicineDoctor);
  const token = useToken();
  const onSubmit = () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const bodyData = {
      profileType: userTypes.doctor,
      data: {
        phone: telemedicineDoctor.phone,
        name: telemedicineDoctor.name,
        pass: telemedicineDoctor.confirmPassword,
        image: telemedicineDoctor.proImg,
        adminType: userTypes.doctor,
      },
      profileData: {
        dName: telemedicineDoctor.name,
        degree: telemedicineDoctor.degree,
        education: telemedicineDoctor.education,
        experience: telemedicineDoctor.experience,
        division: telemedicineDoctor.division,
        district: telemedicineDoctor.district,
        charge: telemedicineDoctor.charge,
        totalExperience: telemedicineDoctor.totalYearsOfExperience,
        workingPlace: telemedicineDoctor.workingPlace,
        signature: telemedicineDoctor.signatureImg,
        specialty: telemedicineDoctor.speciality,
      },
    };

    axios
      .post(`${BASE_URL}${endPoint.user}`, bodyData, header)
      .then((res) => {
        if (res?.data?.success) {
          //console.log(res.data?.data);
          notify("Doctor Added Successfully!", "success");
          navigate("/telemedicine/doctors/view");
        }
      })
      .catch((err) => {
        //console.log(err);
        notify(err.response.data?.message, "warning");
      });
  };
  //console.log(telemedicineDoctor);

  const onChangeBasicInfo = ({ name, value }) => {
    setFormBasicInfo({ ...formBasicInfo, [name]: value });
    if (name === "name") {
      if (value === "") {
        setErrorsBasicInfo((prev) => {
          return {
            ...prev,
            [name]: "Name cann't be blank.",
          };
        });
      } else {
        setErrorsBasicInfo((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "degree") {
      if (value === "") {
        setErrorsBasicInfo((prev) => {
          return {
            ...prev,
            [name]: "Degree cann't be blank.",
          };
        });
      } else {
        setErrorsBasicInfo((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "totalYearsOfExperience") {
      if (value === "" || value <= 0) {
        setErrorsBasicInfo((prev) => {
          return {
            ...prev,
            [name]: "Total Years Of Experience cann't be blank or negative.",
          };
        });
      } else {
        setErrorsBasicInfo((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "phone") {
      if (value === "" || value <= 0) {
        setErrorsBasicInfo((prev) => {
          return {
            ...prev,
            [name]: "Phone Number cann't be blank or negative.",
          };
        });
      } else {
        setErrorsBasicInfo((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
  };
  const onBasicInfoSubmit = () => {
    if (!formBasicInfo.name) {
      setErrorsBasicInfo((prev) => {
        return {
          ...prev,
          name: "Please add a Doctor Name",
        };
      });
    }
    if (!formBasicInfo.degree) {
      setErrorsBasicInfo((prev) => {
        return {
          ...prev,
          degree: "Please add a Doctor Degree",
        };
      });
    }
    if (!formBasicInfo.proImg) {
      setErrorsBasicInfo((prev) => {
        return {
          ...prev,
          proImg: "Please add a Doctor profile Image",
        };
      });
    }
    if (!formBasicInfo.totalYearsOfExperience) {
      setErrorsBasicInfo((prev) => {
        return {
          ...prev,
          totalYearsOfExperience:
            "Please add a Doctor Total Years  OfExperience",
        };
      });
    }
    if (!formBasicInfo.phone) {
      setErrorsBasicInfo((prev) => {
        return {
          ...prev,
          phone: "Please add a Doctor Phone Number",
        };
      });
    }

    if (
      formBasicInfo.name &&
      formBasicInfo.degree &&
      formBasicInfo.proImg &&
      formBasicInfo.totalYearsOfExperience &&
      formBasicInfo.phone &&
      Object.values(errorsBasicInfo).every((item) => !item)
    ) {
      // //console.log(formBasicInfo);

      setInd((prev) => prev + 1);
    }
  };

  const onChangeEducationAndExperience = ({ name, value }) => {
    setFormEducationAndExperience({
      ...formEducationAndExperience,
      [name]: value,
    });
    if (name === "education") {
      if (value === "") {
        setErrorsEducationAndExperience((prev) => {
          return {
            ...prev,
            [name]: "Education cann't be blank.",
          };
        });
      } else {
        setErrorsEducationAndExperience((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "experience") {
      if (value === "") {
        setErrorsEducationAndExperience((prev) => {
          return {
            ...prev,
            [name]: "Experience cann't be blank.",
          };
        });
      } else {
        setErrorsEducationAndExperience((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "workingPlace") {
      if (value === "") {
        setErrorsEducationAndExperience((prev) => {
          return {
            ...prev,
            [name]: "Working Place cann't be blank.",
          };
        });
      } else {
        setErrorsEducationAndExperience((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
  };
  const onSubmitEducationAndExperience = () => {
    if (!formEducationAndExperience.education) {
      setErrorsEducationAndExperience((prev) => {
        return {
          ...prev,
          education: "Please add a Doctor Education ",
        };
      });
    }
    if (!formEducationAndExperience.experience) {
      setErrorsEducationAndExperience((prev) => {
        return {
          ...prev,
          experience: "Please add a Doctor  Experience",
        };
      });
    }
    if (!formEducationAndExperience.workingPlace) {
      setErrorsEducationAndExperience((prev) => {
        return {
          ...prev,
          workingPlace: "Please add a Doctor  Working Place",
        };
      });
    }
    if (
      formEducationAndExperience.education &&
      formEducationAndExperience.experience &&
      formEducationAndExperience.experience &&
      Object.values(errorsEducationAndExperience).every((item) => !item)
    ) {
      // //console.log(formEducationAndExperience);

      setInd((prev) => prev + 1);
    }
  };

  const onChangeServiceLocation = ({ name, value }) => {
    setFormServiceLocation({ ...formServiceLocation, [name]: value });

    if (name === "division") {
      if (value === "") {
        setErrorsServiceLocation((prev) => {
          return { ...prev, [name]: "Division is required!" };
        });
      } else {
        setErrorsServiceLocation((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "district") {
      if (value === "") {
        setErrorsServiceLocation((prev) => {
          return { ...prev, [name]: "District is required!" };
        });
      } else {
        setErrorsServiceLocation((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
  };
  const onSubmitServiceLocation = () => {
    if (!formServiceLocation.division) {
      setErrorsServiceLocation((prev) => {
        return {
          ...prev,
          division: "Please select division ",
        };
      });
    }
    if (!formServiceLocation.district) {
      setErrorsServiceLocation((prev) => {
        return {
          ...prev,
          district: "Please select District ",
        };
      });
    }
    if (
      formServiceLocation.division &&
      formServiceLocation.district &&
      Object.values(errorsServiceLocation).every((item) => !item)
    ) {
      setInd((prev) => prev + 1);
    }
  };

  const onChangeOthers = ({ name, value }) => {
    setFormOthers({ ...formOthers, [name]: value });

    if (name === "charge") {
      if (value === "") {
        setErrorsOthers((prev) => {
          return { ...prev, [name]: "Charge is required!" };
        });
      } else {
        setErrorsOthers((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
  };
  const onSubmitOthers = () => {
    if (!formOthers.charge) {
      setErrorsOthers((prev) => {
        return {
          ...prev,
          charge: "Please select Doctor Charge ",
        };
      });
    }
    
    if (
      formOthers.charge &&
      Object.values(errorsOthers).every((item) => !item)
    ) {
      setInd((prev) => prev + 1);
    }
  };

  //Password on Change Validation
  const onChangePassword = ({ name, value }) => {
    setFormPassword({ ...formPassword, [name]: value });
    if (name === "password") {
      if (value === "") {
        setErrorsPassword((prev) => {
          return { ...prev, [name]: "Password can't be blank!" };
        });
      } else if (value.length < 8) {
        setErrorsPassword((prev) => {
          return {
            ...prev,
            [name]: "Password should be at least 8 characters!",
          };
        });
      } else {
        setErrorsPassword((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "confirmPassword") {
      if (value === "") {
        setErrorsPassword((prev) => {
          return { ...prev, [name]: "Password can't be blank!" };
        });
      } else if (value.length < 8) {
        setErrorsPassword((prev) => {
          return {
            ...prev,
            [name]: "Password should be at least 8 characters!",
          };
        });
      } else {
        setErrorsPassword((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
  };

  // Password on submit validation
  const onSubmitPassword = () => {
    if (!formPassword.password) {
      setErrorsPassword((prev) => {
        return { ...prev, password: "Please Insert Password" };
      });
    }

    if (!formPassword.confirmPassword) {
      setErrorsPassword((prev) => {
        return { ...prev, confirmPassword: "Please Insert Confirm Password" };
      });
    }
    if (formPassword.confirmPassword !== formPassword.password) {
      setErrorsPassword((prev) => {
        return {
          ...prev,
          password: "Password doesn't match with confirm Password",
          confirmPassword: "Password doesn't match with confirm Password",
        };
      });
    }

    if (
      Object.values(formPassword).length > 1 &&
      Object.values(formPassword).filter((item) => item.trim().length > 0)
        .length > 1 &&
      Object.values(errorsPassword).every((item) => !item)
    ) {
      //setInd((prev) => prev + 1)
      //upload student info to api route: api/user and "profileType" will be "schoolHealth"
      onSubmit();
    }
  };

  useEffect(() => {
    setTelemedicineDoctor({
      ...formBasicInfo,
      ...formEducationAndExperience,
      ...formServiceLocation,
      ...formOthers,
      ...formPassword,
    });
  }, [
    formBasicInfo,
    formEducationAndExperience,
    formServiceLocation,
    formOthers,
    formPassword,
  ]);

  //console.log(telemedicineDoctor);

  const getNextSt = () => {
    if (ind === 0) {
      return onBasicInfoSubmit();
    } else if (ind === 1) {
      return onSubmitEducationAndExperience();
    } else if (ind === 2) {
      return onSubmitServiceLocation();
    } else if (ind === 3) {
      return onSubmitOthers();
    } else if (ind === 4) {
      return onSubmitPassword();
    }
  };
  return (
    <AppSteper
      firstStep="Basic Info"
      OtherSteps={[
        "Studied at & expertise",
        "Service Location",
        "Others",
        "Password",
      ]}
      ind={ind}
      setInd={setInd}
      getNextSt={getNextSt}
    >
      <div>
        {ind === 0 && (
          <BasicInfo
            onChangeBasicInfo={onChangeBasicInfo}
            errorsBasicInfo={errorsBasicInfo}
            formBasicInfo={formBasicInfo}
            setErrorsBasicInfo={setErrorsBasicInfo}
          />
        )}
        {ind === 1 && (
          <EducationAndExperience
            formEducationAndExperience={formEducationAndExperience}
            errorsEducationAndExperience={errorsEducationAndExperience}
            onChangeEducationAndExperience={onChangeEducationAndExperience}
          />
        )}
        {ind === 2 && (
          <ServiceLocation
            formServiceLocation={formServiceLocation}
            errorsServiceLocation={errorsServiceLocation}
            onChangeServiceLocation={onChangeServiceLocation}
          />
        )}
        {ind === 3 && (
          <Others
            formOthers={formOthers}
            onChangeOthers={onChangeOthers}
            errorsOthers={errorsOthers}
            setErrorsOthers={setErrorsOthers}
          />
        )}

        {ind === 4 && (
          <Password
            onChangePassword={onChangePassword}
            formPassword={formPassword}
            errorsPassword={errorsPassword}
          />
        )}
      </div>
    </AppSteper>
  );
};

export default Add;
