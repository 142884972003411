import React from 'react'
import AppointmentsInfo from "../../../components/Telemedicine/AppointmentsMain/ViewMain/AppointmentsDetails";
const AppointmentsDetails = () => {
  return (
    <div>
      <AppointmentsInfo />
    </div>
  );
}

export default AppointmentsDetails