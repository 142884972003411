import { Autocomplete, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BASE_URL,
  endPoint,
} from "../../../../constants/ApiConstant/routeConstant";
import { divisions } from "../../../../constants/GeoLocation/geoLocation";
import { useToken } from "../../../../services/useAuth";
import { getLocation } from "../../../../utils/AddressFunctions/getLocation";
import { formateDate } from "../../../../utils/Formatter/dateFormater";
import { notify } from "../../../ui/helpers/ToastMsg";

const AddQuiz = () => {
  const [formEvent, setFormEvent] = useState({});
  const navigate = useNavigate();
  const [errorsEvents, setErrorsEvents] = useState({});
  const [districtData, setDistrictData] = useState();

  const [events, setEvents] = useState({});
  const token = useToken();

  const onChangeEvent = ({ name, value }) => {
    setFormEvent({ ...formEvent, [name]: value });
    if (name === "nameOfOlympiad") {
      if (value === "") {
        setErrorsEvents((prev) => {
          return {
            ...prev,
            [name]: "Name Of Olympiad cann't be blank.",
          };
        });
      } else {
        setErrorsEvents((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "dateOfOlympiad") {
      if (value === "") {
        setErrorsEvents((prev) => {
          return {
            ...prev,
            [name]: "Date Of Olympiad cann't be blank.",
          };
        });
      } else {
        setErrorsEvents((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "division") {
      if (value === null) {
        setErrorsEvents((prev) => {
          return {
            ...prev,
            [name]: "Division cann't be blank.",
          };
        });
      } else {
        setErrorsEvents((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "district") {
      if (value === null) {
        setErrorsEvents((prev) => {
          return {
            ...prev,
            [name]: "District cann't be blank.",
          };
        });
      } else {
        setErrorsEvents((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "venue") {
      if (value === "") {
        setErrorsEvents((prev) => {
          return {
            ...prev,
            [name]: "venue cann't be blank.",
          };
        });
      } else {
        setErrorsEvents((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "region") {
      if (value === "") {
        setErrorsEvents((prev) => {
          return {
            ...prev,
            [name]: "Region cann't be blank.",
          };
        });
      } else {
        setErrorsEvents((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
  };
  const onSubmitEvent = () => {
    if (!formEvent.nameOfOlympiad) {
      setErrorsEvents((prev) => {
        return {
          ...prev,
          nameOfOlympiad: "Please add an Olympiad Name",
        };
      });
    }
    if (!formEvent.olympId) {
      setErrorsEvents((prev) => {
        return {
          ...prev,
          olympId: "Please add an Olympiad ID",
        };
      });
    }
    if (!formEvent.dateOfOlympiad) {
      setErrorsEvents((prev) => {
        return {
          ...prev,
          dateOfOlympiad: "Please add an Olympiad Date",
        };
      });
    }
    if (!formEvent.division) {
      setErrorsEvents((prev) => {
        return {
          ...prev,
          division: "Please add an Olympiad Division",
        };
      });
    }
    if (!formEvent.district) {
      setErrorsEvents((prev) => {
        return {
          ...prev,
          district: "Please add an Olympiad District",
        };
      });
    }
    if (!formEvent.venue) {
      setErrorsEvents((prev) => {
        return {
          ...prev,
          venue: "Please add an Olympiad venue",
        };
      });
    }
    if (!formEvent.region) {
      setErrorsEvents((prev) => {
        return {
          ...prev,
          region: "Please add an Olympiad Region",
        };
      });
    }
    if (
      formEvent.nameOfOlympiad &&
      formEvent.dateOfOlympiad &&
      formEvent.division &&
      formEvent.district &&
      formEvent.venue &&
      formEvent.region &&
      Object.values(errorsEvents).every((item) => !item)
    ) {
      setEvents({ ...formEvent });
      onSubmit();
    }
  };

  //console.log(events);

  const handleSelectedDivision = (param) => {
    onChangeEvent({ name: "division", value: param });
    const divId = divisions.filter((item) => item.name === param);
    const location = getLocation(divId[0].id);

    setDistrictData(location);
    //console.log(divId[0].id);
  };

  const onSubmit = () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const bodyData = {
      title: formEvent?.nameOfOlympiad,
      date: formEvent?.dateOfOlympiad,
      division: formEvent?.division,
      district: formEvent?.district,
      venue: formEvent?.venue,
      region: formEvent?.region,
    };

    axios
      .post(`${BASE_URL}${endPoint.event}`, bodyData, header)
      .then((res) => {
        if (res?.data?.success) {
          //console.log(res.data?.data);
          notify("Event Added Successfully!", "success");
          navigate("/olympiad/events/view");
        }
      })
      .catch((err) => {
        //console.log(err);
        notify(err.response.data?.message, "warning");
      });
  };
  return (
    <div className="my-2 mt-10 shadow-md rounded-xl">
      <div className=" bg-white px-[3rem] py-[2rem] rounded-xl">
        <h1 className=" text-2xl pt-5 text-center font-bold uppercase">
          Add Events
        </h1>{" "}
        <Box sx={{ flexGrow: 1, mt: 7 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {" "}
              <TextField
                id="nameOfOlympiad"
                variant="outlined"
                fullWidth
                size="small"
                value={formEvent?.nameOfOlympiad || ""}
                label={
                  formEvent?.nameOfOlympiad
                    ? "Name of Olympiad"
                    : "Name of Olympiad *"
                }
                onChange={(e) => {
                  onChangeEvent({
                    name: "nameOfOlympiad",
                    value: e.target.value,
                  });
                }}
                error={errorsEvents?.nameOfOlympiad ? true : false}
                helperText={errorsEvents?.nameOfOlympiad}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={
                    formEvent?.dateOfOlympiad
                      ? "Date of Olympiad"
                      : "Date of Olympiad *"
                  }
                  value={formEvent?.dateOfOlympiad || ""}
                  small
                  onChange={(newValue) => {
                    onChangeEvent({
                      name: "dateOfOlympiad",
                      value: formateDate(newValue),
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      error={errorsEvents?.dateOfOlympiad ? true : false}
                      helperText={errorsEvents?.dateOfOlympiad}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                id="division"
                options={divisions.map((div) => div.name)}
                fullWidth
                size="small"
                isOptionEqualToValue={(options, value) =>
                  options.value === value.value
                }
                value={formEvent?.division || ""}
                onChange={(event, newValue) => {
                  handleSelectedDivision(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    error={errorsEvents?.division ? true : false}
                    helperText={errorsEvents?.division}
                    {...params}
                    label={formEvent?.division ? "Division" : "Division" + " *"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                id="district"
                options={
                  districtData ? districtData.map((dist) => dist.name) : []
                }
                fullWidth
                size="small"
                isOptionEqualToValue={(options, value) =>
                  options.value === value.value
                }
                value={formEvent?.district || ""}
                onChange={(event, newValue) => {
                  onChangeEvent({
                    name: "district",
                    value: newValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    error={errorsEvents?.district ? true : false}
                    helperText={errorsEvents?.district}
                    {...params}
                    label={formEvent?.district ? "District" : "District" + " *"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {" "}
              <TextField
                id="venue"
                fullWidth
                size="small"
                value={formEvent?.venue || ""}
                label={formEvent?.venue ? "Venue" : "Venue *"}
                onChange={(e) => {
                  onChangeEvent({
                    name: "venue",
                    value: e.target.value,
                  });
                }}
                error={errorsEvents?.venue ? true : false}
                helperText={errorsEvents?.venue}
              />
            </Grid>{" "}
            <Grid item xs={12} sm={6}>
              {" "}
              <TextField
                id="region"
                fullWidth
                size="small"
                value={formEvent?.region || ""}
                label={formEvent?.region ? "Region" : "Region *"}
                onChange={(e) => {
                  onChangeEvent({
                    name: "region",
                    value: e.target.value,
                  });
                }}
                error={errorsEvents?.region ? true : false}
                helperText={errorsEvents?.region}
              />
            </Grid>
          </Grid>
        </Box>
        <div className=" flex justify-center">
          <button
            className="btn bg-blue-600 hover:bg-blue-600  border-none  font-bold mt-5 px-10 rounded-2xl  "
            onClick={() => onSubmitEvent()}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddQuiz;
