import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "../../../../../redux/store";
import AppButton from "../../../../ui/AppButton";
import AppModal from "../../../../ui/AppModal";

const buttonApply = {
  variant: "primary",
  fullWidth: true,
  size: "xs",
  classes: " text-sm h-11 w-28 font-semibold",
};
const buttonClear = {
  variant: "info",
  fullWidth: true,
  size: "xs",
  classes: " text-sm h-11 w-28 font-semibold mr-2",
};

const FilterData = ({
  sAStudentsFilter,
  setSAStudentsFilter,
  handleFilter,
  setClearFilter,
  clearFilter,
}) => {
  const {  userInfo } = useSelector((state) => state.userInfo);
  const SAStudentsData = {
    insType: "",
    insId: userInfo.schoolAuthority.ins_id,
    classId: 0,
    className: '',
    gender: "",
  };
  const { classes } = useSelector((state) => state.schoolAuthority);
  const [isShow, setIsShow] = useState(false);

  const getClasses = () => {
    let options = [];
    classes &&
      classes.filter((item) => {
        options.push({
          value: item.ins_class_id,
          label: `Class ${item.ins_class_name}`,
        });
      });

    return options;
  };


  return (
    <div>
      <AppModal
        isShowModal={isShow}
        callback={(item) => setIsShow(item)}
        option
        modalTitle="Filter"
        modalTitleClass="text-xl"
        modalWidth="md:w-[420px] w-full"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="institueType"
                  options={getClasses()?.map((elm) => elm.label)}
                  fullWidth
                  size="small"
                  value={sAStudentsFilter.className || ""}
                  onChange={(event, newValue) => {
                    setSAStudentsFilter({
                      ...sAStudentsFilter,
                      className: newValue,
                      classId: getClasses()?.filter(
                        (elm) => elm.label === newValue
                      )[0].value,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Class Name" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="gender"
                  options={["Male", "Female", "Others"]}
                  fullWidth
                  size="small"
                  value={sAStudentsFilter.gender || ""}
                  onChange={(event, newValue) => {
                    setSAStudentsFilter({
                      ...sAStudentsFilter,
                      gender: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Gender" />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <div className="mt-52 flex justify-end">
            <AppButton
              {...buttonClear}
              onClick={() => {
                setClearFilter(!clearFilter);
                setSAStudentsFilter(SAStudentsData);
                setIsShow(false);
              }}
            >
              Clear Filter
            </AppButton>
            <AppButton
              {...buttonApply}
              onClick={() => {
                handleFilter();
                setIsShow(false);
              }}
            >
              Apply
            </AppButton>
          </div>
        </Box>
      </AppModal>
    </div>
  );
};

export default FilterData;
