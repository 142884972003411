import { Box, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiIdCard } from "react-icons/bi";
import {
  BASE_URL,
  endPoint
} from "../../../../../constants/ApiConstant/routeConstant";
import { useSelector } from "../../../../../redux/store";
import CardView from "./CardView";

const RegisterOlympiad = ({ singleRegisteredStudent }) => {
  const { token } = useSelector((state) => state.userInfo);

  const [registrationEvent, setRegistrationEvent] = useState([]);
  const {
    oId
  } = singleRegisteredStudent;

  const getRegistrationEventData = async () => {
    const body = {
      oId: oId,
    };

    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(`${BASE_URL}${endPoint.eventRegGet}`, body, header)
      .then((res) => {
        if (res?.data?.success) {
          setRegistrationEvent(res?.data?.data);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    getRegistrationEventData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: "100%" }}>
          
          {registrationEvent?.length>0? (
            <>
            <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiIdCard style={{ marginRight: 4 }} />
            Event Name
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
          {registrationEvent?.map((item) => {
            return <CardView event={item} />;
          })}
        </Typography>
            </>
           
          ):(
            <h3>No data found</h3>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterOlympiad;
