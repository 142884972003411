import { timeConvertor } from "../../../../../utils/timeConvertor/timeConvertor";

export const tableConvertedData = (res) => {
  let data = [];
  res?.map((itm) => {
 
    let ap_time = "--";
    if (itm?.ap_time) {
      ap_time = `${itm?.ap_time.slice(0, 10)}  ${timeConvertor(
        itm?.ap_time.slice(11, 19),
      )}`;
    }

    let ap_req_time = "--";
    if (itm?.ap_req_time) {
      ap_req_time = `${itm?.ap_req_time.slice(0, 10)}  ${timeConvertor(
        itm?.ap_req_time.slice(11, 19),
      )}`;
    }

    data.push({
      dId: itm?.d_id || "--",
      uId: itm?.u_id || "--",
      dName: itm?.d_name || "--",
      dDegree: itm?.d_degree || "--",
      dEducation: itm?.d_education || "--",
      dExperience: itm?.d_experience || "--",
      dDivision: itm?.d_division || "--",
      dDistrict: itm?.d_district || "--",
      dWokingPlaceDetials: itm?.d_woking_place_detials || "--",
      dCharge: itm?.d_charge || "--",
      dTotalExpYears: itm?.d_total_exp_years || "--",
      dSignature: itm?.d_signature || "--",
      dSpecialty: itm?.d_specialty || "--",
      apId: itm?.ap_id || "--",
      pId: itm?.p_id || "--",
      apLink: itm?.ap_link || "--",
      apReqTime: ap_req_time,
      apTime: ap_time,
      apReason: itm?.ap_reason || "--",
      bloodPressure: itm?.blood_pressure || "--",
      pulse: itm?.pulse || "--",
      height: itm?.height || "--",
      weight: itm?.weight || "--",
      bloodGlucose: itm?.blood_glucose || "--",
      additionalNote: itm?.additional_note || "--",
      status: itm?.payment_status || "--",
      userId: itm?.user_id || "--",
      pName: itm?.p_name || "--",
      pDob: itm?.p_dob || "--",
      pGender: itm?.p_gender || "--",
      pProfession: itm?.p_profession || "--",
      pAddress: itm?.p_address || "--",
      pPhone: itm?.p_phone || "--",
      pEmail: itm?.p_email || "--",
      pNid: itm?.p_nid || "--",
      pBirthCft: itm?.p_birth_cft || "--",
      documents: itm?.documents || "--",
    });
  });

  return data;
};
