import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "../../../redux/store";
import AppCardContainer from "../../ui/AppCardContainer";
import AppSubNav from "../../ui/AppSubNav";
import { tableConvertedData } from "./helpers/convertedPatientsData";
import PDetailsBody from "./PDetailsBody";

const PDetails = () => {
  const { id } = useParams();
  const { token } = useSelector((state) => state.userInfo);
  const [singlePatient, setSinglePatient] = useState({});
  const [selectedNav, setSelectedNav] = useState();

  const getSinglePatientsData = async () => {
    const body = {
      profileType: "telemedicine",
    };
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(`https://api.adminomphd.online/api/user/get/${id}`, body, header)
      .then((res) => {
        if (res?.data?.message) {
          setSinglePatient(tableConvertedData([res?.data?.data])[0]);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  useEffect(() => {
    getSinglePatientsData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AppCardContainer spacing="xl:mt-10 3xl:-mr-16 md:mx-5 mt-20">
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <Link to="/patients">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="w-4 h-4 mr-2 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                ></path>
              </svg>
              All Patients
            </Link>
          </li>
          <li>
            <a>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="w-4 h-4 mr-2 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                ></path>
              </svg>
              <span className=" font-bold">Patient Details</span>
            </a>
          </li>
        </ul>
      </div>

      <div className="mt-10">
        <AppSubNav
          navbarData={["Personal Information"]}
          callback={(item) => setSelectedNav(item)}
          type="tab"
          width="w-44"
        />
      </div>

      {selectedNav?.key === "PersonalInformation" && <PDetailsBody singlePatient={singlePatient} />}
    </AppCardContainer>
  );
};

export default PDetails;
