import { Box, Grid, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsCamera } from "react-icons/bs";
import { MdOutlineModeEdit } from "react-icons/md";
import updateFailed from "../../../assets/failed.gif";
import updateSuccess from "../../../assets/update-succes.gif";
import update from "../../../assets/update.gif";
import { userTypes } from "../../../constants/ApiConstant/databaseConstant";
import { BASE_URL } from "../../../constants/ApiConstant/routeConstant";
import { logout } from "../../../redux/slices/userInfoSlice";
import { useDispatch, useSelector } from "../../../redux/store";
import {
  checkFile,
  valid_mobile,
} from "../../../utils/Validation/formValidation";
import AppButton from "../../ui/AppButton";
import AppCardContainer from "../../ui/AppCardContainer";
import AppModal from "../../ui/AppModal";
import Loader from "../../ui/Loader";

const Settings = () => {
  const dispatch = useDispatch();
  const [isShowInfo, setIsShowInfo] = useState(false);
  const [isShowPass, setIsShowPass] = useState(false);
  const { token, userInfo } = useSelector((state) => state.userInfo);
  const [proPicUploadLoading, setProPicUploadLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [imgUrl, setImgUrl] = useState("");

  const PersonalData = {
    phone: "",
    name: "",
    image: "",
    adminType: userTypes.doctor,
  };
  const PasswordData = {
    prevPassword: "",
    updatedPassword: "",
    confirmPassword: "",
  };

  const ErrorSettingData = {
    phone: null,
    name: null,
    image: null,
    prevPass: null,
    newPass: null,
    conPass: null,
  };

  const [personalData, setPersonalData] = useState(PersonalData);
  const [passwordData, setPasswordData] = useState(PasswordData);
  const [errorSetting, setErrorSetting] = useState(ErrorSettingData);

  const updatePersonalData = async () => {
    if (!errorSetting.name && !errorSetting.phone) {
      const body = {
        data: personalData,
      };

      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .put(`${BASE_URL}/api/user/common/${userInfo?.user_id}`, body, header)
        .then((res) => {
          setSuccessMsg("Information Updated Successfully");
        })
        .catch((err) => {
          setErrorMsg(err.response.data.message);
        });
    } else {
      setErrorMsg("Data is invalid!");
    }
  };

  //console.log(passwordData);
  const updatePassord = async () => {
    if (passwordData.updatedPassword === passwordData.confirmPassword) {
      const body = {
        prevPassword: passwordData?.prevPassword,
        updatedPassword: passwordData?.updatedPassword,
      };

      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .put(`${BASE_URL}/api/user/passd/${userInfo?.user_id}`, body, header)
        .then((res) => {
          setSuccessMsg("Password Updated Successfully");
        })
        .catch((err) => {
          setErrorMsg(err.response.data.message);
        });
    } else {
      setErrorMsg("please confirm your password !!");
    }
    // if (!errorSetting.prevPass && !errorSetting.newPass) {
    // } else {
    //   setErrorMsg("Data is invalid!");
    // }
  };

  const pickProPicAndUpload = async (e) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    if (checkFile(e.target.files, "image")) {
      const file = e.target.files[0];

      const formData = new FormData();
      formData.append("image", file);
      setProPicUploadLoading(true);
      try {
        const url = `${BASE_URL}/api/upload/image`;
        const res = await axios.post(url, formData, headers);
        if (res.data.success) {
          setImgUrl(res.data.data.path);
          setPersonalData({ ...personalData, image: res.data.data.path });
          setProPicUploadLoading(false);
        }
      } catch (e) {
        //console.log(e);
      }
    }
  };

  useEffect(() => {
    if (userInfo) {
      setPersonalData({
        ...personalData,
        name: userInfo?.user_name,
        phone: userInfo?.user_phone_id,
        image: userInfo?.user_img,
      });
      setImgUrl(userInfo?.user_img);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const getImageGif = () => {
    if (!(successMsg === "")) {
      return updateSuccess;
    } else if (!(errorMsg === "")) {
      return updateFailed;
    } else {
      return update;
    }
  };

  const getMessage = () => {
    if (!(successMsg === "")) {
      return successMsg;
    } else if (!(errorMsg === "")) {
      return errorMsg;
    } else {
      return isShowInfo
        ? "Want to update the Information ?"
        : "Want to update the Password ?";
    }
  };

  useEffect(() => {
    if (!(successMsg === "")) {
      setTimeout(() => {
        if (isShowInfo) {
          setIsShowInfo(false);
        }
        if (isShowPass) {
          setIsShowPass(false);
        }
        setSuccessMsg("");
        dispatch(logout(true));
      }, 2800);
    }
    if (!(errorMsg === "")) {
      setTimeout(() => {
        if (isShowInfo) {
          setIsShowInfo(false);
        }
        if (isShowPass) {
          setIsShowPass(false);
        }
        setErrorMsg("");
      }, 2800);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);
  return (
    <AppCardContainer>
      <h1 className="pb-5 text-xl font-bold">Personal Information</h1>
      <div className="flex flex-col md:flex-row md:items-center align-center xl:w-[60%] w-full">
        <div className={`avatar placeholder relative `}>
          <div
            className={`border border-gray-500 rounded-full w-28 cursor-pointer overflow-hidden `}
          >
            {proPicUploadLoading ? (
              <Loader variant="#3498db" />
            ) : imgUrl === "" ? (
              <BsCamera className={`text-gray-500 text-4xl `} />
            ) : (
              <img
                src={`${BASE_URL}/${
                  personalData?.image ? personalData?.image : imgUrl
                }`}
                alt=''
              />
            )}
          </div>

          <div className="avatar placeholder absolute left-20 ">
            <label htmlFor="file">
              <div
                className={`bg-secondary-content text-neutral-content rounded-full border border-gray-500 p-1 cursor-pointer`}
              >
                <MdOutlineModeEdit className=" text-info text-xl" />
              </div>
            </label>
            <input
              type="file"
              id="file"
              className="hidden read-only:border-0 h-14"
              onChange={pickProPicAndUpload}
            />
          </div>
        </div>

        <p className=" text-gray-400 md:ml-8 mt-3 md:mt-0 text-sm md:text-base">
          You can upload images up to 256x256. Your avatar shows up in your
          public profile and your team notifications.
        </p>
      </div>
      <Box sx={{ flexGrow: 1, mt: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {" "}
            <TextField
              id="name"
              label="Name"
              error={errorSetting.name ? true : false}
              helperText={errorSetting.name}
              variant="outlined"
              fullWidth
              size="small"
              value={personalData.name}
              onChange={(e) => {
                setPersonalData({ ...personalData, name: e.target.value });

                if (e.target.value !== "") {
                  setErrorSetting({ ...errorSetting, name: null });
                } else {
                  setErrorSetting({
                    ...errorSetting,
                    name: "Name can not be blank!",
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {" "}
            <TextField
              id="name"
              label="Phone Number"
              error={errorSetting.phone ? true : false}
              helperText={errorSetting.phone}
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              value={personalData.phone}
              onChange={(e) => {
                setPersonalData({ ...personalData, phone: e.target.value });

                if (valid_mobile(e.target.value)) {
                  setErrorSetting({ ...errorSetting, phone: null });
                } else {
                  setErrorSetting({
                    ...errorSetting,
                    phone: "Phone number is not valid!",
                  });
                }
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <div className="flex justify-end mt-10 ">
        <AppModal
          isShowModal={isShowInfo}
          callback={(item) => setIsShowInfo(item)}
          modalhead={false}
          modalWidth="md:w-[450px] w-full"
          modalBtn={
            <button className="btn bg-blue-600 hover:bg-blue-600  border-none  font-bold px-10 rounded-2xl  ">
              Update Information
            </button>
          }
        >
          <div className="flex flex-col items-center">
            <img src={getImageGif()} width="150px" alt="" />
            <p className="font-semibold">{getMessage()}</p>

            <div className="mt-10 flex ">
              <AppButton
                onClick={updatePersonalData}
                variant="primary"
                size="xs"
                classes=" !w-24 mr-3"
              >
                Yes
              </AppButton>
              <AppButton
                onClick={() => setIsShowInfo(false)}
                variant="info"
                size="xs"
                classes=" !w-24 "
              >
                Cancel
              </AppButton>
            </div>
          </div>
        </AppModal>
      </div>
      <div className=" ">
        <h1 className=" text-xl pt-10 font-bold">Change Passowrd</h1>

        <Box sx={{ flexGrow: 1, mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {" "}
              <TextField
                id="name"
                label="Old Passowrd"
                variant="outlined"
                fullWidth
                size="small"
                value={passwordData.prevPassword}
                onChange={(e) => {
                  setPasswordData({
                    ...passwordData,
                    prevPassword: e.target.value,
                  });

                  // if (valid_mobile(e.target.value)) {
                  //   setErrorSetting({ ...errorSetting, prevPass: null });
                  // } else {
                  //   setErrorSetting({
                  //     ...errorSetting,
                  //     prevPass: "Previous password is not valid!",
                  //   });
                  // }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {" "}
              <TextField
                id="name"
                label="New Passowrd"
                variant="outlined"
                fullWidth
                size="small"
                value={passwordData.updatedPassword}
                onChange={(e) => {
                  setPasswordData({
                    ...passwordData,
                    updatedPassword: e.target.value,
                  });

                  // if (valid_mobile(e.target.value)) {
                  //   setErrorSetting({ ...errorSetting, newPass: null });
                  // } else {
                  //   setErrorSetting({
                  //     ...errorSetting,
                  //     newPass: "Previous password is not valid!",
                  //   });
                  // }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {" "}
              <TextField
                id="olympid"
                label="Confirm Password"
                variant="outlined"
                fullWidth
                size="small"
                value={passwordData.confirmPassword}
                onChange={(e) => {
                  setPasswordData({
                    ...passwordData,
                    confirmPassword: e.target.value,
                  });

                  // if (valid_mobile(e.target.value)) {
                  //   setErrorSetting({ ...errorSetting, conPass: null });
                  // } else {
                  //   setErrorSetting({
                  //     ...errorSetting,
                  //     conPass: "Previous password is not valid!",
                  //   });
                  // }
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <div className="flex justify-end mt-10 ">
          <AppModal
            isShowModal={isShowPass}
            callback={(item) => setIsShowPass(item)}
            modalhead={false}
            modalWidth="md:w-[450px] w-full"
            modalBtn={
              <button className="btn bg-blue-600 hover:bg-blue-600  border-none  font-bold px-10 rounded-2xl  ">
                Update Password
              </button>
            }
          >
            <div className="flex flex-col items-center">
              <img src={getImageGif()} width="150px" alt="" />
              <p className="font-semibold">{getMessage()}</p>

              <div className="mt-10 flex ">
                <AppButton
                  onClick={updatePassord}
                  variant="primary"
                  size="xs"
                  classes=" !w-24 mr-3"
                >
                  Yes
                </AppButton>
                <AppButton
                  onClick={() => setIsShowPass(false)}
                  variant="info"
                  size="xs"
                  classes=" !w-24 "
                >
                  Cancel
                </AppButton>
              </div>
            </div>
          </AppModal>
        </div>
      </div>
    </AppCardContainer>
  );
};

export default Settings;
