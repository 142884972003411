import { useEffect, useRef, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import Loader from "./Loader";

const AppFilterSelect = ({ selectionData, callback, showOnClickApi }) => {
  const ref = useRef(null);
  const [isExpand, setIsExpand] = useState(false);
  const [data, setData] = useState(selectionData);
  const [lastSelectedId, setLastSelectedId] = useState("");

  const handleClick = (id) => {
    setIsExpand(false);
    if (showOnClickApi) {
      setLastSelectedId(id);
    }
    const updatedData = [...data];
    updatedData?.map((item) => {
      if (item.id === id) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });

    setData([...updatedData]);
    if (callback) {
      const selectedData = updatedData?.find((item) => item.id === id);
      callback(selectedData);
    }
  };

  const getSelectedData = () => {
    if (selectionData) {
      const updatedData = [...selectionData];

      const selectedData = updatedData?.find(
        (item) => item.selected || item?.id === lastSelectedId
      );

      return selectedData;
    }
    return "";
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsExpand(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      className=" w-44 h-full relative flex justify-between items-center"
      ref={ref}
    >
      {/* selected div */}
      <div
        className={`w-full h-full flex justify-between items-center pl-3 pr-2 md:px-4 cursor-pointer`}
        role="button"
        tabIndex={0}
        onClick={() => {
          setIsExpand(!isExpand);
        }}
      >
        <div className="flex items-center">
          <h4 className=" text-xs md:text-sm text-black">
            {getSelectedData()?.label
              ? getSelectedData()?.label
              : "Select Type"}
          </h4>
        </div>

        <div className="text-xs text-gray-400">
          <AiFillCaretDown />
        </div>
      </div>

      <div
        className={`bg-white z-50 w-full border border-base-200 rounded-xl shadow-xl absolute left-0 top-10
        transition-all ${
          isExpand ? "block opacity-100  translate-y-1 " : "opacity-0 hidden"
        }
         `}
      >
        <ul className={``}>
          {data.length !== 0 ? (
            <>
              {data?.map((item, index) => (
                <li
                  key={item?.id}
                  className={`rounded-t p-3 py-4 md:p-4 block whitespace-no-wrap cursor-pointer z-50 ${
                    !(index === data.length - 1)
                      ? "border-b border-gray-300"
                      : "border-none"
                  }`}
                  role="button "
                  tabIndex={0}
                  onClick={() => handleClick(item?.id)}
                >
                  <div className="flex items-center">
                    <h5 className="text-xs md:text-sm text-black">
                      {item?.label}
                    </h5>
                  </div>
                </li>
              ))}
            </>
          ) : (
            <div>
              <Loader />
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default AppFilterSelect;
