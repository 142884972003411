import { Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";
import { divisions } from "../../../../../constants/GeoLocation/geoLocation";

import { getLocation } from "../../../../../utils/AddressFunctions/getLocation";


const InstituteAddress = ({
  onChangeAddressInfo,
  errorsAddressInfo,
  formAddressInfo
}) => {
  const [districtData, setDistrictData] = useState();

  const handleSelectedDivision = (param) => {
    onChangeAddressInfo({name:"division",value:param})
    const divId = divisions.filter(item=> item.name===param)
    const location = getLocation(divId[0].id);
    setDistrictData(location);
    //console.log(divId[0].id)
  };

  return (
    <div
      className={` "block"
      rounded-xl bg-[#ffffff] p-5 font-poppins`}
    >
      <form>
        <div className=" grid sm:grid-cols-2 grid-cols-1  gap-3 px-2  ">
          <Autocomplete
            disablePortal
            id="division"
            options={divisions.map((div) => div.name)}
            fullWidth
            size="small"
            value={formAddressInfo.division || null}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(event, newValue) => {
              handleSelectedDivision(newValue);
            }}
            renderInput={(params) => (
              <TextField
                error={errorsAddressInfo.division ? true : false}
                helperText={errorsAddressInfo.division}
                {...params}
                label={
                  formAddressInfo.division ? "Division" : "Division" + " *"
                }
              />
            )}
          />
          <Autocomplete
            disablePortal
            id="district"
            options={districtData ? districtData.map((dist) => dist.name) : []}
            fullWidth
            size="small"
            value={formAddressInfo.district || null}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(event, newValue) => {
              onChangeAddressInfo({ name: "district", value: newValue });
            }}
            renderInput={(params) => (
              <TextField
                error={errorsAddressInfo.district ? true : false}
                helperText={errorsAddressInfo.district}
                {...params}
                label={
                  formAddressInfo.district ? "District" : "District" + " *"
                }
              />
            )}
          />
          <TextField
            error={errorsAddressInfo.upozila}
            helperText={errorsAddressInfo.upozila}
            id="upozila"
            label="Upozila"
            variant="outlined"
            fullWidth
            size="small"
            
            value={formAddressInfo.upozila || ""}
            onChange={(e) => {
              onChangeAddressInfo({ name: "upozila", value: e.target.value });
            }}
          />

          <TextField
            error={errorsAddressInfo.roadno ? true : false}
            helperText={errorsAddressInfo.roadno}
            id="roadno"
            label="Road No"
            variant="outlined"
            fullWidth
            size="small"
            value={formAddressInfo.roadno || ""}
            onChange={(e) => {
              onChangeAddressInfo({ name: "roadno", value: e.target.value });
            }}
          />
          <TextField
            error={errorsAddressInfo.houseno ? true : false}
            helperText={errorsAddressInfo.houseno}
            id="houseno"
            label="House No"
            variant="outlined"
            fullWidth
            size="small"
            value={formAddressInfo.houseno || ""}
            onChange={(e) => {
              onChangeAddressInfo({ name: "houseno", value: e.target.value });
            }}
          />
        </div>
      </form>
    </div>
  );
};
export default InstituteAddress;
