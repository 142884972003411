import React, { useEffect, useState } from "react";
import {  Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, endPoint } from "../../../../../constants/ApiConstant/routeConstant";
import { IoAlert, IoCheckmarkSharp } from "react-icons/io5";
import { GrAchievement } from "react-icons/gr";


const QuizInfo = ({ singleHijamaUsers }) => {
 const [quizMarks, setQuizMarks] = useState([]);
 const { token } = useSelector((state) => state.userInfo);
  const {
    hId,
  } = singleHijamaUsers;
  

   const getQuizInfo = async () => {
     const header = {
       headers: {
         Authorization: `Bearer ${token}`,
       },
     };

     await axios
       .get(`${BASE_URL}${endPoint.responseGet}/${hId}`, header)
       .then((res) => {
         if (res?.data?.success) {
           //notify("Data getting successfully","success");
           setQuizMarks(res.data?.data[0]);
         }
       })
       .catch((err) => {
         //console.log(err);
         //notify(err.message,"danger");
       });
  };
   useEffect(() => {
     getQuizInfo();
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
  
  
  //console.log(quizMarks);
  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: { md: "3.33%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoCheckmarkSharp style={{ marginRight: 4 }} />
            Marks
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {quizMarks.sumMark ? quizMarks.sumMark : "Not found"}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "33.33%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <GrAchievement style={{ marginRight: 4 }} />
            Total Marks
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {quizMarks.totalMark ? quizMarks.totalMark : "Not found"}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "33.33%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoAlert style={{ marginRight: 4 }} />
            Attempt Times
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {quizMarks.attemptTimes ? quizMarks.attemptTimes : "Not found"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default QuizInfo;
