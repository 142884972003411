import React from "react";
import {  Typography, Box } from "@mui/material";
import {
  AiOutlineSafetyCertificate,
} from "react-icons/ai";
import {
  MdOutlinePlace,
} from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { BiIdCard } from "react-icons/bi";
import { IoIdCardOutline } from "react-icons/io5";
import { FaAddressCard, FaRegAddressCard } from "react-icons/fa";

const BasicInfo = ({ singleRegisteredStudent }) => {
  const {
      userId,
      userPhoneId ,
      userName ,
      oInstitute ,
      oDivision ,
      oDistrict ,
      oDetails_address ,
  } = singleRegisteredStudent;

//console.log(singleRegisteredStudent);
  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiIdCard style={{ marginRight: 4 }} />
            ID
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {userId}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoIdCardOutline style={{ marginRight: 4 }} />
            Name
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {userName}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineSafetyCertificate style={{ marginRight: 4 }} />
            Institution
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {oInstitute}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaAddressCard style={{ marginRight: 4 }} />
            Address
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {oDetails_address}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", my: 5, flexWrap:'wrap' }}>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FiPhoneCall style={{ marginRight: 4 }} />
            Phone
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {userPhoneId}
          </Typography>
        </Box>

        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaRegAddressCard style={{ marginRight: 4 }} />
            Division
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {oDivision}
          </Typography>
        </Box>
        <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              display: "flex",
              alignItems: "center",
            }}
          >
            <MdOutlinePlace style={{ marginRight: 4 }} />
            District
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            {oDistrict}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BasicInfo;
