export const InstSearchOptions = [
  {
    id: 1,
    key: "insName",
    label: "Institute Name",
    placeHolder: "Search by institute Name",
    selected: false,
  },
  {
    id: 2,
    key: "insId",
    label: "Institute ID",
    placeHolder: "Search by institute ID",
    selected: false,
  },
];


export const AppointmentSearchOptions = [
  {
    id: 1,
    key: "apId",
    label: "Appointment ID",
    placeHolder: "Search by appointment ID",
    selected: false,
  },
  {
    id: 2,
    key: "pId",
    label: "Patient ID",
    placeHolder: "Search by Patient ID",
    selected: false,
  },
  {
    id: 3,
    key: "pName",
    label: "Patient Name",
    placeHolder: "Search by Patient Name",
    selected: false,
  },
  {
    id: 4,
    key: "pDOB",
    label: "Patient DOB",
    placeHolder: "Search by Patient Date of Birth",
    selected: false,
  },
];

export const PatientSearchOptions = [
  {
    id: 1,
    key: "pId",
    label: "Patient ID",
    placeHolder: "Search by Patient ID",
    selected: false,
  },
  {
    id: 2,
    key: "pName",
    label: "Patient Name",
    placeHolder: "Search by Patient Name",
    selected: false,
  },
  {
    id: 3,
    key: "pDoB",
    label: "Patient DOB",
    placeHolder: "Search by Patient Date of Birth",
    selected: false,
  },
];
