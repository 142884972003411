import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import { BsCircle } from "react-icons/bs";
import Loader from "../../../../assets/loader.gif";
import {
  BASE_URL,
  endPoint,
} from "../../../../constants/ApiConstant/routeConstant";
import { useSelector } from "../../../../redux/store";
import { useToken } from "../../../../services/useAuth";
import AppCardContainer from "../../../ui/AppCardContainer";
import Button from "../View/Button";

const View = () => {
  const { deleteSuccess } = useSelector((state) => state.modal);
  const [quizes, setQuizes] = useState([]);
  const [, setError] = useState();
  const [loading, setLoading] = useState();

  const token = useToken();
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getData = async () => {
    setLoading(true);

    await axios
      .get(`${BASE_URL}${endPoint.quizGet}`, header)
      .then((res) => {
        setLoading(false);

        if (res?.data?.success) {
          setQuizes(res.data?.data);
        }
      })
      .catch((err) => {
        setLoading(false);

        setError(err.response.data.message);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  return (
    <AppCardContainer spacing="xl:mt-10 mt-20 mx-5 xl:mx-0" classes="max-h-[75vh] overflow-scroll ">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={Loader} style={{ width: "20%" }} alt="" />
        </div>
      ) : (
        quizes.map((quiz, index) => {
          return (
            <Accordion sx={{ mb: 1, bgcolor: "#e8eaf6" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <p className="font-bold">
                    {" "}
                    {index + 1}. {quiz.q_title}
                  </p>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="p-5">
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item sm={10} xs={12}>
                        <div>
                          <div className="md:mt-6 mt-2 flex">
                            <BsCircle className="mt-1" />
                            <p className="pl-3">{quiz.q_op_1}</p>
                          </div>
                          <div className="mt-4 flex">
                            <BsCircle className="mt-1" />
                            <p className="pl-3">{quiz.q_op_2}</p>
                          </div>
                          <div className="mt-4 flex">
                            <BsCircle className="mt-1" />
                            <p className="pl-3">{quiz.q_op_3}</p>
                          </div>
                          <div className="mt-4 flex">
                            <BsCircle className="mt-1" />
                            <p className="pl-3">{quiz.q_op_4}</p>
                          </div>

                          <div className="mt-4 flex">
                            <p className="font-bold">Answer : </p>
                            <p className="pl-3">{quiz.q_ans}</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item sm={1} xs={12} sx={{mr:{xs:2, sm:5}}}>
                        <div className=" flex ">

                        <p className="font-bold">Mark : </p>
                        <p className="pl-3">{quiz.q_mark}</p>
                        </div>
                      </Grid>
                      <Grid item sm={1} xs={12}>
                        <Button quiz={quiz} />
                      </Grid>
                    </Grid>
                  </Box>
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })
      )}
    </AppCardContainer>
  );
};

export default View;
