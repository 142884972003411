import { Image, Text, View } from '@react-pdf/renderer';
import React from 'react';
import DoctorImage from './Images/Doctor.png';
import Logo from './Images/omphdLogo.png';
import { styles } from './pdfCss';
const Header = ({addPress}) => {
  return (
    <View style={styles.section1} >

    <View >
      <View >
          <Image src={DoctorImage} style={styles.docImage}  />
          
          <Text style={styles.basicText} >Doctor ID:{addPress?.dId}</Text>
      </View>
      <View  >
          <Text style={styles.basicText}  >{addPress?.appointment?.dName}</Text>
          <Text style={styles.basicText} >{addPress?.appointment?.dDegree}</Text>
          <Text style={styles.basicText} >{addPress?.appointment?.dEducation}</Text>
      </View>
    </View>

    <View>
      
      <Image src={Logo} style={styles.logoImage} />
    
      <Text style={styles.textCenterHeading}>OMPDH</Text>
      <Text style={styles.basicTextRight} >Omphdofficial@gmail.com</Text>
      <Text style={styles.basicTextRight}>101 Holy View, Kanishail Road,</Text>
      <Text style={styles.basicTextRight}>Shamimabad, Sylhet</Text>
      <Text style={styles.basicTextRight}>COO- 01614312153, CFO- 0161431215</Text>
    </View>
  </View>
  )
}

export default Header