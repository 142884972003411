import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BASE_URL, endPoint } from '../../../constants/ApiConstant/routeConstant';
import { convertedPrascriptionData } from './helper/convertedPrascriptionData';
import Prescription from './Prescription';

const PrescriptionContainer = ({id}) => {
    const [prescriptionData, setPrescriptionData] = useState([]);
    const { token, userInfo } = useSelector((state) => state.userInfo);
   const getAllPrescriptionData = async () => {
     const body = {
       apId: id,
     };
     //console.log(body)
     const header = {
       headers: {
         Authorization: `Bearer ${token}`,
       },
     };
     await axios
       .post(`${BASE_URL}${endPoint.prescriptionGet}`, body, header)
       .then((res) => {
         if (res?.data?.message) {
           setPrescriptionData(convertedPrascriptionData(res?.data?.data));
         }
       })
       .catch((err) => {
         //console.log(err);
       });
   };
   useEffect(() => {
     getAllPrescriptionData();
   }, []);
  
  return (
    <>
      {prescriptionData?.map((singlePrescriptionData) => (
        <Prescription
          singlePrescriptionData={singlePrescriptionData}
        />
      ))}
    </>
  );
}

export default PrescriptionContainer