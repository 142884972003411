import React, { useEffect, useState } from "react";
import InstitutionalInfo from "./Forms/InstitutionalInfo";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userTypes } from "../../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint
} from "../../../../constants/ApiConstant/routeConstant";
import { useToken } from "../../../../services/useAuth";
import { valid_mobile } from "../../../../utils/Validation/formValidation";
import AppSteper from "../../../ui/AppSteper";
import { notify } from "../../../ui/helpers/ToastMsg";
import BasicMedicalHistory from "./Forms/BasicMedicalHistory";
import FamilyDetails from "./Forms/FamilyDetails";
import GeneralExamination from "./Forms/GeneralExamination";
import ParticularDetails from "./Forms/ParticularDetails";
import Password from "./Forms/Password";
import Remarks from "./Forms/Remarks";
import SystemicExamination from "./Forms/SystemicExamination";

const Add = () => {
  const [ind, setInd] = useState(0);
  const navigate = useNavigate();

  const [studentInfo, setStudentInfo] = useState({});
  const [formInstitutionalInfo, setFormInstitutionalInfo] = useState({});
  const [errorsInstitutionalInfo, setErrorsInstitutionalInfo] = useState({});

  const [formParticularDetails, setFormParticularDetails] = useState({});
  const [errorsParticularDetails, setErrorsParticularDetails] = useState({});

  const [formFamilyDetails, setFormFamilyDetails] = useState({});
  const [errorsFamilyDetails, setErrorsFamilyDetails] = useState({});

  const [formBasicMedicalHistory, setFormBasicMedicalHistory] = useState({});
  const [errorsBasicMedicalHistory, setErrorsBasicMedicalHistory] = useState(
    {}
  );

  const [formGeneralExamination, setFormGeneralExamination] = useState({});
  const [errorsGeneralExamination, setErrorsGeneralExamination] = useState({});

  const [formSystemicExamination, setFormSystemicExamination] = useState({});
  const [errorsSystemicExamination, setErrorsSystemicExamination] = useState(
    {}
  );

  const [formRemarks, setFormRemarks] = useState({});
  const [errorsRemarks, setErrorsRemarks] = useState({});

  const [formPassword, setFormPassword] = useState({});
  const [errorsPassword, setErrorsPassword] = useState({});

  const [institutions,setInstitutions]= useState([]);
  const [institutionsError,setInstitutionsError]= useState();
  const [loading,setLoading]=useState();
 
  const token = useToken()
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getData = async () => {
    const body = {
      profileType: userTypes.schoolAuthority,
    };
    
    await axios
      .post(`${BASE_URL}${endPoint.userGet}`, body, header)
      .then((res) => {
        
          if (res?.data?.success) {
            setInstitutions(res.data?.data)
          }
          
      })
      .catch((err) => {
        
        setInstitutionsError(err.response.data.message)     
      });
  };

  const onSubmit = async()=>{
    const insId = institutions.find((el)=> el.ins_name===studentInfo.educationalInstitution).ins_id;
    const classesIns = institutions.find((el)=> el.ins_id===insId).classes;
    const classId =  classesIns.find((el)=> el.ins_class_name===studentInfo.class).ins_class_id;
    const bodyData = 
    {
      profileType: userTypes.schoolHealth,
      data: {
        phone: studentInfo.phone,
        name: studentInfo.name,
        pass: studentInfo.password,
        image: "",
        schoolHealth: "yes",
      },
      profileData: {
        sId:studentInfo.studentId,
        insId: insId,
        classId: classId,
        sName: studentInfo.name,
        section: studentInfo.section,
        roll: studentInfo.roll,
        session: studentInfo.session,
        dob: studentInfo.dateOfBirth,
        bloodGrp: studentInfo.bloodGroup,
        gender: studentInfo.gender,
        religion: studentInfo.religion,
        pAddress: studentInfo.permanentAddress,
        tAddress: studentInfo.presentAddress,
        houseType: studentInfo.houseType,
        houseConType: studentInfo.otherHouseType,
        fatherName: studentInfo.fatherName,
        motherName: studentInfo.motherName,
        fatherProf: studentInfo.fatherProfession,
        motherProf: studentInfo.motherProfession,
        totalSiblings: studentInfo.noOfSiblings,
        siblingDetails: studentInfo.detailsOfSiblings
          ? studentInfo.detailsOfSiblings
          : "",
        prevIllness: studentInfo.previousIllness,
        drugHistory: studentInfo.medicationHistory,
        vitalSign: "",
        bp: studentInfo.bloodPressure+" mmHg",
        pulse: studentInfo.pulse+" bpm",
        temp: studentInfo.temperature+" °F",
        height: studentInfo.height,
        weight: studentInfo.weight,
        bmi: studentInfo.bmi,
        cvIe: studentInfo.leftEye,
        cvRe: studentInfo.rightEye,
        vaIf: studentInfo.farvisionLeftEye,
        vaIn: studentInfo.nearvisionLeftEye,
        vaRf: studentInfo.farvisionRightEye,
        vaRn: studentInfo.nearvisionRightEye,
        vaBf: studentInfo.farvisionBothEye,
        vaBn: studentInfo.nearvisionBothEye,
        vaCmnt: studentInfo.vaComment ? studentInfo.vaComment : "",
        appearance: studentInfo.appearance,
        bodyBuilt: studentInfo.bodyBuilt,
        anemia: studentInfo.anemia,
        jaundice: studentInfo.jaundice,
        cyanosis: studentInfo.cyanosis,
        nail: studentInfo.nailCondition,
        ear: studentInfo.ear,
        throat: studentInfo.throat,
        nose: studentInfo.nose,
        limbs: studentInfo.limbs,
        skin: studentInfo.generalSkinCondition,
        lymph: studentInfo.lymphNodes,
        others: studentInfo.others,
        resSys: studentInfo.respiratorySystem,
        carSys: studentInfo.cardiovascularSystem,
        oralSys: studentInfo.oralAndAbdominalSystem,
        nervousSys: studentInfo.nervousSystem,
        muscuSys: studentInfo.musculoskeletalSystem,
        uroSys: studentInfo.urogenitalSystem,
        diagnosis: studentInfo.diagnosis,
        treatment: studentInfo.treatment,
        referral: studentInfo.referral ? studentInfo.referral : "",
        followUp: "",
        healthStatus: studentInfo.healthStatus,
        checkupdate: studentInfo.startDate,
        dataEntryDate: studentInfo.dataEntryDate,
      },
    }
    //console.log(bodyData)
    setLoading(true)
     await axios
      .post(`${BASE_URL}${endPoint.user}`, bodyData, header)
      .then((res) => {
          setLoading(false)
          if (res?.data?.success) {
            //console.log(res.data?.data)
            notify("Student Added Successfully!", "success")
            navigate("/healthcare/students/view");
          }
      })
      .catch((err) => {
        setLoading(false)
        //console.log(err)  
        notify(err.response.data?.message,"warning")  
      });
  };

  useEffect(() => {
    getData();
  }, []);
  ////console.log(institutions)

  //InstitutionalInfo on Change Validation
  const onChangeInstitutionalInfo = ({ name, value }) => {
    setFormInstitutionalInfo({ ...formInstitutionalInfo, [name]: value });
    if (name === "educationalInstitution") {
      if (value.length === 0) {
        setErrorsInstitutionalInfo((prev) => {
          return {
            ...prev,
            [name]: "Educational Institution  can't be blank!",
          };
        });
      } else {
        setErrorsInstitutionalInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "studentId") {
      if (value.length === 0) {
        setErrorsInstitutionalInfo((prev) => {
          return {
            ...prev,
            [name]: "Student ID  can't be blank!",
          };
        });
      } else {
        setErrorsInstitutionalInfo((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
  };

  // InstituteInfo on submit validation
  const onSubmitInstitutionalInfo = () => {
    if (!formInstitutionalInfo.educationalInstitution) {
      setErrorsInstitutionalInfo((prev) => {
        return { ...prev, educationalInstitution: "Please Select Institution" };
      });
    }
    if (!formInstitutionalInfo.studentId) {
      setErrorsInstitutionalInfo((prev) => {
        return { ...prev, studentId: "Please insert Student ID" };
      });
    }

    if (
      formInstitutionalInfo.educationalInstitution && formInstitutionalInfo.studentId &&
      Object.values(errorsInstitutionalInfo).every((item) => !item)
    ) {
      setInd((prev) => prev + 1);
    }
  };

  //ParticularDetails on Change Validation
  const onChangeParticularDetails = ({ name, value }) => {
    setFormParticularDetails({ ...formParticularDetails, [name]: value });
    if (name === "name") {
      if (value === "") {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: "Name can't be blank!",
          };
        });
      } else {
        setErrorsParticularDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "class") {
      if (value === "") {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: "Class can't be blank!",
          };
        });
      } else {
        setErrorsParticularDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "bloodGroup") {
      if (value === "") {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: "Blood Group can't be blank!",
          };
        });
      } else {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "phone") {
      if (value === "") {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: "Phone number can't be blank!",
          };
        });
      } else if (!valid_mobile(value)) {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: "Phone Number format invalid!",
          };
        });
      } else {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: null,
          };
        });
      }
    }
    if (name === "section") {
      if (value === "") {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: "Section can't be blank!",
          };
        });
      } else {
        setErrorsParticularDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "roll") {
      if (value === "") {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: "Roll can't be blank!",
          };
        });
      } else if (value < 0) {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: "Roll must be Positive",
          };
        });
      } else {
        setErrorsParticularDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "session") {
      if (value === "") {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: "Session can't be blank!",
          };
        });
      } else {
        setErrorsParticularDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "dateOfBirth") {
      if (value === "") {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: "Date of Birth can't be blank!",
          };
        });
      } else {
        setErrorsParticularDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "gender") {
      if (value === null) {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: "Null can't be blank!",
          };
        });
      } else {
        setErrorsParticularDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "religion") {
      if (value === null) {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: "Religion can't be blank!",
          };
        });
      } else {
        setErrorsParticularDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "permanentAddress") {
      if (value === "") {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: "Permanent Address can't be blank!",
          };
        });
      } else {
        setErrorsParticularDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "presentAddress") {
      if (value === "") {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: "Present Address can't be blank!",
          };
        });
      } else {
        setErrorsParticularDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "houseType") {
      if (value === null) {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: "House Type can't be blank!",
          };
        });
      } else {
        setErrorsParticularDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "otherHouseType") {
      if (value === null) {
        setErrorsParticularDetails((prev) => {
          return {
            ...prev,
            [name]: "Other House Type can't be blank!",
          };
        });
      } else {
        setErrorsParticularDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
  };
  // Particular Details on submit validation
  const onSubmitParticularDetails = () => {
    if (!formParticularDetails.name) {
      setErrorsParticularDetails((prev) => {
        return { ...prev, name: "Please Insert Name" };
      });
    }

    if (!formParticularDetails.class) {
      setErrorsParticularDetails((prev) => {
        return { ...prev, class: "Please Select Class" };
      });
    }

    if (!formParticularDetails.bloodGroup) {
      setErrorsParticularDetails((prev) => {
        return { ...prev, bloodGroup: "Please Insert Blood Group" };
      });
    }

    if (!formParticularDetails.phone) {
      setErrorsParticularDetails((prev) => {
        return { ...prev, phone: "Please Insert Phone Number" };
      });
    }
    if (!formParticularDetails.section) {
      setErrorsParticularDetails((prev) => {
        return { ...prev, section: "Please Insert Section" };
      });
    }

    if (!formParticularDetails.roll) {
      setErrorsParticularDetails((prev) => {
        return { ...prev, roll: "Please Insert Roll" };
      });
    }

    if (!formParticularDetails.session) {
      setErrorsParticularDetails((prev) => {
        return { ...prev, session: "Please Insert Session" };
      });
    }

    if (!formParticularDetails.dateOfBirth) {
      setErrorsParticularDetails((prev) => {
        return { ...prev, dateOfBirth: "Please Insert Date of Birth" };
      });
    }

    if (!formParticularDetails.gender) {
      setErrorsParticularDetails((prev) => {
        return { ...prev, gender: "Please Select Gender" };
      });
    }

    if (!formParticularDetails.religion) {
      setErrorsParticularDetails((prev) => {
        return { ...prev, religion: "Please Select Religion" };
      });
    }

    if (!formParticularDetails.permanentAddress) {
      setErrorsParticularDetails((prev) => {
        return { ...prev, permanentAddress: "Please Insert Permanent Address" };
      });
    }

    if (!formParticularDetails.presentAddress) {
      setErrorsParticularDetails((prev) => {
        return { ...prev, presentAddress: "Please Insert Present Address" };
      });
    }

    if (!formParticularDetails.houseType) {
      setErrorsParticularDetails((prev) => {
        return { ...prev, houseType: "Please Select House Type" };
      });
    }

    if (!formParticularDetails.otherHouseType) {
      setErrorsParticularDetails((prev) => {
        return {
          ...prev,
          otherHouseType: "Please Select House Construction Type",
        };
      });
    }

    if (
      Object.values(formParticularDetails).length > 13 &&
      Object.values(formParticularDetails).filter(
        (item) => item.trim().length > 0
      ).length > 13 &&
      Object.values(errorsParticularDetails).every((item) => !item)
    ) {
      ////console.log(formParticularDetails)
      setInd((prev) => prev + 1);
    }
  };

  //FamilyDetails on Change Validation
  const onChangeFamilyDetails = ({ name, value }) => {
    setFormFamilyDetails({ ...formFamilyDetails, [name]: value });

    if (name === "fatherName") {
      if (value === "") {
        setErrorsFamilyDetails((prev) => {
          return {
            ...prev,
            [name]: "Father Name can't be blank!",
          };
        });
      } else {
        setErrorsFamilyDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "fatherProfession") {
      if (value === "") {
        setErrorsFamilyDetails((prev) => {
          return {
            ...prev,
            [name]: "Father Profession can't be blank!",
          };
        });
      } else {
        setErrorsFamilyDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "motherName") {
      if (value === "") {
        setErrorsFamilyDetails((prev) => {
          return {
            ...prev,
            [name]: "Mother Profession can't be blank!",
          };
        });
      } else {
        setErrorsFamilyDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "motherProfession") {
      if (value === "") {
        setErrorsFamilyDetails((prev) => {
          return {
            ...prev,
            [name]: "Mother Profession can't be blank!",
          };
        });
      } else {
        setErrorsFamilyDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "noOfSiblings") {
      if (value === "") {
        setErrorsFamilyDetails((prev) => {
          return {
            ...prev,
            [name]: "Number Of Siblings can't be blank!",
          };
        });
      } else if (value < 0) {
        setErrorsFamilyDetails((prev) => {
          return {
            ...prev,
            [name]: "Number must be Positive",
          };
        });
      } else {
        setErrorsFamilyDetails((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
  };

  // Family Details on submit validation
  const onSubmitFamilyDetails = () => {
    if (!formFamilyDetails.fatherName) {
      setErrorsFamilyDetails((prev) => {
        return { ...prev, fatherName: "Please Insert Father Name" };
      });
    }
    if (!formFamilyDetails.fatherProfession) {
      setErrorsFamilyDetails((prev) => {
        return { ...prev, fatherProfession: "Please Insert Father Profession" };
      });
    }
    if (!formFamilyDetails.motherName) {
      setErrorsFamilyDetails((prev) => {
        return { ...prev, motherName: "Please Insert Mother Name" };
      });
    }
    if (!formFamilyDetails.motherProfession) {
      setErrorsFamilyDetails((prev) => {
        return { ...prev, motherProfession: "Please Insert Mother Profession" };
      });
    }
    if (!formFamilyDetails.noOfSiblings) {
      setErrorsFamilyDetails((prev) => {
        return { ...prev, noOfSiblings: "Please Insert No of siblings" };
      });
    }

    if (
      Object.values(formFamilyDetails).length > 4 &&
      Object.values(formFamilyDetails).filter((item) => item.trim().length > 0)
        .length > 4 &&
      Object.values(errorsFamilyDetails).every((item) => !item)
    ) {
      setInd((prev) => prev + 1);
    }
  };

  //BasicMedicalHistory on Change Validation
  const onChangeBasicMedicalHistory = ({ name, value }) => {
    setFormBasicMedicalHistory({ ...formBasicMedicalHistory, [name]: value });
    if (name === "previousIllness") {
      if (value === "") {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Previous Illness can't be blank!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "medicationHistory") {
      if (value === "") {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Medication History can't be blank!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "vitalSigns") {
      if (value === "") {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Vital Signs can't be blank!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "bloodPressure") {
      if (value === "") {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Blood Pressure can't be blank!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "pulse") {
      if (value === "") {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Pulse can't be blank!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "temperature") {
      if (value === "") {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Temperature can't be blank!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "heightFts") {
      if (value === "" || value <= 0) {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Height (Fts) can't be blank!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "heightInches") {
      if (value === "" || value < 0) {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Height (Inches) can't be blank!",
          };
        });
      }else if (value >=12) {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Inches can't be greater than or equal to 12!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "weight") {
      if (value === "" || value <= 0) {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Weight can't be blank or negative!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "leftEye") {
      if (value === null) {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "left Eye can't be blank!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "rightEye") {
      if (value === null) {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Right Eye can't be blank!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "farvisionLeftEye") {
      if (value === "") {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Farvision Left Eye can't be blank!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "nearvisionLeftEye") {
      if (value === "") {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Nearvision Left Eye can't be blank!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "farvisionRightEye") {
      if (value === "") {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Farvision Right Eye can't be blank!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "nearvisionRightEye") {
      if (value === "") {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Nearision Right Eye can't be blank!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "farvisionBothEye") {
      if (value === "") {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Farvision Both Eye can't be blank!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "nearvisionBothEye") {
      if (value === "") {
        setErrorsBasicMedicalHistory((prev) => {
          return {
            ...prev,
            [name]: "Nearvision Both Eye can't be blank!",
          };
        });
      } else {
        setErrorsBasicMedicalHistory((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
  };
  // Basic Medical History on submit validation
  const onSubmitBasicMedicalHistory = () => {
    if (!formBasicMedicalHistory.previousIllness) {
      setErrorsBasicMedicalHistory((prev) => {
        return { ...prev, previousIllness: "Please Insert Previous Illness" };
      });
    }
    if (!formBasicMedicalHistory.medicationHistory) {
      setErrorsBasicMedicalHistory((prev) => {
        return {
          ...prev,
          medicationHistory: "Please Insert Medication History",
        };
      });
    }
    if (!formBasicMedicalHistory.bloodPressure) {
      setErrorsBasicMedicalHistory((prev) => {
        return { ...prev, bloodPressure: "Please Insert Blood Pressure" };
      });
    }
    if (!formBasicMedicalHistory.pulse) {
      setErrorsBasicMedicalHistory((prev) => {
        return { ...prev, pulse: "Please Insert Pulse" };
      });
    }
    if (!formBasicMedicalHistory.temperature) {
      setErrorsBasicMedicalHistory((prev) => {
        return { ...prev, temperature: "Please Insert Temperature" };
      });
    }
    if (!formBasicMedicalHistory.heightFts) {
      setErrorsBasicMedicalHistory((prev) => {
        return { ...prev, heightFts: "Please Insert Height in Feet" };
      });
    }
    if (!formBasicMedicalHistory.heightInches) {
      setErrorsBasicMedicalHistory((prev) => {
        return { ...prev, heightInches: "Please Insert Height in Inches" };
      });
    }
    if (!formBasicMedicalHistory.weight) {
      setErrorsBasicMedicalHistory((prev) => {
        return { ...prev, weight: "Please Insert weight" };
      });
    }
    if (!formBasicMedicalHistory.leftEye) {
      setErrorsBasicMedicalHistory((prev) => {
        return { ...prev, leftEye: "Please Select Left Eye" };
      });
    }
    if (!formBasicMedicalHistory.rightEye) {
      setErrorsBasicMedicalHistory((prev) => {
        return { ...prev, rightEye: "Please Select Right Eye" };
      });
    }
    if (!formBasicMedicalHistory.farvisionLeftEye) {
      setErrorsBasicMedicalHistory((prev) => {
        return {
          ...prev,
          farvisionLeftEye: "Please Insert far vision of left eye",
        };
      });
    }
    if (!formBasicMedicalHistory.nearvisionLeftEye) {
      setErrorsBasicMedicalHistory((prev) => {
        return {
          ...prev,
          nearvisionLeftEye: "Please Insert near vision of left eye",
        };
      });
    }
    if (!formBasicMedicalHistory.farvisionRightEye) {
      setErrorsBasicMedicalHistory((prev) => {
        return {
          ...prev,
          farvisionRightEye: "Please Insert far vision of right eye",
        };
      });
    }
    if (!formBasicMedicalHistory.nearvisionRightEye) {
      setErrorsBasicMedicalHistory((prev) => {
        return {
          ...prev,
          nearvisionRightEye: "Please Insert near vision of right eye",
        };
      });
    }
    if (!formBasicMedicalHistory.farvisionBothEye) {
      setErrorsBasicMedicalHistory((prev) => {
        return {
          ...prev,
          farvisionBothEye: "Please Insert far vision of Both Eye",
        };
      });
    }

    if (!formBasicMedicalHistory.nearvisionBothEye) {
      setErrorsBasicMedicalHistory((prev) => {
        return {
          ...prev,
          nearvisionBothEye: "Please Insert near vision of Both Eye",
        };
      });
    }

    if (
      Object.values(formBasicMedicalHistory).length > 15 &&
      Object.values(formBasicMedicalHistory).filter(
        (item) => item.trim().length > 0
      ).length > 15 &&
      Object.values(errorsBasicMedicalHistory).every((item) => !item)
    ) {
      setInd((prev) => prev + 1);
    }
  };

  //GeneralExamination on Change Validation
  const onChangeGeneralExamination = ({ name, value }) => {
    setFormGeneralExamination({ ...formGeneralExamination, [name]: value });

    if (name === "appearance") {
      if (value === null) {
        setErrorsGeneralExamination((prev) => {
          return {
            ...prev,
            [name]: "appearance can't be blank!",
          };
        });
      } else {
        setErrorsGeneralExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "bodyBuilt") {
      if (value === null) {
        setErrorsGeneralExamination((prev) => {
          return {
            ...prev,
            [name]: "bodyBuilt can't be blank!",
          };
        });
      } else {
        setErrorsGeneralExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "anemia") {
      if (value === null) {
        setErrorsGeneralExamination((prev) => {
          return {
            ...prev,
            [name]: "anemia can't be blank!",
          };
        });
      } else {
        setErrorsGeneralExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "jaundice") {
      if (value === null) {
        setErrorsGeneralExamination((prev) => {
          return {
            ...prev,
            [name]: "jaundice can't be blank!",
          };
        });
      } else {
        setErrorsGeneralExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "cyanosis") {
      if (value === null) {
        setErrorsGeneralExamination((prev) => {
          return {
            ...prev,
            [name]: "cyanosis can't be blank!",
          };
        });
      } else {
        setErrorsGeneralExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "nailCondition") {
      if (value === null) {
        setErrorsGeneralExamination((prev) => {
          return {
            ...prev,
            [name]: "nailCondition can't be blank!",
          };
        });
      } else {
        setErrorsGeneralExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "ear") {
      if (value === "") {
        setErrorsGeneralExamination((prev) => {
          return {
            ...prev,
            [name]: "Ear can't be blank!",
          };
        });
      } else {
        setErrorsGeneralExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "nose") {
      if (value === "") {
        setErrorsGeneralExamination((prev) => {
          return {
            ...prev,
            [name]: "Nose can't be blank!",
          };
        });
      } else {
        setErrorsGeneralExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "throat") {
      if (value === "") {
        setErrorsGeneralExamination((prev) => {
          return {
            ...prev,
            [name]: "Throat can't be blank!",
          };
        });
      } else {
        setErrorsGeneralExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "limbs") {
      if (value === "") {
        setErrorsGeneralExamination((prev) => {
          return {
            ...prev,
            [name]: "Limbs System can't be blank!",
          };
        });
      } else {
        setErrorsGeneralExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "generalSkinCondition") {
      if (value === "") {
        setErrorsGeneralExamination((prev) => {
          return {
            ...prev,
            [name]: "General Skin Condition can't be blank!",
          };
        });
      } else {
        setErrorsGeneralExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "lymphNodes") {
      if (value === "") {
        setErrorsGeneralExamination((prev) => {
          return {
            ...prev,
            [name]: "Lymph Nodes System can't be blank!",
          };
        });
      } else {
        setErrorsGeneralExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
  };
  // General Examination on submit validation
  const onSubmitGeneralExamination = () => {
    if (!formGeneralExamination.appearance) {
      setErrorsGeneralExamination((prev) => {
        return { ...prev, appearance: "Please Select Appearance" };
      });
    }
    if (!formGeneralExamination.bodyBuilt) {
      setErrorsGeneralExamination((prev) => {
        return { ...prev, bodyBuilt: "Please Select Body Built" };
      });
    }
    if (!formGeneralExamination.anemia) {
      setErrorsGeneralExamination((prev) => {
        return { ...prev, anemia: "Please Select Anemia" };
      });
    }
    if (!formGeneralExamination.jaundice) {
      setErrorsGeneralExamination((prev) => {
        return { ...prev, jaundice: "Please Select Jaundice" };
      });
    }
    if (!formGeneralExamination.cyanosis) {
      setErrorsGeneralExamination((prev) => {
        return { ...prev, cyanosis: "Please Select Cyanosis" };
      });
    }
    if (!formGeneralExamination.nailCondition) {
      setErrorsGeneralExamination((prev) => {
        return { ...prev, nailCondition: "Please Select nailCondition" };
      });
    }
    if (!formGeneralExamination.ear) {
      setErrorsGeneralExamination((prev) => {
        return { ...prev, ear: "Please Insert Ear" };
      });
    }
    if (!formGeneralExamination.nose) {
      setErrorsGeneralExamination((prev) => {
        return { ...prev, nose: "Please Insert Nose" };
      });
    }
    if (!formGeneralExamination.throat) {
      setErrorsGeneralExamination((prev) => {
        return { ...prev, throat: "Please Insert Throat" };
      });
    }
    if (!formGeneralExamination.limbs) {
      setErrorsGeneralExamination((prev) => {
        return { ...prev, limbs: "Please Insert Limbs" };
      });
    }
    if (!formGeneralExamination.generalSkinCondition) {
      setErrorsGeneralExamination((prev) => {
        return {
          ...prev,
          generalSkinCondition: "Please Insert General Skin Condition",
        };
      });
    }
    if (!formGeneralExamination.lymphNodes) {
      setErrorsGeneralExamination((prev) => {
        return { ...prev, lymphNodes: "Please Insert Lymph Nodes" };
      });
    }

    if (
      Object.values(formGeneralExamination).length > 11 &&
      Object.values(formGeneralExamination).filter(
        (item) => item.trim().length > 0
      ).length > 11 &&
      Object.values(errorsInstitutionalInfo).every((item) => !item)
    ) {
      setInd((prev) => prev + 1);
    }
  };

  //SystemicExamination on Change Validation
  const onChangeSystemicExamination = ({ name, value }) => {
    setFormSystemicExamination({ ...formSystemicExamination, [name]: value });
    if (name === "respiratorySystem") {
      if (value === "") {
        setErrorsSystemicExamination((prev) => {
          return {
            ...prev,
            [name]: "Respiratory System can't be blank!",
          };
        });
      } else {
        setErrorsSystemicExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "cardiovascularSystem") {
      if (value === "") {
        setErrorsSystemicExamination((prev) => {
          return {
            ...prev,
            [name]: "Cardiovascular System can't be blank!",
          };
        });
      } else {
        setErrorsSystemicExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "oralAndAbdominalSystem") {
      if (value === "") {
        setErrorsSystemicExamination((prev) => {
          return {
            ...prev,
            [name]: "Oral And Abdominal System can't be blank!",
          };
        });
      } else {
        setErrorsSystemicExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "nervousSystem") {
      if (value === "") {
        setErrorsSystemicExamination((prev) => {
          return {
            ...prev,
            [name]: "Nervous System can't be blank!",
          };
        });
      } else {
        setErrorsSystemicExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "musculoskeletalSystem") {
      if (value === "") {
        setErrorsSystemicExamination((prev) => {
          return {
            ...prev,
            [name]: "Musculoskeletal System can't be blank!",
          };
        });
      } else {
        setErrorsSystemicExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "urogenitalSystem") {
      if (value === "") {
        setErrorsSystemicExamination((prev) => {
          return {
            ...prev,
            [name]: "Urogenital System can't be blank!",
          };
        });
      } else {
        setErrorsSystemicExamination((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
  };

  // Systemic examination on submit validation
  const onSubmitSystemicExamination = () => {
    if (!formSystemicExamination.respiratorySystem) {
      setErrorsSystemicExamination((prev) => {
        return {
          ...prev,
          respiratorySystem: "Please Insert Respiratory System",
        };
      });
    }
    if (!formSystemicExamination.cardiovascularSystem) {
      setErrorsSystemicExamination((prev) => {
        return {
          ...prev,
          cardiovascularSystem: "Please Insert Cardiovascular System",
        };
      });
    }
    if (!formSystemicExamination.oralAndAbdominalSystem) {
      setErrorsSystemicExamination((prev) => {
        return {
          ...prev,
          oralAndAbdominalSystem: "Please Insert Oral And Abdominal System",
        };
      });
    }
    if (!formSystemicExamination.nervousSystem) {
      setErrorsSystemicExamination((prev) => {
        return { ...prev, nervousSystem: "Please Insert Nervous System" };
      });
    }
    if (!formSystemicExamination.musculoskeletalSystem) {
      setErrorsSystemicExamination((prev) => {
        return {
          ...prev,
          musculoskeletalSystem: "Please Insert Musculoskeletal System",
        };
      });
    }
    if (!formSystemicExamination.urogenitalSystem) {
      setErrorsSystemicExamination((prev) => {
        return {
          ...prev,
          urogenitalSystem: "Please Insert Urogenital System",
        };
      });
    }

    if (
      Object.values(formSystemicExamination).length > 5 &&
      Object.values(formSystemicExamination).filter(
        (item) => item.trim().length > 0
      ).length > 4 &&
      Object.values(errorsSystemicExamination).every((item) => !item)
    ) {
      setInd((prev) => prev + 1);
    }
  };

  //Remarks on Change Validation
  const onChangeRemarks = ({ name, value }) => {
    setFormRemarks({ ...formRemarks, [name]: value });
    if (name === "diagnosis") {
      if (value === "") {
        setErrorsRemarks((prev) => {
          return {
            ...prev,
            [name]: "Diagnosis can't be blank!",
          };
        });
      } else {
        setErrorsRemarks((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "treatment") {
      if (value === "") {
        setErrorsRemarks((prev) => {
          return {
            ...prev,
            [name]: "Treatment can't be blank!",
          };
        });
      } else {
        setErrorsRemarks((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "startDate") {
      if (value === "") {
        setErrorsRemarks((prev) => {
          return {
            ...prev,
            [name]: "startDate can't be blank!",
          };
        });
      } else {
        setErrorsRemarks((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "dataEntryDate") {
      if (value === "") {
        setErrorsRemarks((prev) => {
          return {
            ...prev,
            [name]: "dataEntryDate can't be blank!",
          };
        });
      } else {
        setErrorsRemarks((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
    if (name === "healthStatus") {
      if (value === "") {
        setErrorsRemarks((prev) => {
          return {
            ...prev,
            [name]: "Health Status can't be blank!",
          };
        });
      } else {
        setErrorsRemarks((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
  };
  // Remarks on submit validation
  const onSubmitRemarks = () => {
    if (!formRemarks.diagnosis) {
      setErrorsRemarks((prev) => {
        return { ...prev, diagnosis: "Please Insert Diagnosis" };
      });
    }
    if (!formRemarks.treatment) {
      setErrorsRemarks((prev) => {
        return { ...prev, treatment: "Please Insert Treatment" };
      });
    }
    if (!formRemarks.startDate) {
      setErrorsRemarks((prev) => {
        return { ...prev, startDate: "Please Select Start Date" };
      });
    }
    if (!formRemarks.dataEntryDate) {
      setErrorsRemarks((prev) => {
        return { ...prev, dataEntryDate: "Please Select Data Entry Date" };
      });
    }
    if (!formRemarks.healthStatus) {
      setErrorsRemarks((prev) => {
        return { ...prev, healthStatus: "Please Insert Health Status" };
      });
    }

    if (
      Object.values(formRemarks).length > 4 &&
      Object.values(formRemarks).filter((item) => item.trim().length > 0)
        .length > 4 &&
      Object.values(errorsRemarks).every((item) => !item)
    ) {
      setInd((prev) => prev + 1);
    }
  };
  //Password on Change Validation
  const onChangePassword = ({ name, value }) => {
    setFormPassword({ ...formPassword, [name]: value });
    if (name === "password") {
      if (value === "") {
        setErrorsPassword((prev) => {
          return { ...prev, [name]: "Password can't be blank!" };
        });
      } else if (value.length < 8) {
        setErrorsPassword((prev) => {
          return {
            ...prev,
            [name]: "Password should be at least 8 characters!",
          };
        });
      } else {
        setErrorsPassword((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }

    if (name === "confirmPassword") {
      if (value === "") {
        setErrorsPassword((prev) => {
          return { ...prev, [name]: "Password can't be blank!" };
        });
      } else if (value.length < 8) {
        setErrorsPassword((prev) => {
          return {
            ...prev,
            [name]: "Password should be at least 8 characters!",
          };
        });
      } else {
        setErrorsPassword((prev) => {
          return { ...prev, [name]: null };
        });
      }
    }
  };

  // Password on submit validation
  const onSubmitPassword = () => {
    if (!formPassword.password) {
      setErrorsPassword((prev) => {
        return { ...prev, password: "Please Insert Password" };
      });
    }

    if (!formPassword.confirmPassword) {
      setErrorsPassword((prev) => {
        return { ...prev, confirmPassword: "Please Insert Confirm Password" };
      });
    }
    if (formPassword.confirmPassword !== formPassword.password) {
      setErrorsPassword((prev) => {
        return {
          ...prev,
          password: "Password doesn't match with confirm Password",
          confirmPassword: "Password doesn't match with confirm Password",
        };
      });
    }

    if (
      Object.values(formPassword).length > 1 &&
      Object.values(formPassword).filter((item) => item.trim().length > 0)
        .length > 1 &&
      Object.values(errorsPassword).every((item) => !item)
    ) {
      //setInd((prev) => prev + 1)
      //upload student info to api route: api/user and "profileType" will be "schoolHealth"
      onSubmit();
    }
  };
  //console.log(studentInfo);
  useEffect(() => {
    setStudentInfo({
      ...formInstitutionalInfo,
      ...formParticularDetails,
      ...formFamilyDetails,
      ...formBasicMedicalHistory,
      ...formGeneralExamination,
      ...formSystemicExamination,
      ...formRemarks,
      ...formPassword
    });
  }, [
    formInstitutionalInfo,
    formParticularDetails,
    formParticularDetails,
    formFamilyDetails,
    formBasicMedicalHistory,
    formGeneralExamination,
    formSystemicExamination,
    formRemarks,
    formPassword
  ]);

  const getNextSt = () => {
    if (ind === 0) {
      return onSubmitInstitutionalInfo();
    } else if (ind === 1) {
      return onSubmitParticularDetails();
    } else if (ind === 2) {
      return onSubmitFamilyDetails();
    } else if (ind === 3) {
      return onSubmitBasicMedicalHistory();
    } else if (ind === 4) {
      return onSubmitGeneralExamination();
    } else if (ind === 5) {
      return onSubmitSystemicExamination();
    } else if (ind === 6) {
      return onSubmitRemarks();
    } else if (ind === 7) {
      return onSubmitPassword();
    }
  };
  return (
    <AppSteper
      firstStep="Institutional Info"
      OtherSteps={[
        "Particular Details",
        "Family Details",
        "Basic Medical History",
        "General Examination",
        "Systemic Examination",
        "Remarks",
        "Password",
      ]}
      ind={ind}
      setInd={setInd}
      getNextSt={getNextSt}
      loading={loading}
    >
      <div>
        {ind === 0 && (
          <InstitutionalInfo
            formInstitutionalInfo={formInstitutionalInfo}
            onChangeInstitutionalInfo={onChangeInstitutionalInfo}
            errorsInstitutionalInfo={errorsInstitutionalInfo}
            institutions={institutions}
            error={institutionsError}
          />
        )}
        {ind === 1 && (
          <ParticularDetails
            formParticularDetails={formParticularDetails}
            errorsParticularDetails={errorsParticularDetails}
            onChangeParticularDetails={onChangeParticularDetails}
            classes={
              institutions
                ? institutions.find(
                    (el) => el.ins_name === studentInfo.educationalInstitution,
                  ).classes
                : []
            }
          />
        )}
        {ind === 2 && (
          <FamilyDetails
            formFamilyDetails={formFamilyDetails}
            errorsFamilyDetails={errorsFamilyDetails}
            onChangeFamilyDetails={onChangeFamilyDetails}
          />
        )}
        {ind === 3 && (
          <BasicMedicalHistory
            formBasicMedicalHistory={formBasicMedicalHistory}
            errorsBasicMedicalHistory={errorsBasicMedicalHistory}
            onChangeBasicMedicalHistory={onChangeBasicMedicalHistory}
            setFormBasicMedicalHistory={setFormBasicMedicalHistory}
          />
        )}
        {ind === 4 && (
          <GeneralExamination
            onChangeGeneralExamination={onChangeGeneralExamination}
            formGeneralExamination={formGeneralExamination}
            setFormGeneralExamination={setFormGeneralExamination}
            errorsGeneralExamination={errorsGeneralExamination}
            setErrorsGeneralExamination={setErrorsGeneralExamination}
          />
        )}
        {ind === 5 && (
          <SystemicExamination
            formSystemicExamination={formSystemicExamination}
            setFormSystemicExamination= {setFormSystemicExamination}
            errorsSystemicExamination={errorsSystemicExamination}
            setErrorsSystemicExamination={setErrorsSystemicExamination}
            onChangeSystemicExamination={onChangeSystemicExamination}
          />
        )}
        {ind === 6 && (
          <Remarks
            onChangeRemarks={onChangeRemarks}
            formRemarks={formRemarks}
            errorsRemarks={errorsRemarks}
          />
        )}

        {ind === 7 && (
          <Password
            onChangePassword={onChangePassword}
            formPassword={formPassword}
            errorsPassword={errorsPassword}
            loading={loading}
          />
        )}
        <ToastContainer />
      </div>
    </AppSteper>
  );
};

export default Add;
