import { Box, Typography } from '@mui/material';
import React from 'react'

import { BsCalendarDate, BsGenderAmbiguous } from "react-icons/bs";

import { AiOutlineAudit, AiOutlineSolution } from "react-icons/ai";
import { MdWorkOutline } from "react-icons/md";
import { FaRegAddressCard, FaRegIdCard } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { GrCertificate } from "react-icons/gr";

const PatientInfo = ({ singleAppointment }) => {
  const {
    pId,
    pName,
    pDob,
    pGender,
    pProfession,
    pAddress,
    pPhone,
    pEmail,
    pNid,
    pBirthCft,
  } = singleAppointment;

  
  return (
    <div className="">
      <Box sx={{ px: 2, py: 1 }}>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <AiOutlineSolution style={{ marginRight: 4 }} />
              Patient Id
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {pId}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <AiOutlineAudit style={{ marginRight: 4 }} />
              Patient Name
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {pName}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <BsCalendarDate style={{ marginRight: 4 }} />
              Date of Birth
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {pDob}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <BsGenderAmbiguous style={{ marginRight: 4 }} />
              Gender
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {pGender}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <MdWorkOutline style={{ marginRight: 4 }} />
              Profession
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {pProfession}
            </Typography>
          </Box>
          <Box sx={{ width: {  xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <FaRegAddressCard style={{ marginRight: 4 }} />
              Address
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {pAddress}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <FiPhoneCall style={{ marginRight: 4 }} />
              Phone
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {pPhone}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <AiOutlineMail style={{ marginRight: 4 }} />
              Email
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {pEmail}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <FaRegIdCard style={{ marginRight: 4 }} />
              NID
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {pNid}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <GrCertificate style={{ marginRight: 4 }} />
              Birth certificate
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {pBirthCft}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default PatientInfo