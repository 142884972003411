import { useState } from "react";
import { endPoint } from "../../../../constants/ApiConstant/routeConstant";
import AppSubNav from "../../../ui/AppSubNav";
import PersonalInfo from "./EventInfoDetails";
import { useParams } from "react-router-dom";
import AppCardContainer from "../../../ui/AppCardContainer";
import AppBredcrumbs from "../../../ui/AppBredcrumbs";
import AppTableActionButton from "../../../ui/AppTableActionButton";
import { useDelete } from "../../../../services/api";

const EventsInfo = () => {
  const { id } = useParams();
  const [selectedNav, setSelectedNav] = useState();
  const { handleDelete } = useDelete(endPoint.eventDelete, id);

  //console.log(id);

  return (
    <AppCardContainer spacing="xl:mt-10 3xl:-mr-16 mx-5 mt-20">
      <div className="flex items-center justify-between">
        <AppBredcrumbs
          link="/olympiad/events/view"
          allTitle="All Events"
          detailsTitle="Events Details"
        />
        <AppTableActionButton
          isView={false}
          editLink={`/olympiad/events/edit/${id}`}
          deleteFunction={() => handleDelete()}
          redirectTo="/olympiad/events/view"
        />
      </div>

      <div className="mt-10">
        <AppSubNav
          navbarData={["Basic Info"]}
          callback={(item) => setSelectedNav(item)}
          type="tab"
          width="w-24"
        />
      </div>

      {selectedNav?.key === "BasicInfo" && <PersonalInfo id={id} />}
    </AppCardContainer>
  );
};

export default EventsInfo;
