import { Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL, endPoint } from '../../../../constants/ApiConstant/routeConstant';
import { useSelector } from '../../../../redux/store';
import { styles } from '../../Common/Constant';
import Item from '../../Common/Item';


const BasicInfo = ({profile}) => {

  const { token } = useSelector((state) => state.userInfo);

  const [watchedVideos, setWatchedVideos] = useState([]);
  const [quizInfo,setQuizInfo] = useState()
  const getWatchedVideosData = async () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .get(`${BASE_URL}${endPoint.videoWatchGet}/${profile.h_id}`, header)
      .then((res) => {
        if (res?.data?.success) {
          setWatchedVideos(res?.data?.data);
          //console.log(res.data.data)
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };


  const getQuizInfoData = async () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .get(`${BASE_URL}${endPoint.responseGet}/${profile.h_id}`, header)
      .then((res) => {
        if (res?.data?.success) {
          setQuizInfo(res?.data?.data);
          //console.log(res.data.data)
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    getWatchedVideosData();
    getQuizInfoData();

  }, []);

  

  return (
    <div style={styles.innerContainer}>
    
    <div style={styles.sectionContainer}>
      <h3 style={styles.subHeader}>  Basic Information </h3>
<Grid container>
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Name"} value={profile.h_name} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Phone"} value={profile.user_phone_id} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Hijama ID"} value={profile.h_id} /> 
  </Grid>

  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Email"} value={profile.h_email}  /> 
  </Grid>

    
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Payment Status"} value={profile.payment_status}  /> 
  </Grid>
  
    
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Course Status"} value={profile.course_status}  /> 
  </Grid>
  
  
</Grid>
    

</div>

{
  watchedVideos.length>0 && (
    <div style={styles.sectionContainer}>
<h3 style={styles.subHeader}>  Watched Videos </h3>
<Grid container>

{
  watchedVideos.map((item)=> (
<Grid item xs={4} style={styles.itemContainer} >
<Item tag={"Video Title"} value={item.vid_title} /> 
</Grid>
  ))
}



</Grid>


</div>
  )
}

{
  quizInfo && (
    <div style={styles.sectionContainer}>
<h3 style={styles.subHeader}>  Quiz Info </h3>
<Grid container>

<Grid item xs={4} style={styles.itemContainer} >
<Item tag={"Mark"} value={quizInfo[0].sumMark} /> 
</Grid>
 

<Grid item xs={4} style={styles.itemContainer} >
<Item tag={"Total Mark"} value={quizInfo[0].totalMark} /> 
</Grid>
 

<Grid item xs={4} style={styles.itemContainer} >
<Item tag={"Attempt Times"} value={quizInfo[0].attemptTimes} /> 
</Grid>
 
 



</Grid>


</div>
  )
}


   </div>
  )
}

export default BasicInfo
