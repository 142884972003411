import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { userTypes } from "../../../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint,
} from "../../../../../constants/ApiConstant/routeConstant";
import { useToken } from "../../../../../services/useAuth";
import AppButton from "../../../../ui/AppButton";
import AppModal from "../../../../ui/AppModal";
import { StudentData } from "../SAStudentsView";

const buttonApply = {
  variant: "primary",
  fullWidth: true,
  size: "xs",
  classes: " text-sm h-11 w-28 font-semibold",
};
const buttonClear = {
  variant: "info",
  fullWidth: true,
  size: "xs",
  classes: " text-sm h-11 w-28 font-semibold mr-2",
};

const FilterData = ({
  studentFilter,
  setStudentFilter,
  handleFilter,
  setClearFilter,
  clearFilter,
}) => {
  const [isShow, setIsShow] = useState(false);
  const [instData, setInstData] = useState([]);
  const [, setLoading] = useState(false);

  const token = useToken();

  //console.log(instData);
  // Category List
  const getInstNameOption = () => {
    let options = [];
    if (studentFilter.insType) {
      const filteredData =
        instData &&
        instData.filter((item) => item?.ins_type === studentFilter.insType);

      filteredData?.filter((elm) =>
        options.push({ value: elm.ins_id, label: elm.ins_name })
      );
    } 
    // else {
    //   instData &&
    //     instData?.filter((elm) =>
    //       options.push({ value: elm.ins_id, label: elm.ins_name })
    //     );
    // }
    return options;
  };

  const getInstIdOption = () => {
    let options = [];
    if (studentFilter.insName) {
      const filteredData =
        instData &&
        instData.filter((item) => item?.ins_name === studentFilter.insName);

      filteredData?.filter((elm) =>
        elm.classes.filter((item) => {
          options.push({
            value: item.ins_class_id,
            label: item.ins_class_name,
          });
        })
      );
    } 
   

    return options;
  };

  const getInstData = async () => {
    const body = {
      profileType: userTypes.schoolAuthority,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    await axios
      .post(`${BASE_URL}${endPoint.userGet}`, body, header)
      .then((res) => {
        setLoading(false);

        if (res?.data?.success) {
          setInstData(res.data?.data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getInstData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AppModal
        isShowModal={isShow}
        callback={(item) => setIsShow(item)}
        option
        modalTitle="Filter"
        modalTitleClass="text-xl"
        modalWidth="md:w-[420px] w-full"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="institueType"
                  options={["School", "College", "Madrasa", "University"]}
                  fullWidth
                  size="small"
                  value={studentFilter.insType || ""}
                  onChange={(event, newValue) => {
                    setStudentFilter({
                      ...studentFilter,
                      insType: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Institution Type" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="institueName"
                  options={getInstNameOption().map((elm) => elm.label)}
                  fullWidth
                  size="small"
                  // value={studentFilter.insName || ""}
                  onChange={(event, newValue) => {
                    setStudentFilter({
                      ...studentFilter,
                      insName: newValue,
                      insId: getInstNameOption().filter(
                        (elm) => elm.label === newValue,
                      )[0].value,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Institution Name" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="institueClasses"
                  options={getInstIdOption().map((elm) => elm.label)}
                  fullWidth
                  size="small"
                  // value={studentFilter.className || ""}
                  onChange={(event, newValue) => {
                    setStudentFilter({
                      ...studentFilter,
                      className: newValue,
                      classId: getInstIdOption().filter(
                        (elm) => elm.label === newValue,
                      )[0].value,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Class Name" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="gender"
                  options={["Male", "Female", "Other"]}
                  fullWidth
                  size="small"
                  value={studentFilter.gender || ""}
                  onChange={(event, newValue) => {
                    setStudentFilter({
                      ...studentFilter,
                      gender: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Gender" />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <div className="mt-52 flex justify-end">
            <AppButton
              {...buttonClear}
              onClick={() => {
                setClearFilter(!clearFilter);
                setStudentFilter(StudentData);
                setIsShow(false);
              }}
            >
              Clear Filter
            </AppButton>
            <AppButton
              {...buttonApply}
              onClick={() => {
                handleFilter();
                setIsShow(false);
              }}
            >
              Apply
            </AppButton>
          </div>
        </Box>
      </AppModal>
    </div>
  );
};

export default FilterData;
