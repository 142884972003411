import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BASE_URL } from "../../../constants/ApiConstant/routeConstant";
import { useSelector } from "../../../redux/store";
import AppCardContainer from "../../ui/AppCardContainer";
import AppSubNav from "../../ui/AppSubNav";
import AddPrescription from "./AddPrescription";
import AppintmentInfo from "./AppintmentInfo";
import Documents from "./Documents";
import GeneralInfo from "./GeneralInfo";
import PatientInfo from "./PatientInfo";
import PrescriptionContainer from "./PrescriptionContainer";
import { tableConvertedData } from "./helper/convertedAppointmentData";

const APDetails = () => {
  const { id } = useParams();
  const { token } = useSelector((state) => state.userInfo);
  const [singleAppointment, setSingleAppointment] = useState({});
  const [selectedNav, setSelectedNav] = useState();
  const getSingleAppointmentData = async () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .get(
        `${BASE_URL}/api/tele/appointment/get/${id}`,
        header,
      )
      .then((res) => {
        if (res?.data?.message) {
          setSingleAppointment(tableConvertedData([res?.data?.data])[0]);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  ////console.log(singleAppointment.documents);

  useEffect(() => {
    getSingleAppointmentData();
  }, []);

  return (
    <AppCardContainer spacing="xl:mt-10 3xl:-mr-16 md:mx-5 mt-20">
      <div className="flex md:flex-row flex-col items-center justify-between">
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <Link to="/appointments">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="w-4 h-4 mr-2 stroke-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                  ></path>
                </svg>
                All Appointments
              </Link>
            </li>
            <li>
              <a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="w-4 h-4 mr-2 stroke-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                  ></path>
                </svg>
                <span className=" font-bold">Appointment Details</span>
              </a>
            </li>
          </ul>
        </div>

        <div>
          <AddPrescription singleAppointment={singleAppointment} />
        </div>
      </div>

      <div className="mt-10">
        <AppSubNav
          navbarData={[
            "General Information",
            "Appointment Info",
            "Patient Info",
            "Documents",
            "Prescription",
          ]}
          callback={(item) => setSelectedNav(item)}
          type="tab"
          width="w-44"
        />
      </div>

      {selectedNav?.key === "GeneralInformation" && (
        <GeneralInfo singleAppointment={singleAppointment} />
      )}
      {selectedNav?.key === "AppointmentInfo" && (
        <AppintmentInfo singleAppointment={singleAppointment} />
      )}
      {selectedNav?.key === "PatientInfo" && (
        <PatientInfo singleAppointment={singleAppointment} />
      )}
      {selectedNav?.key === "Documents" && (
        <Documents singleAppointment={singleAppointment} />
      )}
      {selectedNav?.key === "Prescription" && <PrescriptionContainer id={id} />}
    </AppCardContainer>
  );
};

export default APDetails;
