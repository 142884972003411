import React from 'react'
import { styles } from './Constant'

const Item = ({tag,value}) => {
  return (
    <div style={styles.item}>
  <h4 style={styles.tag}> {tag} </h4>
  <h5 style={styles.value}> {value} </h5>
  </div>  
  )
}

export default Item