import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { HiOutlineBars3BottomLeft } from "react-icons/hi2";
import { ImCross } from "react-icons/im";
import { handleBackgroudZIndexShow } from "../../redux/slices/modalSlice";
import { useDispatch } from "../../redux/store";
import useScrollLock from "../../services/useScrollLock";

const AppModal = ({
  option,
  children,
  modalhead = true,
  modalBtn,
  modalBtnTitle,
  modalTitle,
  modalWidth = "md:min-w-[430px] min-w-[390px]",
  modalTitleClass = "text-2xl",
  isShowModal = false,
  callback,
  btnDisableClass = false,
  showCross = true,
  headerSpeparator = true,
  isNotification = false,
}) => {
  const ref = useRef();
  const { lockScroll, unlockScroll } = useScrollLock();
  const dispatch = useDispatch();
  const portalRef = useRef(null);
  const [mounted, setMounted] = useState(false);

  const handleModalShow = (item) => {
    if (callback) {
      callback(item);
    }
  };

  useEffect(() => {
    dispatch(handleBackgroudZIndexShow(isShowModal));
    if (isShowModal) {
      lockScroll();
    } else {
      unlockScroll();
    }

    return () => {
      unlockScroll();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowModal]);

  useEffect(() => {
    portalRef.current = document.querySelector("#portal");
    setMounted(true);
  }, []);

  return (
    <div>
      <div>
        {option ? (
          <div
            role="button"
            className="bg-blue-600 px-6 py-[10px] rounded-md shadow-md ml-2 cursor-pointer modal-button"
            onClick={() => handleModalShow(true)}
          >
            <HiOutlineBars3BottomLeft className="text-2xl text-white w-full" />
          </div>
        ) : (
          <div onClick={() => handleModalShow(true)}>{modalBtn}</div>
        )}
      </div>
      {mounted && portalRef.current
        ? createPortal(
            <div
              className={`fixed overflow-y-auto top-0 p-5 w-full left-0 ${
                isShowModal ? "" : "hidden"
              } z-[999999999] `}
              id="modal"
              ref={ref}
            >
              <div className="flex items-center justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                  <div className={`absolute inset-0  ${isNotification? 'bg-gray-900/[.2]' :'bg-gray-900/[.6]'} `} />
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                  &#8203;
                </span>
                <div
                  className={`${modalWidth} overflow-y-auto custom-scrollbar  inline-block bg-white border border-base-100/[.2] border-solid rounded-xl  align-center  text-left shadow-xl transform transition-all  sm:align-middle sm:max-w-lg `}
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  {modalhead && (
                    <div
                      className={`p-6  flex items-center justify-between ${
                        headerSpeparator
                          ? "border-solid border-b-[1px] border-base-100/[.2] pb-4"
                          : "pb-0"
                      }  `}
                    >
                      <h4 className={` ${modalTitleClass} font-semibold`}>
                        {modalTitle}
                      </h4>

                      {showCross ? (
                        <div
                          className="text-[#909599] font-light"
                          role="button"
                          tabIndex={0}
                          onClick={() => handleModalShow(false)}
                        >
                          <ImCross className="text-xs font-light" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  <div className="md:p-6 p-3 pt-0 md:max-h-[90vh] max-h-[70vh] overflow-y-auto">
                    {children}
                  </div>
                </div>
              </div>
            </div>,
            portalRef.current
          )
        : null}
    </div>
  );
};

export default AppModal;
