import React,{ useState } from "react";
import { Typography, Box } from "@mui/material";
import { CgNotes } from "react-icons/cg";
import { BsSpeedometer2 } from "react-icons/bs";
import { GiHealthCapsule, GiStethoscope } from "react-icons/gi";
import { HiOutlineScale } from "react-icons/hi2";
import { AiOutlineFieldTime, AiOutlineLink, AiOutlineSolution } from "react-icons/ai";
import { IoPulse } from "react-icons/io5";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";

const AppintmentInfo = ({ singleAppointment }) => {
  const [copy, setCopy] = useState(false);

  const onChangeCopy = async (value) => {
    setCopy(value);
    setTimeout(() => {
      setCopy(!value);
    }, 2000);
  };
  const {
    apId,
    apLink,
    apReqTime,
    apTime,
    apReason,
    status,
  } = singleAppointment;


 return (
   <div className="">
     <Box sx={{ px: 2, py: 1 }}>
       <Box sx={{ display: "flex", flexWrap: "wrap" }}>
         <Box sx={{ width: { md: "33.33%", xs: "100%", sm: "50%" } }}>
           <Typography
             sx={{
               fontWeight: "400",
               fontSize: 14,
               mt: 3,
               display: "flex",
               alignItems: "center",
             }}
           >
             <AiOutlineSolution style={{ marginRight: 4 }} />
             Appointment Id
           </Typography>
           <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
             {apId}
           </Typography>
         </Box>
         <Box sx={{ width: { md: "33.33%", xs: "100%", sm: "50%" },paddingRight: "2px" }}>
           <Typography
             sx={{
               fontWeight: "400",
               fontSize: 14,
               mt: 3,
               display: "flex",
               alignItems: "center",
             }}
           >
             <AiOutlineLink style={{ marginRight: 4 }} />
             Appointment Link {" - "}
             <CopyToClipboard text={apLink}>
               <FaRegCopy
                 className="text-green-800 text-xl  cursor-pointer"
                 onClick={() => onChangeCopy(true)}
               />
             </CopyToClipboard>
             <span className="text-green-500 ml-2">
               {" "}
               {copy ? "Copied!!" : ""}
             </span>
           </Typography>
           <Typography
             sx={{
               fontWeight: "500",
               fontSize: 16,
               mt: 0.5,
               overflow: "hidden",
             }}
           >
             {apLink}
           </Typography>
         </Box>
         <Box sx={{ width: { md: "30%", xs: "100%", sm: "50%" } }}>
           <Typography
             sx={{
               fontWeight: "400",
               fontSize: 14,
               mt: 3,
               display: "flex",
               alignItems: "center",
             }}
           >
             <AiOutlineFieldTime style={{ marginRight: 4 }} />
             Appointment Request Time
           </Typography>
           <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
             <p>Date: {apReqTime ? apReqTime.slice(0, 10) : apReqTime} </p>
             <p>Time : {apReqTime ? apReqTime.slice(11) : apReqTime}</p>
           </Typography>
         </Box>
       </Box>

       <Box sx={{ display: "flex", my: 5, flexWrap:'wrap' }}>
         <Box sx={{ width: { md: "33.33%", xs: "100%", sm: "50%" } }}>
           <Typography
             sx={{
               fontWeight: "400",
               fontSize: 14,

               display: "flex",
               alignItems: "center",
             }}
           >
             <BsSpeedometer2 style={{ marginRight: 4 }} />
             Appointment Time
           </Typography>
           <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
             {apTime}
           </Typography>
         </Box>
         <Box sx={{ width: { md: "33.33%", xs: "100%", sm: "50%" } }}>
           <Typography
             sx={{
               fontWeight: "400",
               fontSize: 14,

               display: "flex",
               alignItems: "center",
             }}
           >
             <GiHealthCapsule style={{ marginRight: 4 }} />
             Appointment
           </Typography>
           <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
             {apReason}
           </Typography>
         </Box>
         <Box sx={{ width: { md: "33.33%", xs: "100%", sm: "50%" } }}>
           <Typography
             sx={{
               fontWeight: "400",
               fontSize: 14,
               display: "flex",
               alignItems: "center",
             }}
           >
             <CgNotes style={{ marginRight: 4 }} />
             Payment Status
           </Typography>
           <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
             {status}
           </Typography>
         </Box>
       </Box>
     </Box>
   </div>
 );
};

export default AppintmentInfo;
