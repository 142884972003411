import React from 'react';

// border-solid border-base-200/[.2]

const getClassNames = ({ size = 'lg', fullWidth, classes }) => {
  let classNames =
    '	 input-bordered input rounded-lg border-black/[0.4] ';
  switch (size) {
    case 'lg':
      classNames += ' px-[32px] py-[14px]';
      break;
    case 'md':
      classNames += ' px-[24px] py-[10px] ';
      break;
    case 'sm':
      classNames += ' px-[18px] py-[8px] ';
      break;
    default:
  }

  if (fullWidth) {
    classNames += ' w-full';
  }
  return `${classNames} ${classes || ''}`;
};

const Input = ({
  type,
  placeHolder,
  classes,
  fullWidth,
  size,
  onChange,
  value,
  ...restProps
}) => {
  return (
    <input
      type={type}
      className={getClassNames({ size, fullWidth, classes })}
      {...restProps}
      placeholder={placeHolder}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    ></input>
  );
};

export default Input;
