import React, { useState } from "react";
import { Typography, Box, TextField, Grid } from "@mui/material";

import { BiIdCard } from "react-icons/bi";

import { IoIdCardOutline } from "react-icons/io5";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect } from "react";
import { useToken } from "../../../../../services/useAuth";
import {
  BASE_URL,
  endPoint,
} from "../../../../../constants/ApiConstant/routeConstant";
import { notify } from "../../../../ui/helpers/ToastMsg";
import axios from "axios";
import { formateDateTime } from "../../../../../utils/Formatter/dateTimeFormater";

const AppoinmentsLink = ({ singleAppointment, getSingleAppointmentData }) => {
  const token = useToken();
  const [formLink, setFormLink] = useState();
  const [formTime, setFormTime] = useState();
  const [success, setSuccess] = useState(false);
  const [copy, setCopy] = useState(false);
  const onChangeCopy = async (value) => {
    setCopy(value);
    setTimeout(() => {
      setCopy(!value);
    }, 2000);
  };

  //console.log(copy);

  const {
    apId,

    apLink,

    apTime,
  } = singleAppointment;

  const onSubmitLink = () => {
    setSuccess(false);
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const bodyData = {
      link: formLink?.link,
    };

    axios
      .put(`${BASE_URL}${endPoint.appointment}/${apId}`, bodyData, header)
      .then((res) => {
        if (res?.data?.success) {
          setSuccess(true);
          //console.log(res.data?.data);
          notify("Link Added Successfully!", "success");
        }
      })
      .catch((err) => {
        //console.log(err);
        notify(err.response.data?.message, "warning");
      });
  };

  const onChangeLink = ({ name, value }) => {
    setFormLink({ ...formLink, [name]: value });
  };

  const onSubmitTime = () => {
    setSuccess(false);
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const bodyData = {
      time: formTime?.time,
    };

    axios
      .put(`${BASE_URL}${endPoint.appointment}/${apId}`, bodyData, header)
      .then((res) => {
        if (res?.data?.success) {
          //console.log(res.data?.data);
          setSuccess(true);
          notify("Time Added Successfully!", "success");
        }
      })
      .catch((err) => {
        //console.log(err);
        notify(err.response.data?.message, "warning");
      });
  };

  const onChangeTime = ({ name, value }) => {
    setFormTime({ ...formTime, [name]: formateDateTime(value) });
  };

  //console.log(formTime);

  useEffect(() => {
    getSingleAppointmentData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiIdCard style={{ marginRight: 4 }} />
            Link
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            <div>
              <div className="flex  ">
                <p className=" font-bold mr-5 h-6 overflow-hidden w-[15rem]">
                  {apLink}
                </p>

                <CopyToClipboard text={apLink}>
                  <FaRegCopy
                    className="text-green-800 text-xl  cursor-pointer"
                    onClick={() => onChangeCopy(true)}
                  />
                </CopyToClipboard>

                <span className="text-green-500 ml-2">
                  {" "}
                  {copy ? "Copied!!" : ""}
                </span>
              </div>

              <div className="flex mt-4">
                <Grid item xs={12}>
                  {" "}
                  <TextField
                    id="link"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={formLink?.link || ""}
                    label="Create Appoinment Link"
                    onChange={(e) => {
                      onChangeLink({
                        name: "link",
                        value: e.target.value,
                      });
                    }}
                  />
                </Grid>

                <button
                  className=" bg-orange-400 rounded h-[2.5rem] px-4 mx-3 hover:bg-orange-400 cursor-pointer"
                  onClick={() => onSubmitLink()}
                >
                  Update
                </button>
              </div>
            </div>
          </Typography>
        </Box>
        <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: 14,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoIdCardOutline style={{ marginRight: 4 }} />
            App Time
          </Typography>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
            <div>
              <div className="flex  ">
                <p className=" font-bold mr-5 h-6 overflow-hidden w-[15rem]">
                  {apTime ? apTime : apTime}
                </p>
              </div>

              <div className="flex mt-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size="small" />
                    )}
                    label="DateTimePicker"
                    value={formTime?.time}
                    onChange={(newValue) => {
                      onChangeTime({
                        name: "time",
                        value: newValue,
                      });
                    }}
                  />
                </LocalizationProvider>

                <button
                  className=" bg-orange-400 rounded h-[2.5rem] px-4 mx-3 hover:bg-orange-400 cursor-pointer"
                  onClick={() => onSubmitTime()}
                >
                  Update
                </button>
              </div>
            </div>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AppoinmentsLink;
