import AppCardContainer from "../ui/AppCardContainer";
import { TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import success from "../../assets/success.gif";
import { BASE_URL, endPoint } from "../../constants/ApiConstant/routeConstant";
import { setAllNotifications } from "../../redux/slices/notificationSlice";
import { useSelector } from "../../redux/store";
import AppButton from "../ui/AppButton";
import AppModal from "../ui/AppModal";
import AppTablePagination from "../ui/AppTablePagination";
import { IoNotifications } from "react-icons/io5";

const AllNotifications = () => {
  const dispatch = useDispatch();
  const { token, role, userInfo } = useSelector((state) => state.userInfo);
  const { allNotifications } = useSelector((state) => state.notificationInfo);
  const [isShowModal, setIsShowModal] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [passwordUpdate, setPassword] = useState("");
  const [targetNID, setTargetNID] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState();
  const [totalNoti, setTotalNoti] = useState(0);

  //console.log(role);

  const handlePageClick = (event) => {
    setPageCount(event.selected + 1)
    // setPageCount(pageCount + 1);
  };

  const getNotificationAction = (value, uId, nId) => {
    switch (value) {
      case "danger":
        return (
          <AppModal
            isShowModal={isShowModal}
            callback={(item) => setIsShowModal(item)}
            modalhead={false}
            modalWidth="md:w-[450px]"
            isNotification
            modalBtn={
              <button
                className="text-white font-bold text-xs bg-red-400 p-3 capitalize rounded-lg"
                onClick={() => setTargetNID(nId)}
              >
                forget password
              </button>
            }
          >
            <div className="flex flex-col items-center  ">
              <p className="font-semibold mb-6">
                {successMsg !== "" ? successMsg : "Want to Update Password ?"}
              </p>
              {successMsg === "" ? (
                <TextField
                  id="diagnosis"
                  label={"Password *"}
                  variant="outlined"
                  fullWidth
                  type="text"
                  size="small"
                  value={passwordUpdate}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              ) : (
                <img src={success} width="150px" alt="" />
              )}

              <div className="mt-10 flex  justify-center ">
                <AppButton
                  onClick={() => handleUpdatePassword(uId, targetNID)}
                  variant="primary"
                  size="xs"
                  classes=" !w-24 mr-3"
                >
                  Yes
                </AppButton>
                <AppButton
                  onClick={() => setIsShowModal(false)}
                  variant="info"
                  size="xs"
                  classes=" !w-24 "
                >
                  Cancel
                </AppButton>
              </div>
            </div>
          </AppModal>
        );

      default:
        break;
    }
  };

  const handleUpdatePassword = async (uId, nId) => {
    //console.log(uId);
    if (passwordUpdate === "") {
      return;
    } else {
      const body = {
        updatedPassword: passwordUpdate,
      };

      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = await axios.put(
          `${BASE_URL}${endPoint.userPassUpdate}/${uId}`,
          body,
          header
        );
        if (res.data.success) {
          setSuccessMsg(res?.data?.message);
          try {
            const body = {
              varient: "success",
            };

            const header = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            const result = await axios.put(
              `${BASE_URL}${endPoint.updateVariant}/${nId}`,
              body,
              header
            );
            if (result.data.success) {
              callNotifications();
            }
          } catch (e) {
            //console.log(e);
          }
        }
        setTimeout(() => {
          setIsShowModal(false);
          setSuccessMsg("");
        }, 2800);
      } catch (err) {
        //console.log(err);
      }
    }
  };

  const getNotificationVariant = (value) => {
    switch (value) {
      case "danger":
        return "bg-red-100";
      case "success":
        return "bg-green-100";
      case "warning":
        return "bg-orange-100";
      case "info":
        return "bg-sky-100";
      default:
        break;
    }
  };

  const handleMarkUnreadNotifications = (id) => {
    const markedFalse = allNotifications?.map((obj) => {
      if (obj?.n_id === id) {
        return { ...obj, isMarked: false };
      }
      return obj;
    });
    dispatch(setAllNotifications(markedFalse));
  };

  const setMarkedNotifications = (res) => {
    //console.log(res);
    const notificationData = res?.targetNotifications;
    let persistNotification = allNotifications;

    if (persistNotification?.length > 0) {
      persistNotification = persistNotification.filter(
        (item) => !item.isMarked
      );
      let updatedNotiData = [];
      for (let i = 0; i < notificationData.length; i++) {
        let targetNoti = notificationData[i];
        let foundedNoti;
        for (let j = 0; j < persistNotification.length; j++) {
          if (persistNotification[j].n_id === targetNoti.n_id) {
            foundedNoti = targetNoti;
          }
        }
        if (foundedNoti) {
          foundedNoti.isMarked = false;
          updatedNotiData = [...updatedNotiData, foundedNoti];
        } else {
          targetNoti.isMarked = true;
          updatedNotiData = [...updatedNotiData, targetNoti];
        }
      }
      dispatch(setAllNotifications(updatedNotiData));
    } else {
      const data = res?.targetNotifications.map((elm) => ({
        ...elm,
        isMarked: true,
      }));
      dispatch(setAllNotifications(data));
    }
  };

  const getAllNotification = async (userId) => {
    const body = {
      profileType: role,
      id: userId,
      limit: 10,
      pageNo: pageCount,
    };

    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios
      .post(`${BASE_URL}${endPoint.notificationGet}`, body, header)
      .then((res) => {
        if (res.data.success) {
          return res?.data;
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const callNotifications = () => {
    if (role === "superAdmin") {
      getAllNotification(null)
        .then((res) => {
          //console.log(res);
          setTotalPageCount(res?.data?.totalPageNo);
          setTotalNoti(res?.data?.totalNotifications);
          setMarkedNotifications(res?.data);
        })
        .catch((err) => {
          //console.log(err);
        });
    } else {
      getAllNotification(userInfo?.user_id)
        .then((res) => {
          //console.log(res);
          setTotalPageCount(res?.data?.totalPageNo);
          setTotalNoti(res?.data?.totalNotifications);
          setMarkedNotifications(res?.data);
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };

  useEffect(() => {
    callNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, pageCount]);


  return (
    <AppCardContainer spacing="xl:mt-10 3xl:-mr-16 md:mx-5 mx-1 md:mt-20 mt-16">
      <div className=" mx-3 mb-5">
        <div className="flex items-center ">
          <IoNotifications className="text-2xl mr-2" />,
          <p className="text-2xl font-bold">Notifications</p>
        </div>
        <p className="text-sm font-semibold color-gray-500">
          Total notification is {totalNoti}
        </p>
      </div>
     <div className="custom-scrollbar max-h-[51vh] 3xl:max-h-[60vh] overflow-scroll">
     {allNotifications?.map((noti, index) => {
        return (
          <div
            className={` ${getNotificationVariant(
              noti?.n_varient
            )} hover:bg-gray-50 flex items-center  hover:rounded-lg pr-3 m-3 rounded-lg`}
            key={index}
          >
            <div
              className={`flex  flex-auto w-[480px]  text-primary-content p-5 relative `}
              key={index + 1}
              role="button"
              tabIndex={0}
              onClick={() => handleMarkUnreadNotifications(noti?.n_id)}
            >
              <div>
                <p className="text-black font-semibold text-sm">
                  {noti?.n_msg}
                </p>

                <div className="flex justify-between items-center mt-3">
                  <p className="text-black text-xs font-[400]">
                    {noti?.n_time}
                  </p>
                  {noti?.n_type === "action" &&
                    getNotificationAction(
                      noti?.n_varient,
                      noti?.u_id,
                      noti?.n_id
                    )}
                </div>
              </div>
            </div>

            {noti?.isMarked ? (
              <div className="bg-[#F97316] w-3 h-3 rounded-full"></div>
            ) : (
              ""
            )}
          </div>
        );
      })}
     </div>

      <div className="flex justify-center items-center h-full w-full">
        <AppTablePagination
          paddingY="py-2"
          pageCount={totalPageCount}
          handlePageClick={handlePageClick}
        />
      </div>
    </AppCardContainer>
  );
};

export default AllNotifications;
