import {
  districts,
  divisions,
  upozillas,
  postCodes,
} from '../../constants/GeoLocation/geoLocation';

export const getLocation = (div_id, dist_id, upozilla) => {
  let returnItems;
  if (div_id && dist_id && upozilla) {
    returnItems = postCodes.filter((u) => {
      if (
        u.division_id === div_id &&
        u.district_id === dist_id &&
        u.upazila === upozilla
      ) {
        return true;
      }
    });
  } else if (div_id && dist_id && upozilla) {
    returnItems = postCodes.filter((u) => {
      if (
        u.division_id === div_id &&
        u.district_id === dist_id &&
        u.upazila === upozilla
      ) {
        return true;
      }
    });
  } else if (div_id && dist_id) {
    returnItems = upozillas.filter((item) => {
      if (item.district_id === dist_id) {
        return true;
      }
    });
  } else if (div_id) {
    returnItems = districts.filter((item) => {
      if (item.division_id === div_id) {
        return true;
      }
    });
  } else {
    returnItems = [...divisions];
  }
  return returnItems;
};
