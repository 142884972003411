import { Document, Font, Page, pdf, PDFDownloadLink, PDFViewer, Text } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { useToken } from '../../../services/useAuth';

import BanglaBlack from '../fonts/NotoSerifBengali-Black.ttf';
import BanglaBlackExtraBold from '../fonts/NotoSerifBengali-Bold.ttf';
import BanglaBlackExtraLight from '../fonts/NotoSerifBengali-ExtraLight.ttf';
import BanglaBlackLight from '../fonts/NotoSerifBengali-Light.ttf';
import BanglaBlackMedium from '../fonts/NotoSerifBengali-Medium.ttf';
import BanglaBlackRegular from '../fonts/NotoSerifBengali-Regular.ttf';
import BanglaBlackThin from '../fonts/NotoSerifBengali-Thin.ttf';
import './Pdf.css';



Font.register({
  family: 'Open Sans',
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: 'Lato',
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: 'Lato Italic',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
  family: 'Lato Bold',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});
Font.register({
  family:"Noto Serif Bengali",
  src:`https://fonts.googleapis.com/css2?family=Noto+Serif+Bengali&display=swap`
})
Font.register({
  family: 'bangla-black', 
  src: BanglaBlack,
});

Font.register({
  family: 'bangla-extra-bold', 
  src: BanglaBlackExtraBold,
});

Font.register({
  family: 'bangla-extra-light', 
  src: BanglaBlackExtraLight,
});

Font.register({
  family: 'bangla-light', 
  src: BanglaBlackLight,
});

Font.register({
  family: 'bangla-medium', 
  src: BanglaBlackMedium,
});

Font.register({
  family: 'bangla-regular', 
  src: BanglaBlackRegular,
});

Font.register({
  family: 'bangla-thin', 
  src: BanglaBlackThin,
});


const PdfPage = props => (
  <Page >
  
   <Text>Sample Text</Text>
</Page>
);

const MyDocument = () => (


  <Document
  author="MD Rezowanur Rahman"
  keywords="awesome, resume, start wars"
  subject="OMPHD"
  title="Prescription"
>

 
  <PdfPage size="A4" />
  

</Document>

);

export default function PDFContainer(props) {
  //console.log("first render")
  //console.log(JSON.parse(props.addPress))

  const [loading, setLoading] = useState();
  const token = useToken();
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const uploadPdf = async () => {


      const element = <MyDocument  />;//my pdf component
      const myPdf = pdf([]);
      myPdf.updateContainer(element);
      const blob = await myPdf.toBlob();
      var file = new File([blob], "testpdf.pdf", {lastModified: (new Date()).getTime(),type:"application/pdf"});
      //console.log(file);

      //upload to server start

      const formData = new FormData();
      formData.append("pdf", file);
      // try {
      //   setLoading(true);
      //   const url = `${BASE_URL}/api/upload/pdf`;
      //   const res = await axios.post(url, formData, headers);
      //   if (res.data.success) {
      //     //console.log(res.data)
      //   }
      //   setLoading(false);
      // } catch (e) {
      //   //console.log(e);
      // }

      //upload to server end

  };

  useEffect(()=>{
    uploadPdf()
  },[])

  return (
    <div style={{ position: 'relative', height: '100%',width:"100%" }} >
    <PDFDownloadLink document={<MyDocument />} fileName="somename.pdf">
    {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : 'Download now!'
    }
  </PDFDownloadLink>
  <div>
        <div>
          <PDFViewer  style={{ position: 'relative', height: '100vh',width:"100%" }} >
            <MyDocument/>
          </PDFViewer>
          <p>Page</p>

        </div>
      </div>
    </div>
  )
}

