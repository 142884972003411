
import React from "react";
import { API, styles } from "../../../Common/Constant";
import Footer from "../../../Common/Footer";
import Header from "../../../Common/Header";
import HealthRecordsInfoContainer from "./HealthRecordsInfoContainer";
import ParticularDetailsInfo from "./ParticularDetails.js";

export const StudentDetailsPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} style={styles.page}>
      <Header profile={props.profile} proType={props.proType} title={"Student Profile"} />
      <div style={styles.container}>
        <div style={styles.leftColumn}>
          <div style={styles.container}>
          {props.profile.user_img &&
            <img
            style={styles.image}
            src={`${API}/${props.profile.user_img}`}
            alt="image"
          />
        }
          
          </div>

          <ParticularDetailsInfo profile={props.profile} proType={props.proType}  />
          <h2 style={styles.textMiddle}> Health Records </h2>
          <HealthRecordsInfoContainer  profile={props.profile} proType={props.proType} />
         

        </div>

      </div>
      <Footer/>
    </div>
  );
});