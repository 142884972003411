import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../../assets/loader.gif";
import {
  pdfTypes,
  userTypes,
} from "../../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint,
} from "../../../../constants/ApiConstant/routeConstant";
import { doctorsPdfFnc } from "../../../../redux/slices/tableDataForPdfSlice";
import { useDispatch, useSelector } from "../../../../redux/store";
import { useToken } from "../../../../services/useAuth";
import "../../../../styles/globalStyles.css";
import AppSearchAndFilter from "../../../ui/AppSearchAndFilter";
import CardView from "./CardView";
import FilterData from "./helper/FilterData";
import { FaUserMd } from "react-icons/fa";

export const DocData = {
  division: "",
  district: "",
  specialty: "",
  fees: 0,
  experience: 0,
};

const View = () => {
  const { deleteSuccess } = useSelector((state) => state.modal);
  // const { doctorsPdf } = useSelector((state) => state.tablePdf);
  const dispatch = useDispatch();
  const [doctors, setDoctors] = useState([]);
  const [, setDoctorsError] = useState();
  const [loading, setLoading] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [docFilter, setDocFilter] = useState(DocData);
  const [clearFilter, setClearFilter] = useState(false);

  const token = useToken();

  const getData = async () => {
    const body = {
      profileType: userTypes.doctor,
    };

    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);

    await axios
      .post(`${BASE_URL}${endPoint.userGet}`, body, header)
      .then((res) => {
        setLoading(false);

        if (res?.data?.success) {
          setDoctors(res.data?.data);
        }
      })
      .catch((err) => {
        setLoading(false);

        setDoctorsError(err.response.data.message);
      });
  };

  const getAllDoctorsSearchedData = async () => {
    if (!(searchValue === "")) {
      const body = {
        type: userTypes.doctor,
        data: searchValue,
      };

      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .post(`${BASE_URL}${endPoint.search}`, body, header)
        .then((res) => {
          if (res?.data?.success) {
            setDoctors(res?.data?.data);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    } else {
      getData();
    }
  };

  const handleFilter = async () => {
    if (
      docFilter.division === "" &&
      docFilter.district === "" &&
      docFilter.specialty === "" &&
      docFilter.fees === 0 &&
      docFilter.experience === 0
    ) {
      getData();
    } else {
      const body = {
        type: userTypes.doctor,
        data: docFilter,
      };

      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .post(`${BASE_URL}${endPoint.filter}`, body, header)
        .then((res) => {
          if (res?.data?.success) {
            setDoctors(res?.data?.data);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };

  useEffect(() => {
    if (doctors) {
      dispatch(doctorsPdfFnc(doctors));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctors, searchValue]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess, clearFilter]);

  useEffect(() => {
    getAllDoctorsSearchedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div className="shadow-md w-full bg-white mt-10 h-[77vh] relative overflow-scroll custom-scroll rounded-xl">
      <div className="flex md:flex-row flex-col items-center justify-between fixed bg-white xl:w-[70%] w-full  p-5 shadow-md rounded-xl">
        <div className="flex items-center">
          <div className="flex items-center">
            <FaUserMd className="text-lg mr-2 mt-[0.2rem]" />
            <p className="text-xl font-bold ">Doctors</p>
          </div>
          <Link
            to={`/pdfview/${pdfTypes.doctorList}`}
            rel="noopener noreferrer"
          >
            <SimCardDownloadIcon
              style={{ color: "red", cursor: "pointer" }}
              fontSize="large"
            />
          </Link>
        </div>
        <div className="flex items-center">
          <AppSearchAndFilter
            searchVal={(val) => setSearchValue(val)}
            searchPlaceHolder="Search by Doctor name, id and dob"
          />
          <FilterData
            docFilter={docFilter}
            setDocFilter={setDocFilter}
            handleFilter={handleFilter}
            setClearFilter={setClearFilter}
            clearFilter={clearFilter}
          />
        </div>
      </div>
      <div className=" mt-32 md:mt-24 ">
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={Loader} style={{ width: "20%" }} alt="" />
          </div>
        ) : (
          <>
            {doctors.map((doctor) => {
              return <CardView doctor={doctor} />;
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default View;
