import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import styled from 'styled-components';


const StyledTableCell = styled(TableCell)({
  padding: '3px !important',
  fontFamily:'Noto Serif Bengali !important',
  border:'1px solid black !important'
})

const MyTable = ({headersData,bodyData}) => {



  return (
    <TableContainer component={Paper} sx={{marginTop:'1rem',marginBottom:'1rem',borderRadius:'0 !important'}} >
    <Table  aria-label="simple table" >
      <TableHead  >
        <TableRow > 
        {
          headersData.map((item)=> {
            return (<StyledTableCell align="center" style={{fontWeight:700}} >{item}</StyledTableCell>)
          })
        }
        </TableRow>
      </TableHead>
      <TableBody>
        {bodyData.map((single,index) => (
          <TableRow
            key={index+1}
           >
            <StyledTableCell align="center"  >
              #{index+1}
            </StyledTableCell>
            {
              single.map((value)=> (
                <StyledTableCell align="center"  >{value}</StyledTableCell>

              ))
            }
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
  )
}

export default MyTable