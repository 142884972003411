import { Box, Checkbox, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';

const SystemicExamination = ({
  formSystemicExamination,
  errorsSystemicExamination,
  onChangeSystemicExamination,
  setFormSystemicExamination,
  setErrorsSystemicExamination
}) => {

  const InitialData = {
    respiratorySystem: "",
    cardiovascularSystem: "",
    oralAndAbdominalSystem: "",
    nervousSystem: "",
    musculoskeletalSystem:"",
    urogenitalSystem: ""
  };

  const defaultData = {
    respiratorySystem: "NAD",
    cardiovascularSystem: "NAD",
    oralAndAbdominalSystem: "NAD",
    nervousSystem: "NAD",
    musculoskeletalSystem:"NAD",
    urogenitalSystem: "NAD"
  };
  const [checked, setChecked] = useState(false);
  const [sysExamination, setSysExamination] = useState(InitialData);

  const handleChange = (event) => {
    // //console.log(event.target.checked);
    if (event.target.checked) {
      setSysExamination(defaultData);
     
      const arr =Object.keys(defaultData)
      let addedObj= {}
      arr.map((item)=>{
         addedObj[item]= defaultData[item]
      })
    
         setFormSystemicExamination({ ...formSystemicExamination, ...addedObj });
         let obj={}
         Object.keys(errorsSystemicExamination).forEach((key,value) => obj[key] = "");
         setErrorsSystemicExamination(obj)
         

    } else {
      setSysExamination(InitialData);
      const arr =Object.keys(InitialData)
      let addedObj= {}
      arr.map((item)=>{
         addedObj[item]= InitialData[item]
      })
    
         setFormSystemicExamination({ ...formSystemicExamination, ...addedObj });
         let obj={}
         Object.keys(errorsSystemicExamination).forEach((key,value) => obj[key] = "Please Select");
         setErrorsSystemicExamination(obj)
         
  
    }
    setChecked(event.target.checked);
  };


  return (
    <div>
    <div className=" flex justify-end mb-2">
    <p className="font-bold mt-2" >NORMAL FINDINGS</p>
    <Checkbox  checked={checked} onChange={handleChange} />
  </div>
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="respiratorySystem"
                multiline
                rows={3}
                fullWidth
                error={errorsSystemicExamination.respiratorySystem? true: false}
                helperText={errorsSystemicExamination.respiratorySystem}
                value={formSystemicExamination?.respiratorySystem || ""}
                label={
                  formSystemicExamination.respiratorySystem
                    ? "Respiratory System"
                    : "Respiratory System" + " *"
                }
                onChange={(e) => {

                  setSysExamination({
                    ...sysExamination,
                    respiratorySystem: e.target.value,
                  });

                  onChangeSystemicExamination({
                    name: "respiratorySystem",
                    value: e.target.value,
                  });
                }}
              />
            </Grid>{" "}
            <Grid item xs={12} sm={6}>
              <TextField
                id="cardiovascularSystem"
                multiline
                rows={3}
                fullWidth
                error={errorsSystemicExamination.cardiovascularSystem? true: false}
                helperText={errorsSystemicExamination.cardiovascularSystem}
                value={formSystemicExamination?.cardiovascularSystem || ""}
                label={
                  formSystemicExamination.cardiovascularSystem
                    ? "Cardiovascular System"
                    : "Cardiovascular System" + " *"
                }
                onChange={(e) => {
                  
                  setSysExamination({
                    ...sysExamination,
                    cardiovascularSystem: e.target.value,
                  });

                  onChangeSystemicExamination({
                    name: "cardiovascularSystem",
                    value: e.target.value,
                  });
                }}
              />
            </Grid>{" "}
            <Grid item xs={12} sm={6}>
              <TextField
                id="oralAndAbdominalSystem"
                multiline
                rows={3}
                fullWidth
                error={errorsSystemicExamination.oralAndAbdominalSystem? true: false}
                helperText={errorsSystemicExamination.oralAndAbdominalSystem}
                value={formSystemicExamination?.oralAndAbdominalSystem || ""}
                label={
                  formSystemicExamination.oralAndAbdominalSystem
                    ? "Oral and Abdominal System"
                    : "Oral and Abdominal System" + " *"
                }
                onChange={(e) => {
                  
                  setSysExamination({
                    ...sysExamination,
                    oralAndAbdominalSystem: e.target.value,
                  });

                  onChangeSystemicExamination({
                    name: "oralAndAbdominalSystem",
                    value: e.target.value,
                  });
                }}
              />
            </Grid>{" "}
            <Grid item xs={12} sm={6}>
              <TextField
                id="nervousSystem"
                multiline
                rows={3}
                fullWidth
                error={errorsSystemicExamination.nervousSystem? true: false}
                helperText={errorsSystemicExamination.nervousSystem}
                value={formSystemicExamination?.nervousSystem || ""}
                label={
                  formSystemicExamination.nervousSystem
                    ? "Nervous System"
                    : "Nervous System" + " *"
                }
                onChange={(e) => {
                  
                  setSysExamination({
                    ...sysExamination,
                    nervousSystem: e.target.value,
                  });

                  onChangeSystemicExamination({
                    name: "nervousSystem",
                    value: e.target.value,
                  });
                }}
              />
            </Grid>{" "}
            <Grid item xs={12} sm={6}>
              <TextField
                id="musculoskeletalSystem"
                multiline
                rows={3}
                fullWidth
                error={errorsSystemicExamination.musculoskeletalSystem? true: false}
                helperText={errorsSystemicExamination.musculoskeletalSystem}
                value={formSystemicExamination?.musculoskeletalSystem || ""}
                label={
                  formSystemicExamination.musculoskeletalSystem
                    ? "Musculoskeletal System"
                    : "Musculoskeletal System" + " *"
                }
                onChange={(e) => {
                  
                setSysExamination({
                  ...sysExamination,
                  musculoskeletalSystem: e.target.value,
                });

                  onChangeSystemicExamination({
                    name: "musculoskeletalSystem",
                    value: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
            <TextField
              id="urogenitalSystem"
              multiline
              rows={3}
              fullWidth
              error={errorsSystemicExamination.urogenitalSystem? true: false}
              helperText={errorsSystemicExamination.urogenitalSystem}
              value={formSystemicExamination?.urogenitalSystem || ""}
              label={
                formSystemicExamination.urogenitalSystem
                  ? "Urogenital System"
                  : "Urogenital System" + " *"
              }
              onChange={(e) => {
                
                setSysExamination({
                  ...sysExamination,
                  urogenitalSystem: e.target.value,
                });

                onChangeSystemicExamination({
                  name: "urogenitalSystem",
                  value: e.target.value,
                });
              }}
            />
          </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default SystemicExamination