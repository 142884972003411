import React from "react";
import { Paper, Typography, Box } from "@mui/material";

const Summary = ({ authorityInfo }) => {
  const { ins_sm_total_patients, ins_sm_total_amount, ins_sm_per_head,ins_service_status } =
    authorityInfo;
  return (
    <Paper sx={{ borderRadius: "12px", p: 4 }}>
      <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>Summary</Typography>

      <Box sx={{ mt: 3 }}>
        <Box sx={{ mt: 2 }}>
          <Box>
            <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
              Patients No.
            </Typography>
            <Typography sx={{ fontWeight: "400", fontSize: 16, mb: 2 }}>
              {ins_sm_total_patients}
            </Typography>
          </Box>
          <Box sx={{my:3}}>
            <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
              Total Received Amount
            </Typography>
            <Typography sx={{ fontWeight: "400", fontSize: 16, mb: 2 }}>
              {ins_sm_total_amount}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
              Per head costing
            </Typography>
            <Typography sx={{ fontWeight: "400", fontSize: 16, mb: 2 }}>
              {ins_sm_per_head}
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
          <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 3 }}>
            Service Status
          </Typography>
          <Typography sx={{ fontWeight: "400", fontSize: 16, mt: 0.5 }}>
           {ins_service_status}
          </Typography>
        </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default Summary;
