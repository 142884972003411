import axios from "axios";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import InstitutionDetails from "./components/HealthCare/institutions/ViewMain/InstitutionDetails";
import EditQuiz from "./components/Hijama/Quiz/AddMain/EditQuiz";
import EditVideos from "./components/Hijama/Videos/Add/Edit";
import AllNotifications from "./components/Navbar/AllNotifications";
import EditEventsInfo from "./components/Olympiad/Events/AddMain/EditMain";
import EventsInfo from "./components/Olympiad/Events/View/EventsInfo";
import EditNotice from "./components/Olympiad/Notices/AddMain/EditMain";
import NoticeInfoDetails from "./components/Olympiad/Notices/View/NoticesInfo";
import EditMain from "./components/Telemedicine/Doctors/AddMain/EditMain";
import DoctorsInfo from "./components/Telemedicine/Doctors/View/DoctorsInfo";
import Dashboard from "./components/dashboard/Dashboard";
import DoctorDashboard from "./components/dashboard/DoctorDashboard";
import SADashboard from "./components/dashboard/SADashboard";
import APDetails from "./components/doctor/apintments/APDetails";
import DAppointments from "./components/doctor/apintments/DAppointments";
import DPatients from "./components/doctor/patients/DPatients";
import PDetails from "./components/doctor/patients/PDetails";
import DPrescription from "./components/doctor/prescription/DPrescription";
import EditPrescription from "./components/doctor/prescription/EditPrescription";
import PressDetails from "./components/doctor/prescription/PressDetails";
import DProfile from "./components/doctor/profile/DProfile";
import Layout from "./components/layout/Layout";
import LoginForm from "./components/login-page/LoginForm";
import SAStudentsView from "./components/school-authority/Students/ViewMain/SAStudentsView";
import SAStudentsViewDetails from "./components/school-authority/Students/ViewMain/studentsDetails";
import SATeachersView from "./components/school-authority/Teachers/View/SATeachersView";
import SATeachersViewDetails from "./components/school-authority/Teachers/View/teachersDetails";
import SAProfile from "./components/school-authority/profile/SAProfile";
import SAStatistics from "./components/school-authority/statistics/SAStatistics";
import Settings from "./components/super-admin/settings/Settings";
import { BASE_URL, endPoint } from "./constants/ApiConstant/routeConstant";
import HealthCare from "./pages/HealthCare/HealthCare";
import InstitutionsAddPage from "./pages/HealthCare/institutions/InstitutionsAddPage";
import InstitutionsEditPage from "./pages/HealthCare/institutions/InstitutionsEditPage";
import InstitutionsViewPage from "./pages/HealthCare/institutions/InstitutionsViewPage";
import StudentsAddPage from "./pages/HealthCare/students/StudentsAddPage";
import StudentsDetails from "./pages/HealthCare/students/StudentsDetails";
import StudentsEditPage from "./pages/HealthCare/students/StudentsEditPage";
import StudentsViewPage from "./pages/HealthCare/students/StudentsViewPage";
import TeachersAddPage from "./pages/HealthCare/teachers/TeachersAddPage";
import TeachersDetails from "./pages/HealthCare/teachers/TeachersDetails";
import TeachersEditPage from "./pages/HealthCare/teachers/TeachersEditPage";
import TeachersViewPage from "./pages/HealthCare/teachers/TeachersViewPage";
import Hijama from "./pages/Hijama/Hijama";
import AddQuiz from "./pages/Hijama/Quiz/AddQuiz";
import ViewQuiz from "./pages/Hijama/Quiz/ViewQuiz";
import AddVideos from "./pages/Hijama/Videos/AddVideos";
import ViewVideos from "./pages/Hijama/Videos/ViewVideos";
import Users from "./pages/Hijama/users/Users";
import UsersDetails from "./pages/Hijama/users/UsersDetails";
import Districts from "./pages/Olympiad/Districts";
import AddEvents from "./pages/Olympiad/Events/AddEvents";
import ViewEvents from "./pages/Olympiad/Events/ViewEvents";
import AddNotices from "./pages/Olympiad/Notices/AddNotices";
import ViewNotices from "./pages/Olympiad/Notices/ViewNotices";
import Olympiad from "./pages/Olympiad/Olympiad";
import RegisteredStudents from "./pages/Olympiad/RegisteredStudents";
import RegisteredStudentsDetails from "./pages/Olympiad/RegisteredStudentsDetails";
import Appointments from "./pages/Telemedicine/Appointments/Appointments";
import AppointmentsDetails from "./pages/Telemedicine/Appointments/AppointmentsDetails";
import AddDoctorsPage from "./pages/Telemedicine/Doctors/AddDoctorsPage";
import ViewDoctorsPage from "./pages/Telemedicine/Doctors/ViewDoctorsPage";
import Patients from "./pages/Telemedicine/Patients/Patients";
import PatientsDetails from "./pages/Telemedicine/Patients/PatientsDetails";
import Telemedicine from "./pages/Telemedicine/Telemedicine";
import AddAdmin from "./pages/admin/AddAdmin";
import ViewAdmin from "./pages/admin/ViewAdmin";
import PrivateRoute from "./pages/authPage/PrivateRoute";
import PDFScreen from "./pages/react-to-print/PDFScreen";
import PdfPage from "./pages/react-to-print/reactPdf/index";
import { useSelector } from "./redux/store";

const App = () => {
  const { role, token } = useSelector((state) => state.userInfo);

  const getAdminDashboard = () => {
    if (role) {
      if (role === "superAdmin") {
        return <Dashboard />;
      }
      if (role === "schoolAuthority") {
        return <SADashboard />;
      }
      if (role === "doctor") {
        return <DoctorDashboard />;
      }
    } else return null;
  };

  const checkNotification = async () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(`${BASE_URL}${endPoint.checkNoti}`, {}, header)
      .then((res) => {})
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    checkNotification();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="custom-scrollbar-main">
      <Layout>
        <Routes>
          <Route path={"/admin"} element={<LoginForm />} />
          <Route path={"/"} element={<LoginForm />} />
          <Route
            path={"/dashboard"}
            element={<PrivateRoute>{getAdminDashboard()}</PrivateRoute>}
          />
          <Route
            path={"/healthcare"}
            element={
              <PrivateRoute>
                <HealthCare />
              </PrivateRoute>
            }
          />
          <Route
            path={"/healthcare/institutions/add"}
            element={
              <PrivateRoute>
                <InstitutionsAddPage />
              </PrivateRoute>
            }
          />
          <Route
            path={"/healthcare/institutions/edit/:id"}
            element={
              <PrivateRoute>
                <InstitutionsEditPage />
              </PrivateRoute>
            }
          />
          <Route
            path={"/healthcare/institutions/view"}
            element={
              <PrivateRoute>
                
                <InstitutionsViewPage />
              </PrivateRoute>
            }
          />
          <Route
            path={"/healthcare/institutions/view/details/:id"}
            element={
              <PrivateRoute>
                <InstitutionDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={"/healthcare/students/add"}
            element={
              <PrivateRoute>
                <StudentsAddPage />
              </PrivateRoute>
            }
          />
          <Route
            path={"/healthcare/students/edit/:id"}
            element={
              <PrivateRoute>
                <StudentsEditPage />
              </PrivateRoute>
            }
          />
          <Route
            path={"/healthcare/students/view"}
            element={
              <PrivateRoute>
                <StudentsViewPage />
              </PrivateRoute>
            }
          />
          <Route
            path={"/healthcare/students/view/details/:id"}
            element={
              <PrivateRoute>
                
                <StudentsDetails />
              </PrivateRoute>
            }
          />
          <Route
          path={"/healthcare/teachers/add"}
          element={
            <PrivateRoute>
              <TeachersAddPage />
            </PrivateRoute>
          }
        />
        <Route
          path={"/healthcare/teachers/edit/:id"}
          element={
            <PrivateRoute>
              <TeachersEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path={"/healthcare/teachers/view"}
          element={
            <PrivateRoute>
              <TeachersViewPage />
            </PrivateRoute>
          }
        />
        <Route
          path={"/healthcare/teachers/view/details/:id"}
          element={
            <PrivateRoute>
              
              <TeachersDetails />
            </PrivateRoute>
          }
        />
          <Route
            path={"/telemedicine"}
            element={
              <PrivateRoute>
                <Telemedicine />
              </PrivateRoute>
            }
          />
          <Route
            path={"/telemedicine/doctors/add"}
            element={
              <PrivateRoute>
                <AddDoctorsPage />
              </PrivateRoute>
            }
          />
          <Route
            path={"/telemedicine/doctors/view"}
            element={
              <PrivateRoute>
                <ViewDoctorsPage />
              </PrivateRoute>
            }
          />
          <Route
            path={"/telemedicine/doctors/view/details/:id"}
            element={
              <PrivateRoute>
                <DoctorsInfo />
              </PrivateRoute>
            }
          />
          <Route
            path={"/telemedicine/doctors/edit/:id"}
            element={
              <PrivateRoute>
                <EditMain />
              </PrivateRoute>
            }
          />
          <Route
            path={"/telemedicine/appointments"}
            element={
              <PrivateRoute>
                <Appointments />
              </PrivateRoute>
            }
          />
          <Route
            path={"/telemedicine/appointments/details/:id"}
            element={
              <PrivateRoute>
                <AppointmentsDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={"/telemedicine/patients"}
            element={
              <PrivateRoute>
                <Patients />
              </PrivateRoute>
            }
          />
          <Route
            path={"/telemedicine/patients/details/:id"}
            element={
              <PrivateRoute>
                <PatientsDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={"/olympiad"}
            element={
              <PrivateRoute>
                <Olympiad />
              </PrivateRoute>
            }
          />
          <Route
            path={"/olympiad/events/add"}
            element={
              <PrivateRoute>
                <AddEvents />
              </PrivateRoute>
            }
          />
          <Route
            path={"/olympiad/events/view"}
            element={
              <PrivateRoute>
                <ViewEvents />
              </PrivateRoute>
            }
          />
          <Route
            path={"/olympiad/events/details/:id"}
            element={
              <PrivateRoute>
                <EventsInfo />
              </PrivateRoute>
            }
          />
          <Route
            path={"/olympiad/events/edit/:id"}
            element={
              <PrivateRoute>
                <EditEventsInfo />
              </PrivateRoute>
            }
          />
          <Route
            path={"/olympiad/districts"}
            element={
              <PrivateRoute>
                <Districts />
              </PrivateRoute>
            }
          />
          <Route
            path={"/olympiad/notices/add"}
            element={
              <PrivateRoute>
                <AddNotices />
              </PrivateRoute>
            }
          />
          <Route
            path={"/olympiad/notices/view"}
            element={
              <PrivateRoute>
                <ViewNotices />
              </PrivateRoute>
            }
          />
          <Route
            path={"/olympiad/notices/edit/:id"}
            element={
              <PrivateRoute>
                <EditNotice />
              </PrivateRoute>
            }
          />
          <Route
            path={"/olympiad/notices/details/:id"}
            element={
              <PrivateRoute>
                <NoticeInfoDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={"/olympiad/registered-students"}
            element={
              <PrivateRoute>
                <RegisteredStudents />
              </PrivateRoute>
            }
          />
          <Route
            path={"/olympiad/registered-students/details/:id"}
            element={
              <PrivateRoute>
                <RegisteredStudentsDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={"/hijama"}
            element={
              <PrivateRoute>
                <Hijama />
              </PrivateRoute>
            }
          />
          <Route
            path={"/hijama/Videos/add"}
            element={
              <PrivateRoute>
                <AddVideos />
              </PrivateRoute>
            }
          />
          <Route
            path={"/hijama/Videos/view"}
            element={
              <PrivateRoute>
                <ViewVideos />
              </PrivateRoute>
            }
          />
          <Route
            path={"/hijama/Videos/edit/:id"}
            element={
              <PrivateRoute>
                <EditVideos />
              </PrivateRoute>
            }
          />
          <Route
            path={"/hijama/quiz/add"}
            element={
              <PrivateRoute>
                <AddQuiz />
              </PrivateRoute>
            }
          />
          <Route
            path={"/hijama/quiz/view"}
            element={
              <PrivateRoute>
                <ViewQuiz />
              </PrivateRoute>
            }
          />
          <Route
            path={"/hijama/quiz/edit/:id"}
            element={
              <PrivateRoute>
                <EditQuiz />
              </PrivateRoute>
            }
          />
          <Route
            path={"/hijama/users"}
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path={"/hijama/users/details/:id"}
            element={
              <PrivateRoute>
                <UsersDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={"/notifications"}
            element={
              <PrivateRoute>
                
                <AllNotifications />
              </PrivateRoute>
            }
          />
          <Route
            path={"/superAdmin/add"}
            element={
              <PrivateRoute>
                
                <AddAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path={"/superAdmin/view"}
            element={
              <PrivateRoute>
                
                <ViewAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path={"/pdfview/:type/:id"}
            element={
              <PrivateRoute>
                <PDFScreen />
              </PrivateRoute>
            }
          />
          <Route
            path={"/pdfview/:type/"}
            element={
              <PrivateRoute>
                <PDFScreen />
              </PrivateRoute>
            }
          />
          <Route
            path={"/settings"}
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          {/* School Authority */}
          <Route
            path={"/school-authority-profile"}
            element={
              <PrivateRoute>
                
                <SAProfile />
              </PrivateRoute>
            }
          />
          <Route
            path={"/students"}
            element={
              <PrivateRoute>
                
                <SAStudentsView />
              </PrivateRoute>
            }
          />
          <Route
            path={"/students/details/:id"}
            element={
              <PrivateRoute>
                
                <SAStudentsViewDetails />
              </PrivateRoute>
            }
          />

          <Route
          path={"/teachers"}
          element={
            <PrivateRoute>
              
              <SATeachersView />
            </PrivateRoute>
          }
        />
        <Route
          path={"/teachers/details/:id"}
          element={
            <PrivateRoute>
              
              <SATeachersViewDetails />
            </PrivateRoute>
          }
        />

          <Route
            path={"/statistics"}
            element={
              <PrivateRoute>
                
                <SAStatistics />
              </PrivateRoute>
            }
          />
          {/* Doctor */}
          <Route
            path={"/doctor-profile"}
            element={
              <PrivateRoute>
                
                <DProfile />
              </PrivateRoute>
            }
          />
          <Route
            path={"/patients"}
            element={
              <PrivateRoute>
                
                <DPatients />
              </PrivateRoute>
            }
          />
          <Route
            path={"/patients/details/:id"}
            element={
              <PrivateRoute>
                
                <PDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={"/appointments"}
            element={
              <PrivateRoute>
                
                <DAppointments />
              </PrivateRoute>
            }
          />
          <Route
            path={"/appointments/details/:id"}
            element={
              <PrivateRoute>
                
                <APDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={"/prescriptionPdf"}
            element={
              <PrivateRoute>
                
                <PdfPage />
              </PrivateRoute>
            }
          />
          <Route
            path={"/prescriptions"}
            element={
              <PrivateRoute>
                
                <DPrescription />
              </PrivateRoute>
            }
          />
          <Route
            path={"/prescription/details"}
            element={
              <PrivateRoute>
                
                <PressDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={"/prescription/details/edit/:id"}
            element={
              <PrivateRoute>
                
                <EditPrescription />
              </PrivateRoute>
            }
          />
        </Routes>
      </Layout>
      <ToastContainer />
    </div>
  );
};

export default App;
