import React from "react";
import { HiUsers } from "react-icons/hi";
import AppButton from "./AppButton";
import Loader from "./Loader";

const AppDashboardCard = ({
  title,
  value,
  viewLink,
  bgColor,
  textColor,
  icon,
  isStyle,
}) => {
  return (
    <div className="stats shadow !p-2 w-full ">
      <div className="stat">
        <div className="flex  justify-between">
          <div>
            <div
              className={` ${
                isStyle ? "text-xs" : "text-sm"
              } text-gray-600 font-bold !uppercase`}
            >
              {title}
            </div>
            <div
              className={`font-bold ${
                isStyle ? "text-2xl" : "text-6xl"
              }  mt-3 mb-6`}
            >
              {value >= 0 ? value : <Loader variant="#3498db" />}
            </div>
            <div>
              <AppButton
                link={viewLink}
                classes={` ${bgColor} ${textColor} hover:${bgColor} hover:${textColor} border-none font-bold`}
              >
                view page
              </AppButton>
            </div>
          </div>
          <div
            className={`${
              isStyle ? "w-20 h-20 3xl:w-24 3xl:h-24" : "w-28 h-28"
            } rounded-full ${bgColor} flex justify-center items-center`}
          >
            {icon}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppDashboardCard;
