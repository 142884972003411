import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: [],
    allNotifications: [],
  },
  reducers: {
    setNotifications: (state, action) => {
        state.notifications = action.payload;
    },
    setAllNotifications: (state, action) => {
        state.allNotifications = action.payload;
    },
  },
});

// Exports all actions
export const { setNotifications, setAllNotifications} = notificationSlice.actions;

export default notificationSlice.reducer;
