import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { divisions } from "../../../../../constants/GeoLocation/geoLocation";
import { getLocation } from "../../../../../utils/AddressFunctions/getLocation";
import AppModal from "../../../../ui/AppModal";
import AppButton from "../../../../ui/AppButton";
import { InstData } from "../SAInstitutionsView";

const buttonApply = {
  variant: "primary",
  fullWidth: true,
  size: "xs",
  classes: " text-sm h-11 w-28 font-semibold",
};
const buttonClear = {
  variant: "info",
  fullWidth: true,
  size: "xs",
  classes: " text-sm h-11 w-28 font-semibold mr-2",
};

const FilterData = ({
  instFilter,
  setInstFilter,
  handleFilter,
  setClearFilter,
  clearFilter,
}) => {
  const [isShow, setIsShow] = useState(false);

  const [districtData, setDistrictData] = useState();

  const handleSelectedDivision = (param) => {
    setInstFilter({ ...instFilter, division: param });
    const divId = divisions.filter((item) => item.name === param);
    const location = getLocation(divId[0].id);
    setDistrictData(location);
  };

  return (
    <div>
      <AppModal
        isShowModal={isShow}
        callback={(item) => setIsShow(item)}
        option
        modalTitle="Institute Filter"
        modalTitleClass="text-xl"
        modalWidth="md:w-[420px] h-[70vh] w-full"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="institueType"
                  options={["School", "College", "Madrasa", "University"]}
                  fullWidth
                  size="small"
                  value={instFilter.insType || ""}
                  onChange={(event, newValue) => {
                    setInstFilter({ ...instFilter, insType: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Institue Type" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="division"
                  fullWidth
                  size="small"
                  options={divisions.map((div) => div.name)}
                  value={instFilter.division || ""}
                  onChange={(event, newValue) => {
                    handleSelectedDivision(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Division" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="district"
                  options={
                    districtData ? districtData.map((dist) => dist.name) : []
                  }
                  fullWidth
                  size="small"
                  value={instFilter.district || null}
                  onChange={(event, newValue) => {
                    setInstFilter({ ...instFilter, district: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="District" />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <div className="mt-48 flex justify-end">
            <AppButton
              {...buttonClear}
              onClick={() => {
                setClearFilter(!clearFilter);
                setInstFilter(InstData);
                setIsShow(false);
              }}
            >
              Clear_Filter
            </AppButton>
            <AppButton
              {...buttonApply}
              onClick={() => {
                handleFilter();
                setIsShow(false);
              }}
            >
              Apply
            </AppButton>
          </div>
        </Box>
      </AppModal>
    </div>
  );
};

export default FilterData;
