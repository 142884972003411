
// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)

import React from "react";
import { styles } from "../../../Common/Constant";
import Footer from '../../../Common/Footer';
import Header from '../../../Common/Header';
import MyTable from '../../../Common/Table';

export const StudentTableToPrint = React.forwardRef((props, ref) => {


 const headerData = ['NO','Name','Phone','Institute','Class','Section','Roll','DOB','Gender','Religion','Father Name','BMI','Health Status']

//   const bodyData =props.data?.map((item)=>{
//     return [item.s_name,item.user_phone_id,item.ins_name,item.ins_class_name,item.s_section,item.s_roll,item.s_dob,item.s_gender,item.s_religion,item.s_ftr_name,item.s_bmi,item.s_health_status]
//  })

const bodyData =props.data?.map((item)=>{
  return [item.sName,item.userPhoneId,item.insName,item.insClassName,item.sSection,item.sRoll,item.sDob,item.sGender,item.sReligion,item.sFtrName,item.sBmi,item.sHealthStatus]
})

  return (
    <div ref={ref} style={styles.page}>
      <Header profile={props.data} proType={props.proType} title={"Students List"} />
      <div style={styles.container}>
        <div style={styles.leftColumn}>
          <div style={styles.container}>

          {
            props.data.length>0 ? (
          <MyTable 
            headersData={headerData}
            bodyData = {bodyData}
          />
            ) : <h3 style={{textAlign:'center'}}> No Class found!</h3>
          }

          </div>
          

        </div>

        
        
      </div>
      <Footer/>
    </div>
  );
});