import { Box, Grid, TextField } from "@mui/material";
import React from "react";

const EducationAndExperience = ({
  formEducationAndExperience,
  errorsEducationAndExperience,
  onChangeEducationAndExperience,
}) => {
  return (
    <div>
      {" "}
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="education"
                multiline
                rows={3}
                fullWidth
                label={
                  formEducationAndExperience?.name ? "Education" : "Education *"
                }
                onChange={(e) => {
                  onChangeEducationAndExperience({
                    name: "education",
                    value: e.target.value,
                  });
                }}
                value={formEducationAndExperience.education || ""}
                error={errorsEducationAndExperience?.education ? true : false}
                helperText={errorsEducationAndExperience?.education}
              />
            </Grid>{" "}
            <Grid item xs={12} sm={6}>
              <TextField
                id="experience"
                multiline
                rows={3}
                fullWidth
                label={
                  formEducationAndExperience?.experience
                    ? "Experience"
                    : "Experience *"
                }
                onChange={(e) => {
                  onChangeEducationAndExperience({
                    name: "experience",
                    value: e.target.value,
                  });
                }}
                value={formEducationAndExperience.experience || ""}
                error={errorsEducationAndExperience?.experience ? true : false}
                helperText={errorsEducationAndExperience?.experience}
              />
            </Grid>{" "}
            <Grid item xs={12} sm={6}>
              <TextField
                id="workingPlace"
                multiline
                rows={3}
                fullWidth
                label={
                  formEducationAndExperience?.workingPlace
                    ? "Working Place"
                    : "Working Place *"
                }
                onChange={(e) => {
                  onChangeEducationAndExperience({
                    name: "workingPlace",
                    value: e.target.value,
                  });
                }}
                value={formEducationAndExperience.workingPlace || ""}
                error={
                  errorsEducationAndExperience?.workingPlace ? true : false
                }
                helperText={errorsEducationAndExperience?.workingPlace}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default EducationAndExperience;
