import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsCardChecklist } from "react-icons/bs";
import {
  pdfTypes,
  searchTypes,
  userTypes,
} from "../../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint,
} from "../../../../constants/ApiConstant/routeConstant";
import { hijamaUsersPdfFnc } from "../../../../redux/slices/tableDataForPdfSlice";
import { useDispatch, useSelector } from "../../../../redux/store";
import AppTable from "../../../ui/AppTable";
import { DynamicTableDataForSAHijamaUsers } from "../../../ui/helpers/table/TableBodyData";
import { tableHeadHijamaUsersDataData } from "../../../ui/helpers/table/TableHeadeData";
import { useConvertedTableData } from "../../../ui/helpers/table/useConvertedTableData";
import { tableConvertedData } from "./helper/convertedHijamaUsersData";
import FilterData from "./helper/FilterData";

export const HijamaData = {
  courseStatus: "",
  paymentStatus: "",
};

const SAHijamaUsersView = () => {
  const { token } = useSelector((state) => state.userInfo);
  const { deleteSuccess } = useSelector((state) => state.modal);
  // const { hijamaUsersPdf } = useSelector((state) => state.tablePdf);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [hijamaUser, setHijamaUser] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [clearFilter, setClearFilter] = useState(false);
  const [hijamaUserFilter, setHijamaUserFilter] = useState(HijamaData);

  const limit = 6;

  const { tableData, pageCount, handlePageClick } =
    useConvertedTableData(hijamaUser, limit);

  const getAllHijamaUserData = async () => {
    const body = {
      profileType: userTypes.hijama,
    };

    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    await axios
      .post(`${BASE_URL}${endPoint.userGet}`, body, header)
      .then((res) => {
        if (res?.data?.success) {
          setHijamaUser(tableConvertedData(res?.data?.data));
          setLoading(false);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const getAllHijamaUserSearchedData = async () => {
    if (!(searchValue === "")) {
      const body = {
        type: searchTypes.hijama,
        data: searchValue,
      };

      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);
      await axios
        .post(`${BASE_URL}${endPoint.search}`, body, header)
        .then((res) => {
          if (res?.data?.success) {
            setHijamaUser(tableConvertedData(res?.data?.data));
            setLoading(false);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    } else {
      getAllHijamaUserData();
    }
  };

  const handleFilter = async () => {
    if (
      hijamaUserFilter.courseStatus === "" &&
      hijamaUserFilter.paymentStatus === ""
    ) {
      getAllHijamaUserData();
    } else {
      const body = {
        type: searchTypes.hijama,
        data: hijamaUserFilter,
      };

      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);
      await axios
        .post(`${BASE_URL}${endPoint.filter}`, body, header)
        .then((res) => {
          if (res?.data?.success) {
            setHijamaUser(tableConvertedData(res?.data?.data));
            setLoading(false);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };

  const handleDeleteUser = async ( userId, id) => {
    const body = {
      profileType:  userTypes.hijama,
      userId: userId,
      id: id,
    };
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios
      .post(`${BASE_URL}${endPoint.deleteUser}`, body, header)
      .then((res) => {
        //console.log(res.data.message);
        return res.data.message;
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    if (hijamaUser) {
      dispatch(hijamaUsersPdfFnc(hijamaUser));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hijamaUser, searchValue]);

  useEffect(() => {
    getAllHijamaUserData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);

  useEffect(() => {
    getAllHijamaUserSearchedData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFilter, searchValue]);

  return (
    <div className="3xl:-mr-10 mx-5 mt-20 xl:mx-0 xl:mt-0">
      <AppTable
        tableTitle="Hijama User"
        titleIcon={<BsCardChecklist />}
        titleClass="font-bold"
        search
        // handleDelete={handleDelete}
        searchPlaceHolder="Search by hijama user name, id and dob"
        searchVal={(val) => setSearchValue(val)}
        filter
        filterBox={
          <FilterData
            hijamaUserFilter={hijamaUserFilter}
            setHijamaUserFilter={setHijamaUserFilter}
            handleFilter={handleFilter}
            setClearFilter={setClearFilter}
            clearFilter={clearFilter}
          />
        }
        handleDelete={handleDeleteUser}
        tableHeadData={tableHeadHijamaUsersDataData}
        tableBodyData={tableData}
        dynamicTable={(colItem, headItem, index, shadow, handleDelete) =>
          DynamicTableDataForSAHijamaUsers(
            colItem,
            headItem,
            index,
            shadow,
            handleDelete
          )
        }
        pdf={`/pdfview/${pdfTypes.hijamaList}`}
        loader={loading}
        pagination
        pageCount={pageCount}
        handlePageClick={handlePageClick}
      />
    </div>
  );
};

export default SAHijamaUsersView;
