import { Grid } from '@mui/material';
import React from 'react';
import { styles } from '../../../Common/Constant';
import Item from '../../../Common/Item';

const ParticularDetailsInfo = ({profile}) => {
 
  return (
    <div style={styles.innerContainer}>
    
    <div style={styles.sectionContainer}>
      <h3 style={styles.subHeader}>  Basic Information </h3>
<Grid container>
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Name"} value={profile.t_name} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Institution Name"} value={profile.ins_name} /> 
  </Grid>
  

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Joining Date"} value={profile.t_join}  /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Date of Birth"} value={profile.t_dob} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Blood Group"} value={profile.t_blood} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Gender"} value={profile.t_gender} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Religion"} value={profile.t_religion} /> 
  </Grid>
  
</Grid>

       
       

</div>


<div style={styles.sectionContainer} break>
<h4 style={styles.subHeader}> Address Information </h4> 

<Grid container>
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Permanent Address"} value={profile.t_p_address} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Present Address"} value={profile.t_t_address} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Upozila / Thana"} value={profile.ins_upozila} /> 
  </Grid>



  
</Grid>


</div>

<div style={styles.sectionContainer}>
<h4 style={styles.subHeader}> Family Information </h4> 
<Grid container>
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Father Name"} value={profile.t_ftr_name} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Mother Name"} value={profile.t_mtr_name} /> 
  </Grid>
  
  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Father Chronic"} value={profile.t_ftr_chronic} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Mother Chronic"} value={profile.t_mtr_chronic} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"No of Siblings"} value={profile.t_no_of_siblings} /> 
  </Grid>

  <Grid item xs={4} style={styles.itemContainer} >
  <Item tag={"Sibling Chronic"} value={profile.t_sibling_chronic} /> 
  </Grid>

  
</Grid>
   
    </div>
   </div>
  )
}

export default ParticularDetailsInfo