import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import AppButton from '../../../../ui/AppButton';
import AppModal from '../../../../ui/AppModal';
import { PatientData } from '../SAPatientsView';

const buttonApply = {
  variant: "primary",
  fullWidth: true,
  size: "xs",
  classes: " text-sm h-11 w-28 font-semibold",
};
const buttonClear = {
  variant: "info",
  fullWidth: true,
  size: "xs",
  classes: " text-sm h-11 w-28 font-semibold mr-2",
};

const FilterData = ({
  patientFilter,
  setPatientFilter,
  handleFilter,
  setClearFilter,
  clearFilter,
}) => {
    const [isShow, setIsShow] = useState(false);
  return (
    <div>
      <AppModal
        isShowModal={isShow}
        callback={(item) => setIsShow(item)}
        option
        modalTitle='Patient Filter'
        modalTitleClass="text-xl"
        modalWidth="md:w-[420px] w-full"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="gender"
                  options={["Male", "Female"]}
                  fullWidth
                  size="small"
                  value={patientFilter.gender || ""}
                  onChange={(event, newValue) => {
                    setPatientFilter({ ...patientFilter, gender: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Gender" />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <div className="mt-32 flex justify-end">
            <AppButton
              {...buttonClear}
              onClick={() => {
                setClearFilter(!clearFilter);
                setPatientFilter(PatientData);
                setIsShow(false);
              }}
            >
              Clear_Filter
            </AppButton>
            <AppButton
              {...buttonApply}
              onClick={() => {
                handleFilter();
                setIsShow(false);
              }}
            >
              Apply
            </AppButton>
          </div>
        </Box>
      </AppModal>
    </div>
  )
}

export default FilterData