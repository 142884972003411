import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsCardChecklist } from "react-icons/bs";
import { pdfTypes } from "../../../../constants/ApiConstant/databaseConstant";
import {
  BASE_URL,
  endPoint
} from "../../../../constants/ApiConstant/routeConstant";
import { appointmentsPdfFnc } from "../../../../redux/slices/tableDataForPdfSlice";
import { useDispatch, useSelector } from "../../../../redux/store";
import AppTable from "../../../ui/AppTable";
import { DynamicTableDataForSAAppointments } from "../../../ui/helpers/table/TableBodyData";
import { tableHeadSAAppointmentsData } from "../../../ui/helpers/table/TableHeadeData";
import { AppointmentSearchOptions } from "../../../ui/helpers/table/TableSearchOptions";
import { useConvertedTableData } from "../../../ui/helpers/table/useConvertedTableData";
import { tableConvertedData } from "./helper/convertedAppointmentData";

export const AppointmentData = {
  insType: "",
  division: "",
  district: "",
};

const SAAppointmentsView = () => {
  const { deleteSuccess } = useSelector((state) => state.modal);
  // const { appointmentsPdf } = useSelector((state) => state.tablePdf);
  const dispatch = useDispatch();
  const [appointment, setAppointment] = useState([]);
  const { token } = useSelector((state) => state.userInfo);
  const [searchValue, setSearchValue] = useState("");
  const [searchKey, setSearchKey] = useState();
  const [appointmentSearchData, setAppointmentSearchData] = useState([]);
  const [loading, setLoading] = useState(false);

  const limit = 6;

  const { tableData, pageCount, handlePageClick } =
    useConvertedTableData(appointment, limit);

  const getAllAppointmentData = async () => {
    const body = {
      // profileType: userTypes.telemedicine,
    };

    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    await axios
      .post(`${BASE_URL}${endPoint.appointmentGet}`, body, header)
      .then((res) => {
        if (res?.data?.success) {
          setAppointment(tableConvertedData(res?.data?.data));
          setAppointmentSearchData(tableConvertedData(res?.data?.data));
          setLoading(false);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const getAllAppointmentSearchedData = async () => {
   if (searchValue === "" || searchValue === 0) {
      getAllAppointmentData();
    } else {
      const data =
      searchValue &&
      appointmentSearchData?.filter((col) => {
        if (isNaN(col[searchKey])) {
          return col[searchKey]
            ?.toString()
            .toLowerCase()
            .includes(searchValue?.toString().toLowerCase());
        } else {
          return col[searchKey] === searchValue;
        }
      });
      setAppointment(data);
    }
  };

  const handleDelete = async (endPoint, id) => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios
      .delete(`${BASE_URL}${endPoint}/${id}`, header)
      .then((res) => {
        return res.data.message;
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    if (appointment) {
      dispatch(appointmentsPdfFnc(appointment));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment, searchValue]);

  useEffect(() => {
    getAllAppointmentData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess, searchKey]);

  useEffect(() => {
    getAllAppointmentSearchedData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div className="3xl:-mr-10 mx-5 mt-20 xl:mx-0 xl:mt-0">
      <AppTable
        tableTitle="Appointments Information"
        titleClass="font-bold"
        titleIcon={<BsCardChecklist />}
        search
        searchType
        searchOption={AppointmentSearchOptions}
        searchVal={(val) => setSearchValue(val)}
        searchKey={(key) => setSearchKey(key)}
        handleDelete={handleDelete}
        tableHeadData={tableHeadSAAppointmentsData}
        tableBodyData={tableData}
        dynamicTable={(colItem, headItem, index, shadow, handleDelete) =>
          DynamicTableDataForSAAppointments(colItem, headItem, index, shadow, handleDelete)
        }
        pdf={`/pdfview/${pdfTypes.appointmentList}`}
        loader={loading}
        pagination
        pageCount={pageCount}
        handlePageClick={handlePageClick}
      />
    </div>
  );
};

export default SAAppointmentsView;
