import { useState } from "react";
import AppSubNav from "../../ui/AppSubNav";
import BasicInfo from "./Details/Basic Info";

import AppCardContainer from "../../ui/AppCardContainer";
import AppBredcrumbs from "../../ui/AppBredcrumbs";
import { useLocation } from "react-router-dom";

const PressDetails = () => {
  const location = useLocation();
  const { data } = location.state;
  const [, setSelectedNav] = useState();

  return (
    <div>
      <AppCardContainer spacing="xl:mt-10 3xl:-mr-16 md:mx-5 mt-20">
        <div className="flex  justify-between">
          <div className="flex items-center justify-between">
            <AppBredcrumbs
              link="/prescriptions"
              allTitle="All Prescription"
              detailsTitle="Prescription Detaile"
            />
          </div>

          {/* <div className="">
            <AddPrescription singlePrescription={data} />
          </div> */}
        </div>
        <div className="mt-10">
          <AppSubNav
            navbarData={["Basic Info"]}
            callback={(item) => setSelectedNav(item)}
            type="tab"
            width="w-40"
          />
        </div>

        <BasicInfo singlePrescription={data} />
      </AppCardContainer>
    </div>
  );
};

export default PressDetails;
