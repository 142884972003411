import { createSlice } from "@reduxjs/toolkit";

export const tableDataForPdfSlice = createSlice({
  name: "pdfData",
  initialState: {
    institutePdf: [],
    studentsPdf: [],
    doctorsPdf: [],
    patientsPdf: [],
    appointmentsPdf: [],
    olympiedUsersPdf: [],
    hijamaUsersPdf: [],
    singlePatientsPdf: [],
    singleAppointmentsPdf: [],
    singlePrescriptionsPdf: [],
    singleStudentsPdf: [],
  },
  reducers: {
    institutePdfFnc: (state, action) => {
      state.institutePdf = action.payload;
    },
    studentsPdfFnc: (state, action) => {
      state.studentsPdf = action.payload;
    },
    doctorsPdfFnc: (state, action) => {
      state.doctorsPdf = action.payload;
    },
    patientsPdfFnc: (state, action) => {
      state.patientsPdf = action.payload;
    },
    appointmentsPdfFnc: (state, action) => {
      state.appointmentsPdf = action.payload;
    },
    olympiedUsersPdfFnc: (state, action) => {
      state.olympiedUsersPdf = action.payload;
    },
    hijamaUsersPdfFnc: (state, action) => {
      state.hijamaUsersPdf = action.payload;
    },
    singlePatientsPdfFnc: (state, action) => {
      state.singlePatientsPdf = action.payload;
    },
    singleAppointmentsPdfFnc: (state, action) => {
      state.singleAppointmentsPdf = action.payload;
    },
    singlePrescriptionsPdfFnc: (state, action) => {
      state.singlePrescriptionsPdf = action.payload;
    },
    singleStudentsPdfFnc: (state, action) => {
      state.singleStudentsPdf = action.payload;
    },
  },
});

// Exports all actions
export const {
  institutePdfFnc,
  studentsPdfFnc,
  doctorsPdfFnc,
  patientsPdfFnc,
  appointmentsPdfFnc,
  olympiedUsersPdfFnc,
  hijamaUsersPdfFnc,
  singlePatientsPdfFnc,
  singleAppointmentsPdfFnc,
  singlePrescriptionsPdfFnc,
  singleStudentsPdfFnc,
} = tableDataForPdfSlice.actions;

export default tableDataForPdfSlice.reducer;
