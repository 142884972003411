import React from "react";
import { Paper, Typography, Box } from "@mui/material";
import {
  AiOutlineIdcard,
  AiOutlineSafetyCertificate,
  AiOutlineSolution,
} from "react-icons/ai";
import {
  MdOutlineBusinessCenter,
  MdOutlineMarkEmailUnread,
} from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { BsCalendar2Date, BsGenderTrans } from "react-icons/bs";
import { GrMapLocation } from "react-icons/gr";
import { IoIdCardOutline } from "react-icons/io5";

const PDetailsBody = ({ singlePatient }) => {
  return (
    <div className="">
      <Box sx={{ px: 2, py: 1 }}>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <AiOutlineSolution style={{ marginRight: 4 }} />
              Name
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {singlePatient?.pName}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <IoIdCardOutline style={{ marginRight: 4 }} />
              Patient ID
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {singlePatient?.pId}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <MdOutlineMarkEmailUnread style={{ marginRight: 4 }} />
              Email
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {singlePatient?.pEmail}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                mt: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <FiPhoneCall style={{ marginRight: 4 }} />
              Phone Number
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {singlePatient?.pNumber}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", my: 5, flexWrap:'wrap' }}>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                display: "flex",
                alignItems: "center",
              }}
            >
              <BsCalendar2Date style={{ marginRight: 4 }} />
              Date of Birth
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {singlePatient?.pDoB}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                display: "flex",
                alignItems: "center",
              }}
            >
              <AiOutlineSafetyCertificate style={{ marginRight: 4 }} />
              BirthCertificate
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {singlePatient?.pBirthCft}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                display: "flex",
                alignItems: "center",
              }}
            >
              <BsGenderTrans style={{ marginRight: 4 }} />
              Gender
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {singlePatient?.pGender}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                display: "flex",
                alignItems: "center",
              }}
            >
              <AiOutlineIdcard style={{ marginRight: 4 }} />
              NID
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {singlePatient?.pNid}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", my: 5, flexWrap:'wrap' }}>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                display: "flex",
                alignItems: "center",
              }}
            >
              <MdOutlineBusinessCenter style={{ marginRight: 4 }} />
              Professions
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {singlePatient?.pProfession}
            </Typography>
          </Box>
          <Box sx={{ width: { md: "25%", xs: "100%", sm: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: 14,
                display: "flex",
                alignItems: "center",
              }}
            >
              <GrMapLocation style={{ marginRight: 4 }} />
              Address
            </Typography>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 0.5 }}>
              {singlePatient?.pAddress}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default PDetailsBody;
