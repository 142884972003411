import React from 'react'
import StudentInfo from '../../../components/Hijama/UsersMain/ViewMain/HijamaUsersDetails'
const UsersDetails = () => {
  return (
    <div>
      <StudentInfo />
    </div>
  );
};

export default UsersDetails;