import { Autocomplete, Box, Grid, Stack, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { formateDate } from "../../../../../utils/Formatter/dateFormater";

const ParticularDetails = ({
  formParticularDetails,
  errorsParticularDetails,
  onChangeParticularDetails,
  classes,
  isEdit
}) => {
  const [value, setValue] = React.useState(null);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {" "}
          <TextField
            id="name"
            label={formParticularDetails?.name ? "Name" : "Name *"}
            variant="outlined"
            fullWidth
            size="small"
            error={errorsParticularDetails.name ? true : false}
            helperText={errorsParticularDetails?.name}
            value={formParticularDetails.name || ""}
            onChange={(e) => {
              onChangeParticularDetails({
                name: "name",
                value: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            disablePortal
            id="class"
            options={classes.map((item)=> item.ins_class_name)}
            fullWidth
            size="small"
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            value={formParticularDetails.class || ""}
            onChange={(event, newValue) => {
              onChangeParticularDetails({
                name: "class",
                value: newValue,
              });
            }}
            renderInput={(params) => (
              <TextField
                error={errorsParticularDetails.class ? true : false}
                helperText={errorsParticularDetails?.class}
                {...params}
                label={formParticularDetails?.class ? "Class" : "Class *"}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            disablePortal
            id="bloodGroup"
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            value={formParticularDetails.bloodGroup || ""}
            onChange={(even, newValue) => {
              onChangeParticularDetails({
                name: "bloodGroup",
                value: newValue,
              });
            }}
            options={["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-" ,"Unknown"]}
            fullWidth
            size="small"
            renderInput={(params) => (
              <TextField
                error={errorsParticularDetails.bloodGroup ? true : false}
                helperText={errorsParticularDetails?.bloodGroup}
                {...params}
                label={
                  formParticularDetails?.bloodGroup
                    ? "Blood Group"
                    : "Blood Group *"
                }
              />
            )}
          />
          </Grid>
       {!isEdit && (
        
        <Grid item xs={12} sm={6}>
        <TextField
        id="section"
        label={formParticularDetails?.phone ? "Phone" : "Phone *"}
        variant="outlined"
        fullWidth
        size="small"
        error={errorsParticularDetails.phone ? true : false}
        helperText={errorsParticularDetails?.phone}
        value={formParticularDetails?.phone || ""}
        onChange={(e) => {
          onChangeParticularDetails({
            name: "phone",
            value: e.target.value,
          });
        }}
      />
        </Grid>
       )}
        <Grid item xs={12} sm={6}>
          {" "}
          <TextField
            id="section"
            label={formParticularDetails?.section ? "Section" : "Section *"}
            variant="outlined"
            fullWidth
            size="small"
            error={errorsParticularDetails.section ? true : false}
            helperText={errorsParticularDetails?.section}
            value={formParticularDetails.section || ""}
            onChange={(e) => {
              onChangeParticularDetails({
                name: "section",
                value: e.target.value,
              });
            }}
          />
        </Grid>{" "}
        <Grid item xs={12} sm={6}>
          {" "}
          <TextField
            id="roll"
            type="number"
            label={formParticularDetails?.roll ? "Roll" : "Roll *"}
            variant="outlined"
            fullWidth
            size="small"
            error={errorsParticularDetails.roll ? true : false}
            helperText={errorsParticularDetails?.roll}
            value={formParticularDetails.roll || ""}
            onChange={(e) => {
              onChangeParticularDetails({
                name: "roll",
                value: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {" "}
          <TextField
            id="session"
            label={formParticularDetails?.session ? "Session" : "Session *"}
            variant="outlined"
            fullWidth
            size="small"
            error={errorsParticularDetails.session ? true : false}
            helperText={errorsParticularDetails?.session}
            value={formParticularDetails.session || ""}
            onChange={(e) => {
              onChangeParticularDetails({
                name: "session",
                value: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <DatePicker
                disableFuture
                id="dateOfBirth"
                label={
                  formParticularDetails?.dateOfBirth
                    ? "Date of birth"
                    : "Date of birth *"
                }
                openTo="year"
                views={["year", "month", "day"]}
                value={formParticularDetails.dateOfBirth || null}
                inputFormat="YYYY-MM-DD"
                onChange={(newValue) => {
                  setValue(newValue);
                  onChangeParticularDetails({
                    name: "dateOfBirth",
                    value: formateDate(newValue),
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    error={errorsParticularDetails.dateOfBirth ? true : false}
                    helperText={errorsParticularDetails.dateOfBirth}
                  />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            disablePortal
            id="gender"
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            options={["Male", "Female", "Other"]}
            fullWidth
            size="small"
            value={formParticularDetails.gender || ""}
            onChange={(event, newValue) => {
              onChangeParticularDetails({
                name: "gender",
                value: newValue,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errorsParticularDetails.gender ? true : false}
                helperText={errorsParticularDetails?.gender}
                label={formParticularDetails?.gender ? "Gender" : "Gender *"}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            disablePortal
            id="religion"
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            options={["Islam", "Hinduism", "Buddhism", "Cristianity", "Other"]}
            fullWidth
            size="small"
            value={formParticularDetails.religion || ""}
            onChange={(event, newValue) => {
              onChangeParticularDetails({
                name: "religion",
                value: newValue,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errorsParticularDetails.religion ? true : false}
                helperText={errorsParticularDetails?.religion}
                label={
                  formParticularDetails?.religion ? "Religion" : "Religion *"
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="permanentAddress"
            label={
              formParticularDetails?.permanentAddress
                ? "Permanent address"
                : "Permanent address *"
            }
            multiline
            rows={3}
            fullWidth
            error={errorsParticularDetails.permanentAddress ? true : false}
            helperText={errorsParticularDetails?.permanentAddress}
            value={formParticularDetails.permanentAddress || ""}
            onChange={(e) => {
              onChangeParticularDetails({
                name: "permanentAddress",
                value: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="presentAddress"
            label={
              formParticularDetails?.presentAddress
                ? "Present address"
                : "Present address *"
            }
            error={errorsParticularDetails.presentAddress ? true : false}
            helperText={errorsParticularDetails?.presentAddress}
            value={formParticularDetails.presentAddress || ""}
            onChange={(e) => {
              onChangeParticularDetails({
                name: "presentAddress",
                value: e.target.value,
              });
            }}
            multiline
            rows={3}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            disablePortal
            id="houseType"
            options={["Self Owned", "Rented"]}
            fullWidth
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            size="small"
            value={formParticularDetails.houseType || ""}
            onChange={(event, newValue) => {
              onChangeParticularDetails({
                name: "houseType",
                value: newValue,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errorsParticularDetails.houseType ? true : false}
                helperText={errorsParticularDetails?.houseType}
                label={
                  formParticularDetails?.houseType
                    ? "House type"
                    : "House type *"
                }
              />
            )}
          />
        </Grid>{" "}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            disablePortal
            id="otherHouseType"
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            options={["Kacha", "Paka"]}
            fullWidth
            size="small"
            value={formParticularDetails.otherHouseType || ""}
            onChange={(event, newValue) => {
              onChangeParticularDetails({
                name: "otherHouseType",
                value: newValue,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errorsParticularDetails.otherHouseType ? true : false}
                helperText={errorsParticularDetails?.otherHouseType}
                label={
                  formParticularDetails?.otherHouseType
                    ? "House Construction Type"
                    : "House Construction Type *"
                }
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ParticularDetails;
