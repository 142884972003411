import React from "react";
import Logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";
import Notification from "./Notification";
import { CgDetailsMore } from "react-icons/cg";
import { useDispatch } from "../../redux/store";
import { handleShowDrawer } from "../../redux/slices/drawerSlice";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="md:h-[12vh] h-auto pt-2 pb-4 md:pt-0 md:pb-0 border flex md:flex-row flex-col items-center lg:px-20 px-5 w-full fixed z-50 bg-white rounded">
      <CgDetailsMore
        className="text-2xl xl:hidden md:block hidden md:mr-5 xl:mr-0 mr-0"
        onClick={() => dispatch(handleShowDrawer(true))}
      />
      <div className=" flex  items-center w-[40%] h-full ">
        <div
          role="button"
          onClick={() => {
            navigate("/dashboard");
            sessionStorage.setItem("url", "/dashboard");
          }}
        >
          <img
            src={Logo}
            className="h-16 rounded-full object-cover"
            alt="logo"
          />
        </div>
        <p className="text-xl font-extrabold">OMPHD</p>
      </div>
      <div className=" flex items-center md:justify-end justify-between md:w-[60%] w-full cursor-pointer">
        <CgDetailsMore
          className="text-2xl md:hidden block"
          onClick={() => dispatch(handleShowDrawer(true))}
        />
        <div className="mr-4">
          <ProfileDropdown />
        </div>
        <Notification />
      </div>
    </div>
  );
};

export default Navbar;
