export const divisions = [
  {
    id: '1',
    name: 'Barishal',
    bn_name: 'বরিশাল',
    lat: '22.701002',
    long: '90.353451',
  },
  {
    id: '2',
    name: 'Chattogram',
    bn_name: 'চট্টগ্রাম',
    lat: '22.356851',
    long: '91.783182',
  },
  {
    id: '3',
    name: 'Dhaka',
    bn_name: 'ঢাকা',
    lat: '23.810332',
    long: '90.412518',
  },
  {
    id: '4',
    name: 'Khulna',
    bn_name: 'খুলনা',
    lat: '22.845641',
    long: '89.540328',
  },
  {
    id: '5',
    name: 'Rajshahi',
    bn_name: 'রাজশাহী',
    lat: '24.363589',
    long: '88.624135',
  },
  {
    id: '6',
    name: 'Rangpur',
    bn_name: 'রংপুর',
    lat: '25.743892',
    long: '89.275227',
  },
  {
    id: '7',
    name: 'Sylhet',
    bn_name: 'সিলেট',
    lat: '24.894929',
    long: '91.868706',
  },
  {
    id: '8',
    name: 'Mymensingh',
    bn_name: 'ময়মনসিংহ',
    lat: '24.747149',
    long: '90.420273',
  },
];

export const districts = [
  {
    id: '1',
    division_id: '3',
    name: 'Dhaka',
    bn_name: 'ঢাকা',
    lat: '23.7115253',
    long: '90.4111451',
  },
  {
    id: '2',
    division_id: '3',
    name: 'Faridpur',
    bn_name: 'ফরিদপুর',
    lat: '23.6070822',
    long: '89.8429406',
  },

  {
    id: '3',
    division_id: '3',
    name: 'Gazipur',
    bn_name: 'গাজীপুর',
    lat: '24.0022858',
    long: '90.4264283',
  },

  {
    id: '4',
    division_id: '3',
    name: 'Gopalganj',
    bn_name: 'গোপালগঞ্জ',
    lat: '23.0050857',
    long: '89.8266059',
  },
  {
    id: '5',
    division_id: '8',
    name: 'Jamalpur',
    bn_name: 'জামালপুর',
    lat: '24.937533',
    long: '89.937775',
  },
  {
    id: '6',
    division_id: '3',
    name: 'Kishoreganj',
    bn_name: 'কিশোরগঞ্জ',
    lat: '24.444937',
    long: '90.776575',
  },
  {
    id: '7',
    division_id: '3',
    name: 'Madaripur',
    bn_name: 'মাদারীপুর',
    lat: '23.164102',
    long: '90.1896805',
  },
  {
    id: '8',
    division_id: '3',
    name: 'Manikganj',
    bn_name: 'মানিকগঞ্জ',
    lat: '23.8644',
    long: '90.0047',
  },
  {
    id: '9',
    division_id: '3',
    name: 'Munshiganj',
    bn_name: 'মুন্সিগঞ্জ',
    lat: '23.5422',
    long: '90.5305',
  },
  {
    id: '10',
    division_id: '8',
    name: 'Mymensingh',
    bn_name: 'ময়মনসিংহ',
    lat: '24.7471',
    long: '90.4203',
  },
  {
    id: '11',
    division_id: '3',
    name: 'Narayanganj',
    bn_name: 'নারায়াণগঞ্জ',
    lat: '23.63366',
    long: '90.496482',
  },
  {
    id: '12',
    division_id: '3',
    name: 'Narsingdi',
    bn_name: 'নরসিংদী',
    lat: '23.932233',
    long: '90.71541',
  },
  {
    id: '13',
    division_id: '8',
    name: 'Netrokona',
    bn_name: 'নেত্রকোণা',
    lat: '24.870955',
    long: '90.727887',
  },
  {
    id: '14',
    division_id: '3',
    name: 'Rajbari',
    bn_name: 'রাজবাড়ি',
    lat: '23.7574305',
    long: '89.6444665',
  },
  {
    id: '15',
    division_id: '3',
    name: 'Shariatpur',
    bn_name: 'শরীয়তপুর',
    lat: '23.2423',
    long: '90.4348',
  },
  {
    id: '16',
    division_id: '8',
    name: 'Sherpur',
    bn_name: 'শেরপুর',
    lat: '25.0204933',
    long: '90.0152966',
  },
  {
    id: '17',
    division_id: '3',
    name: 'Tangail',
    bn_name: 'টাঙ্গাইল',
    lat: '24.2513',
    long: '89.9167',
  },
  {
    id: '18',
    division_id: '5',
    name: 'Bogura',
    bn_name: 'বগুড়া',
    lat: '24.8465228',
    long: '89.377755',
  },
  {
    id: '19',
    division_id: '5',
    name: 'Joypurhat',
    bn_name: 'জয়পুরহাট',
    lat: '25.0968',
    long: '89.0227',
  },
  {
    id: '20',
    division_id: '5',
    name: 'Naogaon',
    bn_name: 'নওগাঁ',
    lat: '24.7936',
    long: '88.9318',
  },
  {
    id: '21',
    division_id: '5',
    name: 'Natore',
    bn_name: 'নাটোর',
    lat: '24.420556',
    long: '89.000282',
  },
  {
    id: '22',
    division_id: '5',
    name: 'Nawabganj',
    bn_name: 'নবাবগঞ্জ',
    lat: '24.5965034',
    long: '88.2775122',
  },
  {
    id: '23',
    division_id: '5',
    name: 'Pabna',
    bn_name: 'পাবনা',
    lat: '23.998524',
    long: '89.233645',
  },
  {
    id: '24',
    division_id: '5',
    name: 'Rajshahi',
    bn_name: 'রাজশাহী',
    lat: '24.3745',
    long: '88.6042',
  },
  {
    id: '25',
    division_id: '5',
    name: 'Sirajgonj',
    bn_name: 'সিরাজগঞ্জ',
    lat: '24.4533978',
    long: '89.7006815',
  },
  {
    id: '26',
    division_id: '6',
    name: 'Dinajpur',
    bn_name: 'দিনাজপুর',
    lat: '25.6217061',
    long: '88.6354504',
  },
  {
    id: '27',
    division_id: '6',
    name: 'Gaibandha',
    bn_name: 'গাইবান্ধা',
    lat: '25.328751',
    long: '89.528088',
  },
  {
    id: '28',
    division_id: '6',
    name: 'Kurigram',
    bn_name: 'কুড়িগ্রাম',
    lat: '25.805445',
    long: '89.636174',
  },
  {
    id: '29',
    division_id: '6',
    name: 'Lalmonirhat',
    bn_name: 'লালমনিরহাট',
    lat: '25.9923',
    long: '89.2847',
  },
  {
    id: '30',
    division_id: '6',
    name: 'Nilphamari',
    bn_name: 'নীলফামারী',
    lat: '25.931794',
    long: '88.856006',
  },
  {
    id: '31',
    division_id: '6',
    name: 'Panchagarh',
    bn_name: 'পঞ্চগড়',
    lat: '26.3411',
    long: '88.5541606',
  },
  {
    id: '32',
    division_id: '6',
    name: 'Rangpur',
    bn_name: 'রংপুর',
    lat: '25.7558096',
    long: '89.244462',
  },
  {
    id: '33',
    division_id: '6',
    name: 'Thakurgaon',
    bn_name: 'ঠাকুরগাঁও',
    lat: '26.0336945',
    long: '88.4616834',
  },
  {
    id: '34',
    division_id: '1',
    name: 'Barguna',
    bn_name: 'বরগুনা',
    lat: '22.0953',
    long: '90.1121',
  },
  {
    id: '35',
    division_id: '1',
    name: 'Barishal',
    bn_name: 'বরিশাল',
    lat: '22.7010',
    long: '90.3535',
  },
  {
    id: '36',
    division_id: '1',
    name: 'Bhola',
    bn_name: 'ভোলা',
    lat: '22.685923',
    long: '90.648179',
  },
  {
    id: '37',
    division_id: '1',
    name: 'Jhalokati',
    bn_name: 'ঝালকাঠি',
    lat: '22.6406',
    long: '90.1987',
  },
  {
    id: '38',
    division_id: '1',
    name: 'Patuakhali',
    bn_name: 'পটুয়াখালী',
    lat: '22.3596316',
    long: '90.3298712',
  },
  {
    id: '39',
    division_id: '1',
    name: 'Pirojpur',
    bn_name: 'পিরোজপুর',
    lat: '22.5841',
    long: '89.9720',
  },
  {
    id: '40',
    division_id: '2',
    name: 'Bandarban',
    bn_name: 'বান্দরবান',
    lat: '22.1953275',
    long: '92.2183773',
  },
  {
    id: '41',
    division_id: '2',
    name: 'Brahmanbaria',
    bn_name: 'ব্রাহ্মণবাড়িয়া',
    lat: '23.9570904',
    long: '91.1119286',
  },
  {
    id: '42',
    division_id: '2',
    name: 'Chandpur',
    bn_name: 'চাঁদপুর',
    lat: '23.2332585',
    long: '90.6712912',
  },
  {
    id: '43',
    division_id: '2',
    name: 'Chattogram',
    bn_name: 'চট্টগ্রাম',
    lat: '22.335109',
    long: '91.834073',
  },
  {
    id: '44',
    division_id: '2',
    name: 'Cumilla',
    bn_name: 'কুমিল্লা',
    lat: '23.4682747',
    long: '91.1788135',
  },
  {
    id: '45',
    division_id: '2',
    name: "Cox's Bazar",
    bn_name: 'কক্স বাজার',
    lat: '21.4272',
    long: '92.0058',
  },
  {
    id: '46',
    division_id: '2',
    name: 'Feni',
    bn_name: 'ফেনী',
    lat: '23.0159',
    long: '91.3976',
  },
  {
    id: '47',
    division_id: '2',
    name: 'Khagrachari',
    bn_name: 'খাগড়াছড়ি',
    lat: '23.119285',
    long: '91.984663',
  },
  {
    id: '48',
    division_id: '2',
    name: 'Lakshmipur',
    bn_name: 'লক্ষ্মীপুর',
    lat: '22.942477',
    long: '90.841184',
  },
  {
    id: '49',
    division_id: '2',
    name: 'Noakhali',
    bn_name: 'নোয়াখালী',
    lat: '22.869563',
    long: '91.099398',
  },
  {
    id: '50',
    division_id: '2',
    name: 'Rangamati',
    bn_name: 'রাঙ্গামাটি',
    lat: '22.7324',
    long: '92.2985',
  },
  {
    id: '51',
    division_id: '7',
    name: 'Habiganj',
    bn_name: 'হবিগঞ্জ',
    lat: '24.374945',
    long: '91.41553',
  },
  {
    id: '52',
    division_id: '7',
    name: 'Maulvibazar',
    bn_name: 'মৌলভীবাজার',
    lat: '24.482934',
    long: '91.777417',
  },
  {
    id: '53',
    division_id: '7',
    name: 'Sunamganj',
    bn_name: 'সুনামগঞ্জ',
    lat: '25.0658042',
    long: '91.3950115',
  },
  {
    id: '54',
    division_id: '7',
    name: 'Sylhet',
    bn_name: 'সিলেট',
    lat: '24.8897956',
    long: '91.8697894',
  },
  {
    id: '55',
    division_id: '4',
    name: 'Bagerhat',
    bn_name: 'বাগেরহাট',
    lat: '22.651568',
    long: '89.785938',
  },
  {
    id: '56',
    division_id: '4',
    name: 'Chuadanga',
    bn_name: 'চুয়াডাঙ্গা',
    lat: '23.6401961',
    long: '88.841841',
  },
  {
    id: '57',
    division_id: '4',
    name: 'Jashore',
    bn_name: 'যশোর',
    lat: '23.16643',
    long: '89.2081126',
  },
  {
    id: '58',
    division_id: '4',
    name: 'Jhenaidah',
    bn_name: 'ঝিনাইদহ',
    lat: '23.5448176',
    long: '89.1539213',
  },
  {
    id: '59',
    division_id: '4',
    name: 'Khulna',
    bn_name: 'খুলনা',
    lat: '22.815774',
    long: '89.568679',
  },
  {
    id: '60',
    division_id: '4',
    name: 'Kushtia',
    bn_name: 'কুষ্টিয়া',
    lat: '23.901258',
    long: '89.120482',
  },
  {
    id: '61',
    division_id: '4',
    name: 'Magura',
    bn_name: 'মাগুরা',
    lat: '23.487337',
    long: '89.419956',
  },
  {
    id: '62',
    division_id: '4',
    name: 'Meherpur',
    bn_name: 'মেহেরপুর',
    lat: '23.762213',
    long: '88.631821',
  },
  {
    id: '63',
    division_id: '4',
    name: 'Narail',
    bn_name: 'নড়াইল',
    lat: '23.172534',
    long: '89.512672',
  },
  {
    id: '64',
    division_id: '4',
    name: 'Satkhira',
    bn_name: 'সাতক্ষীরা',
    lat: '22.7185',
    long: '89.0705',
  },
];

export const upozillas = [
  {
    id: '1',
    district_id: '34',
    name: 'Amtali',
    bn_name: 'আমতলী',
  },
  {
    id: '2',
    district_id: '34',
    name: 'Bamna',
    bn_name: 'বামনা',
  },
  {
    id: '3',
    district_id: '34',
    name: 'Barguna Sadar',
    bn_name: 'বরগুনা সদর',
  },
  {
    id: '4',
    district_id: '34',
    name: 'Betagi',
    bn_name: 'বেতাগি',
  },
  {
    id: '5',
    district_id: '34',
    name: 'Patharghata',
    bn_name: 'পাথরঘাটা',
  },
  {
    id: '6',
    district_id: '34',
    name: 'Taltali',
    bn_name: 'তালতলী',
  },
  {
    id: '7',
    district_id: '35',
    name: 'Muladi',
    bn_name: 'মুলাদি',
  },
  {
    id: '8',
    district_id: '35',
    name: 'Babuganj',
    bn_name: 'বাবুগঞ্জ',
  },
  {
    id: '9',
    district_id: '35',
    name: 'Agailjhara',
    bn_name: 'আগাইলঝরা',
  },
  {
    id: '10',
    district_id: '35',
    name: 'Barisal Sadar',
    bn_name: 'বরিশাল সদর',
  },
  {
    id: '11',
    district_id: '35',
    name: 'Bakerganj',
    bn_name: 'বাকেরগঞ্জ',
  },
  {
    id: '12',
    district_id: '35',
    name: 'Banaripara',
    bn_name: 'বানাড়িপারা',
  },
  {
    id: '13',
    district_id: '35',
    name: 'Gaurnadi',
    bn_name: 'গৌরনদী',
  },
  {
    id: '14',
    district_id: '35',
    name: 'Hizla',
    bn_name: 'হিজলা',
  },
  {
    id: '15',
    district_id: '35',
    name: 'Mehendiganj',
    bn_name: 'মেহেদিগঞ্জ ',
  },
  {
    id: '16',
    district_id: '35',
    name: 'Wazirpur',
    bn_name: 'ওয়াজিরপুর',
  },
  {
    id: '17',
    district_id: '36',
    name: 'Bhola Sadar',
    bn_name: 'ভোলা সদর',
  },
  {
    id: '18',
    district_id: '36',
    name: 'Burhanuddin',
    bn_name: 'বুরহানউদ্দিন',
  },
  {
    id: '19',
    district_id: '36',
    name: 'Char Fasson',
    bn_name: 'চর ফ্যাশন',
  },
  {
    id: '20',
    district_id: '36',
    name: 'Daulatkhan',
    bn_name: 'দৌলতখান',
  },
  {
    id: '21',
    district_id: '36',
    name: 'Lalmohan',
    bn_name: 'লালমোহন',
  },
  {
    id: '22',
    district_id: '36',
    name: 'Manpura',
    bn_name: 'মনপুরা',
  },
  {
    id: '23',
    district_id: '36',
    name: 'Tazumuddin',
    bn_name: 'তাজুমুদ্দিন',
  },
  {
    id: '24',
    district_id: '37',
    name: 'Jhalokati Sadar',
    bn_name: 'ঝালকাঠি সদর',
  },
  {
    id: '25',
    district_id: '37',
    name: 'Kathalia',
    bn_name: 'কাঁঠালিয়া',
  },
  {
    id: '26',
    district_id: '37',
    name: 'Nalchity',
    bn_name: 'নালচিতি',
  },
  {
    id: '27',
    district_id: '37',
    name: 'Rajapur',
    bn_name: 'রাজাপুর',
  },
  {
    id: '28',
    district_id: '38',
    name: 'Bauphal',
    bn_name: 'বাউফল',
  },
  {
    id: '29',
    district_id: '38',
    name: 'Dashmina',
    bn_name: 'দশমিনা',
  },
  {
    id: '30',
    district_id: '38',
    name: 'Galachipa',
    bn_name: 'গলাচিপা',
  },
  {
    id: '31',
    district_id: '38',
    name: 'Kalapara',
    bn_name: 'কালাপারা',
  },
  {
    id: '32',
    district_id: '38',
    name: 'Mirzaganj',
    bn_name: 'মির্জাগঞ্জ ',
  },
  {
    id: '33',
    district_id: '38',
    name: 'Patuakhali Sadar',
    bn_name: 'পটুয়াখালী সদর',
  },
  {
    id: '34',
    district_id: '38',
    name: 'Dumki',
    bn_name: 'ডুমকি',
  },
  {
    id: '35',
    district_id: '38',
    name: 'Rangabali',
    bn_name: 'রাঙ্গাবালি',
  },
  {
    id: '36',
    district_id: '39',
    name: 'Bhandaria',
    bn_name: 'ভ্যান্ডারিয়া',
  },
  {
    id: '37',
    district_id: '39',
    name: 'Kaukhali',
    bn_name: 'কাউখালি',
  },
  {
    id: '38',
    district_id: '39',
    name: 'Mathbaria',
    bn_name: 'মাঠবাড়িয়া',
  },
  {
    id: '39',
    district_id: '39',
    name: 'Nazirpur',
    bn_name: 'নাজিরপুর',
  },
  {
    id: '40',
    district_id: '39',
    name: 'Nesarabad',
    bn_name: 'নেসারাবাদ',
  },
  {
    id: '41',
    district_id: '39',
    name: 'Pirojpur Sadar',
    bn_name: 'পিরোজপুর সদর',
  },
  {
    id: '42',
    district_id: '39',
    name: 'Zianagar',
    bn_name: 'জিয়ানগর',
  },
  {
    id: '43',
    district_id: '40',
    name: 'Bandarban Sadar',
    bn_name: 'বান্দরবন সদর',
  },
  {
    id: '44',
    district_id: '40',
    name: 'Thanchi',
    bn_name: 'থানচি',
  },
  {
    id: '45',
    district_id: '40',
    name: 'Lama',
    bn_name: 'লামা',
  },
  {
    id: '46',
    district_id: '40',
    name: 'Naikhongchhari',
    bn_name: 'নাইখংছড়ি ',
  },
  {
    id: '47',
    district_id: '40',
    name: 'Ali kadam',
    bn_name: 'আলী কদম',
  },
  {
    id: '48',
    district_id: '40',
    name: 'Rowangchhari',
    bn_name: 'রউয়াংছড়ি ',
  },
  {
    id: '49',
    district_id: '40',
    name: 'Ruma',
    bn_name: 'রুমা',
  },
  {
    id: '50',
    district_id: '41',
    name: 'Brahmanbaria Sadar',
    bn_name: 'ব্রাহ্মণবাড়িয়া সদর',
  },
  {
    id: '51',
    district_id: '41',
    name: 'Ashuganj',
    bn_name: 'আশুগঞ্জ',
  },
  {
    id: '52',
    district_id: '41',
    name: 'Nasirnagar',
    bn_name: 'নাসির নগর',
  },
  {
    id: '53',
    district_id: '41',
    name: 'Nabinagar',
    bn_name: 'নবীনগর',
  },
  {
    id: '54',
    district_id: '41',
    name: 'Sarail',
    bn_name: 'সরাইল',
  },
  {
    id: '55',
    district_id: '41',
    name: 'Shahbazpur Town',
    bn_name: 'শাহবাজপুর টাউন',
  },
  {
    id: '56',
    district_id: '41',
    name: 'Kasba',
    bn_name: 'কসবা',
  },
  {
    id: '57',
    district_id: '41',
    name: 'Akhaura',
    bn_name: 'আখাউরা',
  },
  {
    id: '58',
    district_id: '41',
    name: 'Bancharampur',
    bn_name: 'বাঞ্ছারামপুর',
  },
  {
    id: '59',
    district_id: '41',
    name: 'Bijoynagar',
    bn_name: 'বিজয় নগর',
  },
  {
    id: '60',
    district_id: '42',
    name: 'Chandpur Sadar',
    bn_name: 'চাঁদপুর সদর',
  },
  {
    id: '61',
    district_id: '42',
    name: 'Faridganj',
    bn_name: 'ফরিদগঞ্জ',
  },
  {
    id: '62',
    district_id: '42',
    name: 'Haimchar',
    bn_name: 'হাইমচর',
  },
  {
    id: '63',
    district_id: '42',
    name: 'Haziganj',
    bn_name: 'হাজীগঞ্জ',
  },
  {
    id: '64',
    district_id: '42',
    name: 'Kachua',
    bn_name: 'কচুয়া',
  },
  {
    id: '65',
    district_id: '42',
    name: 'Matlab Uttar',
    bn_name: 'মতলব উত্তর',
  },
  {
    id: '66',
    district_id: '42',
    name: 'Matlab Dakkhin',
    bn_name: 'মতলব দক্ষিণ',
  },
  {
    id: '67',
    district_id: '42',
    name: 'Shahrasti',
    bn_name: 'শাহরাস্তি',
  },
  {
    id: '68',
    district_id: '43',
    name: 'Anwara',
    bn_name: 'আনোয়ারা',
  },
  {
    id: '69',
    district_id: '43',
    name: 'Banshkhali',
    bn_name: 'বাশখালি',
  },
  {
    id: '70',
    district_id: '43',
    name: 'Boalkhali',
    bn_name: 'বোয়ালখালি',
  },
  {
    id: '71',
    district_id: '43',
    name: 'Chandanaish',
    bn_name: 'চন্দনাইশ',
  },
  {
    id: '72',
    district_id: '43',
    name: 'Fatikchhari',
    bn_name: 'ফটিকছড়ি',
  },
  {
    id: '73',
    district_id: '43',
    name: 'Hathazari',
    bn_name: 'হাঠহাজারী',
  },
  {
    id: '74',
    district_id: '43',
    name: 'Lohagara',
    bn_name: 'লোহাগারা',
  },
  {
    id: '75',
    district_id: '43',
    name: 'Mirsharai',
    bn_name: 'মিরসরাই',
  },
  {
    id: '76',
    district_id: '43',
    name: 'Patiya',
    bn_name: 'পটিয়া',
  },
  {
    id: '77',
    district_id: '43',
    name: 'Rangunia',
    bn_name: 'রাঙ্গুনিয়া',
  },
  {
    id: '78',
    district_id: '43',
    name: 'Raozan',
    bn_name: 'রাউজান',
  },
  {
    id: '79',
    district_id: '43',
    name: 'Sandwip',
    bn_name: 'সন্দ্বীপ',
  },
  {
    id: '80',
    district_id: '43',
    name: 'Satkania',
    bn_name: 'সাতকানিয়া',
  },
  {
    id: '81',
    district_id: '43',
    name: 'Sitakunda',
    bn_name: 'সীতাকুণ্ড',
  },
  {
    id: '82',
    district_id: '44',
    name: 'Barura',
    bn_name: 'বড়ুরা',
  },
  {
    id: '83',
    district_id: '44',
    name: 'Brahmanpara',
    bn_name: 'ব্রাহ্মণপাড়া',
  },
  {
    id: '84',
    district_id: '44',
    name: 'Burichong',
    bn_name: 'বুড়িচং',
  },
  {
    id: '85',
    district_id: '44',
    name: 'Chandina',
    bn_name: 'চান্দিনা',
  },
  {
    id: '86',
    district_id: '44',
    name: 'Chauddagram',
    bn_name: 'চৌদ্দগ্রাম',
  },
  {
    id: '87',
    district_id: '44',
    name: 'Daudkandi',
    bn_name: 'দাউদকান্দি',
  },
  {
    id: '88',
    district_id: '44',
    name: 'Debidwar',
    bn_name: 'দেবীদ্বার',
  },
  {
    id: '89',
    district_id: '44',
    name: 'Homna',
    bn_name: 'হোমনা',
  },
  {
    id: '90',
    district_id: '44',
    name: 'Comilla Sadar',
    bn_name: 'কুমিল্লা সদর',
  },
  {
    id: '91',
    district_id: '44',
    name: 'Laksam',
    bn_name: 'লাকসাম',
  },
  {
    id: '92',
    district_id: '44',
    name: 'Monohorgonj',
    bn_name: 'মনোহরগঞ্জ',
  },
  {
    id: '93',
    district_id: '44',
    name: 'Meghna',
    bn_name: 'মেঘনা',
  },
  {
    id: '94',
    district_id: '44',
    name: 'Muradnagar',
    bn_name: 'মুরাদনগর',
  },
  {
    id: '95',
    district_id: '44',
    name: 'Nangalkot',
    bn_name: 'নাঙ্গালকোট',
  },
  {
    id: '96',
    district_id: '44',
    name: 'Comilla Sadar South',
    bn_name: 'কুমিল্লা সদর দক্ষিণ',
  },
  {
    id: '97',
    district_id: '44',
    name: 'Titas',
    bn_name: 'তিতাস',
  },
  {
    id: '98',
    district_id: '45',
    name: 'Chakaria',
    bn_name: 'চকরিয়া',
  },
  {
    id: '100',
    district_id: '45',
    name: "{{198}}''{{199}}",
    bn_name: 'কক্স বাজার সদর',
  },
  {
    id: '101',
    district_id: '45',
    name: 'Kutubdia',
    bn_name: 'কুতুবদিয়া',
  },
  {
    id: '102',
    district_id: '45',
    name: 'Maheshkhali',
    bn_name: 'মহেশখালী',
  },
  {
    id: '103',
    district_id: '45',
    name: 'Ramu',
    bn_name: 'রামু',
  },
  {
    id: '104',
    district_id: '45',
    name: 'Teknaf',
    bn_name: 'টেকনাফ',
  },
  {
    id: '105',
    district_id: '45',
    name: 'Ukhia',
    bn_name: 'উখিয়া',
  },
  {
    id: '106',
    district_id: '45',
    name: 'Pekua',
    bn_name: 'পেকুয়া',
  },
  {
    id: '107',
    district_id: '46',
    name: 'Feni Sadar',
    bn_name: 'ফেনী সদর',
  },
  {
    id: '108',
    district_id: '46',
    name: 'Chagalnaiya',
    bn_name: 'ছাগল নাইয়া',
  },
  {
    id: '109',
    district_id: '46',
    name: 'Daganbhyan',
    bn_name: 'দাগানভিয়া',
  },
  {
    id: '110',
    district_id: '46',
    name: 'Parshuram',
    bn_name: 'পরশুরাম',
  },
  {
    id: '111',
    district_id: '46',
    name: 'Fhulgazi',
    bn_name: 'ফুলগাজি',
  },
  {
    id: '112',
    district_id: '46',
    name: 'Sonagazi',
    bn_name: 'সোনাগাজি',
  },
  {
    id: '113',
    district_id: '47',
    name: 'Dighinala',
    bn_name: 'দিঘিনালা ',
  },
  {
    id: '114',
    district_id: '47',
    name: 'Khagrachhari',
    bn_name: 'খাগড়াছড়ি',
  },
  {
    id: '115',
    district_id: '47',
    name: 'Lakshmichhari',
    bn_name: 'লক্ষ্মীছড়ি',
  },
  {
    id: '116',
    district_id: '47',
    name: 'Mahalchhari',
    bn_name: 'মহলছড়ি',
  },
  {
    id: '117',
    district_id: '47',
    name: 'Manikchhari',
    bn_name: 'মানিকছড়ি',
  },
  {
    id: '118',
    district_id: '47',
    name: 'Matiranga',
    bn_name: 'মাটিরাঙ্গা',
  },
  {
    id: '119',
    district_id: '47',
    name: 'Panchhari',
    bn_name: 'পানছড়ি',
  },
  {
    id: '120',
    district_id: '47',
    name: 'Ramgarh',
    bn_name: 'রামগড়',
  },
  {
    id: '121',
    district_id: '48',
    name: 'Lakshmipur Sadar',
    bn_name: 'লক্ষ্মীপুর সদর',
  },
  {
    id: '122',
    district_id: '48',
    name: 'Raipur',
    bn_name: 'রায়পুর',
  },
  {
    id: '123',
    district_id: '48',
    name: 'Ramganj',
    bn_name: 'রামগঞ্জ',
  },
  {
    id: '124',
    district_id: '48',
    name: 'Ramgati',
    bn_name: 'রামগতি',
  },
  {
    id: '125',
    district_id: '48',
    name: 'Komol Nagar',
    bn_name: 'কমল নগর',
  },
  {
    id: '126',
    district_id: '49',
    name: 'Noakhali Sadar',
    bn_name: 'নোয়াখালী সদর',
  },
  {
    id: '127',
    district_id: '49',
    name: 'Begumganj',
    bn_name: 'বেগমগঞ্জ',
  },
  {
    id: '128',
    district_id: '49',
    name: 'Chatkhil',
    bn_name: 'চাটখিল',
  },
  {
    id: '129',
    district_id: '49',
    name: 'Companyganj',
    bn_name: 'কোম্পানীগঞ্জ',
  },
  {
    id: '130',
    district_id: '49',
    name: 'Shenbag',
    bn_name: 'শেনবাগ',
  },
  {
    id: '131',
    district_id: '49',
    name: 'Hatia',
    bn_name: 'হাতিয়া',
  },
  {
    id: '132',
    district_id: '49',
    name: 'Kobirhat',
    bn_name: 'কবিরহাট ',
  },
  {
    id: '133',
    district_id: '49',
    name: 'Sonaimuri',
    bn_name: 'সোনাইমুরি',
  },
  {
    id: '134',
    district_id: '49',
    name: 'Suborno Char',
    bn_name: 'সুবর্ণ চর ',
  },
  {
    id: '135',
    district_id: '50',
    name: 'Rangamati Sadar',
    bn_name: 'রাঙ্গামাটি সদর',
  },
  {
    id: '136',
    district_id: '50',
    name: 'Belaichhari',
    bn_name: 'বেলাইছড়ি',
  },
  {
    id: '137',
    district_id: '50',
    name: 'Bagaichhari',
    bn_name: 'বাঘাইছড়ি',
  },
  {
    id: '138',
    district_id: '50',
    name: 'Barkal',
    bn_name: 'বরকল',
  },
  {
    id: '139',
    district_id: '50',
    name: 'Juraichhari',
    bn_name: 'জুরাইছড়ি',
  },
  {
    id: '140',
    district_id: '50',
    name: 'Rajasthali',
    bn_name: 'রাজাস্থলি',
  },
  {
    id: '141',
    district_id: '50',
    name: 'Kaptai',
    bn_name: 'কাপ্তাই',
  },
  {
    id: '142',
    district_id: '50',
    name: 'Langadu',
    bn_name: 'লাঙ্গাডু',
  },
  {
    id: '143',
    district_id: '50',
    name: 'Nannerchar',
    bn_name: 'নান্নেরচর ',
  },
  {
    id: '144',
    district_id: '50',
    name: 'Kaukhali',
    bn_name: 'কাউখালি',
  },
  {
    id: '145',
    district_id: '1',
    name: 'Dhamrai',
    bn_name: 'ধামরাই',
  },
  {
    id: '146',
    district_id: '1',
    name: 'Dohar',
    bn_name: 'দোহার',
  },
  {
    id: '147',
    district_id: '1',
    name: 'Keraniganj',
    bn_name: 'কেরানীগঞ্জ',
  },
  {
    id: '148',
    district_id: '1',
    name: 'Nawabganj',
    bn_name: 'নবাবগঞ্জ',
  },
  {
    id: '149',
    district_id: '1',
    name: 'Savar',
    bn_name: 'সাভার',
  },
  {
    id: '150',
    district_id: '2',
    name: 'Faridpur Sadar',
    bn_name: 'ফরিদপুর সদর',
  },
  {
    id: '151',
    district_id: '2',
    name: 'Boalmari',
    bn_name: 'বোয়ালমারী',
  },
  {
    id: '152',
    district_id: '2',
    name: 'Alfadanga',
    bn_name: 'আলফাডাঙ্গা',
  },
  {
    id: '153',
    district_id: '2',
    name: 'Madhukhali',
    bn_name: 'মধুখালি',
  },
  {
    id: '154',
    district_id: '2',
    name: 'Bhanga',
    bn_name: 'ভাঙ্গা',
  },
  {
    id: '155',
    district_id: '2',
    name: 'Nagarkanda',
    bn_name: 'নগরকান্ড',
  },
  {
    id: '156',
    district_id: '2',
    name: 'Charbhadrasan',
    bn_name: 'চরভদ্রাসন ',
  },
  {
    id: '157',
    district_id: '2',
    name: 'Sadarpur',
    bn_name: 'সদরপুর',
  },
  {
    id: '158',
    district_id: '2',
    name: 'Shaltha',
    bn_name: 'শালথা',
  },
  {
    id: '159',
    district_id: '3',
    name: 'Gazipur Sadar-Joydebpur',
    bn_name: 'গাজীপুর সদর',
  },
  {
    id: '160',
    district_id: '3',
    name: 'Kaliakior',
    bn_name: 'কালিয়াকৈর',
  },
  {
    id: '161',
    district_id: '3',
    name: 'Kapasia',
    bn_name: 'কাপাসিয়া',
  },
  {
    id: '162',
    district_id: '3',
    name: 'Sripur',
    bn_name: 'শ্রীপুর',
  },
  {
    id: '163',
    district_id: '3',
    name: 'Kaliganj',
    bn_name: 'কালীগঞ্জ',
  },
  {
    id: '164',
    district_id: '3',
    name: 'Tongi',
    bn_name: 'টঙ্গি',
  },
  {
    id: '165',
    district_id: '4',
    name: 'Gopalganj Sadar',
    bn_name: 'গোপালগঞ্জ সদর',
  },
  {
    id: '166',
    district_id: '4',
    name: 'Kashiani',
    bn_name: 'কাশিয়ানি',
  },
  {
    id: '167',
    district_id: '4',
    name: 'Kotalipara',
    bn_name: 'কোটালিপাড়া',
  },
  {
    id: '168',
    district_id: '4',
    name: 'Muksudpur',
    bn_name: 'মুকসুদপুর',
  },
  {
    id: '169',
    district_id: '4',
    name: 'Tungipara',
    bn_name: 'টুঙ্গিপাড়া',
  },
  {
    id: '170',
    district_id: '5',
    name: 'Dewanganj',
    bn_name: 'দেওয়ানগঞ্জ',
  },
  {
    id: '171',
    district_id: '5',
    name: 'Baksiganj',
    bn_name: 'বকসিগঞ্জ',
  },
  {
    id: '172',
    district_id: '5',
    name: 'Islampur',
    bn_name: 'ইসলামপুর',
  },
  {
    id: '173',
    district_id: '5',
    name: 'Jamalpur Sadar',
    bn_name: 'জামালপুর সদর',
  },
  {
    id: '174',
    district_id: '5',
    name: 'Madarganj',
    bn_name: 'মাদারগঞ্জ',
  },
  {
    id: '175',
    district_id: '5',
    name: 'Melandaha',
    bn_name: 'মেলানদাহা',
  },
  {
    id: '176',
    district_id: '5',
    name: 'Sarishabari',
    bn_name: 'সরিষাবাড়ি ',
  },
  {
    id: '177',
    district_id: '5',
    name: 'Narundi Police I.C',
    bn_name: 'নারুন্দি',
  },
  {
    id: '178',
    district_id: '6',
    name: 'Astagram',
    bn_name: 'অষ্টগ্রাম',
  },
  {
    id: '179',
    district_id: '6',
    name: 'Bajitpur',
    bn_name: 'বাজিতপুর',
  },
  {
    id: '180',
    district_id: '6',
    name: 'Bhairab',
    bn_name: 'ভৈরব',
  },
  {
    id: '181',
    district_id: '6',
    name: 'Hossainpur',
    bn_name: 'হোসেনপুর ',
  },
  {
    id: '182',
    district_id: '6',
    name: 'Itna',
    bn_name: 'ইটনা',
  },
  {
    id: '183',
    district_id: '6',
    name: 'Karimganj',
    bn_name: 'করিমগঞ্জ',
  },
  {
    id: '184',
    district_id: '6',
    name: 'Katiadi',
    bn_name: 'কতিয়াদি',
  },
  {
    id: '185',
    district_id: '6',
    name: 'Kishoreganj Sadar',
    bn_name: 'কিশোরগঞ্জ সদর',
  },
  {
    id: '186',
    district_id: '6',
    name: 'Kuliarchar',
    bn_name: 'কুলিয়ারচর',
  },
  {
    id: '187',
    district_id: '6',
    name: 'Mithamain',
    bn_name: 'মিঠামাইন',
  },
  {
    id: '188',
    district_id: '6',
    name: 'Nikli',
    bn_name: 'নিকলি',
  },
  {
    id: '189',
    district_id: '6',
    name: 'Pakundia',
    bn_name: 'পাকুন্ডা',
  },
  {
    id: '190',
    district_id: '6',
    name: 'Tarail',
    bn_name: 'তাড়াইল',
  },
  {
    id: '191',
    district_id: '7',
    name: 'Madaripur Sadar',
    bn_name: 'মাদারীপুর সদর',
  },
  {
    id: '192',
    district_id: '7',
    name: 'Kalkini',
    bn_name: 'কালকিনি',
  },
  {
    id: '193',
    district_id: '7',
    name: 'Rajoir',
    bn_name: 'রাজইর',
  },
  {
    id: '194',
    district_id: '7',
    name: 'Shibchar',
    bn_name: 'শিবচর',
  },
  {
    id: '195',
    district_id: '8',
    name: 'Manikganj Sadar',
    bn_name: 'মানিকগঞ্জ সদর',
  },
  {
    id: '196',
    district_id: '8',
    name: 'Singair',
    bn_name: 'সিঙ্গাইর',
  },
  {
    id: '197',
    district_id: '8',
    name: 'Shibalaya',
    bn_name: 'শিবালয়',
  },
  {
    id: '198',
    district_id: '8',
    name: 'Saturia',
    bn_name: 'সাটুরিয়া',
  },
  {
    id: '199',
    district_id: '8',
    name: 'Harirampur',
    bn_name: 'হরিরামপুর',
  },
  {
    id: '200',
    district_id: '8',
    name: 'Ghior',
    bn_name: 'ঘিওর',
  },
  {
    id: '201',
    district_id: '8',
    name: 'Daulatpur',
    bn_name: 'দৌলতপুর',
  },
  {
    id: '202',
    district_id: '9',
    name: 'Lohajang',
    bn_name: 'লোহাজং',
  },
  {
    id: '203',
    district_id: '9',
    name: 'Sreenagar',
    bn_name: 'শ্রীনগর',
  },
  {
    id: '204',
    district_id: '9',
    name: 'Munshiganj Sadar',
    bn_name: 'মুন্সিগঞ্জ সদর',
  },
  {
    id: '205',
    district_id: '9',
    name: 'Sirajdikhan',
    bn_name: 'সিরাজদিখান',
  },
  {
    id: '206',
    district_id: '9',
    name: 'Tongibari',
    bn_name: 'টঙ্গিবাড়ি',
  },
  {
    id: '207',
    district_id: '9',
    name: 'Gazaria',
    bn_name: 'গজারিয়া',
  },
  {
    id: '208',
    district_id: '10',
    name: 'Bhaluka',
    bn_name: 'ভালুকা',
  },
  {
    id: '209',
    district_id: '10',
    name: 'Trishal',
    bn_name: 'ত্রিশাল',
  },
  {
    id: '210',
    district_id: '10',
    name: 'Haluaghat',
    bn_name: 'হালুয়াঘাট',
  },
  {
    id: '211',
    district_id: '10',
    name: 'Muktagachha',
    bn_name: 'মুক্তাগাছা',
  },
  {
    id: '212',
    district_id: '10',
    name: 'Dhobaura',
    bn_name: 'ধবারুয়া',
  },
  {
    id: '213',
    district_id: '10',
    name: 'Fulbaria',
    bn_name: 'ফুলবাড়িয়া',
  },
  {
    id: '214',
    district_id: '10',
    name: 'Gaffargaon',
    bn_name: 'গফরগাঁও',
  },
  {
    id: '215',
    district_id: '10',
    name: 'Gauripur',
    bn_name: 'গৌরিপুর',
  },
  {
    id: '216',
    district_id: '10',
    name: 'Ishwarganj',
    bn_name: 'ঈশ্বরগঞ্জ',
  },
  {
    id: '217',
    district_id: '10',
    name: 'Mymensingh Sadar',
    bn_name: 'ময়মনসিং সদর',
  },
  {
    id: '218',
    district_id: '10',
    name: 'Nandail',
    bn_name: 'নন্দাইল',
  },
  {
    id: '219',
    district_id: '10',
    name: 'Phulpur',
    bn_name: 'ফুলপুর',
  },
  {
    id: '220',
    district_id: '11',
    name: 'Araihazar',
    bn_name: 'আড়াইহাজার',
  },
  {
    id: '221',
    district_id: '11',
    name: 'Sonargaon',
    bn_name: 'সোনারগাঁও',
  },
  {
    id: '222',
    district_id: '11',
    name: 'Bandar',
    bn_name: 'বান্দার',
  },
  {
    id: '223',
    district_id: '11',
    name: 'Naryanganj Sadar',
    bn_name: 'নারায়ানগঞ্জ সদর',
  },
  {
    id: '224',
    district_id: '11',
    name: 'Rupganj',
    bn_name: 'রূপগঞ্জ',
  },
  {
    id: '225',
    district_id: '11',
    name: 'Siddirgonj',
    bn_name: 'সিদ্ধিরগঞ্জ',
  },
  {
    id: '226',
    district_id: '12',
    name: 'Belabo',
    bn_name: 'বেলাবো',
  },
  {
    id: '227',
    district_id: '12',
    name: 'Monohardi',
    bn_name: 'মনোহরদি',
  },
  {
    id: '228',
    district_id: '12',
    name: 'Narsingdi Sadar',
    bn_name: 'নরসিংদী সদর',
  },
  {
    id: '229',
    district_id: '12',
    name: 'Palash',
    bn_name: 'পলাশ',
  },
  {
    id: '230',
    district_id: '12',
    name: 'Raipura, Narsingdi',
    bn_name: 'রায়পুর',
  },
  {
    id: '231',
    district_id: '12',
    name: 'Shibpur',
    bn_name: 'শিবপুর',
  },
  {
    id: '232',
    district_id: '13',
    name: 'Kendua Upazilla',
    bn_name: 'কেন্দুয়া',
  },
  {
    id: '233',
    district_id: '13',
    name: 'Atpara Upazilla',
    bn_name: 'আটপাড়া',
  },
  {
    id: '234',
    district_id: '13',
    name: 'Barhatta Upazilla',
    bn_name: 'বরহাট্টা',
  },
  {
    id: '235',
    district_id: '13',
    name: 'Durgapur Upazilla',
    bn_name: 'দুর্গাপুর',
  },
  {
    id: '236',
    district_id: '13',
    name: 'Kalmakanda Upazilla',
    bn_name: 'কলমাকান্দা',
  },
  {
    id: '237',
    district_id: '13',
    name: 'Madan Upazilla',
    bn_name: 'মদন',
  },
  {
    id: '238',
    district_id: '13',
    name: 'Mohanganj Upazilla',
    bn_name: 'মোহনগঞ্জ',
  },
  {
    id: '239',
    district_id: '13',
    name: 'Netrakona-S Upazilla',
    bn_name: 'নেত্রকোনা সদর',
  },
  {
    id: '240',
    district_id: '13',
    name: 'Purbadhala Upazilla',
    bn_name: 'পূর্বধলা',
  },
  {
    id: '241',
    district_id: '13',
    name: 'Khaliajuri Upazilla',
    bn_name: 'খালিয়াজুরি',
  },
  {
    id: '242',
    district_id: '14',
    name: 'Baliakandi',
    bn_name: 'বালিয়াকান্দি',
  },
  {
    id: '243',
    district_id: '14',
    name: 'Goalandaghat',
    bn_name: 'গোয়ালন্দ ঘাট',
  },
  {
    id: '244',
    district_id: '14',
    name: 'Pangsha',
    bn_name: 'পাংশা',
  },
  {
    id: '245',
    district_id: '14',
    name: 'Kalukhali',
    bn_name: 'কালুখালি',
  },
  {
    id: '246',
    district_id: '14',
    name: 'Rajbari Sadar',
    bn_name: 'রাজবাড়ি সদর',
  },
  {
    id: '247',
    district_id: '15',
    name: 'Shariatpur Sadar -Palong',
    bn_name: 'শরীয়তপুর সদর ',
  },
  {
    id: '248',
    district_id: '15',
    name: 'Damudya',
    bn_name: 'দামুদিয়া',
  },
  {
    id: '249',
    district_id: '15',
    name: 'Naria',
    bn_name: 'নড়িয়া',
  },
  {
    id: '250',
    district_id: '15',
    name: 'Jajira',
    bn_name: 'জাজিরা',
  },
  {
    id: '251',
    district_id: '15',
    name: 'Bhedarganj',
    bn_name: 'ভেদারগঞ্জ',
  },
  {
    id: '252',
    district_id: '15',
    name: 'Gosairhat',
    bn_name: 'গোসাইর হাট ',
  },
  {
    id: '253',
    district_id: '16',
    name: 'Jhenaigati',
    bn_name: 'ঝিনাইগাতি',
  },
  {
    id: '254',
    district_id: '16',
    name: 'Nakla',
    bn_name: 'নাকলা',
  },
  {
    id: '255',
    district_id: '16',
    name: 'Nalitabari',
    bn_name: 'নালিতাবাড়ি',
  },
  {
    id: '256',
    district_id: '16',
    name: 'Sherpur Sadar',
    bn_name: 'শেরপুর সদর',
  },
  {
    id: '257',
    district_id: '16',
    name: 'Sreebardi',
    bn_name: 'শ্রীবরদি',
  },
  {
    id: '258',
    district_id: '17',
    name: 'Tangail Sadar',
    bn_name: 'টাঙ্গাইল সদর',
  },
  {
    id: '259',
    district_id: '17',
    name: 'Sakhipur',
    bn_name: 'সখিপুর',
  },
  {
    id: '260',
    district_id: '17',
    name: 'Basail',
    bn_name: 'বসাইল',
  },
  {
    id: '261',
    district_id: '17',
    name: 'Madhupur',
    bn_name: 'মধুপুর',
  },
  {
    id: '262',
    district_id: '17',
    name: 'Ghatail',
    bn_name: 'ঘাটাইল',
  },
  {
    id: '263',
    district_id: '17',
    name: 'Kalihati',
    bn_name: 'কালিহাতি',
  },
  {
    id: '264',
    district_id: '17',
    name: 'Nagarpur',
    bn_name: 'নগরপুর',
  },
  {
    id: '265',
    district_id: '17',
    name: 'Mirzapur',
    bn_name: 'মির্জাপুর',
  },
  {
    id: '266',
    district_id: '17',
    name: 'Gopalpur',
    bn_name: 'গোপালপুর',
  },
  {
    id: '267',
    district_id: '17',
    name: 'Delduar',
    bn_name: 'দেলদুয়ার',
  },
  {
    id: '268',
    district_id: '17',
    name: 'Bhuapur',
    bn_name: 'ভুয়াপুর',
  },
  {
    id: '269',
    district_id: '17',
    name: 'Dhanbari',
    bn_name: 'ধানবাড়ি',
  },
  {
    id: '270',
    district_id: '55',
    name: 'Bagerhat Sadar',
    bn_name: 'বাগেরহাট সদর',
  },
  {
    id: '271',
    district_id: '55',
    name: 'Chitalmari',
    bn_name: 'চিতলমাড়ি',
  },
  {
    id: '272',
    district_id: '55',
    name: 'Fakirhat',
    bn_name: 'ফকিরহাট',
  },
  {
    id: '273',
    district_id: '55',
    name: 'Kachua',
    bn_name: 'কচুয়া',
  },
  {
    id: '274',
    district_id: '55',
    name: 'Mollahat',
    bn_name: 'মোল্লাহাট ',
  },
  {
    id: '275',
    district_id: '55',
    name: 'Mongla',
    bn_name: 'মংলা',
  },
  {
    id: '276',
    district_id: '55',
    name: 'Morrelganj',
    bn_name: 'মরেলগঞ্জ',
  },
  {
    id: '277',
    district_id: '55',
    name: 'Rampal',
    bn_name: 'রামপাল',
  },
  {
    id: '278',
    district_id: '55',
    name: 'Sarankhola',
    bn_name: 'স্মরণখোলা',
  },
  {
    id: '279',
    district_id: '56',
    name: 'Damurhuda',
    bn_name: 'দামুরহুদা',
  },
  {
    id: '280',
    district_id: '56',
    name: 'Chuadanga-S',
    bn_name: 'চুয়াডাঙ্গা সদর',
  },
  {
    id: '281',
    district_id: '56',
    name: 'Jibannagar',
    bn_name: 'জীবন নগর ',
  },
  {
    id: '282',
    district_id: '56',
    name: 'Alamdanga',
    bn_name: 'আলমডাঙ্গা',
  },
  {
    id: '283',
    district_id: '57',
    name: 'Abhaynagar',
    bn_name: 'অভয়নগর',
  },
  {
    id: '284',
    district_id: '57',
    name: 'Keshabpur',
    bn_name: 'কেশবপুর',
  },
  {
    id: '285',
    district_id: '57',
    name: 'Bagherpara',
    bn_name: 'বাঘের পাড়া ',
  },
  {
    id: '286',
    district_id: '57',
    name: 'Jessore Sadar',
    bn_name: 'যশোর সদর',
  },
  {
    id: '287',
    district_id: '57',
    name: 'Chaugachha',
    bn_name: 'চৌগাছা',
  },
  {
    id: '288',
    district_id: '57',
    name: 'Manirampur',
    bn_name: 'মনিরামপুর ',
  },
  {
    id: '289',
    district_id: '57',
    name: 'Jhikargachha',
    bn_name: 'ঝিকরগাছা',
  },
  {
    id: '290',
    district_id: '57',
    name: 'Sharsha',
    bn_name: 'সারশা',
  },
  {
    id: '291',
    district_id: '58',
    name: 'Jhenaidah Sadar',
    bn_name: 'ঝিনাইদহ সদর',
  },
  {
    id: '292',
    district_id: '58',
    name: 'Maheshpur',
    bn_name: 'মহেশপুর',
  },
  {
    id: '293',
    district_id: '58',
    name: 'Kaliganj',
    bn_name: 'কালীগঞ্জ',
  },
  {
    id: '294',
    district_id: '58',
    name: 'Kotchandpur',
    bn_name: 'কোট চাঁদপুর ',
  },
  {
    id: '295',
    district_id: '58',
    name: 'Shailkupa',
    bn_name: 'শৈলকুপা',
  },
  {
    id: '296',
    district_id: '58',
    name: 'Harinakunda',
    bn_name: 'হাড়িনাকুন্দা',
  },
  {
    id: '297',
    district_id: '59',
    name: 'Terokhada',
    bn_name: 'তেরোখাদা',
  },
  {
    id: '298',
    district_id: '59',
    name: 'Batiaghata',
    bn_name: 'বাটিয়াঘাটা ',
  },
  {
    id: '299',
    district_id: '59',
    name: 'Dacope',
    bn_name: 'ডাকপে',
  },
  {
    id: '300',
    district_id: '59',
    name: 'Dumuria',
    bn_name: 'ডুমুরিয়া',
  },
  {
    id: '301',
    district_id: '59',
    name: 'Dighalia',
    bn_name: 'দিঘলিয়া',
  },
  {
    id: '302',
    district_id: '59',
    name: 'Koyra',
    bn_name: 'কয়ড়া',
  },
  {
    id: '303',
    district_id: '59',
    name: 'Paikgachha',
    bn_name: 'পাইকগাছা',
  },
  {
    id: '304',
    district_id: '59',
    name: 'Phultala',
    bn_name: 'ফুলতলা',
  },
  {
    id: '305',
    district_id: '59',
    name: 'Rupsa',
    bn_name: 'রূপসা',
  },
  {
    id: '306',
    district_id: '60',
    name: 'Kushtia Sadar',
    bn_name: 'কুষ্টিয়া সদর',
  },
  {
    id: '307',
    district_id: '60',
    name: 'Kumarkhali',
    bn_name: 'কুমারখালি',
  },
  {
    id: '308',
    district_id: '60',
    name: 'Daulatpur',
    bn_name: 'দৌলতপুর',
  },
  {
    id: '309',
    district_id: '60',
    name: 'Mirpur',
    bn_name: 'মিরপুর',
  },
  {
    id: '310',
    district_id: '60',
    name: 'Bheramara',
    bn_name: 'ভেরামারা',
  },
  {
    id: '311',
    district_id: '60',
    name: 'Khoksa',
    bn_name: 'খোকসা',
  },
  {
    id: '312',
    district_id: '61',
    name: 'Magura Sadar',
    bn_name: 'মাগুরা সদর',
  },
  {
    id: '313',
    district_id: '61',
    name: 'Mohammadpur',
    bn_name: 'মোহাম্মাদপুর',
  },
  {
    id: '314',
    district_id: '61',
    name: 'Shalikha',
    bn_name: 'শালিখা',
  },
  {
    id: '315',
    district_id: '61',
    name: 'Sreepur',
    bn_name: 'শ্রীপুর',
  },
  {
    id: '316',
    district_id: '62',
    name: 'angni',
    bn_name: 'আংনি',
  },
  {
    id: '317',
    district_id: '62',
    name: 'Mujib Nagar',
    bn_name: 'মুজিব নগর',
  },
  {
    id: '318',
    district_id: '62',
    name: 'Meherpur-S',
    bn_name: 'মেহেরপুর সদর',
  },
  {
    id: '319',
    district_id: '63',
    name: 'Narail-S Upazilla',
    bn_name: 'নড়াইল সদর',
  },
  {
    id: '320',
    district_id: '63',
    name: 'Lohagara Upazilla',
    bn_name: 'লোহাগাড়া',
  },
  {
    id: '321',
    district_id: '63',
    name: 'Kalia Upazilla',
    bn_name: 'কালিয়া',
  },
  {
    id: '322',
    district_id: '64',
    name: 'Satkhira Sadar',
    bn_name: 'সাতক্ষীরা সদর',
  },
  {
    id: '323',
    district_id: '64',
    name: 'Assasuni',
    bn_name: 'আসসাশুনি ',
  },
  {
    id: '324',
    district_id: '64',
    name: 'Debhata',
    bn_name: 'দেভাটা',
  },
  {
    id: '325',
    district_id: '64',
    name: 'Tala',
    bn_name: 'তালা',
  },
  {
    id: '326',
    district_id: '64',
    name: 'Kalaroa',
    bn_name: 'কলরোয়া',
  },
  {
    id: '327',
    district_id: '64',
    name: 'Kaliganj',
    bn_name: 'কালীগঞ্জ',
  },
  {
    id: '328',
    district_id: '64',
    name: 'Shyamnagar',
    bn_name: 'শ্যামনগর',
  },
  {
    id: '329',
    district_id: '18',
    name: 'Adamdighi',
    bn_name: 'আদমদিঘী',
  },
  {
    id: '330',
    district_id: '18',
    name: 'Bogra Sadar',
    bn_name: 'বগুড়া সদর',
  },
  {
    id: '331',
    district_id: '18',
    name: 'Sherpur',
    bn_name: 'শেরপুর',
  },
  {
    id: '332',
    district_id: '18',
    name: 'Dhunat',
    bn_name: 'ধুনট',
  },
  {
    id: '333',
    district_id: '18',
    name: 'Dhupchanchia',
    bn_name: 'দুপচাচিয়া',
  },
  {
    id: '334',
    district_id: '18',
    name: 'Gabtali',
    bn_name: 'গাবতলি',
  },
  {
    id: '335',
    district_id: '18',
    name: 'Kahaloo',
    bn_name: 'কাহালু',
  },
  {
    id: '336',
    district_id: '18',
    name: 'Nandigram',
    bn_name: 'নন্দিগ্রাম',
  },
  {
    id: '337',
    district_id: '18',
    name: 'Sahajanpur',
    bn_name: 'শাহজাহানপুর',
  },
  {
    id: '338',
    district_id: '18',
    name: 'Sariakandi',
    bn_name: 'সারিয়াকান্দি',
  },
  {
    id: '339',
    district_id: '18',
    name: 'Shibganj',
    bn_name: 'শিবগঞ্জ',
  },
  {
    id: '340',
    district_id: '18',
    name: 'Sonatala',
    bn_name: 'সোনাতলা',
  },
  {
    id: '341',
    district_id: '19',
    name: 'Joypurhat S',
    bn_name: 'জয়পুরহাট সদর',
  },
  {
    id: '342',
    district_id: '19',
    name: 'Akkelpur',
    bn_name: 'আক্কেলপুর',
  },
  {
    id: '343',
    district_id: '19',
    name: 'Kalai',
    bn_name: 'কালাই',
  },
  {
    id: '344',
    district_id: '19',
    name: 'Khetlal',
    bn_name: 'খেতলাল',
  },
  {
    id: '345',
    district_id: '19',
    name: 'Panchbibi',
    bn_name: 'পাঁচবিবি',
  },
  {
    id: '346',
    district_id: '20',
    name: 'Naogaon Sadar',
    bn_name: 'নওগাঁ সদর',
  },
  {
    id: '347',
    district_id: '20',
    name: 'Mohadevpur',
    bn_name: 'মহাদেবপুর',
  },
  {
    id: '348',
    district_id: '20',
    name: 'Manda',
    bn_name: 'মান্দা',
  },
  {
    id: '349',
    district_id: '20',
    name: 'Niamatpur',
    bn_name: 'নিয়ামতপুর',
  },
  {
    id: '350',
    district_id: '20',
    name: 'Atrai',
    bn_name: 'আত্রাই',
  },
  {
    id: '351',
    district_id: '20',
    name: 'Raninagar',
    bn_name: 'রাণীনগর',
  },
  {
    id: '352',
    district_id: '20',
    name: 'Patnitala',
    bn_name: 'পত্নীতলা',
  },
  {
    id: '353',
    district_id: '20',
    name: 'Dhamoirhat',
    bn_name: 'ধামইরহাট ',
  },
  {
    id: '354',
    district_id: '20',
    name: 'Sapahar',
    bn_name: 'সাপাহার',
  },
  {
    id: '355',
    district_id: '20',
    name: 'Porsha',
    bn_name: 'পোরশা',
  },
  {
    id: '356',
    district_id: '20',
    name: 'Badalgachhi',
    bn_name: 'বদলগাছি',
  },
  {
    id: '357',
    district_id: '21',
    name: 'Natore Sadar',
    bn_name: 'নাটোর সদর',
  },
  {
    id: '358',
    district_id: '21',
    name: 'Baraigram',
    bn_name: 'বড়াইগ্রাম',
  },
  {
    id: '359',
    district_id: '21',
    name: 'Bagatipara',
    bn_name: 'বাগাতিপাড়া',
  },
  {
    id: '360',
    district_id: '21',
    name: 'Lalpur',
    bn_name: 'লালপুর',
  },
  {
    id: '361',
    district_id: '21',
    name: 'Natore Sadar',
    bn_name: 'নাটোর সদর',
  },
  {
    id: '362',
    district_id: '21',
    name: 'Baraigram',
    bn_name: 'বড়াই গ্রাম',
  },
  {
    id: '363',
    district_id: '22',
    name: 'Bholahat',
    bn_name: 'ভোলাহাট',
  },
  {
    id: '364',
    district_id: '22',
    name: 'Gomastapur',
    bn_name: 'গোমস্তাপুর',
  },
  {
    id: '365',
    district_id: '22',
    name: 'Nachole',
    bn_name: 'নাচোল',
  },
  {
    id: '366',
    district_id: '22',
    name: 'Nawabganj Sadar',
    bn_name: 'নবাবগঞ্জ সদর',
  },
  {
    id: '367',
    district_id: '22',
    name: 'Shibganj',
    bn_name: 'শিবগঞ্জ',
  },
  {
    id: '368',
    district_id: '23',
    name: 'Atgharia',
    bn_name: 'আটঘরিয়া',
  },
  {
    id: '369',
    district_id: '23',
    name: 'Bera',
    bn_name: 'বেড়া',
  },
  {
    id: '370',
    district_id: '23',
    name: 'Bhangura',
    bn_name: 'ভাঙ্গুরা',
  },
  {
    id: '371',
    district_id: '23',
    name: 'Chatmohar',
    bn_name: 'চাটমোহর',
  },
  {
    id: '372',
    district_id: '23',
    name: 'Faridpur',
    bn_name: 'ফরিদপুর',
  },
  {
    id: '373',
    district_id: '23',
    name: 'Ishwardi',
    bn_name: 'ঈশ্বরদী',
  },
  {
    id: '374',
    district_id: '23',
    name: 'Pabna Sadar',
    bn_name: 'পাবনা সদর',
  },
  {
    id: '375',
    district_id: '23',
    name: 'Santhia',
    bn_name: 'সাথিয়া',
  },
  {
    id: '376',
    district_id: '23',
    name: 'Sujanagar',
    bn_name: 'সুজানগর',
  },
  {
    id: '377',
    district_id: '24',
    name: 'Bagha',
    bn_name: 'বাঘা',
  },
  {
    id: '378',
    district_id: '24',
    name: 'Bagmara',
    bn_name: 'বাগমারা',
  },
  {
    id: '379',
    district_id: '24',
    name: 'Charghat',
    bn_name: 'চারঘাট',
  },
  {
    id: '380',
    district_id: '24',
    name: 'Durgapur',
    bn_name: 'দুর্গাপুর',
  },
  {
    id: '381',
    district_id: '24',
    name: 'Godagari',
    bn_name: 'গোদাগারি',
  },
  {
    id: '382',
    district_id: '24',
    name: 'Mohanpur',
    bn_name: 'মোহনপুর',
  },
  {
    id: '383',
    district_id: '24',
    name: 'Paba',
    bn_name: 'পবা',
  },
  {
    id: '384',
    district_id: '24',
    name: 'Puthia',
    bn_name: 'পুঠিয়া',
  },
  {
    id: '385',
    district_id: '24',
    name: 'Tanore',
    bn_name: 'তানোর',
  },
  {
    id: '386',
    district_id: '25',
    name: 'Sirajganj Sadar',
    bn_name: 'সিরাজগঞ্জ সদর',
  },
  {
    id: '387',
    district_id: '25',
    name: 'Belkuchi',
    bn_name: 'বেলকুচি',
  },
  {
    id: '388',
    district_id: '25',
    name: 'Chauhali',
    bn_name: 'চৌহালি',
  },
  {
    id: '389',
    district_id: '25',
    name: 'Kamarkhanda',
    bn_name: 'কামারখান্দা',
  },
  {
    id: '390',
    district_id: '25',
    name: 'Kazipur',
    bn_name: 'কাজীপুর',
  },
  {
    id: '391',
    district_id: '25',
    name: 'Raiganj',
    bn_name: 'রায়গঞ্জ',
  },
  {
    id: '392',
    district_id: '25',
    name: 'Shahjadpur',
    bn_name: 'শাহজাদপুর',
  },
  {
    id: '393',
    district_id: '25',
    name: 'Tarash',
    bn_name: 'তারাশ',
  },
  {
    id: '394',
    district_id: '25',
    name: 'Ullahpara',
    bn_name: 'উল্লাপাড়া',
  },
  {
    id: '395',
    district_id: '26',
    name: 'Birampur',
    bn_name: 'বিরামপুর',
  },
  {
    id: '396',
    district_id: '26',
    name: 'Birganj',
    bn_name: 'বীরগঞ্জ',
  },
  {
    id: '397',
    district_id: '26',
    name: 'Biral',
    bn_name: 'বিড়াল',
  },
  {
    id: '398',
    district_id: '26',
    name: 'Bochaganj',
    bn_name: 'বোচাগঞ্জ',
  },
  {
    id: '399',
    district_id: '26',
    name: 'Chirirbandar',
    bn_name: 'চিরিরবন্দর',
  },
  {
    id: '400',
    district_id: '26',
    name: 'Phulbari',
    bn_name: 'ফুলবাড়ি',
  },
  {
    id: '401',
    district_id: '26',
    name: 'Ghoraghat',
    bn_name: 'ঘোড়াঘাট',
  },
  {
    id: '402',
    district_id: '26',
    name: 'Hakimpur',
    bn_name: 'হাকিমপুর',
  },
  {
    id: '403',
    district_id: '26',
    name: 'Kaharole',
    bn_name: 'কাহারোল',
  },
  {
    id: '404',
    district_id: '26',
    name: 'Khansama',
    bn_name: 'খানসামা',
  },
  {
    id: '405',
    district_id: '26',
    name: 'Dinajpur Sadar',
    bn_name: 'দিনাজপুর সদর',
  },
  {
    id: '406',
    district_id: '26',
    name: 'Nawabganj',
    bn_name: 'নবাবগঞ্জ',
  },
  {
    id: '407',
    district_id: '26',
    name: 'Parbatipur',
    bn_name: 'পার্বতীপুর',
  },
  {
    id: '408',
    district_id: '27',
    name: 'Fulchhari',
    bn_name: 'ফুলছড়ি',
  },
  {
    id: '409',
    district_id: '27',
    name: 'Gaibandha sadar',
    bn_name: 'গাইবান্ধা সদর',
  },
  {
    id: '410',
    district_id: '27',
    name: 'Gobindaganj',
    bn_name: 'গোবিন্দগঞ্জ',
  },
  {
    id: '411',
    district_id: '27',
    name: 'Palashbari',
    bn_name: 'পলাশবাড়ী',
  },
  {
    id: '412',
    district_id: '27',
    name: 'Sadullapur',
    bn_name: 'সাদুল্যাপুর',
  },
  {
    id: '413',
    district_id: '27',
    name: 'Saghata',
    bn_name: 'সাঘাটা',
  },
  {
    id: '414',
    district_id: '27',
    name: 'Sundarganj',
    bn_name: 'সুন্দরগঞ্জ',
  },
  {
    id: '415',
    district_id: '28',
    name: 'Kurigram Sadar',
    bn_name: 'কুড়িগ্রাম সদর',
  },
  {
    id: '416',
    district_id: '28',
    name: 'Nageshwari',
    bn_name: 'নাগেশ্বরী',
  },
  {
    id: '417',
    district_id: '28',
    name: 'Bhurungamari',
    bn_name: 'ভুরুঙ্গামারি',
  },
  {
    id: '418',
    district_id: '28',
    name: 'Phulbari',
    bn_name: 'ফুলবাড়ি',
  },
  {
    id: '419',
    district_id: '28',
    name: 'Rajarhat',
    bn_name: 'রাজারহাট',
  },
  {
    id: '420',
    district_id: '28',
    name: 'Ulipur',
    bn_name: 'উলিপুর',
  },
  {
    id: '421',
    district_id: '28',
    name: 'Chilmari',
    bn_name: 'চিলমারি',
  },
  {
    id: '422',
    district_id: '28',
    name: 'Rowmari',
    bn_name: 'রউমারি',
  },
  {
    id: '423',
    district_id: '28',
    name: 'Char Rajibpur',
    bn_name: 'চর রাজিবপুর',
  },
  {
    id: '424',
    district_id: '29',
    name: 'Lalmanirhat Sadar',
    bn_name: 'লালমনিরহাট সদর',
  },
  {
    id: '425',
    district_id: '29',
    name: 'Aditmari',
    bn_name: 'আদিতমারি',
  },
  {
    id: '426',
    district_id: '29',
    name: 'Kaliganj',
    bn_name: 'কালীগঞ্জ',
  },
  {
    id: '427',
    district_id: '29',
    name: 'Hatibandha',
    bn_name: 'হাতিবান্ধা',
  },
  {
    id: '428',
    district_id: '29',
    name: 'Patgram',
    bn_name: 'পাটগ্রাম',
  },
  {
    id: '429',
    district_id: '30',
    name: 'Nilphamari Sadar',
    bn_name: 'নীলফামারী সদর',
  },
  {
    id: '430',
    district_id: '30',
    name: 'Saidpur',
    bn_name: 'সৈয়দপুর',
  },
  {
    id: '431',
    district_id: '30',
    name: 'Jaldhaka',
    bn_name: 'জলঢাকা',
  },
  {
    id: '432',
    district_id: '30',
    name: 'Kishoreganj',
    bn_name: 'কিশোরগঞ্জ',
  },
  {
    id: '433',
    district_id: '30',
    name: 'Domar',
    bn_name: 'ডোমার',
  },
  {
    id: '434',
    district_id: '30',
    name: 'Dimla',
    bn_name: 'ডিমলা',
  },
  {
    id: '435',
    district_id: '31',
    name: 'Panchagarh Sadar',
    bn_name: 'পঞ্চগড় সদর',
  },
  {
    id: '436',
    district_id: '31',
    name: 'Debiganj',
    bn_name: 'দেবীগঞ্জ',
  },
  {
    id: '437',
    district_id: '31',
    name: 'Boda',
    bn_name: 'বোদা',
  },
  {
    id: '438',
    district_id: '31',
    name: 'Atwari',
    bn_name: 'আটোয়ারি',
  },
  {
    id: '439',
    district_id: '31',
    name: 'Tetulia',
    bn_name: 'তেঁতুলিয়া',
  },
  {
    id: '440',
    district_id: '32',
    name: 'Badarganj',
    bn_name: 'বদরগঞ্জ',
  },
  {
    id: '441',
    district_id: '32',
    name: 'Mithapukur',
    bn_name: 'মিঠাপুকুর',
  },
  {
    id: '442',
    district_id: '32',
    name: 'Gangachara',
    bn_name: 'গঙ্গাচরা',
  },
  {
    id: '443',
    district_id: '32',
    name: 'Kaunia',
    bn_name: 'কাউনিয়া',
  },
  {
    id: '444',
    district_id: '32',
    name: 'Rangpur Sadar',
    bn_name: 'রংপুর সদর',
  },
  {
    id: '445',
    district_id: '32',
    name: 'Pirgachha',
    bn_name: 'পীরগাছা',
  },
  {
    id: '446',
    district_id: '32',
    name: 'Pirganj',
    bn_name: 'পীরগঞ্জ',
  },
  {
    id: '447',
    district_id: '32',
    name: 'Taraganj',
    bn_name: 'তারাগঞ্জ',
  },
  {
    id: '448',
    district_id: '33',
    name: 'Thakurgaon Sadar',
    bn_name: 'ঠাকুরগাঁও সদর',
  },
  {
    id: '449',
    district_id: '33',
    name: 'Pirganj',
    bn_name: 'পীরগঞ্জ',
  },
  {
    id: '450',
    district_id: '33',
    name: 'Baliadangi',
    bn_name: 'বালিয়াডাঙ্গি',
  },
  {
    id: '451',
    district_id: '33',
    name: 'Haripur',
    bn_name: 'হরিপুর',
  },
  {
    id: '452',
    district_id: '33',
    name: 'Ranisankail',
    bn_name: 'রাণীসংকইল',
  },
  {
    id: '453',
    district_id: '51',
    name: 'Ajmiriganj',
    bn_name: 'আজমিরিগঞ্জ',
  },
  {
    id: '454',
    district_id: '51',
    name: 'Baniachang',
    bn_name: 'বানিয়াচং',
  },
  {
    id: '455',
    district_id: '51',
    name: 'Bahubal',
    bn_name: 'বাহুবল',
  },
  {
    id: '456',
    district_id: '51',
    name: 'Chunarughat',
    bn_name: 'চুনারুঘাট',
  },
  {
    id: '457',
    district_id: '51',
    name: 'Habiganj Sadar',
    bn_name: 'হবিগঞ্জ সদর',
  },
  {
    id: '458',
    district_id: '51',
    name: 'Lakhai',
    bn_name: 'লাক্ষাই',
  },
  {
    id: '459',
    district_id: '51',
    name: 'Madhabpur',
    bn_name: 'মাধবপুর',
  },
  {
    id: '460',
    district_id: '51',
    name: 'Nabiganj',
    bn_name: 'নবীগঞ্জ',
  },
  {
    id: '461',
    district_id: '51',
    name: 'Shaistagonj',
    bn_name: 'শায়েস্তাগঞ্জ',
  },
  {
    id: '462',
    district_id: '52',
    name: 'Moulvibazar Sadar',
    bn_name: 'মৌলভীবাজার',
  },
  {
    id: '463',
    district_id: '52',
    name: 'Barlekha',
    bn_name: 'বড়লেখা',
  },
  {
    id: '464',
    district_id: '52',
    name: 'Juri',
    bn_name: 'জুড়ি',
  },
  {
    id: '465',
    district_id: '52',
    name: 'Kamalganj',
    bn_name: 'কামালগঞ্জ',
  },
  {
    id: '466',
    district_id: '52',
    name: 'Kulaura',
    bn_name: 'কুলাউরা',
  },
  {
    id: '467',
    district_id: '52',
    name: 'Rajnagar',
    bn_name: 'রাজনগর',
  },
  {
    id: '468',
    district_id: '52',
    name: 'Sreemangal',
    bn_name: 'শ্রীমঙ্গল',
  },
  {
    id: '469',
    district_id: '53',
    name: 'Bishwamvarpur',
    bn_name: 'বিসশম্ভারপুর',
  },
  {
    id: '470',
    district_id: '53',
    name: 'Chhatak',
    bn_name: 'ছাতক',
  },
  {
    id: '471',
    district_id: '53',
    name: 'Derai',
    bn_name: 'দেড়াই',
  },
  {
    id: '472',
    district_id: '53',
    name: 'Dharampasha',
    bn_name: 'ধরমপাশা',
  },
  {
    id: '473',
    district_id: '53',
    name: 'Dowarabazar',
    bn_name: 'দোয়ারাবাজার',
  },
  {
    id: '474',
    district_id: '53',
    name: 'Jagannathpur',
    bn_name: 'জগন্নাথপুর',
  },
  {
    id: '475',
    district_id: '53',
    name: 'Jamalganj',
    bn_name: 'জামালগঞ্জ',
  },
  {
    id: '476',
    district_id: '53',
    name: 'Sulla',
    bn_name: 'সুল্লা',
  },
  {
    id: '477',
    district_id: '53',
    name: 'Sunamganj Sadar',
    bn_name: 'সুনামগঞ্জ সদর',
  },
  {
    id: '478',
    district_id: '53',
    name: 'Shanthiganj',
    bn_name: 'শান্তিগঞ্জ',
  },
  {
    id: '479',
    district_id: '53',
    name: 'Tahirpur',
    bn_name: 'তাহিরপুর',
  },
  {
    id: '480',
    district_id: '54',
    name: 'Sylhet Sadar',
    bn_name: 'সিলেট সদর',
  },
  {
    id: '481',
    district_id: '54',
    name: 'Beanibazar',
    bn_name: 'বেয়ানিবাজার',
  },
  {
    id: '482',
    district_id: '54',
    name: 'Bishwanath',
    bn_name: 'বিশ্বনাথ',
  },
  {
    id: '483',
    district_id: '54',
    name: 'Dakshin Surma',
    bn_name: 'দক্ষিণ সুরমা',
  },
  {
    id: '484',
    district_id: '54',
    name: 'Balaganj',
    bn_name: 'বালাগঞ্জ',
  },
  {
    id: '485',
    district_id: '54',
    name: 'Companiganj',
    bn_name: 'কোম্পানিগঞ্জ',
  },
  {
    id: '486',
    district_id: '54',
    name: 'Fenchuganj',
    bn_name: 'ফেঞ্চুগঞ্জ',
  },
  {
    id: '487',
    district_id: '54',
    name: 'Golapganj',
    bn_name: 'গোলাপগঞ্জ',
  },
  {
    id: '488',
    district_id: '54',
    name: 'Gowainghat',
    bn_name: 'গোয়াইনঘাট',
  },
  {
    id: '489',
    district_id: '54',
    name: 'Jaintiapur',
    bn_name: 'জয়ন্তপুর',
  },
  {
    id: '490',
    district_id: '54',
    name: 'Kanaighat',
    bn_name: 'কানাইঘাট',
  },
  {
    id: '491',
    district_id: '54',
    name: 'Zakiganj',
    bn_name: 'জাকিগঞ্জ',
  },
  {
    id: '492',
    district_id: '54',
    name: 'Nobigonj',
    bn_name: 'নবীগঞ্জ',
  },
  {
    id: '493',
    district_id: '45',
    name: 'Eidgaon',
    bn_name: 'ঈদগাঁও',
  },
  {
    id: '494',
    district_id: '53',
    name: 'Modhyanagar',
    bn_name: 'মধ্যনগর',
  },
  {
    id: '495',
    district_id: '7',
    name: 'Dasar',
    bn_name: 'দশর',
  },
];

export const postCodes = [
  {
    division_id: "1",
    district_id: "34",
    upazila: "Amtali",
    postOffice: "Amtali",
    postCode: "8710",
  },
  {
    division_id: "1",
    district_id: "34",
    upazila: "Bamna",
    postOffice: "Bamna",
    postCode: "8730",
  },
  {
    division_id: "1",
    district_id: "34",
    upazila: "Barguna Sadar",
    postOffice: "Barguna Sadar",
    postCode: "8700",
  },
  {
    division_id: "1",
    district_id: "34",
    upazila: "Barguna Sadar",
    postOffice: "Nali Bandar",
    postCode: "8701",
  },
  {
    division_id: "1",
    district_id: "34",
    upazila: "Betagi",
    postOffice: "Betagi",
    postCode: "8740",
  },
  {
    division_id: "1",
    district_id: "34",
    upazila: "Betagi",
    postOffice: "Darul Ulam",
    postCode: "8741",
  },
  {
    division_id: "1",
    district_id: "34",
    upazila: "Patharghata",
    postOffice: "Kakchira",
    postCode: "8721",
  },
  {
    division_id: "1",
    district_id: "34",
    upazila: "Patharghata",
    postOffice: "Patharghata",
    postCode: "8720",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Agailzhara",
    postOffice: "Agailzhara",
    postCode: "8240",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Agailzhara",
    postOffice: "Gaila",
    postCode: "8241",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Agailzhara",
    postOffice: "Paisarhat",
    postCode: "8242",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Babuganj",
    postOffice: "Babuganj",
    postCode: "8210",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Babuganj",
    postOffice: "Barisal Cadet",
    postCode: "8216",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Babuganj",
    postOffice: "Chandpasha",
    postCode: "8212",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Babuganj",
    postOffice: "Madhabpasha",
    postCode: "8213",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Babuganj",
    postOffice: "Nizamuddin College",
    postCode: "8215",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Babuganj",
    postOffice: "Rahamatpur",
    postCode: "8211",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Babuganj",
    postOffice: "Thakur Mallik",
    postCode: "8214",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Barajalia",
    postOffice: "Barajalia",
    postCode: "8260",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Barajalia",
    postOffice: "Osman Manjil",
    postCode: "8261",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Barisal Sadar",
    postOffice: "Barisal Sadar",
    postCode: "8200",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Barisal Sadar",
    postOffice: "Bukhainagar",
    postCode: "8201",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Barisal Sadar",
    postOffice: "Jaguarhat",
    postCode: "8206",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Barisal Sadar",
    postOffice: "Kashipur",
    postCode: "8205",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Barisal Sadar",
    postOffice: "Patang",
    postCode: "8204",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Barisal Sadar",
    postOffice: "Saheberhat",
    postCode: "8202",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Barisal Sadar",
    postOffice: "Sugandia",
    postCode: "8203",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Gouranadi",
    postOffice: "Batajor",
    postCode: "8233",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Gouranadi",
    postOffice: "Gouranadi",
    postCode: "8230",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Gouranadi",
    postOffice: "Kashemabad",
    postCode: "8232",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Gouranadi",
    postOffice: "Tarki Bandar",
    postCode: "8231",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Mahendiganj",
    postOffice: "Langutia",
    postCode: "8274",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Mahendiganj",
    postOffice: "Laskarpur",
    postCode: "8271",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Mahendiganj",
    postOffice: "Mahendiganj",
    postCode: "8270",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Mahendiganj",
    postOffice: "Nalgora",
    postCode: "8273",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Mahendiganj",
    postOffice: "Ulania",
    postCode: "8272",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Muladi",
    postOffice: "Charkalekhan",
    postCode: "8252",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Muladi",
    postOffice: "Kazirchar",
    postCode: "8251",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Muladi",
    postOffice: "Muladi",
    postCode: "8250",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Sahebganj",
    postOffice: "Charamandi",
    postCode: "8281",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Sahebganj",
    postOffice: "kalaskati",
    postCode: "8284",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Sahebganj",
    postOffice: "Padri Shibpur",
    postCode: "8282",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Sahebganj",
    postOffice: "Sahebganj",
    postCode: "8280",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Sahebganj",
    postOffice: "Shialguni",
    postCode: "8283",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Uzirpur",
    postOffice: "Dakuarhat",
    postCode: "8223",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Uzirpur",
    postOffice: "Dhamura",
    postCode: "8221",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Uzirpur",
    postOffice: "Jugirkanda",
    postCode: "8222",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Uzirpur",
    postOffice: "Shikarpur",
    postCode: "8224",
  },
  {
    division_id: "1",
    district_id: "35",
    upazila: "Uzirpur",
    postOffice: "Uzirpur",
    postCode: "8220",
  },
  {
    division_id: "1",
    district_id: "36",
    upazila: "Bhola Sadar",
    postOffice: "Bhola Sadar",
    postCode: "8300",
  },
  {
    division_id: "1",
    district_id: "36",
    upazila: "Bhola Sadar",
    postOffice: "Joynagar",
    postCode: "8301",
  },
  {
    division_id: "1",
    district_id: "36",
    upazila: "Borhanuddin UPO",
    postOffice: "Borhanuddin UPO",
    postCode: "8320",
  },
  {
    division_id: "1",
    district_id: "36",
    upazila: "Borhanuddin UPO",
    postOffice: "Mirzakalu",
    postCode: "8321",
  },
  {
    division_id: "1",
    district_id: "36",
    upazila: "Charfashion",
    postOffice: "Charfashion",
    postCode: "8340",
  },
  {
    division_id: "1",
    district_id: "36",
    upazila: "Charfashion",
    postOffice: "Dularhat",
    postCode: "8341",
  },
  {
    division_id: "1",
    district_id: "36",
    upazila: "Charfashion",
    postOffice: "Keramatganj",
    postCode: "8342",
  },
  {
    division_id: "1",
    district_id: "36",
    upazila: "Doulatkhan",
    postOffice: "Doulatkhan",
    postCode: "8310",
  },
  {
    division_id: "1",
    district_id: "36",
    upazila: "Doulatkhan",
    postOffice: "Hajipur",
    postCode: "8311",
  },
  {
    division_id: "1",
    district_id: "36",
    upazila: "Hajirhat",
    postOffice: "Hajirhat",
    postCode: "8360",
  },
  {
    division_id: "1",
    district_id: "36",
    upazila: "Hatshoshiganj",
    postOffice: "Hatshoshiganj",
    postCode: "8350",
  },
  {
    division_id: "1",
    district_id: "36",
    upazila: "Lalmohan UPO",
    postOffice: "Daurihat",
    postCode: "8331",
  },
  {
    division_id: "1",
    district_id: "36",
    upazila: "Lalmohan UPO",
    postOffice: "Gazaria",
    postCode: "8332",
  },
  {
    division_id: "1",
    district_id: "36",
    upazila: "Lalmohan UPO",
    postOffice: "Lalmohan UPO",
    postCode: "8330",
  },
  {
    division_id: "1",
    district_id: "37",
    upazila: "Jhalokathi Sadar",
    postOffice: "Baukathi",
    postCode: "8402",
  },
  {
    division_id: "1",
    district_id: "37",
    upazila: "Jhalokathi Sadar",
    postOffice: "Gabha",
    postCode: "8403",
  },
  {
    division_id: "1",
    district_id: "37",
    upazila: "Jhalokathi Sadar",
    postOffice: "Jhalokathi Sadar",
    postCode: "8400",
  },
  {
    division_id: "1",
    district_id: "37",
    upazila: "Jhalokathi Sadar",
    postOffice: "Nabagram",
    postCode: "8401",
  },
  {
    division_id: "1",
    district_id: "37",
    upazila: "Jhalokathi Sadar",
    postOffice: "Shekherhat",
    postCode: "8404",
  },
  {
    division_id: "1",
    district_id: "37",
    upazila: "Kathalia",
    postOffice: "Amua",
    postCode: "8431",
  },
  {
    division_id: "1",
    district_id: "37",
    upazila: "Kathalia",
    postOffice: "Kathalia",
    postCode: "8430",
  },
  {
    division_id: "1",
    district_id: "37",
    upazila: "Kathalia",
    postOffice: "Niamatee",
    postCode: "8432",
  },
  {
    division_id: "1",
    district_id: "37",
    upazila: "Kathalia",
    postOffice: "Shoulajalia",
    postCode: "8433",
  },
  {
    division_id: "1",
    district_id: "37",
    upazila: "Nalchhiti",
    postOffice: "Beerkathi",
    postCode: "8421",
  },
  {
    division_id: "1",
    district_id: "37",
    upazila: "Nalchhiti",
    postOffice: "Nalchhiti",
    postCode: "8420",
  },
  {
    division_id: "1",
    district_id: "37",
    upazila: "Rajapur",
    postOffice: "Rajapur",
    postCode: "8410",
  },
  {
    division_id: "1",
    district_id: "38",
    upazila: "Bauphal",
    postOffice: "Bagabandar",
    postCode: "8621",
  },
  {
    division_id: "1",
    district_id: "38",
    upazila: "Bauphal",
    postOffice: "Bauphal",
    postCode: "8620",
  },
  {
    division_id: "1",
    district_id: "38",
    upazila: "Bauphal",
    postOffice: "Birpasha",
    postCode: "8622",
  },
  {
    division_id: "1",
    district_id: "38",
    upazila: "Bauphal",
    postOffice: "Kalaia",
    postCode: "8624",
  },
  {
    division_id: "1",
    district_id: "38",
    upazila: "Bauphal",
    postOffice: "Kalishari",
    postCode: "8623",
  },
  {
    division_id: "1",
    district_id: "38",
    upazila: "Dashmina",
    postOffice: "Dashmina",
    postCode: "8630",
  },
  {
    division_id: "1",
    district_id: "38",
    upazila: "Galachipa",
    postOffice: "Galachipa",
    postCode: "8640",
  },
  {
    division_id: "1",
    district_id: "38",
    upazila: "Galachipa",
    postOffice: "Gazipur Bandar",
    postCode: "8641",
  },
  {
    division_id: "1",
    district_id: "38",
    upazila: "Khepupara",
    postOffice: "Khepupara",
    postCode: "8650",
  },
  {
    division_id: "1",
    district_id: "38",
    upazila: "Khepupara",
    postOffice: "Mahipur",
    postCode: "8651",
  },
  {
    division_id: "1",
    district_id: "38",
    upazila: "Patuakhali Sadar",
    postOffice: "Dumkee",
    postCode: "8602",
  },
  {
    division_id: "1",
    district_id: "38",
    upazila: "Patuakhali Sadar",
    postOffice: "Moukaran",
    postCode: "8601",
  },
  {
    division_id: "1",
    district_id: "38",
    upazila: "Patuakhali Sadar",
    postOffice: "Patuakhali Sadar",
    postCode: "8600",
  },
  {
    division_id: "1",
    district_id: "38",
    upazila: "Patuakhali Sadar",
    postOffice: "Rahimabad",
    postCode: "8603",
  },
  {
    division_id: "1",
    district_id: "38",
    upazila: "Subidkhali",
    postOffice: "Subidkhali",
    postCode: "8610",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Banaripara",
    postOffice: "Banaripara",
    postCode: "8530",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Banaripara",
    postOffice: "Chakhar",
    postCode: "8531",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Bhandaria",
    postOffice: "Bhandaria",
    postCode: "8550",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Bhandaria",
    postOffice: "Dhaoa",
    postCode: "8552",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Bhandaria",
    postOffice: "Kanudashkathi",
    postCode: "8551",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "kaukhali",
    postOffice: "Jolagati",
    postCode: "8513",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "kaukhali",
    postOffice: "Joykul",
    postCode: "8512",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "kaukhali",
    postOffice: "Kaukhali",
    postCode: "8510",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "kaukhali",
    postOffice: "Keundia",
    postCode: "8511",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Mathbaria",
    postOffice: "Betmor Natun Hat",
    postCode: "8565",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Mathbaria",
    postOffice: "Gulishakhali",
    postCode: "8563",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Mathbaria",
    postOffice: "Halta",
    postCode: "8562",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Mathbaria",
    postOffice: "Mathbaria",
    postCode: "8560",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Mathbaria",
    postOffice: "Shilarganj",
    postCode: "8566",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Mathbaria",
    postOffice: "Tiarkhali",
    postCode: "8564",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Mathbaria",
    postOffice: "Tushkhali",
    postCode: "8561",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Nazirpur",
    postOffice: "Nazirpur",
    postCode: "8540",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Nazirpur",
    postOffice: "Sriramkathi",
    postCode: "8541",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Pirojpur Sadar",
    postOffice: "Hularhat",
    postCode: "8501",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Pirojpur Sadar",
    postOffice: "Parerhat",
    postCode: "8502",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Pirojpur Sadar",
    postOffice: "Pirojpur Sadar",
    postCode: "8500",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Swarupkathi",
    postOffice: "Darus Sunnat",
    postCode: "8521",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Swarupkathi",
    postOffice: "Jalabari",
    postCode: "8523",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Swarupkathi",
    postOffice: "Kaurikhara",
    postCode: "8522",
  },
  {
    division_id: "1",
    district_id: "39",
    upazila: "Swarupkathi",
    postOffice: "Swarupkathi",
    postCode: "8520",
  },
  {
    division_id: "2",
    district_id: "40",
    upazila: "Alikadam",
    postOffice: "Alikadam",
    postCode: "4650",
  },
  {
    division_id: "2",
    district_id: "40",
    upazila: "Bandarban Sadar",
    postOffice: "Bandarban Sadar",
    postCode: "4600",
  },
  {
    division_id: "2",
    district_id: "40",
    upazila: "Naikhong",
    postOffice: "Naikhong",
    postCode: "4660",
  },
  {
    division_id: "2",
    district_id: "40",
    upazila: "Roanchhari",
    postOffice: "Roanchhari",
    postCode: "4610",
  },
  {
    division_id: "2",
    district_id: "40",
    upazila: "Ruma",
    postOffice: "Ruma",
    postCode: "4620",
  },
  {
    division_id: "2",
    district_id: "40",
    upazila: "Thanchi",
    postOffice: "Lama",
    postCode: "4641",
  },
  {
    division_id: "2",
    district_id: "40",
    upazila: "Thanchi",
    postOffice: "Thanchi",
    postCode: "4630",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Akhaura",
    postOffice: "Akhaura",
    postCode: "3450",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Akhaura",
    postOffice: "Azampur",
    postCode: "3451",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Akhaura",
    postOffice: "Gangasagar",
    postCode: "3452",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Banchharampur",
    postOffice: "Banchharampur",
    postCode: "3420",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Brahamanbaria Sadar",
    postOffice: "Ashuganj",
    postCode: "3402",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Brahamanbaria Sadar",
    postOffice: "Ashuganj Share",
    postCode: "3403",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Brahamanbaria Sadar",
    postOffice: "Brahamanbaria Sadar",
    postCode: "3400",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Brahamanbaria Sadar",
    postOffice: "Poun",
    postCode: "3404",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Brahamanbaria Sadar",
    postOffice: "Talshahar",
    postCode: "3401",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Kasba",
    postOffice: "Chandidar",
    postCode: "3462",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Kasba",
    postOffice: "Chargachh",
    postCode: "3463",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Kasba",
    postOffice: "Gopinathpur",
    postCode: "3464",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Kasba",
    postOffice: "Kasba",
    postCode: "3460",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Kasba",
    postOffice: "Kuti",
    postCode: "3461",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Nabinagar",
    postOffice: "Jibanganj",
    postCode: "3419",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Nabinagar",
    postOffice: "Kaitala",
    postCode: "3417",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Nabinagar",
    postOffice: "Laubfatehpur",
    postCode: "3411",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Nabinagar",
    postOffice: "Nabinagar",
    postCode: "3410",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Nabinagar",
    postOffice: "Rasullabad",
    postCode: "3412",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Nabinagar",
    postOffice: "Ratanpur",
    postCode: "3414",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Nabinagar",
    postOffice: "Salimganj",
    postCode: "3418",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Nabinagar",
    postOffice: "Shahapur",
    postCode: "3415",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Nabinagar",
    postOffice: "Shamgram",
    postCode: "3413",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Nasirnagar",
    postOffice: "Fandauk",
    postCode: "3441",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Nasirnagar",
    postOffice: "Nasirnagar",
    postCode: "3440",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Sarail",
    postOffice: "Chandura",
    postCode: "3432",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Sarail",
    postOffice: "Sarial",
    postCode: "3430",
  },
  {
    division_id: "2",
    district_id: "41",
    upazila: "Sarail",
    postOffice: "Shahbajpur",
    postCode: "3431",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Chandpur Sadar",
    postOffice: "Baburhat",
    postCode: "3602",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Chandpur Sadar",
    postOffice: "Chandpur Sadar",
    postCode: "3600",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Chandpur Sadar",
    postOffice: "Puranbazar",
    postCode: "3601",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Chandpur Sadar",
    postOffice: "Sahatali",
    postCode: "3603",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Faridganj",
    postOffice: "Chandra",
    postCode: "3651",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Faridganj",
    postOffice: "Faridganj",
    postCode: "3650",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Faridganj",
    postOffice: "Gridkaliandia",
    postCode: "3653",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Faridganj",
    postOffice: "Islampur Shah Isain",
    postCode: "3655",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Faridganj",
    postOffice: "Rampurbazar",
    postCode: "3654",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Faridganj",
    postOffice: "Rupsha",
    postCode: "3652",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Hajiganj",
    postOffice: "Bolakhal",
    postCode: "3611",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Hajiganj",
    postOffice: "Hajiganj",
    postCode: "3610",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Hayemchar",
    postOffice: "Gandamara",
    postCode: "3661",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Hayemchar",
    postOffice: "Hayemchar",
    postCode: "3660",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Kachua",
    postOffice: "Kachua",
    postCode: "3630",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Kachua",
    postOffice: "Pak Shrirampur",
    postCode: "3631",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Kachua",
    postOffice: "Rahima Nagar",
    postCode: "3632",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Kachua",
    postOffice: "Shachar",
    postCode: "3633",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Matlobganj",
    postOffice: "Kalipur",
    postCode: "3642",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Matlobganj",
    postOffice: "Matlobganj",
    postCode: "3640",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Matlobganj",
    postOffice: "Mohanpur",
    postCode: "3641",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Shahrasti",
    postOffice: "Chotoshi",
    postCode: "3623",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Shahrasti",
    postOffice: "Islamia Madrasa",
    postCode: "3624",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Shahrasti",
    postOffice: "Khilabazar",
    postCode: "3621",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Shahrasti",
    postOffice: "Pashchim Kherihar Al",
    postCode: "3622",
  },
  {
    division_id: "2",
    district_id: "42",
    upazila: "Shahrasti",
    postOffice: "Shahrasti",
    postCode: "3620",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Anawara",
    postOffice: "Anowara",
    postCode: "4376",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Anawara",
    postOffice: "Battali",
    postCode: "4378",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Anawara",
    postOffice: "Paroikora",
    postCode: "4377",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Boalkhali",
    postOffice: "Boalkhali",
    postCode: "4366",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Boalkhali",
    postOffice: "Charandwip",
    postCode: "4369",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Boalkhali",
    postOffice: "Iqbal Park",
    postCode: "4365",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Boalkhali",
    postOffice: "Kadurkhal",
    postCode: "4368",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Boalkhali",
    postOffice: "Kanungopara",
    postCode: "4363",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Boalkhali",
    postOffice: "Sakpura",
    postCode: "4367",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Boalkhali",
    postOffice: "Saroatoli",
    postCode: "4364",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Al- Amin Baria Madra",
    postCode: "4221",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Amin Jute Mills",
    postCode: "4211",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Anandabazar",
    postCode: "4215",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Bayezid Bostami",
    postCode: "4210",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Chandgaon",
    postCode: "4212",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Chawkbazar",
    postCode: "4203",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Chitt. Cantonment",
    postCode: "4220",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Chitt. Customs Acca",
    postCode: "4219",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Chitt. Politechnic In",
    postCode: "4209",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Chitt. Sailers Colon",
    postCode: "4218",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Chittagong Airport",
    postCode: "4205",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Chittagong Bandar",
    postCode: "4100",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Chittagong GPO",
    postCode: "4000",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Export Processing",
    postCode: "4223",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Firozshah",
    postCode: "4207",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Halishahar",
    postCode: "4216",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Halishshar",
    postCode: "4225",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Jalalabad",
    postCode: "4214",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Jaldia Merine Accade",
    postCode: "4206",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Middle Patenga",
    postCode: "4222",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Mohard",
    postCode: "4208",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "North Halishahar",
    postCode: "4226",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "North Katuli",
    postCode: "4217",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Pahartoli",
    postCode: "4202",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Patenga",
    postCode: "4204",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Rampura TSO",
    postCode: "4224",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Chittagong Sadar",
    postOffice: "Wazedia",
    postCode: "4213",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "East Joara",
    postOffice: "Barma",
    postCode: "4383",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "East Joara",
    postOffice: "Dohazari",
    postCode: "4382",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "East Joara",
    postOffice: "East Joara",
    postCode: "4380",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "East Joara",
    postOffice: "Gachbaria",
    postCode: "4381",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Fatikchhari",
    postOffice: "Bhandar Sharif",
    postCode: "4352",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Fatikchhari",
    postOffice: "Fatikchhari",
    postCode: "4350",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Fatikchhari",
    postOffice: "Harualchhari",
    postCode: "4354",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Fatikchhari",
    postOffice: "Najirhat",
    postCode: "4353",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Fatikchhari",
    postOffice: "Nanupur",
    postCode: "4351",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Fatikchhari",
    postOffice: "Narayanhat",
    postCode: "4355",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Hathazari",
    postOffice: "Chitt.University",
    postCode: "4331",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Hathazari",
    postOffice: "Fatahabad",
    postCode: "4335",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Hathazari",
    postOffice: "Gorduara",
    postCode: "4332",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Hathazari",
    postOffice: "Hathazari",
    postCode: "4330",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Hathazari",
    postOffice: "Katirhat",
    postCode: "4333",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Hathazari",
    postOffice: "Madrasa",
    postCode: "4339",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Hathazari",
    postOffice: "Mirzapur",
    postCode: "4334",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Hathazari",
    postOffice: "Nuralibari",
    postCode: "4337",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Hathazari",
    postOffice: "Yunus Nagar",
    postCode: "4338",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Jaldi",
    postOffice: "Banigram",
    postCode: "4393",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Jaldi",
    postOffice: "Gunagari",
    postCode: "4392",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Jaldi",
    postOffice: "Jaldi",
    postCode: "4390",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Jaldi",
    postOffice: "Khan Bahadur",
    postCode: "4391",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Lohagara",
    postOffice: "Chunti",
    postCode: "4398",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Lohagara",
    postOffice: "Lohagara",
    postCode: "4396",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Lohagara",
    postOffice: "Padua",
    postCode: "4397",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Mirsharai",
    postOffice: "Abutorab",
    postCode: "4321",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Mirsharai",
    postOffice: "Azampur",
    postCode: "4325",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Mirsharai",
    postOffice: "Bharawazhat",
    postCode: "4323",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Mirsharai",
    postOffice: "Darrogahat",
    postCode: "4322",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Mirsharai",
    postOffice: "Joarganj",
    postCode: "4324",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Mirsharai",
    postOffice: "Korerhat",
    postCode: "4327",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Mirsharai",
    postOffice: "Mirsharai",
    postCode: "4320",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Mirsharai",
    postOffice: "Mohazanhat",
    postCode: "4328",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Patiya",
    postOffice: "Budhpara",
    postCode: "4371",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Patiya",
    postOffice: "Patiya Head Office",
    postCode: "4370",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Rangunia",
    postOffice: "Dhamair",
    postCode: "4361",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Rangunia",
    postOffice: "Rangunia",
    postCode: "4360",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Rouzan",
    postOffice: "B.I.T Post Office",
    postCode: "4349",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Rouzan",
    postOffice: "Beenajuri",
    postCode: "4341",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Rouzan",
    postOffice: "Dewanpur",
    postCode: "4347",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Rouzan",
    postOffice: "Fatepur",
    postCode: "4345",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Rouzan",
    postOffice: "Gahira",
    postCode: "4343",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Rouzan",
    postOffice: "Guzra Noapara",
    postCode: "4346",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Rouzan",
    postOffice: "jagannath Hat",
    postCode: "4344",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Rouzan",
    postOffice: "Kundeshwari",
    postCode: "4342",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Rouzan",
    postOffice: "Mohamuni",
    postCode: "4348",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Rouzan",
    postOffice: "Rouzan",
    postCode: "4340",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Sandwip",
    postOffice: "Sandwip",
    postCode: "4300",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Sandwip",
    postOffice: "Shiberhat",
    postCode: "4301",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Sandwip",
    postOffice: "Urirchar",
    postCode: "4302",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Satkania",
    postOffice: "Baitul Ijjat",
    postCode: "4387",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Satkania",
    postOffice: "Bazalia",
    postCode: "4388",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Satkania",
    postOffice: "Satkania",
    postCode: "4386",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Sitakunda",
    postOffice: "Barabkunda",
    postCode: "4312",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Sitakunda",
    postOffice: "Baroidhala",
    postCode: "4311",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Sitakunda",
    postOffice: "Bawashbaria",
    postCode: "4313",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Sitakunda",
    postOffice: "Bhatiari",
    postCode: "4315",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Sitakunda",
    postOffice: "Fouzdarhat",
    postCode: "4316",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Sitakunda",
    postOffice: "Jafrabad",
    postCode: "4317",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Sitakunda",
    postOffice: "Kumira",
    postCode: "4314",
  },
  {
    division_id: "2",
    district_id: "43",
    upazila: "Sitakunda",
    postOffice: "Sitakunda",
    postCode: "4310",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Barura",
    postOffice: "Barura",
    postCode: "3560",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Barura",
    postOffice: "Murdafarganj",
    postCode: "3562",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Barura",
    postOffice: "Poyalgachha",
    postCode: "3561",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Brahmanpara",
    postOffice: "Brahmanpara",
    postCode: "3526",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Burichang",
    postOffice: "Burichang",
    postCode: "3520",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Burichang",
    postOffice: "Maynamoti bazar",
    postCode: "3521",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Chandina",
    postOffice: "Chandia",
    postCode: "3510",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Chandina",
    postOffice: "Madhaiabazar",
    postCode: "3511",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Chouddagram",
    postOffice: "Batisa",
    postCode: "3551",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Chouddagram",
    postOffice: "Chiora",
    postCode: "3552",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Chouddagram",
    postOffice: "Chouddagram",
    postCode: "3550",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Comilla Sadar",
    postOffice: "Comilla Contoment",
    postCode: "3501",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Comilla Sadar",
    postOffice: "Comilla Sadar",
    postCode: "3500",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Comilla Sadar",
    postOffice: "Courtbari",
    postCode: "3503",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Comilla Sadar",
    postOffice: "Halimanagar",
    postCode: "3502",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Comilla Sadar",
    postOffice: "Suaganj",
    postCode: "3504",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Daudkandi",
    postOffice: "Dashpara",
    postCode: "3518",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Daudkandi",
    postOffice: "Daudkandi",
    postCode: "3516",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Daudkandi",
    postOffice: "Eliotganj",
    postCode: "3519",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Daudkandi",
    postOffice: "Gouripur",
    postCode: "3517",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Davidhar",
    postOffice: "Barashalghar",
    postCode: "3532",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Davidhar",
    postOffice: "Davidhar",
    postCode: "3530",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Davidhar",
    postOffice: "Dhamtee",
    postCode: "3533",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Davidhar",
    postOffice: "Gangamandal",
    postCode: "3531",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Homna",
    postOffice: "Homna",
    postCode: "3546",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Laksam",
    postOffice: "Bipulasar",
    postCode: "3572",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Laksam",
    postOffice: "Laksam",
    postCode: "3570",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Laksam",
    postOffice: "Lakshamanpur",
    postCode: "3571",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Langalkot",
    postOffice: "Chhariabazar",
    postCode: "3582",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Langalkot",
    postOffice: "Dhalua",
    postCode: "3581",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Langalkot",
    postOffice: "Gunabati",
    postCode: "3583",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Langalkot",
    postOffice: "Langalkot",
    postCode: "3580",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Muradnagar",
    postOffice: "Bangra",
    postCode: "3543",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Muradnagar",
    postOffice: "Companyganj",
    postCode: "3542",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Muradnagar",
    postOffice: "Muradnagar",
    postCode: "3540",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Muradnagar",
    postOffice: "Pantibazar",
    postCode: "3545",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Muradnagar",
    postOffice: "Ramchandarpur",
    postCode: "3541",
  },
  {
    division_id: "2",
    district_id: "44",
    upazila: "Muradnagar",
    postOffice: "Sonakanda",
    postCode: "3544",
  },
  {
    division_id: "2",
    district_id: "45",
    upazila: "Chiringga",
    postOffice: "Badarkali",
    postCode: "4742",
  },
  {
    division_id: "2",
    district_id: "45",
    upazila: "Chiringga",
    postOffice: "Chiringga",
    postCode: "4740",
  },
  {
    division_id: "2",
    district_id: "45",
    upazila: "Chiringga",
    postOffice: "Chiringga S.O",
    postCode: "4741",
  },
  {
    division_id: "2",
    district_id: "45",
    upazila: "Chiringga",
    postOffice: "Malumghat",
    postCode: "4743",
  },
  {
    division_id: "2",
    district_id: "45",
    upazila: "Coxs Bazar Sadar",
    postOffice: "Coxs Bazar Sadar",
    postCode: "4700",
  },
  {
    division_id: "2",
    district_id: "45",
    upazila: "Coxs Bazar Sadar",
    postOffice: "Eidga",
    postCode: "4702",
  },
  {
    division_id: "2",
    district_id: "45",
    upazila: "Coxs Bazar Sadar",
    postOffice: "Zhilanja",
    postCode: "4701",
  },
  {
    division_id: "2",
    district_id: "45",
    upazila: "Gorakghat",
    postOffice: "Gorakghat",
    postCode: "4710",
  },
  {
    division_id: "2",
    district_id: "45",
    upazila: "Kutubdia",
    postOffice: "Kutubdia",
    postCode: "4720",
  },
  {
    division_id: "2",
    district_id: "45",
    upazila: "Ramu",
    postOffice: "Ramu",
    postCode: "4730",
  },
  {
    division_id: "2",
    district_id: "45",
    upazila: "Teknaf",
    postOffice: "Hnila",
    postCode: "4761",
  },
  {
    division_id: "2",
    district_id: "45",
    upazila: "Teknaf",
    postOffice: "St.Martin",
    postCode: "4762",
  },
  {
    division_id: "2",
    district_id: "45",
    upazila: "Teknaf",
    postOffice: "Teknaf",
    postCode: "4760",
  },
  {
    division_id: "2",
    district_id: "45",
    upazila: "Ukhia",
    postOffice: "Ukhia",
    postCode: "4750",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Chhagalnaia",
    postOffice: "Chhagalnaia",
    postCode: "3910",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Chhagalnaia",
    postOffice: "Daraga Hat",
    postCode: "3912",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Chhagalnaia",
    postOffice: "Maharajganj",
    postCode: "3911",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Chhagalnaia",
    postOffice: "Puabashimulia",
    postCode: "3913",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Dagonbhuia",
    postOffice: "Chhilonia",
    postCode: "3922",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Dagonbhuia",
    postOffice: "Dagondhuia",
    postCode: "3920",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Dagonbhuia",
    postOffice: "Dudmukha",
    postCode: "3921",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Dagonbhuia",
    postOffice: "Rajapur",
    postCode: "3923",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Feni Sadar",
    postOffice: "Fazilpur",
    postCode: "3901",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Feni Sadar",
    postOffice: "Feni Sadar",
    postCode: "3900",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Feni Sadar",
    postOffice: "Laskarhat",
    postCode: "3903",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Feni Sadar",
    postOffice: "Sharshadie",
    postCode: "3902",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Pashurampur",
    postOffice: "Fulgazi",
    postCode: "3942",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Pashurampur",
    postOffice: "Munshirhat",
    postCode: "3943",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Pashurampur",
    postOffice: "Pashurampur",
    postCode: "3940",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Pashurampur",
    postOffice: "Shuarbazar",
    postCode: "3941",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Sonagazi",
    postOffice: "Ahmadpur",
    postCode: "3932",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Sonagazi",
    postOffice: "Kazirhat",
    postCode: "3933",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Sonagazi",
    postOffice: "Motiganj",
    postCode: "3931",
  },
  {
    division_id: "2",
    district_id: "46",
    upazila: "Sonagazi",
    postOffice: "Sonagazi",
    postCode: "3930",
  },
  {
    division_id: "2",
    district_id: "47",
    upazila: "Diginala",
    postOffice: "Diginala",
    postCode: "4420",
  },
  {
    division_id: "2",
    district_id: "47",
    upazila: "Khagrachari Sadar",
    postOffice: "Khagrachari Sadar",
    postCode: "4400",
  },
  {
    division_id: "2",
    district_id: "47",
    upazila: "Laxmichhari",
    postOffice: "Laxmichhari",
    postCode: "4470",
  },
  {
    division_id: "2",
    district_id: "47",
    upazila: "Mahalchhari",
    postOffice: "Mahalchhari",
    postCode: "4430",
  },
  {
    division_id: "2",
    district_id: "47",
    upazila: "Manikchhari",
    postOffice: "Manikchhari",
    postCode: "4460",
  },
  {
    division_id: "2",
    district_id: "47",
    upazila: "Matiranga",
    postOffice: "Matiranga",
    postCode: "4450",
  },
  {
    division_id: "2",
    district_id: "47",
    upazila: "Panchhari",
    postOffice: "Panchhari",
    postCode: "4410",
  },
  {
    division_id: "2",
    district_id: "47",
    upazila: "Ramghar Head Office",
    postOffice: "Ramghar Head Office",
    postCode: "4440",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Char Alexgander",
    postOffice: "Char Alexgander",
    postCode: "3730",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Char Alexgander",
    postOffice: "Hajirghat",
    postCode: "3731",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Char Alexgander",
    postOffice: "Ramgatirhat",
    postCode: "3732",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Lakshimpur Sadar",
    postOffice: "Amani Lakshimpur",
    postCode: "3709",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Lakshimpur Sadar",
    postOffice: "Bhabaniganj",
    postCode: "3702",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Lakshimpur Sadar",
    postOffice: "Chandraganj",
    postCode: "3708",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Lakshimpur Sadar",
    postOffice: "Choupalli",
    postCode: "3707",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Lakshimpur Sadar",
    postOffice: "Dalal Bazar",
    postCode: "3701",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Lakshimpur Sadar",
    postOffice: "Duttapara",
    postCode: "3706",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Lakshimpur Sadar",
    postOffice: "Keramatganj",
    postCode: "3704",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Lakshimpur Sadar",
    postOffice: "Lakshimpur Sadar",
    postCode: "3700",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Lakshimpur Sadar",
    postOffice: "Mandari",
    postCode: "3703",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Lakshimpur Sadar",
    postOffice: "Rupchara",
    postCode: "3705",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Ramganj",
    postOffice: "Alipur",
    postCode: "3721",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Ramganj",
    postOffice: "Dolta",
    postCode: "3725",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Ramganj",
    postOffice: "Kanchanpur",
    postCode: "3723",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Ramganj",
    postOffice: "Naagmud",
    postCode: "3724",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Ramganj",
    postOffice: "Panpara",
    postCode: "3722",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Ramganj",
    postOffice: "Ramganj",
    postCode: "3720",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Raypur",
    postOffice: "Bhuabari",
    postCode: "3714",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Raypur",
    postOffice: "Haydarganj",
    postCode: "3713",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Raypur",
    postOffice: "Nagerdighirpar",
    postCode: "3712",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Raypur",
    postOffice: "Rakhallia",
    postCode: "3711",
  },
  {
    division_id: "2",
    district_id: "48",
    upazila: "Raypur",
    postOffice: "Raypur",
    postCode: "3710",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Basurhat",
    postOffice: "Basur Hat",
    postCode: "3850",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Basurhat",
    postOffice: "Charhajari",
    postCode: "3851",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Alaiarpur",
    postCode: "3831",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Amisha Para",
    postCode: "3847",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Banglabazar",
    postCode: "3822",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Bazra",
    postCode: "3824",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Begumganj",
    postCode: "3820",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Bhabani Jibanpur",
    postCode: "3837",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Choumohani",
    postCode: "3821",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Dauti",
    postCode: "3843",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Durgapur",
    postCode: "3848",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Gopalpur",
    postCode: "3828",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Jamidar Hat",
    postCode: "3825",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Joyag",
    postCode: "3844",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Joynarayanpur",
    postCode: "3829",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Khalafat Bazar",
    postCode: "3833",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Khalishpur",
    postCode: "3842",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Maheshganj",
    postCode: "3838",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Mir Owarishpur",
    postCode: "3823",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Nadona",
    postCode: "3839",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Nandiapara",
    postCode: "3841",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Oachhekpur",
    postCode: "3835",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Rajganj",
    postCode: "3834",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Sonaimuri",
    postCode: "3827",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Tangirpar",
    postCode: "3832",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Begumganj",
    postOffice: "Thanar Hat",
    postCode: "3845",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Chatkhil",
    postOffice: "Bansa Bazar",
    postCode: "3879",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Chatkhil",
    postOffice: "Bodalcourt",
    postCode: "3873",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Chatkhil",
    postOffice: "Chatkhil",
    postCode: "3870",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Chatkhil",
    postOffice: "Dosh Gharia",
    postCode: "3878",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Chatkhil",
    postOffice: "Karihati",
    postCode: "3877",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Chatkhil",
    postOffice: "Khilpara",
    postCode: "3872",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Chatkhil",
    postOffice: "Palla",
    postCode: "3871",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Chatkhil",
    postOffice: "Rezzakpur",
    postCode: "3874",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Chatkhil",
    postOffice: "Sahapur",
    postCode: "3881",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Chatkhil",
    postOffice: "Sampara",
    postCode: "3882",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Chatkhil",
    postOffice: "Shingbahura",
    postCode: "3883",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Chatkhil",
    postOffice: "Solla",
    postCode: "3875",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Hatiya",
    postOffice: "Afazia",
    postCode: "3891",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Hatiya",
    postOffice: "Hatiya",
    postCode: "3890",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Hatiya",
    postOffice: "Tamoraddi",
    postCode: "3892",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Noakhali Sadar",
    postOffice: "Chaprashir Hat",
    postCode: "3811",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Noakhali Sadar",
    postOffice: "Char Jabbar",
    postCode: "3812",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Noakhali Sadar",
    postOffice: "Charam Tua",
    postCode: "3809",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Noakhali Sadar",
    postOffice: "Din Monir Hat",
    postCode: "3803",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Noakhali Sadar",
    postOffice: "Kabirhat",
    postCode: "3807",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Noakhali Sadar",
    postOffice: "Khalifar Hat",
    postCode: "3808",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Noakhali Sadar",
    postOffice: "Mriddarhat",
    postCode: "3806",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Noakhali Sadar",
    postOffice: "Noakhali College",
    postCode: "3801",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Noakhali Sadar",
    postOffice: "Noakhali Sadar",
    postCode: "3800",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Noakhali Sadar",
    postOffice: "Pak Kishoreganj",
    postCode: "3804",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Noakhali Sadar",
    postOffice: "Sonapur",
    postCode: "3802",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Senbag",
    postOffice: "Beezbag",
    postCode: "3862",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Senbag",
    postOffice: "Chatarpaia",
    postCode: "3864",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Senbag",
    postOffice: "Kallyandi",
    postCode: "3861",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Senbag",
    postOffice: "Kankirhat",
    postCode: "3863",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Senbag",
    postOffice: "Senbag",
    postCode: "3860",
  },
  {
    division_id: "2",
    district_id: "49",
    upazila: "Senbag",
    postOffice: "T.P. Lamua",
    postCode: "3865",
  },
  {
    division_id: "2",
    district_id: "50",
    upazila: "Barakal",
    postOffice: "Barakal",
    postCode: "4570",
  },
  {
    division_id: "2",
    district_id: "50",
    upazila: "Bilaichhari",
    postOffice: "Bilaichhari",
    postCode: "4550",
  },
  {
    division_id: "2",
    district_id: "50",
    upazila: "Jarachhari",
    postOffice: "Jarachhari",
    postCode: "4560",
  },
  {
    division_id: "2",
    district_id: "50",
    upazila: "Kalampati",
    postOffice: "Betbunia",
    postCode: "4511",
  },
  {
    division_id: "2",
    district_id: "50",
    upazila: "Kalampati",
    postOffice: "Kalampati",
    postCode: "4510",
  },
  {
    division_id: "2",
    district_id: "50",
    upazila: "kaptai",
    postOffice: "Chandraghona",
    postCode: "4531",
  },
  {
    division_id: "2",
    district_id: "50",
    upazila: "kaptai",
    postOffice: "Kaptai",
    postCode: "4530",
  },
  {
    division_id: "2",
    district_id: "50",
    upazila: "kaptai",
    postOffice: "Kaptai Nuton Bazar",
    postCode: "4533",
  },
  {
    division_id: "2",
    district_id: "50",
    upazila: "kaptai",
    postOffice: "Kaptai Project",
    postCode: "4532",
  },
  {
    division_id: "2",
    district_id: "50",
    upazila: "Longachh",
    postOffice: "Longachh",
    postCode: "4580",
  },
  {
    division_id: "2",
    district_id: "50",
    upazila: "Marishya",
    postOffice: "Marishya",
    postCode: "4590",
  },
  {
    division_id: "2",
    district_id: "50",
    upazila: "Naniachhar",
    postOffice: "Nanichhar",
    postCode: "4520",
  },
  {
    division_id: "2",
    district_id: "50",
    upazila: "Rajsthali",
    postOffice: "Rajsthali",
    postCode: "4540",
  },
  {
    division_id: "2",
    district_id: "50",
    upazila: "Rangamati Sadar",
    postOffice: "Rangamati Sadar",
    postCode: "4500",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Demra",
    postOffice: "Demra",
    postCode: "1360",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Demra",
    postOffice: "Matuail",
    postCode: "1362",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Demra",
    postOffice: "Sarulia",
    postCode: "1361",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Dhaka Cantt.",
    postOffice: "Dhaka CantonmentTSO",
    postCode: "1206",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Dhamrai",
    postOffice: "Dhamrai",
    postCode: "1350",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Dhamrai",
    postOffice: "Kamalpur",
    postCode: "1351",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Dhanmondi",
    postOffice: "Jigatala TSO",
    postCode: "1209",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Gulshan",
    postOffice: "Banani TSO",
    postCode: "1213",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Gulshan",
    postOffice: "Gulshan Model Town",
    postCode: "1212",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Jatrabari",
    postOffice: "Dhania TSO",
    postCode: "1232",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Joypara",
    postOffice: "Joypara",
    postCode: "1330",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Joypara",
    postOffice: "Narisha",
    postCode: "1332",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Joypara",
    postOffice: "Palamganj",
    postCode: "1331",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Keraniganj",
    postOffice: "Ati",
    postCode: "1312",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Keraniganj",
    postOffice: "Dhaka Jute Mills",
    postCode: "1311",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Keraniganj",
    postOffice: "Kalatia",
    postCode: "1313",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Keraniganj",
    postOffice: "Keraniganj",
    postCode: "1310",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Khilgaon",
    postOffice: "KhilgaonTSO",
    postCode: "1219",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Khilkhet",
    postOffice: "KhilkhetTSO",
    postCode: "1229",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Lalbag",
    postOffice: "Posta TSO",
    postCode: "1211",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Mirpur",
    postOffice: "Mirpur TSO",
    postCode: "1216",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Mohammadpur",
    postOffice: "Mohammadpur Housing",
    postCode: "1207",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Mohammadpur",
    postOffice: "Sangsad BhabanTSO",
    postCode: "1225",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Motijheel",
    postOffice: "BangabhabanTSO",
    postCode: "1222",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Motijheel",
    postOffice: "DilkushaTSO",
    postCode: "1223",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Nawabganj",
    postOffice: "Agla",
    postCode: "1323",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Nawabganj",
    postOffice: "Churain",
    postCode: "1325",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Nawabganj",
    postOffice: "Daudpur",
    postCode: "1322",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Nawabganj",
    postOffice: "Hasnabad",
    postCode: "1321",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Nawabganj",
    postOffice: "Khalpar",
    postCode: "1324",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Nawabganj",
    postOffice: "Nawabganj",
    postCode: "1320",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "New market",
    postOffice: "New Market TSO",
    postCode: "1205",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Palton",
    postOffice: "Dhaka GPO",
    postCode: "1000",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Ramna",
    postOffice: "Shantinagr TSO",
    postCode: "1217",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Sabujbag",
    postOffice: "Basabo TSO",
    postCode: "1214",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Savar",
    postOffice: "Amin Bazar",
    postCode: "1348",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Savar",
    postOffice: "Dairy Farm",
    postCode: "1341",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Savar",
    postOffice: "EPZ",
    postCode: "1349",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Savar",
    postOffice: "Jahangirnagar Univer",
    postCode: "1342",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Savar",
    postOffice: "Kashem Cotton Mills",
    postCode: "1346",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Savar",
    postOffice: "Rajphulbaria",
    postCode: "1347",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Savar",
    postOffice: "Savar",
    postCode: "1340",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Savar",
    postOffice: "Savar Canttonment",
    postCode: "1344",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Savar",
    postOffice: "Saver P.A.T.C",
    postCode: "1343",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Savar",
    postOffice: "Shimulia",
    postCode: "1345",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Sutrapur",
    postOffice: "Dhaka Sadar HO",
    postCode: "1100",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Sutrapur",
    postOffice: "Gendaria TSO",
    postCode: "1204",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Sutrapur",
    postOffice: "Wari TSO",
    postCode: "1203",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Tejgaon",
    postOffice: "Tejgaon TSO",
    postCode: "1215",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Tejgaon Industrial Area",
    postOffice: "Dhaka Politechnic",
    postCode: "1208",
  },
  {
    division_id: "3",
    district_id: "1",
    upazila: "Uttara",
    postOffice: "Uttara Model TwonTSO",
    postCode: "1230",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Alfadanga",
    postOffice: "Alfadanga",
    postCode: "7870",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Bhanga",
    postOffice: "Bhanga",
    postCode: "7830",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Boalmari",
    postOffice: "Boalmari",
    postCode: "7860",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Boalmari",
    postOffice: "Rupatpat",
    postCode: "7861",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Charbhadrasan",
    postOffice: "Charbadrashan",
    postCode: "7810",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Faridpur Sadar",
    postOffice: "Ambikapur",
    postCode: "7802",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Faridpur Sadar",
    postOffice: "Baitulaman Politecni",
    postCode: "7803",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Faridpur Sadar",
    postOffice: "Faridpursadar",
    postCode: "7800",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Faridpur Sadar",
    postOffice: "Kanaipur",
    postCode: "7801",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Madukhali",
    postOffice: "Kamarkali",
    postCode: "7851",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Madukhali",
    postOffice: "Madukhali",
    postCode: "7850",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Nagarkanda",
    postOffice: "Nagarkanda",
    postCode: "7840",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Nagarkanda",
    postOffice: "Talma",
    postCode: "7841",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Sadarpur",
    postOffice: "Bishwa jaker Manjil",
    postCode: "7822",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Sadarpur",
    postOffice: "Hat Krishapur",
    postCode: "7821",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Sadarpur",
    postOffice: "Sadarpur",
    postCode: "7820",
  },
  {
    division_id: "3",
    district_id: "2",
    upazila: "Shriangan",
    postOffice: "Shriangan",
    postCode: "7804",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Gazipur Sadar",
    postOffice: "B.O.F",
    postCode: "1703",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Gazipur Sadar",
    postOffice: "B.R.R",
    postCode: "1701",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Gazipur Sadar",
    postOffice: "Chandna",
    postCode: "1702",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Gazipur Sadar",
    postOffice: "Gazipur Sadar",
    postCode: "1700",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Gazipur Sadar",
    postOffice: "National University",
    postCode: "1704",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Kaliakaar",
    postOffice: "Kaliakaar",
    postCode: "1750",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Kaliakaar",
    postOffice: "Safipur",
    postCode: "1751",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Kaliganj",
    postOffice: "Kaliganj",
    postCode: "1720",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Kaliganj",
    postOffice: "Pubail",
    postCode: "1721",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Kaliganj",
    postOffice: "Santanpara",
    postCode: "1722",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Kaliganj",
    postOffice: "Vaoal Jamalpur",
    postCode: "1723",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Kapashia",
    postOffice: "kapashia",
    postCode: "1730",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Monnunagar",
    postOffice: "Ershad Nagar",
    postCode: "1712",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Monnunagar",
    postOffice: "Monnunagar",
    postCode: "1710",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Monnunagar",
    postOffice: "Nishat Nagar",
    postCode: "1711",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Sreepur",
    postOffice: "Barmi",
    postCode: "1743",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Sreepur",
    postOffice: "Bashamur",
    postCode: "1747",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Sreepur",
    postOffice: "Boubi",
    postCode: "1748",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Sreepur",
    postOffice: "Kawraid",
    postCode: "1745",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Sreepur",
    postOffice: "Satkhamair",
    postCode: "1744",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Sreepur",
    postOffice: "Sreepur",
    postCode: "1740",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Sripur",
    postOffice: "Rajendrapur",
    postCode: "1741",
  },
  {
    division_id: "3",
    district_id: "3",
    upazila: "Sripur",
    postOffice: "Rajendrapur Canttome",
    postCode: "1742",
  },
  {
    division_id: "3",
    district_id: "4",
    upazila: "Gopalganj Sadar",
    postOffice: "Barfa",
    postCode: "8102",
  },
  {
    division_id: "3",
    district_id: "4",
    upazila: "Gopalganj Sadar",
    postOffice: "Chandradighalia",
    postCode: "8013",
  },
  {
    division_id: "3",
    district_id: "4",
    upazila: "Gopalganj Sadar",
    postOffice: "Gopalganj Sadar",
    postCode: "8100",
  },
  {
    division_id: "3",
    district_id: "4",
    upazila: "Gopalganj Sadar",
    postOffice: "Ulpur",
    postCode: "8101",
  },
  {
    division_id: "3",
    district_id: "4",
    upazila: "Kashiani",
    postOffice: "Jonapur",
    postCode: "8133",
  },
  {
    division_id: "3",
    district_id: "4",
    upazila: "Kashiani",
    postOffice: "Kashiani",
    postCode: "8130",
  },
  {
    division_id: "3",
    district_id: "4",
    upazila: "Kashiani",
    postOffice: "Ramdia College",
    postCode: "8131",
  },
  {
    division_id: "3",
    district_id: "4",
    upazila: "Kashiani",
    postOffice: "Ratoil",
    postCode: "8132",
  },
  {
    division_id: "3",
    district_id: "4",
    upazila: "Kotalipara",
    postOffice: "Kotalipara",
    postCode: "8110",
  },
  {
    division_id: "3",
    district_id: "4",
    upazila: "Maksudpur",
    postOffice: "Batkiamari",
    postCode: "8141",
  },
  {
    division_id: "3",
    district_id: "4",
    upazila: "Maksudpur",
    postOffice: "Khandarpara",
    postCode: "8142",
  },
  {
    division_id: "3",
    district_id: "4",
    upazila: "Maksudpur",
    postOffice: "Maksudpur",
    postCode: "8140",
  },
  {
    division_id: "3",
    district_id: "4",
    upazila: "Tungipara",
    postOffice: "Patgati",
    postCode: "8121",
  },
  {
    division_id: "3",
    district_id: "4",
    upazila: "Tungipara",
    postOffice: "Tungipara",
    postCode: "8120",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Dewangonj",
    postOffice: "Dewangonj",
    postCode: "2030",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Dewangonj",
    postOffice: "Dewangonj S. Mills",
    postCode: "2031",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Islampur",
    postOffice: "Durmoot",
    postCode: "2021",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Islampur",
    postOffice: "Gilabari",
    postCode: "2022",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Islampur",
    postOffice: "Islampur",
    postCode: "2020",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Jamalpur",
    postOffice: "Jamalpur",
    postCode: "2000",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Jamalpur",
    postOffice: "Nandina",
    postCode: "2001",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Jamalpur",
    postOffice: "Narundi",
    postCode: "2002",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Malandah",
    postOffice: "Jamalpur",
    postCode: "2011",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Malandah",
    postOffice: "Mahmoodpur",
    postCode: "2013",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Malandah",
    postOffice: "Malancha",
    postCode: "2012",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Malandah",
    postOffice: "Malandah",
    postCode: "2010",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Mathargonj",
    postOffice: "Balijhuri",
    postCode: "2041",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Mathargonj",
    postOffice: "Mathargonj",
    postCode: "2040",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Shorishabari",
    postOffice: "Bausee",
    postCode: "2052",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Shorishabari",
    postOffice: "Gunerbari",
    postCode: "2051",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Shorishabari",
    postOffice: "Jagannath Ghat",
    postCode: "2053",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Shorishabari",
    postOffice: "Jamuna Sar Karkhana",
    postCode: "2055",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Shorishabari",
    postOffice: "Pingna",
    postCode: "2054",
  },
  {
    division_id: "3",
    district_id: "5",
    upazila: "Shorishabari",
    postOffice: "Shorishabari",
    postCode: "2050",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Bajitpur",
    postOffice: "Bajitpur",
    postCode: "2336",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Bajitpur",
    postOffice: "Laksmipur",
    postCode: "2338",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Bajitpur",
    postOffice: "Sararchar",
    postCode: "2337",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Bhairob",
    postOffice: "Bhairab",
    postCode: "2350",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Hossenpur",
    postOffice: "Hossenpur",
    postCode: "2320",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Itna",
    postOffice: "Itna",
    postCode: "2390",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Karimganj",
    postOffice: "Karimganj",
    postCode: "2310",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Katiadi",
    postOffice: "Gochhihata",
    postCode: "2331",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Katiadi",
    postOffice: "Katiadi",
    postCode: "2330",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Kishoreganj Sadar",
    postOffice: "Kishoreganj S.Mills",
    postCode: "2301",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Kishoreganj Sadar",
    postOffice: "Kishoreganj Sadar",
    postCode: "2300",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Kishoreganj Sadar",
    postOffice: "Maizhati",
    postCode: "2302",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Kishoreganj Sadar",
    postOffice: "Nilganj",
    postCode: "2303",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Kuliarchar",
    postOffice: "Chhoysuti",
    postCode: "2341",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Kuliarchar",
    postOffice: "Kuliarchar",
    postCode: "2340",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Mithamoin",
    postOffice: "Abdullahpur",
    postCode: "2371",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Mithamoin",
    postOffice: "MIthamoin",
    postCode: "2370",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Nikli",
    postOffice: "Nikli",
    postCode: "2360",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Ostagram",
    postOffice: "Ostagram",
    postCode: "2380",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Pakundia",
    postOffice: "Pakundia",
    postCode: "2326",
  },
  {
    division_id: "3",
    district_id: "6",
    upazila: "Tarial",
    postOffice: "Tarial",
    postCode: "2316",
  },
  {
    division_id: "3",
    district_id: "7",
    upazila: "Barhamganj",
    postOffice: "Bahadurpur",
    postCode: "7932",
  },
  {
    division_id: "3",
    district_id: "7",
    upazila: "Barhamganj",
    postOffice: "Barhamganj",
    postCode: "7930",
  },
  {
    division_id: "3",
    district_id: "7",
    upazila: "Barhamganj",
    postOffice: "Nilaksmibandar",
    postCode: "7931",
  },
  {
    division_id: "3",
    district_id: "7",
    upazila: "Barhamganj",
    postOffice: "Umedpur",
    postCode: "7933",
  },
  {
    division_id: "3",
    district_id: "7",
    upazila: "kalkini",
    postOffice: "Kalkini",
    postCode: "7920",
  },
  {
    division_id: "3",
    district_id: "7",
    upazila: "kalkini",
    postOffice: "Sahabrampur",
    postCode: "7921",
  },
  {
    division_id: "3",
    district_id: "7",
    upazila: "Madaripur Sadar",
    postOffice: "Charmugria",
    postCode: "7901",
  },
  {
    division_id: "3",
    district_id: "7",
    upazila: "Madaripur Sadar",
    postOffice: "Habiganj",
    postCode: "7903",
  },
  {
    division_id: "3",
    district_id: "7",
    upazila: "Madaripur Sadar",
    postOffice: "Kulpaddi",
    postCode: "7902",
  },
  {
    division_id: "3",
    district_id: "7",
    upazila: "Madaripur Sadar",
    postOffice: "Madaripur Sadar",
    postCode: "7900",
  },
  {
    division_id: "3",
    district_id: "7",
    upazila: "Madaripur Sadar",
    postOffice: "Mustafapur",
    postCode: "7904",
  },
  {
    division_id: "3",
    district_id: "7",
    upazila: "Rajoir",
    postOffice: "Khalia",
    postCode: "7911",
  },
  {
    division_id: "3",
    district_id: "7",
    upazila: "Rajoir",
    postOffice: "Rajoir",
    postCode: "7910",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Doulatpur",
    postOffice: "Doulatpur",
    postCode: "1860",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Gheor",
    postOffice: "Gheor",
    postCode: "1840",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Lechhraganj",
    postOffice: "Jhitka",
    postCode: "1831",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Lechhraganj",
    postOffice: "Lechhraganj",
    postCode: "1830",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Manikganj Sadar",
    postOffice: "Barangail",
    postCode: "1804",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Manikganj Sadar",
    postOffice: "Gorpara",
    postCode: "1802",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Manikganj Sadar",
    postOffice: "Mahadebpur",
    postCode: "1803",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Manikganj Sadar",
    postOffice: "Manikganj Bazar",
    postCode: "1801",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Manikganj Sadar",
    postOffice: "Manikganj Sadar",
    postCode: "1800",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Saturia",
    postOffice: "Baliati",
    postCode: "1811",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Saturia",
    postOffice: "Saturia",
    postCode: "1810",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Shibloya",
    postOffice: "Aricha",
    postCode: "1851",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Shibloya",
    postOffice: "Shibaloy",
    postCode: "1850",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Shibloya",
    postOffice: "Tewta",
    postCode: "1852",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Shibloya",
    postOffice: "Uthli",
    postCode: "1853",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Singari",
    postOffice: "Baira",
    postCode: "1821",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Singari",
    postOffice: "joymantop",
    postCode: "1822",
  },
  {
    division_id: "3",
    district_id: "8",
    upazila: "Singari",
    postOffice: "Singair",
    postCode: "1820",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Gajaria",
    postOffice: "Gajaria",
    postCode: "1510",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Gajaria",
    postOffice: "Hossendi",
    postCode: "1511",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Gajaria",
    postOffice: "Rasulpur",
    postCode: "1512",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Lohajong",
    postOffice: "Gouragonj",
    postCode: "1334",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Lohajong",
    postOffice: "Gouragonj",
    postCode: "1534",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Lohajong",
    postOffice: "Haldia SO",
    postCode: "1532",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Lohajong",
    postOffice: "Haridia",
    postCode: "1333",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Lohajong",
    postOffice: "Haridia DESO",
    postCode: "1533",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Lohajong",
    postOffice: "Korhati",
    postCode: "1531",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Lohajong",
    postOffice: "Lohajang",
    postCode: "1530",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Lohajong",
    postOffice: "Madini Mandal",
    postCode: "1335",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Lohajong",
    postOffice: "Medini Mandal EDSO",
    postCode: "1535",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Munshiganj Sadar",
    postOffice: "Kathakhali",
    postCode: "1503",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Munshiganj Sadar",
    postOffice: "Mirkadim",
    postCode: "1502",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Munshiganj Sadar",
    postOffice: "Munshiganj Sadar",
    postCode: "1500",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Munshiganj Sadar",
    postOffice: "Rikabibazar",
    postCode: "1501",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Sirajdikhan",
    postOffice: "Ichapur",
    postCode: "1542",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Sirajdikhan",
    postOffice: "Kola",
    postCode: "1541",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Sirajdikhan",
    postOffice: "Malkha Nagar",
    postCode: "1543",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Sirajdikhan",
    postOffice: "Shekher Nagar",
    postCode: "1544",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Sirajdikhan",
    postOffice: "Sirajdikhan",
    postCode: "1540",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Srinagar",
    postOffice: "Baghra",
    postCode: "1557",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Srinagar",
    postOffice: "Barikhal",
    postCode: "1551",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Srinagar",
    postOffice: "Bhaggyakul",
    postCode: "1558",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Srinagar",
    postOffice: "Hashara",
    postCode: "1553",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Srinagar",
    postOffice: "Kolapara",
    postCode: "1554",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Srinagar",
    postOffice: "Kumarbhog",
    postCode: "1555",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Srinagar",
    postOffice: "Mazpara",
    postCode: "1552",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Srinagar",
    postOffice: "Srinagar",
    postCode: "1550",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Srinagar",
    postOffice: "Vaggyakul SO",
    postCode: "1556",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Tangibari",
    postOffice: "Bajrajugini",
    postCode: "1523",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Tangibari",
    postOffice: "Baligao",
    postCode: "1522",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Tangibari",
    postOffice: "Betkahat",
    postCode: "1521",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Tangibari",
    postOffice: "Dighirpar",
    postCode: "1525",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Tangibari",
    postOffice: "Hasail",
    postCode: "1524",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Tangibari",
    postOffice: "Pura",
    postCode: "1527",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Tangibari",
    postOffice: "Pura EDSO",
    postCode: "1526",
  },
  {
    division_id: "3",
    district_id: "9",
    upazila: "Tangibari",
    postOffice: "Tangibari",
    postCode: "1520",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Bhaluka",
    postOffice: "Bhaluka",
    postCode: "2240",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Fulbaria",
    postOffice: "Fulbaria",
    postCode: "2216",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Gaforgaon",
    postOffice: "Duttarbazar",
    postCode: "2234",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Gaforgaon",
    postOffice: "Gaforgaon",
    postCode: "2230",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Gaforgaon",
    postOffice: "Kandipara",
    postCode: "2233",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Gaforgaon",
    postOffice: "Shibganj",
    postCode: "2231",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Gaforgaon",
    postOffice: "Usti",
    postCode: "2232",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Gouripur",
    postOffice: "Gouripur",
    postCode: "2270",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Gouripur",
    postOffice: "Ramgopalpur",
    postCode: "2271",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Haluaghat",
    postOffice: "Dhara",
    postCode: "2261",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Haluaghat",
    postOffice: "Haluaghat",
    postCode: "2260",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Haluaghat",
    postOffice: "Munshirhat",
    postCode: "2262",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Isshwargonj",
    postOffice: "Atharabari",
    postCode: "2282",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Isshwargonj",
    postOffice: "Isshwargonj",
    postCode: "2280",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Isshwargonj",
    postOffice: "Sohagi",
    postCode: "2281",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Muktagachha",
    postOffice: "Muktagachha",
    postCode: "2210",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Mymensingh Sadar",
    postOffice: "Agriculture Universi",
    postCode: "2202",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Mymensingh Sadar",
    postOffice: "Biddyaganj",
    postCode: "2204",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Mymensingh Sadar",
    postOffice: "Kawatkhali",
    postCode: "2201",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Mymensingh Sadar",
    postOffice: "Mymensingh Sadar",
    postCode: "2200",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Mymensingh Sadar",
    postOffice: "Pearpur",
    postCode: "2205",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Mymensingh Sadar",
    postOffice: "Shombhuganj",
    postCode: "2203",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Nandail",
    postOffice: "Gangail",
    postCode: "2291",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Nandail",
    postOffice: "Nandail",
    postCode: "2290",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Phulpur",
    postOffice: "Beltia",
    postCode: "2251",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Phulpur",
    postOffice: "Phulpur",
    postCode: "2250",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Phulpur",
    postOffice: "Tarakanda",
    postCode: "2252",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Trishal",
    postOffice: "Ahmadbad",
    postCode: "2221",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Trishal",
    postOffice: "Dhala",
    postCode: "2223",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Trishal",
    postOffice: "Ram Amritaganj",
    postCode: "2222",
  },
  {
    division_id: "3",
    district_id: "10",
    upazila: "Trishal",
    postOffice: "Trishal",
    postCode: "2220",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Araihazar",
    postOffice: "Araihazar",
    postCode: "1450",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Araihazar",
    postOffice: "Gopaldi",
    postCode: "1451",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Baidder Bazar",
    postOffice: "Baidder Bazar",
    postCode: "1440",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Baidder Bazar",
    postOffice: "Bara Nagar",
    postCode: "1441",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Baidder Bazar",
    postOffice: "Barodi",
    postCode: "1442",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Bandar",
    postOffice: "Bandar",
    postCode: "1410",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Bandar",
    postOffice: "BIDS",
    postCode: "1413",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Bandar",
    postOffice: "D.C Mills",
    postCode: "1411",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Bandar",
    postOffice: "Madanganj",
    postCode: "1414",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Bandar",
    postOffice: "Nabiganj",
    postCode: "1412",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Fatullah",
    postOffice: "Fatulla Bazar",
    postCode: "1421",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Fatullah",
    postOffice: "Fatullah",
    postCode: "1420",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Narayanganj Sadar",
    postOffice: "Narayanganj Sadar",
    postCode: "1400",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Rupganj",
    postOffice: "Bhulta",
    postCode: "1462",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Rupganj",
    postOffice: "Kanchan",
    postCode: "1461",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Rupganj",
    postOffice: "Murapara",
    postCode: "1464",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Rupganj",
    postOffice: "Nagri",
    postCode: "1463",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Rupganj",
    postOffice: "Rupganj",
    postCode: "1460",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Siddirganj",
    postOffice: "Adamjeenagar",
    postCode: "1431",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Siddirganj",
    postOffice: "LN Mills",
    postCode: "1432",
  },
  {
    division_id: "3",
    district_id: "11",
    upazila: "Siddirganj",
    postOffice: "Siddirganj",
    postCode: "1430",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Belabo",
    postOffice: "Belabo",
    postCode: "1640",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Monohordi",
    postOffice: "Hatirdia",
    postCode: "1651",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Monohordi",
    postOffice: "Katabaria",
    postCode: "1652",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Monohordi",
    postOffice: "Monohordi",
    postCode: "1650",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Narshingdi Sadar",
    postOffice: "Karimpur",
    postCode: "1605",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Narshingdi Sadar",
    postOffice: "Madhabdi",
    postCode: "1604",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Narshingdi Sadar",
    postOffice: "Narshingdi College",
    postCode: "1602",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Narshingdi Sadar",
    postOffice: "Narshingdi Sadar",
    postCode: "1600",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Narshingdi Sadar",
    postOffice: "Panchdona",
    postCode: "1603",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Narshingdi Sadar",
    postOffice: "UMC Jute Mills",
    postCode: "1601",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Palash",
    postOffice: "Char Sindhur",
    postCode: "1612",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Palash",
    postOffice: "Ghorashal",
    postCode: "1613",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Palash",
    postOffice: "Ghorashal Urea Facto",
    postCode: "1611",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Palash",
    postOffice: "Palash",
    postCode: "1610",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Raypura",
    postOffice: "Bazar Hasnabad",
    postCode: "1631",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Raypura",
    postOffice: "Radhaganj bazar",
    postCode: "1632",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Raypura",
    postOffice: "Raypura",
    postCode: "1630",
  },
  {
    division_id: "3",
    district_id: "12",
    upazila: "Shibpur",
    postOffice: "Shibpur",
    postCode: "1620",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Susung Durgapur",
    postOffice: "Susnng Durgapur",
    postCode: "2420",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Atpara",
    postOffice: "Atpara",
    postCode: "2470",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Barhatta",
    postOffice: "Barhatta",
    postCode: "2440",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Dharmapasha",
    postOffice: "Dharampasha",
    postCode: "2450",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Dhobaura",
    postOffice: "Dhobaura",
    postCode: "2416",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Dhobaura",
    postOffice: "Sakoai",
    postCode: "2417",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Kalmakanda",
    postOffice: "Kalmakanda",
    postCode: "2430",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Kendua",
    postOffice: "Kendua",
    postCode: "2480",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Khaliajuri",
    postOffice: "Khaliajhri",
    postCode: "2460",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Khaliajuri",
    postOffice: "Shaldigha",
    postCode: "2462",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Madan",
    postOffice: "Madan",
    postCode: "2490",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Moddhynagar",
    postOffice: "Moddoynagar",
    postCode: "2456",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Mohanganj",
    postOffice: "Mohanganj",
    postCode: "2446",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Netrakona Sadar",
    postOffice: "Baikherhati",
    postCode: "2401",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Netrakona Sadar",
    postOffice: "Netrakona Sadar",
    postCode: "2400",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Purbadhola",
    postOffice: "Jaria Jhanjhail",
    postCode: "2412",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Purbadhola",
    postOffice: "Purbadhola",
    postCode: "2410",
  },
  {
    division_id: "3",
    district_id: "13",
    upazila: "Purbadhola",
    postOffice: "Shamgonj",
    postCode: "2411",
  },
  {
    division_id: "3",
    district_id: "14",
    upazila: "Baliakandi",
    postOffice: "Baliakandi",
    postCode: "7730",
  },
  {
    division_id: "3",
    district_id: "14",
    upazila: "Baliakandi",
    postOffice: "Nalia",
    postCode: "7731",
  },
  {
    division_id: "3",
    district_id: "14",
    upazila: "Pangsha",
    postOffice: "Mrigibazar",
    postCode: "7723",
  },
  {
    division_id: "3",
    district_id: "14",
    upazila: "Pangsha",
    postOffice: "Pangsha",
    postCode: "7720",
  },
  {
    division_id: "3",
    district_id: "14",
    upazila: "Pangsha",
    postOffice: "Ramkol",
    postCode: "7721",
  },
  {
    division_id: "3",
    district_id: "14",
    upazila: "Pangsha",
    postOffice: "Ratandia",
    postCode: "7722",
  },
  {
    division_id: "3",
    district_id: "14",
    upazila: "Rajbari Sadar",
    postOffice: "Goalanda",
    postCode: "7710",
  },
  {
    division_id: "3",
    district_id: "14",
    upazila: "Rajbari Sadar",
    postOffice: "Khankhanapur",
    postCode: "7711",
  },
  {
    division_id: "3",
    district_id: "14",
    upazila: "Rajbari Sadar",
    postOffice: "Rajbari Sadar",
    postCode: "7700",
  },
  {
    division_id: "3",
    district_id: "15",
    upazila: "Bhedorganj",
    postOffice: "Bhedorganj",
    postCode: "8030",
  },
  {
    division_id: "3",
    district_id: "15",
    upazila: "Damudhya",
    postOffice: "Damudhya",
    postCode: "8040",
  },
  {
    division_id: "3",
    district_id: "15",
    upazila: "Gosairhat",
    postOffice: "Gosairhat",
    postCode: "8050",
  },
  {
    division_id: "3",
    district_id: "15",
    upazila: "Jajira",
    postOffice: "Jajira",
    postCode: "8010",
  },
  {
    division_id: "3",
    district_id: "15",
    upazila: "Naria",
    postOffice: "Bhozeshwar",
    postCode: "8021",
  },
  {
    division_id: "3",
    district_id: "15",
    upazila: "Naria",
    postOffice: "Gharisar",
    postCode: "8022",
  },
  {
    division_id: "3",
    district_id: "15",
    upazila: "Naria",
    postOffice: "Kartikpur",
    postCode: "8024",
  },
  {
    division_id: "3",
    district_id: "15",
    upazila: "Naria",
    postOffice: "Naria",
    postCode: "8020",
  },
  {
    division_id: "3",
    district_id: "15",
    upazila: "Naria",
    postOffice: "Upshi",
    postCode: "8023",
  },
  {
    division_id: "3",
    district_id: "15",
    upazila: "Shariatpur Sadar",
    postOffice: "Angaria",
    postCode: "8001",
  },
  {
    division_id: "3",
    district_id: "15",
    upazila: "Shariatpur Sadar",
    postOffice: "Chikandi",
    postCode: "8002",
  },
  {
    division_id: "3",
    district_id: "15",
    upazila: "Shariatpur Sadar",
    postOffice: "Shariatpur Sadar",
    postCode: "8000",
  },
  {
    division_id: "3",
    district_id: "16",
    upazila: "Bakshigonj",
    postOffice: "Bakshigonj",
    postCode: "2140",
  },
  {
    division_id: "3",
    district_id: "16",
    upazila: "Jhinaigati",
    postOffice: "Jhinaigati",
    postCode: "2120",
  },
  {
    division_id: "3",
    district_id: "16",
    upazila: "Nakla",
    postOffice: "Gonopaddi",
    postCode: "2151",
  },
  {
    division_id: "3",
    district_id: "16",
    upazila: "Nakla",
    postOffice: "Nakla",
    postCode: "2150",
  },
  {
    division_id: "3",
    district_id: "16",
    upazila: "Nalitabari",
    postOffice: "Hatibandha",
    postCode: "2111",
  },
  {
    division_id: "3",
    district_id: "16",
    upazila: "Nalitabari",
    postOffice: "Nalitabari",
    postCode: "2110",
  },
  {
    division_id: "3",
    district_id: "16",
    upazila: "Sherpur Shadar",
    postOffice: "Sherpur Shadar",
    postCode: "2100",
  },
  {
    division_id: "3",
    district_id: "16",
    upazila: "Shribardi",
    postOffice: "Shribardi",
    postCode: "2130",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Basail",
    postOffice: "Basail",
    postCode: "1920",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Bhuapur",
    postOffice: "Bhuapur",
    postCode: "1960",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Delduar",
    postOffice: "Delduar",
    postCode: "1910",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Delduar",
    postOffice: "Elasin",
    postCode: "1913",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Delduar",
    postOffice: "Hinga Nagar",
    postCode: "1914",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Delduar",
    postOffice: "Jangalia",
    postCode: "1911",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Delduar",
    postOffice: "Lowhati",
    postCode: "1915",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Delduar",
    postOffice: "Patharail",
    postCode: "1912",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Ghatail",
    postOffice: "D. Pakutia",
    postCode: "1982",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Ghatail",
    postOffice: "Dhalapara",
    postCode: "1983",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Ghatail",
    postOffice: "Ghatial",
    postCode: "1980",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Ghatail",
    postOffice: "Lohani",
    postCode: "1984",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Ghatail",
    postOffice: "Zahidganj",
    postCode: "1981",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Gopalpur",
    postOffice: "Gopalpur",
    postCode: "1990",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Gopalpur",
    postOffice: "Hemnagar",
    postCode: "1992",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Gopalpur",
    postOffice: "Jhowail",
    postCode: "1991",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Kalihati",
    postOffice: "Ballabazar",
    postCode: "1973",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Kalihati",
    postOffice: "Elinga",
    postCode: "1974",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Kalihati",
    postOffice: "Kalihati",
    postCode: "1970",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Kalihati",
    postOffice: "Nagarbari",
    postCode: "1977",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Kalihati",
    postOffice: "Nagarbari SO",
    postCode: "1976",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Kalihati",
    postOffice: "Nagbari",
    postCode: "1972",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Kalihati",
    postOffice: "Palisha",
    postCode: "1975",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Kalihati",
    postOffice: "Rajafair",
    postCode: "1971",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Kashkaolia",
    postOffice: "Kashkawlia",
    postCode: "1930",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Madhupur",
    postOffice: "Dhobari",
    postCode: "1997",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Madhupur",
    postOffice: "Madhupur",
    postCode: "1996",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Mirzapur",
    postOffice: "Gorai",
    postCode: "1941",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Mirzapur",
    postOffice: "Jarmuki",
    postCode: "1944",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Mirzapur",
    postOffice: "M.C. College",
    postCode: "1942",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Mirzapur",
    postOffice: "Mirzapur",
    postCode: "1940",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Mirzapur",
    postOffice: "Mohera",
    postCode: "1945",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Mirzapur",
    postOffice: "Warri paikpara",
    postCode: "1943",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Nagarpur",
    postOffice: "Dhuburia",
    postCode: "1937",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Nagarpur",
    postOffice: "Nagarpur",
    postCode: "1936",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Nagarpur",
    postOffice: "Salimabad",
    postCode: "1938",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Sakhipur",
    postOffice: "Kochua",
    postCode: "1951",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Sakhipur",
    postOffice: "Sakhipur",
    postCode: "1950",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Tangail Sadar",
    postOffice: "Kagmari",
    postCode: "1901",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Tangail Sadar",
    postOffice: "Korotia",
    postCode: "1903",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Tangail Sadar",
    postOffice: "Purabari",
    postCode: "1904",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Tangail Sadar",
    postOffice: "Santosh",
    postCode: "1902",
  },
  {
    division_id: "3",
    district_id: "17",
    upazila: "Tangail Sadar",
    postOffice: "Tangail Sadar",
    postCode: "1900",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Bagerhat Sadar",
    postOffice: "Bagerhat Sadar",
    postCode: "9300",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Bagerhat Sadar",
    postOffice: "P.C College",
    postCode: "9301",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Bagerhat Sadar",
    postOffice: "Rangdia",
    postCode: "9302",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Chalna Ankorage",
    postOffice: "Chalna Ankorage",
    postCode: "9350",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Chalna Ankorage",
    postOffice: "Mongla Port",
    postCode: "9351",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Chitalmari",
    postOffice: "Barabaria",
    postCode: "9361",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Chitalmari",
    postOffice: "Chitalmari",
    postCode: "9360",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Fakirhat",
    postOffice: "Bhanganpar Bazar",
    postCode: "9372",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Fakirhat",
    postOffice: "Fakirhat",
    postCode: "9370",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Fakirhat",
    postOffice: "Mansa",
    postCode: "9371",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Kachua UPO",
    postOffice: "Kachua",
    postCode: "9310",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Kachua UPO",
    postOffice: "Sonarkola",
    postCode: "9311",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Mollahat",
    postOffice: "Charkulia",
    postCode: "9383",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Mollahat",
    postOffice: "Dariala",
    postCode: "9382",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Mollahat",
    postOffice: "Kahalpur",
    postCode: "9381",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Mollahat",
    postOffice: "Mollahat",
    postCode: "9380",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Mollahat",
    postOffice: "Nagarkandi",
    postCode: "9384",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Mollahat",
    postOffice: "Pak Gangni",
    postCode: "9385",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Morelganj",
    postOffice: "Morelganj",
    postCode: "9320",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Morelganj",
    postOffice: "Sannasi Bazar",
    postCode: "9321",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Morelganj",
    postOffice: "Telisatee",
    postCode: "9322",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Rampal",
    postOffice: "Foylahat",
    postCode: "9341",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Rampal",
    postOffice: "Gourambha",
    postCode: "9343",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Rampal",
    postOffice: "Rampal",
    postCode: "9340",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Rampal",
    postOffice: "Sonatunia",
    postCode: "9342",
  },
  {
    division_id: "4",
    district_id: "55",
    upazila: "Rayenda",
    postOffice: "Rayenda",
    postCode: "9330",
  },
  {
    division_id: "4",
    district_id: "56",
    upazila: "Alamdanga",
    postOffice: "Alamdanga",
    postCode: "7210",
  },
  {
    division_id: "4",
    district_id: "56",
    upazila: "Alamdanga",
    postOffice: "Hardi",
    postCode: "7211",
  },
  {
    division_id: "4",
    district_id: "56",
    upazila: "Chuadanga Sadar",
    postOffice: "Chuadanga Sadar",
    postCode: "7200",
  },
  {
    division_id: "4",
    district_id: "56",
    upazila: "Chuadanga Sadar",
    postOffice: "Munshiganj",
    postCode: "7201",
  },
  {
    division_id: "4",
    district_id: "56",
    upazila: "Damurhuda",
    postOffice: "Andulbaria",
    postCode: "7222",
  },
  {
    division_id: "4",
    district_id: "56",
    upazila: "Damurhuda",
    postOffice: "Damurhuda",
    postCode: "7220",
  },
  {
    division_id: "4",
    district_id: "56",
    upazila: "Damurhuda",
    postOffice: "Darshana",
    postCode: "7221",
  },
  {
    division_id: "4",
    district_id: "56",
    upazila: "Doulatganj",
    postOffice: "Doulatganj",
    postCode: "7230",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Bagharpara",
    postOffice: "Bagharpara",
    postCode: "7470",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Bagharpara",
    postOffice: "Gouranagar",
    postCode: "7471",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Chaugachha",
    postOffice: "Chougachha",
    postCode: "7410",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Jessore Sadar",
    postOffice: "Basundia",
    postCode: "7406",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Jessore Sadar",
    postOffice: "Chanchra",
    postCode: "7402",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Jessore Sadar",
    postOffice: "Churamankathi",
    postCode: "7407",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Jessore Sadar",
    postOffice: "Jessore Airbach",
    postCode: "7404",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Jessore Sadar",
    postOffice: "Jessore canttonment",
    postCode: "7403",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Jessore Sadar",
    postOffice: "Jessore Sadar",
    postCode: "7400",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Jessore Sadar",
    postOffice: "Jessore Upa-Shahar",
    postCode: "7401",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Jessore Sadar",
    postOffice: "Rupdia",
    postCode: "7405",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Jhikargachha",
    postOffice: "Jhikargachha",
    postCode: "7420",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Keshabpur",
    postOffice: "Keshobpur",
    postCode: "7450",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Monirampur",
    postOffice: "Monirampur",
    postCode: "7440",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Noapara",
    postOffice: "Bhugilhat",
    postCode: "7462",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Noapara",
    postOffice: "Noapara",
    postCode: "7460",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Noapara",
    postOffice: "Rajghat",
    postCode: "7461",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Sarsa",
    postOffice: "Bag Achra",
    postCode: "7433",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Sarsa",
    postOffice: "Benapole",
    postCode: "7431",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Sarsa",
    postOffice: "Jadabpur",
    postCode: "7432",
  },
  {
    division_id: "4",
    district_id: "57",
    upazila: "Sarsa",
    postOffice: "Sarsa",
    postCode: "7430",
  },
  {
    division_id: "4",
    district_id: "58",
    upazila: "Harinakundu",
    postOffice: "Harinakundu",
    postCode: "7310",
  },
  {
    division_id: "4",
    district_id: "58",
    upazila: "Jinaidaha Sadar",
    postOffice: "Jinaidaha Cadet College",
    postCode: "7301",
  },
  {
    division_id: "4",
    district_id: "58",
    upazila: "Jinaidaha Sadar",
    postOffice: "Jinaidaha Sadar",
    postCode: "7300",
  },
  {
    division_id: "4",
    district_id: "58",
    upazila: "Kotchandpur",
    postOffice: "Kotchandpur",
    postCode: "7330",
  },
  {
    division_id: "4",
    district_id: "58",
    upazila: "Maheshpur",
    postOffice: "Maheshpur",
    postCode: "7340",
  },
  {
    division_id: "4",
    district_id: "58",
    upazila: "Naldanga",
    postOffice: "Hatbar Bazar",
    postCode: "7351",
  },
  {
    division_id: "4",
    district_id: "58",
    upazila: "Naldanga",
    postOffice: "Naldanga",
    postCode: "7350",
  },
  {
    division_id: "4",
    district_id: "58",
    upazila: "Shailakupa",
    postOffice: "Kumiradaha",
    postCode: "7321",
  },
  {
    division_id: "4",
    district_id: "58",
    upazila: "Shailakupa",
    postOffice: "Shailakupa",
    postCode: "7320",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Alaipur",
    postOffice: "Alaipur",
    postCode: "9240",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Alaipur",
    postOffice: "Belphulia",
    postCode: "9242",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Alaipur",
    postOffice: "Rupsha",
    postCode: "9241",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Batiaghat",
    postOffice: "Batiaghat",
    postCode: "9260",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Batiaghat",
    postOffice: "Surkalee",
    postCode: "9261",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Chalna Bazar",
    postOffice: "Bajua",
    postCode: "9272",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Chalna Bazar",
    postOffice: "Chalna Bazar",
    postCode: "9270",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Chalna Bazar",
    postOffice: "Dakup",
    postCode: "9271",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Chalna Bazar",
    postOffice: "Nalian",
    postCode: "9273",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Digalia",
    postOffice: "Chandni Mahal",
    postCode: "9221",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Digalia",
    postOffice: "Digalia",
    postCode: "9220",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Digalia",
    postOffice: "Gazirhat",
    postCode: "9224",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Digalia",
    postOffice: "Ghoshghati",
    postCode: "9223",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Digalia",
    postOffice: "Senhati",
    postCode: "9222",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Khulna Sadar",
    postOffice: "Atra Shilpa Area",
    postCode: "9207",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Khulna Sadar",
    postOffice: "BIT Khulna",
    postCode: "9203",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Khulna Sadar",
    postOffice: "Doulatpur",
    postCode: "9202",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Khulna Sadar",
    postOffice: "Jahanabad Canttonmen",
    postCode: "9205",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Khulna Sadar",
    postOffice: "Khula Sadar",
    postCode: "9100",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Khulna Sadar",
    postOffice: "Khulna G.P.O",
    postCode: "9000",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Khulna Sadar",
    postOffice: "Khulna Shipyard",
    postCode: "9201",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Khulna Sadar",
    postOffice: "Khulna University",
    postCode: "9208",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Khulna Sadar",
    postOffice: "Siramani",
    postCode: "9204",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Khulna Sadar",
    postOffice: "Sonali Jute Mills",
    postCode: "9206",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Madinabad",
    postOffice: "Amadee",
    postCode: "9291",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Madinabad",
    postOffice: "Madinabad",
    postCode: "9290",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Paikgachha",
    postOffice: "Chandkhali",
    postCode: "9284",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Paikgachha",
    postOffice: "Garaikhali",
    postCode: "9285",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Paikgachha",
    postOffice: "Godaipur",
    postCode: "9281",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Paikgachha",
    postOffice: "Kapilmoni",
    postCode: "9282",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Paikgachha",
    postOffice: "Katipara",
    postCode: "9283",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Paikgachha",
    postOffice: "Paikgachha",
    postCode: "9280",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Phultala",
    postOffice: "Phultala",
    postCode: "9210",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Sajiara",
    postOffice: "Chuknagar",
    postCode: "9252",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Sajiara",
    postOffice: "Ghonabanda",
    postCode: "9251",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Sajiara",
    postOffice: "Sajiara",
    postCode: "9250",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Sajiara",
    postOffice: "Shahapur",
    postCode: "9253",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Terakhada",
    postOffice: "Pak Barasat",
    postCode: "9231",
  },
  {
    division_id: "4",
    district_id: "59",
    upazila: "Terakhada",
    postOffice: "Terakhada",
    postCode: "9230",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Bheramara",
    postOffice: "Allardarga",
    postCode: "7042",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Bheramara",
    postOffice: "Bheramara",
    postCode: "7040",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Bheramara",
    postOffice: "Ganges Bheramara",
    postCode: "7041",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Janipur",
    postOffice: "Janipur",
    postCode: "7020",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Janipur",
    postOffice: "Khoksa",
    postCode: "7021",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Kumarkhali",
    postOffice: "Kumarkhali",
    postCode: "7010",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Kumarkhali",
    postOffice: "Panti",
    postCode: "7011",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Kustia Sadar",
    postOffice: "Islami University",
    postCode: "7003",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Kustia Sadar",
    postOffice: "Jagati",
    postCode: "7002",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Kustia Sadar",
    postOffice: "Kushtia Mohini",
    postCode: "7001",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Kustia Sadar",
    postOffice: "Kustia Sadar",
    postCode: "7000",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Mirpur",
    postOffice: "Amla Sadarpur",
    postCode: "7032",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Mirpur",
    postOffice: "Mirpur",
    postCode: "7030",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Mirpur",
    postOffice: "Poradaha",
    postCode: "7031",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Rafayetpur",
    postOffice: "Khasmathurapur",
    postCode: "7052",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Rafayetpur",
    postOffice: "Rafayetpur",
    postCode: "7050",
  },
  {
    division_id: "4",
    district_id: "60",
    upazila: "Rafayetpur",
    postOffice: "Taragunia",
    postCode: "7051",
  },
  {
    division_id: "4",
    district_id: "61",
    upazila: "Arpara",
    postOffice: "Arpara",
    postCode: "7620",
  },
  {
    division_id: "4",
    district_id: "61",
    upazila: "Magura Sadar",
    postOffice: "Magura Sadar",
    postCode: "7600",
  },
  {
    division_id: "4",
    district_id: "61",
    upazila: "Mohammadpur",
    postOffice: "Binodpur",
    postCode: "7631",
  },
  {
    division_id: "4",
    district_id: "61",
    upazila: "Mohammadpur",
    postOffice: "Mohammadpur",
    postCode: "7630",
  },
  {
    division_id: "4",
    district_id: "61",
    upazila: "Mohammadpur",
    postOffice: "Nahata",
    postCode: "7632",
  },
  {
    division_id: "4",
    district_id: "61",
    upazila: "Shripur",
    postOffice: "Langalbadh",
    postCode: "7611",
  },
  {
    division_id: "4",
    district_id: "61",
    upazila: "Shripur",
    postOffice: "Nachol",
    postCode: "7612",
  },
  {
    division_id: "4",
    district_id: "61",
    upazila: "Shripur",
    postOffice: "Shripur",
    postCode: "7610",
  },
  {
    division_id: "4",
    district_id: "62",
    upazila: "Gangni",
    postOffice: "Gangni",
    postCode: "7110",
  },
  {
    division_id: "4",
    district_id: "62",
    upazila: "Meherpur Sadar",
    postOffice: "Amjhupi",
    postCode: "7101",
  },
  {
    division_id: "4",
    district_id: "62",
    upazila: "Meherpur Sadar",
    postOffice: "Amjhupi",
    postCode: "7152",
  },
  {
    division_id: "4",
    district_id: "62",
    upazila: "Meherpur Sadar",
    postOffice: "Meherpur Sadar",
    postCode: "7100",
  },
  {
    division_id: "4",
    district_id: "62",
    upazila: "Meherpur Sadar",
    postOffice: "Mujib Nagar Complex",
    postCode: "7102",
  },
  {
    division_id: "4",
    district_id: "63",
    upazila: "Kalia",
    postOffice: "Kalia",
    postCode: "7520",
  },
  {
    division_id: "4",
    district_id: "63",
    upazila: "Laxmipasha",
    postOffice: "Baradia",
    postCode: "7514",
  },
  {
    division_id: "4",
    district_id: "63",
    upazila: "Laxmipasha",
    postOffice: "Itna",
    postCode: "7512",
  },
  {
    division_id: "4",
    district_id: "63",
    upazila: "Laxmipasha",
    postOffice: "Laxmipasha",
    postCode: "7510",
  },
  {
    division_id: "4",
    district_id: "63",
    upazila: "Laxmipasha",
    postOffice: "Lohagora",
    postCode: "7511",
  },
  {
    division_id: "4",
    district_id: "63",
    upazila: "Laxmipasha",
    postOffice: "Naldi",
    postCode: "7513",
  },
  {
    division_id: "4",
    district_id: "63",
    upazila: "Mohajan",
    postOffice: "Mohajan",
    postCode: "7521",
  },
  {
    division_id: "4",
    district_id: "63",
    upazila: "Narail Sadar",
    postOffice: "Narail Sadar",
    postCode: "7500",
  },
  {
    division_id: "4",
    district_id: "63",
    upazila: "Narail Sadar",
    postOffice: "Ratanganj",
    postCode: "7501",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Ashashuni",
    postOffice: "Ashashuni",
    postCode: "9460",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Ashashuni",
    postOffice: "Baradal",
    postCode: "9461",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Debbhata",
    postOffice: "Debbhata",
    postCode: "9430",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Debbhata",
    postOffice: "Gurugram",
    postCode: "9431",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "kalaroa",
    postOffice: "Chandanpur",
    postCode: "9415",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "kalaroa",
    postOffice: "Hamidpur",
    postCode: "9413",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "kalaroa",
    postOffice: "Jhaudanga",
    postCode: "9412",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "kalaroa",
    postOffice: "kalaroa",
    postCode: "9410",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "kalaroa",
    postOffice: "Khordo",
    postCode: "9414",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "kalaroa",
    postOffice: "Murarikati",
    postCode: "9411",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Kaliganj UPO",
    postOffice: "Kaliganj UPO",
    postCode: "9440",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Kaliganj UPO",
    postOffice: "Nalta Mubaroknagar",
    postCode: "9441",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Kaliganj UPO",
    postOffice: "Ratanpur",
    postCode: "9442",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Nakipur",
    postOffice: "Buri Goalini",
    postCode: "9453",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Nakipur",
    postOffice: "Gabura",
    postCode: "9454",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Nakipur",
    postOffice: "Habinagar",
    postCode: "9455",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Nakipur",
    postOffice: "Nakipur",
    postCode: "9450",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Nakipur",
    postOffice: "Naobeki",
    postCode: "9452",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Nakipur",
    postOffice: "Noornagar",
    postCode: "9451",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Satkhira Sadar",
    postOffice: "Budhhat",
    postCode: "9403",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Satkhira Sadar",
    postOffice: "Gunakar kati",
    postCode: "9402",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Satkhira Sadar",
    postOffice: "Satkhira Islamia Acc",
    postCode: "9401",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Satkhira Sadar",
    postOffice: "Satkhira Sadar",
    postCode: "9400",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Taala",
    postOffice: "Patkelghata",
    postCode: "9421",
  },
  {
    division_id: "4",
    district_id: "64",
    upazila: "Taala",
    postOffice: "Taala",
    postCode: "9420",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Alamdighi",
    postOffice: "Adamdighi",
    postCode: "5890",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Alamdighi",
    postOffice: "Nasharatpur",
    postCode: "5892",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Alamdighi",
    postOffice: "Santahar",
    postCode: "5891",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Bogra Sadar",
    postOffice: "Bogra Canttonment",
    postCode: "5801",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Bogra Sadar",
    postOffice: "Bogra Sadar",
    postCode: "5800",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Dhunat",
    postOffice: "Dhunat",
    postCode: "5850",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Dhunat",
    postOffice: "Gosaibari",
    postCode: "5851",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Dupchachia",
    postOffice: "Dupchachia",
    postCode: "5880",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Dupchachia",
    postOffice: "Talora",
    postCode: "5881",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Gabtoli",
    postOffice: "Gabtoli",
    postCode: "5820",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Gabtoli",
    postOffice: "Sukhanpukur",
    postCode: "5821",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Kahalu",
    postOffice: "Kahalu",
    postCode: "5870",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Nandigram",
    postOffice: "Nandigram",
    postCode: "5860",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Sariakandi",
    postOffice: "Chandan Baisha",
    postCode: "5831",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Sariakandi",
    postOffice: "Sariakandi",
    postCode: "5830",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Sherpur",
    postOffice: "Chandaikona",
    postCode: "5841",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Sherpur",
    postOffice: "Palli Unnyan Accadem",
    postCode: "5842",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Sherpur",
    postOffice: "Sherpur",
    postCode: "5840",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Shibganj",
    postOffice: "Shibganj",
    postCode: "5810",
  },
  {
    division_id: "5",
    district_id: "18",
    upazila: "Sonatola",
    postOffice: "Sonatola",
    postCode: "5826",
  },
  {
    division_id: "5",
    district: "Chapinawabganj",
    upazila: "Bholahat",
    postOffice: "Bholahat",
    postCode: "6330",
  },
  {
    division_id: "5",
    district: "Chapinawabganj",
    upazila: "Chapinawabganj Sadar",
    postOffice: "Amnura",
    postCode: "6303",
  },
  {
    division_id: "5",
    district: "Chapinawabganj",
    upazila: "Chapinawabganj Sadar",
    postOffice: "Chapinawbganj Sadar",
    postCode: "6300",
  },
  {
    division_id: "5",
    district: "Chapinawabganj",
    upazila: "Chapinawabganj Sadar",
    postOffice: "Rajarampur",
    postCode: "6301",
  },
  {
    division_id: "5",
    district: "Chapinawabganj",
    upazila: "Chapinawabganj Sadar",
    postOffice: "Ramchandrapur",
    postCode: "6302",
  },
  {
    division_id: "5",
    district: "Chapinawabganj",
    upazila: "Nachol",
    postOffice: "Mandumala",
    postCode: "6311",
  },
  {
    division_id: "5",
    district: "Chapinawabganj",
    upazila: "Nachol",
    postOffice: "Nachol",
    postCode: "6310",
  },
  {
    division_id: "5",
    district: "Chapinawabganj",
    upazila: "Rohanpur",
    postOffice: "Gomashtapur",
    postCode: "6321",
  },
  {
    division_id: "5",
    district: "Chapinawabganj",
    upazila: "Rohanpur",
    postOffice: "Rohanpur",
    postCode: "6320",
  },
  {
    division_id: "5",
    district: "Chapinawabganj",
    upazila: "Shibganj U.P.O",
    postOffice: "Kansart",
    postCode: "6341",
  },
  {
    division_id: "5",
    district: "Chapinawabganj",
    upazila: "Shibganj U.P.O",
    postOffice: "Manaksha",
    postCode: "6342",
  },
  {
    division_id: "5",
    district: "Chapinawabganj",
    upazila: "Shibganj U.P.O",
    postOffice: "Shibganj U.P.O",
    postCode: "6340",
  },
  {
    division_id: "5",
    district_id: "19",
    upazila: "Akkelpur",
    postOffice: "Akklepur",
    postCode: "5940",
  },
  {
    division_id: "5",
    district_id: "19",
    upazila: "Akkelpur",
    postOffice: "jamalganj",
    postCode: "5941",
  },
  {
    division_id: "5",
    district_id: "19",
    upazila: "Akkelpur",
    postOffice: "Tilakpur",
    postCode: "5942",
  },
  {
    division_id: "5",
    district_id: "19",
    upazila: "Joypurhat Sadar",
    postOffice: "Joypurhat Sadar",
    postCode: "5900",
  },
  {
    division_id: "5",
    district_id: "19",
    upazila: "kalai",
    postOffice: "kalai",
    postCode: "5930",
  },
  {
    division_id: "5",
    district_id: "19",
    upazila: "Khetlal",
    postOffice: "Khetlal",
    postCode: "5920",
  },
  {
    division_id: "5",
    district_id: "19",
    upazila: "panchbibi",
    postOffice: "Panchbibi",
    postCode: "5910",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Ahsanganj",
    postOffice: "Ahsanganj",
    postCode: "6596",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Ahsanganj",
    postOffice: "Bandai",
    postCode: "6597",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Badalgachhi",
    postOffice: "Badalgachhi",
    postCode: "6570",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Dhamuirhat",
    postOffice: "Dhamuirhat",
    postCode: "6580",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Mahadebpur",
    postOffice: "Mahadebpur",
    postCode: "6530",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Naogaon Sadar",
    postOffice: "Naogaon Sadar",
    postCode: "6500",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Niamatpur",
    postOffice: "Niamatpur",
    postCode: "6520",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Nitpur",
    postOffice: "Nitpur",
    postCode: "6550",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Nitpur",
    postOffice: "Panguria",
    postCode: "6552",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Nitpur",
    postOffice: "Porsa",
    postCode: "6551",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Patnitala",
    postOffice: "Patnitala",
    postCode: "6540",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Prasadpur",
    postOffice: "Balihar",
    postCode: "6512",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Prasadpur",
    postOffice: "Manda",
    postCode: "6511",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Prasadpur",
    postOffice: "Prasadpur",
    postCode: "6510",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Raninagar",
    postOffice: "Kashimpur",
    postCode: "6591",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Raninagar",
    postOffice: "Raninagar",
    postCode: "6590",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Sapahar",
    postOffice: "Moduhil",
    postCode: "6561",
  },
  {
    division_id: "5",
    district_id: "20",
    upazila: "Sapahar",
    postOffice: "Sapahar",
    postCode: "6560",
  },
  {
    division_id: "5",
    district_id: "21",
    upazila: "Gopalpur UPO",
    postOffice: "Abdulpur",
    postCode: "6422",
  },
  {
    division_id: "5",
    district_id: "21",
    upazila: "Gopalpur UPO",
    postOffice: "Gopalpur U.P.O",
    postCode: "6420",
  },
  {
    division_id: "5",
    district_id: "21",
    upazila: "Gopalpur UPO",
    postOffice: "Lalpur S.O",
    postCode: "6421",
  },
  {
    division_id: "5",
    district_id: "21",
    upazila: "Harua",
    postOffice: "Baraigram",
    postCode: "6432",
  },
  {
    division_id: "5",
    district_id: "21",
    upazila: "Harua",
    postOffice: "Dayarampur",
    postCode: "6431",
  },
  {
    division_id: "5",
    district_id: "21",
    upazila: "Harua",
    postOffice: "Harua",
    postCode: "6430",
  },
  {
    division_id: "5",
    district_id: "21",
    upazila: "Hatgurudaspur",
    postOffice: "Hatgurudaspur",
    postCode: "6440",
  },
  {
    division_id: "5",
    district_id: "21",
    upazila: "Laxman",
    postOffice: "Laxman",
    postCode: "6410",
  },
  {
    division_id: "5",
    district_id: "21",
    upazila: "Natore Sadar",
    postOffice: "Baiddyabal Gharia",
    postCode: "6402",
  },
  {
    division_id: "5",
    district_id: "21",
    upazila: "Natore Sadar",
    postOffice: "Digapatia",
    postCode: "6401",
  },
  {
    division_id: "5",
    district_id: "21",
    upazila: "Natore Sadar",
    postOffice: "Madhnagar",
    postCode: "6403",
  },
  {
    division_id: "5",
    district_id: "21",
    upazila: "Natore Sadar",
    postOffice: "Natore Sadar",
    postCode: "6400",
  },
  {
    division_id: "5",
    district_id: "21",
    upazila: "Singra",
    postOffice: "Singra",
    postCode: "6450",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Banwarinagar",
    postOffice: "Banwarinagar",
    postCode: "6650",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Bera",
    postOffice: "Bera",
    postCode: "6680",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Bera",
    postOffice: "Kashinathpur",
    postCode: "6682",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Bera",
    postOffice: "Nakalia",
    postCode: "6681",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Bera",
    postOffice: "Puran Bharenga",
    postCode: "6683",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Bhangura",
    postOffice: "Bhangura",
    postCode: "6640",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Chatmohar",
    postOffice: "Chatmohar",
    postCode: "6630",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Debottar",
    postOffice: "Debottar",
    postCode: "6610",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Ishwardi",
    postOffice: "Dhapari",
    postCode: "6621",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Ishwardi",
    postOffice: "Ishwardi",
    postCode: "6620",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Ishwardi",
    postOffice: "Pakshi",
    postCode: "6622",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Ishwardi",
    postOffice: "Rajapur",
    postCode: "6623",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Pabna Sadar",
    postOffice: "Hamayetpur",
    postCode: "6602",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Pabna Sadar",
    postOffice: "Kaliko Cotton Mills",
    postCode: "6601",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Pabna Sadar",
    postOffice: "Pabna Sadar",
    postCode: "6600",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Sathia",
    postOffice: "Sathia",
    postCode: "6670",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Sujanagar",
    postOffice: "Sagarkandi",
    postCode: "6661",
  },
  {
    division_id: "5",
    district_id: "22",
    upazila: "Sujanagar",
    postOffice: "Sujanagar",
    postCode: "6660",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Bagha",
    postOffice: "Arani",
    postCode: "6281",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Bagha",
    postOffice: "Bagha",
    postCode: "6280",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Bhabaniganj",
    postOffice: "Bhabaniganj",
    postCode: "6250",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Bhabaniganj",
    postOffice: "Taharpur",
    postCode: "6251",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Charghat",
    postOffice: "Charghat",
    postCode: "6270",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Charghat",
    postOffice: "Sarda",
    postCode: "6271",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Durgapur",
    postOffice: "Durgapur",
    postCode: "6240",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Godagari",
    postOffice: "Godagari",
    postCode: "6290",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Godagari",
    postOffice: "Premtoli",
    postCode: "6291",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Khod Mohanpur",
    postOffice: "Khodmohanpur",
    postCode: "6220",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Lalitganj",
    postOffice: "Lalitganj",
    postCode: "6210",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Lalitganj",
    postOffice: "Rajshahi Sugar Mills",
    postCode: "6211",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Lalitganj",
    postOffice: "Shyampur",
    postCode: "6212",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Putia",
    postOffice: "Putia",
    postCode: "6260",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Rajshahi Sadar",
    postOffice: "Binodpur Bazar",
    postCode: "6206",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Rajshahi Sadar",
    postOffice: "Ghuramara",
    postCode: "6100",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Rajshahi Sadar",
    postOffice: "Kazla",
    postCode: "6204",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Rajshahi Sadar",
    postOffice: "Rajshahi Canttonment",
    postCode: "6202",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Rajshahi Sadar",
    postOffice: "Rajshahi Court",
    postCode: "6201",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Rajshahi Sadar",
    postOffice: "Rajshahi Sadar",
    postCode: "6000",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Rajshahi Sadar",
    postOffice: "Rajshahi University",
    postCode: "6205",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Rajshahi Sadar",
    postOffice: "Sapura",
    postCode: "6203",
  },
  {
    division_id: "5",
    district_id: "24",
    upazila: "Tanor",
    postOffice: "Tanor",
    postCode: "6230",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Baiddya Jam Toil",
    postOffice: "Baiddya Jam Toil",
    postCode: "6730",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Belkuchi",
    postOffice: "Belkuchi",
    postCode: "6740",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Belkuchi",
    postOffice: "Enayetpur",
    postCode: "6751",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Belkuchi",
    postOffice: "Rajapur",
    postCode: "6742",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Belkuchi",
    postOffice: "Sohagpur",
    postCode: "6741",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Belkuchi",
    postOffice: "Sthal",
    postCode: "6752",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Dhangora",
    postOffice: "Dhangora",
    postCode: "6720",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Dhangora",
    postOffice: "Malonga",
    postCode: "6721",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Kazipur",
    postOffice: "Gandail",
    postCode: "6712",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Kazipur",
    postOffice: "Kazipur",
    postCode: "6710",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Kazipur",
    postOffice: "Shuvgachha",
    postCode: "6711",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Shahjadpur",
    postOffice: "Jamirta",
    postCode: "6772",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Shahjadpur",
    postOffice: "Kaijuri",
    postCode: "6773",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Shahjadpur",
    postOffice: "Porjana",
    postCode: "6771",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Shahjadpur",
    postOffice: "Shahjadpur",
    postCode: "6770",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Sirajganj Sadar",
    postOffice: "Raipur",
    postCode: "6701",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Sirajganj Sadar",
    postOffice: "Rashidabad",
    postCode: "6702",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Sirajganj Sadar",
    postOffice: "Sirajganj Sadar",
    postCode: "6700",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Tarash",
    postOffice: "Tarash",
    postCode: "6780",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Ullapara",
    postOffice: "Lahiri Mohanpur",
    postCode: "6762",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Ullapara",
    postOffice: "Salap",
    postCode: "6763",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Ullapara",
    postOffice: "Ullapara",
    postCode: "6760",
  },
  {
    division_id: "5",
    district_id: "25",
    upazila: "Ullapara",
    postOffice: "Ullapara R.S",
    postCode: "6761",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Bangla Hili",
    postOffice: "Bangla Hili",
    postCode: "5270",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Biral",
    postOffice: "Biral",
    postCode: "5210",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Birampur",
    postOffice: "Birampur",
    postCode: "5266",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Birganj",
    postOffice: "Birganj",
    postCode: "5220",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Chrirbandar",
    postOffice: "Chrirbandar",
    postCode: "5240",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Chrirbandar",
    postOffice: "Ranirbandar",
    postCode: "5241",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Dinajpur Sadar",
    postOffice: "Dinajpur Rajbari",
    postCode: "5201",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Dinajpur Sadar",
    postOffice: "Dinajpur Sadar",
    postCode: "5200",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Khansama",
    postOffice: "Khansama",
    postCode: "5230",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Khansama",
    postOffice: "Pakarhat",
    postCode: "5231",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Maharajganj",
    postOffice: "Maharajganj",
    postCode: "5226",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Nababganj",
    postOffice: "Daudpur",
    postCode: "5281",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Nababganj",
    postOffice: "Gopalpur",
    postCode: "5282",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Nababganj",
    postOffice: "Nababganj",
    postCode: "5280",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Osmanpur",
    postOffice: "Ghoraghat",
    postCode: "5291",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Osmanpur",
    postOffice: "Osmanpur",
    postCode: "5290",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Parbatipur",
    postOffice: "Parbatipur",
    postCode: "5250",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Phulbari",
    postOffice: "Phulbari",
    postCode: "5260",
  },
  {
    division_id: "6",
    district_id: "26",
    upazila: "Setabganj",
    postOffice: "Setabganj",
    postCode: "5216",
  },
  {
    division_id: "6",
    district_id: "27",
    upazila: "Bonarpara",
    postOffice: "Bonarpara",
    postCode: "5750",
  },
  {
    division_id: "6",
    district_id: "27",
    upazila: "Bonarpara",
    postOffice: "saghata",
    postCode: "5751",
  },
  {
    division_id: "6",
    district_id: "27",
    upazila: "Gaibandha Sadar",
    postOffice: "Gaibandha Sadar",
    postCode: "5700",
  },
  {
    division_id: "6",
    district_id: "27",
    upazila: "Gobindaganj",
    postOffice: "Gobindhaganj",
    postCode: "5740",
  },
  {
    division_id: "6",
    district_id: "27",
    upazila: "Gobindaganj",
    postOffice: "Mahimaganj",
    postCode: "5741",
  },
  {
    division_id: "6",
    district_id: "27",
    upazila: "Palashbari",
    postOffice: "Palashbari",
    postCode: "5730",
  },
  {
    division_id: "6",
    district_id: "27",
    upazila: "Phulchhari",
    postOffice: "Bharatkhali",
    postCode: "5761",
  },
  {
    division_id: "6",
    district_id: "27",
    upazila: "Phulchhari",
    postOffice: "Phulchhari",
    postCode: "5760",
  },
  {
    division_id: "6",
    district_id: "27",
    upazila: "Saadullapur",
    postOffice: "Naldanga",
    postCode: "5711",
  },
  {
    division_id: "6",
    district_id: "27",
    upazila: "Saadullapur",
    postOffice: "Saadullapur",
    postCode: "5710",
  },
  {
    division_id: "6",
    district_id: "27",
    upazila: "Sundarganj",
    postOffice: "Bamandanga",
    postCode: "5721",
  },
  {
    division_id: "6",
    district_id: "27",
    upazila: "Sundarganj",
    postOffice: "Sundarganj",
    postCode: "5720",
  },
  {
    division_id: "6",
    district_id: "28",
    upazila: "Bhurungamari",
    postOffice: "Bhurungamari",
    postCode: "5670",
  },
  {
    division_id: "6",
    district_id: "28",
    upazila: "Chilmari",
    postOffice: "Chilmari",
    postCode: "5630",
  },
  {
    division_id: "6",
    district_id: "28",
    upazila: "Chilmari",
    postOffice: "Jorgachh",
    postCode: "5631",
  },
  {
    division_id: "6",
    district_id: "28",
    upazila: "Kurigram Sadar",
    postOffice: "Kurigram Sadar",
    postCode: "5600",
  },
  {
    division_id: "6",
    district_id: "28",
    upazila: "Kurigram Sadar",
    postOffice: "Pandul",
    postCode: "5601",
  },
  {
    division_id: "6",
    district_id: "28",
    upazila: "Kurigram Sadar",
    postOffice: "Phulbari",
    postCode: "5680",
  },
  {
    division_id: "6",
    district_id: "28",
    upazila: "Nageshwar",
    postOffice: "Nageshwar",
    postCode: "5660",
  },
  {
    division_id: "6",
    district_id: "28",
    upazila: "Rajarhat",
    postOffice: "Nazimkhan",
    postCode: "5611",
  },
  {
    division_id: "6",
    district_id: "28",
    upazila: "Rajarhat",
    postOffice: "Rajarhat",
    postCode: "5610",
  },
  {
    division_id: "6",
    district_id: "28",
    upazila: "Rajibpur",
    postOffice: "Rajibpur",
    postCode: "5650",
  },
  {
    division_id: "6",
    district_id: "28",
    upazila: "Roumari",
    postOffice: "Roumari",
    postCode: "5640",
  },
  {
    division_id: "6",
    district_id: "28",
    upazila: "Ulipur",
    postOffice: "Bazarhat",
    postCode: "5621",
  },
  {
    division_id: "6",
    district_id: "28",
    upazila: "Ulipur",
    postOffice: "Ulipur",
    postCode: "5620",
  },
  {
    division_id: "6",
    district_id: "29",
    upazila: "Aditmari",
    postOffice: "Aditmari",
    postCode: "5510",
  },
  {
    division_id: "6",
    district_id: "29",
    upazila: "Hatibandha",
    postOffice: "Hatibandha",
    postCode: "5530",
  },
  {
    division_id: "6",
    district_id: "29",
    upazila: "Lalmonirhat Sadar",
    postOffice: "Kulaghat SO",
    postCode: "5502",
  },
  {
    division_id: "6",
    district_id: "29",
    upazila: "Lalmonirhat Sadar",
    postOffice: "Lalmonirhat Sadar",
    postCode: "5500",
  },
  {
    division_id: "6",
    district_id: "29",
    upazila: "Lalmonirhat Sadar",
    postOffice: "Moghalhat",
    postCode: "5501",
  },
  {
    division_id: "6",
    district_id: "29",
    upazila: "Patgram",
    postOffice: "Baura",
    postCode: "5541",
  },
  {
    division_id: "6",
    district_id: "29",
    upazila: "Patgram",
    postOffice: "Burimari",
    postCode: "5542",
  },
  {
    division_id: "6",
    district_id: "29",
    upazila: "Patgram",
    postOffice: "Patgram",
    postCode: "5540",
  },
  {
    division_id: "6",
    district_id: "29",
    upazila: "Tushbhandar",
    postOffice: "Tushbhandar",
    postCode: "5520",
  },
  {
    division_id: "6",
    district_id: "30",
    upazila: "Dimla",
    postOffice: "Dimla",
    postCode: "5350",
  },
  {
    division_id: "6",
    district_id: "30",
    upazila: "Dimla",
    postOffice: "Ghaga Kharibari",
    postCode: "5351",
  },
  {
    division_id: "6",
    district_id: "30",
    upazila: "Domar",
    postOffice: "Chilahati",
    postCode: "5341",
  },
  {
    division_id: "6",
    district_id: "30",
    upazila: "Domar",
    postOffice: "Domar",
    postCode: "5340",
  },
  {
    division_id: "6",
    district_id: "30",
    upazila: "Jaldhaka",
    postOffice: "Jaldhaka",
    postCode: "5330",
  },
  {
    division_id: "6",
    district_id: "30",
    upazila: "Kishoriganj",
    postOffice: "Kishoriganj",
    postCode: "5320",
  },
  {
    division_id: "6",
    district_id: "30",
    upazila: "Nilphamari Sadar",
    postOffice: "Nilphamari Sadar",
    postCode: "5300",
  },
  {
    division_id: "6",
    district_id: "30",
    upazila: "Nilphamari Sadar",
    postOffice: "Nilphamari Sugar Mil",
    postCode: "5301",
  },
  {
    division_id: "6",
    district_id: "30",
    upazila: "Syedpur",
    postOffice: "Syedpur",
    postCode: "5310",
  },
  {
    division_id: "6",
    district_id: "30",
    upazila: "Syedpur",
    postOffice: "Syedpur Upashahar",
    postCode: "5311",
  },
  {
    division_id: "6",
    district_id: "31",
    upazila: "Boda",
    postOffice: "Boda",
    postCode: "5010",
  },
  {
    division_id: "6",
    district_id: "31",
    upazila: "Chotto Dab",
    postOffice: "Chotto Dab",
    postCode: "5040",
  },
  {
    division_id: "6",
    district_id: "31",
    upazila: "Chotto Dab",
    postOffice: "Mirjapur",
    postCode: "5041",
  },
  {
    division_id: "6",
    district_id: "31",
    upazila: "Dabiganj",
    postOffice: "Dabiganj",
    postCode: "5020",
  },
  {
    division_id: "6",
    district_id: "31",
    upazila: "Panchagarh Sadar",
    postOffice: "Panchagarh Sadar",
    postCode: "5000",
  },
  {
    division_id: "6",
    district_id: "31",
    upazila: "Tetulia",
    postOffice: "Tetulia",
    postCode: "5030",
  },
  {
    division_id: "6",
    district_id: "32",
    upazila: "Badarganj",
    postOffice: "Badarganj",
    postCode: "5430",
  },
  {
    division_id: "6",
    district_id: "32",
    upazila: "Badarganj",
    postOffice: "Shyampur",
    postCode: "5431",
  },
  {
    division_id: "6",
    district_id: "32",
    upazila: "Gangachara",
    postOffice: "Gangachara",
    postCode: "5410",
  },
  {
    division_id: "6",
    district_id: "32",
    upazila: "Kaunia",
    postOffice: "Haragachh",
    postCode: "5441",
  },
  {
    division_id: "6",
    district_id: "32",
    upazila: "Kaunia",
    postOffice: "Kaunia",
    postCode: "5440",
  },
  {
    division_id: "6",
    district_id: "32",
    upazila: "Mithapukur",
    postOffice: "Mithapukur",
    postCode: "5460",
  },
  {
    division_id: "6",
    district_id: "32",
    upazila: "Pirgachha",
    postOffice: "Pirgachha",
    postCode: "5450",
  },
  {
    division_id: "6",
    district_id: "32",
    upazila: "Rangpur Sadar",
    postOffice: "Alamnagar",
    postCode: "5402",
  },
  {
    division_id: "6",
    district_id: "32",
    upazila: "Rangpur Sadar",
    postOffice: "Mahiganj",
    postCode: "5403",
  },
  {
    division_id: "6",
    district_id: "32",
    upazila: "Rangpur Sadar",
    postOffice: "Rangpur Cadet Colleg",
    postCode: "5404",
  },
  {
    division_id: "6",
    district_id: "32",
    upazila: "Rangpur Sadar",
    postOffice: "Rangpur Carmiecal Col",
    postCode: "5405",
  },
  {
    division_id: "6",
    district_id: "32",
    upazila: "Rangpur Sadar",
    postOffice: "Rangpur Sadar",
    postCode: "5400",
  },
  {
    division_id: "6",
    district_id: "32",
    upazila: "Rangpur Sadar",
    postOffice: "Rangpur Upa-Shahar",
    postCode: "5401",
  },
  {
    division_id: "6",
    district_id: "32",
    upazila: "Taraganj",
    postOffice: "Taraganj",
    postCode: "5420",
  },
  {
    division_id: "6",
    district_id: "33",
    upazila: "Baliadangi",
    postOffice: "Baliadangi",
    postCode: "5140",
  },
  {
    division_id: "6",
    district_id: "33",
    upazila: "Baliadangi",
    postOffice: "Lahiri",
    postCode: "5141",
  },
  {
    division_id: "6",
    district_id: "33",
    upazila: "Jibanpur",
    postOffice: "Jibanpur",
    postCode: "5130",
  },
  {
    division_id: "6",
    district_id: "33",
    upazila: "Pirganj",
    postOffice: "Pirganj",
    postCode: "5110",
  },
  {
    division_id: "6",
    district_id: "33",
    upazila: "Pirganj",
    postOffice: "Pirganj",
    postCode: "5470",
  },
  {
    division_id: "6",
    district_id: "33",
    upazila: "Rani Sankail",
    postOffice: "Nekmarad",
    postCode: "5121",
  },
  {
    division_id: "6",
    district_id: "33",
    upazila: "Rani Sankail",
    postOffice: "Rani Sankail",
    postCode: "5120",
  },
  {
    division_id: "6",
    district_id: "33",
    upazila: "Thakurgaon Sadar",
    postOffice: "Ruhia",
    postCode: "5103",
  },
  {
    division_id: "6",
    district_id: "33",
    upazila: "Thakurgaon Sadar",
    postOffice: "Shibganj",
    postCode: "5102",
  },
  {
    division_id: "6",
    district_id: "33",
    upazila: "Thakurgaon Sadar",
    postOffice: "Thakurgaon Road",
    postCode: "5101",
  },
  {
    division_id: "6",
    district_id: "33",
    upazila: "Thakurgaon Sadar",
    postOffice: "Thakurgaon Sadar",
    postCode: "5100",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Azmireeganj",
    postOffice: "Azmireeganj",
    postCode: "3360",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Bahubal",
    postOffice: "Bahubal",
    postCode: "3310",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Baniachang",
    postOffice: "Baniachang",
    postCode: "3350",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Baniachang",
    postOffice: "Jatrapasha",
    postCode: "3351",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Baniachang",
    postOffice: "Kadirganj",
    postCode: "3352",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Chunarughat",
    postOffice: "Chandpurbagan",
    postCode: "3321",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Chunarughat",
    postOffice: "Chunarughat",
    postCode: "3320",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Chunarughat",
    postOffice: "Narapati",
    postCode: "3322",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Hobiganj Sadar",
    postOffice: "Gopaya",
    postCode: "3302",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Hobiganj Sadar",
    postOffice: "Hobiganj Sadar",
    postCode: "3300",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Hobiganj Sadar",
    postOffice: "Shaestaganj",
    postCode: "3301",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Kalauk",
    postOffice: "Kalauk",
    postCode: "3340",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Kalauk",
    postOffice: "Lakhai",
    postCode: "3341",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Madhabpur",
    postOffice: "Itakhola",
    postCode: "3331",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Madhabpur",
    postOffice: "Madhabpur",
    postCode: "3330",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Madhabpur",
    postOffice: "Saihamnagar",
    postCode: "3333",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Madhabpur",
    postOffice: "Shahajibazar",
    postCode: "3332",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Nabiganj",
    postOffice: "Digalbak",
    postCode: "3373",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Nabiganj",
    postOffice: "Golduba",
    postCode: "3372",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Nabiganj",
    postOffice: "Goplarbazar",
    postCode: "3371",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Nabiganj",
    postOffice: "Inathganj",
    postCode: "3374",
  },
  {
    division_id: "7",
    district_id: "51",
    upazila: "Nabiganj",
    postOffice: "Nabiganj",
    postCode: "3370",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Baralekha",
    postOffice: "Baralekha",
    postCode: "3250",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Baralekha",
    postOffice: "Dhakkhinbag",
    postCode: "3252",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Baralekha",
    postOffice: "Juri",
    postCode: "3251",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Baralekha",
    postOffice: "Purbashahabajpur",
    postCode: "3253",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Kamalganj",
    postOffice: "Kamalganj",
    postCode: "3220",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Kamalganj",
    postOffice: "Keramatnaga",
    postCode: "3221",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Kamalganj",
    postOffice: "Munshibazar",
    postCode: "3224",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Kamalganj",
    postOffice: "Patrakhola",
    postCode: "3222",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Kamalganj",
    postOffice: "Shamsher Nagar",
    postCode: "3223",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Kulaura",
    postOffice: "Baramchal",
    postCode: "3237",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Kulaura",
    postOffice: "Kajaldhara",
    postCode: "3234",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Kulaura",
    postOffice: "Karimpur",
    postCode: "3235",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Kulaura",
    postOffice: "Kulaura",
    postCode: "3230",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Kulaura",
    postOffice: "Langla",
    postCode: "3232",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Kulaura",
    postOffice: "Prithimpasha",
    postCode: "3233",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Kulaura",
    postOffice: "Tillagaon",
    postCode: "3231",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Moulvibazar Sadar",
    postOffice: "Afrozganj",
    postCode: "3203",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Moulvibazar Sadar",
    postOffice: "Barakapan",
    postCode: "3201",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Moulvibazar Sadar",
    postOffice: "Monumukh",
    postCode: "3202",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Moulvibazar Sadar",
    postOffice: "Moulvibazar Sadar",
    postCode: "3200",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Rajnagar",
    postOffice: "Rajnagar",
    postCode: "3240",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Srimangal",
    postOffice: "Kalighat",
    postCode: "3212",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Srimangal",
    postOffice: "Khejurichhara",
    postCode: "3213",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Srimangal",
    postOffice: "Narain Chora",
    postCode: "3211",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Srimangal",
    postOffice: "Satgaon",
    postCode: "3214",
  },
  {
    division_id: "7",
    district_id: "52",
    upazila: "Srimangal",
    postOffice: "Srimangal",
    postCode: "3210",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Bishamsarpur",
    postOffice: "Bishamsapur",
    postCode: "3010",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Chhatak",
    postOffice: "Chhatak",
    postCode: "3080",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Chhatak",
    postOffice: "Chhatak Cement Facto",
    postCode: "3081",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Chhatak",
    postOffice: "Chhatak Paper Mills",
    postCode: "3082",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Chhatak",
    postOffice: "Chourangi Bazar",
    postCode: "3893",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Chhatak",
    postOffice: "Gabindaganj",
    postCode: "3083",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Chhatak",
    postOffice: "Gabindaganj Natun Ba",
    postCode: "3084",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Chhatak",
    postOffice: "Islamabad",
    postCode: "3088",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Chhatak",
    postOffice: "jahidpur",
    postCode: "3087",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Chhatak",
    postOffice: "Khurma",
    postCode: "3085",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Chhatak",
    postOffice: "Moinpur",
    postCode: "3086",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Dhirai Chandpur",
    postOffice: "Dhirai Chandpur",
    postCode: "3040",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Dhirai Chandpur",
    postOffice: "Jagdal",
    postCode: "3041",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Duara bazar",
    postOffice: "Duara bazar",
    postCode: "3070",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Ghungiar",
    postOffice: "Ghungiar",
    postCode: "3050",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Jagnnathpur",
    postOffice: "Atuajan",
    postCode: "3062",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Jagnnathpur",
    postOffice: "Hasan Fatemapur",
    postCode: "3063",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Jagnnathpur",
    postOffice: "Jagnnathpur",
    postCode: "3060",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Jagnnathpur",
    postOffice: "Rasulganj",
    postCode: "3064",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Jagnnathpur",
    postOffice: "Shiramsi",
    postCode: "3065",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Jagnnathpur",
    postOffice: "Syedpur",
    postCode: "3061",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Sachna",
    postOffice: "Sachna",
    postCode: "3020",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Sunamganj Sadar",
    postOffice: "Pagla",
    postCode: "3001",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Sunamganj Sadar",
    postOffice: "Patharia",
    postCode: "3002",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Sunamganj Sadar",
    postOffice: "Sunamganj Sadar",
    postCode: "3000",
  },
  {
    division_id: "7",
    district_id: "53",
    upazila: "Tahirpur",
    postOffice: "Tahirpur",
    postCode: "3030",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Balaganj",
    postOffice: "Balaganj",
    postCode: "3120",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Balaganj",
    postOffice: "Begumpur",
    postCode: "3125",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Balaganj",
    postOffice: "Brahman Shashon",
    postCode: "3122",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Balaganj",
    postOffice: "Gaharpur",
    postCode: "3128",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Balaganj",
    postOffice: "Goala Bazar",
    postCode: "3124",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Balaganj",
    postOffice: "Karua",
    postCode: "3121",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Balaganj",
    postOffice: "Kathal Khair",
    postCode: "3127",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Balaganj",
    postOffice: "Natun Bazar",
    postCode: "3129",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Balaganj",
    postOffice: "Omarpur",
    postCode: "3126",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Balaganj",
    postOffice: "Tajpur",
    postCode: "3123",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Bianibazar",
    postOffice: "Bianibazar",
    postCode: "3170",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Bianibazar",
    postOffice: "Churkai",
    postCode: "3175",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Bianibazar",
    postOffice: "jaldup",
    postCode: "3171",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Bianibazar",
    postOffice: "Kurar bazar",
    postCode: "3173",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Bianibazar",
    postOffice: "Mathiura",
    postCode: "3172",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Bianibazar",
    postOffice: "Salia bazar",
    postCode: "3174",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Bishwanath",
    postOffice: "Bishwanath",
    postCode: "3130",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Bishwanath",
    postOffice: "Dashghar",
    postCode: "3131",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Bishwanath",
    postOffice: "Deokalas",
    postCode: "3133",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Bishwanath",
    postOffice: "Doulathpur",
    postCode: "3132",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Bishwanath",
    postOffice: "Singer kanch",
    postCode: "3134",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Fenchuganj",
    postOffice: "Fenchuganj",
    postCode: "3116",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Fenchuganj",
    postOffice: "Fenchuganj SareKarkh",
    postCode: "3117",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Goainhat",
    postOffice: "Chiknagul",
    postCode: "3152",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Goainhat",
    postOffice: "Goainhat",
    postCode: "3150",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Goainhat",
    postOffice: "Jaflong",
    postCode: "3151",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Gopalganj",
    postOffice: "banigram",
    postCode: "3164",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Gopalganj",
    postOffice: "Chandanpur",
    postCode: "3165",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Gopalganj",
    postOffice: "Dakkhin Bhadashore",
    postCode: "3162",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Gopalganj",
    postOffice: "Dhaka Dakkhin",
    postCode: "3161",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Gopalganj",
    postOffice: "Gopalgannj",
    postCode: "3160",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Gopalganj",
    postOffice: "Ranaping",
    postCode: "3163",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Jaintapur",
    postOffice: "Jainthapur",
    postCode: "3156",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Jakiganj",
    postOffice: "Ichhamati",
    postCode: "3191",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Jakiganj",
    postOffice: "Jakiganj",
    postCode: "3190",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Kanaighat",
    postOffice: "Chatulbazar",
    postCode: "3181",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Kanaighat",
    postOffice: "Gachbari",
    postCode: "3183",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Kanaighat",
    postOffice: "Kanaighat",
    postCode: "3180",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Kanaighat",
    postOffice: "Manikganj",
    postCode: "3182",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Kompanyganj",
    postOffice: "Kompanyganj",
    postCode: "3140",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Sylhet Sadar",
    postOffice: "Birahimpur",
    postCode: "3106",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Sylhet Sadar",
    postOffice: "Jalalabad",
    postCode: "3107",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Sylhet Sadar",
    postOffice: "Jalalabad Cantoment",
    postCode: "3104",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Sylhet Sadar",
    postOffice: "Kadamtali",
    postCode: "3111",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Sylhet Sadar",
    postOffice: "Kamalbazer",
    postCode: "3112",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Sylhet Sadar",
    postOffice: "Khadimnagar",
    postCode: "3103",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Sylhet Sadar",
    postOffice: "Lalbazar",
    postCode: "3113",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Sylhet Sadar",
    postOffice: "Mogla",
    postCode: "3108",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Sylhet Sadar",
    postOffice: "Ranga Hajiganj",
    postCode: "3109",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Sylhet Sadar",
    postOffice: "Shahajalal Science &",
    postCode: "3114",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Sylhet Sadar",
    postOffice: "Silam",
    postCode: "3105",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Sylhet Sadar",
    postOffice: "Sylhe Sadar",
    postCode: "3100",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Sylhet Sadar",
    postOffice: "Sylhet Biman Bondar",
    postCode: "3102",
  },
  {
    division_id: "7",
    district_id: "54",
    upazila: "Sylhet Sadar",
    postOffice: "Sylhet Cadet Col",
    postCode: "3101",
  },
];
