
import { IoMdAddCircle } from "react-icons/io";


import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { Autocomplete, TextField } from "@mui/material";
export default function AddInstituteStudents({
  onChangeClasses,
  errorsClassInfo,
  formClassInfo,
  setformClassInfo,
  setErrorsClassInfo
}) {

  const addClass = ()=>{
    setformClassInfo([...formClassInfo,{
    className:"",
    gender:"",
    totalStudent:0,
   }])
   setErrorsClassInfo([...errorsClassInfo,{
    className:null,
    gender:null,
    totalStudent:null,
   }])
  }

  const removeClass = (index)=>{
    setformClassInfo(formClassInfo.filter(item => formClassInfo.indexOf(item) !== index));
    setErrorsClassInfo(errorsClassInfo.filter(item => errorsClassInfo.indexOf(item) !== index));

  }



  return (

    <> 
      {formClassInfo.map((cls, index) => {
          
      return (
        <div key={index}>
          <div className=" mr-5 grid sm:grid-cols-12 items-center justify-center grid-cols-1  gap-2 pb-9 relative ">
            <>
              <TextField
                error={errorsClassInfo[index].className ? true : false}
                helperText={errorsClassInfo[index].className}
                className="col-span-4"
                id="class"
                label={cls.className.length === 0 ? "Class *" : "Class "}
                variant="outlined"
                fullWidth
                size="small"
                value={cls.className || null}
                onChange={(e) =>
                  onChangeClasses({
                    name: "className",
                    value: e.target.value,
                    index: index,
                  })
                }
              />

              <Autocomplete
                className="col-span-4"
                disablePortal
                id="gender"
                options={["Only Male", "Only Female", "Both"]}
                fullWidth
                size="small"
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                value={cls.gender || ""}
                onChange={(event, newValue) => {
                  onChangeClasses({
                    name: "gender",
                    value: newValue,
                    index: index,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    error={errorsClassInfo[index].gender ? true : false}
                    helperText={errorsClassInfo[index].gender}
                    {...params}
                    label={
                      cls.gender === null || cls.gender === ""
                        ? "Gender *"
                        : "Gender"
                    }
                  />
                )}
              />
              <TextField
                error={errorsClassInfo[index].totalStudent ? true : false}
                helperText={errorsClassInfo[index].totalStudent}
                className="col-span-3"
                id="no.ofStudents"
                label="No. of Students"
                variant="outlined"
                fullWidth
                type="number"
                value={cls.totalStudent || null}
                onChange={(e) => {
                  onChangeClasses({
                    name: "totalStudent",
                    value: e.target.value,
                    index: index,
                  });
                }}
                size="small"
              />
            </>

            <div className=" flex items-center  ">
              <DeleteOutlineIcon
                onClick={() => removeClass(index)}
                className="cursor-pointer text-red-600 hover:text-red-700 text-2xl"
              />
            </div>
          </div>{" "}
        </div>
      );
    })}
    
           
          
        {" "}
        <div className="flex items-center ">
          <h3
            onClick={addClass}
            className="flex items-center font-bold text-base cursor-pointer gap-2 group group-hover:text-green-400 uppercase"
          >
            <span className="">
              <IoMdAddCircle className=" text-2xl cursor-pointer hover:scale-105 text-green-500 group-hover:text-green-600 transition-all duration-200 ease-in-out group-hover:animate-spin" />{" "}
            </span>
            Add Class Fields
          </h3>
        </div>
        
        </>
  );
}
