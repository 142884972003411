import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AppCardContainer from "../../../ui/AppCardContainer";
import AppSubNav from "../../../ui/AppSubNav";
import AppintmentInfo from "./Details/AppintmentInfo";
import Documents from "./Details/Documents";
import GeneralInfo from "./Details/GeneralInfo";
import PatientInfo from "./Details/PatientInfo";
import { tableConvertedData } from "./helper/convertedAppointmentData";
import { pdfTypes } from "../../../../constants/ApiConstant/databaseConstant";
import { BASE_URL, endPoint } from "../../../../constants/ApiConstant/routeConstant";
import { useSelector } from "../../../../redux/store";
import AppoinmentsLink from "./Details/AppoinmentsLink";
import PrescriptionContainer from "./Details/PrescriptionContainer";




const AppointmentsDetails = () => {
  const { id } = useParams();
  const { token } = useSelector((state) => state.userInfo);
  const [singleAppointment, setSingleAppointment] = useState({});
  const [selectedNav, setSelectedNav] = useState();
  const getSingleAppointmentData = async () => {
    const header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .get(
        `${BASE_URL}${endPoint.appointmentGet}/${id}`,
        header,
      )
      .then((res) => {
        if (res?.data?.message) {
          setSingleAppointment(tableConvertedData([res?.data?.data])[0]);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  useEffect(() => {
    getSingleAppointmentData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppCardContainer spacing="3xl:-mr-10 mx-5 mt-20 xl:mx-0 xl:mt-10">
      <div className="flex items-center justify-between">
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <Link to="/telemedicine/appointments/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="w-4 h-4 mr-2 stroke-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                  ></path>
                </svg>
                All Appointments
              </Link>
            </li>
            <li>
              <a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="w-4 h-4 mr-2 stroke-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                  ></path>
                </svg>
                <span className=" font-bold">Appointment Details</span>
              </a>
            </li>
          </ul>
        </div>
        <Link
            to={`/pdfview/${pdfTypes.appointment}/${id}`}
            target="_blank"
            rel="noopener noreferrer"
          >

          <SimCardDownloadIcon
              style={{ color: "red", cursor: "pointer" }}
              fontSize="large"
            />
          
          </Link>
      </div>

      <div className="mt-10">
        <AppSubNav
          navbarData={[
            "General Information",
            "Appointment Info",
            "Patient Info",
            "Documents",
            "Prescription",
            "Appoinments Link",
          ]}
          callback={(item) => setSelectedNav(item)}
          type="tab"
          width="w-44"
        />
      </div>

      {selectedNav?.key === "GeneralInformation" && (
        <GeneralInfo singleAppointment={singleAppointment} />
      )}
      {selectedNav?.key === "AppointmentInfo" && (
        <AppintmentInfo singleAppointment={singleAppointment} />
      )}
      {selectedNav?.key === "PatientInfo" && (
        <PatientInfo singleAppointment={singleAppointment} />
      )}
      {selectedNav?.key === "Documents" && (
        <Documents singleAppointment={singleAppointment} />
      )}
      {selectedNav?.key === "Prescription" && <PrescriptionContainer id={id} />}
      {selectedNav?.key === "AppoinmentsLink" && (
        <AppoinmentsLink
          singleAppointment={singleAppointment}
          getSingleAppointmentData={getSingleAppointmentData}
        />
      )}
    </AppCardContainer>
  );
};

export default AppointmentsDetails;
