import { endPoint } from "../../../../constants/ApiConstant/routeConstant";
import AppBadge from "../../AppBadge";
import AppTableActionButton from "../../AppTableActionButton";

export const td = (
  dataTitle,
  key,
  component,
  index,
  customClass = "text-sm font-semibold py-4 px-8"
) => (
  <td
    data-title={dataTitle}
    key={key}
    className={`${customClass} ${
      index % 2 === 0 ? " bg-white" : "bg-blue-50"
    } `}
  >
    {component}
  </td>
);

export const DynamicTableDataForClasses = (
  colItem,
  headItem,
  index,
  shadow
) => {
  if (headItem.key === "className") {
    return td(
      "Class Name",
      headItem.id,
      <div className="ml-8">{colItem[headItem.key]}</div>,
      index,
      `sticky left-0 z-30 before:absolute ${shadow ? "drop-shadow-2xl" : ""}`
    );
  }

  return td(
    headItem.title,
    headItem.id,
    <div>{colItem[headItem.key]}</div>,
    index
  );
};

export const DynamicTableDataForStudents = (
  colItem,
  headItem,
  index,
  shadow
) => {
  if (headItem.key === "rollNo") {
    return td(
      "Roll No.",
      headItem.id,
      <div className="ml-8">{colItem[headItem.key]}</div>,
      index,
      `sticky left-0 z-30 before:absolute ${shadow ? "drop-shadow-2xl" : ""}`
    );
  }

  return td(
    headItem.title,
    headItem.id,
    <div>{colItem[headItem.key]}</div>,
    index
  );
};

export const DynamicTableDataForPatients = (
  colItem,
  headItem,
  index,
  shadow
) => {
  if (headItem.key === "pId") {
    return td(
      "Patient ID",
      headItem.id,
      <div className="ml-8">{colItem[headItem.key]}</div>,
      index,
      `sticky left-0 z-30 before:absolute ${shadow ? "drop-shadow-2xl" : ""}`
    );
  }

  if (headItem.key === "action") {
    return td(
      "Action",
      headItem.id,
      <div className="">
        <AppTableActionButton
          viewLink={`/patients/details/${colItem?.id}`}
          isEdit={false}
          isDelete={false}
        />
      </div>,
      index
    );
  }

  return td(
    headItem.title,
    headItem.id,
    <div>{colItem[headItem.key]}</div>,
    index
  );
};

export const DynamicTableDataForAppointments = (
  colItem,
  headItem,
  index,
  shadow
) => {
  if (headItem.key === "apId") {
    return td(
      "Appointments ID",
      headItem.id,
      <div className="ml-8">{colItem[headItem.key]}</div>,
      index,
      `sticky left-0 z-30 before:absolute ${shadow ? "drop-shadow-2xl" : ""}`
    );
  }
  if (headItem.key === "status") {
    return td(
      "Status",
      headItem.id,
      <div className="">
        <AppBadge type={colItem[headItem.key]} />
      </div>,
      index
    );
  }

  if (headItem.key === "action") {
    return td(
      "Action",
      headItem.id,
      <div className="">
        {/* {colItem[headItem.key]} */}
        <AppTableActionButton
          viewLink={`/appointments/details/${colItem?.apId}`}
          isEdit={false}
          isDelete={false}
        />
      </div>,
      index
    );
  }

  return td(
    headItem.title,
    headItem.id,
    <div>{colItem[headItem.key]}</div>,
    index
  );
};

export const DynamicTableDataForPrescription = (
  colItem,
  headItem,
  index,
  shadow
) => {
  if (headItem.key === "presId") {
    return td(
      "Prescription ID",
      headItem.id,
      <div className="ml-8">{colItem[headItem.key]}</div>,
      index,
      `sticky left-0 z-30 before:absolute ${shadow ? "drop-shadow-2xl" : ""}`
    );
  }

  if (headItem.key === "action") {
    return td(
      "Action",
      headItem.id,
      <div className="">
        <AppTableActionButton
          viewLink={`/prescription/details`}
          viewData={colItem}
          // isEdit={false}
          isDelete={false}
          editLink={`/prescription/details/edit/${colItem?.presId}`}
          // editLink
        />
      </div>,
      index
    );
  }

  return td(
    headItem.title,
    headItem.id,
    <div>{colItem[headItem.key]}</div>,
    index
  );
};

// School authority student

export const DynamicTableDataForSclAStudentsData = (
  colItem,
  headItem,
  index,
  shadow
) => {
  if (headItem.key === "sId") {
    return td(
      "Students ID",
      headItem.id,
      <div className="ml-8">{colItem[headItem.key]}</div>,
      index,
      `sticky left-0 z-30 before:absolute ${shadow ? "drop-shadow-2xl" : ""}`
    );
  }

  if (headItem.key === "action") {
    return td(
      "Action",
      headItem.id,
      <div className="">
        <AppTableActionButton
          viewLink={`/students/details/${colItem?.sId}`}
          isEdit={false}
          isDelete={false}
          // deleteFunction={() =>
          //   handleDelete(userTypes.schoolAuthority, colItem)
          // }
        />
      </div>,
      index
    );
  }

  return td(
    headItem.title,
    headItem.id,
    <div>{colItem[headItem.key]}</div>,
    index
  );
};

// superAdmin Institution
export const DynamicTableDataForHealthcareInst = (
  colItem,
  headItem,
  index,
  shadow,
  handleDelete
) => {
  if (headItem.key === "insId") {
    return td(
      "Institutions ID",
      headItem.id,
      <div className="ml-8">{colItem[headItem.key]}</div>,
      index,
      `sticky left-0 z-30 before:absolute ${shadow ? "drop-shadow-2xl" : ""}`
    );
  }

  if (headItem.key === "action") {
    return td(
      "Action",
      headItem.id,
      <div className="">
        <AppTableActionButton
          viewLink={`/healthcare/institutions/view/details/${colItem?.userId}`}
          editLink={`/healthcare/institutions/edit/${colItem?.userId}`}
          deleteFunction={() => handleDelete(colItem.userId, colItem.insId)}
        />
      </div>,
      index
    );
  }

  return td(
    headItem.title,
    headItem.id,
    <div>{colItem[headItem.key]}</div>,
    index
  );
};

// superAdmin Studdent

export const DynamicTableDataForSAStudentsData = (
  colItem,
  headItem,
  index,
  shadow
) => {
  if (headItem.key === "sId") {
    return td(
      "Students ID",
      headItem.id,
      <div className="ml-8">{colItem[headItem.key]}</div>,
      index,
      `sticky left-0 z-30 before:absolute ${shadow ? "drop-shadow-2xl" : ""}`
    );
  }

  if (headItem.key === "action") {
    return td(
      "Action",
      headItem.id,
      <div className="">
        <AppTableActionButton
          viewLink={`/students/details/${colItem?.userId}`}
          isEdit={false}
          isDelete={false}
        />
      </div>,
      index
    );
  }

  return td(
    headItem.title,
    headItem.id,
    <div>{colItem[headItem.key]}</div>,
    index
  );
};

export const DynamicTableDataForSATeachersData = (
  colItem,
  headItem,
  index,
  shadow
) => {
  if (headItem.key === "tId") {
    return td(
      "Teachers ID",
      headItem.id,
      <div className="ml-8">{colItem[headItem.key]}</div>,
      index,
      `sticky left-0 z-30 before:absolute ${shadow ? "drop-shadow-2xl" : ""}`
    );
  }

  if (headItem.key === "action") {
    return td(
      "Action",
      headItem.id,
      <div className="">
        <AppTableActionButton
          viewLink={`/teachers/details/${colItem?.userId}`}
          isEdit={false}
          isDelete={false}
        />
      </div>,
      index
    );
  }

  return td(
    headItem.title,
    headItem.id,
    <div>{colItem[headItem.key]}</div>,
    index
  );
};

// superAdmin Studdent

export const DynamicTableDataForSuperAdminStudentsData = (
  colItem,
  headItem,
  index,
  shadow,
  handleDelete
) => {
  if (headItem.key === "sId") {
    return td(
      "Students ID",
      headItem.id,
      <div className="ml-8">{colItem[headItem.key]}</div>,
      index,
      `sticky left-0 z-30 before:absolute ${shadow ? "drop-shadow-2xl" : ""}`
    );
  }

  if (headItem.key === "action") {
    return td(
      "Action",
      headItem.id,
      <div className="">
        <AppTableActionButton
          viewLink={`/healthcare/students/view/details/${colItem?.userId}`}
          editLink={`/healthcare/students/edit/${colItem?.userId}`}
          deleteFunction={() =>
            handleDelete(
              colItem.userId,
              colItem.sId
            )
          }
        />
      </div>,
      index
    );
  }

  return td(
    headItem.title,
    headItem.id,
    <div>{colItem[headItem.key]}</div>,
    index
  );
};

// superAdmin Studdent

export const DynamicTableDataForSuperAdminTeachersData = (
  colItem,
  headItem,
  index,
  shadow,
  handleDelete
) => {
  if (headItem.key === "tId") {
    return td(
      "Teachers ID",
      headItem.id,
      <div className="ml-8">{colItem[headItem.key]}</div>,
      index,
      `sticky left-0 z-30 before:absolute ${shadow ? "drop-shadow-2xl" : ""}`
    );
  }

  if (headItem.key === "action") {
    return td(
      "Action",
      headItem.id,
      <div className="">
        <AppTableActionButton
          viewLink={`/healthcare/teachers/view/details/${colItem?.userId}`}
          editLink={`/healthcare/teachers/edit/${colItem?.userId}`}
          deleteFunction={() =>
            handleDelete(
              colItem.userId,
              colItem.tId
            )
          }
        />
      </div>,
      index
    );
  }

  return td(
    headItem.title,
    headItem.id,
    <div>{colItem[headItem.key]}</div>,
    index
  );
};

// superAdmin patients

export const DynamicTableDataForSAPatients = (
  colItem,
  headItem,
  index,
  shadow,
  handleDelete
) => {
  if (headItem.key === "pId") {
    return td(
      "Patients ID",
      headItem.id,
      <div className="ml-8">{colItem[headItem.key]}</div>,
      index,
      `sticky left-0 z-30 before:absolute ${shadow ? "drop-shadow-2xl" : ""}`
    );
  }

  if (headItem.key === "action") {
    return td(
      "Action",
      headItem.id,
      <div className="">
        <AppTableActionButton
          isEdit={false}
          viewLink={`/telemedicine/patients/details/${colItem?.userId}`}
          deleteFunction={() =>
            handleDelete(
              colItem?.userId,
              colItem?.pId
            )
          }
        />
      </div>,
      index
    );
  }

  return td(
    headItem.title,
    headItem.id,
    <div>{colItem[headItem.key]}</div>,
    index
  );
};

// superadmin appionement

export const DynamicTableDataForSAAppointments = (
  colItem,
  headItem,
  index,
  shadow,
  handleDelete
) => {
  if (headItem.key === "apId") {
    return td(
      "Appointment ID",
      headItem.id,
      <div className="ml-8">{colItem[headItem.key]}</div>,
      index,
      `sticky left-0 z-30 before:absolute ${shadow ? "drop-shadow-2xl" : ""}`
    );
  }

  if (headItem.key === "status") {
    return td(
      "Status",
      headItem.id,
      <div className="">
        <AppBadge type={colItem[headItem.key]} />
      </div>,
      index
    );
  }

  if (headItem.key === "action") {
    return td(
      "Action",
      headItem.id,
      <div className="">
        <AppTableActionButton
          isEdit={false}
          viewLink={`/telemedicine/appointments/details/${colItem?.apId}`}
          deleteFunction={() =>
            handleDelete(endPoint.appointmentDelete, colItem?.apId)
          }
        />
      </div>,
      index
    );
  }

  return td(
    headItem.title,
    headItem.id,
    <div>{colItem[headItem.key]}</div>,
    index
  );
};

// superadmin registered Student
export const DynamicTableDataForSARegisteredStudents = (
  colItem,
  headItem,
  index,
  shadow,
  handleDelete
) => {
  if (headItem.key === "oId") {
    return td(
      "Olympiad Id",
      headItem.id,
      <div className="ml-8">{colItem[headItem.key]}</div>,
      index,
      `sticky left-0 z-30 before:absolute ${shadow ? "drop-shadow-2xl" : ""}`
    );
  }
  if (headItem.key === "status") {
    return td(
      "Status",
      headItem.id,
      <div className="">
        <AppBadge type={colItem[headItem.key]} />
      </div>,
      index
    );
  }

  if (headItem.key === "action") {
    return td(
      "Action",
      headItem.id,
      <div className="">
        <AppTableActionButton
          viewLink={`/olympiad/registered-students/details/${colItem?.userId}`}
          deleteFunction={() => handleDelete(colItem?.userId, colItem?.oId)}
          isEdit={false}
        />
      </div>,
      index
    );
  }

  return td(
    headItem.title,
    headItem.id,
    <div>{colItem[headItem.key]}</div>,
    index
  );
};

//SuperAdmin hijama users
export const DynamicTableDataForSAHijamaUsers = (
  colItem,
  headItem,
  index,
  shadow,
  handleDelete
) => {
  if (headItem.key === "hId") {
    return td(
      "Hijama Id",
      headItem.id,
      <div className="ml-8">{colItem[headItem.key]}</div>,
      index,
      `sticky left-0 z-30 before:absolute ${shadow ? "drop-shadow-2xl" : ""}`
    );
  }
  if (headItem.key === "status") {
    return td(
      "Status",
      headItem.id,
      <div className="">
        <AppBadge type={colItem[headItem.key]} />
      </div>,
      index
    );
  }

  if (headItem.key === "action") {
    return td(
      "Action",
      headItem.id,
      <div className="">
        <AppTableActionButton
          isEdit={false}
          viewLink={`/hijama/users/details/${colItem?.userId}`}
          deleteFunction={() =>
            handleDelete( colItem?.userId, colItem?.hId)
          }
        />
      </div>,
      index
    );
  }

  return td(
    headItem.title,
    headItem.id,
    <div>{colItem[headItem.key]}</div>,
    index
  );
};
