import React from "react";
import { Paper, Typography, Box } from "@mui/material";

const GenaralInfo = ({ authorityInfo }) => {
  const {
    ins_name,
    ins_type,
    ins_division,
    ins_district,
    ins_upozila,
    ins_road_no,
    ins_house_no,
    ins_reg_no,
    ins_est_year,
  } = authorityInfo;
  return (
    <div className="">
      <Paper data-aos="fade-up" sx={{ borderRadius: "12px", p: 4 }}>
        <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
          General Information
        </Typography>

        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "60%" }}>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 3 }}>
              Institution
            </Typography>
            <Typography sx={{ fontWeight: "400", fontSize: 16, mt: 0.5 }}>
              {ins_name}
            </Typography>
          </Box>
          <Box sx={{ width: "40%" }}>
            <Typography sx={{ fontWeight: "500", fontSize: 16, mt: 3 }}>
              Type of Institution
            </Typography>
            <Typography sx={{ fontWeight: "400", fontSize: 16, mt: 0.5 }}>
              {ins_type}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", my: 5 }}>
          <Box sx={{ width: "60%" }}>
            <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
              Established Year
            </Typography>
            <Typography sx={{ fontWeight: "400", fontSize: 16, mt: 0.5 }}>
              Institution was constructed in {ins_est_year}
            </Typography>
          </Box>
          <Box sx={{ width: "40%" }}>
            <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
              Register No
            </Typography>
            <Typography sx={{ fontWeight: "400", fontSize: 16, mt: 0.5 }}>
              {ins_reg_no}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Box>
            <Typography sx={{ fontWeight: "500", fontSize: 16 }}>
              Location
            </Typography>
            <Typography sx={{ fontWeight: "400", fontSize: 16, mt: 0.5 }}>
              {ins_house_no} , {ins_road_no} , {ins_upozila} , {ins_district} ,{" "}
              {ins_division}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </div>
  );
};

export default GenaralInfo;
